import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { toast } from 'react-toastify';

import { BlockRepository } from '@core/repositories';
import type { DashboardBlocksQueryDto, DashboardBlocksTransformedEntity } from '@core/repositories';
import { getHttpError } from '@core/services/http';
import { LoadingStore } from '@core/store/common';

export class DashboardStore {
  blocks: DashboardBlocksTransformedEntity | null = null;

  repository = new BlockRepository();

  loader = new LoadingStore();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'DashboardStore',
      properties: ['blocks'],
    });
  }

  getAll = async (query?: DashboardBlocksQueryDto) => {
    if (!this.blocks) {
      this.loader.startLoading();
    }

    try {
      const data = await this.repository.getDashboardBlocks(query);

      runInAction(() => {
        this.blocks = data;
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    } finally {
      this.loader.stopLoading();
    }
  };

  disposer = () => {
    clearPersistedStore(this);
  };
}
