import { EntryPage } from './EntryPage';
import { routerCollection } from '@constants';
import type { RouteObject } from 'react-router-dom';

import { lazyDelay } from '@shared/utils';

const DashboardMain = lazyDelay(() => import('./dashboard-main'));

export const dashboardRouter: RouteObject = {
  path: routerCollection.dashboard.root,
  element: <EntryPage />,
  children: [
    {
      index: true,
      element: <DashboardMain />,
    },
  ],
};
