export const AnalyticsEvents = {
  LoginAccountTap: 'login_account_tap',
  LoginPageView: 'login_page_view',
  LoginSuccess: 'login_success',
  RegistrationAccountTap: 'registration_account_tap',
  RegistrationPageView: 'registration_page_view',
  RegistrationSuccess: 'registration_success',
  NamePageView: 'name_page_view',
  OnboardingPageView: 'onboarding_page_view',
  OnboardingSkipTap: 'onboarding_skip_tap',
  OnboardingConfirmTap: 'onboarding_confirm_tap',
  DashboardPageView: 'dashboard_page_view',
  CoursePageView: 'course_page_view',
  CourseLearningTap: 'course_learning_tap',
  CourseInformationTap: 'course_information_tap',
  CourseNotificationTap: 'course_notification_tap',
  CoursePaymentTap: 'course_payment_tap',
  PaymentSuccess: 'payment_success',
  WorkspacePageView: 'workspace_page_view',
  WorkspaceCourseView: 'workspace_course_view',
  WorkspaceHomeworkView: 'workspace_homework_view',
  MyHomeworkTap: 'my_homework_tap',
  CourseHomeworkTap: 'course_homework_tap',
  CourseVideoTap: 'course_video_tap',
  CourseCommentTap: 'course_comment_tap',
  CourseLessonTap: 'course_lesson_tap',
  TestLessonTap: 'test_lesson_tap',
  TestFinishTap: 'test_finish_tap',
  ProfilePageView: 'profile_page_view',
  ChangeNameTap: 'change_name_tap',
  ChangePhoneTap: 'change_phone_tap',
  ChangePhotoTap: 'change_photo_tap',
  ChangeInterestsTap: 'change_interests_tap',
  EventWatchTap: 'event_watch_tap',
  EventAttendTap: 'event_attend_tap',
  EventsPageView: 'events_page_view',
  EventFollowTap: 'event_follow_tap',
  BannerBlockTap: 'banner_block_tap',

  StreamPageView: 'stream_page_view',
  StreamVolumeTap: 'event_volume_tap',
  StreamToggleTap: 'event_toggle_tap',
  StreamBannerTap: 'event_banner_tap',
  StreamCommentTap: 'event_comment_tap',

  BrandsPageView: 'brands_page_view',
  CommunityPageView: 'community_page_view',
  CommunityCategoryView: 'community_category_view',
  CommunitySelectTap: 'community_select_tap',
  CommunityCategoryTap: 'community_category_tap',
  CommunityChannelTap: 'community_channel_tap',
  CommunityCommentTap: 'community_comment_tap',
  MessagesPageView: 'messages_page_view',
};
