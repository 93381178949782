import { useContext, useEffect } from 'react';

import { Player } from '@components';
import { AnalyticsEvents, PlayerContext, useAnalyticsContext } from '@contexts';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { withMediaUrl } from '@shared/utils';

interface CoursePlayerProps {
  courseId: number;
  groupId: number;
}

let playerProgressAccumulator = 0;

export const CoursePlayer = observer(function CoursePlayer(props: CoursePlayerProps) {
  const { courseId, groupId } = props;

  // @ts-ignore
  const { playerInstance } = useContext(PlayerContext);

  const { courseViewStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const setPlayerCurrentTime = (second: number) => {
    return playerInstance.currentTime(second);
  };

  const setTimeCodesStatusState = () => {
    const integerCurrentTimeValue = parseInt(playerInstance.currentTime());
    const videoDuration = parseInt(playerInstance.duration());

    if (integerCurrentTimeValue !== playerProgressAccumulator) {
      playerProgressAccumulator = integerCurrentTimeValue;

      courseViewStore.lessons.forEach((lesson) => {
        if (courseViewStore.activeLesson.id === lesson.id) {
          if (playerInstance.hasStarted_ && videoDuration && lesson.progress < 100) {
            if (integerCurrentTimeValue % 15 === 0 || integerCurrentTimeValue === videoDuration) {
              courseViewStore.markLessonVideoTime(courseId, lesson.id, integerCurrentTimeValue);
              courseViewStore.setCurrentVideoProgress(
                Math.ceil((integerCurrentTimeValue / videoDuration) * 100),
              );
            }
          }

          lesson.videoTimecodes.forEach(
            (
              {
                time: timecodeTime,
                isActive: timeCodeIsActiveStatus,
                id: videoTimecodeId,
                isWatched: timeCodeIsWatchedStatus,
              },
              timeCodeIndex,
            ) => {
              if (
                integerCurrentTimeValue >= parseInt(timecodeTime.toString()) &&
                !timeCodeIsActiveStatus &&
                !timeCodeIsWatchedStatus
              ) {
                courseViewStore.markTimecodeState(videoTimecodeId);

                if (timeCodeIndex !== lesson.videoTimecodes.length - 1) {
                  courseViewStore.markTimecode(groupId, videoTimecodeId);
                }
              }

              if (
                timeCodeIndex === lesson.videoTimecodes.length - 1 &&
                parseInt(playerInstance.currentTime()) === parseInt(playerInstance.duration())
              ) {
                courseViewStore.markTimecodeState(videoTimecodeId);

                courseViewStore.markTimecode(groupId, videoTimecodeId);
              }
            },
          );
        }
      });
    }
  };

  const setNextLessonForPlayer = () => {
    playerProgressAccumulator = 0;
    courseViewStore.setActiveLessonByIndex('next');
  };

  useEffect(() => {
    if (playerInstance) {
      playerInstance.on('ended', setNextLessonForPlayer);

      playerInstance.ready(() => {
        playerInstance.off('timeupdate');
        playerInstance.on('timeupdate', setTimeCodesStatusState);
      });

      playerInstance.on('play', () => {
        if (courseViewStore.course?.courseWithModules && courseViewStore.activeModuleId) {
          sendEvent({
            event: AnalyticsEvents.CourseVideoTap,
            payload: {
              course_name: courseViewStore.course?.title,
              course_id: courseViewStore.course?.id,
              module_id: courseViewStore.activeModuleId,
              lesson_name: courseViewStore.activeLesson?.title,
              lesson_id: courseViewStore.activeLesson?.id,
            },
          });
        } else {
          sendEvent({
            event: AnalyticsEvents.CourseVideoTap,
            payload: {
              course_name: courseViewStore.course?.title,
              course_id: courseViewStore.course?.id,
              lesson_name: courseViewStore.activeLesson?.title,
              lesson_id: courseViewStore.activeLesson?.id,
            },
          });
        }
      });
    }
  }, [playerInstance]);

  useEffect(() => {
    if (playerInstance) {
      playerInstance.off('timeupdate');
      playerInstance.on('timeupdate', setTimeCodesStatusState);

      playerInstance.ready(() => {
        setTimeCodesStatusState();
        courseViewStore.activeLesson.lastWatchedSecond &&
          setPlayerCurrentTime(courseViewStore.activeLesson.lastWatchedSecond);
      });
    }
  }, [courseViewStore.activeLesson]);

  useEffect(() => {
    return () => playerInstance?.off('timeupdate');
  }, []);

  if (!courseViewStore.lessons.length) {
    return null;
  }

  return (
    <Player
      videoUrl={withMediaUrl(courseViewStore.activeLesson?.video)}
      videoType={courseViewStore.activeLesson?.videoContentType}
    />
  );
});
