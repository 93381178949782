import type { Dispatch, SetStateAction } from 'react';

import { dashboardTabsVariants } from './dashboard-tabs.constants';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

interface DashboardTabsProps {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

export const DashboardTabs = observer(function DashboardTabs(props: DashboardTabsProps) {
  const { activeTab, setActiveTab } = props;

  const { t } = useTranslation();

  return (
    <div className={s.dashboardTabs}>
      <button
        onClick={() => setActiveTab(dashboardTabsVariants.communities)}
        className={cl(
          s.dashboardTabsButton,
          activeTab === dashboardTabsVariants.communities ? s.activeButton : '',
        )}
      >
        <TypographyNew as="span" variant="body-3">
          {t('shared-content.all-communities')}
        </TypographyNew>
      </button>
      <button
        onClick={() => setActiveTab(dashboardTabsVariants.courses)}
        className={cl(
          s.dashboardTabsButton,
          activeTab === dashboardTabsVariants.courses ? s.activeButton : '',
        )}
      >
        <TypographyNew as="span" variant="body-3">
          {t('shared-content.all-courses')}
        </TypographyNew>
      </button>
    </div>
  );
});
