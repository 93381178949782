import { useCallback, useMemo } from 'react';

import type { CreateChannelForm } from './create-channel-modal.form';
import { createChannelSchema, updateChannelSchema } from './create-channel-modal.form';
import type { BaseModalProps } from '@components';
import { FormFields } from '@components';
import { zodResolver } from '@hookform/resolvers/zod';
import cl from 'classnames';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CommunityChannelStatus, CommunityChannelType } from '@core/constants';
import type { CreateCommunityChannelDto } from '@core/repositories';
import { type ChannelStore, useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Avatar, Box, Group, Modal, Spinner, Stack, Typography, TypographyNew } from '@shared/UI';
import { ButtonNew } from '@shared/UI/button-new';

import { mergeComponentsWithName, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

export interface CreateChannelModalProps extends BaseModalProps {
  channel?: ChannelStore;
}

const _CreateChannelModal = observer((props: CreateChannelModalProps) => {
  const { onClose, channel, ...restProps } = props;

  const { t } = useTranslation();

  const { communityChannelsStore } = useRootStore();

  const hasDefaultValues = channel !== undefined;

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<CreateChannelForm>({
    defaultValues: {
      name: channel?.title ?? '',
      file: [],
    },
    resolver: channel ? zodResolver(updateChannelSchema) : zodResolver(createChannelSchema),
  });

  const file = watch('file');

  const onHandleSubmit: SubmitHandler<CreateChannelForm> = async (details) => {
    if (channel) {
      let dto: Partial<CreateCommunityChannelDto> = {
        title: details.name,
      };

      if (details.file.length > 0) {
        dto.file = details.file[0].originalFile;
      }

      await communityChannelsStore.updateChannelMedia(channel.id, dto);
    } else {
      await communityChannelsStore.createChannel({
        file: details.file[0].originalFile,
        title: details.name,
      });
    }

    onClose?.();
  };

  const onHandleRemoveClick = useCallback(async () => {
    if (channel) {
      await communityChannelsStore.updateChannelEmitter({
        status: CommunityChannelStatus.Closed,
        title: getValues('name'),
        categoryId: channel.id,
        type: CommunityChannelType.Free,
      });
    }
    onClose?.();
  }, []);

  const onHandleRestoreClick = useCallback(async () => {
    if (channel) {
      await communityChannelsStore.updateChannelEmitter({
        status: CommunityChannelStatus.Active,
        title: getValues('name'),
        categoryId: channel.id,
        type: CommunityChannelType.Free,
      });
    }

    onClose?.();
  }, []);

  const modalTitle = hasDefaultValues
    ? t('shared-content.edit-channel')
    : t('modals.create-communication-item.category.title');
  const imageSrc = useMemo(() => {
    if (file && file.length > 0) {
      return file[0].url;
    }
    if (channel && channel.imageUrl) {
      return withMediaUrl(channel.imageUrl);
    }

    return ' ';
  }, [channel, file]);

  return (
    <Modal
      centered
      title={
        <Typography as="h4" variant="title-bold-4">
          {modalTitle}
        </Typography>
      }
      isNeedToUnmount
      className={s.createChannelModal}
      onSubmit={handleSubmit(onHandleSubmit)}
      onClose={onClose}
      {...restProps}
    >
      {communityChannelsStore.createChannelLoader.isLoading && <Spinner isFullSize />}
      <Stack align="flex-start" gap="xxl" className={s.createChannelModalBody}>
        <Typography as="p" variant="body-medium-3" className={s.createChannelModalDescription}>
          {t('modals.create-communication-item.category.description')}
        </Typography>

        <Box as="form" w="100%">
          <Stack>
            <Group wrap="nowrap">
              <Avatar size="xl" src={imageSrc} />
              <Stack>
                <FormFields.FileUploader
                  accept="image/*"
                  control={control}
                  name="file"
                  className={s.createChannelModalUploader}
                  maxFileSize={2}
                  label={t('shared-content.upload-category-photo')}
                  showErrorNotification
                />
                <TypographyNew variant="body-4" colorSchema="neutral-200">
                  {t('my-account.personal-information.avatar-description')}
                </TypographyNew>
              </Stack>
            </Group>
            <FormFields.Input
              label={t('inputs.create-category.label')}
              placeholder={t('inputs.create-category.placeholder')}
              control={control}
              name="name"
              error={errors.name}
            />
            <Group w="100%" justify="center" grow>
              <ButtonNew type="submit">
                {t('modals.create-communication-item.category.submit-button')}
              </ButtonNew>
              {hasDefaultValues && (
                <ButtonNew
                  onClick={
                    channel?.status === CommunityChannelStatus.Active
                      ? onHandleRemoveClick
                      : onHandleRestoreClick
                  }
                  type="button"
                  variant="outline"
                  className={cl({
                    [s.createChannelModalRemoveBtn]:
                      channel?.status === CommunityChannelStatus.Active,
                    [s.createChannelModalRestoreBtn]:
                      channel?.status === CommunityChannelStatus.Closed,
                  })}
                >
                  {channel?.status === CommunityChannelStatus.Active
                    ? t('modals.communication-item-settings.category.delete-button')
                    : t('modals.communication-item-settings.category.restore-button')}
                </ButtonNew>
              )}
            </Group>
          </Stack>
        </Box>
      </Stack>
    </Modal>
  );
});

export const CreateChannelModal = mergeComponentsWithName(
  'CreateChannelModal',
  _CreateChannelModal,
);
