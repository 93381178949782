import { useMediaRecorderContext } from '@units';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { SvgIcon, Typography } from '@shared/UI';

import type { ElementProps } from '@shared/types';
import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface CancelRecordingProps extends Omit<ElementProps<'button'>, 'onClick'> {}

const _CancelRecording = observer((props: CancelRecordingProps) => {
  const { className, ...restProps } = props;

  const { isRecording, videoRecordOptions, audioRecordOptions, stopwatch, cancelRecord } =
    useMediaRecorderContext();

  const rootClasses = cl(
    s.cancelRecording,
    {
      [s.cancelRecordingActive]: isRecording,
    },
    className,
  );

  if (!audioRecordOptions.previewStream && !videoRecordOptions.previewStream) return null;

  return (
    <>
      <button onClick={cancelRecord} className={rootClasses} {...restProps}>
        <Typography className={s.cancelRecordingTime} as="span" variant="body-medium-3">
          {stopwatch.time}
        </Typography>

        <Typography className={s.cancelRecordingActionText} as="span" variant="body-medium-2">
          Cancel <SvgIcon type="elongated-arrow" />
        </Typography>
      </button>
    </>
  );
});

export const CancelRecording = mergeComponentsWithName('CancelRecording', _CancelRecording);
