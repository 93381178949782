import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

import type {
  CoursePreviewBlockTransformedEntity,
  GetCoursePreviewQueryDto,
} from '@core/repositories';
import { BlockRepository } from '@core/repositories';
import { LoadingStore } from '@core/store/common';

export class CoursePreviewStore {
  repository = new BlockRepository();

  coursePreview: CoursePreviewBlockTransformedEntity | null = null;

  loader = new LoadingStore();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: 'CoursePreview',
      properties: ['coursePreview'],
    });
  }

  get hasReviews() {
    return this.coursePreview && this.coursePreview.review.items.length > 0;
  }

  get hasPriceList() {
    return this.coursePreview && this.coursePreview.price.length > 0;
  }

  get hasCourses() {
    return this.coursePreview && this.coursePreview.courses.length > 0;
  }

  get hasEvents() {
    return this.coursePreview && this.coursePreview.event.length > 0;
  }

  get hasLessons() {
    return this.coursePreview && this.coursePreview.lesson && this.coursePreview.lesson.length > 0;
  }

  getCoursePreview = async (courseId: number, query?: GetCoursePreviewQueryDto) => {
    if (!this.coursePreview || this.coursePreview.course.id !== courseId) {
      this.loader.startLoading();
    }

    try {
      const data = await this.repository.getCoursePreview(courseId, query);

      runInAction(() => {
        this.coursePreview = data;
      });
    } catch (e) {
      throw e;
    } finally {
      this.loader.stopLoading();
    }
  };

  disposer = () => {
    this.coursePreview = null;
    this.loader = new LoadingStore();

    clearPersistedStore(this);
  };
}
