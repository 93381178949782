import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { GroupProps } from '@shared/UI';
import { Box, Document, Group, ProgressBar, Stack, Typography } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface MediaContentTileProps extends Omit<GroupProps, 'children'> {
  mediaUrl?: string;
  document?: Blob;
  label: string;
  mimeType: string;
  size: string;
  progressLoad?: number;
}

const _MediaContentTile = observer((props: MediaContentTileProps) => {
  const { mediaUrl, document, label, mimeType, size, progressLoad, className, ...restProps } =
    props;

  const rootClasses = cl(s.mediaContentTile, className);

  return (
    <Group wrap="nowrap" className={rootClasses} {...restProps}>
      {mediaUrl && (
        <Box className={s.mediaContentTilePreview}>
          <img width={48} height={48} src={mediaUrl} loading="lazy" />
        </Box>
      )}
      {document && (
        <Box className={s.mediaContentTilePreview}>
          <Document.Thumbnail doc={document} />
        </Box>
      )}
      <Stack className={s.mediaContentTileInfoWrapper} align="flex-start" gap="xs-3">
        <Typography className={s.mediaContentTileName} ellipsis as="span" variant="body-bold-3">
          {label}.{mimeType}
        </Typography>

        <Typography className={s.mediaContentTileSize} as="span" variant="body-medium-3">
          {size}
        </Typography>
        {progressLoad && <ProgressBar progress={progressLoad} />}
      </Stack>
    </Group>
  );
});

export const MediaContentTile = mergeComponentsWithName('MediaContentTile', _MediaContentTile);
