import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { Button, SvgIcon } from '@shared/UI';

export const SidebarTrigger = observer(function SidebarTrigger() {
  const { appLayoutStore } = useRootStore();

  return (
    <Button
      onClick={appLayoutStore.handleChangeOpen}
      variant="text"
      size="icon"
      shape="square"
      disabledAnimation
    >
      <SvgIcon type="burger-menu" />
    </Button>
  );
});
