import { useEffect } from 'react';

import { Actions } from './actions';
import type { BaseModalProps } from '@components';
import { PaymentResults } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import dayjs from 'dayjs';
import Lottie from 'lottie-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import paymentError from '@assets/lottie-animations/payment_error.json';
import paymentSuccess from '@assets/lottie-animations/payment_success.json';

import { Group, Modal, Stack, TypographyNew } from '@shared/UI';

import type { ObjectValues } from '@shared/types';

import s from './styles.module.scss';

interface PaymentResultModalProps extends BaseModalProps {
  resultType: ObjectValues<typeof PaymentResults>;
  subscriptionTitle: string;
  price: number;
  isSubscription?: boolean;
  courseId?: number;
}

export const PaymentResultModal = observer(function PaymentResultModal(
  props: PaymentResultModalProps,
) {
  const { resultType, subscriptionTitle, price, isSubscription, courseId, ...restProps } = props;

  const { t } = useTranslation();

  const sendEvent = useAnalyticsContext();

  const animation = resultType === PaymentResults.Success ? paymentSuccess : paymentError;

  const title =
    resultType === PaymentResults.Success ? 'PAYMENT SUCCESSFUL' : 'OOPS! PAYMENT FAILED';

  const description =
    resultType === PaymentResults.Success
      ? 'You will be redirected to the home page shortly or click here to return to home page'
      : 'Payment could not be process. Try again';

  const purchaseDate = dayjs().format('MMMM DD YYYY');

  useEffect(() => {
    if (courseId && resultType === PaymentResults.Success) {
      sendEvent({
        event: AnalyticsEvents.PaymentSuccess,
        payload: { course_id: courseId, payment_amount: price },
      });
    }
  }, []);

  return (
    <Modal
      centered
      size="lg"
      id="payment-result-modal"
      className={s.paymentResultModal}
      withHeader={false}
      {...restProps}
    >
      <div className={s.paymentResultModalContent}>
        <Stack gap="sm" className={s.paymentResultModalHeader}>
          <div className={s.paymentResultModalContentAnimation}>
            <Lottie animationData={animation} loop={false} />
          </div>
          <TypographyNew variant="title-2" weight="bold">
            {title}
          </TypographyNew>
          <TypographyNew variant="body-1">{description}</TypographyNew>
        </Stack>

        <Group justify="space-between" wrap="nowrap" w="100%">
          <TypographyNew variant="body-2" colorSchema="neutral-200">
            {t('shared-content.date')}:
          </TypographyNew>
          <TypographyNew variant="body-2" colorSchema="neutral-200">
            {purchaseDate}
          </TypographyNew>
        </Group>

        <div className={s.paymentResultModalContentDivider} />

        <Group justify="space-between" wrap="nowrap" w="100%">
          <TypographyNew variant="title-4" weight="bold">
            {subscriptionTitle}
          </TypographyNew>
          <TypographyNew variant="title-4" weight="bold">
            {price}$
          </TypographyNew>
        </Group>

        <Actions resultType={resultType} isSubscription={isSubscription} />
      </div>
    </Modal>
  );
});
