import { AppLayoutStore, AppLoaderStore, AppSettingsStore } from './app';
import { AuthStore } from './auth';
import {
  ChannelsStore,
  CommunitiesStore,
  CommunityChatsStore,
  CommunityMessagesStore,
  CoursePreviewStore,
  CourseViewStore,
  DashboardStore,
  EventsStore,
  MessageCounterStore,
  MyWorkspaceStore,
  NotificationsStore,
  PrivateChatsStore,
  PrivateMessagesStore,
  StreamChatStore,
  StreamsStore,
} from './content';
import { OnBoardingStore } from './on-boarding';
import { PaymentsStore } from './payments';
import { UserStore } from './user';
import { configurePersistable } from 'mobx-persist-store';

import { createSafeContext } from '@shared/utils';

configurePersistable({
  storage: window.localStorage,
  expireIn: 86400000,
  removeOnExpiration: true,
});

class RootStore {
  appSettingsStore = new AppSettingsStore();

  appLoaderStore = new AppLoaderStore();

  appLayoutStore = new AppLayoutStore();

  userStore = new UserStore();

  onBoardingStore = new OnBoardingStore(this.userStore);

  authStore = new AuthStore(this.onBoardingStore, this.userStore, this.appLoaderStore);

  messageCounter = new MessageCounterStore();

  dashboardStore = new DashboardStore();

  eventsStore = new EventsStore();

  courseViewStore = new CourseViewStore();

  myWorkspaceStore = new MyWorkspaceStore();

  communitiesStore = new CommunitiesStore();

  notificationsStore = new NotificationsStore();

  communityChannelsStore = new ChannelsStore();

  communityChatsStore = new CommunityChatsStore(this.communityChannelsStore);

  communityMessagesStore = new CommunityMessagesStore(
    this.userStore,
    this.communityChatsStore,
    this.messageCounter,
  );

  coursePreviewStore = new CoursePreviewStore();

  privateChatsStore = new PrivateChatsStore(this.userStore, this.messageCounter);

  privateMessagesStore = new PrivateMessagesStore(this.userStore, this.privateChatsStore);

  streamsStore = new StreamsStore();

  streamChatStore = new StreamChatStore();

  paymentsStore = new PaymentsStore();

  cleanStores = () => {
    for (const value in this) {
      const key = value as keyof RootStore;
      if ('disposer' in this[key] && typeof (this[key] as any).disposer === 'function') {
        (this[key] as any).disposer();
      }
    }
  };

  initConnectedSocketEvents = () => {
    for (const value in this) {
      const key = value as keyof RootStore;
      if ('initEvents' in this[key] && typeof (this[key] as any).initEvents === 'function') {
        (this[key] as any).initEvents();
      }
    }
  };
}

export type RootStoreType = RootStore;

export const rootStore = new RootStore();

export const {
  0: RootStoreProvider,
  1: useRootStore,
  2: RootStoreContext,
} = createSafeContext<RootStoreType>('RootStoreContext');
