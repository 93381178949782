import type { AnimationEventHandler } from 'react';
import { useLayoutEffect, useRef } from 'react';

import { welcomeAnimation } from './_utils';
import cl from 'classnames';
import type { LottieRefCurrentProps } from 'lottie-react';
import Lottie from 'lottie-react';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import s from './styles.module.scss';

export const WelcomeScreen = observer(() => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const { appLoaderStore } = useRootStore();

  useLayoutEffect(() => {
    const isShown = appLoaderStore.canShowWelcomeLoader(lottieRef?.current?.play);
    appLoaderStore.setIsVisibleWelcome(isShown);
  }, []);

  const completedCallback = () => appLoaderStore.setIsVisibleWelcome(false);

  const handleEnterFrameCallback: AnimationEventHandler = (e) => {
    const targetFrame = 250;
    const currentFrame = Math.floor((e as any).currentTime);
    if (currentFrame === targetFrame && !appLoaderStore.isVisibleWelcome) {
      completedCallback();
    }
  };

  const renderSettings = {
    preserveAspectRatio: 'xMidYMid slice',
  };

  const rootClasses = cl(s.loader, {
    [s.loaderActive]: appLoaderStore.isVisibleWelcome,
    [s.loaderHidden]: !appLoaderStore.isVisibleWelcome,
  });

  return (
    <div className={rootClasses}>
      <Lottie
        className={s.loaderAnimation}
        animationData={welcomeAnimation}
        lottieRef={lottieRef}
        rendererSettings={renderSettings}
        onEnterFrame={handleEnterFrameCallback}
        onComplete={completedCallback}
        onLoopComplete={completedCallback}
      />
    </div>
  );
});
