type CustomHTMLEvent =
  | Event
  | MouseEvent
  | TouchEvent
  | PointerEvent
  | React.MouseEvent
  | React.TouchEvent
  | React.PointerEvent;

export const extractNode = (event: CustomHTMLEvent, identificator: string) => {
  const target = event.target as HTMLElement;

  if (!target) return null;

  const extractedNode = target.closest(identificator) as HTMLElement;

  if (!extractedNode) return null;

  return extractedNode;
};
