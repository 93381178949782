import { useCallback, useMemo } from 'react';

import { modalStore } from '@components';
import { PaymentResults } from '@constants';
import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button, Group } from '@shared/UI';

import type { ObjectValues } from '@shared/types';

import s from './styles.module.scss';

interface ActionsProps {
  resultType: ObjectValues<typeof PaymentResults>;
  isSubscription?: boolean;
}

export const Actions = observer(function Actions(props: ActionsProps) {
  const { resultType, isSubscription } = props;

  const { t } = useTranslation();

  const handleClickBack = () => {
    modalStore.closeAll();
  };

  const btnLabel = useMemo(() => {
    if (resultType === PaymentResults.Success) {
      if (isSubscription) {
        return 'modals.success-payment-modal.actions.community';
      }

      return 'payment-status.start-watching';
    }

    return 'shared-content.try-again';
  }, [isSubscription, resultType]);

  const navigateToCommunity = () => {
    window.location.replace(`${routerCollection.community.root}`);
    modalStore.closeAll();
  };

  const navigateToCourse = () => {
    window.location.replace(`${routerCollection.myWorkspace.courses}`);

    modalStore.closeAll();
  };

  const btnOnClick = useCallback(() => {
    if (resultType === PaymentResults.Success) {
      if (isSubscription) {
        return navigateToCommunity();
      }

      return navigateToCourse();
    }
  }, [resultType, isSubscription]);

  return (
    <Group gap="md" wrap="nowrap" className={s.actions}>
      <Button variant="dark-gray" onClick={handleClickBack}>
        {t('shared-content.back-to-main')}
      </Button>
      <a
        href="mailto: support@comms.fun"
        target="_blank"
        rel="noopener noreferrer"
        className={s.actionsSupportLink}
      >
        <Button variant="dark-gray" type="button">
          {t('shared-content.contact-support')}
        </Button>
      </a>
      <Button onClick={btnOnClick}>{t(btnLabel)}</Button>
    </Group>
  );
});
