import type { CreatePaymentIntentDto, CreateSubscriptionDto } from './dto';
import type { StripeConfigResponse, StripePaymentMethodsResponse } from './responses';

import { PATH_COLLECTION } from '@core/constants';
import { createHTTPService } from '@core/services/http';

export class PaymentsRepository {
  private readonly http = createHTTPService(PATH_COLLECTION.PRICE.ROOT);

  private readonly httpStripe = createHTTPService(PATH_COLLECTION.STRIPE.ROOT);

  private readonly httpCourses = createHTTPService(PATH_COLLECTION.COURSES.ROOT);

  buyFreeCommunity = async (id: number) => {
    const path = PATH_COLLECTION.PRICE.FREE_COMMUNITY;

    try {
      const { data } = await this.http.post(path, {}, { param: { id } });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getPaymentConfig = async () => {
    const path = PATH_COLLECTION.STRIPE.CONFIG;

    try {
      const { data } = await this.httpStripe.get<StripeConfigResponse>(path);

      return data;
    } catch (e) {
      throw e;
    }
  };

  createPaymentIntent = async (body: CreatePaymentIntentDto) => {
    const path = PATH_COLLECTION.STRIPE.CREATE_PAYMENT_INTENT;

    try {
      const { data } = await this.httpStripe.post(path, body);

      return data;
    } catch (e) {
      throw e;
    }
  };

  createSubscription = async (body: CreateSubscriptionDto) => {
    const path = PATH_COLLECTION.STRIPE.CREATE_SUBSCRIPTION;

    try {
      const { data } = await this.httpStripe.post(path, body);

      return data;
    } catch (e) {
      throw e;
    }
  };

  createPaymentMethod = async (token: string) => {
    const path = PATH_COLLECTION.STRIPE.CREATE_PAYMENT_METHOD;

    try {
      const { data } = await this.httpStripe.post(path, { paymentMethodToken: token });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getPaymentMethods = async () => {
    const path = PATH_COLLECTION.STRIPE.GET_PAYMENT_METHODS;

    try {
      const { data } = await this.httpStripe.get<StripePaymentMethodsResponse>(path);

      return data;
    } catch (e) {
      throw e;
    }
  };

  removePaymentMethod = async (id: string) => {
    const path = PATH_COLLECTION.STRIPE.REMOVE_PAYMENT_METHOD;

    try {
      const { data } = await this.httpStripe.post(path, { paymentMethodId: id });

      return data;
    } catch (e) {
      throw e;
    }
  };

  unsubscribe = async (id: string) => {
    const path = PATH_COLLECTION.STRIPE.UNSUBSCRIBE;

    try {
      await this.httpStripe.post(path, { subscriptionId: id });
    } catch (e) {
      throw e;
    }
  };

  enableCourseTrial = async (courseId: number, priceCourseId: number) => {
    const path = PATH_COLLECTION.COURSES.CREATE_COURSE_TRIAL;

    try {
      await this.httpCourses.post(path, { priceCourseId }, { param: { courseId } });
    } catch (e) {
      throw e;
    }
  };
}
