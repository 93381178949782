import { RouterLink } from '@components';
import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const Subtitle = observer(function Subtitle() {
  const { t } = useTranslation();

  return (
    <span>
      {t('registration.already-registered')}
      <RouterLink to={routerCollection.auth.login}>{t('shared-content.sign-in')}</RouterLink>
    </span>
  );
});
