import { EmojiReactionItem } from '../emoji-reaction-item';
import { observer } from 'mobx-react-lite';

import { randomUuid } from '@shared/utils';

interface EmojiReactionListProps {
  reactions: Record<
    string,
    {
      count: number;
      isUserReaction: boolean;
    }
  >;
  messageId: string;
  handleEmojiClick: (details: string, id: string) => void;
}

export const EmojiReactionList = observer(function EmojiReactionList(
  props: EmojiReactionListProps,
) {
  const { reactions, messageId, handleEmojiClick } = props;

  return Object.entries(reactions).map((reaction) => (
    <EmojiReactionItem
      key={randomUuid()}
      reaction={reaction[0]}
      reactionCount={reaction[1].count}
      messageId={messageId}
      handleEmojiClick={handleEmojiClick}
    />
  ));
});
