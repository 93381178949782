import { FormButtons } from '../_components';
import { FormFields } from '@components';
import { routerCollection } from '@constants';
import { useAppNavigate } from '@hooks';

import { useRootStore } from '@core/store';

import { FormBox } from '@features/auth/_components';
import type { ForgotPasswordSecondStepSchema } from '@features/auth/_schemas';
import { forgotPasswordSecondStepSchema } from '@features/auth/_schemas';

import { zodResolver } from '@shared/libs/hookform-resolvers';
import { observer } from '@shared/libs/mobx';
import type { SubmitHandler } from '@shared/libs/react-hook-form';
import { useForm } from '@shared/libs/react-hook-form';
import { useTranslation } from '@shared/libs/react-i18next';

export const ForgotPasswordSecondStepForm = observer(function ForgotPasswordSecondStepForm() {
  const navigate = useAppNavigate();
  const { t } = useTranslation();

  const { authStore } = useRootStore();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordSecondStepSchema>({
    resolver: zodResolver(forgotPasswordSecondStepSchema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
      firstOtp: '',
      email: authStore.defaultFormValues.forgotPasswordFirstStep.email,
    },
  });

  const onSubmit: SubmitHandler<ForgotPasswordSecondStepSchema> = async (dto) => {
    try {
      const { email, newPassword, firstOtp } = dto;
      await authStore.forgotPasswordSecondStep({ email, newPassword, firstOtp });

      navigate(routerCollection.auth.login);
    } catch (e) {}
  };

  return (
    <FormBox
      onSubmit={handleSubmit(onSubmit)}
      title={t('new-password.enter-new-password')}
      hideGoogleButton
      hideSwitcher
    >
      <FormFields.Password
        name="newPassword"
        control={control}
        label="inputs.password.label"
        placeholder="inputs.password.placeholder"
        error={errors.newPassword}
      />
      <FormFields.Password
        name="confirmPassword"
        control={control}
        label="inputs.confirm-password.label"
        placeholder="inputs.password.placeholder"
        error={errors.newPassword}
      />

      <FormFields.Input name="email" control={control} hidden />

      <FormFields.OtpCode name="firstOtp" control={control} error={errors.firstOtp} hiddeResend />

      <FormButtons />
    </FormBox>
  );
});
