import type { ReactNode } from 'react';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

export interface EachProps<T> {
  data: T[];
  render?: (item: T, index: number) => ReactNode;
  children?: (item: T, index: number) => ReactNode;
}

const _Each = observer(<T,>(props: EachProps<T>) => {
  const { data, render, children } = props;

  if (render) {
    return <>{data.map((item, index) => render(item, index))}</>;
  }

  return <>{data.map((item, index) => children!(item, index))}</>;
});

export const Each = mergeComponentsWithName('Each', _Each);
