import { useEffect } from 'react';

import { Button } from '../../../../button';
import { Input } from '../../../../fields';
import { useRichEditorContext } from '../../../rich-editor.context';
import { LINK_PATTERN } from '@constants';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('link-form-dropdown');

type LinkForm = {
  link: string;
};

interface DropdownContentProps {
  onClose: () => void;
}

const linkForm = z.object({
  link: z.string().refine((v) => LINK_PATTERN.test(v) || v.length === 0),
});

const _DropdownContent = observer((props: DropdownContentProps) => {
  const { onClose } = props;

  const { editor } = useRichEditorContext();

  const { handleSubmit, setFocus, setValue } = useForm<LinkForm>({
    resolver: zodResolver(linkForm),
    defaultValues: {
      link: editor().getAttributes('link').href || '',
    },
  });

  const onSubmit: SubmitHandler<LinkForm> = (dto) => {
    if (dto.link.length === 0) {
      editor().chain().focus().extendMarkRange('link').unsetLink().run();
    } else {
      editor()
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({
          href: dto.link,
          target: '_blank',
        })
        .run();
    }

    onClose();
  };

  const handleClickButton = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    setFocus('link');
    return () => {
      setValue('link', '');
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={rootClass}>
      <Input className={appendClass('-field')} name="link" />
      <Button type="button" size="xs" variant="text" disabledAnimation onClick={handleClickButton}>
        Submit
      </Button>
    </form>
  );
});

export const DropdownContent = mergeComponentsWithName('DropdownContent', _DropdownContent);
