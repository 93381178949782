export const dropdownAnimation = {
  hidden: {
    opacity: 0,
    transform: 'translateY(-100%)',
  },
  visible: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  exit: {
    opacity: 0,
    transform: 'translateY(-100%)',
  },
};
