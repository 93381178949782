import { z } from 'zod';

import type { BaseQueryDto } from '@core/repositories/_dto';

export const coursesBlocksQuerySchema = z.object({
  lang: z.string(),
  page: z.number().optional(),
  limit: z.number().optional(),
});

export type CoursesBlocksQueryDto = BaseQueryDto & {
  page?: number;
  limit?: number;
};
