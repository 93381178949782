import { Suspense } from 'react';

import { useAppLifeCycle } from './units';
import { AppVersion, Loader } from '@components';
import { AnalyticsProvider } from '@contexts';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';

import { appSocket } from '@core/services/socket';
import { useRootStore } from '@core/store';

import { RootRouter } from '@pages/root.router';

const App = observer(function App() {
  const { authStore, userStore, appLoaderStore } = useRootStore();

  useAppLifeCycle();

  if ((authStore.isCheckingAuth || appSocket.isConnecting) && appLoaderStore.welcomeWasShown) {
    return <Loader isLoading />;
  }

  return (
    <AnalyticsProvider userStore={userStore}>
      <ToastContainer position="bottom-right" />
      <Suspense fallback={<Loader isLoading />}>
        <RootRouter />
      </Suspense>
      <AppVersion />
    </AnalyticsProvider>
  );
});

export default App;
