import { useEffect } from 'react';

import { Loader } from '@components';
import { routerCollection } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { useAppLocation } from '@hooks';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet } from 'react-router-dom';

import { useRootStore } from '@core/store';

import s from './styles.module.scss';

export const EntryPage = observer(function EntryPage() {
  const { onBoardingStore, userStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const { state } = useAppLocation();

  const canEntryOnInterests = state?.canEntryOnInterests || false;

  useEffect(() => {
    sendEvent({ event: AnalyticsEvents.OnboardingPageView });
  }, []);

  if (userStore.loader.isLoading) {
    return <Loader isLoading />;
  }

  if (onBoardingStore.isCompleted && !canEntryOnInterests) {
    const from = state?.from || routerCollection.dashboard.root;
    return <Navigate to={from} replace />;
  }

  return (
    <div className={s.onBoardEntry}>
      <Outlet />
    </div>
  );
});
