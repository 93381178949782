import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import { PrivateMessageSidebarItem } from '@features-new';
import { TalkingArea, useChatLayout, useMessageParams } from '@units';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Box, Input, SvgIcon, TypographyNew } from '@shared/UI';
import { ButtonNew } from '@shared/UI/button-new';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _PrivateChatSidebar = observer(() => {
  const { t } = useTranslation();

  const { privateChatsStore } = useRootStore();

  const [search, setSearch] = useState('');

  const { isMobile, isOpenSidebar, onOpenSidebar } = useChatLayout();

  const { chatId } = useMessageParams(TalkingArea.PRIVATE);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setSearch(value);
  };

  const clearValue = () => {
    setSearch('');
  };

  const renderChats = privateChatsStore.chats
    .filter((chat) => chat.participant.name.toLowerCase().includes(search.toLowerCase()))
    .map((chat) => {
      let lastMessageData = {
        message: '',
        date: '',
      };

      if (chat.lastMessage) {
        lastMessageData['message'] = chat.lastMessage.message;
        lastMessageData['date'] = chat.lastMessage.createDate;
      }

      return (
        <Box as="li" w="100%" key={chat.id}>
          <PrivateMessageSidebarItem chat={chat} />
        </Box>
      );
    });

  useEffect(() => {
    if (!chatId && isMobile && !isOpenSidebar) {
      onOpenSidebar();
    }
  }, [isMobile]);

  return (
    <Box w="100%" as="ul">
      <Box className={s.chatSearchContainer}>
        <Input
          value={search}
          onChange={handleChangeSearch}
          className={s.chatSearch}
          placeholder={t('shared-content.search-user')}
          prefix={<SvgIcon type="search" />}
          suffix={
            search.length > 0 ? (
              <ButtonNew
                size="icon-xs"
                variant="ghost"
                className={s.crossIcon}
                onClick={clearValue}
                tabIndex={0}
              >
                <SvgIcon type="cross" />
              </ButtonNew>
            ) : null
          }
        />
      </Box>
      {renderChats.length ? (
        renderChats
      ) : (
        <Box className={s.noSearchResults}>
          <TypographyNew colorSchema="neutral-200">{t('community.chats.no-chats')}</TypographyNew>
        </Box>
      )}
    </Box>
  );
});

export const PrivateChatSidebar = mergeComponentsWithName(
  'PrivateChatSidebar',
  _PrivateChatSidebar,
);
