import { useEffect } from 'react';

import { PageSkeleton } from './page-skeleton';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { ContainerWithTitle, LayoutPaddingBox } from '@units';
import { CommunityCardsCarousel } from '@widgets';

import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';
import { useTranslation } from '@shared/libs/react-i18next';

import { Box, Each, Stack, Typography } from '@shared/UI';

import { useMediaQuery } from '@shared/hooks';
import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _Communities = observer(() => {
  const { communitiesStore, messageCounter } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const { t, i18n } = useTranslation();

  const isMatch = useMediaQuery('(max-width: 860px)');

  useEffect(() => {
    communitiesStore.getCommunitiesBlocks({ lang: i18n.language });
  }, [i18n.language]);

  useEffect(() => {
    sendEvent({ event: AnalyticsEvents.BrandsPageView });
  }, []);

  const renderContent = () => {
    if (communitiesStore.communityBlocksLoader.isLoading) {
      return <PageSkeleton />;
    }
    return (
      <>
        {communitiesStore.purchasedCommunities &&
          communitiesStore.purchasedCommunities.businessAccounts.length > 0 && (
            <ContainerWithTitle label={communitiesStore.purchasedCommunities?.title || ''}>
              <CommunityCardsCarousel
                communities={communitiesStore.purchasedCommunities?.businessAccounts || []}
                arrowVariant="filled"
                navigationPosition={isMatch ? 'none' : 'horizontal'}
                getCommunityUnreadCount={messageCounter.getCommunity}
                hasViewerAccess
              />
            </ContainerWithTitle>
          )}

        <Each data={communitiesStore.otherCommunities}>
          {(otherCommunity) =>
            otherCommunity.businessAccounts.length > 0 && (
              <ContainerWithTitle label={otherCommunity.title} key={otherCommunity.id}>
                <CommunityCardsCarousel
                  arrowVariant="filled"
                  navigationPosition={isMatch ? 'none' : 'horizontal'}
                  communities={otherCommunity.businessAccounts}
                />
              </ContainerWithTitle>
            )
          }
        </Each>
      </>
    );
  };

  return (
    <Box className={s.communities}>
      <Box className={s.communitiesHeader}>
        <Typography as="h2" variant="title-bold-1">
          {t('brands.all-brands-in-one-place')}
        </Typography>
      </Box>
      <LayoutPaddingBox>
        <Stack align="flex-start" justify="flex-start" gap="48">
          {renderContent()}
        </Stack>
      </LayoutPaddingBox>
    </Box>
  );
});

export const Communities = mergeComponentsWithName('Communities', _Communities);
