import { type CSSProperties, type RefObject, useMemo } from 'react';

import { useCommunityMessageItemController } from './community-message-item.controller';
import { CircleVideoMessage, CommunityMessageTile, MessageVariant } from '@features-new';
import type { VirtualItem } from '@tanstack/react-virtual';
import {
  EmojiReactionList,
  type IMessageListController,
  MessageDate,
  MessageTile,
  MessageTileFooterInfo,
} from '@units';

import { MessageViewType } from '@core/constants';

import { observer } from '@shared/libs/mobx';

import { Box, Group } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

export interface CommunityMessageItemProps {
  row: VirtualItem;
  communityMessagesListController: IMessageListController;
  scrollAreaRef: RefObject<HTMLElement>;
  setupRef: (node: HTMLElement | null) => void;
  isActiveSticky: (index: number) => boolean;
}

const _CommunityMessageItem = observer((props: CommunityMessageItemProps) => {
  const {
    row,
    communityMessagesListController,
    scrollAreaRef,
    setupRef,
    isActiveSticky,
    ...restProps
  } = props;

  const { message, messageParams, onSelectEmoji } = useCommunityMessageItemController({
    row,
    messagesListController: communityMessagesListController,
  });

  const renderMessageVariant = useMemo(() => {
    if (!communityMessagesListController.isMessage(message)) return null;

    if (message.isMessageWithFiles) {
      return <MessageVariant.TextWithMedia message={message} parent={scrollAreaRef} />;
    }

    if (message.isMessageWithAudio) {
      return (
        <MessageVariant.Audio
          setAudioRef={communityMessagesListController.setMediaElement}
          onClickAudio={communityMessagesListController.onChangeActiveMedia}
          message={message}
        />
      );
    }

    return <MessageVariant.Text message={message} />;
  }, [message, scrollAreaRef]);

  const styles: CSSProperties = useMemo(
    () => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      transform: `translateY(${row.start}px)`,
      willChange: 'transform',
    }),
    [row],
  );

  const dateStyle = {
    ...(isActiveSticky(row.index)
      ? { position: 'sticky', top: 15, zIndex: 1 }
      : { position: 'absolute', transform: `translateY(${row.start}px)`, top: 0 }),
    width: '100%',
    left: 0,
    willChange: 'transform',
    display: 'flex',
    justifyContent: 'center',
  } satisfies CSSProperties;

  if (!message) return null;

  return (
    <Box
      {...restProps}
      style={communityMessagesListController.isMessage(message) ? styles : dateStyle}
      innerRef={setupRef}
      as="li"
      py={[0, 16]}
    >
      {communityMessagesListController.isMessage(message) ? (
        <CommunityMessageTile message={message} scrollAreaRef={scrollAreaRef}>
          {message.type === MessageViewType.Video ? (
            <CircleVideoMessage
              message={message}
              onClickVideo={communityMessagesListController.onChangeActiveMedia}
              setVideoRef={communityMessagesListController.setMediaElement}
              handleEmojiClick={onSelectEmoji}
              showViewedCount
            />
          ) : (
            <MessageTile
              message={message}
              id={message.id}
              variant={messageParams.variant}
              owner={messageParams.owner}
              footerSlot={
                <Group gap="xs" align="flex-end" w="100%">
                  <EmojiReactionList
                    reactions={message.reactions.reactions}
                    messageId={message.id}
                    handleEmojiClick={onSelectEmoji}
                  />
                  <MessageTileFooterInfo message={message} showViewedCount />
                </Group>
              }
            >
              {renderMessageVariant}
            </MessageTile>
          )}
        </CommunityMessageTile>
      ) : (
        <MessageDate date={message} />
      )}
    </Box>
  );
});

export const CommunityMessageItemNew = mergeComponentsWithName(
  'CommunityMessageItemNew',
  _CommunityMessageItem,
);
