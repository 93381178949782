import { SvgIcon } from '../../../svg-icon';
import { useRichEditorContext } from '../../rich-editor.context';
import { EditorMenuItem } from '../editor-menu-item';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

const _BtnOrderedList = observer(() => {
  const { editor } = useRichEditorContext();

  const handleClickBtn = () => editor().chain().focus().toggleOrderedList().run();

  const icon = <SvgIcon type="editor-numbered-list" />;

  return (
    <EditorMenuItem
      icon={icon}
      onClick={handleClickBtn}
      isActive={editor().isActive('orderedList')}
    />
  );
});

export const BtnOrderedList = mergeComponentsWithName('BtnOrderedList', _BtnOrderedList);
