export const PIN_CODE_PATTERN = /^(\d{0,1})(\d{0,1})(\d{0,1})(\d{0,1})(\d{0,1})/;

export const PATH_PARAMS_PATTERN = /:(\w+)/g;

export const MEDIA_QUERY_PATTERN = /\(\s*((?:max|min)-(?:width|height)):\s*(\d+)(px|vw|vh)\s*\)/;

export const MENTION_MESSAGE_PATTERN = /<@(everyone|\d)>/gm;

export const LINK_PATTERN =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const ONLY_FIRST_LINK_PATTERN =
  /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/;

export const YOUTUBE_LINK =
  /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

export const YOUTUBE_LINK_ID =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export const MENTION_TAG = /(<span data-type="mention".*?<\/span>)/gmu;

export const MENTION_DATA_ID = /data-id="(\d+)"/;

export const MENTION_NAME = /@([\p{L}\p{N}\s]+)/u;

export const EMPTY_TAGS_PATTERN = /<(\w+)>\s*<\/\1>|<(\w+)>\s*<\/\2>$/g;
