export interface EventEmitterListener<T> {
  (event: T): any;
}

export interface EventEmitterDisposable {
  dispose(): void;
}

export class EventEmitter<T> {
  private EventEmitterListeners: EventEmitterListener<T>[] = [];

  private EventEmitterListenersOncer: EventEmitterListener<T>[] = [];

  on = (EventEmitterListener: EventEmitterListener<T>): EventEmitterDisposable => {
    this.EventEmitterListeners.push(EventEmitterListener);
    return {
      dispose: () => this.off(EventEmitterListener),
    };
  };

  once = (EventEmitterListener: EventEmitterListener<T>): void => {
    this.EventEmitterListenersOncer.push(EventEmitterListener);
  };

  off = (EventEmitterListener: EventEmitterListener<T>) => {
    const callbackIndex = this.EventEmitterListeners.indexOf(EventEmitterListener);
    if (callbackIndex > -1) this.EventEmitterListeners.splice(callbackIndex, 1);
  };

  emit = (event: T) => {
    this.EventEmitterListeners.forEach((EventEmitterListener) => EventEmitterListener(event));

    if (this.EventEmitterListenersOncer.length > 0) {
      const toCall = this.EventEmitterListenersOncer;
      this.EventEmitterListenersOncer = [];
      toCall.forEach((EventEmitterListener) => EventEmitterListener(event));
    }
  };

  pipe = (te: EventEmitter<T>): EventEmitterDisposable => {
    return this.on((e) => te.emit(e));
  };
}

export const createEventEmitter = <T = any>(): EventEmitter<T> => new EventEmitter<T>();
