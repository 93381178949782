import { useCallback, useMemo } from 'react';

import type { VirtualItem } from '@tanstack/react-virtual';
import { type IMessageListController, useMessageTransmitterContext } from '@units';
import { computed } from 'mobx';

import { useRootStore } from '@core/store';

import { useContextMenu } from '@shared/UI';

interface UseCommunityMessageItemControllerProps {
  row: VirtualItem;
  messagesListController: IMessageListController;
}

export const useCommunityMessageItemController = (
  props: UseCommunityMessageItemControllerProps,
) => {
  const { row, messagesListController } = props;

  const { communityMessagesStore, communityChatsStore } = useRootStore();

  const { messageTransmitter } = useMessageTransmitterContext();

  const { show, hide } = useContextMenu();

  const message = computed(() => messagesListController.rowsWithDate[row.index]).get();

  const onSelectEmoji = useCallback((emoji: string) => {
    if (!communityChatsStore.activeChat || !messagesListController.isMessage(message)) return;

    communityMessagesStore.toggleEmojiEmitter({
      communicationItemId: communityChatsStore.activeChat.id,
      messageId: message.id,
      reaction: emoji,
    });
  }, []);

  const messageParams = useMemo(
    () =>
      ({
        owner:
          messagesListController.isMessage(message) && message?.isMessageFromViewer
            ? 'viewer'
            : 'participant',
        variant:
          messagesListController.isMessage(message) && message?.isMessageFromViewer
            ? 'primary'
            : 'secondary',
      } as const),
    [message],
  );

  return {
    message,
    messageParams,
    messageTransmitter,
    onSelectEmoji,
    contextMenu: {
      show,
      hide,
    },
  };
};
