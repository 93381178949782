import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { toast } from 'react-toastify';

import { BlockRepository, CoursesRepository, GroupRepository } from '@core/repositories';
import type { CourseGroupBlockEntity, CourseGroupLessonBlockEntity } from '@core/repositories';
import type { VideoTimecodeEntity } from '@core/repositories/_entities';
import type { GetCommentsQueryDto } from '@core/repositories/courses/dto';
import type { CommentItemEntity } from '@core/repositories/courses/entities';
import { getHttpError } from '@core/services/http';
import { LoadingStore } from '@core/store/common';

export class CourseViewStore {
  course: CourseGroupBlockEntity | null = null;

  lessons: CourseGroupLessonBlockEntity[] = [];

  activeLesson: CourseGroupLessonBlockEntity = {} as CourseGroupLessonBlockEntity;

  activeModuleId: number | null = null;

  comments: CommentItemEntity[] = [];

  loader = new LoadingStore();

  private blocksRepository = new BlockRepository();

  private groupRepository = new GroupRepository();

  private coursesRepository = new CoursesRepository();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'CourseViewStore',
      properties: ['course', 'comments'],
    });
  }

  setLessons = (lessons: CourseGroupLessonBlockEntity[]) => {
    this.lessons = lessons;
  };

  setActiveLesson = (lesson: CourseGroupLessonBlockEntity) => {
    this.activeLesson = lesson;
  };

  setCurrentVideoProgress = (progress: number) => {
    this.lessons = this.lessons.map((lesson) => {
      if (this.activeLesson.id === lesson.id) {
        return {
          ...lesson,
          progress,
        };
      }

      return lesson;
    });
  };

  markTimecodeState = (activeTimecodeId: number) => {
    const lessonsWithUpdatedTimecodes = this.lessons.map((lesson) => {
      if (lesson.id === this.activeLesson.id) {
        lesson.videoTimecodes.map((timeCode, timeCodeIndex) => {
          if (timeCode.id === activeTimecodeId) {
            timeCode.isActive = true;
          }

          const previousTimeCodeItem = lesson.videoTimecodes[timeCodeIndex - 1];

          if (timeCode.id === activeTimecodeId && previousTimeCodeItem) {
            if (!previousTimeCodeItem.isWatched) {
              previousTimeCodeItem.isWatched = true;
              previousTimeCodeItem.isActive = false;
            }
          }

          return timeCode;
        });
      }
      return lesson;
    });

    this.lessons = lessonsWithUpdatedTimecodes;
  };

  setActiveLessonByIndex = (
    direction?: 'prev' | 'next',
    handleButtonClick?: (index: number) => void,
  ) => {
    const indexOfCurrentLesson = this.lessons.findIndex(
      (lesson) => lesson.id === this.activeLesson.id,
    );

    const updatedLessons = this.lessons.map((lesson, lessonIndex) => {
      if (indexOfCurrentLesson === lessonIndex) {
        lesson.videoTimecodes.forEach((timecode: VideoTimecodeEntity) => {
          if (!timecode.isWatched) {
            timecode.isWatched = true;
            timecode.isActive = false;
          }
        });
      }

      return lesson;
    });

    if (direction === 'next') {
      const nextLesson = this.lessons[indexOfCurrentLesson + 1];

      handleButtonClick?.(indexOfCurrentLesson + 1);

      this.activeLesson = {
        ...nextLesson,
      };
    }

    if (direction === 'prev') {
      const previousLesson = this.lessons[indexOfCurrentLesson - 1];

      handleButtonClick?.(indexOfCurrentLesson - 1);

      this.activeLesson = {
        ...previousLesson,
      };
    }

    this.lessons = updatedLessons;
  };

  getCourse = async (id: number) => {
    if (!this.course) {
      this.loader.startLoading();
    }

    try {
      const data = await this.blocksRepository.getCoursesGroupBlocksByGroupId(id);

      runInAction(() => {
        this.course = data.block;

        if (data.block.courseWithModules) {
          this.activeModuleId =
            data.block.lastWatchedLesson.lastWatchedModuleId || data.block.lessonsBlock[0].id;

          if (this.activeModuleId) {
            data.block.lessonsBlock.map((item) => {
              if ('lessons' in item && this.activeModuleId === item.id) {
                this.setLessons(item.lessons);

                const lastWatchedLesson = item.lessons.find(
                  (lesson) => data.block.lastWatchedLesson.lastWatchedLessonId === lesson.id,
                );

                this.activeLesson =
                  (lastWatchedLesson as CourseGroupLessonBlockEntity) || item.lessons[0];
              }

              return item;
            });
          }
        }

        if (!data.block.courseWithModules) {
          const lastWatchedLesson = data.block.lessonsBlock.find(
            (lesson) => data.block.lastWatchedLesson.lastWatchedLessonId === lesson.id,
          );

          this.lessons = data.block.lessonsBlock as CourseGroupLessonBlockEntity[];
          this.activeLesson =
            (lastWatchedLesson as CourseGroupLessonBlockEntity) ||
            (data.block.lessonsBlock[0] as CourseGroupLessonBlockEntity);
        }
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    } finally {
      this.loader.stopLoading();
    }
  };

  markTimecode = async (groupId: number, videoTimecodeId: number) => {
    try {
      const data = await this.groupRepository.markTimecode(groupId, videoTimecodeId);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  markLessonVideoTime = async (courseId: number, lessonId: number, time: number) => {
    try {
      const data = await this.coursesRepository.markLessonVideoTime(courseId, lessonId, time);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  getComments = async (courseId: number, lessonId: number, query?: GetCommentsQueryDto) => {
    try {
      const data = await this.coursesRepository.getComments(courseId, lessonId, query);

      runInAction(() => {
        this.comments = data.lessonComments;
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  postComment = async (courseId: number, lessonId: number, comment: string) => {
    try {
      const data = await this.coursesRepository.postComment(courseId, lessonId, comment);

      runInAction(() => {
        this.comments = [data, ...this.comments];
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  disposer = () => {
    clearPersistedStore(this);
  };
}
