import { createContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import type Player from 'video.js/dist/types/player';

type PlayerContextProps = {
  playerInstance: Player | null;
  setPlayerInstance: Dispatch<SetStateAction<Player | null>>;
};

export const PlayerContext = createContext<PlayerContextProps | null>(null);
