import { useEffect } from 'react';

import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

export const EntryPage = observer(function EntryPage() {
  const sendEvent = useAnalyticsContext();

  useEffect(() => {
    sendEvent({ event: AnalyticsEvents.ProfilePageView });
  }, []);

  return <Outlet />;
});
