import type { ElementType, MouseEvent } from 'react';
import { useCallback, useRef, useState } from 'react';

import type { BoxProps } from '../box';
import { Box } from '../box';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('crawl-line');

export type CrawlLineProps<Element extends ElementType = 'div'> = BoxProps<Element> & {
  speed?: number;
  trigger?: 'hover';
  isPermanentRunning?: boolean;
};

const _CrawlLine = observer((props: CrawlLineProps) => {
  const {
    children,
    className,
    speed = 4,
    trigger,
    isPermanentRunning = false,
    ...restProps
  } = props;

  const boxRef = useRef<HTMLElement | null>(null);

  const [_speed, setSpeed] = useState(0);

  const rootClasses = cl(rootClass, className);

  const checkIsNeedToRun = (node: HTMLElement) => {
    requestAnimationFrame(() => {
      setSpeed(() => (node.scrollWidth / speed) * 0.1);
      if (trigger) {
        return;
      }

      if (node.scrollWidth > node.offsetWidth || isPermanentRunning) {
        node.setAttribute('data-active', 'true');
      } else {
        node.setAttribute('data-active', 'false');
      }
    });
  };

  const setBoxRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      boxRef.current = node;
      checkIsNeedToRun(node);
    }
  }, []);

  const containerStyle = {
    animationDuration: `${_speed}s`,
  };

  const onMouseOver = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (
        trigger === 'hover' &&
        event.currentTarget.scrollWidth > event.currentTarget.offsetWidth
      ) {
        event.currentTarget.setAttribute('data-active', 'true');
      }
    },
    [trigger],
  );

  const onMouseLeave = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (trigger === 'hover') {
        event.currentTarget.setAttribute('data-active', 'false');
      }
    },
    [trigger],
  );

  return (
    <Box
      className={rootClasses}
      innerRef={setBoxRef}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      {...restProps}
    >
      <Box style={containerStyle} className={appendClass('-content-container')}>
        {children}
      </Box>
    </Box>
  );
});

export const CrawlLine = mergeComponentsWithName('CrawlLine', _CrawlLine);
