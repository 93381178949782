import { observer } from 'mobx-react-lite';
import { LazyLoadImage as LazyLoad } from 'react-lazy-load-image-component';
import type { LazyLoadImageProps } from 'react-lazy-load-image-component';

import logoSquareImage from '@assets/images/vector/logo/logo.svg';

export type RemoteImageProps = { wrapperClassName?: string } & LazyLoadImageProps;

export const RemoteImage = observer(function RemoteImage(props: RemoteImageProps) {
  const { src, wrapperClassName, ...restProps } = props;

  const path = `${import.meta.env.VITE_MEDIA_SERVER_DEFAULT_API_URL_PATH}${src}`;

  return (
    <LazyLoad
      src={path}
      alt={path}
      effect="black-and-white"
      placeholderSrc={logoSquareImage}
      {...restProps}
    />
  );
});
