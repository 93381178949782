import type { AxiosRequestConfig } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';

import type { CommunityChatStatus, CommunityChatType } from '@core/constants';
import { SocketEventsCollection } from '@core/constants';
import type {
  CommunityChatEntity,
  UpdateCommunityChatDto,
  UpdateCommunityChatResponse,
  UploadChatAttachmentsDto,
} from '@core/repositories';
import { communityRepository } from '@core/repositories';
import { appSocket } from '@core/services/socket';
import { LoadingStore } from '@core/store/common';

import type { ObjectValues } from '@shared/types';

interface IChatStore extends Required<CommunityChatEntity> {}

export class ChatStore implements IChatStore {
  categoryId: string;

  description: string;

  id: string;

  status: ObjectValues<typeof CommunityChatStatus>;

  title: string;

  type: ObjectValues<typeof CommunityChatType>;

  unreadCount: number;

  uploadFileLoader = new LoadingStore();

  discordChannelId = '';

  constructor({
    categoryId,
    description,
    status,
    unreadCount,
    type,
    id,
    title,
    discordChannelId,
  }: CommunityChatEntity) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.unreadCount = unreadCount;
    this.categoryId = categoryId;
    this.description = description;
    this.status = status;
    this.type = type;
    this.id = id;
    this.title = title;
    this.discordChannelId = discordChannelId ?? '';
  }

  increaseUnreadCount = () => {
    this.unreadCount += 1;
  };

  decreaseUnreadCount = () => {
    this.unreadCount = Math.max(0, this.unreadCount - 1);
  };

  updateUnreadCount = (value: number) => {
    this.unreadCount = Math.max(0, value);
  };

  uploadChatAttachments = async (
    dto: Omit<UploadChatAttachmentsDto, 'chatId'>,
    onProgress?: AxiosRequestConfig['onUploadProgress'],
  ) => {
    this.uploadFileLoader.startLoading();
    try {
      await communityRepository.uploadChatAttachments(
        {
          chatId: this.id,
          ...dto,
        },
        onProgress,
      );
    } catch (err) {
    } finally {
      this.uploadFileLoader.stopLoading();
    }
  };

  updateChat = (dto: Omit<UpdateCommunityChatDto, 'communicationItemId'>) => {
    const _dto: Required<UpdateCommunityChatDto> = {
      status: this.status,
      communicationItemId: this.id,
      description: this.description,
      categoryId: this.categoryId,
      type: this.type,
      title: this.title,
      discordChannelId: this.discordChannelId,
      ...dto,
    };

    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.UpdateCommunication, _dto);
  };

  updateChatInfo = (details: UpdateCommunityChatResponse) => {
    runInAction(() => {
      this.status = details.status;
      this.description = details.description;
      this.type = details.type;
      this.title = details.title;
      this.discordChannelId = details.discordChannelId ?? this.discordChannelId;
    });
  };
}
