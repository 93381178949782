export class StorageService<Schema extends Record<string, any>> {
  constructor(private readonly storage: Storage) {}

  public getItem<T extends keyof Schema>(key: T): Schema[T] | null {
    const data = this.storage.getItem(key as string);

    if (data === null) return data;

    return JSON.parse(this.storage.getItem(key as string) as string);
  }

  public setItem<T extends keyof Schema>(key: T, value: Schema[T]): void {
    this.storage.setItem(key as string, JSON.stringify(value));
  }

  public removeItem<T extends keyof Schema>(key: T): void {
    this.storage.removeItem(key as string);
  }

  public clear(): void {
    this.storage.clear();
  }
}
