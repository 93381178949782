import { useEffect, useState } from 'react';

import type { BaseModalProps } from '../../_types';
import { modalStore } from '../../modal.store';
import { CancelSubscriptionSuccessModal } from '../cancel-subscription-success-modal';
import { cancelSubscriptionReasonsSchema } from './cancel-subscription-reasons-modal.schema';
import type { CancelSubscriptionReasonsSchema } from './cancel-subscription-reasons-modal.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Button, Checkbox, Modal, Stack, Textarea, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

const reasons = [
  t('my-account.my-subscription.reasons.1'),
  t('my-account.my-subscription.reasons.2'),
  t('my-account.my-subscription.reasons.3'),
  t('my-account.my-subscription.reasons.4'),
  t('my-account.my-subscription.reasons.5'),
];

interface CancelSubscriptionReasonsModalProps extends BaseModalProps {
  endDate: string;
  subscriptionId: string;
}

export const CancelSubscriptionReasonsModal = observer(function CancelSubscriptionReasonsModal(
  props: CancelSubscriptionReasonsModalProps,
) {
  const { endDate, subscriptionId, ...restProps } = props;

  const { t } = useTranslation();

  const [isOwnReasonFieldVisible, setIsOwnReasonFieldVisible] = useState(false);

  const { paymentsStore } = useRootStore();

  const {
    formState: { errors },
    watch,
    handleSubmit,
    control,
  } = useForm<CancelSubscriptionReasonsSchema>({
    defaultValues: {
      selectedFields: Array(reasons.length).fill(false),
      ownReason: '',
    },
    resolver: zodResolver(cancelSubscriptionReasonsSchema),
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === `selectedFields.${reasons.length - 1}` && value.selectedFields) {
        if (value.selectedFields[value.selectedFields.length - 1]) {
          setIsOwnReasonFieldVisible(true);
        } else {
          setIsOwnReasonFieldVisible(false);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async () => {
    paymentsStore.unsubscribe(subscriptionId).then(() => {
      modalStore.activateModal({
        component: <CancelSubscriptionSuccessModal endDate={endDate} isNeedToUnmount />,
        id: 'cancel-subscription-success',
      });
    });
  };

  return (
    <Modal
      centered
      size="lg"
      id="cancel-subscription-reasons"
      title={
        <TypographyNew variant="title-3" weight="bold">
          {t('my-account.my-subscription.cancel')}
        </TypographyNew>
      }
      {...restProps}
    >
      <Stack gap="xxl" align="flex-start" className={s.cancelSubscriptionReasonsModal}>
        <TypographyNew variant="title-4" weight="bold">
          {t('my-account.my-subscription.reason-title')}
        </TypographyNew>
        <form onSubmit={handleSubmit(onSubmit)} className={s.cancelSubscriptionReasonsModalForm}>
          <Stack>
            {reasons.map((reason, reasonIndex) => {
              return (
                <Controller
                  key={reason}
                  name={`selectedFields.${reasonIndex}`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      id={`unsubscribe-reason-${reasonIndex}`}
                      hasError={!!errors?.selectedFields}
                    >
                      {reason}
                    </Checkbox>
                  )}
                />
              );
            })}
            {isOwnReasonFieldVisible && (
              <Controller
                name="ownReason"
                control={control}
                render={({ field }) => (
                  <Textarea
                    value={field.value}
                    onChange={field.onChange}
                    error={errors?.ownReason?.message}
                  />
                )}
              />
            )}
            <Button type="submit" size="sm" className={s.cancelSubscriptionReasonsModalButton}>
              {t('my-account.my-subscription.finish-cancelation')}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
});
