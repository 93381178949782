import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import type { MyHomeworkEntity, MyHomeworkTypes } from '@core/repositories';

import type { BadgeProps } from '@shared/UI';

type UseHomeworkAccordionProps = {
  blockType: MyHomeworkTypes;
};

export function useHomeworkAccordion(props: UseHomeworkAccordionProps) {
  const { blockType } = props;

  const { t } = useTranslation();

  const badgeBg: Record<MyHomeworkTypes, string> = useMemo(
    () => ({
      toBeSent: 'rgba(255, 59, 48, 0.25)',
      unverified: 'rgba(255, 204, 0, 0.25)',
      verified: 'rgba(93, 201, 72, 0.25)',
    }),
    [],
  );

  const badgeText: Record<MyHomeworkTypes, string> = useMemo(
    () => ({
      toBeSent: t('my-workspace.homework.to-be-sent'),
      unverified: t('my-workspace.homework.need-verification'),
      verified: t('my-workspace.homework.verified'),
    }),
    [t],
  );

  const badge: BadgeProps = useMemo(
    () => ({
      backgroundColor: badgeBg[blockType],
      label: badgeText[blockType],
      color: '#ffffff',
      size: 'md',
    }),
    [badgeBg, badgeText, blockType],
  );

  const accordionLabel = useCallback(
    (homework: MyHomeworkEntity) => {
      const homeworkType =
        homework.type === 'test' ? t('shared-content.test') : t('shared-content.homework');

      return {
        title: `${t('shared-content.lesson')} ${homework.position} | ${homeworkType}`,
        subtitle: homework.title,
      };
    },
    [t],
  );

  return {
    badge,
    accordionLabel,
  };
}
