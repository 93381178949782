import { observer } from 'mobx-react-lite';

import type { HomeworkTestOptionEntity } from '@core/repositories';

import { Checkbox } from '@shared/UI';

import { HomeworkCheckbox } from './homework-checkbox';

export type CheckboxesProps = {
  options: HomeworkTestOptionEntity[];

  selectedIds: string[];

  onChange: (value: string | string[]) => void;

  canInteract: boolean;
};

export const Checkboxes = observer(function Checkboxes(props: CheckboxesProps) {
  const { options, selectedIds, canInteract, onChange } = props;

  return (
    <Checkbox.Group disabled={!canInteract} value={selectedIds} onChange={onChange}>
      {options.map((opt) => {
        const isChecked = selectedIds.includes(String(opt.id));
        return <HomeworkCheckbox key={opt.id} option={opt} isChecked={isChecked} />;
      })}
    </Checkbox.Group>
  );
});
