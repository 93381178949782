import { type ReactNode, useMemo } from 'react';

import { ReplyMessageInfo } from '../reply-message-info';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { MessageViewType } from '@core/constants';
import type { MessageStore } from '@core/store';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { GroupProps } from '@shared/UI';
import { Avatar, Box, Group, Stack, Typography } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const messageItem = cva(s.messageTile, {
  variants: {
    variant: {
      primary: s.messageTileVariantPrimary,
      secondary: s.messageTileVariantSecondary,
      pinned: s.messageTileVariantPinned,
      audio: s.messageTileVariantAudio,
    },
    owner: {
      viewer: s.messageTileViewer,
      participant: s.messageTileParticipant,
      other: s.messageTileOther,
    },
  },

  defaultVariants: {
    variant: 'primary',
    owner: 'viewer',
  },
});

export interface MessageTileProps
  extends Omit<GroupProps, 'children'>,
    VariantProps<typeof messageItem> {
  message: MessageStore;

  children: ReactNode;

  footerSlot?: ReactNode;

  showViewedCount?: boolean;
}

const _MessageTile = observer((props: MessageTileProps) => {
  const { children, variant, owner, className, footerSlot, message, itemType, ...restProps } =
    props;

  const rootClasses = messageItem({
    variant,
    owner,
    className: cl(
      {
        [s.messageTileHiddenAvatar]: message.isMessageFromViewer,
        [s.messageTileVariantPinned]: message.isPinned,
        [s.messageTileVariantAudio]: message.type === MessageViewType.Audio,
      },
      className,
    ),
  });

  const messageTileFooterClasses = cl({
    [s.messageTileFooter]: Object.keys(message.reactions.reactions).length > 0,
  });

  const imageProps = useMemo(
    () => ({
      visibleByDefault: true,
      width: 36,
      height: 36,
    }),
    [],
  );

  return (
    <Group wrap="nowrap" className={rootClasses} align="flex-end" {...restProps}>
      {!message.isMessageFromViewer && (
        <Avatar imageProps={imageProps} src={message.ownerImage} size="sm-2" />
      )}
      <Box className={s.messageTileWrapper}>
        <Stack
          className={s.messageTileContent}
          gap="xs-3"
          justify="flex-start"
          align="flex-start"
          w="100%"
        >
          {message.replyMessageInfo && (
            <ReplyMessageInfo
              authorName={message.replyMessageInfo.authorName}
              message={message.replyMessageInfo.message}
              type={message.replyMessageInfo.type}
              attachments={message.replyMessageInfo.attachments}
            />
          )}
          {!message.isMessageFromViewer && (
            <Typography className={s.messageTileOwnerName} as="span" variant="body-medium-2">
              {message.authorName}
            </Typography>
          )}
          <Box className={s.messageTileContentMsg} w="100%">
            {children}
          </Box>
        </Stack>

        <div className={messageTileFooterClasses}>
          <Box w="100%">{footerSlot}</Box>
        </div>
      </Box>
    </Group>
  );
});

export const MessageTile = mergeComponentsWithName('MessageTile', _MessageTile);
