import type { FormEvent } from 'react';
import { useEffect, useState } from 'react';

import { PaymentResultModal } from '../../payment-result-modal';
import { CreditCardFields } from './credit-card-fields';
import { SavedCardVariant } from './saved-card-variant';
import { modalStore } from '@components';
import { PaymentResults, PaymentVariants } from '@constants';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { StripePaymentMethod } from '@core/repositories';
import { useRootStore } from '@core/store';

import { Button, Radio, Stack } from '@shared/UI';

import s from './styles.module.scss';

const RADIO_NAME = 'payment-variant';

interface CheckoutFormProps {
  subscriptionTitle: string;
  price: number;
  id: number;
  isTrial: boolean;
  clientSecret: string;
  toggleIsProcessing: (value?: boolean) => void;
  isSubscription?: boolean;
  courseId?: number;
}

export const CheckoutForm = observer(function CheckoutForm(props: CheckoutFormProps) {
  const {
    subscriptionTitle,
    price,
    id,
    isTrial,
    clientSecret,
    isSubscription,
    toggleIsProcessing,
    courseId,
  } = props;

  const { t } = useTranslation();

  const { paymentsStore } = useRootStore();

  const stripe = useStripe();
  const elements = useElements();

  const [savedCards, setSavedCards] = useState<StripePaymentMethod[]>([]);
  const [currentVariant, setCurrentVariant] = useState(PaymentVariants.NewCard);

  const [isCardNumberDirty, setIsCardNumberDirty] = useState(false);
  const [isExpDateDirty, setIsExpDateDirty] = useState(false);
  const [isCvcNumberDirty, setIsCvcNumberDirty] = useState(false);

  const handleCardNumberChange = (event: StripeCardNumberElementChangeEvent) => {
    setIsCardNumberDirty(!event.empty);
  };
  const handleExpDateChange = (event: StripeCardExpiryElementChangeEvent) => {
    setIsExpDateDirty(!event.empty);
  };
  const handleCvcNumberChange = (event: StripeCardCvcElementChangeEvent) => {
    setIsCvcNumberDirty(!event.empty);
  };

  const isProceedButtonDisabled = () => {
    if (currentVariant !== PaymentVariants.NewCard) {
      return false;
    }

    if (!stripe || !isCardNumberDirty || !isCvcNumberDirty || !isExpDateDirty) {
      return true;
    }

    return false;
  };

  const PaymentErrorModal = (
    <PaymentResultModal
      resultType={PaymentResults.Error}
      subscriptionTitle={subscriptionTitle}
      price={price}
      isNeedToUnmount
    />
  );

  const makeSubscription = async () => {
    const cardElement = elements?.getElement(CardNumberElement);
    const result = cardElement && (await stripe?.createToken(cardElement));

    if (result && 'error' in result) {
      modalStore.activateModal({
        component: PaymentErrorModal,
      });
      throw new Error('Payment error');
    } else {
      try {
        result && (await paymentsStore.createPaymentMethod(result.token.id));
        await paymentsStore.createSubscription({ priceCommunityId: id, isTrial });
      } catch (e) {
        modalStore.activateModal({
          component: PaymentErrorModal,
        });
        throw e;
      }
    }

    return result;
  };

  const makeOneTimePayment = async () => {
    const cardElement = elements?.getElement(CardNumberElement);
    const result = await stripe?.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement as StripeCardNumberElement,
      },
    });

    if (result && 'error' in result) {
      modalStore.activateModal({
        component: PaymentErrorModal,
      });
      throw new Error('Payment error');
    }
  };

  const handlePaymentBySavedCard = async () => {
    try {
      await paymentsStore.createSubscription({
        priceCommunityId: id,
        isTrial,
        paymentMethodId: currentVariant,
      });
    } catch (e) {
      modalStore.activateModal({
        component: PaymentErrorModal,
      });
      throw e;
    }
  };

  const showSuccessModal = () => {
    modalStore.activateModal({
      component: (
        <PaymentResultModal
          resultType={PaymentResults.Success}
          subscriptionTitle={subscriptionTitle}
          price={price}
          isSubscription={isSubscription}
          courseId={courseId}
          isNeedToUnmount
        />
      ),
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    toggleIsProcessing(true);
    event.preventDefault();

    modalStore.deactivateModal('payment-result-modal');

    if (!stripe || !elements) {
      return;
    }

    const savedCardId = savedCards.find((card) => card.id === currentVariant)?.id;

    if (savedCardId && currentVariant === savedCardId) {
      try {
        await handlePaymentBySavedCard();
        showSuccessModal();
      } finally {
        toggleIsProcessing(false);
      }
    } else {
      try {
        if (isSubscription || isTrial) {
          await makeSubscription();
        } else {
          await makeOneTimePayment();
        }
        showSuccessModal();
      } finally {
        toggleIsProcessing(false);
      }
    }
  };

  const handleChangeRadio = (variant: string) => {
    setCurrentVariant(() => variant);
  };

  useEffect(() => {
    if (isSubscription) {
      paymentsStore.getPaymentMethods().then((data) => {
        setSavedCards(() => data.paymentMethods);
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} className={s.checkoutForm}>
      <Stack w="100%">
        <div className={s.checkoutFormCardWrapper}>
          <CreditCardFields
            handleCardNumberChange={handleCardNumberChange}
            handleExpDateChange={handleExpDateChange}
            handleCvcNumberChange={handleCvcNumberChange}
          />
        </div>
        {Boolean(savedCards.length) && (
          <div className={s.checkoutFormSavedCardsWrapper}>
            <Radio.Group onChange={handleChangeRadio}>
              {savedCards.map((card) => {
                return <SavedCardVariant key={card.id} name={RADIO_NAME} {...card} />;
              })}
            </Radio.Group>
          </div>
        )}
        <Button
          disabled={isProceedButtonDisabled()}
          type="submit"
          size="sm"
          className={s.checkoutFormButton}
        >
          {t('modals.stripe-modal.proceed')}
        </Button>
      </Stack>
    </form>
  );
});
