import type { Target, TargetAndTransition } from 'framer-motion';

export const initialAnimationOverlay: Target = {
  opacity: 0,
  visibility: 'hidden',
  backdropFilter: 'blur(0px)',
};

export const animateOverlay: TargetAndTransition = {
  opacity: 1,
  visibility: 'visible',
  backdropFilter: 'blur(4px)',
  transition: {
    duration: 0.2,
  },
};

export const exitOverlay: TargetAndTransition = {
  opacity: 0,
  visibility: 'hidden',
};

export const initialAnimationInner: Target = {
  opacity: 0,
  transform: 'scale(0.3)',
  visibility: 'hidden',
};

export const animateInner: TargetAndTransition = {
  opacity: 1,
  transform: 'scale(1)',
  visibility: 'visible',
  transition: {
    duration: 0.5,
  },
};

export const exitInner: TargetAndTransition = {
  opacity: 0,
  transform: 'scale(0)',
  visibility: 'hidden',
};
