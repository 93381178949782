import type { Ref } from 'react';

export const setupMenuItemRef = (node: HTMLLIElement | null, innerRef?: Ref<HTMLLIElement>) => {
  if (node) {
    if (typeof innerRef === 'function') {
      innerRef(node);
      return;
    }
    if (innerRef?.current) {
      (innerRef as any).current = node;
    }
  }
};
