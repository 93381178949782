import { useLayoutEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

function createWrapperAndAppendToBody(wrapperId: string, className?: string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  if (className) {
    wrapperElement.className = className;
  }
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

export type PortalProps = {
  children: ReactNode;
  wrapperId?: string;
  className?: string;
};

export const Portal = observer(function Portal(props: PortalProps) {
  const { children, wrapperId = 'react-portal-wrapper', className } = props;

  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId, className);
    }

    setWrapperElement(element);

    return () => {
      if (systemCreated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
});
