import type { EmojiDetails } from '../emoji-picker';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useModalState } from '@hooks';
import { useTranslation } from 'react-i18next';

import { observer } from '@shared/libs/mobx';

import { Button, Dropdown, Group, SvgIcon } from '@shared/UI';

import { useMediaQuery } from '@shared/hooks';
import { mergeComponentsWithName, randomUuid } from '@shared/utils';

import s from './styles.module.scss';

const popularReactions = ['❤️', '👍', '😊', '😂', '🥰', '😭'];

interface EmojiReactionPickerProps {
  messageId: string;
  onCloseContextMenu: (value: boolean) => void;
  onSelect?: (details: EmojiDetails | string, id: string) => void;
}

const _EmojiReactionPicker = observer((props: EmojiReactionPickerProps) => {
  const { onSelect, messageId, onCloseContextMenu } = props;

  const { isOpen, onToggle } = useModalState();

  const { i18n } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 768px)');

  const locale = i18n.language === 'ua' ? 'uk' : 'en';

  const handleOnSelect = (details: EmojiDetails) => {
    onSelect?.(details, messageId);
    onToggle();
    onCloseContextMenu(false);
  };

  const handleReactionClick = (reaction: string) => {
    onSelect?.(reaction, messageId);
    onCloseContextMenu(false);
  };

  const EmojiPicker = (
    <Picker
      data={data}
      navPosition="none"
      previewPosition="none"
      emojiSize={24}
      locale={locale}
      perLine={isMobile ? 6 : 8}
      onEmojiSelect={handleOnSelect}
      theme="dark"
    />
  );

  return (
    <Dropdown isOpen={isOpen} content={EmojiPicker} className={s.emojiReactionPicker}>
      <Group justify="space-between" w="100%" className={s.emojiReactionPickerMenuItem}>
        {popularReactions.map((reaction) => (
          <span key={randomUuid()} onClick={() => handleReactionClick(reaction)}>
            {reaction}
          </span>
        ))}
        <Button
          variant="outline"
          disabledAnimation
          size="icon"
          onClick={onToggle}
          className={s.emojiReactionPickerTriggerButton}
        >
          <SvgIcon type="arrow" />
        </Button>
      </Group>
    </Dropdown>
  );
});

export const EmojiReactionPicker = mergeComponentsWithName(
  '_EmojiReactionPicker',
  _EmojiReactionPicker,
);
