const BLOCKS = {
  ROOT: 'block',

  DASHBOARD_V2: 'dashboard-v2',
  NOTIFICATION: 'notification',
  COURSE_LANDING: 'courses/:courseId',
  COURSE_VIEW: 'courses/groups/:groupId',
  MY_COURSES: 'my-workspace',
  HOMEWORK: 'my-workspace/homeworks',
  HOMEWORK_BY_ID: 'my-workspace/homeworks/:homeworkId',
  ONBOARD: 'onboard',
  CALENDAR: 'calendar',
  COMMUNITIES: 'communities',
  COMMUNITY_PREVIEW: 'business-account/:businessAccountId/dashboard',
  STREAMS: 'streams/:streamId',
} as const;

const COURSES = {
  ROOT: 'courses',

  COMMENTS: ':courseId/lessons/:lessonId/comments',
  MARK_LESSON_VIDEO_TIME: ':courseId/lessons/:lessonId/mark-lesson-video-time',
  CREATE_COURSE_TRIAL: ':courseId/trials',
} as const;

const MY_HOMEWORK = {
  ROOT: 'homework',

  COMPLETE_TEST_HOMEWORK: ':homeworkId/test/:testId/complete',
} as const;

const USERS = {
  ROOT: 'users',

  ME: 'me',

  ADD_EMAIL: {
    FIRST_STEP: 'add-email/first-step',
    SECOND_STEP: 'add-email/second-step',
  },
  ADD_PHONE: {
    FIRST_STEP: 'add-phone/first-step',
    SECOND_STEP: 'add-phone/second-step',
  },
  CHANGE_PASSWORD: {
    FIRST_STEP: 'change-password/first-step',
    SECOND_STEP: 'change-password/second-step',
  },
} as const;

const AUTH = {
  ROOT: 'auth',
  SIGN_UP: 'sign-up',
  SIGN_IN: 'sign-in',
  SIGN_IN_WITH_GOOGLE: 'sign-in-with-google',
  SIGN_IN_WITH_APPLE: 'sign-in-with-apple',
  FORGOT_PASSWORD_FIRST_STEP: 'forgot-password/first-step',
  FORGOT_PASSWORD_SECOND_STEP: 'forgot-password/second-step',
  VERIFY: 'verify',
  LOGOUT: 'logout',
} as const;

const MEDIA = {
  ROOT: 'attachment',

  COMPLETE_WRITING_HOMEWORK: 'lms/homework/:homeworkId/writing/:writingId/complete',

  USER_IMAGE: 'lms/images/user',

  UPLOAD_CHANNEL_ATTACHMENTS: 'community/message',

  COMMUNITY_CHANNEL_MEDIA: 'community/category',

  COMMUNITY_CHANNEL_UPDATE: 'community/category/:channelId',

  UPLOAD_PRIVATE_CHAT_ATTACHMENTS: 'private-chat/message',
};

const ON_BOARD = {
  ROOT: 'onboard',
  UPD_INTERESTS: 'user',
} as const;

const CALENDAR = {
  ROOT: 'calendar',
  SUBSCRIBE_TO_EVENT: ':id/subscribe',
  UNSUBSCRIBE_TO_EVENT: ':id/unsubscribe',
} as const;

const GROUP = {
  ROOT: 'group',
  MARK_TIMECODE: ':groupId/video-timecode/:videoTimecodeId/mark',
} as const;

const PRICE = {
  ROOT: 'price',
  FREE_COMMUNITY: 'community/:id/buy',
} as const;

const STRIPE = {
  ROOT: 'stripe',
  CONFIG: 'config',
  CREATE_SUBSCRIPTION: 'create-subscription',
  CREATE_PAYMENT_INTENT: 'create-payment-intent',
  CREATE_PAYMENT_METHOD: 'payment-methods',
  GET_PAYMENT_METHODS: 'payment-methods',
  REMOVE_PAYMENT_METHOD: 'detach-payment-method',
  UNSUBSCRIBE: 'unsubscribe',
} as const;

const NOTIFICATION = {
  ROOT: 'socket',
  READ_ALL: 'read/all',
  READ_BY_ID: 'read/:notificationId',
} as const;

export const PATH_COLLECTION = {
  BLOCKS,
  USERS,
  AUTH,
  MEDIA,
  ON_BOARD,
  MY_HOMEWORK,
  CALENDAR,
  COURSES,
  GROUP,
  PRICE,
  STRIPE,
  NOTIFICATION,
} as const;
