import { Checkbox } from './checkbox';
import { FileUploader } from './file-uploader';
import { Input } from './input';
import { OtpCode } from './otp-code';
import { Password } from './password';
import { Phone } from './phone';

export const FormFields = {
  Phone,
  Input,
  Checkbox,
  Password,
  FileUploader,
  OtpCode,
};
