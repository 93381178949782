import { useEffect, useRef } from 'react';

export function useLatestCallback<T extends AnyFunction>(callback?: T): T {
  const ref = useRef<T | undefined>(callback);

  const latestCallback = useRef(function latestCallback(this: unknown, ...args: unknown[]) {
    return ref?.current?.apply(this, args);
  } as unknown as T).current;

  useEffect(() => {
    ref.current = callback;
  }, []);

  return latestCallback;
}
