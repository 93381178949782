import type { MouseEvent } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { useRootStore } from '@core/store';

import './styles.scss';

export type RouterLinkProps = {
  withLoading?: boolean;
  isNeedToOpenInNewTab?: boolean;
  className?: string;
  classNameActive?: string;
  classNamePending?: string;
} & Omit<NavLinkProps, 'target' | 'className'>;

export const RouterLink = observer(function RouterLink(props: RouterLinkProps) {
  const {
    className,
    classNamePending,
    classNameActive,
    isNeedToOpenInNewTab,
    onClick,
    withLoading,
    ...restProps
  } = props;

  const { appLoaderStore } = useRootStore();

  const getClassName = (params: { isActive: boolean; isPending: boolean }) => {
    const { isActive, isPending } = params;
    return cl(
      'router-link',
      {
        ['router-link-active']: isActive,
        ['router-link-pending']: isPending,
      },
      {
        [classNameActive || '']: isActive,
      },
      {
        [classNamePending || '']: isPending,
      },

      className,
    );
  };

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (withLoading) {
      appLoaderStore.startLoading();

      setTimeout(appLoaderStore.stopLoading, 300);
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <NavLink
      className={getClassName}
      target={isNeedToOpenInNewTab ? '_blank' : '_self'}
      onClick={handleClick}
      {...restProps}
    />
  );
});
