import { useCallback } from 'react';

import { useQuickEmojiController } from './quick-emoji.controller';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { observer } from '@shared/libs/mobx';

import { Button, Dropdown, Each, Group, SvgIcon, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const quickEmojiList = ['❤️', '👍', '😊', '😂', '🥰', '😭'];

export interface QuickEmojiProps {
  onSelectEmoji?: (emoji: string) => void;
}

const _QuickEmoji = observer((props: QuickEmojiProps) => {
  const { onSelectEmoji } = props;

  const { locale, perLine, isOpen, onToggle, onClose } = useQuickEmojiController();

  const handleSelectEmoji = useCallback(
    (details: { native: string }) => {
      onSelectEmoji?.(details.native);
      onClose();
    },
    [onSelectEmoji],
  );

  const onClickQuickEmoji = useCallback(
    (emoji: string) => {
      onSelectEmoji?.(emoji);
      onClose();
    },
    [onSelectEmoji],
  );

  return (
    <Dropdown
      isOpen={isOpen}
      className={s.quickEmoji}
      content={
        <Picker
          data={data}
          navPosition="none"
          previewPosition="none"
          emojiSize={24}
          locale={locale}
          perLine={perLine}
          onEmojiSelect={handleSelectEmoji}
          theme="dark"
        />
      }
    >
      <Group justify="space-between" w="100%" className={s.quickEmojiList}>
        <Each data={quickEmojiList}>
          {(emoji) => (
            <TypographyNew
              as="span"
              variant="body-1"
              key={emoji}
              onClick={() => onClickQuickEmoji(emoji)}
            >
              {emoji}
            </TypographyNew>
          )}
        </Each>

        <Button
          variant="outline"
          disabledAnimation
          size="icon"
          onClick={onToggle}
          className={s.quickEmojiBtn}
        >
          <SvgIcon type="arrow" />
        </Button>
      </Group>
    </Dropdown>
  );
});

export const QuickEmoji = mergeComponentsWithName('QuickEmoji', _QuickEmoji);
