import { RouterLink } from '@components';
import { routerCollection } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import type { CourseCardProps } from '@units';
import { CourseCard } from '@units';
import get from 'lodash.get';

import { observer } from '@shared/libs/mobx';

import type { CarouselProps } from '@shared/UI';
import { Carousel } from '@shared/UI';

import type { NestedDotPaths } from '@shared/types';
import { createStandardDateFormat, mergeComponentsWithName, withMediaUrl } from '@shared/utils';

type DataKeys<T extends Record<string, any>> = Partial<
  Record<
    | keyof Pick<
        CourseCardProps,
        'title' | 'image' | 'companyName' | 'progress' | 'isLocked' | 'isPurchased' | 'groupId'
      >
    | 'startDate'
    | 'endDate'
    | 'key',
    NestedDotPaths<T>
  >
>;

export interface CourseCarouselProps<T extends Record<string, any>> extends CarouselProps {
  courses: T[];
  dataKeys?: DataKeys<T>;
  dateTransform?: (course: T) => string;
  onClickCourse?: (course: T) => void;
}

const _CourseCarousel = observer(<T extends Record<string, any>>(props: CourseCarouselProps<T>) => {
  const { courses, dataKeys, dateTransform, onClickCourse, ...restProps } = props;

  const sendEvent = useAnalyticsContext();

  const _dataKeys = {
    title: 'title',
    image: 'image',
    companyName: 'companyName',
    progress: 'progress',
    isLocked: 'isLocked',
    isPurchased: 'isPurchased',
    startDate: 'startDate',
    endDate: 'endDate',
    key: 'id',
    groupId: 'groupId',
    ...dataKeys,
  };

  const courseMapper = courses.map((course, idx) => {
    const image = get(course, _dataKeys.image, '');
    const title = get(course, _dataKeys.title, '');
    const key = get(course, _dataKeys?.key || '', idx);
    const startDate = get(course, _dataKeys?.startDate, undefined);
    const endDate = get(course, _dataKeys?.endDate, undefined);
    const progress = get(course, _dataKeys?.progress, undefined);
    const companyName = get(course, _dataKeys?.companyName, undefined);
    const isPurchased = get(course, _dataKeys?.isPurchased, undefined);
    const groupId = get(course, _dataKeys?.groupId, undefined);

    let date: string | null = null;

    if (startDate && endDate) {
      date = dateTransform
        ? dateTransform(course)
        : `${createStandardDateFormat(startDate)} - ${createStandardDateFormat(endDate)}`;
    }

    const courseLink = isPurchased
      ? `${routerCollection.myWorkspace.courses}/${groupId}`
      : `${routerCollection.community.root}/course/${key}`;

    const courseClickHandler = () => {
      onClickCourse?.(course);

      if (!isPurchased) {
        sendEvent({
          event: AnalyticsEvents.CourseInformationTap,
          payload: { course_name: title, course_id: key },
        });
      }
    };

    return (
      <Carousel.Slide key={key} onClick={courseClickHandler}>
        <RouterLink to={courseLink}>
          <CourseCard
            image={withMediaUrl(image)}
            title={title}
            date={date}
            progress={progress}
            companyName={companyName}
          />
        </RouterLink>
      </Carousel.Slide>
    );
  });

  return <Carousel {...restProps}>{courseMapper}</Carousel>;
});

export const CourseCarousel = mergeComponentsWithName('CourseCarousel', _CourseCarousel);
