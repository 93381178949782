export const CommunityChannelStatus = {
  Active: 'ACTIVE',
  Closed: 'CLOSED',
} as const;

export const CommunityChatStatus = {
  Active: 'ACTIVE',
  Closed: 'CLOSED',
} as const;

export const CommunityChatType = {
  Chat: 'CHAT',
  Channel: 'CHANNEL',
  VoiceChannel: 'VOICE_CHANNEL',
} as const;

export const CommunityMentionType = {
  User: 'user',
} as const;

export const CommunityChannelType = {
  Free: 'FREE',
  Paid: 'PAID',
};
