import { cloneElement } from 'react';
import type { SVGProps } from 'react';

import { observer } from 'mobx-react-lite';

import type { ObjectKeys } from '@shared/types';

import { svgIconCollection } from './svg-icon-collection';

export type SvgIconProps = {
  type: ObjectKeys<typeof svgIconCollection>;
} & SVGProps<SVGSVGElement>;

export const SvgIcon = observer(function SvgIcon(props: SvgIconProps) {
  const { type, ...restProps } = props;

  return cloneElement(svgIconCollection[type], { ...restProps });
});
