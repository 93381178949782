import type { CSSProperties, ReactElement, ReactNode, Ref } from 'react';
import { Children, cloneElement } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { observer } from '@shared/libs/mobx';

import { ClassGapCollection } from '@shared/constants';
import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass } = classNames('group');

const group = cva(rootClass, {
  variants: {
    gap: ClassGapCollection(rootClass),
  },
  defaultVariants: {
    gap: 'md',
  },
});

export interface GroupProps extends ElementProps<'div', 'style'>, VariantProps<typeof group> {
  children: ReactNode;

  style?: Omit<CSSProperties, 'alignItems' | 'justifyContent' | 'gap' | 'wrap' | 'flexGrow'>;

  grow?: boolean;

  justify?: CSSProperties['justifyContent'];

  align?: CSSProperties['alignItems'];

  w?: CSSProperties['width'];

  h?: CSSProperties['height'];

  wrap?: CSSProperties['flexWrap'];

  innerRef?: Ref<HTMLDivElement>;

  overflow?: CSSProperties['overflow'];
}

const _Group = observer((props: GroupProps) => {
  const {
    children,
    className,
    style,
    grow = false,
    justify = 'flex-start',
    align = 'center',
    gap,
    wrap = 'wrap',
    w,
    h,
    innerRef,
    overflow,
    ...restProps
  } = props;

  const rootClasses = group({ gap, className });

  const rootStyles = {
    ...style,
    '--ui-group-justify': justify,
    '--ui-group-align': align,
    '--ui-group-wrap': wrap,
    width: w,
    height: h,
    overflow,
  };

  return (
    <div ref={innerRef} className={rootClasses} style={rootStyles} {...restProps}>
      {Children.map(children, (_child) => {
        const child = _child as ReactElement;
        if (typeof child !== 'string' && child?.props) {
          return cloneElement(child, {
            ...child.props,
            'data-grow': grow,
          });
        }
        return child;
      })}
    </div>
  );
});

export const Group = mergeComponentsWithName('Group', _Group);
