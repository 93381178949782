import { RemoteImage } from '@components';
import { observer } from 'mobx-react-lite';

import { Avatar, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

export type TitleProps = {
  imageSrc: string;
  ownerName: string;
  title: string;
};

export const Title = observer(function Title(props: TitleProps) {
  const { imageSrc, ownerName, title } = props;

  return (
    <div className={s.subscriptionTitle}>
      <div className={s.subscriptionTitleLeft}>
        <Avatar shape="square" size="lg" icon={<RemoteImage src={imageSrc} />} />
      </div>
      <div className={s.subscriptionTitleRight}>
        <TypographyNew as="span" variant="body-2" weight="extra-bold">
          {ownerName}
        </TypographyNew>
        <TypographyNew as="span" variant="body-4">
          {title}
        </TypographyNew>
      </div>
    </div>
  );
});
