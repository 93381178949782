import { sessionStorageService } from '@services/storage-service';
import { makeAutoObservable } from 'mobx';

export class AppLoaderStore {
  isVisibleWelcome = false;

  isVisibleLoader = false;

  welcomeWasShown = false;

  constructor() {
    this.welcomeWasShown = sessionStorageService.getItem('isWelcomeScreenShown') ? true : false;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  canShowWelcomeLoader(callback?: () => void) {
    const isShown = sessionStorageService.getItem('isWelcomeScreenShown');

    if (!isShown) {
      callback?.();
      sessionStorageService.setItem('isWelcomeScreenShown', { value: true });
      return true;
    }
    return false;
  }

  setIsVisibleLoader = (value: boolean) => {
    this.isVisibleLoader = value;
  };

  setIsVisibleWelcome = (value: boolean) => {
    this.isVisibleWelcome = value;
  };

  startLoading = () => {
    this.setIsVisibleLoader(true);
  };

  stopLoading = () => {
    this.setIsVisibleLoader(false);
  };
}
