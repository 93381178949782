import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface MessageImagePreviewProps {
  srcUrl: string;
}

const _MessageImagePreview = observer((props: MessageImagePreviewProps) => {
  const { srcUrl } = props;

  return (
    <div className={s.messageImagePreview}>
      <img src={srcUrl} loading="lazy" />
    </div>
  );
});

export const MessageImagePreview = mergeComponentsWithName(
  'MessageImagePreview',
  _MessageImagePreview,
);
