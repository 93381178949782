import { useMemo } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import type { HomeworkTestOptionEntity } from '@core/repositories';

import { Checkbox, SvgIcon } from '@shared/UI';

import { asHtml } from '@shared/utils';

import s from './styles.module.scss';

export interface HomeworkCheckboxProps {
  option: HomeworkTestOptionEntity;

  isChecked: boolean;
}

export const HomeworkCheckbox = observer(function HomeworkCheckbox(props: HomeworkCheckboxProps) {
  const { option, isChecked } = props;

  const isIncorrectChecked = option.isSelectedAnswer && !option.isCorrectAnswer;

  const isCorrectChecked = option.isSelectedAnswer && option.isCorrectAnswer;

  const correctAnswer = option.isCorrectAnswer;

  const indicator = useMemo(() => {
    if (isIncorrectChecked) {
      return <SvgIcon className={s.homeworkCheckboxIndicator} type="red-cross" />;
    }
    if (isCorrectChecked || isChecked || correctAnswer) {
      return <SvgIcon className={s.homeworkCheckboxIndicator} type="green-checkmark" />;
    }

    return null;
  }, [isIncorrectChecked, isCorrectChecked, isChecked, correctAnswer]);

  const rootClasses = cl(s.homeworkCheckbox, {
    [s.homeworkRadioIncorrect]: isIncorrectChecked,
    [s.homeworkRadioCorrect]: isCorrectChecked || isChecked,
  });

  return (
    <Checkbox className={rootClasses} value={String(option.id)} indicator={indicator} size="xl">
      {asHtml(option.title)}
    </Checkbox>
  );
});
