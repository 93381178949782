import { ONLY_FIRST_LINK_PATTERN } from '@constants';
import { makeAutoObservable, runInAction } from 'mobx';

import { MessageViewType } from '@core/constants';
import type { PrivateChatMessageReaction, ReplyMessageInfoEntity } from '@core/repositories';

import type { ObjectValues } from '@shared/types';

export type EditMessageDetails = {
  messageId: string;
  chatId: string;
  messageText: string;
  attachments: string[];
};

interface IMessageStore {
  id: string;

  message: string;

  type: ObjectValues<typeof MessageViewType>;

  authorId: number;

  authorName: string;

  createDate: string;

  attachments: string[];

  chatId: string;

  isEdited: boolean;

  isPinned: boolean;

  isViewed: boolean;

  reactions: PrivateChatMessageReaction;

  viewCount: number;

  viewerIds: number[];

  isMessageFromViewer: boolean;

  replyMessageInfo: ReplyMessageInfoEntity;

  ownerImage: string;
}

export interface IMessageStoreOptions extends Omit<IMessageStore, 'ownerImage'> {
  ownerImage?: string;
  previewLink?: string;
}

export class MessageStore implements IMessageStore {
  id: string;

  message: string;

  type: ObjectValues<typeof MessageViewType>;

  authorId: number;

  authorName: string;

  createDate: string;

  attachments: string[];

  chatId: string;

  isEdited: boolean;

  isPinned: boolean;

  isViewed: boolean;

  reactions: PrivateChatMessageReaction;

  viewCount: number;

  viewerIds: number[];

  isMessageFromViewer: boolean;

  replyMessageInfo: ReplyMessageInfoEntity;

  ownerImage: string;

  previewLink: string;

  constructor(
    {
      id,
      message,
      type,
      authorId,
      authorName,
      chatId,
      createDate,
      isEdited,
      isPinned,
      isViewed,
      viewCount,
      viewerIds,
      reactions,
      attachments,
      isMessageFromViewer,
      replyMessageInfo,
      ownerImage,
    }: IMessageStoreOptions,
    private viewMessages?: (messageId: string, chatId: string) => void,
    private editMessageAction?: (details: {
      messageId: string;
      chatId: string;
      messageText: string;
      attachments: string[];
    }) => void,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.id = id;
    this.message = message;
    this.type = type;
    this.authorId = authorId;
    this.authorName = authorName;
    this.chatId = chatId;
    this.createDate = createDate;
    this.isEdited = isEdited;
    this.isPinned = isPinned;
    this.isViewed = isViewed;
    this.viewCount = viewCount;
    this.viewerIds = viewerIds;
    this.reactions = reactions;
    this.attachments = attachments;
    this.isMessageFromViewer = isMessageFromViewer;
    this.replyMessageInfo = replyMessageInfo;
    this.ownerImage = ownerImage ?? '';

    const match = this.message.match(ONLY_FIRST_LINK_PATTERN);
    this.previewLink = match?.[0] ?? '';
  }

  get isMessageOnlyText() {
    return this.type === MessageViewType.Text && this.attachments.length === 0;
  }

  get isMessageWithFiles() {
    return this.type === MessageViewType.Text && this.attachments.length > 0;
  }

  get isMessageWithAudio() {
    return this.type === MessageViewType.Audio && this.attachments.length > 0;
  }

  editMessage = (messageText: string) => {
    runInAction(() => {
      this.message = messageText;
    });
    this.editMessageAction?.({
      messageText,
      messageId: this.id,
      chatId: this.chatId,
      attachments: this.attachments,
    });
  };

  viewMessage = () => {
    if (!this.isMessageFromViewer && !this.isViewed) {
      runInAction(() => {
        this.isViewed = true;
      });

      this?.viewMessages?.(this.id, this.chatId);
    }
  };
}
