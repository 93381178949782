import { useMemo } from 'react';

import { PaymentResultModal } from '../payment-result-modal';
import { StripeModal } from '../stripe-modal';
import type { BaseModalProps } from '@components';
import { modalStore } from '@components';
import { PaymentResults, ProductTypes } from '@constants';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import type { CommunityPreviewPrice } from '@core/repositories';
import { useRootStore } from '@core/store';

import type { RenderForItem } from '@shared/UI';
import { Badge, Box, Button, For, Group, Modal, Stack, SvgIcon, TypographyNew } from '@shared/UI';

import type { ObjectValues } from '@shared/types';
import { randomUuid } from '@shared/utils';

import s from './styles.module.scss';

const getTrialDuration = (trialDayDuration: number) => {
  if (trialDayDuration && trialDayDuration > 0) {
    return `${trialDayDuration} ${trialDayDuration > 1 ? 'days' : 'day'} free trial`;
  }
  return '';
};

const getStartDateAfterTrial = (trialDayDuration: number, t: TFunction) => {
  if (trialDayDuration && trialDayDuration > 0) {
    const date = dayjs(new Date()).add(trialDayDuration, 'day').format('MMMM DD, YYYY');
    return `${t('shared-content.starts-on')} ${date}`;
  }
  return '';
};

interface PayPlanModalProps extends BaseModalProps {
  price: CommunityPreviewPrice;
  subscriptionTitle: string;
  isTrial: boolean;
  productType: ObjectValues<typeof ProductTypes>;
  courseId?: number;
}

export const PayPlanModal = observer(function PayPlanModal(props: PayPlanModalProps) {
  const { price, subscriptionTitle, isTrial, productType, courseId, ...restProps } = props;

  const { t } = useTranslation();

  const { paymentsStore } = useRootStore();

  const getStripe = async () => {
    try {
      const { publishableKey } = await paymentsStore.getPaymentConfig();
      const stripe = await loadStripe(publishableKey);
      return stripe;
    } catch (e) {
      throw e;
    }
  };

  const secretCommunityFlow = async () => {
    if (!price.isSubscription) {
      const data = await paymentsStore.createPaymentIntent({ priceCommunityId: price.id });

      return data.clientSecret;
    }

    return null;
  };

  const showModalResult = (result: string) => {
    modalStore.activateModal({
      component: (
        <PaymentResultModal
          resultType={result}
          subscriptionTitle={subscriptionTitle}
          price={price.price}
          courseId={courseId}
          isNeedToUnmount
        />
      ),
    });
  };

  const secretCourseFlow = async () => {
    if (isTrial) {
      try {
        courseId && (await paymentsStore.enableCourseTrial(courseId, price.id));
        showModalResult(PaymentResults.Success);
      } catch (e) {
        showModalResult(PaymentResults.Error);
      }

      return null;
    }

    const data = await paymentsStore.createPaymentIntent({ priceCourseId: price.id });
    return data.clientSecret;
  };

  const closeModalHandler = () => {
    modalStore.deactivateModal('pay-plan-modal');
  };

  const getSecret = async () => {
    try {
      if (productType === ProductTypes.Community) {
        return await secretCommunityFlow();
      }

      if (productType === ProductTypes.Course) {
        return await secretCourseFlow();
      }

      return null;
    } catch (e) {
      throw e;
    }
  };

  const handleClickPayment = async () => {
    try {
      const stripe = await getStripe();
      const clientSecret = await getSecret();

      if (productType === ProductTypes.Course && isTrial) return;

      modalStore.activateModal({
        component: (
          <StripeModal
            price={price}
            subscriptionTitle={subscriptionTitle}
            productType={productType}
            stripe={stripe}
            clientSecret={clientSecret}
            isTrial={isTrial}
            courseId={courseId}
            isNeedToUnmount
          />
        ),
      });

      modalStore.deactivateModal('pay-plan-modal');
    } catch (e) {
      throw e;
    }
  };

  const advantagesList = useMemo(
    () =>
      price.priceList.map((label) => {
        const key = randomUuid();

        return {
          label,
          key,
        };
      }),
    [price.priceList],
  );

  const renderAdvantages: RenderForItem<(typeof advantagesList)[0]> = (advantage) => {
    return (
      <li key={advantage.key}>
        <TypographyNew as="span" variant="body-4" colorSchema="neutral-200">
          {advantage.label}
        </TypographyNew>
      </li>
    );
  };

  return (
    <Modal centered id="pay-plan-modal" size="md" {...restProps}>
      <Stack align="flex-start" className={s.payPlanModal}>
        <TypographyNew variant="title-3" weight="bold">
          {t('modals.payments.you-selected')}:
        </TypographyNew>
        <Group justify="space-between" w="100%">
          <TypographyNew variant="title-4" weight="bold" className={s.payPlanModalTariff}>
            {subscriptionTitle}
          </TypographyNew>
          <Badge variant="purple" label={price.title} />
        </Group>
        <TypographyNew variant="title-4" weight="bold">
          {t('modals.payments.you-will-get')}:
        </TypographyNew>
        <Box as="ul" className={s.payPlanModalBenefits}>
          <For data={advantagesList} render={renderAdvantages} />
        </Box>

        {!isTrial && (
          <>
            <Group justify="space-between" w="100%">
              <TypographyNew variant="body-2" weight="bold">
                {t('modals.payments.total-amount')}
              </TypographyNew>
              <TypographyNew variant="title-2" weight="bold">
                {price.price}$
              </TypographyNew>
            </Group>
            <Button
              variant="dark-gray"
              size="xs"
              onClick={handleClickPayment}
              suffixIcon={
                <Group gap="md">
                  <SvgIcon type="mastercard-gray" />
                  <SvgIcon type="visa-gray" />
                </Group>
              }
            >
              <TypographyNew variant="body-3">
                {t('modals.payments.methods.payment-card')}
              </TypographyNew>
            </Button>
          </>
        )}

        {isTrial && (
          <>
            <Box w="100%">
              <Group justify="space-between" w="100%" wrap="nowrap">
                <TypographyNew variant="body-2" className={s.payPlanModalTrialInfoText}>
                  {getTrialDuration(price.trialDayDuration)}
                </TypographyNew>
                <TypographyNew variant="body-2" className={s.payPlanModalTrialInfoText}>
                  {price.price}$ / {t('modals.payments.month')}
                </TypographyNew>
              </Group>
              <Group justify="space-between" w="100%" wrap="nowrap">
                <TypographyNew
                  variant="body-3"
                  colorSchema="neutral-200"
                  className={s.payPlanModalTrialInfoText}
                >
                  {t('modals.payments.starting-today')}
                </TypographyNew>
                <TypographyNew
                  variant="body-3"
                  colorSchema="neutral-200"
                  className={s.payPlanModalTrialInfoText}
                >
                  {getStartDateAfterTrial(price.trialDayDuration, t)}
                </TypographyNew>
              </Group>
            </Box>
            <TypographyNew variant="body-3" className={s.payPlanModalCancelDescription}>
              {t('modals.payments.trial-description')}
            </TypographyNew>
            <Button onClick={handleClickPayment}>{t('modals.payments.try-it-free')}</Button>
            <Button variant="outline" onClick={closeModalHandler}>
              {t('modals.payments.no-thanks')}
            </Button>
          </>
        )}
      </Stack>
    </Modal>
  );
});
