import { ProfileContent } from './profile-content';
import { useModalState } from '@hooks';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { Avatar, Dropdown } from '@shared/UI';

import s from './styles.module.scss';

export const ProfileDropdown = observer(function ProfileDropdown() {
  const { isOpen, onToggle } = useModalState();

  const { userStore } = useRootStore();

  return (
    <Dropdown
      className={s.profileDropdown}
      isOpen={isOpen}
      content={<ProfileContent />}
      onOpenChange={onToggle}
      offsetValue={18}
    >
      <Avatar icon={<img src={userStore.avatar} alt="user avatar" />} />
    </Dropdown>
  );
});
