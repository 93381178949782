import type { ChangeEvent } from 'react';
import { useMemo } from 'react';

import type { EmailFormSchema } from '../../_schemas';
import { emailFormSchema } from '../../_schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import type { RegisterOptions, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useRootStore } from '@core/store';

import { useToggle } from '@shared/hooks';
import { transformOtp } from '@shared/utils';

const defaultValues: EmailFormSchema = {
  email: '',
  password: '',
  firstOtp: '',
};
export const useViewController = () => {
  const { t } = useTranslation();

  const { userStore } = useRootStore();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    reset,
  } = useForm<EmailFormSchema>({
    defaultValues,
    resolver: zodResolver(emailFormSchema),
  });

  const [isOtpVisible, toggleIsOtpVisible] = useToggle();
  const [isOtpValid, toggleIsOtpValid] = useToggle();

  const handleFirstStep = async (email: string) => {
    try {
      await userStore.firstStepAddEmail({ email });
      toggleIsOtpVisible(true);
    } catch (e) {}
  };

  const handleSecondStep = async (dto: Required<EmailFormSchema>) => {
    try {
      await userStore.secondStepAddEmail({ ...dto, firstOtp: transformOtp(dto.firstOtp) });
      toggleIsOtpVisible(false);
      reset(defaultValues);
      toast.success(t('my-account.personal-information.successfully-changed'));
    } catch (e) {}
  };

  const onSubmit: SubmitHandler<EmailFormSchema> = async (dto) => {
    if (dto.firstOtp) {
      await handleSecondStep({ ...dto, firstOtp: dto.firstOtp });
      return;
    }
    await handleFirstStep(dto.email);
  };

  const handleChangeOtp = (e: ChangeEvent<HTMLInputElement>) => {
    const otp = e.target.value;

    if (otp.length === 9 && !isOtpValid) {
      toggleIsOtpValid(true);
    }

    if ((!otp || otp.length < 9) && isOtpValid) {
      toggleIsOtpValid(false);
    }
  };

  const handleChangeFields = () => {
    if (isOtpVisible) {
      toggleIsOtpVisible(false);
      setValue('firstOtp', defaultValues.firstOtp);
    }
  };

  const otpRules: RegisterOptions = useMemo(
    () => ({
      onChange: handleChangeOtp,
    }),
    [handleChangeOtp],
  );

  const emailAndPassRules: RegisterOptions = useMemo(
    () => ({
      onChange: handleChangeFields,
    }),
    [handleChangeFields],
  );

  const isDisabledButton = !isDirty || userStore.loader.isLoading || (isOtpVisible && !isOtpValid);

  return {
    errors,
    control,
    isDisabledButton,
    onSubmit: handleSubmit(onSubmit),
    otpRules,
    emailAndPassRules,
    isOtpVisible,
  };
};
