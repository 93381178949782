import { LoadingStore } from '../common';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import type {
  CommunitySubscriptionEntity,
  CurrentUserEntity,
  FirstStepAddEmailDto,
  FirstStepAddPhoneDto,
  FirstStepChangePasswordDto,
  OnBoardItemEntity,
  PatchMeDto,
  SecondStepAddEmailDto,
  SecondStepAddPhoneDto,
  SecondStepChangePasswordDto,
  ViewerBusinnesAccount,
  ViewerPermissions,
  ViewerPermissionsEntity,
} from '@core/repositories';
import { UserRepository } from '@core/repositories';
import { getHttpError } from '@core/services/http';

import { createFormData, randomUuid, withMediaUrl } from '@shared/utils';

export class UserStore {
  user: CurrentUserEntity = {} as CurrentUserEntity;

  communitySubscriptions: CommunitySubscriptionEntity[] = [];

  onboards: OnBoardItemEntity[] = [];

  permissions: ViewerPermissionsEntity = createDefaultPermissions();

  repository = new UserRepository();

  loader = new LoadingStore();

  businessAccount: ViewerBusinnesAccount | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get hasSubscriptions() {
    return this.communitySubscriptions.length > 0;
  }

  get hasPhone() {
    return !!this.user.phoneNumber;
  }

  get hasEmail() {
    return !!this.user.email;
  }

  get hasName() {
    return !!this.user.name;
  }

  get hasInterests() {
    return this.onboards.length > 0;
  }

  get businessId() {
    return this.businessAccount?.id || null;
  }

  get avatar() {
    if (this.user.image instanceof File) {
      return URL.createObjectURL(this.user.image);
    }

    if (this.user.image?.startsWith('blob:')) {
      return this.user.image;
    }

    return withMediaUrl(this.user.image, true) + `?cd=${randomUuid()}`;
  }

  getMe = async () => {
    this.loader.startLoading();
    try {
      const data = await this.repository.getMe();

      runInAction(() => {
        this.setUser(data.user);
        this.setSubscriptions(data.communitySubscriptions);
        this.setOnboards(data.onboards);

        if ('permissions' in data && data.permissions && Object.keys(data.permissions).length > 0) {
          this.permissions = data.permissions;
        }

        if ('businessAccount' in data && data.businessAccount) {
          this.businessAccount = data.businessAccount;
        }
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  patchMe = async (dto: PatchMeDto) => {
    this.loader.startLoading();
    try {
      const data = await this.repository.patchMe(dto);

      runInAction(() => {
        this.updateUser(dto);
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  firstStepAddPhone = async (dto: FirstStepAddPhoneDto) => {
    this.loader.startLoading();
    try {
      const data = await this.repository.firstStepAddPhone(dto);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  secondStepAddPhone = async (dto: FirstStepAddPhoneDto & SecondStepAddPhoneDto) => {
    this.loader.startLoading();
    try {
      const { firstOtp, phone } = dto;

      const data = await this.repository.secondStepAddPhone({ firstOtp });

      runInAction(() => {
        this.updateUser({ phoneNumber: phone });
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  firstStepAddEmail = async (dto: FirstStepAddEmailDto) => {
    this.loader.startLoading();
    try {
      const data = await this.repository.firstStepAddEmail(dto);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  secondStepAddEmail = async (dto: FirstStepAddEmailDto & SecondStepAddEmailDto) => {
    this.loader.startLoading();
    try {
      const { email, ...restDto } = dto;

      const data = await this.repository.secondStepAddEmail(restDto);

      runInAction(() => {
        this.updateUser({ email });
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  firstStepChangePassword = async (dto: FirstStepChangePasswordDto) => {
    this.loader.startLoading();
    try {
      const data = await this.repository.firstStepChangePassword(dto);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  secondStepChangePassword = async (dto: SecondStepChangePasswordDto) => {
    this.loader.startLoading();
    try {
      const data = await this.repository.secondStepChangePassword(dto);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  uploadAvatar = async (avatar: File, fileUrl: string) => {
    this.loader.startLoading();

    const formData = createFormData({ image: avatar });

    try {
      const data = await this.repository.uploadImage(formData);

      runInAction(() => {
        this.user.image = fileUrl;
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  setSubscriptions = (subscriptions: CommunitySubscriptionEntity[]) => {
    this.communitySubscriptions = subscriptions;
  };

  setOnboards = (onboards: OnBoardItemEntity[]) => {
    this.onboards = onboards;
  };

  setUser = (user: CurrentUserEntity) => {
    this.user = user;
  };

  updateUser = (user: Partial<CurrentUserEntity>) => {
    this.user = {
      ...this.user,
      ...user,
    };
  };

  disposer = () => {
    this.user = {} as CurrentUserEntity;
    this.setSubscriptions([]);
    this.setOnboards([]);
  };
}

function createDefaultPermissions(): ViewerPermissionsEntity {
  const permissions: ViewerPermissions = {
    create: false,
    read: false,
    update: false,
    delete: false,
  };

  const createPermissions = <T extends keyof ViewerPermissions | null = null>(
    ...omitKeys: T[]
  ): T extends string ? Omit<ViewerPermissions, T> : ViewerPermissions => {
    return Object.entries(permissions).reduce((acc: any, [k, v]) => {
      const _k = k as T;
      if (!omitKeys || !omitKeys.includes(_k)) {
        acc[_k] = v;
      }
      return acc;
    }, {}) as T extends string ? Omit<ViewerPermissions, T> : ViewerPermissions;
  };

  return {
    benefit: createPermissions(),
    businessAccount: createPermissions('create'),
    calendar: createPermissions(),
    category: createPermissions('delete', 'create', 'update'),
    communityCategory: createPermissions(),
    communityCommunicationItem: createPermissions(),
    course: createPermissions(),
    group: createPermissions(),
    communityMessage: createPermissions(),
    homework: createPermissions(),
    lessonComment: createPermissions('create', 'read', 'update'),
    notification: createPermissions(),
    price: createPermissions(),
    stream: createPermissions(),
    s3: createPermissions(),
    communityMessageMention: createPermissions(),
  };
}
