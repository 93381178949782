import { communityRouter } from './community';
import { dashboardRouter } from './dashboard';
import { eventsRouter } from './events';
import { myWorkspaceRouter } from './my-workspace';
import { privateChatsRouter } from './private-chats';
import { profileRouter } from './profile';
import { IsOnBoardedRoute, ProtectedRoute } from '@components';
import { routerCollection } from '@constants';
import { MainLayout } from '@layouts';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

export const contentRouter: RouteObject = {
  path: '/',
  element: (
    <ProtectedRoute>
      <IsOnBoardedRoute>
        <MainLayout />
      </IsOnBoardedRoute>
    </ProtectedRoute>
  ),
  children: [
    {
      index: true,
      element: <Navigate to={routerCollection.dashboard.root} replace />,
    },
    profileRouter,
    dashboardRouter,
    myWorkspaceRouter,
    eventsRouter,
    communityRouter,
    privateChatsRouter,
  ],
};
