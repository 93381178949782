import { useEffect } from 'react';

import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useRootStore } from '@core/store';

import { getEndOfYear, getStartOfDay } from '@shared/utils';

export const EntryPage = observer(function EntryPage() {
  const { i18n } = useTranslation();

  const { eventsStore, appLoaderStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const getEvents = async () => {
    appLoaderStore.startLoading();
    try {
      await eventsStore.getAll({
        dateFrom: getStartOfDay(),
        dateTo: getEndOfYear(),
        lang: i18n.language,
      });
    } catch (e) {
    } finally {
      appLoaderStore.stopLoading();
    }
  };

  useEffect(() => {
    getEvents();

    return () => eventsStore.disposer();
  }, [i18n.language]);

  useEffect(() => {
    sendEvent({ event: AnalyticsEvents.EventsPageView });
  }, []);

  return <Outlet />;
});
