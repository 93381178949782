import type { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import { TypographyNew } from '@shared/UI';

export type TitleProps = {
  children: ReactNode;
};

export const Title = observer(function Title(props: TitleProps) {
  const { children } = props;

  return (
    <TypographyNew as="h2" variant="title-2" weight="bold">
      {children}
    </TypographyNew>
  );
});
