import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { pdfjs } from 'react-pdf';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

export interface DocumentThumbnailProps {
  doc: File | Blob;

  loader?: ReactNode;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const _DocumentThumbnail = observer((props: DocumentThumbnailProps) => {
  const { doc, loader } = props;

  const [thumbnail, setThumbnailUrl] = useState<string | null>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadPdf = async () => {
      setIsLoading(true);
      const pdfBuffer = await doc.arrayBuffer();
      const pdfDocument = await pdfjs.getDocument({ data: pdfBuffer }).promise;

      const firstPage = await pdfDocument.getPage(1);
      const viewport = firstPage.getViewport({ scale: 0.2 });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context!,
        viewport: viewport,
      };

      await firstPage.render(renderContext).promise;

      const thumbnailDataUrl = canvas.toDataURL('image/jpeg');
      setThumbnailUrl(thumbnailDataUrl);
      setIsLoading(false);
    };

    loadPdf();
  }, [document]);

  if (isLoading && loader) {
    return loader;
  }

  return thumbnail ? (
    <img src={thumbnail} alt="File Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
  ) : null;
});

export const DocumentThumbnail = mergeComponentsWithName('DocumentThumbnail', _DocumentThumbnail);
