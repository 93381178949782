import { NavItem } from '@components';
import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';

import { List, SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

export const ListItems = observer(function ListItems() {
  const items = [
    {
      label: 'dashboard.title',
      to: routerCollection.dashboard.root,
      icon: <SvgIcon type="dashboard" />,
      withLoading: true,
    },

    {
      label: 'my-account.title',
      to: routerCollection.myAccount.root,
      icon: <SvgIcon type="account" />,
      withLoading: true,
    },
  ];

  return (
    <List
      data={items}
      itemKey={(item) => item.to}
      renderItem={(item) => (
        <NavItem
          to={item.to}
          label={item.label}
          icon={item.icon}
          classNameActive={s.listItemActive}
          labelClassName={s.listItemLabel}
        />
      )}
      className={s.list}
    />
  );
});
