import type { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import s from './styles.module.scss';

type HeaderProps = {
  title: ReactNode;
  subTitle?: ReactNode;
};

export const Header = observer(function Header(props: HeaderProps) {
  const { title, subTitle } = props;

  return (
    <div className={s.header}>
      <h3 className={s.title}>{title}</h3>
      {subTitle && <p className={s.subtitle}>{subTitle}</p>}
    </div>
  );
});
