import { HomeworkType } from '@constants';
import { makeAutoObservable } from 'mobx';

import { MyHomeworkRepository } from '@core/repositories';
import type {
  HomeworkWritingEntity,
  MyHomeworkTypes,
  SingleHomeworkWritingEntity,
} from '@core/repositories';
import type { WritingHomeworkAnswerDto } from '@core/repositories/my-homework/dto/complete-writing-homework-by-id.dto';

interface HomeworkWritingInterface extends Omit<SingleHomeworkWritingEntity, 'type'> {}

export class HomeworkWritingStore implements HomeworkWritingInterface {
  type = HomeworkType.writing;

  blockKey: MyHomeworkTypes;

  writings: HomeworkWritingEntity[];

  position: number;

  deadlineDate: string | null = null;

  id: number;

  title: string;

  rating: number;

  review: string;

  groupId: number;

  homeworkId: number;

  private repository = new MyHomeworkRepository();

  constructor({
    blockKey,
    writings,
    id,
    position,
    rating,
    review,
    title,
    deadlineDate,
    groupId,
    homeworkId,
  }: {
    blockKey: MyHomeworkTypes;
    groupId: number;
    homeworkId: number;
  } & HomeworkWritingInterface) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.groupId = groupId;

    this.blockKey = blockKey;

    this.writings = writings;

    this.id = id;

    this.position = position;

    this.deadlineDate = deadlineDate;

    this.rating = rating;

    this.title = title;

    this.review = review;

    this.homeworkId = homeworkId;
  }

  get currentWriting() {
    return this.writings?.[0] || null;
  }

  completeHomework = async (dto: WritingHomeworkAnswerDto) => {
    try {
      await this.repository.completeWritingById(
        {
          homeworkId: this.id,
          writingId: this.currentWriting?.id,
          userAnswer: dto,
        },
        {
          groupId: this.groupId,
        },
      );
    } catch (e) {
      console.error(e);
    }
  };
}
