import type { CSSProperties } from 'react';

import { TypographyNew } from '../common';
import type { GroupProps } from '../group';
import { Group } from '../group';
import type { UniqueIdentifier } from '@dnd-kit/core';
import type { AnimateLayoutChanges } from '@dnd-kit/sortable';
import { rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

export interface SortableDndBoxProps extends GroupProps {
  isDraggable?: boolean;
  dragId: UniqueIdentifier;
  dragHandlerClassNames?: string;
  onClickDragHandler?: () => void;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true;

const { rootClass, appendClass } = classNames('sortable-dnd-box');

const Icon = () => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66663 6C2.66663 5.73629 2.58843 5.47851 2.44192 5.25924C2.29541 5.03998 2.08717 4.86908 1.84354 4.76816C1.5999 4.66724 1.33181 4.64084 1.07317 4.69229C0.814531 4.74373 0.576954 4.87072 0.390484 5.05719C0.204014 5.24366 0.0770257 5.48124 0.0255787 5.73988C-0.0258682 5.99852 0.000536168 6.26661 0.101453 6.51025C0.20237 6.75388 0.373267 6.96212 0.592532 7.10863C0.811798 7.25514 1.06958 7.33333 1.33329 7.33333C1.68691 7.33333 2.02605 7.19286 2.2761 6.94281C2.52615 6.69276 2.66663 6.35362 2.66663 6ZM2.66663 1.33333C2.66663 1.06963 2.58843 0.811839 2.44192 0.592574C2.29541 0.373308 2.08717 0.202411 1.84354 0.101495C1.5999 0.000577661 1.33181 -0.0258268 1.07317 0.0256202C0.814531 0.0770671 0.576954 0.204055 0.390484 0.390525C0.204014 0.576995 0.0770259 0.814572 0.0255789 1.07321C-0.025868 1.33185 0.000536372 1.59994 0.101453 1.84358C0.20237 2.08721 0.373267 2.29545 0.592533 2.44196C0.811798 2.58847 1.06958 2.66667 1.33329 2.66667C1.68691 2.66667 2.02605 2.52619 2.2761 2.27614C2.52615 2.02609 2.66663 1.68696 2.66663 1.33333ZM2.66663 10.6667C2.66663 10.403 2.58843 10.1452 2.44192 9.92591C2.29541 9.70664 2.08717 9.53574 1.84354 9.43483C1.5999 9.33391 1.33181 9.30751 1.07317 9.35895C0.814531 9.4104 0.576953 9.53739 0.390484 9.72386C0.204014 9.91033 0.0770254 10.1479 0.0255785 10.4065C-0.0258684 10.6652 0.000535964 10.9333 0.101453 11.1769C0.20237 11.4205 0.373267 11.6288 0.592532 11.7753C0.811798 11.9218 1.06958 12 1.33329 12C1.68691 12 2.02605 11.8595 2.2761 11.6095C2.52615 11.3594 2.66663 11.0203 2.66663 10.6667Z"
      fill="#C8C8C8"
    />
    <path
      d="M8.00256 6C8.00256 5.73629 7.92436 5.47851 7.77786 5.25924C7.63135 5.03998 7.42311 4.86908 7.17947 4.76816C6.93584 4.66724 6.66775 4.64084 6.40911 4.69229C6.15047 4.74373 5.91289 4.87072 5.72642 5.05719C5.53995 5.24366 5.41296 5.48124 5.36152 5.73988C5.31007 5.99852 5.33647 6.26661 5.43739 6.51025C5.53831 6.75388 5.7092 6.96212 5.92847 7.10863C6.14774 7.25514 6.40552 7.33333 6.66923 7.33333C7.02285 7.33333 7.36199 7.19286 7.61204 6.94281C7.86209 6.69276 8.00256 6.35362 8.00256 6ZM8.00256 1.33333C8.00256 1.06963 7.92436 0.811839 7.77786 0.592574C7.63135 0.373308 7.42311 0.202411 7.17947 0.101495C6.93584 0.000577661 6.66775 -0.0258268 6.40911 0.0256202C6.15047 0.0770671 5.91289 0.204055 5.72642 0.390525C5.53995 0.576995 5.41296 0.814572 5.36152 1.07321C5.31007 1.33185 5.33647 1.59994 5.43739 1.84358C5.53831 2.08721 5.7092 2.29545 5.92847 2.44196C6.14774 2.58847 6.40552 2.66667 6.66923 2.66667C7.02285 2.66667 7.36199 2.52619 7.61204 2.27614C7.86209 2.02609 8.00256 1.68696 8.00256 1.33333ZM8.00256 10.6667C8.00256 10.403 7.92436 10.1452 7.77786 9.92591C7.63135 9.70664 7.42311 9.53574 7.17947 9.43483C6.93584 9.33391 6.66775 9.30751 6.40911 9.35895C6.15047 9.4104 5.91289 9.53739 5.72642 9.72386C5.53995 9.91033 5.41296 10.1479 5.36152 10.4065C5.31007 10.6652 5.33647 10.9333 5.43739 11.1769C5.53831 11.4205 5.7092 11.6288 5.92847 11.7753C6.14774 11.9218 6.40552 12 6.66923 12C7.02285 12 7.36199 11.8595 7.61204 11.6095C7.86209 11.3594 8.00256 11.0203 8.00256 10.6667Z"
      fill="#C8C8C8"
    />
  </svg>
);

const _SortableDndBox = observer((props: SortableDndBoxProps) => {
  const {
    dragId,
    isDraggable = false,
    style,
    children,
    dragHandlerClassNames,
    className,
    onClickDragHandler,
    ...restProps
  } = props;

  const {
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
    setNodeRef,
  } = useSortable({
    id: dragId,
    disabled: !isDraggable,
    animateLayoutChanges,
    strategy: rectSortingStrategy,
  });

  const _style: CSSProperties = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.4 : undefined,
  };

  const dragHandlerClasses = cl(
    appendClass('-handler'),
    { [appendClass('-hadnler--dragging')]: isDragging },
    dragHandlerClassNames,
  );

  return (
    <Group
      className={cl(rootClass, className)}
      wrap="nowrap"
      innerRef={setNodeRef}
      style={_style}
      w="100%"
      {...restProps}
    >
      {isDraggable && (
        <TypographyNew
          disabledSelection
          className={dragHandlerClasses}
          as="span"
          ref={setActivatorNodeRef}
          onClick={onClickDragHandler}
          {...attributes}
          {...listeners}
        >
          <Icon />
        </TypographyNew>
      )}
      {children}
    </Group>
  );
});

export const SortableDndBox = mergeComponentsWithName('SortableDndBox', _SortableDndBox);
