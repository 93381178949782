import { observer } from 'mobx-react-lite';

import type { CommentItemEntity } from '@core/repositories/courses/entities';

import { Avatar, Stack, TypographyNew } from '@shared/UI';

import { getFormatedDate } from '@shared/utils';
import { withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

interface CommentItemProps {
  item: CommentItemEntity;
}

export const CommentItem = observer(function CommentItem(props: CommentItemProps) {
  const { item } = props;
  const { comment, createdDate, user } = item;

  return (
    <div className={s.comment}>
      <Avatar src={withMediaUrl(user.image)} />
      <Stack align="flex-start" className={s.commentInfo}>
        <div>
          <TypographyNew as="span" variant="body-2" weight="bold">
            {user.name}
          </TypographyNew>
          <TypographyNew
            as="span"
            variant="body-4"
            colorSchema="neutral-200"
            className={s.commentDate}
          >
            {getFormatedDate(createdDate, 'YYYY-MM-DD HH:mm')}
          </TypographyNew>
        </div>
        <TypographyNew variant="body-2" colorSchema="neutral-200">
          {comment}
        </TypographyNew>
      </Stack>
    </div>
  );
});
