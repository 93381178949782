import * as z from 'zod';

export const signInByEmailSchema = z.object({
  email: z
    .string({
      required_error: 'errors.required-field',
    })
    .min(1, { message: 'errors.required-field' })
    .email({ message: 'errors.invalid-email' }),
  password: z
    .string({ required_error: 'errors.required-field' })
    .refine((val) => val.length !== 0, { message: 'errors.required-field' })
    .refine((val) => val.length >= 8, { message: 'errors.small-length' }),
  otp: z.string().optional(),
});

export type SignInByEmailSchema = z.infer<typeof signInByEmailSchema>;
