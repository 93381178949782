import { TalkingArea, useMessageParams } from '@units';

import { CommunityChatType } from '@core/constants';
import { useRootStore } from '@core/store';

export const useCommunityHeaderController = () => {
  const { communityChatsStore, communityMessagesStore, messageCounter } = useRootStore();

  const { chatId } = useMessageParams(TalkingArea.COMMUNITY);

  const chat = chatId ? communityChatsStore.getChatById(chatId) : null;

  const unreadCount = chatId ? messageCounter.getChat(chatId)?.totalCount ?? 0 : 0;

  const chatIconType: 'channel' | 'bubble-chats' =
    chat?.type === CommunityChatType.Channel ? 'channel' : 'bubble-chats';

  const onClickPinBtn = () => {
    if (chat && !communityMessagesStore.isPinnedMessagesVisible) {
      communityMessagesStore.getPinnedMessagesEmitter(chat.id);
    }

    if (chat && communityMessagesStore.isPinnedMessagesVisible) {
      communityChatsStore.awaitedForceConnectToChatEmitter({ communicationItemId: chat.id });
    }

    communityMessagesStore.toggleIsPinnedMessagesVisible();
  };

  const onClickReadAllBtn = () => {
    if (chat) {
      communityMessagesStore.awaitedReadAllMessagesEmitter(chat.id);
    }
  };

  return {
    chat,
    chatIconType,
    unreadCount,
    isPinnedMessagesVisible: communityMessagesStore.isPinnedMessagesVisible,
    onClickPinBtn,
    onClickReadAllBtn,
  };
};
