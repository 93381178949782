import { useState } from 'react';

import { FormBox } from '../../_components';
import type { PhoneFormSchema } from '../../_schemas';
import { phoneFormSchema } from '../../_schemas';
import { FormFields } from '@components';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import type { RegisterOptions, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useRootStore } from '@core/store';

import { transformOtp, transformPhoneNumber } from '@shared/utils';

const defaultValues: PhoneFormSchema = {
  phone: '',
  code: '+380',
  firstOtp: '',
};

export const Form = observer(function Form() {
  const { userStore } = useRootStore();

  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
    reset,
  } = useForm<PhoneFormSchema>({
    defaultValues,
    resolver: zodResolver(phoneFormSchema),
  });

  const [isOtpVisible, setIsOtpVisible] = useState(false);

  const handleFirstStep = async (phone: string) => {
    try {
      await userStore.firstStepAddPhone({ phone });

      setIsOtpVisible(true);
    } catch (e) {}
  };

  const handleSecondStep = async (otp: string, phone: string) => {
    try {
      await userStore.secondStepAddPhone({ firstOtp: transformOtp(otp), phone });

      setIsOtpVisible(false);
      reset(defaultValues);
      toast.success(t('my-account.personal-information.successfully-changed'));
    } catch (e) {}
  };

  const onSubmit: SubmitHandler<PhoneFormSchema> = async (dto) => {
    const phone = transformPhoneNumber(dto.phone, dto.code);
    if (dto.firstOtp) {
      await handleSecondStep(dto.firstOtp, phone);
      return;
    }
    await handleFirstStep(phone);
  };

  const handleChangePhone = () => {
    if (isOtpVisible) {
      setIsOtpVisible(false);
      setValue('firstOtp', '');
    }
  };

  const phoneRules: RegisterOptions = {
    onChange: handleChangePhone,
  };

  const otpCode = watch('firstOtp');

  const isOtpValid = !otpCode || otpCode.length !== 9;

  const isDisabledButton = !isDirty || userStore.loader.isLoading || (isOtpVisible && isOtpValid);

  return (
    <FormBox onSubmit={handleSubmit(onSubmit)} isDisabledButton={isDisabledButton}>
      <FormFields.Phone
        namePhone="phone"
        nameCode="code"
        control={control}
        label="inputs.phone-select.label"
        error={errors.phone}
        rulesPhone={phoneRules}
        rulesCode={phoneRules}
      />

      {isOtpVisible && (
        <FormFields.OtpCode control={control} name="firstOtp" error={errors.firstOtp} hiddeResend />
      )}
    </FormBox>
  );
});
