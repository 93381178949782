import { type CSSProperties, type ElementType, type HTMLAttributes, forwardRef } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('typography-new');

const typography = cva(rootClass, {
  variants: {
    variant: {
      'title-1': appendClass('--title-1'),
      'title-2': appendClass('--title-2'),
      'title-3': appendClass('--title-3'),
      'title-4': appendClass('--title-4'),
      'body-1': appendClass('--body-1'),
      'body-2': appendClass('--body-2'),
      'body-3': appendClass('--body-3'),
      'body-4': appendClass('--body-4'),
    },
    weight: {
      bold: appendClass('--bold'),
      regular: appendClass('--regular'),
      'extra-bold': appendClass('--extra-bold'),
    },
    colorSchema: {
      'secondary-100': appendClass('--secondary-100'),
      'secondary-200': appendClass('--secondary-200'),
      'secondary-300': appendClass('--secondary-300'),
      'secondary-400': appendClass('--secondary-400'),
      'secondary-500': appendClass('--secondary-500'),
      'secondary-600': appendClass('--secondary-600'),
      'secondary-700': appendClass('--secondary-700'),
      'secondary-800': appendClass('--secondary-800'),
      'secondary-900': appendClass('--secondary-900'),
      'neutral-100': appendClass('--neutral-100'),
      'neutral-200': appendClass('--neutral-200'),
      'neutral-300': appendClass('--neutral-300'),
      'neutral-400': appendClass('--neutral-400'),
      'neutral-500': appendClass('--neutral-500'),
      'neutral-600': appendClass('--neutral-600'),
      'neutral-700': appendClass('--neutral-700'),
      'neutral-800': appendClass('--neutral-800'),
      'neutral-2-100': appendClass('--neutral-2-100'),
      'neutral-2-200': appendClass('--neutral-2-200'),
      'neutral-2-300': appendClass('--neutral-2-300'),
      'neutral-2-400': appendClass('--neutral-2-400'),
      'neutral-2-500': appendClass('--neutral-2-500'),
      'neutral-2-600': appendClass('--neutral-2-600'),
      'neutral-2-700': appendClass('--neutral-2-700'),
      'neutral-2-800': appendClass('--neutral-2-800'),
      'primary-100': appendClass('--primary-100'),
      'primary-200': appendClass('--primary-200'),
      'primary-300': appendClass('--primary-300'),
      'primary-400': appendClass('--primary-400'),
      'primary-500': appendClass('--primary-500'),
      'primary-600': appendClass('--primary-600'),
      'primary-700': appendClass('--primary-700'),
      'primary-800': appendClass('--primary-800'),
      'primary-900': appendClass('--primary-900'),
      'red-100': appendClass('--red-100'),
      'red-500': appendClass('--red-500'),
      'yellow-100': appendClass('--yellow-100'),
      'yellow-500': appendClass('--yellow-500'),
      'green-100': appendClass('--green-100'),
      'green-500': appendClass('--green-500'),
    },
  },
  defaultVariants: {
    weight: 'regular',
    variant: 'body-2',
  },
});

export interface TypographyNewProps extends HTMLAttributes<any>, VariantProps<typeof typography> {
  as?: ElementType;
  truncate?: boolean;
  lineClamp?: number;
  textAlign?: CSSProperties['textAlign'];
  disabledSelection?: boolean;
}

const _Typography = observer(
  forwardRef<HTMLElement, TypographyNewProps>((props, ref) => {
    const {
      as: Component = 'p',
      truncate = false,
      className,
      variant,
      children,
      colorSchema,
      weight,
      lineClamp,
      style,
      textAlign,
      disabledSelection = false,
      ...restProps
    } = props;

    const rootClasses = typography({ variant, weight, colorSchema, className });

    const rootStyle = {
      ...style,
      '--typography-line-clamp': lineClamp,
      '--typography-text-align': textAlign,
    };

    return (
      <Component
        className={rootClasses}
        data-truncate={truncate}
        data-line-clamp={typeof lineClamp === 'number'}
        data-disabled-selection={disabledSelection}
        style={rootStyle}
        ref={ref}
        {...restProps}
      >
        {children}
      </Component>
    );
  }),
);

export const TypographyNew = mergeComponentsWithName('Typography', _Typography);
