import { routerCollection } from '@constants';
import { useAppNavigate } from '@hooks';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Box, Button } from '@shared/UI';

import s from './styles.module.scss';

type FormButtonsProps = {};

export const FormButtons = observer(function FormButtons(props: FormButtonsProps) {
  const {} = props;

  const navigate = useAppNavigate();
  const { t } = useTranslation();

  const { authStore } = useRootStore();

  const handleClickCancel = () => {
    navigate(routerCollection.auth.login);
    authStore.clearFormValues('forgotPasswordFirstStep');
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={20}
      className={s.formButtons}
    >
      <Button variant="outline" onClick={handleClickCancel}>
        {t('shared-content.cancel')}
      </Button>
      <Button type="submit">{t('forgot-password.send-code')}</Button>
    </Box>
  );
});
