import { FormBox } from '../../_components';
import { PrivacyPolicy, Subtitle } from '../_components';
import { FormFields } from '@components';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import type { RegisterOptions, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { SubmitButton } from '@features/auth/_components/submit-button';
import { useVerifyOtp } from '@features/auth/_hooks';
import type { FormSignUpByPhoneSchema } from '@features/auth/_schemas';
import { signUpByPhoneSchema } from '@features/auth/_schemas';

import { transformPhoneNumber } from '@shared/utils';

export const SignUpFormByPhone = observer(function LoginFormByPhone() {
  const { t } = useTranslation();

  const { authStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const onVerifyOtp = useVerifyOtp();

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    getValues,
    setValue,
  } = useForm<FormSignUpByPhoneSchema>({
    resolver: zodResolver(signUpByPhoneSchema),
    defaultValues: {
      ...authStore.defaultFormValues.signUpByPhone,
      isAgreeRules: false,
      emailSubscription: false,
      otp: '',
    },
  });

  const onSubmit: SubmitHandler<FormSignUpByPhoneSchema> = async (dto) => {
    const { phone, code, otp } = dto;

    if (otp) {
      await onVerifyOtp({ phone: transformPhoneNumber(phone, code), otp });
      sendEvent({ event: AnalyticsEvents.RegistrationAccountTap });
    } else {
      await authStore.signUpByPhone({ phone, code });
    }
  };

  const handleClickResend = async () => {
    try {
      const isValid = await trigger();
      if (isValid) {
        const { phone, code } = getValues();
        authStore.signUpByPhone({ phone, code });
        return;
      }
      return await Promise.reject();
    } catch (e) {
      return await Promise.reject();
    }
  };

  const handleChangePhone = () => {
    if (authStore.defaultFormValues.signUpByPhone.isOTPVisible) {
      authStore.setIsOTPVisible(false, 'signUpByPhone');
      setValue('otp', '');
    }
  };

  const phoneRules: RegisterOptions = {
    onChange: handleChangePhone,
  };

  return (
    <FormBox
      onSubmit={handleSubmit(onSubmit)}
      title={t('shared-content.sign-up')}
      subTitle={<Subtitle />}
    >
      <FormFields.Phone
        namePhone="phone"
        nameCode="code"
        control={control}
        label="inputs.phone-select.label"
        error={errors.phone}
        rulesPhone={phoneRules}
        rulesCode={phoneRules}
      />
      {authStore.defaultFormValues.signUpByPhone.isOTPVisible && (
        <FormFields.OtpCode
          onClickResend={handleClickResend}
          name="otp"
          control={control}
          error={errors.otp}
        />
      )}

      <PrivacyPolicy
        control={control}
        isAgreeRulesError={errors.isAgreeRules}
        emailSubscriptionError={errors.emailSubscription}
      />

      <SubmitButton
        isOtpVisible={authStore.defaultFormValues.signUpByPhone.isOTPVisible}
        control={control}
        type="sign-up"
      />
    </FormBox>
  );
});
