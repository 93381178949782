import type { CSSProperties, ReactNode } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import cl from 'classnames';

import { observer } from '@shared/libs/mobx';

import type { BoxProps } from '@shared/UI';
import { Box, CrawlLine, Typography } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const categoryCard = cva(s.categoryCard, {
  variants: {
    variant: {
      primary: s.categoryCardVariantPrimary,
      secondary: s.categoryCardVariantSecondary,
    },
  },

  defaultVariants: {
    variant: 'primary',
  },
});

export interface CategoryCardProps extends BoxProps, VariantProps<typeof categoryCard> {
  label: ReactNode;

  image?: string;

  isLocked?: boolean;

  headerSlot?: ReactNode;
}

const _CategoryCard = observer((props: CategoryCardProps) => {
  const { label, image, isLocked, headerSlot, variant, className, style, ...restProps } = props;

  const rootClasses = categoryCard({
    variant,
    className: cl(
      {
        [s.categoryCardLocked]: isLocked,
      },
      className,
    ),
  });

  const _style: CSSProperties = {
    ...style,
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Box
      intent="glass"
      data-header-slot={Boolean(headerSlot)}
      className={rootClasses}
      style={_style}
      title={label as string}
      {...restProps}
    >
      {headerSlot && <Box>{headerSlot}</Box>}

      <CrawlLine justifyContent="center">
        <Typography as="h4" variant="title-bold-3" whiteSpace="nowrap">
          {label}
        </Typography>
      </CrawlLine>
    </Box>
  );
});

export const CategoryCard = mergeComponentsWithName('CategoryCard', _CategoryCard);
