import type { CSSProperties, ReactNode, Ref } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { observer } from '@shared/libs/mobx';

import { ClassGapCollection } from '@shared/constants';
import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass } = classNames('stack');

const stack = cva(rootClass, {
  variants: {
    gap: ClassGapCollection(rootClass),
  },
  defaultVariants: {
    gap: 'md',
  },
});

export interface StackProps extends ElementProps<'div'>, VariantProps<typeof stack> {
  children: ReactNode;

  justify?: CSSProperties['justifyContent'];

  align?: CSSProperties['alignItems'];

  h?: CSSProperties['height'];

  w?: CSSProperties['width'];

  innerRef?: Ref<HTMLDivElement>;
}

const _Stack = observer((props: StackProps) => {
  const {
    children,
    gap,
    justify = 'stretch',
    align = 'center',
    className,
    style,
    h,
    w,
    innerRef,
    ...restProps
  } = props;

  const rootClasses = stack({ gap, className });

  const styles = {
    '--ui-stack-justify': justify,
    '--ui-stack-align': align,
    height: h,
    width: w,
    ...style,
  };

  return (
    <div ref={innerRef} {...restProps} className={rootClasses} style={styles}>
      {children}
    </div>
  );
});

export const Stack = mergeComponentsWithName('Stack', _Stack);
