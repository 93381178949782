import { lazy } from 'react';

import { ProtectedRoute } from '@components';
import { routerCollection } from '@constants';
import type { RouteObject } from 'react-router-dom';

import { EntryPage } from './EntryPage';

const FirstStep = lazy(() => import('./first-step'));
const SecondStep = lazy(() => import('./second-step'));

export const onBoardingRouter: RouteObject = {
  path: routerCollection.onboarding.root,
  element: (
    <ProtectedRoute>
      <EntryPage />
    </ProtectedRoute>
  ),

  children: [
    {
      index: true,
      element: <FirstStep />,
    },
    {
      path: routerCollection.onboarding.interests,
      element: <SecondStep />,
    },
  ],
};
