import type { BaseModalProps } from '../../_types';
import { modalStore } from '../../modal.store';
import { CancelSubscriptionReasonsModal } from '../cancel-subscription-reasons-modal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Stack, TypographyNew } from '@shared/UI';

import { createDateWithMonthFormat } from '@shared/utils';

import s from './styles.module.scss';

interface CancelSubscriptionModalProps extends BaseModalProps {
  endDate: string;
  subscriptionId: string;
}

export const CancelSubscriptionModal = observer(function CancelSubscriptionModal(
  props: CancelSubscriptionModalProps,
) {
  const { endDate, subscriptionId, ...restProps } = props;

  const { t } = useTranslation();

  const listItems = [
    t('my-account.my-subscription.cancel-description-item.1', {
      date: createDateWithMonthFormat(endDate),
    }),
    t('my-account.my-subscription.cancel-description-item.2'),
    t('my-account.my-subscription.cancel-description-item.3'),
  ];

  const handleSubscriptionCancellingClick = async () => {
    modalStore.activateModal({
      component: (
        <CancelSubscriptionReasonsModal
          subscriptionId={subscriptionId}
          endDate={endDate}
          isNeedToUnmount
        />
      ),
      id: 'cancel-subscription-reasons',
    });
  };

  return (
    <Modal
      centered
      size="lg"
      title={
        <TypographyNew variant="title-3" weight="bold">
          {t('my-account.my-subscription.cancel')}
        </TypographyNew>
      }
      id="cancel-subscription"
      {...restProps}
    >
      <Stack gap="xxl" align="flex-start" className={s.cancelSubscriptionModal}>
        <TypographyNew variant="title-4" weight="bold">
          {t('my-account.my-subscription.cancel-subtitle')}
        </TypographyNew>
        <TypographyNew colorSchema="neutral-200">
          {t('my-account.my-subscription.cancel-description')}
        </TypographyNew>
        <ul className={s.cancelSubscriptionModalDescriptionList}>
          {listItems.map((item) => (
            <TypographyNew
              key={item}
              as="li"
              variant="body-3"
              colorSchema="neutral-200"
              className={s.cancelSubscriptionModalDescriptionListItem}
            >
              {item}
            </TypographyNew>
          ))}
        </ul>
        <Button
          size="sm"
          className={s.cancelSubscriptionModalButton}
          onClick={handleSubscriptionCancellingClick}
        >
          {t('shared-content.continue')}
        </Button>
      </Stack>
    </Modal>
  );
});
