import { useEffect, useMemo, useRef, useState } from 'react';

import type { WaveSurferOptions } from 'wavesurfer.js';
import WaveSurfer from 'wavesurfer.js';

import type { MessageStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Skeleton, Stack, SvgIcon, Typography } from '@shared/UI';

import { mergeComponentsWithName, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

const surferOptions: Partial<WaveSurferOptions> = {
  cursorWidth: 1,
  barRadius: 1,
  waveColor: ['#fff'],
  progressColor: ['#8f34e6', '#0590dc', '#0841b7'],
  cursorColor: 'transparent',
  barWidth: 3.5,
  normalize: true,
  barAlign: 'bottom',
  height: 20,
  barHeight: 32,
  fetchParams: {
    credentials: 'include',
  },
};

type PlayState = {
  isPause: boolean;
  isPlaying: boolean;
  isStopped: boolean;
};

export interface MessageAudioProps {
  setAudioRef: (messageId: string, audio: WaveSurfer) => void;

  onClickAudio: (messageId: string) => void;

  message: MessageStore;
}

const formatTime = (time: number) => {
  return [
    Math.floor((time % 3600) / 60), // minutes
    ('00' + Math.floor(time % 60)).slice(-2), // seconds
  ].join(':');
};
const _MessageAudio = observer((props: MessageAudioProps) => {
  const { setAudioRef, onClickAudio, message } = props;

  const waveRef = useRef<HTMLDivElement>(null);

  const [waveSurfer, setWaveSurfer] = useState<WaveSurfer | null>(null);

  const [audioState, setAudioState] = useState<PlayState>({
    isPlaying: false,
    isPause: false,
    isStopped: true,
  });

  const [duration, setDuration] = useState<string | null>(null);

  const [currentTime, setCurrentTime] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const audioUrl = useMemo(() => withMediaUrl(message.attachments[0]), [message.attachments]);

  const audioDuration =
    waveSurfer && waveSurfer.getDuration() && formatTime(waveSurfer.getDuration());

  const onLoad = () => {
    setIsLoading(true);
  };

  const onAudioProcess = (currentTime: number) => {
    const formattedTime = formatTime(currentTime);
    setCurrentTime((prevState) => (formattedTime === prevState ? prevState : formattedTime));
  };

  const onReady = () => {
    const _duration =
      waveSurfer && waveSurfer.getDuration() > 0 ? formatTime(waveSurfer.getDuration()) : null;
    setDuration(_duration);
    setIsLoading(false);
  };

  const onFinish = () => {
    waveSurfer?.stop();
    setAudioState({
      isStopped: true,
      isPause: false,
      isPlaying: false,
    });
    waveSurfer?.seekTo(0);
  };
  const onPause = () => {
    setAudioState({
      isStopped: false,
      isPause: true,
      isPlaying: false,
    });
  };

  const onPlay = () => {
    setAudioState({
      isStopped: false,
      isPause: false,
      isPlaying: true,
    });
  };
  const setupListeners = (audio: WaveSurfer) => {
    audio.on('load', onLoad);
    audio.on('audioprocess', onAudioProcess);
    audio.on('ready', onReady);
    audio.on('pause', onPause);
    audio.on('play', onPlay);
    audio.on('finish', onFinish);
  };

  const initWaveSurfer = () => {
    let _waveSurfer: WaveSurfer | null = null;

    if (!_waveSurfer && waveRef.current) {
      _waveSurfer = WaveSurfer.create({
        ...surferOptions,
        container: waveRef.current,
      });

      _waveSurfer.load(audioUrl).finally();

      setWaveSurfer(_waveSurfer);
    }

    if (_waveSurfer) {
      setupListeners(_waveSurfer);
      setAudioRef(message.id, _waveSurfer);
    }
  };

  useEffect(() => {
    initWaveSurfer();

    return () => {
      waveSurfer?.destroy();
    };
  }, [audioUrl]);

  const displayedTime = useMemo(() => {
    if ((audioState.isPause || audioState.isPlaying) && currentTime) {
      return currentTime;
    }
    if (audioState.isStopped && duration) {
      return duration;
    }
    return null;
  }, [duration, currentTime]);

  function handleClickAudio() {
    onClickAudio(message.id);
  }

  return (
    <div className={s.messageAudio}>
      {isLoading ? (
        <>
          <Skeleton width={48} height={48} borderRadius="50%" />
          <Skeleton className={s.messageAudioWave} />
        </>
      ) : (
        <>
          <button className={s.messageAudioBtn} onClick={handleClickAudio}>
            <SvgIcon type={audioState.isPlaying ? 'pause' : 'play'} />
          </button>
          <Stack gap="xs-3" align="flex-start" className={s.messageAudioWaveWrapper}>
            <div className={s.messageAudioWave} id="waveform" ref={waveRef}></div>
            {displayedTime && (
              <Typography className={s.messageAudioTime} as="span" variant="body-medium-3">
                {displayedTime} / {audioDuration}
              </Typography>
            )}
          </Stack>
        </>
      )}
    </div>
  );
});

export const MessageAudio = mergeComponentsWithName('MessageAudio', _MessageAudio);
