import { useMemo } from 'react';

import cl from 'classnames';

import type { HomeworkTestOptionEntity } from '@core/repositories';

import { observer } from '@shared/libs/mobx';

import { Radio, SvgIcon } from '@shared/UI';

import { asHtml } from '@shared/utils';

import s from './styles.module.scss';

export interface HomeworkRadioProps {
  option: HomeworkTestOptionEntity;

  isChecked: boolean;
}

export const HomeworkRadio = observer(function HomeworkRadio(props: HomeworkRadioProps) {
  const { option, isChecked } = props;

  const isIncorrectChecked = option.isSelectedAnswer && !option.isCorrectAnswer;

  const isCorrectChecked = option.isCorrectAnswer;

  const indicator = useMemo(() => {
    if (isIncorrectChecked) {
      return <SvgIcon className={s.homeworkRadioIndicator} type="red-cross" />;
    }
    if (isCorrectChecked || isChecked) {
      return <SvgIcon className={s.homeworkRadioIndicator} type="green-checkmark" />;
    }

    return null;
  }, [isChecked, option]);

  const rootClasses = cl(s.homeworkRadio, {
    [s.homeworkRadioCorrect]: isCorrectChecked || isChecked,
    [s.homeworkRadioIncorrect]: isIncorrectChecked,
  });

  return (
    <Radio
      className={rootClasses}
      indicator={indicator}
      size="xl"
      label={asHtml(option.title)}
      value={String(option.id)}
      disabled
    />
  );
});
