import { SvgIcon } from '../../../svg-icon';
import { useRichEditorContext } from '../../rich-editor.context';
import { EditorMenuItem } from '../editor-menu-item';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

const _BtnBold = observer(() => {
  const { editor } = useRichEditorContext();

  const handleClickBtn = () => editor().chain().focus().toggleBold().run();

  const icon = <SvgIcon type="editor-bold" />;

  return (
    <EditorMenuItem icon={icon} onClick={handleClickBtn} isActive={editor().isActive('bold')} />
  );
});

export const BtnBold = mergeComponentsWithName('BtnBold', _BtnBold);
