import { Fragment } from 'react';
import type { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import type { ScrollPosition } from 'react-lazy-load-image-component';

import { mergeComponentsWithName } from '@shared/utils';

export interface ScrollWrapperProps {
  scrollPosition: ScrollPosition;

  children: (scrollPosition: ScrollPosition) => ReactNode;
}

const _ScrollWrapper = trackWindowScroll(
  observer((props: ScrollWrapperProps) => {
    const { scrollPosition, children } = props;

    return <Fragment>{children(scrollPosition)}</Fragment>;
  }),
);

export const ScrollWrapper = mergeComponentsWithName('ScrollWrapper', _ScrollWrapper);
