import { useRef } from 'react';

import type { EmojiDetails } from '@features-new';
import { EmojiPicker } from '@features-new';
import { SendButton } from '@units';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Avatar, Input } from '@shared/UI';

import s from './styles.module.scss';

type MessageFormType = {
  message: string;
};

export const MessageForm = observer(function MessageForm() {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const { userStore, streamChatStore } = useRootStore();

  const { handleSubmit, control, setValue, watch } = useForm<MessageFormType>({
    defaultValues: {
      message: '',
    },
  });

  const message = watch('message', '');

  const onSubmit = ({ message }: MessageFormType) => {
    if (message) {
      streamChatStore.sendMessage(message);
      setValue('message', '');
    }
  };

  const onSelectEmoji = (details: EmojiDetails) => {
    if (inputRef) {
      const cursorPosition = inputRef.current?.selectionStart || 0;
      const text =
        message.slice(0, cursorPosition) + details.native + message.slice(cursorPosition);
      setValue('message', text);
    }
  };

  return (
    <div className={s.root}>
      <Avatar src={userStore.avatar} />
      <form onSubmit={handleSubmit(onSubmit)} className={s.messageForm}>
        <div className={s.messageFormInputWrapper}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={t('shared-content.write-comment')}
                value={field.value}
                onChange={field.onChange}
                className={s.messageFormInput}
                ref={inputRef}
              />
            )}
          />
          <div className={s.messageFormEmojiPicker}>
            <EmojiPicker onSelect={onSelectEmoji} />
          </div>
        </div>
        <SendButton type="submit" className={s.messageFormButton} />
      </form>
    </div>
  );
});
