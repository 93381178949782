import { useCallback, useEffect, useRef } from 'react';

import { modalStore } from '@components';
import { EMPTY_TAGS_PATTERN } from '@constants';
import {
  CancelRecording,
  type EmojiDetails,
  EmojiPicker,
  MediaRecordButtons,
  UploadedFilesModal,
  type UploadedFilesModalDetailsSubmit,
} from '@features-new';
import type { Editor } from '@tiptap/react';
import {
  EditorBox,
  MessageDrawer,
  SendButton,
  useMediaRecorderContext,
  useMessageTransmitterContext,
} from '@units';
import { type TargetAndTransition, motion } from 'framer-motion';
import { autorun } from 'mobx';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { RichTextEditorProps } from '@shared/UI';
import { RichTextEditor, SvgIcon, Upload, type UploadedFile } from '@shared/UI';

import { removeEmptyPTags } from '@shared/utils';

import s from './styles.module.scss';

type MessageForm = {
  message: string;
};

const footerAnimate: TargetAndTransition = {
  transform: 'translateY(0)',
  transition: {
    delay: 0.2,
  },
};

const footerInitial = {
  transform: 'translateY(100%)',
};

export interface FormProps {
  onUploadFiles: (message: string, files: UploadedFile[]) => Promise<any>;
  onSubmitMessage: (message: string, replyMessageId?: string) => void;
  editorExtensions?: RichTextEditorProps['extensionsOptions'];
}

export const Form = observer(function Form(props: FormProps) {
  const { onUploadFiles, onSubmitMessage, editorExtensions } = props;

  const { isRecording } = useMediaRecorderContext();

  const editorRef = useRef<Editor>();

  const { t } = useTranslation();

  const { control, handleSubmit, getValues, watch } = useForm<MessageForm>({
    defaultValues: {
      message: '',
    },
  });

  const { messageTransmitter } = useMessageTransmitterContext();

  const messageText = watch('message');

  const onSubmit: SubmitHandler<MessageForm> = ({ message }) => {
    if (message.replace(EMPTY_TAGS_PATTERN, '').length === 0) return;

    const replacedMessage = removeEmptyPTags(message);

    if (messageTransmitter.editableMessage) {
      messageTransmitter.editableMessage.editMessage(replacedMessage);
      messageTransmitter.clearEditableMessage();
    } else if (messageTransmitter.replyMessage) {
      onSubmitMessage?.(replacedMessage, messageTransmitter.replyMessage.id);
      messageTransmitter.clearReplyMessage();
    } else {
      onSubmitMessage(replacedMessage);
    }

    editorRef?.current?.chain().setContent('', true).run();
  };

  const onSubmitFileModal = useCallback(
    async ({ message, files }: UploadedFilesModalDetailsSubmit) => {
      await onUploadFiles(message, files);
    },
    [onUploadFiles],
  );

  const handleSelectEmoji = useCallback((emoji: EmojiDetails) => {
    editorRef?.current?.chain().focus().insertContent(emoji.native).run();
  }, []);

  const onSubmitByEnter = () => {
    handleSubmit(onSubmit)();
  };

  const handleChangeUpload = (files: UploadedFile[]) => {
    const defaultValue = getValues('message');
    modalStore.activateModal({
      component: (
        <UploadedFilesModal
          onSubmit={onSubmitFileModal}
          isNeedToUnmount
          files={files}
          defaultValue={defaultValue}
        />
      ),
    });
  };

  useEffect(() => {
    const reaction = autorun(() => {
      if (messageTransmitter.editableMessage && !messageTransmitter.replyMessage) {
        editorRef?.current
          ?.chain()
          .setContent(messageTransmitter.editableMessage.message, true)
          .run();
      }

      if (!messageTransmitter.editableMessage) {
        editorRef?.current?.chain().setContent('', true).run();
      }
    });

    return () => {
      reaction();
    };
  }, []);

  return (
    <motion.form
      initial={footerInitial}
      animate={footerAnimate}
      id="chat-form"
      onSubmit={handleSubmit(onSubmit)}
      className={cl(s.chatForm, {
        [s.chatFormRecording]: isRecording,
      })}
    >
      <EditorBox className={s.chatFormEditorWrapper}>
        {messageTransmitter.editableMessage && !messageTransmitter.replyMessage && (
          <MessageDrawer
            label={t('shared-content.edit-message')}
            beforeIcon={<SvgIcon type="pen" />}
            message={messageTransmitter.editableMessage}
            onCancel={messageTransmitter.clearEditableMessage}
          />
        )}
        {messageTransmitter.replyMessage && (
          <MessageDrawer
            label={messageTransmitter.replyMessage.authorName}
            beforeIcon={<SvgIcon type="reply" />}
            message={messageTransmitter.replyMessage}
            onCancel={messageTransmitter.clearReplyMessage}
          />
        )}
        <CancelRecording />
        <Controller
          name="message"
          control={control}
          render={({ field }) => {
            return (
              <RichTextEditor
                extensionsOptions={editorExtensions}
                slotBefore={<EmojiPicker onSelect={handleSelectEmoji} />}
                slotAfter={
                  <Upload
                    accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf"
                    multiple
                    showErrorNotification
                    maxFileSize={10}
                    onChange={handleChangeUpload}
                  >
                    <SvgIcon type="paperclip" className={s.chatFormPaperclip} />
                  </Upload>
                }
                editorRef={editorRef}
                onChange={field.onChange}
                content={field.value}
                onSubmit={onSubmitByEnter}
              />
            );
          }}
        />
      </EditorBox>
      {messageText.length > 7 ||
      messageTransmitter.replyMessage ||
      messageTransmitter.editableMessage ? (
        <SendButton form="chat-form" type="submit" />
      ) : (
        <MediaRecordButtons />
      )}
    </motion.form>
  );
});
