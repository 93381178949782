import { CommunityBenefit } from '@units';

import type { CommunityPreviewBenefitEntity } from '@core/repositories';

import { observer } from '@shared/libs/mobx';

import type { BoxProps, CarouselProps } from '@shared/UI';
import { Box, Carousel } from '@shared/UI';

import { useMediaQuery } from '@shared/hooks';
import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface CommunityBenefitsTileProps {
  benefits: CommunityPreviewBenefitEntity[];
}

const _CommunityBenefitsTile = observer((props: CommunityBenefitsTileProps) => {
  const { benefits } = props;

  const isMatch = useMediaQuery('(max-width: 860px)');

  const BenefitElement = isMatch ? Carousel.Slide : Box;

  const carouselContainerProps: CarouselProps = {
    slidesPerView: 1,
  };

  const boxContainerProps: BoxProps = {
    className: s.benefitsTileContainerTile,
  };

  const benefitsMapper = benefits.map((benefit, idx) => {
    return (
      <BenefitElement
        className={s.benefitsTileItemContainer}
        key={benefit.id}
        data-item-number={idx + 1}
      >
        <CommunityBenefit
          className={s.benefitsTileItem}
          title={benefit.title}
          description={benefit.description}
        />
      </BenefitElement>
    );
  });

  return (
    <div className={s.benefitsTile}>
      {isMatch ? (
        <Carousel {...carouselContainerProps}>{benefitsMapper}</Carousel>
      ) : (
        <div data-items-count={benefits.length} {...boxContainerProps}>
          {benefitsMapper}
        </div>
      )}
    </div>
  );
});

export const CommunityBenefitsTile = mergeComponentsWithName(
  'CommunityBenefitsTile',
  _CommunityBenefitsTile,
);
