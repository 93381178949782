import { useMediaRecordButtonsController } from './media-record-buttons.controller';
import { VideoPreview } from './video-preview';
import { FloatingFocusManager, FloatingOverlay, FloatingPortal } from '@floating-ui/react';
import { RecordButton, RecordingType } from '@units';
import { motion } from 'framer-motion';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _MediaRecordButtons = observer(() => {
  const {
    recordingType,
    videoRecordOptions,
    audioRecordOptions,
    currentBtn,
    context,
    cancelRecord,
    stopRecording,
    getReferenceProps,
    getFloatingProps,
    bindLongPress,
    setupVideoContainerRef,
  } = useMediaRecordButtonsController();

  const rootClasses = cl(s.mediaRecordButtons, {
    [s.mediaRecordButtonsAudioActive]: currentBtn === RecordingType.Audio,
    [s.mediaRecordButtonsVideoActive]: currentBtn === RecordingType.Video,
  });

  return (
    <ul className={rootClasses}>
      <li className={cl(s.mediaRecordButton, s.mediaRecordAudioButton)}>
        <RecordButton
          data-variant={RecordingType.Audio}
          isRecording={audioRecordOptions.status === 'recording'}
          variant="audio"
          {...bindLongPress({ targetType: RecordingType.Audio })}
        />
      </li>
      <li className={cl(s.mediaRecordButton, s.mediaRecordVideoButton)}>
        <RecordButton
          isRecording={videoRecordOptions.status === 'recording'}
          data-variant={RecordingType.Video}
          {...getReferenceProps({
            type: 'button',
          })}
          {...bindLongPress({ targetType: RecordingType.Video })}
        />
      </li>

      {recordingType === RecordingType.Video &&
        (videoRecordOptions.status === 'acquiring_media' ||
          videoRecordOptions.status === 'recording') && (
          <FloatingPortal>
            <FloatingOverlay lockScroll className={s.recordingOverlay} onClick={cancelRecord}>
              <FloatingFocusManager context={context}>
                <motion.div
                  ref={setupVideoContainerRef}
                  {...getFloatingProps({
                    ref: setupVideoContainerRef,
                    className: s.recordingVideo,
                  })}
                >
                  <motion.div
                    className={s.circleProgressContainer}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.1, ease: 'easeInOut' }}
                  >
                    <VideoPreview stream={videoRecordOptions.previewStream} />
                    <motion.div className={s.timer}>
                      <CountdownCircleTimer
                        isPlaying={Boolean(videoRecordOptions.previewStream)}
                        duration={60}
                        colors="rgba(0,0,0,0.5)"
                        trailColor="#ffffff"
                        strokeWidth={5}
                        strokeLinecap="square"
                        onComplete={stopRecording}
                      />
                    </motion.div>
                  </motion.div>
                </motion.div>
              </FloatingFocusManager>
            </FloatingOverlay>
          </FloatingPortal>
        )}
    </ul>
  );
});

export const MediaRecordButtons = mergeComponentsWithName(
  'MediaRecordButtons',
  _MediaRecordButtons,
);
