import { useCallback } from 'react';
import type { CSSProperties, ChangeEvent, ReactNode } from 'react';

import { CheckboxGroupProvider } from '../checkbox-group.context';
import type { CheckboxSize } from '../checkbox.types';
import { observer } from 'mobx-react-lite';

import { useUncontrolled } from '@shared/hooks';
import type { ElementDirection, ElementProps } from '@shared/types';

export interface CheckboxGroupProps extends ElementProps<'div', 'onChange' | 'defaultValue'> {
  children: ReactNode;

  value?: string[];

  defaultValue?: string[];

  onChange?: (value: string[]) => void;

  size?: CheckboxSize;

  direction?: ElementDirection;

  disabled?: boolean;
}

export const CheckboxGroup = observer(function CheckboxGroup(props: CheckboxGroupProps) {
  const { children, value, defaultValue, onChange, direction = 'vertical', size, disabled } = props;

  const [_value, setValue] = useUncontrolled({
    value,
    defaultValue,
    finalValue: [],
    onChange,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const itemValue = event.currentTarget.value;
    setValue(
      _value.includes(itemValue)
        ? _value.filter((item) => item !== itemValue)
        : [..._value, itemValue],
    );
  };

  const isChecked = useCallback(
    (v: string) => {
      return _value.includes(v);
    },
    [_value],
  );

  const containerStyles: CSSProperties = {
    display: 'flex',
    flexDirection: direction === 'vertical' ? 'column' : 'row',
    gap: 12,
  };

  return (
    <CheckboxGroupProvider
      value={{ value: _value, onChange: handleChange, size, disabled, isChecked }}
    >
      <div style={containerStyles}>{children}</div>
    </CheckboxGroupProvider>
  );
});
