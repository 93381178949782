import { HomeworkType } from '@constants';
import { makeAutoObservable } from 'mobx';

import { MyHomeworkRepository } from '@core/repositories';
import type { HomeworkTestEntity, MyHomeworkTypes } from '@core/repositories';

export class HomeworkTestStore {
  tests: HomeworkTestEntity[] = [];

  total: number;

  correctAnswers: number;

  completedTests: number;

  type = HomeworkType.test;

  currentTestIndex = 0;

  blockKey: MyHomeworkTypes;

  homeworkId: number;

  groupId: number;

  isCompleted = false;

  private repository = new MyHomeworkRepository();

  constructor({
    total,
    completedTests,
    correctAnswers,
    tests,
    blockKey,
    homeworkId,
    groupId,
  }: {
    total: number;
    correctAnswers: number;
    completedTests: number;
    tests: HomeworkTestEntity[];
    blockKey: MyHomeworkTypes;
    homeworkId: number;
    groupId: number;
  }) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.total = total;

    this.correctAnswers = correctAnswers;

    this.completedTests = completedTests;

    this.tests = tests;

    this.blockKey = blockKey;

    this.homeworkId = homeworkId;

    this.groupId = groupId;

    this.isCompleted = this.completedTests === this.total;
  }

  get currentTest() {
    return this.tests[this.currentTestIndex] || ({} as HomeworkTestEntity);
  }

  get hasMoreTask() {
    return this.tests.length > this.currentTestIndex + 1;
  }

  get isLastTest() {
    return this.tests.length === this.currentTestIndex + 1;
  }

  completeTask = async (value: number[]) => {
    try {
      const currentTestId = this.currentTest.id;
      await this.repository.completeTestById(value, this.homeworkId, currentTestId, {
        groupId: this.groupId,
      });
      this.nextTest();
    } catch (e) {
      console.error(e);
    }
  };

  nextTest = () => {
    if (this.tests.length === this.currentTestIndex + 1) {
      this.isCompleted = true;
      this.completedTests = this.total;
    } else {
      this.currentTestIndex += 1;
      this.completedTests += 1;
    }
  };

  prevTest = () => {
    if (this.currentTestIndex > 0) {
      this.currentTestIndex -= 1;
    }
  };
}
