import type { CompleteHomeworkQueryDto } from './dto';

import { PATH_COLLECTION } from '@core/constants';
import type { CompleteWritingHomeworkByIdDto } from '@core/repositories/my-homework/dto/complete-writing-homework-by-id.dto';
import { createHTTPMediaService, createHTTPService } from '@core/services/http';

import { createFormData } from '@shared/utils';

export class MyHomeworkRepository {
  private readonly http = createHTTPService(PATH_COLLECTION.MY_HOMEWORK.ROOT);

  private readonly httpMedia = createHTTPMediaService(PATH_COLLECTION.MEDIA.ROOT);

  completeTestById = async (
    value: number[],
    homeworkId: number,
    testId: number,
    query: CompleteHomeworkQueryDto,
  ) => {
    const path = PATH_COLLECTION.MY_HOMEWORK.COMPLETE_TEST_HOMEWORK;

    return this.http.post<any>(
      path,
      {
        selectedHomeworkTestOptionIds: value,
      },
      {
        query,
        param: {
          homeworkId,
          testId,
        },
      },
    );
  };

  completeWritingById = async (
    params: CompleteWritingHomeworkByIdDto,
    query: CompleteHomeworkQueryDto,
  ) => {
    const path = PATH_COLLECTION.MEDIA.COMPLETE_WRITING_HOMEWORK;

    const formData = createFormData({
      payload: {
        answer: params.userAnswer.answer,
      },
    });

    if (Boolean(params.userAnswer.files?.length)) {
      params.userAnswer?.files?.forEach((file) => {
        formData.append('file', file);
      });
    }

    return this.httpMedia.post<null, FormData, CompleteHomeworkQueryDto>(path, formData, {
      query,
      param: {
        homeworkId: params.homeworkId,
        writingId: params.writingId,
      },
    });
  };
}
