import { useEffect } from 'react';
import type { ReactNode } from 'react';

import { AnalyticsContext } from './AnalyticsContext';
import type { AnalyticsSendEventEntity } from './analytics.entity';
import amplitude from 'amplitude-js';

import type { UserStore } from '@core/store/user';

interface AnalyticsProviderProps {
  children: ReactNode;
  userStore: UserStore;
}

export const AnalyticsProvider = (props: AnalyticsProviderProps) => {
  const { children, userStore } = props;

  const sendEvent = ({ event, payload }: AnalyticsSendEventEntity) => {
    amplitude.getInstance().logEvent(event, {
      ...payload,
      user_id: userStore.user.id,
      user_creation_time: userStore.user.creationDate,
    });
  };

  useEffect(() => {
    amplitude.getInstance().init(import.meta.env.VITE_AMPLITUDE_API_KEY, '', {
      includeUtm: true,
    });
  }, []);

  return <AnalyticsContext.Provider value={sendEvent}>{children}</AnalyticsContext.Provider>;
};
