import type { EditMessageDetails } from '../../common';
import { LoadingStore, MessageStore } from '../../common';
import debounce from 'lodash.debounce';
import { makeAutoObservable, runInAction } from 'mobx';

import { LoadMessageDirection, SocketEventsCollection } from '@core/constants';
import type {
  JoinToPrivateChatDto,
  JointToPrivateChatResponse,
  PrivateChatMessageEntity,
  PrivateChatParticipantEntity,
  PrivateMessageReactionDto,
  PrivateMessageReactionEntity,
  SendPrivateMessageDto,
  SendPrivateMessageResponse,
} from '@core/repositories';
import type { ChatPaginationEntity } from '@core/repositories/_entities';
import { appSocket } from '@core/services/socket';
import type { PrivateChatsStore } from '@core/store';
import type { UserStore } from '@core/store/user';

import type { ObjectValues } from '@shared/types';
import { mapToArray, withMediaUrl } from '@shared/utils';

type ChatBucket = {
  pagination: ChatPaginationEntity & {
    upMessageTriggerId: string | null;
    downMessageTriggerId: string | null;
  };
  loader: LoadingStore;
  messages: Map<string, MessageStore>;
  participants: Record<number, PrivateChatParticipantEntity>;
  companion: PrivateChatParticipantEntity | null;
};

type ChatsBuckets = Map<string, ChatBucket>;

export class PrivateMessagesStore {
  private _chatsBuckets: ChatsBuckets = new Map();

  private needToViewMessages: Set<string> = new Set();

  constructor(private userStore: UserStore, private privateChatsStore: PrivateChatsStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    // this.initListeners();
    // this.initSubscribers();
  }

  //#region --- Getters
  get activeChatCompanion() {
    return this.activeChatBucket?.companion ?? null;
  }

  get activeChatMessages() {
    return this.activeChatBucket ? mapToArray(this.activeChatBucket.messages) : [];
  }

  get activeChatPagination() {
    return this.activeChatBucket?.pagination ?? null;
  }

  get activeChatParticipats() {
    return this.activeChatBucket?.participants ?? null;
  }

  //#endregion --- Getters

  //#region --- Actions

  public sendMessageEmitter = (messageText: string, replyMessageId?: string) => {
    if (!this.privateChatsStore.currentChatId) return;
    let dto: SendPrivateMessageDto = {
      message: messageText,
      privateChatId: this.privateChatsStore.currentChatId,
    };
    if (replyMessageId) dto['repliedOnMessageId'] = replyMessageId;
    appSocket.dispatch(SocketEventsCollection.PrivateMessageEvents.Emitters.SendMessage, dto);
  };

  public editMessageEmitter = (details: EditMessageDetails) => {
    appSocket.dispatch(SocketEventsCollection.PrivateMessageEvents.Emitters.EditMessage, {
      message: details.messageText,
      messageId: details.messageId,
      privateChatId: details.chatId,
      attachments: details.attachments,
    });
  };

  public updateEmojiEmitter = (details: PrivateMessageReactionDto) => {
    appSocket.dispatch(SocketEventsCollection.PrivateMessageEvents.Emitters.ToggleEmoji, details);
  };

  public awaitedLoadMessages = async (
    triggeredMessage: MessageStore,
    direction: ObjectValues<typeof LoadMessageDirection>,
  ) => {
    if (!this.privateChatsStore.currentChatId) return;

    const chatBucket = this.activeChatBucket;

    if (this.canLoadNewMessages(chatBucket)) return;

    const dto = {
      direction,
      paginationMessageId: triggeredMessage.id,
      limit: 20,
      skip: 0,
      privateChatId: triggeredMessage.chatId,
    };

    try {
      const response = await appSocket.awaitedDispatch(
        SocketEventsCollection.PrivateMessageEvents.Emitters.GetMessages,
        SocketEventsCollection.PrivateMessageEvents.Subscribers.GetMessages,
        dto,
      );

      const prevMessages = mapToArray(chatBucket.messages);

      if (response.pagination.direction === LoadMessageDirection.Up) {
        const updMessagesMap = this.updatedMessagesByUpDirection(
          response.messages,
          prevMessages,
          chatBucket,
        );
        runInAction(() => {
          chatBucket.messages = updMessagesMap;
          chatBucket.pagination.firstMessagePresent = response.pagination.firstMessagePresent;
        });
        return;
      }

      if (response.pagination.direction === LoadMessageDirection.Down) {
        const updMessagesMap = this.updatedMessagesByDownDirection(
          response.messages,
          prevMessages,
          chatBucket,
        );
        runInAction(() => {
          chatBucket.messages = updMessagesMap;
          chatBucket.pagination.lastMessagePresent = response.pagination.lastMessagePresent;
        });
      }
    } catch (error) {}
  };

  private viewMessagesEmitter = debounce((chatId: string) => {
    appSocket.dispatch(SocketEventsCollection.PrivateMessageEvents.Emitters.ViewMessages, {
      messageIds: Array.from(this.needToViewMessages, (id) => id),
      privateChatId: chatId,
    });

    this.needToViewMessages = new Set();
  }, 200);

  private viewMessagesTrigger = (messageId: string, chatId: string) => {
    const chat = this.privateChatsStore.getChatById(chatId);

    if (chat?.lastMessage?.id === messageId) {
      chat.lastMessage.isViewed = true;
    }

    this.needToViewMessages.add(messageId);
    this.viewMessagesEmitter(chatId);
  };

  receiveViewerMessage = (callback?: (newMessage: MessageStore) => void) => {
    return appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.GetNewMessage,
      (newMessage) => {
        callback?.(this.createMessageStore(newMessage));
      },
    );
  };

  receiveTemplateMessage = (callback?: (newMessage: MessageStore) => void) => {
    return appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.ReceiveTemplateMsg,
      (newMessage) => {
        callback?.(this.createMessageStore(newMessage));
      },
    );
  };

  awaitedReadAllMessages = async (chatId: string) => {
    const bucket = this.getChatBucketByChatId(chatId);
    const chat = this.privateChatsStore.getChatById(chatId);

    if (!bucket) return;

    try {
      const { messages, privateChatId } = await appSocket.awaitedDispatch(
        SocketEventsCollection.PrivateMessageEvents.Emitters.ViewAllMessages,
        SocketEventsCollection.PrivateMessageEvents.Subscribers.ViewAllMessages,
        {
          privateChatId: chatId,
        },
      );

      const lastNewMessage = messages[messages.length - 1];
      const isExistOnBucket = bucket?.messages.has(lastNewMessage.id);

      runInAction(() => {
        if (!isExistOnBucket) {
          this.replaceMessagesByChatId(privateChatId, messages);
        }
        if (bucket) {
          bucket.pagination.lastMessagePresent = true;
        }

        chat?.updateUnreadCount(0);
      });
    } catch (error) {}
  };

  //#endregion --- Actions

  //#region --- Subscribers
  private joinToPrivateChatSubscriber = (details: JointToPrivateChatResponse) => {
    const { privateChatId, messages, pagination, participants } = details;

    let bucket: ChatBucket;

    const existBucket = this._chatsBuckets.get(privateChatId);

    if (existBucket) {
      bucket = existBucket;
    } else {
      bucket = this.createEmptyBucket(privateChatId);
    }

    const _participants = this.receiveParticipants(participants);

    for (const message of messages) {
      bucket.messages.set(
        message.id,
        this.createMessageStore(message, _participants[message.authorId]),
      );
    }

    runInAction(() => {
      bucket.pagination = { ...bucket.pagination, ...pagination };
      bucket.participants = _participants;
      bucket.companion = this.findCompanion(participants);
      this.privateChatsStore.loader.completeLoading();
      bucket.loader.completeLoading();
    });
  };

  private getMessageSubscriber = (details: SendPrivateMessageResponse) => {
    const collection = this.activeChatBucket;

    if (
      collection &&
      (collection.pagination.lastMessagePresent || collection.messages.size === 0)
    ) {
      const participant = collection.participants[details.authorId];
      runInAction(() => {
        collection.messages.set(details.id, this.createMessageStore(details, participant));
        collection.pagination.lastMessagePresent = true;
      });
    }
  };

  private getMessageReactionsSubscriber = (reactions: PrivateMessageReactionEntity) => {
    const collection = this.activeChatBucket;

    if (collection) {
      const message = collection.messages.get(reactions.messageId);

      if (message) {
        runInAction(() => {
          message.reactions.reactions = reactions.reactions;
        });
      }
    }
  };

  private receiveUnread = (unreadMessage: SendPrivateMessageResponse) => {
    const chat = this.privateChatsStore.getChatById(unreadMessage.privateChatId);

    if (!chat) return;

    if (unreadMessage.authorId !== this.userStore.user.id) {
      chat.increaseUnreadCount();
    }

    chat.lastMessage = this.createMessageStore(unreadMessage, chat.participant);
    chat.lastMessage.isViewed = false;
  };

  private editMessageSubscriber = (details: SendPrivateMessageResponse) => {
    const chat = this.privateChatsStore.getChatById(details.privateChatId);

    if (!chat) return;

    this.activeChatMessages.forEach((message) => {
      if (message.id === details.id) {
        message.message = details.message;
        message.isEdited = details.isEdited;
      }

      if (chat.lastMessage && chat.lastMessage.id === details.id) {
        chat.lastMessage.message = details.message;
        chat.lastMessage.isEdited = details.isEdited;
      }
    });
  };

  //#endregion --- Subscribers

  //#region --- Helpers

  public replaceMessagesByChatId = (chatId: string, messages: PrivateChatMessageEntity[]) => {
    const bucket = this.getChatBucketByChatId(chatId);

    if (!bucket) return;

    const newMessages = this.createMessageStoresArray(messages);

    runInAction(() => {
      bucket.messages = newMessages;
    });
  };

  public getChatBucketByChatId = (chatId: string) => {
    return this._chatsBuckets.get(chatId) ?? null;
  };

  public getMessagesByChatId = (chatId: string) => {
    const chatBucket = this.getChatBucketByChatId(chatId);

    if (!chatBucket) return [];

    return mapToArray(chatBucket.messages);
  };

  private canLoadNewMessages(chatBucket: ChatBucket | null): chatBucket is null {
    return (
      !chatBucket ||
      (chatBucket.pagination.lastMessagePresent && chatBucket.pagination.firstMessagePresent)
    );
  }

  private updatedMessagesByUpDirection = (
    newMsgs: PrivateChatMessageEntity[],
    prevMessages: MessageStore[],
    chatBucket: ChatBucket,
  ) => {
    const _newMsgs = mapToArray(
      this.createMessageStoresArray([...newMsgs].reverse(), chatBucket.participants),
    );
    const messagesMap: ChatBucket['messages'] = new Map();
    _newMsgs.forEach((msg) => messagesMap.set(msg.id, msg));
    prevMessages.forEach((msg) => messagesMap.set(msg.id, msg));

    return messagesMap;
  };

  private updatedMessagesByDownDirection = (
    newMsgs: PrivateChatMessageEntity[],
    prevMessages: MessageStore[],
    chatBucket: ChatBucket,
  ) => {
    const _newMsgs = mapToArray(this.createMessageStoresArray(newMsgs, chatBucket.participants));
    const messagesMap: ChatBucket['messages'] = new Map();
    prevMessages.forEach((msg) => messagesMap.set(msg.id, msg));
    _newMsgs.forEach((msg) => messagesMap.set(msg.id, msg));

    return messagesMap;
  };

  private get activeChatBucket() {
    if (!this.privateChatsStore.currentChatId) return null;

    return this._chatsBuckets.get(this.privateChatsStore.currentChatId) ?? null;
  }

  private createMessageStoresArray = (
    messages: PrivateChatMessageEntity[],
    chatParticipants?: ChatBucket['participants'],
  ) => {
    return messages.reduce((acc: Map<string, MessageStore>, message) => {
      const participant = chatParticipants?.[message.authorId];

      acc.set(message.id, this.createMessageStore(message, participant));
      return acc;
    }, new Map());
  };

  private createMessageStore = (
    message: PrivateChatMessageEntity,
    messageParticipant?: PrivateChatParticipantEntity,
  ) => {
    const { privateChatId, ...msg } = message;

    return new MessageStore(
      {
        ...msg,
        chatId: privateChatId,
        isMessageFromViewer: this.userStore.user.id === msg.authorId,
        ownerImage: withMediaUrl(messageParticipant?.image ?? ''),
      },
      this.viewMessagesTrigger,
      this.editMessageEmitter,
    );
  };

  private receiveParticipants = (participants: PrivateChatParticipantEntity[]) => {
    return participants.reduce((acc: ChatBucket['participants'], participant) => {
      if (!acc[participant.id]) {
        acc[participant.id] = participant;
      }
      return acc;
    }, {});
  };

  private createEmptyBucket(privateChatId: string) {
    const newBucket = {
      loader: new LoadingStore(),
      pagination: {} as ChatBucket['pagination'],
      messages: new Map(),
      participants: {},
      companion: null,
    };
    return this._chatsBuckets.set(privateChatId, newBucket).get(privateChatId) as ChatBucket;
  }

  private findCompanion(participants: PrivateChatParticipantEntity[]) {
    return participants.find((participant) => participant.id !== this.userStore.user.id) ?? null;
  }

  //#endregion --- Helpers

  private joinToChatListener = (details: JoinToPrivateChatDto) => {
    const bucket = this._chatsBuckets.get(details.privateChatId);

    if (!bucket) {
      const newBucket = this.createEmptyBucket(details.privateChatId);

      newBucket.loader.startLoading();
    } else {
      bucket.loader.startLoading();
    }
  };

  private initListeners = () => {
    appSocket.subscribeDispatch(
      SocketEventsCollection.PrivateMessageEvents.Emitters.JoinToChatById,
      this.joinToChatListener,
    );
  };

  private initSubscribers = () => {
    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.JoinToChatById,
      this.joinToPrivateChatSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.GetNewMessage,
      this.getMessageSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.ReceiveTemplateMsg,
      this.getMessageSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.ToggleEmoji,
      this.getMessageReactionsSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.ReceiveUnread,
      this.receiveUnread,
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.ViewMessages,
      (details) => {
        runInAction(() => {
          const bucket = this.getChatBucketByChatId(details.privateChatId);
          if (!bucket) return;
          for (const msgId of details.viewedMessageIds) {
            const msg = bucket.messages.get(msgId);
            if (msg) {
              msg.viewCount += 1;
            }
          }
        });
      },
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.EditMessage,
      this.editMessageSubscriber,
    );
  };

  initEvents = () => {
    this.initListeners();
    this.initSubscribers();
  };

  disposer = () => {
    this._chatsBuckets = new Map();
  };
}
