// import { useEffect, useRef, useState } from 'react';
import { useCommunityMessageListController } from '../community-messages';
import { useCommunityHeaderController } from './community-header.controller';

// import { useTranslation } from 'react-i18next';
import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { Box, Button, Group, Stack, SvgIcon, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _CommunityChatHeader = observer(() => {
  // const { t } = useTranslation();

  // const [isCanShow, setIsCanShow] = useState(false);
  // const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const {
    chat,
    chatIconType,
    isPinnedMessagesVisible,
    // unreadCount,
    onClickPinBtn,
    // onClickReadAllBtn,
  } = useCommunityHeaderController();

  const { onScrollToBottom } = useCommunityMessageListController();

  // useEffect(() => {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }

  //   timeoutRef.current = setTimeout(() => {
  //     if (unreadCount > 0) {
  //       setIsCanShow(true);
  //     } else setIsCanShow(false);
  //   }, 300);

  //   return () => {
  //     if (timeoutRef.current) {
  //       clearTimeout(timeoutRef.current);
  //       timeoutRef.current = null;
  //     }
  //   };
  // }, [unreadCount]);

  const handlePinBtnClick = () => {
    onClickPinBtn();
    if (isPinnedMessagesVisible) {
      onScrollToBottom();
    }
  };

  if (!chat) return null;

  return (
    <Group wrap="nowrap" w="100%" className={s.communityChatHeader}>
      <Box as="span" className={s.communityChatHeaderIconBox}>
        <SvgIcon type={chatIconType} />
      </Box>
      <Stack gap="xs-2" align="flex-start" w="calc(100% - 60px)">
        <TypographyNew as="span" variant="body-2" weight="bold">
          {chat.title}
        </TypographyNew>
        <TypographyNew as="p" truncate variant="body-3">
          {chat.description}
        </TypographyNew>
      </Stack>
      <Button
        variant="outline"
        size="icon"
        disabledAnimation
        onClick={handlePinBtnClick}
        className={cl(s.communityChatHeaderPinBtn, {
          [s.communityChatHeaderPinBtnActive]: isPinnedMessagesVisible,
        })}
      >
        <SvgIcon type="pin-filled" />
      </Button>
      {/* {unreadCount > 0 && (
        <button onClick={onClickReadAllBtn} className={s.communityChatHeaderReadAllBtn}>
          <TypographyNew as="span" variant="body-4">
            {unreadCount} {t('community.channels.unread-messages')}
          </TypographyNew>
          <TypographyNew as="span" variant="body-4">
            {t('community.channels.mark-all-as-read')}
          </TypographyNew>
        </button>
      )} */}
    </Group>
  );
});

export const CommunityChatHeader = mergeComponentsWithName(
  'CommunityChatHeader',
  _CommunityChatHeader,
);
