import { ContextMenuController } from './context-menu.controller';

import { createSafeContext } from '@shared/utils';

interface ContextMenuValue {
  controller: ContextMenuController;
}

export const [ContextMenuProvider, useContextMenuContext] =
  createSafeContext<ContextMenuValue>('ContextMenu');

export const useContextMenu = () => {
  const { controller } = useContextMenuContext();

  return {
    show: controller.show,
    hide: controller.hide,
  };
};
