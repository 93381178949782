import type { MutableRefObject, ReactNode } from 'react';
import { useMemo } from 'react';

import { BubbleEditorMenu } from './bubble-editor-menu';
import { RichEditorProvider } from './rich-editor.context';
import type { KeyboardHandlerOptions } from './rich-editor.extensions';
import { KeyboardHandler } from './rich-editor.extensions';
import type { BulletListOptions } from '@tiptap/extension-bullet-list';
import BulletList from '@tiptap/extension-bullet-list';
import type { LinkOptions } from '@tiptap/extension-link';
import Link from '@tiptap/extension-link';
import type { ListItemOptions } from '@tiptap/extension-list-item';
import ListItem from '@tiptap/extension-list-item';
import type { MentionOptions } from '@tiptap/extension-mention';
import Mention from '@tiptap/extension-mention';
import type { OrderedListOptions } from '@tiptap/extension-ordered-list';
import OrderedList from '@tiptap/extension-ordered-list';
import type { PlaceholderOptions } from '@tiptap/extension-placeholder';
import Placeholder from '@tiptap/extension-placeholder';
import type { UnderlineOptions } from '@tiptap/extension-underline';
import Underline from '@tiptap/extension-underline';
import type { Editor, EditorOptions, Extensions } from '@tiptap/react';
import { EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { suggestion } from '@widgets';

import { observer } from '@shared/libs/mobx';

import { Box, Group, Stack } from '@shared/UI';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const WHITE_SPACE_BETWEEN_TAGS_PATTERN = /^<p>\s*<\/p>$/i;

const { rootClass, appendClass } = classNames('rich-text-editor');

type ExtensionOption<T extends Record<string, any>> = boolean | Partial<T>;

type ExtensionsOptions = {
  keyboard: ExtensionOption<KeyboardHandlerOptions>;
  underline: ExtensionOption<UnderlineOptions>;
  listItem: ExtensionOption<ListItemOptions>;
  bulletList: ExtensionOption<BulletListOptions>;
  orderedList: ExtensionOption<OrderedListOptions>;
  placeholder: ExtensionOption<PlaceholderOptions>;
  link: ExtensionOption<LinkOptions>;
  mention: ExtensionOption<MentionOptions>;
};

export interface RichTextEditorProps {
  onChange?: (value: string) => void;

  content?: string;

  editorOptions?: Partial<Omit<EditorOptions, 'extensions' | 'onUpdate' | 'content'>>;

  editorRef?: MutableRefObject<Editor | undefined>;

  headerSlot?: ReactNode;

  slotBefore?: ReactNode;

  slotAfter?: ReactNode;

  onSubmit?: (value: string, editor: Editor) => void;

  extensionsOptions?: Partial<ExtensionsOptions>;
}

function createDefaultExtensionOptions(
  options: Partial<ExtensionsOptions> = {},
): ExtensionsOptions {
  return Object.assign(
    {
      keyboard: true,
      underline: true,
      listItem: true,
      bulletList: true,
      orderedList: true,
      placeholder: true,
      link: true,
      mention: true,
    },
    options,
  );
}

function objectExtensionOptions(options: ExtensionOption<{}>) {
  if (typeof options === 'boolean') {
    return {};
  }

  return options;
}

const _RichTextEditor = observer((props: RichTextEditorProps) => {
  const {
    onChange,
    onSubmit,
    content = '',
    editorRef,
    editorOptions,
    slotAfter,
    slotBefore,
    extensionsOptions,
  } = props;

  const extensions = useMemo((): Extensions => {
    const result: Extensions = [];
    const defaultExtensionOptions = createDefaultExtensionOptions(extensionsOptions);

    if (Boolean(defaultExtensionOptions.keyboard)) {
      result.push(
        KeyboardHandler.configure({
          onSubmit,
          ...objectExtensionOptions(defaultExtensionOptions.keyboard),
        }),
      );
    }

    if (Boolean(defaultExtensionOptions.underline)) {
      result.push(
        Underline.configure({
          ...objectExtensionOptions(defaultExtensionOptions.underline),
        }),
      );
    }

    if (Boolean(defaultExtensionOptions.listItem)) {
      result.push(
        ListItem.configure({
          ...objectExtensionOptions(defaultExtensionOptions.listItem),
        }),
      );
    }

    if (Boolean(defaultExtensionOptions.bulletList)) {
      result.push(
        BulletList.configure({
          keepMarks: true,
          HTMLAttributes: {
            class: appendClass('-content-bullet-list'),
          },
          ...objectExtensionOptions(defaultExtensionOptions.bulletList),
        }),
      );
    }

    if (Boolean(defaultExtensionOptions.orderedList)) {
      result.push(
        OrderedList.configure({
          keepMarks: true,
          HTMLAttributes: {
            class: appendClass('-content-ordered-list'),
          },
          ...objectExtensionOptions(defaultExtensionOptions.orderedList),
        }),
      );
    }

    if (Boolean(defaultExtensionOptions.placeholder)) {
      result.push(
        Placeholder.configure({
          emptyEditorClass: appendClass('-content-empty'),
          placeholder: 'Your message...',
          ...objectExtensionOptions(defaultExtensionOptions.placeholder),
        }),
      );
    }

    if (Boolean(defaultExtensionOptions.link)) {
      result.push(
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            class: appendClass('-content-link'),
          },
          autolink: false,
          ...objectExtensionOptions(defaultExtensionOptions.link),
        }),
      );
    }

    if (Boolean(defaultExtensionOptions.mention)) {
      result.push(
        Mention.configure({
          renderLabel({ options, node }) {
            return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
          },
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion,
          ...objectExtensionOptions(defaultExtensionOptions.mention),
        }),
      );
    }

    return result;
  }, [extensionsOptions]);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: false,
        orderedList: false,
        listItem: false,
      }),
      ...extensions,
    ],
    onUpdate: ({ editor }) => {
      let value = editor.getHTML();
      let json = editor.getJSON().content;

      if (Array.isArray(json) && json.length === 1 && !json[0].hasOwnProperty('content')) {
        value = '';
      }

      if (WHITE_SPACE_BETWEEN_TAGS_PATTERN.test(value)) {
        value = '';
      }

      onChange?.(value);
    },
    onCreate: (params) => {
      if (editorRef !== undefined) {
        editorRef.current = params.editor as Editor;
      }
    },
    content,
    ...editorOptions,
  });

  if (!editor) return null;

  const _editor = () => {
    return editor;
  };

  return (
    <RichEditorProvider value={{ editor: _editor }}>
      <Box className={rootClass}>
        <Stack>
          <Group wrap="nowrap" w="100%">
            {slotBefore}
            <Box className={appendClass('-content-container')}>
              <BubbleEditorMenu editor={_editor} />
              <EditorContent className={appendClass('-content')} editor={editor} />
            </Box>
            {slotAfter}
          </Group>
        </Stack>
      </Box>
    </RichEditorProvider>
  );
});

export const RichTextEditor = mergeComponentsWithName('RichTextEditor', _RichTextEditor);
