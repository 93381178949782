import type { ReactNode } from 'react';

import { FormErrorMessage, FormLabel } from '../_components';
import type { FormErrorMessageProps, FormLabelProps } from '../_components';
import { observer } from 'mobx-react-lite';
import type { Control, FieldError, FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';

import { Field } from './field';

import s from './styles.module.scss';

type PhoneProps<TFormValues extends FieldValues> = {
  nameCode: FieldPath<TFormValues>;
  namePhone: FieldPath<TFormValues>;
  rulesCode?: RegisterOptions;
  rulesPhone?: RegisterOptions;
  control?: Control<TFormValues>;
  label?: ReactNode;
  error?: FieldError;

  labelElementProps?: Omit<FormLabelProps, 'content' | 'htmlFor'>;
  errorElementProps?: Omit<FormErrorMessageProps, 'message'>;
};

export const Phone = observer(function Phone<TFormValues extends FieldValues>(
  props: PhoneProps<TFormValues>,
) {
  const {
    nameCode,
    namePhone,
    label,
    error,
    control,
    labelElementProps,
    errorElementProps,
    rulesPhone,
    rulesCode,
  } = props;

  const {} = control || {};

  const hasLabel = !!label;

  return (
    <div className={s.formFieldPhone}>
      <div className={s.textContainer}>
        {hasLabel && <FormLabel content={label} htmlFor={namePhone} {...labelElementProps} />}
        {error && <FormErrorMessage message={error?.message} {...errorElementProps} />}
      </div>
      <Field
        error={error}
        namePhone={namePhone}
        control={control}
        nameCode={nameCode}
        rulesPhone={rulesPhone}
        rulesCode={rulesCode}
      />
    </div>
  );
});
