import { AuthType } from '@constants';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { toast } from 'react-toastify';

import { ApiError } from '@core/constants';
import type {
  AuthViaAppleDto,
  AuthViaGoogleDto,
  ForgotPasswordFirstStepDto,
  ForgotPasswordSecondStepDto,
  SignInByEmailDto,
  SignInByPhoneDto,
  SignUpByEmailDto,
  SignUpByPhoneDto,
  VerifyByEmailDto,
  VerifyByPhoneDto,
} from '@core/repositories';
import { AuthRepository, UserRepository } from '@core/repositories';
import { getHttpError } from '@core/services/http';
import type { AppLoaderStore, OnBoardingStore } from '@core/store';
import type { UserStore } from '@core/store/user';

import type { ObjectKeys, ObjectValues } from '@shared/types';
import { transformPhoneNumber } from '@shared/utils';

const DEFAULT_VALUE_BY_EMAIL = {
  email: '',
  isOTPVisible: false,
};

const DEFAULT_VALUE_BY_PHONE = {
  phone: '',
  code: '+380',
  isOTPVisible: false,
};

const DEFAULT_VALUE_FORGOT_PASSWORD_FIRST_STEP = {
  email: '',
};

const DEFAULT_FORM_VALUES = {
  signInByEmail: DEFAULT_VALUE_BY_EMAIL,
  signInByPhone: DEFAULT_VALUE_BY_PHONE,
  signUpByEmail: DEFAULT_VALUE_BY_EMAIL,
  signUpByPhone: DEFAULT_VALUE_BY_PHONE,
  forgotPasswordFirstStep: DEFAULT_VALUE_FORGOT_PASSWORD_FIRST_STEP,
};

export class AuthStore {
  preferredAuthType: ObjectValues<typeof AuthType> = AuthType.email;

  isUserHasAccess = false;

  isReCaptchaLoading = true;

  isAuthLoading = false;

  isCheckingAuth = false;

  isUserFromSharedLink = false;

  defaultFormValues = DEFAULT_FORM_VALUES;

  repository = new AuthRepository();

  userRepository = new UserRepository();

  constructor(
    private onBoardingStore: OnBoardingStore,
    private userStore: UserStore,
    private appLoaderStore: AppLoaderStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'AuthStore',
      properties: ['preferredAuthType', 'isReCaptchaLoading', 'defaultFormValues'],
    });

    this.checkAuth();
  }

  signInByEmail = async (dto: SignInByEmailDto) => {
    this.isAuthLoading = true;
    try {
      const data = await this.repository.signIn(dto);
      runInAction(() => {
        this.defaultFormValues.signInByEmail = {
          ...this.defaultFormValues.signInByEmail,
          email: dto.email,
          isOTPVisible: true,
        };
      });
      return data;
    } catch (e) {
      const err = getHttpError(e);

      if (ApiError.OTP_SENT === err.message) {
        runInAction(() => {
          this.defaultFormValues.signInByEmail.isOTPVisible = true;
        });
      } else {
        runInAction(() => {
          this.defaultFormValues.signInByEmail.isOTPVisible = false;
        });
      }

      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
      });
    }
  };

  signInByPhone = async (dto: SignInByPhoneDto & { code: string }) => {
    this.isAuthLoading = true;
    try {
      const { phone, code } = dto;

      const finalPhone = transformPhoneNumber(phone, code);

      const data = await this.repository.signIn({ phone: finalPhone });
      runInAction(() => {
        this.defaultFormValues.signInByPhone = {
          ...this.defaultFormValues.signInByPhone,
          phone,
          code,
          isOTPVisible: true,
        };
      });
      return data;
    } catch (e) {
      const err = getHttpError(e);
      if (ApiError.OTP_SENT === err.message) {
        runInAction(() => {
          this.defaultFormValues.signInByPhone.isOTPVisible = true;
        });
      } else {
        runInAction(() => {
          this.defaultFormValues.signInByEmail.isOTPVisible = false;
        });
      }

      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
      });
    }
  };

  signUpByEmail = async (dto: SignUpByEmailDto) => {
    this.isAuthLoading = true;
    try {
      const data = await this.repository.signUp(dto);

      runInAction(() => {
        this.defaultFormValues.signUpByEmail = {
          ...this.defaultFormValues.signUpByEmail,
          email: dto.email,
          isOTPVisible: true,
        };
      });
      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
      });
    }
  };

  signUpByPhone = async (dto: SignUpByPhoneDto & { code: string }) => {
    this.isAuthLoading = true;

    const { phone, code } = dto;
    const finalPhone = transformPhoneNumber(phone, code);

    try {
      const data = await this.repository.signUp({ phone: finalPhone });

      runInAction(() => {
        this.defaultFormValues.signUpByPhone = {
          ...this.defaultFormValues.signUpByPhone,
          phone,
          code,
          isOTPVisible: true,
        };
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
      });
    }
  };

  authViaGoogle = async (dto: AuthViaGoogleDto) => {
    this.isAuthLoading = true;
    try {
      const data = await this.repository.authViaGoogle(dto);

      this.setIsUserHasAccess(true);

      this.onBoardingStore.completeOnBoarding();

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
      });
    }
  };

  authViaApple = async (dto: AuthViaAppleDto) => {
    this.isAuthLoading = true;
    try {
      const data = await this.repository.authViaApple(dto);

      this.setIsUserHasAccess(true);

      this.onBoardingStore.completeOnBoarding();

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
      });
    }
  };

  forgotPasswordFirstStep = async (dto: ForgotPasswordFirstStepDto) => {
    this.isAuthLoading = true;
    try {
      const data = await this.repository.forgotPasswordFirstStep(dto);

      runInAction(() => {
        this.defaultFormValues.forgotPasswordFirstStep = {
          email: dto.email,
        };
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
      });
    }
  };

  logout = async () => {
    try {
      await this.repository.logout();
      runInAction(() => {
        this.isUserHasAccess = false;
      });
    } catch (e) {}
  };

  forgotPasswordSecondStep = async (dto: ForgotPasswordSecondStepDto) => {
    this.isAuthLoading = true;
    try {
      const data = await this.repository.forgotPasswordSecondStep(dto);

      runInAction(() => {
        this.defaultFormValues.signInByEmail.email = dto.email;
        this.clearFormValues('forgotPasswordFirstStep');
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
      });
    }
  };

  verify = async (dto: VerifyByEmailDto | VerifyByPhoneDto) => {
    this.isAuthLoading = true;
    try {
      const { user } = await this.repository.verify(dto);

      runInAction(() => {
        if (user.name) {
          this.onBoardingStore.completeOnBoarding();
        }

        this.clearFormValues();
        this.setIsUserHasAccess(true);
      });

      const data = await this.userStore.getMe();

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
      });
    }
  };

  setPreferredAuthType = (type: ObjectKeys<typeof AuthType>) => {
    this.preferredAuthType = type;
  };

  setIsOTPVisible = (
    value: boolean,
    type: keyof Omit<typeof DEFAULT_FORM_VALUES, 'forgotPasswordFirstStep'>,
  ) => {
    this.defaultFormValues[type].isOTPVisible = value;
  };

  setIsUserHasAccess = (value: boolean) => {
    this.isUserHasAccess = value;
  };

  setIsReCaptchaLoading = (value: boolean) => {
    this.isReCaptchaLoading = value;
  };

  clearFormValues = (type?: ObjectKeys<typeof DEFAULT_FORM_VALUES>) => {
    if (type) {
      this.defaultFormValues[type] = DEFAULT_FORM_VALUES[type] as any;
    } else {
      this.defaultFormValues = DEFAULT_FORM_VALUES;
    }
  };

  disposer = () => {
    this.clearFormValues();
    this.setIsUserHasAccess(false);
    this.setIsReCaptchaLoading(false);
    this.isAuthLoading = false;
  };

  private checkAuth = async () => {
    this.isAuthLoading = true;
    this.isCheckingAuth = true;
    this.appLoaderStore.startLoading();
    try {
      const { user } = await this.userStore.getMe();

      runInAction(() => {
        if (user.name) {
          this.onBoardingStore.completeOnBoarding();
        }
        this.setIsUserHasAccess(true);
      });
    } catch (e) {
    } finally {
      runInAction(() => {
        this.isAuthLoading = false;
        this.appLoaderStore.stopLoading();
        this.isCheckingAuth = false;
      });
    }
  };
}
