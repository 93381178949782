import { Dropdown } from '../../../dropdown';
import { SvgIcon } from '../../../svg-icon';
import { useRichEditorContext } from '../../rich-editor.context';
import { EditorMenuItem } from '../editor-menu-item';
import { DropdownContent } from './dropdown-content';
import { useModalState } from '@hooks';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

const _BtnLink = observer(() => {
  const { editor } = useRichEditorContext();
  const { isOpen, onToggle, onClose } = useModalState();

  const icon = <SvgIcon type="editor-link" />;

  return (
    <Dropdown
      placement="top"
      isOpen={isOpen}
      onOpenChange={onToggle}
      content={<DropdownContent onClose={onClose} />}
    >
      <EditorMenuItem icon={icon} isActive={editor().isActive('link')} />
    </Dropdown>
  );
});

export const BtnLink = mergeComponentsWithName('BtnLink', _BtnLink);
