import { LoadingStore } from '../../common';
import { ChatStore } from './chat.store';
import { makeAutoObservable, runInAction } from 'mobx';

import { SocketEventsCollection } from '@core/constants';
import type {
  CommunityChatEntity,
  ConnectToCategoryDto,
  ConnectToChatDto,
  ConnectToChatResponse,
  CreateCommunityChatResponse,
  UpdateCommunityChatResponse,
  ViewAllMessagesResponse,
} from '@core/repositories';
import { appSocket } from '@core/services/socket';
import type { ChannelsStore } from '@core/store';

type ChatsBucket = {
  loader: LoadingStore;
  chats: Map<string, ChatStore>;
};

/*
 * key - channelId
 * value - chat bucket
 */
type ChatBuckets = Map<string, ChatsBucket>;

export class CommunityChatsStore {
  chatsLoader = new LoadingStore();

  particularChatLoader = new LoadingStore();

  private _channelChats: ChatBuckets = new Map();

  private _activeChat: ChatStore | null = null;

  constructor(private channelsStore: ChannelsStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    // this.initListeners();
    // this.initSubscribers();
  }

  //#region --- Getters
  get activeChat() {
    return this._activeChat;
  }

  get allChats() {
    const chats: ChatStore[] = [];
    Array.from(this._channelChats, ([, channel]) =>
      Array.from(channel.chats, ([, chat]) => chats.push(chat)),
    );
    return chats;
  }

  get chatsForActiveChannel() {
    if (!this.channelsStore.activeChannel) return [];
    const chats = this._channelChats.get(this.channelsStore.activeChannel.channel.id)?.chats;

    return Array.from(chats ?? [], ([, chat]) => chat);
  }

  get chatsMap() {
    return this._channelChats;
  }
  //#endregion --- Getters

  //#region --- Emitters
  connectToChatEmitter = (details: ConnectToChatDto) => {
    if (this._activeChat?.id === details.communicationItemId) return;
    this.particularChatLoader.startLoading();
    appSocket.dispatch(
      SocketEventsCollection.CommunityEvents.Emitters.ConnectCommunication,
      details,
    );
  };

  awaitedConnectToChatEmitter = async (details: ConnectToChatDto) => {
    if (this._activeChat?.id === details.communicationItemId) return;

    try {
      const response = await appSocket.awaitedDispatch(
        SocketEventsCollection.CommunityEvents.Emitters.ConnectCommunication,
        SocketEventsCollection.CommunityEvents.Subscribers.ConnectCommunication,
        details,
      );

      runInAction(() => {
        this._activeChat = this.getChatById(response.communicationItemId);
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  awaitedForceConnectToChatEmitter = async (details: ConnectToChatDto) => {
    try {
      const response = await appSocket.awaitedDispatch(
        SocketEventsCollection.CommunityEvents.Emitters.ConnectCommunication,
        SocketEventsCollection.CommunityEvents.Subscribers.ConnectCommunication,
        details,
      );

      runInAction(() => {
        this._activeChat = this.getChatById(response.communicationItemId);
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateChatPositionEmitter = (chats: ChatStore[], channelId: string) => {
    const channel = this._channelChats.get(channelId);

    if (!channel) return;

    const updatedChatsMap = new Map();

    chats.forEach((chat) => {
      updatedChatsMap.set(chat.id, chat);
    });

    runInAction(() => {
      channel.chats = updatedChatsMap;
    });

    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.UpdateCommunicationOrder, {
      categoryId: channelId,
      communicationItems: chats.map((chat, idx) => ({ id: chat.id, position: idx })),
    });
  };

  readAllMessagesEmitter = (chatId: string) => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.ViewAllMessages, {
      communicationItemId: chatId,
    });
  };

  //#endregion --- Emitters

  //#region --- Helpers

  getChatById = (chatId: string) => {
    return this.allChats.find((chat) => chat.id === chatId) ?? null;
  };

  getChatsByChannelId = (channelId: string) => {
    const channel = this._channelChats.get(channelId);

    if (!channel) return [];
    return Array.from(channel.chats, ([, value]) => value);
  };

  getChatsLoadingByChannelId = (channelId: string) => {
    const channel = this._channelChats.get(channelId);
    if (!channel) return false;
    return channel.loader.isLoading;
  };

  readMessageInChat = (chatId: string) => {
    if (!this.channelsStore.activeChannel) return;

    const chat = this._channelChats
      .get(this.channelsStore.activeChannel.channel.id)
      ?.chats.get(chatId);

    if (chat) {
      chat.decreaseUnreadCount();
    }
  };

  chatBucketById = (channelId: string) => {
    return this._channelChats.get(channelId);
  };

  resetActiveChat = () => {
    this._activeChat = null;
  };

  //#endregion --- Helpers

  //#region --- Subsribers
  private connectToChatSubscriber = (details: ConnectToChatResponse) => {
    this._activeChat = this.getChatById(details.communicationItemId);
    this.particularChatLoader.stopLoading();
  };

  private connectToChannelListener = (details: ConnectToCategoryDto) => {
    const chatsLength = this._channelChats.get(details.categoryId)?.chats.size || 0;

    if (chatsLength === 0) {
      this.chatsLoader.startLoading();

      runInAction(() => {
        this._channelChats.set(details.categoryId, {
          loader: new LoadingStore(true),
          chats: new Map(),
        });
      });
    }
  };

  private connectToChannelSubscriber = (details: CommunityChatEntity[]) => {
    const channelId = details[0]
      ? details[0].categoryId
      : this.channelsStore.activeChannel?.channel.id
      ? this.channelsStore.activeChannel?.channel.id
      : null;

    if (!channelId) {
      this.chatsLoader.stopLoading();
      return;
    }

    const chatsBucket = this._channelChats.get(channelId);

    const channelBucket = this.channelsStore.channelBucketById(channelId);

    runInAction(() => {
      channelBucket?.loading.completeLoading();
      details.forEach((chat) => {
        chatsBucket?.chats.set(chat.id, new ChatStore(chat));
      });
    });

    // setTimeout(() => {
    this.chatsLoader.stopLoading();

    chatsBucket?.loader.stopLoading();
    // }, 300);
  };

  private createCommunityChatSubscriber = (details: CreateCommunityChatResponse) => {
    const collection = this._channelChats.get(details.categoryId) as ChatsBucket;

    runInAction(() => {
      collection.chats.set(details.id, new ChatStore(details));
    });
  };

  private updateChatSubscriber = (details: UpdateCommunityChatResponse) => {
    const collection = this._channelChats.get(details.categoryId);

    if (collection) {
      const chat = collection.chats.get(details.id);

      chat?.updateChatInfo(details);
    }
  };

  private readAllMessagesSubscriber = (details: ViewAllMessagesResponse) => {
    const chat = this.getChatById(details.communicationItemId);

    runInAction(() => {
      if (chat) {
        chat.unreadCount = 0;
      }
    });
  };

  //#endregion --- Subsribers

  private initSubscribers = () => {
    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ConnectCommunication,
      this.connectToChatSubscriber,
    );
    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ConnectCategory,
      this.connectToChannelSubscriber,
    );
    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.CreateCommunication,
      this.createCommunityChatSubscriber,
    );
    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.UpdateCommunication,
      this.updateChatSubscriber,
    );
    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ViewAllMessages,
      this.readAllMessagesSubscriber,
    );
  };

  private initListeners = () => {
    appSocket.subscribeDispatch(
      SocketEventsCollection.CommunityEvents.Emitters.ConnectCategory,
      this.connectToChannelListener,
    );
  };

  initEvents = () => {
    this.initListeners();
    this.initSubscribers();
  };

  disposer = () => {
    this._channelChats = new Map();
    this._activeChat = null;
    this.chatsLoader = new LoadingStore();
    this.particularChatLoader = new LoadingStore();
  };
}
