import type { BaseResponse } from '../_types';

import { PATH_COLLECTION } from '@core/constants';
import { createHTTPService } from '@core/services/http';

export class GroupRepository {
  private readonly http = createHTTPService(PATH_COLLECTION.GROUP.ROOT);

  markTimecode = async (groupId: number, videoTimecodeId: number) => {
    const path = PATH_COLLECTION.GROUP.MARK_TIMECODE;

    try {
      const { data } = await this.http.post<BaseResponse>(
        path,
        { isWatched: true },
        {
          param: { groupId, videoTimecodeId },
        },
      );

      return data;
    } catch (e) {
      throw e;
    }
  };
}
