import { RouterLink } from '@components';
import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const Subtitle = observer(function Subtitle() {
  const { t } = useTranslation();

  return (
    <span>
      {t('login.dont-have-acc')}
      <RouterLink to={routerCollection.auth.registration}>{t('shared-content.sign-up')}</RouterLink>
    </span>
  );
});
