import { SubscriptionsList } from './subscriptions-list';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { TypographyNew } from '@shared/UI';

export const Subscriptions = observer(function Subscriptions() {
  const { t } = useTranslation();

  return (
    <div>
      <TypographyNew as="h2" variant="title-2" weight="bold">
        {t('my-account.my-subscription.title')}
      </TypographyNew>
      <div>
        <SubscriptionsList />
      </div>
    </div>
  );
});
