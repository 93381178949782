import { blockMainType } from './constants';
import type {
  CoursesBlocksQueryDto,
  CoursesGroupBlocksGroupIdQueryDto,
  DashboardBlocksQueryDto,
  EventsBlocksQueryDto,
  GetCommunitiesQueryDto,
  GetCommunityPreviewQueryDto,
  GetCoursePreviewQueryDto,
  HomeworkCourseQueryDto,
  MyCoursesQueryDto,
  MyHomeworksQueryDto,
  OnBoardQueryDto,
  StreamsBlocksQueryDto,
} from './dto';
import type {
  CoursePreviewBlockType,
  CoursePreviewContentType,
  DashboardBlockTransformedEntity,
} from './entities';
import type {
  GetCoursesBlocksResponse,
  GetCoursesGroupBlocksByGroupIdResponse,
  GetDashboardBlocksResponse,
  GetDashboardBlocksTransformedResponse,
  GetEventsBlocksResponse,
  HomeworkCourseResponse,
  MyCoursesResponse,
  MyHomeworkResponse,
  OnBoardResponse,
  StreamsBlocksResponse,
} from './responses';
import { DEFAULT_LANGUAGE } from '@constants';

import { PATH_COLLECTION } from '@core/constants';
import type { IdParam } from '@core/repositories/_types';
import type {
  GetCommunitiesResponse,
  GetCommunityPreviewResponse,
  GetCoursePreviewResponse,
  GetCoursePreviewTransformedResponse,
} from '@core/repositories/blocks/responses/community';
import { createHTTPService } from '@core/services/http';

import type { ObjectValues } from '@shared/types';

export class BlockRepository {
  private readonly http = createHTTPService(PATH_COLLECTION.BLOCKS.ROOT);

  getDashboardBlocks = async (query?: DashboardBlocksQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.DASHBOARD_V2;

    const { lang = DEFAULT_LANGUAGE, ...restQuery } = query || {};

    try {
      const { data } = await this.http.get<GetDashboardBlocksTransformedResponse>(path, {
        query: { lang, ...restQuery },
        config: {
          transformResponse: async (res: string) => {
            const data = (await JSON.parse(res)) as GetDashboardBlocksResponse;

            const secondaryBanners = data.block
              .filter((block) => block.blockType === blockMainType.banner)
              .map((banner) => ({ title: banner.title, content: banner.content }));

            const transformedData: Record<
              ObjectValues<typeof blockMainType>,
              DashboardBlockTransformedEntity | DashboardBlockTransformedEntity[]
            > = data.block.reduce((acc, item) => {
              if (item.blockType === blockMainType.banner) {
                acc[blockMainType.banner] = secondaryBanners;
              } else {
                acc[item.blockType] = { title: item.title, content: item.content };
              }

              return acc;
            }, {} as Record<string, DashboardBlockTransformedEntity | DashboardBlockTransformedEntity[]>);

            return transformedData;
          },
        },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getCoursesBlocks = async (query: CoursesBlocksQueryDto) => {
    const { lang = DEFAULT_LANGUAGE, ...restQuery } = query || {};

    const path = PATH_COLLECTION.BLOCKS.COURSE_LANDING;

    try {
      const { data } = await this.http.get<GetCoursesBlocksResponse>(path, {
        query: { lang, ...restQuery },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getCoursesGroupBlocksByGroupId = async (
    groupId: IdParam,
    query?: CoursesGroupBlocksGroupIdQueryDto,
  ) => {
    const path = PATH_COLLECTION.BLOCKS.COURSE_VIEW;

    try {
      const { data } = await this.http.get<GetCoursesGroupBlocksByGroupIdResponse>(path, {
        query,
        param: { groupId },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getOnBoardBlocks = async (query?: OnBoardQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.ONBOARD;

    const { lang = DEFAULT_LANGUAGE, ...restQuery } = query || {};

    try {
      const { data } = await this.http.get<OnBoardResponse>(path, {
        query: { lang, ...restQuery },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getMyCourses = async (query?: MyCoursesQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.MY_COURSES;

    const { lang = DEFAULT_LANGUAGE, ...restQuery } = query || {};

    try {
      const { data } = await this.http.get<MyCoursesResponse>(path, {
        query: { lang, ...restQuery },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getMyHomeworksByGroupId = async (query: MyHomeworksQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.HOMEWORK;

    const { lang = DEFAULT_LANGUAGE, ...restQuery } = query || {};

    try {
      const { data } = await this.http.get<MyHomeworkResponse>(path, {
        query: { lang, ...restQuery },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getHomeworkCourseById = async (homeworkId: IdParam, query: HomeworkCourseQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.HOMEWORK_BY_ID;

    const { lang = DEFAULT_LANGUAGE, ...restQuery } = query || {};

    try {
      const { data } = await this.http.get<HomeworkCourseResponse>(path, {
        query: { lang, ...restQuery },
        param: { homeworkId },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getEvents = async (query?: EventsBlocksQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.CALENDAR;

    const { lang = DEFAULT_LANGUAGE, ...restQuery } = query || {};

    try {
      const { data } = await this.http.get<GetEventsBlocksResponse>(path, {
        query: { lang, ...restQuery },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getCommunities = async (query?: GetCommunitiesQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.COMMUNITIES;

    const { lang = DEFAULT_LANGUAGE } = query || {};

    try {
      const { data } = await this.http.get<GetCommunitiesResponse>(path, {
        query: { lang },
      });

      return data.block;
    } catch (e) {
      throw e;
    }
  };

  getCommunityPreview = async (businessAccountId: number, query?: GetCommunityPreviewQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.COMMUNITY_PREVIEW;

    const { lang = DEFAULT_LANGUAGE } = query || {};

    try {
      const { data } = await this.http.get<GetCommunityPreviewResponse>(path, {
        param: {
          businessAccountId,
        },
        query: { lang },
      });

      return data.block;
    } catch (e) {
      throw e;
    }
  };

  getCoursePreview = async (courseId: number, query?: GetCoursePreviewQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.COURSE_LANDING;

    const { lang = DEFAULT_LANGUAGE } = query || {};

    try {
      const { data } = await this.http.get<GetCoursePreviewTransformedResponse>(path, {
        param: {
          courseId,
        },
        query: { lang },
        config: {
          transformResponse: async (res: string) => {
            const data = (await JSON.parse(res)) as GetCoursePreviewResponse;

            const transformedData: Record<CoursePreviewBlockType, CoursePreviewContentType> =
              data.block.reduce((acc, item) => {
                acc[item.blockType] = item.content;

                return acc;
              }, {} as Record<CoursePreviewBlockType, CoursePreviewContentType>);

            return transformedData;
          },
        },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  getStreamById = async (streamId: IdParam, query?: StreamsBlocksQueryDto) => {
    const path = PATH_COLLECTION.BLOCKS.STREAMS;

    const { lang = DEFAULT_LANGUAGE, ...restQuery } = query || {};

    try {
      const { data } = await this.http.get<StreamsBlocksResponse>(path, {
        query: { lang, ...restQuery },
        param: { streamId },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };
}

export const blockRepository = new BlockRepository();
