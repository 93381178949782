import { StripeField } from '../stripe-field';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import type {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Typography } from '@shared/UI';

import s from './styles.module.scss';

interface CreditCardFieldsProps {
  handleCardNumberChange: (event: StripeCardNumberElementChangeEvent) => any;
  handleExpDateChange: (event: StripeCardExpiryElementChangeEvent) => any;
  handleCvcNumberChange: (event: StripeCardCvcElementChangeEvent) => any;
}

export const CreditCardFields = observer(function CreditCardFields(props: CreditCardFieldsProps) {
  const { handleCardNumberChange, handleCvcNumberChange, handleExpDateChange } = props;

  const { t } = useTranslation();

  return (
    <div className={s.creditCardFields}>
      <Typography>{t('modals.stripe-modal.credit-card')}</Typography>

      <div className={s.creditCardFieldsCardNumberWrapper}>
        <StripeField label={t('modals.stripe-modal.card-number')}>
          <CardNumberElement onChange={handleCardNumberChange} />
        </StripeField>
      </div>

      <div className={s.creditCardFieldsOtherFieldWrapper}>
        <StripeField label={t('modals.stripe-modal.exp-date')}>
          <CardExpiryElement onChange={handleExpDateChange} />
        </StripeField>
        <StripeField label={t('modals.stripe-modal.cvc-number')}>
          <CardCvcElement onChange={handleCvcNumberChange} />
        </StripeField>
      </div>
    </div>
  );
});
