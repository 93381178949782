import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button } from '@shared/UI';
import type { ButtonProps } from '@shared/UI';

import s from './styles.module.scss';

export type SubmitButtonProps = {} & Omit<ButtonProps, 'type' | 'children'>;

export const SubmitButton = observer(function SubmitButton(props: SubmitButtonProps) {
  const { ...restProps } = props;

  const { t } = useTranslation();

  return (
    <div className={s.submitButtonWrapper}>
      <Button type="submit" {...restProps}>
        {t('shared-content.save-changes')}
      </Button>
    </div>
  );
});
