import type { CSSProperties, MouseEvent, ReactNode } from 'react';

import { useAccordionContext } from '../Accordion.context';
import { useAccordionItemContext } from '../AccordionItem.context';
import cl from 'classnames';

import { observer } from '@shared/libs/mobx';

import { SvgIcon } from '@shared/UI';

import type { ElementProps } from '@shared/types';
import { classNames, componentName, mergeComponents } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('accordion-control');

export interface AccordionControlProps extends ElementProps<'button'> {
  disabled?: boolean;

  chevron?: ReactNode;

  chevronIcon?: ReactNode;

  align?: CSSProperties['alignItems'];

  blockOpening?: boolean;

  onOpen?: (value: string) => void;

  onClose?: (value: string) => void;
}

const _AccordionControl = observer((props: AccordionControlProps) => {
  const {
    children,
    chevron,
    chevronIcon,
    disabled,
    onClick,
    onClose,
    onOpen,
    className,
    align = 'flex-start',
    style,
    blockOpening = false,
    ...restProps
  } = props;

  const { value, isLoading } = useAccordionItemContext();
  const ctx = useAccordionContext();
  const isActive = ctx.isItemActive(value);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (blockOpening) return;

    if (isActive) {
      onClose?.(value);
    } else {
      onOpen?.(value);
    }
    onClick?.(event);
    ctx.onChange(value);
  };

  const rootClasses = cl(
    rootClass,
    {
      [appendClass('--blocked')]: blockOpening,
    },
    className,
  );

  const defaultChevron = !isLoading ? (
    <span className={appendClass('-chevron')}>
      {chevronIcon ?? <SvgIcon type="arrow-with-gradient" />}
    </span>
  ) : (
    <span className={appendClass('-chevron')}>
      <SvgIcon type="dual-ring-spinner" className={appendClass('-icon-loader')} />
    </span>
  );

  const _style = {
    ...style,
    alignItems: align,
  };

  return (
    <button
      className={rootClasses}
      onClick={handleClick}
      data-active={isActive}
      data-loading={isLoading}
      data-disabled={disabled}
      data-accordion-control="true"
      style={_style}
      {...restProps}
    >
      <div className={appendClass('-content-container')}>{children}</div>
      <span className={appendClass('-chevron-container')}>{chevron || defaultChevron}</span>
    </button>
  );
});

export const AccordionControl = mergeComponents(_AccordionControl, {
  ...componentName('Accordion.Control'),
});
