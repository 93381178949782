import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { ButtonProps } from '@shared/UI';
import { Button, SvgIcon } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface SendButtonProps extends Omit<ButtonProps, 'size' | 'children'> {}

const _SendButton = observer((props: SendButtonProps) => {
  const { className, ...restProps } = props;

  const rootClasses = cl(s.sendBtn, className);

  return (
    <Button size="icon" disabledAnimation className={rootClasses} {...restProps}>
      <SvgIcon type="paper-airplane-2" />
    </Button>
  );
});

export const SendButton = mergeComponentsWithName('SendButton', _SendButton);
