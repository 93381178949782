import { SubscriptionItem } from './subscription-item';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { randomUuid } from '@shared/utils';

import s from './styles.module.scss';

export const SubscriptionsList = observer(function SubscriptionsList() {
  const { userStore } = useRootStore();

  const mapSubscriptions = userStore.communitySubscriptions.map((subscription) => {
    const key = randomUuid();
    return (
      <div key={key} className={s.subscriptionsListItem}>
        <SubscriptionItem
          image={subscription.businessAccountImage}
          ownerName={subscription.businessAccountTitle}
          title={subscription.title}
          endDate={subscription.endDate}
          priceList={subscription.priceList}
          subscriptionId={subscription.subscriptionId}
        />
      </div>
    );
  });

  return <div className={s.subscriptionsList}>{mapSubscriptions}</div>;
});
