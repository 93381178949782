import type { ReactNode } from 'react';

import { routerCollection } from '@constants';
import { useAppLocation } from '@hooks';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import { useRootStore } from '@core/store';

type ProtectedRouteProps = {
  children: ReactNode;
};

export const ProtectedRoute = observer(function ProtectedRoute(props: ProtectedRouteProps) {
  const { children } = props;

  const { pathname } = useAppLocation();

  const { authStore } = useRootStore();

  if (!authStore.isUserHasAccess && !authStore.isAuthLoading) {
    const state = {
      from: pathname,
    };

    return <Navigate to={routerCollection.auth.login} state={state} />;
  }

  return <>{children}</>;
});
