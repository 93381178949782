import type { CSSProperties, ElementType, ReactNode } from 'react';
import type { Ref } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { getSpaceValue } from '@shared/UI/_utils';

import type { ElementProps } from '@shared/types';
import { mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const box = cva('ui-box', {
  variants: {
    intent: {
      unstyled: 'ui-box--unstyled',
      glass: 'ui-box--glass',
    },
  },
  defaultVariants: {
    intent: 'unstyled',
  },
});

export type BoxProps<Element extends ElementType = 'div'> = ElementProps<Element> &
  VariantProps<typeof box> & {
    px?: number | [number, number];
    py?: number | [number, number];
    mx?: number | [number, number];
    my?: number | [number, number];
    textAlign?: CSSProperties['textAlign'];
    display?: CSSProperties['display'];
    alignItems?: CSSProperties['alignItems'];
    justifyContent?: CSSProperties['justifyContent'];
    overflow?: CSSProperties['overflow'];
    rounded?: CSSProperties['borderRadius'];

    flexWrap?: CSSProperties['flexWrap'];
    gap?: CSSProperties['gap'];
    w?: CSSProperties['width'];
    h?: CSSProperties['height'];
    as?: Element;
    className?: string;
    style?: CSSProperties;
    children?: ReactNode;
    innerRef?: Ref<HTMLElement>;
  };

const _Box = observer(<Element extends ElementType = 'div'>(props: BoxProps<Element>) => {
  const {
    as = 'div',
    className,
    px,
    py,
    mx,
    my,
    textAlign,
    display,
    alignItems,
    justifyContent,
    flexWrap,
    w,
    h,
    gap,
    style,
    intent,
    innerRef,
    rounded,
    overflow,
    ...restProps
  } = props;

  const Component = as;

  const rootClasses = cl(box({ intent }), className);

  const styles: CSSProperties = {
    paddingRight: px ? getSpaceValue(px, 1) : undefined,
    paddingLeft: px ? getSpaceValue(px, 0) : undefined,
    paddingTop: py ? getSpaceValue(py, 0) : undefined,
    paddingBottom: py ? getSpaceValue(py, 1) : undefined,
    marginLeft: mx ? getSpaceValue(mx, 0) : undefined,
    marginRight: mx ? getSpaceValue(mx, 1) : undefined,
    marginTop: my ? getSpaceValue(my, 0) : undefined,
    marginBottom: my ? getSpaceValue(my, 1) : undefined,
    alignItems,
    justifyContent,
    textAlign,
    gap,
    width: w,
    flexWrap,
    display,
    height: h,
    borderRadius: rounded,
    overflow,
    ...style,
  };

  return (
    <Component
      ref={innerRef as any}
      className={rootClasses}
      style={styles}
      data-pad={px}
      {...restProps}
    />
  );
});

export const Box = mergeComponentsWithName('Box', _Box);
