import type { ObjectKeys } from '@shared/types';

const _ClassGapCollection = {
  none: '',
  xs: combineClassName('xs'),
  'xs-2': combineClassName('xs-2'),
  'xs-3': combineClassName('xs-3'),
  sm: combineClassName('sm'),
  md: combineClassName('md'),
  xl: combineClassName('xl'),
  xxl: combineClassName('xxl'),
  '36': combineClassName('36'),
  '48': combineClassName(48),
  '56': combineClassName(56),
} as const;

function combineClassName<T extends string | number>(name: T) {
  return `--gap-${name}` as `--gap-${T}`;
}

function withParentClass<T extends string>(parentClass: T) {
  return Object.entries(_ClassGapCollection).reduce((acc, [key, value]) => {
    acc[key as ObjectKeys<typeof _ClassGapCollection>] = `${parentClass}${value}`;

    return acc;
  }, {} as Record<ObjectKeys<typeof _ClassGapCollection>, `${T}${(typeof _ClassGapCollection)[ObjectKeys<typeof _ClassGapCollection>]}`>);
}

export const ClassGapCollection = Object.assign(withParentClass, _ClassGapCollection);
