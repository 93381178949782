import { contentLengthToSize } from './content-length-to-size';
import { getFileNameFromUrl } from './get-file-name-from-url';

export type FetchedOutsideFile = {
  name: string;
  mimeType: string;
  size: number;
  readableSize: string;
  originUrl: string;
  blobUrl: string;
  content: Blob;
  extension: string;
};

const inSeconds = (seconds: number) => seconds * 1000;

const DEFAULT_TIMEOUT = inSeconds(10);

const getFileExtensionFromMimeType = (mimeType: string) => {
  const mimeParts = mimeType.split('/');
  if (mimeParts.length === 2) {
    return mimeParts[1];
  } else {
    return null;
  }
};

export type FetchOutsideFileReturn = Awaited<FetchedOutsideFile>;

class FileCache {
  private static cache = new Map<string, FetchedOutsideFile>();

  static get = (key: string) => {
    return this.cache.get(key);
  };

  static set = (key: string, value: FetchedOutsideFile) => {
    this.cache.set(key, value);
  };
}

export const fetchOutsideFile = async (
  fileUrl: string,
  timeout = DEFAULT_TIMEOUT,
): Promise<FetchedOutsideFile> => {
  const timeoutPromise = () =>
    new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Request timed out')), timeout);
    });

  try {
    const cached = FileCache.get(fileUrl);

    if (cached) {
      return cached;
    }

    const responsePromise = fetch(fileUrl, {
      credentials: 'include',
    });

    const response = (await Promise.race([responsePromise, timeoutPromise()])) as Response;

    const blob = await response.blob();

    const size = contentLengthToSize(blob.size);

    const name = getFileNameFromUrl(fileUrl).replace(/\.[^.]+$/, '');

    const fetchedFile = {
      name,
      mimeType: blob.type,
      size: blob.size,
      readableSize: size,
      originUrl: fileUrl,
      blobUrl: URL.createObjectURL(blob),
      content: blob,
      extension: getFileExtensionFromMimeType(blob.type) || '',
    };

    FileCache.set(fileUrl, fetchedFile);

    return fetchedFile;
  } catch (error) {
    return Promise.reject(error);
  }
};
