import { FormBox } from '../../_components';
import type { ForgotPasswordFirstStepSchema } from '../../_schemas';
import { forgotPasswordFirstStepSchema } from '../../_schemas';
import { FormButtons } from '../_components';
import { FormFields } from '@components';
import { routerCollection } from '@constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppNavigate } from '@hooks';
import { observer } from 'mobx-react-lite';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

export const ForgotPasswordFirstStepForm = observer(function ForgotPasswordFirstStepForm() {
  const { t } = useTranslation();

  const { authStore } = useRootStore();

  const navigate = useAppNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFirstStepSchema>({
    resolver: zodResolver(forgotPasswordFirstStepSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<ForgotPasswordFirstStepSchema> = async (dto) => {
    try {
      await authStore.forgotPasswordFirstStep(dto);

      navigate(routerCollection.auth.newPassword);
    } catch (e) {}
  };

  return (
    <FormBox
      onSubmit={handleSubmit(onSubmit)}
      title={t('forgot-password.restore-password')}
      hideGoogleButton
      hideSwitcher
    >
      <FormFields.Input
        name="email"
        control={control}
        error={errors.email}
        label="inputs.email.label"
        placeholder="inputs.email.placeholder"
      />

      <FormButtons />
    </FormBox>
  );
});
