import { ReactNode } from 'react';

import { ContextTriggerEvent, Position } from './libs';
import { makeAutoObservable, runInAction } from 'mobx';

import { EventEmitterDisposable, createEventEmitter } from '@shared/utils';

type ShowActionParams = {
  event: ContextTriggerEvent;
  component: ReactNode;
  beforeClose?: () => void;
};

const showEvent = createEventEmitter<ShowActionParams>();
const hideEvent = createEventEmitter<null>();

export class ContextMenuController {
  private _isOpen = false;

  event: ContextTriggerEvent | null = null;

  position = {
    x: 0,
    y: 0,
  };

  component: ReactNode = null;

  private offShowEvent: EventEmitterDisposable;

  private offHideEvent: EventEmitterDisposable;

  private beforeClose: null | (() => void) = null;

  constructor() {
    this.offShowEvent = showEvent.on(this.show);
    this.offHideEvent = hideEvent.on(this.hide);

    makeAutoObservable(
      this,
      {
        position: false,
        event: false,
      },
      { autoBind: true },
    );
  }

  get isOpen() {
    return this._isOpen;
  }

  show = ({ component, event, beforeClose }: ShowActionParams) => {
    event.preventDefault();
    event.stopPropagation();

    runInAction(() => {
      this.event = event;
      this.component = component;
      this.position = this.getPostionFromEvent(event);
      this._isOpen = true;
      if (beforeClose) {
        this.beforeClose = beforeClose;
      }
    });
  };

  hide = () => {
    if (this.beforeClose) {
      this.beforeClose();
    }
    runInAction(() => {
      this._isOpen = false;
      this.event = null;
      this.position = {
        x: 0,
        y: 0,
      };
      this.beforeClose = null;
      this.component = null;
    });
  };

  dispose = () => {
    this.offShowEvent.dispose();
    this.offHideEvent.dispose();
  };

  private getPostionFromEvent = (event: ContextTriggerEvent): Position => {
    if ('clientX' in event && 'clientY' in event) {
      return {
        x: event.clientX,
        y: event.clientY,
      };
    }
    if ('touches' in event) {
      return {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
    }

    return {
      x: 0,
      y: 0,
    };
  };
}

export const showContextMenu = (params: ShowActionParams) => showEvent.emit(params);
export const hideContextMenu = () => hideEvent.emit(null);
