import { FormFields } from '@components';
import { observer } from 'mobx-react-lite';
import type { Control, FieldError, FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';

import { Box } from '@shared/UI';

import { ResendButton } from './resend-button';
import type { ResendButtonProps } from './resend-button';

type OtpCodeProps<TFormValues extends FieldValues> = {
  name: FieldPath<TFormValues>;
  control?: Control<TFormValues>;
  rules?: RegisterOptions;
  error?: FieldError;
  hiddeResend?: boolean;
} & ResendButtonProps;

export const OtpCode = observer(function OtpCode<TFormValues extends FieldValues>(
  props: OtpCodeProps<TFormValues>,
) {
  const { control, name, rules, error, onClickResend, hiddeResend } = props;

  return (
    <Box>
      <FormFields.Input
        name={name}
        control={control}
        error={error}
        rules={rules}
        label="inputs.otp.label"
        placeholder="7-7-7-7-7"
        mask="9-9-9-9-9"
      />
      {!hiddeResend && <ResendButton onClickResend={onClickResend} />}
    </Box>
  );
});
