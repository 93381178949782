import * as z from 'zod';

export const signInByPhoneSchema = z.object({
  phone: z
    .string({
      required_error: 'errors.required-field',
    })
    .min(1, { message: 'errors.required-field' }),
  code: z.string(),
  otp: z.string().optional(),
});

export type SignInByPhoneSchema = z.infer<typeof signInByPhoneSchema>;
