import { useEffect } from 'react';

import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useRootStore } from '@core/store';

export const EntryPage = observer(function EntryPage() {
  const { i18n } = useTranslation();

  const { dashboardStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  useEffect(() => {
    dashboardStore.getAll({ lang: i18n.language });
  }, [i18n.language]);

  useEffect(() => {
    sendEvent({ event: AnalyticsEvents.DashboardPageView });
  }, []);

  return (
    <section>
      <Outlet />
    </section>
  );
});
