import type { Editor } from '@tiptap/react';
import { Extension } from '@tiptap/react';

export interface KeyboardHandlerOptions {
  onSubmit: (value: string, editor: Editor) => void;
}

export const KeyboardHandler = Extension.create<KeyboardHandlerOptions>({
  name: 'keyboardHandler',
  addOptions() {
    return {
      onSubmit: () => {},
    };
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const isCodeBlockActive = this.editor.isActive('codeBlock');
        const currentValue = this.editor.getHTML();

        if (isCodeBlockActive) {
          return false;
        }

        this.options.onSubmit(currentValue, this.editor as Editor);
        return this.editor.commands.clearContent();
      },

      'Mod-Enter': () => {
        const isCodeBlockActive = this.editor.isActive('codeBlock');
        const currentValue = this.editor.getHTML();

        if (isCodeBlockActive) {
          this.options.onSubmit(currentValue, this.editor as Editor);
          return this.editor.commands.clearContent();
        }

        if (!isCodeBlockActive) {
          this.options.onSubmit(currentValue, this.editor as Editor);
          return this.editor.commands.clearContent();
        }

        return false;
      },

      'Shift-Enter': () => {
        return this.editor.commands.first(({ commands }) => [
          () => commands.createParagraphNear(),
          () => commands.newlineInCode(),
          () => commands.liftEmptyBlock(),
          () => commands.splitBlock(),
        ]);
      },
    };
  },
});
