import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import cl from 'classnames';

import { observer } from '@shared/libs/mobx';

import { Badge, Image, Indicator, SvgIcon, Typography } from '@shared/UI';

import type { ElementProps } from '@shared/types';
import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const communityCard = cva(s.communityCard, {
  variants: {
    size: {
      sm: s.communityCardSizeSm,
      md: s.communityCardSizeMd,
      lg: s.communityCardSizeLg,
    },
  },

  defaultVariants: {
    size: 'md',
  },
});

export interface CommunityCardProps
  extends ElementProps<'div', 'children' | 'title'>,
    VariantProps<typeof communityCard> {
  title: string;
  languageFlagSrc: string;
  communityLanguageAbbr: string;
  cardBackgroundSrc: string;
  activeUsersCount: number;
  rating: number;
  communityId: number;
  hasViewerAccess?: boolean;
  unreadCount?: number;
}

const _CommunityCard = observer((props: CommunityCardProps) => {
  const {
    languageFlagSrc,
    cardBackgroundSrc,
    communityLanguageAbbr,
    rating,
    communityId,
    activeUsersCount,
    title,
    size,
    className,
    hasViewerAccess,
    unreadCount,
    ...restProps
  } = props;

  const contentBottomClasses = cl(s.communityCardContentBottom, {
    [s.communityCardContentBottomWithRating]: rating !== 0,
  });

  const rootClasses = communityCard({ size, className });

  return (
    <div className={rootClasses} {...restProps}>
      {Boolean(unreadCount) && (
        <Indicator
          count={unreadCount}
          size="md"
          maxCount={99}
          className={s.communityCardIndicator}
        />
      )}
      <div className={s.communityCardContent}>
        <Image
          src={cardBackgroundSrc}
          alt="community tile image"
          className={s.communityCardContentBg}
        />

        <Badge
          label={communityLanguageAbbr}
          leftSection={<Image src={languageFlagSrc} alt="community card language flag" />}
        />
        <div className={contentBottomClasses}>
          {rating && <Badge leftSection={<SvgIcon type="star" />} label={rating} />}
          <Badge leftSection={<SvgIcon type="persons" />} label={activeUsersCount} />
        </div>
      </div>
      <Typography className={s.communityCardName} variant="body-bold-2" as="p">
        {title}
      </Typography>
    </div>
  );
});

export const CommunityCard = mergeComponentsWithName('CommunityCard', _CommunityCard);
