import { SvgIcon } from '../../../svg-icon';
import { useRichEditorContext } from '../../rich-editor.context';
import { EditorMenuItem } from '../editor-menu-item';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

const _BtnItalic = observer(() => {
  const { editor } = useRichEditorContext();

  const handleClickBtn = () => editor().chain().focus().toggleItalic().run();

  const icon = <SvgIcon type="editor-italic" />;

  return (
    <EditorMenuItem icon={icon} onClick={handleClickBtn} isActive={editor().isActive('italic')} />
  );
});

export const BtnItalic = mergeComponentsWithName('BtnItalic', _BtnItalic);
