import { MessageCounterStore } from './../message-counter';
import type { AxiosRequestConfig } from 'axios';
import { makeAutoObservable } from 'mobx';

import type {
  PrivateChatInfoEntity,
  PrivateChatMessageEntity,
  PrivateChatParticipantEntity,
  UploadPrivateChatAttachmentsDto,
} from '@core/repositories';
import { privateChatRepository } from '@core/repositories';
import { LoadingStore, MessageStore } from '@core/store/common';

interface IPrivateChatStore extends PrivateChatInfoEntity {
  lastMessage: MessageStore | null;

  viewer: PrivateChatParticipantEntity;

  participant: PrivateChatParticipantEntity;
}

export interface PrivateChatStoreOptions extends Omit<IPrivateChatStore, 'lastMessage'> {
  lastMessage: PrivateChatMessageEntity | null;
}

export class PrivateChatStore implements IPrivateChatStore {
  id: string;

  lastMessage: MessageStore | null;

  ownerId: number;

  participantIds: number[];

  unreadCount: number;

  viewer: PrivateChatParticipantEntity;

  participant: Omit<PrivateChatParticipantEntity, 'name'> & { name: string };

  uploadFileLoader = new LoadingStore();

  constructor(
    {
      lastMessage,
      ownerId,
      id,
      unreadCount,
      participantIds,
      participant,
      viewer,
    }: PrivateChatStoreOptions,
    private messageCounter: MessageCounterStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    if (lastMessage) {
      const { privateChatId, ...restMessage } = lastMessage;
      this.lastMessage = new MessageStore({
        ...restMessage,
        chatId: privateChatId,
        isMessageFromViewer: restMessage.authorId === viewer.id,
      });
    } else {
      this.lastMessage = null;
    }

    this.ownerId = ownerId;
    this.id = id;
    this.unreadCount = unreadCount;
    this.participantIds = participantIds;
    this.viewer = viewer;
    this.participant = {
      ...participant,
      name: participant.name ? participant.name : '',
    };
  }

  increaseUnreadCount = () => {
    this.unreadCount += 1;
    this.messageCounter.privateChats.increaseCount();
  };

  decreaseUnreadCount = (count?: number) => {
    const _count = count ?? 1;

    this.unreadCount = Math.max(0, this.unreadCount - _count);

    this.messageCounter.privateChats.decreaseCount(_count);
  };

  updateUnreadCount = (value: number) => {
    this.unreadCount = Math.max(0, value);
    this.messageCounter.privateChats.updateCount(value);
  };

  uploadChatAttachments = async (
    dto: Omit<UploadPrivateChatAttachmentsDto, 'privateChatId'>,
    onProgress?: AxiosRequestConfig['onUploadProgress'],
  ) => {
    this.uploadFileLoader.startLoading();
    try {
      await privateChatRepository.uploadChatAttachments(
        {
          privateChatId: this.id,
          ...dto,
        },
        onProgress,
      );
    } catch (err) {
    } finally {
      this.uploadFileLoader.stopLoading();
    }
  };
}
