import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { Box, Stack, Typography } from '@shared/UI';
import type { BoxProps } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface CommunityBenefitProps extends BoxProps {
  title: string;
  description: string;
}

const _CommunityBenefit = observer((props: CommunityBenefitProps) => {
  const { title, description, className, ...restProps } = props;

  const rootClasses = cl(s.communityBenefit, className);

  return (
    <Box className={rootClasses} intent="glass" {...restProps}>
      <Stack align="flex-start">
        <Typography as="h4" variant="title-bold-4">
          {title}
        </Typography>
        <Typography as="p" variant="body-medium-3">
          {description}
        </Typography>
      </Stack>
    </Box>
  );
});

export const CommunityBenefit = mergeComponentsWithName('CommunityBenefit', _CommunityBenefit);
