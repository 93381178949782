import { useRef, useState } from 'react';

import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';

type UseContextMenuFloatingProps = {
  items: {
    [key: string]: any;
    label: string | (() => string);
  }[];
  isOpen: boolean;
  onChangeOpen: (value: boolean) => void;
};

const middleware = [
  offset({ mainAxis: 5, alignmentAxis: 4 }),
  flip({
    fallbackPlacements: ['left-start'],
  }),
  shift({ padding: 10 }),
];

export function useContextMenuFloating(props: UseContextMenuFloatingProps) {
  const { items, isOpen, onChangeOpen } = props;

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const listContentRef = useRef(
    items?.map((item) => (typeof item.label === 'function' ? item.label() : item.label)),
  );

  const { refs, floatingStyles, context } = useFloating({
    open: items.length > 0 && isOpen,
    onOpenChange: (open) => {
      if (items.length) {
        onChangeOpen(open);
      }
    },
    middleware,
    placement: 'right-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context);
  const listNavigation = useListNavigation(context, {
    listRef: listItemsRef,
    onNavigate: setActiveIndex,
    activeIndex,
  });
  const typeahead = useTypeahead(context, {
    enabled: isOpen,
    listRef: listContentRef,
    onMatch: setActiveIndex,
    activeIndex,
  });

  const { getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNavigation,
    typeahead,
  ]);

  return {
    getFloatingProps,
    getItemProps,
    floatingStyles,
    refs,
    listItemsRef,
    listContentRef,
    activeIndex,
    context,
  };
}
