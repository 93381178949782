import { HTMLAttributes } from 'react';

import cl from 'classnames';

import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

export interface SeparatorProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {}

const { rootClass } = classNames('context-menu-separator');

const _Separator = observer((props: SeparatorProps) => {
  const { className } = props;

  return <div className={cl(rootClass, className)}></div>;
});

export const Separator = mergeComponentsWithName('Separator', _Separator);
