import type { CSSProperties } from 'react';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { ElementProps } from '@shared/types';
import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export type LayoutPaddingBoxProps = ElementProps<'div'> & {
  padding?: CSSProperties['padding'];
  pt?: number;
  pb?: number;
  pr?: number;
  pl?: number;
};

const _LayoutPaddingBox = observer((props: LayoutPaddingBoxProps) => {
  const { children, padding, style, className, pt, pr, pl, pb, ...restProps } = props;

  const _style: CSSProperties = {
    ...style,
    padding,
    paddingBottom: pb,
    paddingTop: pt,
    paddingLeft: pl,
    paddingRight: pr,
  };

  const rootClasses = cl(s.layoutPaddingBox, className);

  return (
    <div className={rootClasses} style={_style} {...restProps}>
      {children}
    </div>
  );
});

export const LayoutPaddingBox = mergeComponentsWithName('LayoutPaddingBox', _LayoutPaddingBox);
