import { TalkingArea, useMessageParams } from '@units';
import { CommunityMessageList } from '@widgets';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

const _Chat = observer(() => {
  const { chatId } = useMessageParams(TalkingArea.COMMUNITY);

  return <CommunityMessageList key={chatId} />;
});

export const Chat = mergeComponentsWithName('Chat', _Chat);
