import { CommunityItem } from './community-item';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Each, Stack, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _CommunitiesCollection = observer(() => {
  const { communitiesStore } = useRootStore();

  const { t } = useTranslation();

  return (
    <Stack gap="sm" align="stretch" className={s.communitiesCollection}>
      <TypographyNew
        as="p"
        variant="body-3"
        colorSchema="neutral-500"
        className={s.communitiesCollectionTitle}
      >
        {t('brands.title')}
      </TypographyNew>
      <ul className={s.communitiesCollectionList}>
        <Each data={communitiesStore.purchasedCommunities?.businessAccounts ?? []}>
          {(community) => <CommunityItem key={community.communityId} community={community} />}
        </Each>
      </ul>
    </Stack>
  );
});

export const CommunitiesCollection = mergeComponentsWithName(
  'CommunitiesCollection',
  _CommunitiesCollection,
);
