import { useCallback } from 'react';

import { modalStore } from '@components';
import { ConfirmDelete } from '@features-new';
import { useAppNavigate } from '@hooks';
import type { MessageTransmitter } from '@units';
import { useAccessPermissions, useBusinessIdParam } from '@units';
import { computed } from 'mobx';
import { useTranslation } from 'react-i18next';

import { MessageViewType } from '@core/constants';
import type { MessageStore } from '@core/store';
import { useRootStore } from '@core/store';

import { useContextMenu } from '@shared/UI';

export const useCommunityMessageContextMenuController = (
  message: MessageStore,
  messageTransmitter: MessageTransmitter,
) => {
  const messageComputed = computed(() => message);

  const { t } = useTranslation();
  const navigate = useAppNavigate();

  const { communityMessagesStore, communityChatsStore, privateChatsStore } = useRootStore();

  const { hide } = useContextMenu();
  const businessIdParam = useBusinessIdParam();
  const { permissions, checkPermissionWithBusinessId, checkBusinessId } = useAccessPermissions();

  const messagePermissions = {
    canDelete: checkPermissionWithBusinessId(permissions.communityMessage.delete, businessIdParam),
    canPin: checkBusinessId(businessIdParam),
    canShowEdit:
      message.isMessageFromViewer &&
      message.type !== MessageViewType.Audio &&
      message.type !== MessageViewType.Video,
  };

  const onSelectQuickEmoji = useCallback((emoji: string) => {
    if (!communityChatsStore.activeChat) return;

    communityMessagesStore.toggleEmojiEmitter({
      communicationItemId: communityChatsStore.activeChat.id,
      messageId: message.id,
      reaction: emoji,
    });

    hide();
  }, []);

  const onClickReply = useCallback(() => {
    messageTransmitter.setReplyMessage(messageComputed.get());
    messageTransmitter.clearEditableMessage();
  }, []);

  const onClickPin = useCallback(() => {
    communityMessagesStore.pinMessageEmitter(messageComputed.get().id);
  }, []);

  const onEditMessage = useCallback(() => {
    messageTransmitter.setEditableMessage(messageComputed.get());
  }, []);

  const onConnectToPrivateChat = useCallback(async () => {
    const chatId = privateChatsStore.chats.find(
      (chat) => chat.participant.id === messageComputed.get().authorId,
    )?.id;

    if (chatId) {
      navigate(`/private-chats/chats/${chatId}`);
    } else {
      await privateChatsStore.awaitedCreatePrivateChat(messageComputed.get().authorId);
      navigate(`/private-chats/chats/${privateChatsStore.currentChatId}`);
    }
  }, []);

  const onClickDelete = useCallback(() => {
    modalStore.activateModal({
      component: (
        <ConfirmDelete
          label={t(`modals.delete-communication-item.message.title`)}
          description={t(`modals.delete-communication-item.message.description`)}
          onClickDelete={() => {
            communityMessagesStore.removeMessageEmitter(messageComputed.get().id);
          }}
        />
      ),
    });
  }, [t]);

  return {
    messagePermissions,
    onClickPin,
    onClickReply,
    onEditMessage,
    onClickDelete,
    onSelectQuickEmoji,
    onConnectToPrivateChat,
  };
};
