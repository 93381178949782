import type { MouseEvent } from 'react';
import { useCallback, useRef } from 'react';

import { Attachment } from './attachment';
import { FormFields, modalStore } from '@components';
import { FullSizeMediaModal, MessageImagePreview } from '@units';
import { AnimatePresence } from 'framer-motion';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { MyHomeworkTypes } from '@core/repositories';
import type { WritingHomeworkAnswerDto } from '@core/repositories/my-homework/dto';
import { type HomeworkWritingStore, useRootStore } from '@core/store';

import { motion } from '@shared/libs/framer-motion';
import { observer } from '@shared/libs/mobx';

import type { UploadError, UploadedFile } from '@shared/UI';
import {
  Box,
  Button,
  Document,
  Each,
  Group,
  Stack,
  SvgIcon,
  Textarea,
  Typography,
  TypographyNew,
} from '@shared/UI';

import { useLatestCallback } from '@shared/hooks';
import type { FetchOutsideFileReturn } from '@shared/utils';
import { FileKind, asHtml, getFileNameFromUrl, kindOfFile, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

export interface CollapseContentWritingProps {
  writing: HomeworkWritingStore;
  blockKey: MyHomeworkTypes;
}

interface Data extends Omit<WritingHomeworkAnswerDto, 'file'> {
  file: UploadedFile[];
}

const getFileConfig = (remoteFilePathList?: string[], localFileList?: UploadedFile[]) => {
  if (remoteFilePathList?.length) {
    return remoteFilePathList.map((url) => ({
      name: getFileNameFromUrl(url),
      path: withMediaUrl(url),
    }));
  }

  if (localFileList?.length) {
    return localFileList.map((file) => ({
      name: file.name,
      path: URL.createObjectURL(file.originalFile),
    }));
  }

  return null;
};

export const CollapseContentWriting = observer(function CollapseContentWriting(
  props: CollapseContentWritingProps,
) {
  const { writing } = props;

  const { myWorkspaceStore } = useRootStore();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit: _handleSubmit,
    formState: { isSubmitted },
    watch,
    control,
    setValue,
  } = useForm<Data>({
    defaultValues: {
      answer: writing.currentWriting?.answer || '',
    },
  });

  const isEmpty = watch('answer').length === 0;
  const watchedFile = watch('file');

  const listRef = useRef<HTMLUListElement>(null);
  const fileList = useRef<FetchOutsideFileReturn[]>([]);

  const setFileList = useLatestCallback((file: FetchOutsideFileReturn) => {
    fileList.current.push(file);
  });
  const slideKey = useLatestCallback((file: FetchOutsideFileReturn) => {
    return file.originUrl;
  });

  const slideContent = useLatestCallback((file: FetchOutsideFileReturn) => {
    const fileType = kindOfFile(file.originUrl);

    if (fileType === FileKind.IMAGE) {
      return <MessageImagePreview srcUrl={URL.createObjectURL(file.content)} />;
    }

    if (fileType === FileKind.DOCUMENT) {
      return <Document file={file.content} />;
    }

    return null;
  });

  const handleClickMedia = useCallback((event: MouseEvent<HTMLLIElement>) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));

    modalStore.activateModal({
      component: (
        <FullSizeMediaModal
          data={fileList.current}
          itemKey={slideKey}
          slideContent={slideContent}
          initialSlide={index}
        />
      ),
    });
  }, []);

  const handleSubmit: SubmitHandler<Data> = async (value) => {
    const files = value.file?.map((item) => item.originalFile);

    try {
      await writing.completeHomework({ answer: value.answer, files });

      await myWorkspaceStore.completeHomework(writing.homeworkId, writing.blockKey);
    } catch (e) {
      console.error(e);
    }
  };

  const isShowSendBtn = writing.blockKey === 'toBeSent';
  const isDisabledField = writing.blockKey !== 'toBeSent';

  const fileConfig = getFileConfig(writing.currentWriting?.attachmentUrl, watchedFile);

  const handleRemoveFile = () => {
    setValue('file', []);
  };

  const onError = (err: UploadError) => {
    if (err.type === 'maxFileSize') {
      toast.error(t('errors.big-file', { size: 5 }));
    }
  };

  return (
    <div className={s.contentWriting}>
      <Stack gap="xl" align="stretch">
        <TypographyNew variant="body-3">{asHtml(writing.currentWriting?.title)}</TypographyNew>

        <Box w="100%" as="ul" innerRef={listRef} className={s.contentWritingMediaList}>
          <Each data={writing.writings[0].writingAttachmentUrls}>
            {(url, index) => (
              <Attachment
                key={url}
                url={url}
                data-index={index}
                setLoadedFile={setFileList}
                onClickFile={handleClickMedia}
              />
            )}
          </Each>
        </Box>

        <form onSubmit={_handleSubmit(handleSubmit)}>
          <Stack align="stretch" className={s.contentWritingTextareaContainer} gap="xs">
            <Textarea
              label={t('shared-content.your-answer')}
              placeholder={t('shared-content.your-answer')}
              disabled={isSubmitted || isDisabledField}
              className={s.contentWritingTextarea}
              {...register('answer')}
            />
            {!isDisabledField && (
              <div className={s.contentWritingUplaodContainer}>
                <FormFields.FileUploader
                  maxFileSize={5}
                  control={control}
                  onError={onError}
                  name="file"
                  multiple
                >
                  <span>
                    <SvgIcon color="#000" type="paperclip" />
                  </span>
                </FormFields.FileUploader>
              </div>
            )}
            <Group justify={fileConfig ? 'space-between' : 'flex-end'} wrap="nowrap">
              <AnimatePresence>
                {Boolean(fileConfig?.length) && (
                  <motion.div
                    style={{
                      width: '100%',
                    }}
                    initial={{ x: '-100px', opacity: 0 }}
                    animate={{
                      x: 0,
                      opacity: 1,
                    }}
                    exit={{
                      x: '-100px',
                      opacity: 0,
                    }}
                  >
                    {fileConfig?.map((file, index) => (
                      <Group gap="xs" wrap="nowrap" key={`${file.name}-${index}`}>
                        <SvgIcon type="file" />
                        <Typography
                          as="a"
                          href={file.path}
                          download
                          variant="body-medium-3"
                          decoration="underline"
                          lineClamp={1}
                          color="#ffffff"
                        >
                          {file.name}
                        </Typography>
                        {watchedFile && (
                          <Button
                            className={s.contentWritingRemoveBtn}
                            disabledAnimation
                            size="icon"
                            variant="text"
                            onClick={handleRemoveFile}
                          >
                            <SvgIcon fontSize={24} type="cross" />
                          </Button>
                        )}
                      </Group>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
              {isShowSendBtn && (
                <Button
                  type="submit"
                  className={s.contentWritingBtn}
                  disabledAnimation
                  size="icon"
                  variant="text"
                  disabled={isSubmitted || isEmpty}
                >
                  <SvgIcon type="paper-airplane" />
                </Button>
              )}
            </Group>
            {writing.review && (
              <Stack align="flex-start" gap="sm">
                <TypographyNew variant="body-4" colorSchema="neutral-200">
                  {t('shared-content.comment')}
                </TypographyNew>
                <div className={s.contentWritingComment}>
                  <TypographyNew colorSchema="neutral-100">{asHtml(writing.review)}</TypographyNew>
                </div>
              </Stack>
            )}
          </Stack>
        </form>
      </Stack>
    </div>
  );
});
