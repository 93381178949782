import { CommentForm } from './comment-form';
import { CommentItem } from './comment-item';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Avatar, TypographyNew } from '@shared/UI';

import { renderCorrectUaNounInPlural } from '@shared/utils';

import s from './styles.module.scss';

export const CourseLessonComments = observer(function CourseLessonComments() {
  const { t, i18n } = useTranslation();

  const { courseViewStore, userStore } = useRootStore();

  const commentsLabel =
    i18n.language === 'ua'
      ? renderCorrectUaNounInPlural(courseViewStore.comments.length, 'коментар', true)
      : t('shared-content.comments');

  return (
    <div className={s.lessonComments}>
      <div className={s.lessonCommentsForm}>
        <Avatar src={userStore.avatar} />
        <CommentForm />
      </div>

      {!!courseViewStore.comments.length && (
        <div className={s.lessonCommentsCount}>
          <TypographyNew
            as="span"
            variant="body-2"
            colorSchema="neutral-200"
            className={s.lessonCommentsCountText}
          >
            {courseViewStore.comments.length}
          </TypographyNew>
          <TypographyNew as="span" variant="body-2" colorSchema="neutral-200">
            {commentsLabel}
          </TypographyNew>
        </div>
      )}

      {courseViewStore.comments.map((item) => (
        <CommentItem key={item.id} item={item} />
      ))}
    </div>
  );
});
