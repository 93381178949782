import { HomeworkTestStore } from './homework-test.store';
import { HomeworkWritingStore } from './homework-writing.store';
import { HomeworkType } from '@constants';
import { makeAutoObservable } from 'mobx';

import { BlockRepository } from '@core/repositories';
import type { MyHomeworkEntity, MyHomeworkTypes, SingleHomeworkEntity } from '@core/repositories';
import { getHttpError } from '@core/services/http';
import { LoadingStore } from '@core/store/common';

export class CourseHomeworkStore {
  homework: MyHomeworkEntity;

  groupId: number;

  homeworksTask: HomeworkTestStore | HomeworkWritingStore | null = null;

  repository = new BlockRepository();

  loader = new LoadingStore();

  blockKey: MyHomeworkTypes;

  constructor(homework: MyHomeworkEntity, groupId: number, blockKey: MyHomeworkTypes) {
    this.homework = homework;

    this.groupId = groupId;

    this.blockKey = blockKey;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  getHomeworkTaskById = async () => {
    if (this.homeworksTask !== null) return;

    this.loader.startLoading();
    try {
      const data = await this.repository.getHomeworkCourseById(this.homework.id, {
        groupId: this.groupId,
      });

      this.fillTasks(data);
    } catch (e) {
      const err = getHttpError(e);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  completeTask = () => {
    this.homeworksTask = null;
  };

  private fillTasks = (data: SingleHomeworkEntity) => {
    if (data.type === HomeworkType.test) {
      this.homeworksTask = new HomeworkTestStore({
        completedTests: data.completedTests,
        total: data.totalTests,
        correctAnswers: data.correctAnswers || 0,
        tests: data.tests,
        blockKey: this.blockKey,
        homeworkId: data.id,
        groupId: this.groupId,
      });
    }

    if (data.type === HomeworkType.writing) {
      this.homeworksTask = new HomeworkWritingStore({
        blockKey: this.blockKey,
        groupId: this.groupId,
        homeworkId: this.homework.id,
        ...data,
      });
    }
  };
}
