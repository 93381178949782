import { HTMLProps, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { useContextMenuContext } from '../context-menu.context';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { autorun } from 'mobx';

import { useLatestCallback } from '@shared/hooks';

const middlewares = [
  offset({ mainAxis: 5, alignmentAxis: 4 }),
  flip({
    fallbackPlacements: ['left-start'],
  }),
  shift({ padding: 10 }),
];

export const useMenuController = () => {
  const { controller: contextControler } = useContextMenuContext();

  const itemNodes = useRef<(HTMLElement | null)[]>([]);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const { context, refs, floatingStyles, isPositioned } = useFloating({
    open: contextControler.isOpen,
    onOpenChange: useCallback((open: boolean) => {
      if (!open) {
        contextControler.hide();
      }
    }, []),
    middleware: middlewares,
    strategy: 'fixed',
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
  });

  const intersectionList = [
    useRole(context, { role: 'menu' }),
    useDismiss(context),
    useListNavigation(context, {
      listRef: itemNodes,
      onNavigate: setActiveIndex,
      activeIndex,
    }),
  ];

  const setItemNode = useLatestCallback((node: HTMLElement | null) => {
    if (node) {
      itemNodes.current.push(node);
    }
  });

  const { getFloatingProps } = useInteractions(intersectionList);

  const _getFloatingProps = useCallback(
    (userProps?: HTMLProps<HTMLElement>) => {
      return {
        ...getFloatingProps({
          ...userProps,
          style: { ...floatingStyles, ...userProps?.style },
          ref: refs.setFloating,
        }),
      };
    },
    [floatingStyles, refs],
  );

  const mountMenu = useLatestCallback(() => {
    refs.setPositionReference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x: contextControler.position.x,
          y: contextControler.position.y,
          top: contextControler.position.y,
          right: contextControler.position.x,
          bottom: contextControler.position.y,
          left: contextControler.position.x,
        };
      },
    });
  });

  useLayoutEffect(() => {
    const disposer = autorun(() => {
      if (contextControler.isOpen) {
        mountMenu();
      }
    });

    return () => {
      disposer();
    };
  }, []);

  return {
    refs,
    context,
    isPositioned,
    activeIndex,
    isOpen: contextControler.isOpen,
    setItemNode,
    getFloatingProps: _getFloatingProps,
  };
};
