import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Radio, SvgIcon, Typography } from '@shared/UI';

import s from './styles.module.scss';

interface SavedCardVariantProps {
  brand: 'visa' | 'mastercard';
  expMonth: number;
  expYear: number;
  id: string;
  lastNumber: string;
  name: string;
}

export const SavedCardVariant = observer(function SavedCardVariant(props: SavedCardVariantProps) {
  const { name, lastNumber, expMonth, expYear, brand, id } = props;

  const { t } = useTranslation();

  return (
    <div className={s.stripeModalSavedCardVariant}>
      <div className={s.stripeModalSavedCardVariantWrapper}>
        <Radio
          id={`${name}-${lastNumber}-${brand}-${id}`}
          name={name}
          className={s.stripeModalSavedCardRadio}
          value={id}
          label={
            <div className={s.stripeModalSavedCardVariantInfoCard}>
              <Typography
                as="span"
                variant="body-medium-2"
                className={s.stripeModalSavedCardVariantInfoCardNumber}
              >{`**** ${lastNumber}`}</Typography>
              <Typography
                as="span"
                variant="body-medium-3"
                className={s.stripeModalSavedCardVariantInfoCardDate}
              >
                {`${t('modals.stripe-modal.exp-date')} ${expMonth}/${expYear}`}
              </Typography>
            </div>
          }
        />

        <SvgIcon type={brand} />
      </div>
    </div>
  );
});
