import { useMemo } from 'react';

import { CheckoutForm } from './checkout-form';
import type { BaseModalProps } from '@components';
import type { ProductTypes } from '@constants';
import { Elements } from '@stripe/react-stripe-js';
import type { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import Lottie from 'lottie-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { CommunityPreviewPrice } from '@core/repositories';

import paymentSuccess from '@assets/lottie-animations/payment_success.json';

import { Modal, Spinner, Stack, SvgIcon, TypographyNew } from '@shared/UI';

import { useToggle } from '@shared/hooks';
import type { ObjectValues } from '@shared/types';

import s from './styles.module.scss';

const getOptions = (clientSecret: string) => {
  const defaultOptions = {
    appearance: {
      theme: 'night',
    },
    loader: 'always',
  };

  if (!clientSecret) return defaultOptions;

  return {
    clientSecret,
    ...defaultOptions,
  };
};

interface StripeModalProps extends BaseModalProps {
  price: CommunityPreviewPrice;
  subscriptionTitle: string;
  productType: ObjectValues<typeof ProductTypes>;
  stripe: Stripe | null;
  clientSecret: string;
  isTrial: boolean;
  courseId?: number;
}

export const StripeModal = observer(function StripeModal(props: StripeModalProps) {
  const { stripe, clientSecret, subscriptionTitle, price, isTrial, courseId, ...restProps } = props;

  const { t } = useTranslation();

  const options = useMemo(() => getOptions(clientSecret), [clientSecret]);

  const [isProcessing, toggleIsProcessing] = useToggle();

  return (
    <Modal centered size="lg" withHeader={false} className={s.stripeModal} {...restProps}>
      {isProcessing && <Spinner isFullSize />}
      <Stack gap="xs">
        <div className={s.stripeModalAnimation}>
          <Lottie animationData={paymentSuccess} loop={false} />
        </div>
        <TypographyNew variant="title-2" weight="bold">
          {t('modals.stripe-modal.title')}
        </TypographyNew>
        <TypographyNew variant="body-1">{t('modals.stripe-modal.subtitle')}</TypographyNew>
      </Stack>
      <Stack gap="xxl" className={s.stripeModalCheckout}>
        <Elements stripe={stripe} options={options as StripeElementsOptions}>
          <CheckoutForm
            subscriptionTitle={subscriptionTitle}
            price={price.price}
            id={price.id}
            isTrial={isTrial}
            isSubscription={price.isSubscription}
            clientSecret={clientSecret}
            toggleIsProcessing={toggleIsProcessing}
            courseId={courseId}
          />
        </Elements>
        <SvgIcon type="stripe-power-by" />
      </Stack>
    </Modal>
  );
});
