import { RouterLink } from '@components';
import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';

import s from './styles.module.scss';

export const NotFound = observer(function NotFound() {
  return (
    <div className={s.notFound}>
      <div className={s.notFoundContainer}>
        <div className={s.notFoundNoise} />
        <div className={s.notFoundOverlay} />
        <div className={s.notFoundTerminal}>
          <h1 className={s.notFoundTitle}>
            Error <span className={s.notFoundTitleErrorcode}>404</span>
          </h1>
          <p className={s.notFoundDescription}>
            <span className={s.notFoundArrow}>{'>'}</span>
            The page you are looking for might have been removed, had its name changed or is
            temporarily unavailable.
          </p>
          <p className={s.notFoundDescription}>
            <span className={s.notFoundArrow}>{'>'}</span>Please try to
            <RouterLink
              to={routerCollection.dashboard.root}
              className={s.notFoundDescriptionLink}
              replace
            >
              [return to the homepage]
            </RouterLink>
            .
          </p>
          <p className={s.notFoundDescription}>
            <span className={s.notFoundArrow}>{'>'}</span>Good luck.
          </p>
          <p className={s.notFoundBlinking}>
            <span className={s.notFoundArrow}>{'>'}</span>
            <span className={s.notFoundBlinkingCaret}></span>
          </p>
        </div>
      </div>
    </div>
  );
});
