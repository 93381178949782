import { useCallback } from 'react';

import type { BaseModalProps } from '@components';
import { useTranslation } from 'react-i18next';

import { observer } from '@shared/libs/mobx';

import { Group, Modal, Stack, TypographyNew } from '@shared/UI';
import { ButtonNew } from '@shared/UI/button-new';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface ConfirmDeleteProps extends BaseModalProps {
  label: string;

  description?: string;

  onClickDelete?: () => void;
}

const _ConfirmDelete = observer((props: ConfirmDeleteProps) => {
  const { label, description, centered = true, onClose, onClickDelete, ...restProps } = props;

  const { t } = useTranslation();

  const onHandleDelete = useCallback(() => {
    onClickDelete?.();
    onClose?.();
  }, [onClose, onClickDelete]);

  return (
    <Modal
      centered={centered}
      title={
        <TypographyNew variant="title-4" weight="bold">
          {label}
        </TypographyNew>
      }
      onClose={onClose}
      {...restProps}
    >
      <Stack className={s.confirmDeleteBody} gap="xxl">
        {description && (
          <TypographyNew textAlign="center" variant="body-2" colorSchema="neutral-300">
            {description}
          </TypographyNew>
        )}
        <Group wrap="nowrap" grow w="100%">
          <ButtonNew onClick={onClose} variant="secondary-outline">
            {t('shared-content.cancel')}
          </ButtonNew>
          <ButtonNew variant="red" onClick={onHandleDelete}>
            {t('shared-content.delete')}
          </ButtonNew>
        </Group>
      </Stack>
    </Modal>
  );
});

export const ConfirmDelete = mergeComponentsWithName('ConfirmDelete', _ConfirmDelete);
