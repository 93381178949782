import { PATH_COLLECTION } from '@core/constants';
import { createHTTPNotificationService } from '@core/services/http';

export class NotificationRepository {
  private readonly httpNotification = createHTTPNotificationService(
    PATH_COLLECTION.NOTIFICATION.ROOT,
  );

  readAllNotifications = () => {
    const path = PATH_COLLECTION.NOTIFICATION.READ_ALL;

    return this.httpNotification.patch(path, {});
  };

  readNotificationById = (notificationId: number) => {
    const path = PATH_COLLECTION.NOTIFICATION.READ_BY_ID;

    return this.httpNotification.patch(path, {}, { param: { notificationId } });
  };
}

export const notificationRepository = new NotificationRepository();
