import { useEffect, useRef } from 'react';

import { MessageItem } from '../message-item';
import { usePaginateStreamsLiveChat } from '../stream-chat.hooks';
import { observer } from 'mobx-react-lite';

import type { StreamChatTabs } from '@core/constants';
import { useRootStore } from '@core/store';

import { cl } from '@shared/libs/classnames';

import { Each } from '@shared/UI';

import type { ObjectValues } from '@shared/types';

import s from './styles.module.scss';

interface MessageListProps {
  tab: ObjectValues<typeof StreamChatTabs>;
}

export const MessageList = observer(function MessageList(props: MessageListProps) {
  const { tab } = props;

  const { streamChatStore, streamsStore } = useRootStore();

  const liveChatMessagesListRef = useRef(null);

  const isNeedToPaginate = usePaginateStreamsLiveChat(liveChatMessagesListRef);

  useEffect(() => {
    if (isNeedToPaginate) {
      streamChatStore.getMessages();
    }
  }, [isNeedToPaginate]);

  const rootClasses = cl(s.messagesList, {
    [s.messagesListAdmin]: streamsStore.stream?.userCanModerateChat,
  });

  return (
    <div className={rootClasses} ref={liveChatMessagesListRef}>
      <Each data={streamChatStore.messages}>
        {(message) => (
          <MessageItem
            key={message.id}
            message={message.message}
            messageId={message.id}
            authorName={message.authorName}
            authorId={message.authorId}
            tab={tab}
          />
        )}
      </Each>
    </div>
  );
});
