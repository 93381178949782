export function classNames<T extends string = string, P extends string = 'ui'>(
  root: T,
  customPrefix?: P,
) {
  const prefix = customPrefix ?? ('ui' as P);

  const _root = `${prefix}-${root}` as `${P}-${T}`;

  function appendClass(className: string | string[], parentClassName?: string) {
    if (Array.isArray(className)) {
      return className.map((c) => `${_root}${c}`).join(' ');
    }

    if (parentClassName) {
      return `${_root}${parentClassName.replace(_root, '')}${className.replace(_root, '')}`;
    } else {
      return `${_root}${className}`;
    }
  }

  return { rootClass: _root, appendClass };
}
