import { cloneElement } from 'react';

import { authRouter } from './auth';
import { contentRouter } from './content';
import { notFoundRouter } from './not-found';
import { onBoardingRouter } from './on-boarding';
import { modalStore } from '@components';
import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { uiTestingRouter } from '@pages/ui-testing';

import { ContextMenuNew } from '@shared/UI';

const Modals = observer(() => {
  const modals = modalStore.activeModals.map((modal) => {
    const isNeedToUnmount =
      'isNeedToUnmount' in modal.component.props && modal.component.props.isNeedToUnmount;

    return cloneElement(modal.component, {
      ...modal.component.props,
      key: modal.id,
      isOpen: modal.isOpen,
      id: modal.id,
      onClose: isNeedToUnmount
        ? () => modalStore.deactivateModal(modal.id)
        : () => modalStore.hideModal(modal.id),
    });
  });

  return <>{modals[modals.length - 1] ?? null}</>;
});

export const rootRouter = createBrowserRouter([
  {
    element: (
      <ContextMenuNew>
        <Outlet />
        <Modals />
      </ContextMenuNew>
    ),
    children: [authRouter, notFoundRouter, contentRouter, onBoardingRouter],
  },
  !import.meta.env.PROD ? uiTestingRouter : {},
  {
    path: '*',
    element: <Navigate to={routerCollection.notFound.root} replace />,
  },
]);

export const RootRouter = () => {
  return <RouterProvider router={rootRouter} />;
};
