import type { CSSProperties, ElementType, ReactNode } from 'react';
import { useMemo } from 'react';

import type { TypographyVariant } from './_types';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import { useClassName } from '@shared/UI/_hooks';

import type { ElementProps } from '@shared/types';
import { mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

export type TypographyProps<Element extends ElementType = 'div'> = ElementProps<Element> & {
  variant?: TypographyVariant;
  as?: Element;
  children: ReactNode;
  wordBreak?: CSSProperties['wordBreak'];
  whiteSpace?: CSSProperties['whiteSpace'];
  ellipsis?: boolean;
  lineClamp?: null | number;
  userSelect?: CSSProperties['userSelect'];
  align?: CSSProperties['textAlign'];
  decoration?: CSSProperties['textDecoration'];
  color?: CSSProperties['color'];
};

const _Typography = observer(<Element extends ElementType>(props: TypographyProps<Element>) => {
  const {
    variant = 'body-medium-1',
    as = 'div',
    className,
    ellipsis = false,
    children,
    style,
    lineClamp,
    userSelect = 'auto',
    align = 'initial',
    decoration = 'none',
    color,
    wordBreak,
    whiteSpace,
    ...restProps
  } = props;

  const { rootClassName, appendClass } = useClassName('typography');

  let Component = as;

  const rootClasses = cl(
    rootClassName,
    appendClass(`--${variant}`),
    {
      [appendClass('--ellipsis')]: ellipsis,
    },
    className,
  );

  const ellipsisRowsStyles = useMemo((): CSSProperties => {
    if (lineClamp) {
      return {
        WebkitLineClamp: lineClamp,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      };
    }
    return {};
  }, [lineClamp]);

  const styles: CSSProperties = {
    color,
    ...ellipsisRowsStyles,
    ...style,
    wordBreak,
    whiteSpace,
    '--ui-typography-user-select': userSelect,
    '--ui-typography-align': align,
    '--ui-typography-decoration': decoration,
  };

  return (
    <Component className={rootClasses} style={styles} {...restProps}>
      {children}
    </Component>
  );
});

export const Typography = mergeComponentsWithName('Typography', _Typography);
