import { FormBox } from '../../_components';
import { PrivacyPolicy, Subtitle } from '../_components';
import { FormFields } from '@components';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import type { RegisterOptions, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { SubmitButton } from '@features/auth/_components/submit-button';
import { useVerifyOtp } from '@features/auth/_hooks';
import type { FormSignUpByEmailSchema } from '@features/auth/_schemas';
import { signUpByEmailSchema } from '@features/auth/_schemas';

export const SignUpFormByEmail = observer(function RegistrationForm() {
  const { t } = useTranslation();

  const { authStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const onVerifyOtp = useVerifyOtp();

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    getValues,
    setValue,
  } = useForm<FormSignUpByEmailSchema>({
    resolver: zodResolver(signUpByEmailSchema),
    defaultValues: {
      ...authStore.defaultFormValues.signUpByEmail,
      confirmPassword: '',
      password: '',
      isAgreeRules: false,
      emailSubscription: false,
      otp: '',
    },
  });

  const onSubmit: SubmitHandler<FormSignUpByEmailSchema> = async (dto) => {
    const { email, password, otp } = dto;

    if (otp) {
      await onVerifyOtp({ email, otp });
      sendEvent({ event: AnalyticsEvents.RegistrationAccountTap });
    } else {
      await authStore.signUpByEmail({ email, password });
    }
  };

  const handleClickResend = async () => {
    try {
      const isValid = await trigger();
      if (isValid) {
        const { email, password } = getValues();
        authStore.signInByEmail({ email, password });
        return;
      }
      return await Promise.reject();
    } catch (e) {
      return await Promise.reject();
    }
  };

  const handleChangeEmailOrPassword = () => {
    if (authStore.defaultFormValues.signUpByEmail.isOTPVisible) {
      authStore.setIsOTPVisible(false, 'signUpByEmail');
      setValue('otp', '');
    }
  };

  const emailAndPasswordRules: RegisterOptions = {
    onChange: handleChangeEmailOrPassword,
  };

  return (
    <FormBox
      onSubmit={handleSubmit(onSubmit)}
      title={t('shared-content.sign-in')}
      subTitle={<Subtitle />}
    >
      <FormFields.Input
        name="email"
        label="inputs.email.label"
        placeholder="inputs.email.placeholder"
        error={errors.email}
        control={control}
        rules={emailAndPasswordRules}
      />
      <FormFields.Password
        name="password"
        label="inputs.password.label"
        placeholder="inputs.password.placeholder"
        error={errors.password}
        control={control}
        rules={emailAndPasswordRules}
      />
      <FormFields.Password
        name="confirmPassword"
        label="inputs.confirm-password.label"
        placeholder="inputs.password.placeholder"
        error={errors.confirmPassword}
        control={control}
      />
      {authStore.defaultFormValues.signUpByEmail.isOTPVisible && (
        <FormFields.OtpCode
          onClickResend={handleClickResend}
          name="otp"
          control={control}
          error={errors.otp}
        />
      )}
      <PrivacyPolicy
        control={control}
        isAgreeRulesError={errors.isAgreeRules}
        emailSubscriptionError={errors.emailSubscription}
      />

      <SubmitButton
        control={control}
        isOtpVisible={authStore.defaultFormValues.signUpByEmail.isOTPVisible}
        type="sign-up"
      />
    </FormBox>
  );
});
