import {
  KeyboardSensor,
  PointerSensor,
  PointerSensorOptions,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

export interface UseCommunitySidebarSensorsParams {
  pointerOptions?: PointerSensorOptions;
}

export const useCommunitySidebarSensors = (params?: UseCommunitySidebarSensorsParams) => {
  const { pointerOptions } = params || {};
  const pointerSensor = useSensor(PointerSensor, pointerOptions);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  const sensors = useSensors(pointerSensor, keyboardSensor, touchSensor);

  return sensors;
};
