import { useModalState } from '@hooks';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@shared/hooks';

export const useQuickEmojiController = () => {
  const { i18n } = useTranslation();

  const { isOpen, onToggle, onClose } = useModalState();

  const locale = i18n.language === 'ua' ? 'uk' : 'en';

  const isMobile = useMediaQuery('(max-width: 768px)');

  const perLine = isMobile ? 6 : 8;

  return {
    isOpen,
    locale,
    isMobile,
    perLine,
    onToggle,
    onClose,
  };
};
