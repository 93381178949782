import type { ButtonHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import { useClassName } from '@shared/UI/_hooks';
import { Spinner } from '@shared/UI/spinner';

import { mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

export type ButtonVariants =
  | 'light'
  | 'light-tinted'
  | 'light-gray'
  | 'text'
  | 'dark'
  | 'dark-gray'
  | 'outline'
  | 'outline-gray'
  | 'outline-tinted'
  | 'outline-neutral'
  | 'outline-dark';

export type ButtonProps = {
  prefixIcon?: ReactNode;
  suffixIcon?: ReactNode;
  prefixIconClassName?: string;
  suffixIconClassName?: string;
  isLoading?: boolean;
  variant?: ButtonVariants;
  size?: 'lg' | 'md' | 'sm' | 'xs' | 'icon';
  disabledAnimation?: boolean;
  shape?: 'square' | 'circle' | 'rounded';

  fillColor?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const _Button = observer(
  forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
    const {
      prefixIcon,
      suffixIcon,
      prefixIconClassName,
      suffixIconClassName,
      className,
      disabled,
      isLoading,
      children,
      variant = 'dark',
      type = 'button',
      size = 'md',
      disabledAnimation = false,
      shape = 'rounded',
      ...restProps
    } = props;

    const { rootClassName, appendClass } = useClassName('button');

    const rootClasses = cl(
      rootClassName,
      appendClass([`--${size}`, `--${variant}`, `--${shape}`]),

      {
        [appendClass('--disabled')]: disabled,
        [appendClass('--loading')]: isLoading,
        [appendClass('--disabled-animation')]: disabledAnimation,
      },
      className,
    );

    const prefixIconClasses = cl(appendClass('__prefix-icon'), prefixIconClassName);
    const suffixClasses = cl(appendClass('__suffix-icon'), suffixIconClassName);

    const withPrefix = prefixIcon && <span className={prefixIconClasses}>{prefixIcon}</span>;

    const withSuffix = suffixIcon && <span className={suffixClasses}>{suffixIcon}</span>;

    return (
      <button ref={ref} className={rootClasses} type={type} disabled={disabled} {...restProps}>
        {withPrefix}
        <span className={appendClass('-content')}>{children}</span>
        {isLoading && <Spinner size="sm" className={appendClass('-loader')} />}
        {withSuffix}
      </button>
    );
  }),
);

export const Button = mergeComponentsWithName('Button', _Button);
