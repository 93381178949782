import { RouterLink } from '../../routes';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import { Badge, Image, ProgressBar, Typography, TypographyNew } from '@shared/UI';

import { createStandardDateFormat, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

export type GridCourseBlockProps = {
  title: string;

  startDate: string;

  endDate: string;

  progress: number;

  accountTitle: string;

  image: string;

  groupId: number;

  to?: string;

  fullSize?: boolean;
};

export const GridCourseBlock = observer(function GridCourseBlock(props: GridCourseBlockProps) {
  const { progress, accountTitle, image, title, startDate, endDate, to, fullSize } = props;

  const date = `${createStandardDateFormat(startDate)} - ${createStandardDateFormat(endDate)}`;

  const rootClasses = cl(s.gridCourseBlock, {
    [s.gridCourseBlockFullSize]: fullSize,
  });

  return (
    <div className={rootClasses}>
      {to && <RouterLink className={s.gridCourseBlockLink} to={to}></RouterLink>}
      <Image src={withMediaUrl(image)} alt="course tile image" className={s.gridCourseBlockBg} />
      <div className={s.gridCourseBlockBackdrop} />
      <div className={s.gridCourseBlockContainer}>
        <div>
          <Badge variant="blackOpacity" size="sm" label={accountTitle} />
        </div>
        <div>
          <Typography as="p" variant="title-bold-3" lineClamp={2}>
            {title}
          </Typography>
          <TypographyNew
            variant="title-4"
            colorSchema="secondary-300"
            className={s.gridCourseBlockDescriptionDate}
          >
            {date}
          </TypographyNew>
        </div>
        <div className={s.gridCourseBlockProgressBox}>
          <ProgressBar progress={progress} bgVariant="gray" size="sm" isHiddenLabel />
        </div>
      </div>
    </div>
  );
});
