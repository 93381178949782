type Ev =
  | Event
  | MouseEvent
  | TouchEvent
  | PointerEvent
  | React.MouseEvent
  | React.TouchEvent
  | React.PointerEvent;

export const extractMsgNodeFromParentEvent = (event: Ev) => {
  const target = event.target as HTMLElement;

  if (!target) return null;

  const extractMessageNode = target.closest('[data-message-id]') as HTMLElement;

  if (!extractMessageNode) return null;

  return {
    ...extractMessageNode,
    get messageId() {
      return extractMessageNode.getAttribute('data-message-id');
    },
  };
};
