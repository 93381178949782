import { routerCollection } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { useAppNavigate } from '@hooks';
import type { CommunityCardProps } from '@units';
import { CommunityCard } from '@units';

import type { CommunityBlockItemEntity } from '@core/repositories';
import type { Branch } from '@core/store';

import { observer } from '@shared/libs/mobx';

import type { CarouselProps } from '@shared/UI';
import { Carousel } from '@shared/UI';

import { mergeComponentsWithName, withMediaUrl } from '@shared/utils';

export interface CommunityCardsCarouselProps extends CarouselProps {
  communities: CommunityBlockItemEntity[];

  hasViewerAccess?: boolean;

  isLoading?: boolean;

  cardSize?: CommunityCardProps['size'];

  getCommunityUnreadCount?: (communityId: number) => Branch | null;
}

const _CommunityCardsCarousel = observer((props: CommunityCardsCarouselProps) => {
  const { communities, hasViewerAccess, cardSize, getCommunityUnreadCount, ...restProps } = props;

  const navigate = useAppNavigate();

  const sendEvent = useAnalyticsContext();

  const handleClickCard = (community: CommunityBlockItemEntity) => {
    return () => {
      sendEvent({
        event: AnalyticsEvents.CommunitySelectTap,
        payload: { brand_id: community.id, brand_name: community.communityTitle },
      });

      if (hasViewerAccess) {
        navigate(routerCollection.community.community, {
          params: {
            businessAccountId: community.id,
          },
        });
      } else {
        navigate(routerCollection.community.communityPreview, {
          params: {
            businessAccountId: community.id,
          },
        });
      }
    };
  };

  const cardsMapper = communities.map((community) => {
    return (
      <Carousel.Slide key={community.communityId}>
        <CommunityCard
          onClick={handleClickCard(community)}
          role="button"
          tabIndex={0}
          title={community.communityTitle}
          rating={community.averageRating || 0}
          cardBackgroundSrc={withMediaUrl(community.logo)}
          languageFlagSrc={withMediaUrl(community.langFlagImage)}
          activeUsersCount={community.activeCommunityUsers}
          communityLanguageAbbr={community.lang}
          size={cardSize}
          communityId={community.id}
          hasViewerAccess={hasViewerAccess}
          unreadCount={getCommunityUnreadCount?.(community.id)?.totalCount ?? 0}
        />
      </Carousel.Slide>
    );
  });

  return <Carousel {...restProps}>{cardsMapper}</Carousel>;
});

export const CommunityCardsCarousel = mergeComponentsWithName(
  'CommunityCardsCarousel',
  _CommunityCardsCarousel,
);
