import { NotificationContent } from './notifications-content';
import { useModalState } from '@hooks';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { Button, Dropdown, SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

export const Notifications = observer(function Notifications() {
  const { isOpen, onToggle } = useModalState();

  const { notificationsStore, privateChatsStore } = useRootStore();

  return (
    <Dropdown
      isOpen={isOpen}
      contentClassName={s.notifications}
      content={
        <NotificationContent
          joinToChat={privateChatsStore.joinToChat}
          readAllNotifications={notificationsStore.readAllNotifications}
          notifications={notificationsStore.notifications}
        />
      }
      onOpenChange={onToggle}
      offsetValue={24}
    >
      <Button
        size="icon"
        variant="text"
        shape="square"
        disabledAnimation
        className={s.notificationsButton}
      >
        <SvgIcon type="bell" />
        {!!notificationsStore.notifications.length && (
          <span className={s.notificationsButtonBadge} />
        )}
      </Button>
    </Dropdown>
  );
});
