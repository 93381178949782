import { useParams } from 'react-router-dom';

type CommunityAreaParams = {
  chatId?: string;
  businessAccountId?: string;
  channelId?: string;
};

type PrivateAreaParams = {
  chatId?: string;
};

export enum TalkingArea {
  COMMUNITY = 'community',
  PRIVATE = 'private',
}

type TalkingAreaType<T extends TalkingArea> = T extends TalkingArea.COMMUNITY
  ? CommunityAreaParams
  : T extends TalkingArea.PRIVATE
  ? PrivateAreaParams
  : unknown;

export const useMessageParams = <T extends TalkingArea>(area: T): TalkingAreaType<T> => {
  const params = useParams<TalkingAreaType<T>>();

  if (area === TalkingArea.COMMUNITY) {
    return params as unknown as TalkingAreaType<T>;
  } else {
    return params as unknown as TalkingAreaType<T>;
  }

  // return params as unknown as TalkingAreaType<T>;
};
