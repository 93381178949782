import type { AnimationEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';

import { loaderAnimation } from './_utils';
import cl from 'classnames';
import type { LottieRefCurrentProps } from 'lottie-react';
import Lottie from 'lottie-react';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import s from './styles.module.scss';

export type LoaderProps = {
  isLoading?: boolean;
};

export const Loader = observer(function Loader(props: LoaderProps) {
  const { isLoading = false } = props;

  const { appLoaderStore } = useRootStore();

  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);

  useEffect(() => {
    // needs for smooth transition between page and for good animation when request was successful
    if (!appLoaderStore.isVisibleLoader && lottieRef.current && isAnimationCompleted) {
      lottieRef.current.stop();
    }

    if (
      (appLoaderStore.isVisibleLoader || isLoading || !isAnimationCompleted) &&
      lottieRef.current
    ) {
      setIsAnimationCompleted(false);
      lottieRef.current.play();
    }
  }, [appLoaderStore.isVisibleLoader, isAnimationCompleted]);

  const handleEnterFrameCallback: AnimationEventHandler = (event) => {
    const targetFrame = 240;
    const currentFrame = Math.floor((event as any).currentTime);

    if (currentFrame === targetFrame && !appLoaderStore.isVisibleLoader) {
      setIsAnimationCompleted(true);
    }
  };

  const onLoopCompleteCallback = () => {
    setIsAnimationCompleted(true);
  };

  const rootClasses = cl(
    s.loader,
    appLoaderStore.isVisibleLoader || isLoading || !isAnimationCompleted
      ? s.loaderActive
      : s.loaderHidden,
  );

  const rendererSettings = {
    preserveAspectRatio: 'xMidYMid slice',
  };

  return (
    <div className={rootClasses}>
      <Lottie
        className={s.loaderAnimation}
        animationData={loaderAnimation}
        loop
        lottieRef={lottieRef}
        rendererSettings={rendererSettings}
        onEnterFrame={handleEnterFrameCallback}
        onLoopComplete={onLoopCompleteCallback}
      />
    </div>
  );
});
