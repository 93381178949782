import { useCallback, useMemo } from 'react';

import { VirtualItem } from '@tanstack/react-virtual';
import { IMessageListController } from '@units';
import { computed } from 'mobx';

import { useRootStore } from '@core/store';

interface UsePrivateMessageControllerProps {
  row: VirtualItem;
  messageListController: IMessageListController;
}
export const usePrivateMessageController = (props: UsePrivateMessageControllerProps) => {
  const { messageListController, row } = props;

  const { privateChatsStore, privateMessagesStore } = useRootStore();

  const message = computed(() => messageListController.rowsWithDate[row.index]).get();

  const onSelectEmoji = useCallback(
    (emoji: string) => {
      if (!privateChatsStore.currentChatId || !messageListController.isMessage(message)) return;

      privateMessagesStore.updateEmojiEmitter({
        privateChatId: privateChatsStore.currentChatId,
        messageId: message.id,
        reaction: emoji,
      });
    },
    [message],
  );

  const messageParams = useMemo(
    () =>
      ({
        owner:
          messageListController.isMessage(message) && message?.isMessageFromViewer
            ? 'viewer'
            : 'participant',
        variant:
          messageListController.isMessage(message) && message?.isMessageFromViewer
            ? 'primary'
            : 'secondary',
      } as const),
    [message],
  );

  return {
    message,
    messageParams,
    onSelectEmoji,
  };
};
