import { HttpStatusCode } from 'axios';

import { PATH_COLLECTION } from '@core/constants';
import { HttpError } from '@core/services/http';

export const rejectedInterceptor = (error: any) => {
  const err = HttpError.create(error);

  if (
    HttpStatusCode.Unauthorized === err.status &&
    !window.location.pathname.includes(PATH_COLLECTION.AUTH.ROOT)
  ) {
    window.location.replace(`${PATH_COLLECTION.AUTH.ROOT}/${PATH_COLLECTION.AUTH.SIGN_IN}`);
    return Promise.reject(err);
  }

  return Promise.reject(err);
};
