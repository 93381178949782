import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { toast } from 'react-toastify';

import type { CalendarEntity, EventsBlocksQueryDto, EventsKeyType } from '@core/repositories';
import { BlockRepository, CalendarRepository } from '@core/repositories';
import type { EventsItemEntity } from '@core/repositories/_entities/events-item.entity';
import { getHttpError } from '@core/services/http';
import { LoadingStore } from '@core/store/common';

export class EventsStore {
  calendar: CalendarEntity | null = null;

  loader = new LoadingStore();

  private blocksRepository = new BlockRepository();

  private calendarRepository = new CalendarRepository();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'EventsStore',
      properties: ['calendar'],
    });
  }

  getAll = async (query?: EventsBlocksQueryDto) => {
    if (!this.calendar) {
      this.loader.startLoading();
    }

    try {
      const data = await this.blocksRepository.getEvents(query);

      runInAction(() => {
        this.calendar = data.block.calendar;
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    } finally {
      this.loader.stopLoading();
    }
  };

  subscribeToEvent = async (id: number) => {
    try {
      const data = await this.calendarRepository.subscribeToEvent(id);

      runInAction(() => {
        this.updateParticipantEvent(data.calendar, id);
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  unSubscribeToEvent = async (id: number) => {
    try {
      const data = await this.calendarRepository.unsubscribeToEvent(id);

      runInAction(() => {
        this.updateParticipantEvent(data.calendar, id);
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  disposer = () => {
    clearPersistedStore(this);
  };

  private updateParticipantEvent = (updEvent: EventsItemEntity, id: number) => {
    this.calendar = Object.entries(this.calendar as CalendarEntity).reduce(
      (acc: CalendarEntity, [key, value]) => {
        if (!acc.hasOwnProperty(key)) {
          acc[key as EventsKeyType] = value.map((event) => (event.id === id ? updEvent : event));
        }
        return acc;
      },
      {} as CalendarEntity,
    );
  };
}
