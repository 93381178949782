import { isSubmitButtonDisabled } from '../../_utils';
import { observer } from 'mobx-react-lite';
import type { Control } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Box, Button } from '@shared/UI';

type SubmitButtonProps = {
  control: Control<any, any>;
  type?: 'sign-in' | 'sign-up';
  isOtpVisible: boolean;
};

export const SubmitButton = observer(function SubmitButton(props: SubmitButtonProps) {
  const { control, type = 'sign-in', isOtpVisible } = props;

  const { t } = useTranslation();

  const otp = useWatch({
    control,
    name: 'otp',
  }) as string;

  const { authStore } = useRootStore();

  return (
    <Box py={[18, 20]}>
      <Button
        disabled={isSubmitButtonDisabled(authStore.isAuthLoading, isOtpVisible, otp)}
        isLoading={authStore.isAuthLoading}
        type="submit"
      >
        {type === 'sign-in' ? t('shared-content.sign-in') : t('shared-content.sign-up')}
      </Button>
    </Box>
  );
});
