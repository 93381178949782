import { forwardRef } from 'react';
import type { ForwardedRef } from 'react';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { useClassName } from '@shared/UI/_hooks';
import { BaseInputContainer } from '@shared/UI/common';
import type { BaseInputContainerProps } from '@shared/UI/common';

import { useId } from '@shared/hooks';
import type { ElementProps } from '@shared/types';
import { componentName } from '@shared/utils';

import './styles.scss';

export interface TextareaProps extends ElementProps<'textarea'> {
  label?: BaseInputContainerProps['label'];

  error?: BaseInputContainerProps['error'];

  containerProps?: Omit<BaseInputContainerProps, 'id' | 'children' | 'label' | 'className'>;
}

const _Textarea = observer(
  forwardRef((props: TextareaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const { className, id, containerProps, error, label, rows = 8, ...restProps } = props;

    const { rootClassName, appendClass } = useClassName('textarea');

    const rootClasses = cl(
      rootClassName,
      {
        [appendClass('--with-error')]: error,
      },
      className,
    );

    const _id = useId(id);

    return (
      <BaseInputContainer
        id={_id}
        error={error}
        label={label}
        className={rootClasses}
        {...containerProps}
      >
        <textarea ref={ref} id={_id} className={appendClass('-field')} rows={rows} {...restProps} />
      </BaseInputContainer>
    );
  }),
);

export const Textarea = Object.assign(_Textarea, {
  ...componentName('Textarea'),
});
