import { Element, attributesToProps } from 'html-react-parser';

import { TypographyNew } from '@shared/UI';

import { asHtml } from '@shared/utils';

export function messageTextParser(message: string) {
  return asHtml(message, {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs) {
        if (domNode.name === 'p' && domNode.children.length === 0) {
          return <br />;
        }

        if (domNode.attribs['data-type'] && domNode.attribs['data-type'] === 'mention') {
          const attributes = domNode.attribs;
          const props = attributesToProps(domNode.attribs);

          props['className'] = 'message-mention';

          return (
            <TypographyNew as="span" variant="body-2" {...props}>
              @{attributes['data-label']}
            </TypographyNew>
          );
        }
      }
    },
  });
}
