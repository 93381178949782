import { useEffect } from 'react';

import { MessageItem } from '../message-item';
import { observer } from 'mobx-react-lite';

import type { StreamChatTabs } from '@core/constants';
import { useRootStore } from '@core/store';

import type { ObjectValues } from '@shared/types';

interface MutedAndMarkedUsersProps {
  tab: ObjectValues<typeof StreamChatTabs>;
}

export const MutedAndMarkedUsers = observer(function MutedAndMarkedUsers(
  props: MutedAndMarkedUsersProps,
) {
  const { tab } = props;

  const { streamChatStore } = useRootStore();

  useEffect(() => {
    streamChatStore.getMarkedAndMutedUsers();
  }, []);

  return streamChatStore.markedAndMutedUsers.map((user) => (
    <MessageItem
      key={user.authorId}
      authorId={user.authorId}
      authorName={user.authorName}
      tab={tab}
      userStatus={user.status}
    />
  ));
});
