import { useCallback, useEffect, useState } from 'react';

interface UseUncontrolled<T> {
  value?: T;

  defaultValue?: T;

  finalValue?: T;

  onChange?: (value: T) => void;
}

export function useUncontrolled<T>(options: UseUncontrolled<T>): [T, (value: T) => void, boolean] {
  const { value, defaultValue, finalValue, onChange = () => {} } = options;

  const [uncontrolledValue, setUncontrolledValue] = useState(
    defaultValue !== undefined ? defaultValue : finalValue,
  );

  const handleUncontrolledChange = useCallback(
    (val: T) => {
      setUncontrolledValue(val);
      onChange?.(val);
    },
    [onChange],
  );

  useEffect(() => {
    if (defaultValue !== undefined) {
      setUncontrolledValue(defaultValue);
    } else {
      setUncontrolledValue(finalValue);
    }
  }, [defaultValue]);

  if (value !== undefined) {
    return [value as T, onChange, true];
  }

  return [uncontrolledValue as T, handleUncontrolledChange, false];
}
