import { observer } from 'mobx-react-lite';

import { SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

interface EventCardBookmarkProps {
  isSubscribed: boolean;
  onChangeStatus?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const EventCardBookmark = observer(function EventCardBookmark(
  props: EventCardBookmarkProps,
) {
  const { isSubscribed, onChangeStatus } = props;

  return (
    <button onClick={onChangeStatus} className={s.eventCardBookmark}>
      <div className={s.eventCardBookmarkIcon}>
        {isSubscribed ? <SvgIcon type="check" /> : <SvgIcon type="union" />}
      </div>
    </button>
  );
});
