import type { ChangeEvent } from 'react';

import type { CheckboxSize } from './checkbox.types';

import { createOptionalContext } from '@shared/utils';

interface CheckboxGroupContext {
  value: string[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: CheckboxSize;
  disabled?: boolean;
  isChecked: (value: string) => boolean;
}

export const [CheckboxGroupProvider, useCheckboxContext] =
  createOptionalContext<CheckboxGroupContext>();
