import { useFormController } from './form.controller';
import { CancelRecording, EmojiPicker, MediaRecordButtons } from '@features-new';
import { EditorBox, MessageDrawer, SendButton } from '@units';
import { type TargetAndTransition, motion } from 'framer-motion';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { RichTextEditor, Stack, SvgIcon, Upload } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface FormProps {
  chatId: string | null;
}

const footerAnimate: TargetAndTransition = {
  transform: 'translateY(0)',
  transition: {
    delay: 0.2,
  },
};

const footerInitial = {
  transform: 'translateY(100%)',
};
const _Form = observer((props: FormProps) => {
  const { chatId } = props;

  const { t } = useTranslation();

  const {
    messageTransmitter,
    control,
    editorRef,
    canShowSendButton,
    isRecording,
    onSubmit,
    onSelectEmoji,
    onChangeUpload,
    onSubmitByEnter,
  } = useFormController({
    chatId,
  });

  return (
    <motion.form
      initial={footerInitial}
      animate={footerAnimate}
      exit={footerAnimate}
      id="community-message-form"
      onSubmit={onSubmit}
      className={cl(s.communityMessageForm, {
        [s.communityMessageFormRecording]: isRecording,
      })}
    >
      <EditorBox className={s.communityMessageFormEditorWrapper}>
        <Stack align="stretch">
          {messageTransmitter.editableMessage && !messageTransmitter.replyMessage && (
            <MessageDrawer
              label={t('shared-content.edit-message')}
              beforeIcon={<SvgIcon type="pen" />}
              message={messageTransmitter.editableMessage}
              onCancel={messageTransmitter.clearEditableMessage}
            />
          )}
          {messageTransmitter.replyMessage && (
            <MessageDrawer
              label={messageTransmitter.replyMessage.authorName}
              beforeIcon={<SvgIcon type="reply" />}
              message={messageTransmitter.replyMessage}
              onCancel={messageTransmitter.clearReplyMessage}
            />
          )}
          <CancelRecording />
          <Controller
            name="message"
            control={control}
            render={({ field }) => {
              return (
                <RichTextEditor
                  slotBefore={<EmojiPicker onSelect={onSelectEmoji} />}
                  slotAfter={
                    <Upload
                      multiple
                      onChange={onChangeUpload}
                      showErrorNotification
                      maxFileSize={10}
                      accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf"
                    >
                      <SvgIcon type="paperclip" className={s.communityMessageFormPaperclip} />
                    </Upload>
                  }
                  editorRef={editorRef}
                  onChange={field.onChange}
                  content={field.value}
                  onSubmit={onSubmitByEnter}
                />
              );
            }}
          />
        </Stack>
      </EditorBox>
      {canShowSendButton ? (
        <SendButton form="community-message-form" type="submit" />
      ) : (
        <MediaRecordButtons />
      )}
    </motion.form>
  );
});

export const Form = mergeComponentsWithName('Form', _Form);
