import { observer } from '@shared/libs/mobx';

import { TypographyNew, TypographyNewProps } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

export interface MessageNicknameProps {
  nickname: string;
  color?: TypographyNewProps['colorSchema'];
  variant?: TypographyNewProps['variant'];
}

const _MessageNickname = observer((props: MessageNicknameProps) => {
  const { nickname, color = 'secondary-300', variant = 'body-1' } = props;

  return (
    <TypographyNew colorSchema={color} variant={variant} as="span">
      {nickname}
    </TypographyNew>
  );
});

export const MessageNickname = mergeComponentsWithName('MessageNickname', _MessageNickname);
