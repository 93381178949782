import { CSSProperties, ReactNode, RefObject, useDeferredValue } from 'react';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

export interface MessageListContainerProps {
  totalSize: () => number;
  children: ReactNode;
  innerRef?: RefObject<HTMLUListElement>;
}

const _MessageListContainer = observer((props: MessageListContainerProps) => {
  const { totalSize, children, innerRef } = props;

  const _totalSize = useDeferredValue(totalSize());

  const listContainerStyle: CSSProperties = {
    height: `${_totalSize}px`,
    width: '100%',
    position: 'relative',
    willChange: 'opacity, transform, height',
    overflowAnchor: 'none',
  };

  return (
    <ul ref={innerRef} style={listContainerStyle} id="message-list-container">
      {children}
    </ul>
  );
});

export const MessageListContainer = mergeComponentsWithName(
  'MessageListContainer',
  _MessageListContainer,
);
