import type { AxiosRequestConfig } from 'axios';

import { PATH_COLLECTION } from '@core/constants';
import type { UploadPrivateChatAttachmentsDto } from '@core/repositories';
import { createHTTPMediaService } from '@core/services/http';

import { createFormData } from '@shared/utils';

export class PrivateChatRepository {
  private readonly httpMedia = createHTTPMediaService(PATH_COLLECTION.MEDIA.ROOT);

  uploadChatAttachments = (
    dto: UploadPrivateChatAttachmentsDto,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress'],
  ) => {
    const { files, privateChatId, message, type } = dto;

    let payload = {
      message,
      privateChatId,
      type,
    };

    if (!payload.type) {
      delete payload.type;
    }

    const formData = createFormData({
      payload,
    });

    files.forEach((file, idx) => formData.append(idx.toString(), file));

    const path = PATH_COLLECTION.MEDIA.UPLOAD_PRIVATE_CHAT_ATTACHMENTS;

    return this.httpMedia.post<null, FormData>(path, formData, {
      config: {
        onUploadProgress,
      },
    });
  };
}

export const privateChatRepository = new PrivateChatRepository();
