import type { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import { cl } from '@shared/libs/classnames';

import { Typography } from '@shared/UI';
import { useClassName } from '@shared/UI/_hooks';

import type { ElementProps } from '@shared/types';

import './styles.scss';

export interface BaseInputContainerProps extends ElementProps<'div'> {
  children: ReactNode;

  id: string;

  label?: string;

  error?: string;
}

export const BaseInputContainer = observer(function BaseInputContainer(
  props: BaseInputContainerProps,
) {
  const { children, className, label, error, id } = props;

  const { rootClassName, appendClass } = useClassName('base-input-container');

  const rootClasses = cl(
    rootClassName,
    {
      [appendClass('--with-error')]: error,
    },
    className,
  );

  return (
    <div className={rootClasses}>
      <label className={appendClass('-label')} htmlFor={id}>
        <Typography className={appendClass('-text')} as="span" variant="body-medium-4">
          {label}
        </Typography>
        <Typography className={appendClass('-text')} as="span" variant="body-medium-4">
          {error}
        </Typography>
      </label>
      {children}
    </div>
  );
});
