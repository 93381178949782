import { ForgotPasswordLink, FormBox } from '../../_components';
import { Subtitle } from '../_components';
import { FormFields } from '@components';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import type { RegisterOptions, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { SubmitButton } from '@features/auth/_components/submit-button';
import { useVerifyOtp } from '@features/auth/_hooks';
import type { SignInByPhoneSchema } from '@features/auth/_schemas';
import { signInByPhoneSchema } from '@features/auth/_schemas';

import { Box } from '@shared/UI';

import { transformPhoneNumber } from '@shared/utils';

export const SignInFormByPhone = observer(function LoginFormByPhone() {
  const { t } = useTranslation();

  const { authStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const onVerifyOtp = useVerifyOtp();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    trigger,
    setValue,
  } = useForm<SignInByPhoneSchema>({
    resolver: zodResolver(signInByPhoneSchema),
    defaultValues: {
      ...authStore.defaultFormValues.signInByPhone,
      otp: '',
    },
  });

  const onSubmit: SubmitHandler<SignInByPhoneSchema> = async (dto) => {
    const { phone, code, otp } = dto;

    if (otp) {
      await onVerifyOtp({ phone: transformPhoneNumber(phone, code), otp });
      sendEvent({ event: AnalyticsEvents.LoginAccountTap });
    } else {
      await authStore.signInByPhone({ phone, code });
    }
  };

  const handleClickResend = async () => {
    try {
      const isValid = await trigger();
      if (isValid) {
        const { phone, code } = getValues();
        await authStore.signInByPhone({ phone, code });
        return;
      }
      return await Promise.reject();
    } catch (e) {
      return await Promise.reject();
    }
  };

  const { isOTPVisible } = authStore.defaultFormValues.signInByPhone;

  const handleChangePhone = () => {
    if (isOTPVisible) {
      authStore.setIsOTPVisible(false, 'signInByPhone');
      setValue('otp', '');
    }
  };

  const phoneRules: RegisterOptions = {
    onChange: handleChangePhone,
  };

  return (
    <FormBox
      onSubmit={handleSubmit(onSubmit)}
      title={t('shared-content.sign-in')}
      subTitle={<Subtitle />}
    >
      <FormFields.Phone
        namePhone="phone"
        nameCode="code"
        control={control}
        label="inputs.phone-select.label"
        error={errors.phone}
        rulesCode={phoneRules}
        rulesPhone={phoneRules}
      />

      {isOTPVisible && (
        <FormFields.OtpCode
          onClickResend={handleClickResend}
          name="otp"
          control={control}
          error={errors.otp}
        />
      )}

      <SubmitButton isOtpVisible={isOTPVisible} control={control} />
      <Box textAlign="center">
        <ForgotPasswordLink />
      </Box>
    </FormBox>
  );
});
