import { observer } from 'mobx-react-lite';

import { SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

type EyeSwitcherProps = {
  isHidden: boolean;
  onClick: () => void;
};

export const EyeSwitcher = observer(function EyeSwitcher(props: EyeSwitcherProps) {
  const { isHidden, onClick } = props;

  const iconType = isHidden ? 'eye-crossed-out' : 'eye';

  return (
    <button tabIndex={-1} onClick={onClick} type="button" className={s.eyeSwitcher}>
      <SvgIcon type={iconType} fill="red" className={s.eyeSwitcherIcon} />
    </button>
  );
});
