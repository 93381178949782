import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useRootStore } from '@core/store';

import type { UploadError, UploadedFile } from '@shared/UI';
import { Avatar, Upload } from '@shared/UI';

import s from './styles.module.scss';

export const ProfileAvatar = observer(function ProfileAvatar() {
  const { t } = useTranslation();

  const { userStore } = useRootStore();

  const handleError = (error: UploadError) => {
    if (error.type === 'maxFileSize') {
      toast.error(t('errors.big-file', { size: 2 }));
      return;
    }
  };

  const handleChange = async (files: UploadedFile[]) => {
    if (!files.length) return;
    const file = files[0];

    try {
      await userStore.uploadAvatar(file.originalFile, file.url);
      toast.success(t('my-account.personal-information.avatar-successfully-changed'));
    } catch (e) {}
  };

  return (
    <div className={s.profileAvatar}>
      <div>
        <Avatar size="xxl" src={userStore.avatar} />
      </div>
      <div>
        <Upload
          label={t('shared-content.upload-avatar')}
          accept="image/png, image/jpeg, image/jpg, image/gif"
          onError={handleError}
          onChange={handleChange}
        />

        <p className={s.profileAvatarDescription}>
          {t('my-account.personal-information.avatar-description')}
        </p>
      </div>
    </div>
  );
});
