import { useEffect, useMemo, useState } from 'react';

import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { Stream, StreamChat } from '@widgets';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { useRootStore } from '@core/store';

import { Tabs, TypographyNew } from '@shared/UI';

import { useMediaQuery } from '@shared/hooks';

import s from './styles.module.scss';

const StreamTabs = {
  Description: 'description',
  Chat: 'chat',
};

const tabsList = [
  { name: StreamTabs.Description, label: 'my-workspace.streams.tabs.description' },
  { name: StreamTabs.Chat, label: 'shared-content.chat' },
];

const Streams = observer(function Streams() {
  const [activeTab, setActiveTab] = useState(StreamTabs.Description);

  const { id } = useParams();

  const isMobile = useMediaQuery('(max-width: 1199px)');

  const { streamsStore, streamChatStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const getStream = async () => {
    if (id) {
      try {
        await streamsStore.getStream(+id);
      } catch (e) {}
    }
  };

  const connectToStreamChat = () => {
    if (id) {
      streamChatStore.connectToStreamChat(id);
    }
  };

  useEffect(() => {
    getStream();
    connectToStreamChat();

    return () => streamsStore.disposer();
  }, [id]);

  useEffect(() => {
    sendEvent({
      event: AnalyticsEvents.StreamPageView,
      payload: { stream_id: streamsStore.stream?.id, stream_name: streamsStore.stream?.title },
    });
  }, []);

  const tabsLayout = useMemo(() => {
    if (activeTab === StreamTabs.Description) {
      return (
        <TypographyNew variant="body-2" colorSchema="neutral-300">
          {streamsStore.stream?.description}
        </TypographyNew>
      );
    }

    if (activeTab === StreamTabs.Chat) {
      return <StreamChat />;
    }

    return null;
  }, [activeTab, streamsStore.stream?.description]);

  return (
    <div className={s.stream}>
      {streamsStore.stream && (
        <Stream
          youtubeLink={streamsStore.stream.youtubeLink}
          title={streamsStore.stream.title}
          description={streamsStore.stream.description}
          banner={streamsStore.banner}
        />
      )}
      {isMobile && <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabsList} />}
      {isMobile ? tabsLayout : <StreamChat />}
    </div>
  );
});

export default Streams;
