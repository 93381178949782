import { observer } from 'mobx-react-lite';

import { TypographyNew } from '@shared/UI';

import { asHtml } from '@shared/utils';

import s from './styles.module.scss';

export interface BaseCollapseLabelProps {
  title: string;
  subtitle: string;
}

export const BaseAccordionLabel = observer(function BaseCollapseLabel(
  props: BaseCollapseLabelProps,
) {
  const { title, subtitle } = props;

  return (
    <div className={s.baseCollapseLabel}>
      <div>
        <TypographyNew
          as="p"
          variant="body-1"
          colorSchema="neutral-400"
          className={s.baseCollapseLabelTitle}
        >
          {title}
        </TypographyNew>
      </div>
      <div>
        <TypographyNew
          as="p"
          variant="title-4"
          weight="bold"
          className={s.baseCollapseLabelSubtitle}
        >
          {asHtml(subtitle)}
        </TypographyNew>
      </div>
    </div>
  );
});
