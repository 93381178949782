import { useCommunityMessageContextMenuController } from './community-message-context-menu.controller';
import { QuickEmoji } from '@features-new';
import { MessageTransmitter } from '@units';
import { useTranslation } from 'react-i18next';

import { MessageStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { ContextMenuNew, Stack, SvgIcon } from '@shared/UI';

import { Nullable } from '@shared/types';
import { mergeComponentsWithName } from '@shared/utils';

export interface CommunityMessageContextMenuProps {
  message: MessageStore;

  root?: Nullable<HTMLElement>;

  messageTransmitter: MessageTransmitter;
}

const _CommunityMessageContextMenu = observer((props: CommunityMessageContextMenuProps) => {
  const { message, root, messageTransmitter } = props;

  const { t } = useTranslation();

  const {
    messagePermissions,
    onClickDelete,
    onClickPin,
    onClickReply,
    onEditMessage,
    onSelectQuickEmoji,
    onConnectToPrivateChat,
  } = useCommunityMessageContextMenuController(message, messageTransmitter);

  return (
    <ContextMenuNew.Menu root={root}>
      <Stack w="100%" align="baseline" gap="xs">
        <QuickEmoji onSelectEmoji={onSelectQuickEmoji} />
        <ContextMenuNew.Content>
          <ContextMenuNew.Item icon={<SvgIcon type="reply" />} onClick={onClickReply}>
            {t('shared-content.reply')}
          </ContextMenuNew.Item>
          {messagePermissions.canPin && (
            <ContextMenuNew.Item icon={<SvgIcon type="pin" />} onClick={onClickPin}>
              {message.isPinned ? t('shared-content.unpin') : t('shared-content.pin')}
            </ContextMenuNew.Item>
          )}
          {!message.isMessageFromViewer && (
            <ContextMenuNew.Item
              icon={<SvgIcon type="reply" transform="scale(-1 1)" />}
              onClick={onConnectToPrivateChat}
            >
              {t('shared-content.send-message')}
            </ContextMenuNew.Item>
          )}
          {messagePermissions.canShowEdit && (
            <ContextMenuNew.Item icon={<SvgIcon type="edit-message" />} onClick={onEditMessage}>
              {t('shared-content.edit-message')}
            </ContextMenuNew.Item>
          )}
          {messagePermissions.canDelete && (
            <ContextMenuNew.Item
              color="red"
              icon={<SvgIcon type="rubbish-bin" />}
              onClick={onClickDelete}
            >
              {t('modals.delete-communication-item.message.title')}
            </ContextMenuNew.Item>
          )}
        </ContextMenuNew.Content>
      </Stack>
    </ContextMenuNew.Menu>
  );
});

export const CommunityMessageContextMenu = mergeComponentsWithName(
  'CommunityMessageContextMenu',
  _CommunityMessageContextMenu,
);
