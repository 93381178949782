import { PATH_COLLECTION } from '@core/constants';
import { createHTTPService } from '@core/services/http';

import type { UpdateInterestsDto } from './dto';
import type { UpdateInterestsResponse } from './responses';

export class OnBoardRepository {
  private readonly http = createHTTPService(PATH_COLLECTION.ON_BOARD.ROOT);

  updateInterests = async (dto: UpdateInterestsDto) => {
    const path = PATH_COLLECTION.ON_BOARD.UPD_INTERESTS;

    try {
      const { data } = await this.http.put<UpdateInterestsResponse>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };
}
