import { type MotionProps } from 'framer-motion';

import type { Target, TargetAndTransition, Transition } from '@shared/libs/framer-motion';

export const initialAnimationState: Target = {
  height: 0,
  // opacity: 0,
};

export const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

export const animateState: TargetAndTransition = {
  height: 'auto',
  // opacity: 1,
};
export const exitState: TargetAndTransition = {
  height: 0,
  // opacity: 0,
};
export const transitionState: Transition = { duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] };

export const animationConfig: MotionProps = {
  initial: initialAnimationState,
  animate: animateState,
  exit: exitState,
  transition: transitionState,
};
