import {
  convertSecondsToMinutesAndSecondsString,
  formatTimeCodeIndexToStringWithZero,
} from './video-timecodes.utils';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import type { CoursePreviewLessonBlockEntity } from '@core/repositories';
import type { VideoTimecodeEntity } from '@core/repositories/_entities';

import { TypographyNew } from '@shared/UI';

import { removeHtmlElementsFromString } from '@shared/utils';

import s from './styles.module.scss';

interface VideoTimecodeItemProps {
  title: string;
  time: string;
  index: number;
}

const VideoTimecodesItem = observer(function VideoTimecodesItem(props: VideoTimecodeItemProps) {
  const { title, time, index } = props;

  return (
    <>
      <TypographyNew
        as="span"
        variant="body-2"
        colorSchema="neutral-200"
        className={s.timecodeNumber}
      >
        {formatTimeCodeIndexToStringWithZero(index + 1)}
      </TypographyNew>
      <TypographyNew
        as="span"
        variant="body-2"
        colorSchema="neutral-200"
        className={s.timecodeContent}
      >
        {removeHtmlElementsFromString(title)}
      </TypographyNew>
      <TypographyNew
        as="span"
        variant="body-2"
        colorSchema="neutral-200"
        className={s.timecodeTime}
      >
        {time}
      </TypographyNew>
    </>
  );
});

interface VideoTimecodesProps {
  videoTimecodes?: VideoTimecodeEntity[];
  lessons?: CoursePreviewLessonBlockEntity[];
  onTimecodeClick?: (timecode?: VideoTimecodeEntity) => void;
  activeTimecodeId?: number;
}

export const VideoTimecodes = observer(function VideoTimecodes(props: VideoTimecodesProps) {
  const { videoTimecodes = [], lessons = [], onTimecodeClick, activeTimecodeId } = props;

  const setTimecodeClassName = (timecode: VideoTimecodeEntity) => {
    const { isWatched, isActive } = timecode;

    if (isActive) {
      if (activeTimecodeId === timecode.id) {
        return `${s.timecodeActive} ${s.timecodeCompletedActive}`;
      }

      return s.timecodeActive;
    }

    if (isWatched) {
      if (activeTimecodeId === timecode.id) {
        return `${s.timecodeCompleted} ${s.timecodeCompletedActive}`;
      }

      return s.timecodeCompleted;
    }

    return '';
  };

  return (
    <ul>
      {videoTimecodes.length
        ? videoTimecodes.map((timecodeItem, index) => {
            return (
              <li
                className={cl(
                  s.timecode,
                  videoTimecodes.length === 1 ? s.timecodeWithoutLine : '',
                  setTimecodeClassName(timecodeItem),
                )}
                key={timecodeItem.id}
              >
                <button
                  className={s.timecodeContainer}
                  onClick={() => onTimecodeClick?.(timecodeItem)}
                >
                  <VideoTimecodesItem
                    title={timecodeItem.title}
                    time={convertSecondsToMinutesAndSecondsString(timecodeItem.time)}
                    index={index}
                  />
                </button>
              </li>
            );
          })
        : lessons.map((lessonItem, index) => {
            return (
              <li
                className={cl(s.timecode, lessons.length === 1 ? s.timecodeWithoutLine : '')}
                key={`${lessonItem.title}-${lessonItem.position}`}
              >
                <button
                  className={s.timecodeContainer}
                  // onClick={() => onTimecodeClick?.(lessonItem.id)}
                >
                  <VideoTimecodesItem
                    title={lessonItem.title}
                    time={lessonItem.duration}
                    index={index}
                  />
                </button>
              </li>
            );
          })}
    </ul>
  );
});
