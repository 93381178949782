import { DEFAULT_LANGUAGE } from './constants';
import translationEN from './locales/en/translation.json';
import translationUA from './locales/ua/translation.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const defaultNS = 'translation' as const;
export const resources = {
  ua: {
    translation: translationUA,
  },
  en: {
    translation: translationEN,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: DEFAULT_LANGUAGE,
  fallbackLng: 'ua',
  ns: ['translation'],
  defaultNS,
  resources,
  debug: import.meta.env.MODE === 'development',
});

export default i18n;
