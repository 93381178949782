import { useCallback, useMemo } from 'react';
import type { CSSProperties, HTMLAttributes, HTMLProps, RefObject } from 'react';

import { FloatingPortal } from '@floating-ui/react';
import type { ReferenceType } from '@floating-ui/react';
import cl from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import type { Variants } from 'framer-motion';
import { observer } from 'mobx-react-lite';

import { For, Spinner } from '@shared/UI';
import { useClassName } from '@shared/UI/_hooks';

import { Option } from './option';
import type { SelectOption } from './option';

import './styles.scss';

export type MenuProps<T extends SelectOption> = {
  isOpen: boolean;
  setFloating: (node: HTMLElement | null) => void;
  floatingStyles: CSSProperties;
  getFloatingProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
  options: T[];
  parentRef: RefObject<ReferenceType>;
  selectedOption: T | undefined;
  onClickOption: (option: T) => void;
  isLoading?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const animateVariants: Variants = {
  visible: {
    maxHeight: 300,
    opacity: 1,
    padding: '8px 0',
    overflowY: 'auto',
  },
  hidden: {
    maxHeight: 0,
    opacity: 0,
    padding: '0 0',
    overflowY: 'clip',
  },
};

const exit = {
  maxHeight: 0,
  opacity: 0,
  padding: 0,
};

export const Menu = observer(function Menu<T extends SelectOption>(props: MenuProps<T>) {
  const {
    isOpen,
    floatingStyles,
    style,
    parentRef,
    options,
    className,
    selectedOption,
    setFloating,
    getFloatingProps,
    onClickOption,
    isLoading,
    ...restProps
  } = props;

  const { rootClassName, appendClass } = useClassName('select-menu');

  const styles = useMemo(
    () => ({
      ...style,
      ...floatingStyles,
      width: parentRef.current?.getBoundingClientRect().width,
    }),
    [floatingStyles, style],
  );

  const renderOptions = useCallback(
    (option: T) => {
      return (
        <Option
          key={option.value}
          onClick={() => onClickOption(option)}
          isSelected={selectedOption?.value === option.value}
          {...option}
        />
      );
    },
    [onClickOption, selectedOption, isLoading, options],
  );

  const rootClasses = cl(
    rootClassName,
    {
      [appendClass('--hidden')]: !isOpen,
      [appendClass('--loading')]: isLoading,
    },
    className,
  );

  const listClasses = cl(appendClass('-body'), {
    [appendClass('-body--hidden')]: isOpen,
    [appendClass('-body--visible')]: !isOpen,
  });

  const animate = isOpen ? 'visible' : ' hidden';

  return (
    <AnimatePresence>
      {isOpen && (
        <FloatingPortal>
          <div
            style={styles}
            ref={setFloating}
            className={rootClasses}
            {...getFloatingProps()}
            {...restProps}
          >
            <motion.ul
              className={listClasses}
              initial="hidden"
              animate={animate}
              variants={animateVariants}
              exit={exit}
            >
              {isLoading ? (
                <li>
                  <Spinner />
                </li>
              ) : (
                <For data={options} render={renderOptions} />
              )}
            </motion.ul>
          </div>
        </FloatingPortal>
      )}
    </AnimatePresence>
  );
});
