import type { ReactNode } from 'react';

import { messageTextParser } from '../../libs';
import { rootAnimate, rootInitial } from './message-drawer.animation';
import { motion, useAnimate } from 'framer-motion';

import type { MessageStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Group, Stack, SvgIcon, TypographyNew } from '@shared/UI';
import { ButtonNew } from '@shared/UI/button-new';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface MessageDrawerProps {
  message: MessageStore;
  label: string;
  onCancel?: () => void;
  beforeIcon?: ReactNode;
}

const _MessageDrawer = observer((props: MessageDrawerProps) => {
  const { message, beforeIcon, label, onCancel } = props;

  const [scope, animate] = useAnimate();

  const onHandleCancel = async () => {
    await animate(scope.current, {
      opacity: 0,
      maxHeight: 0,
    });

    onCancel?.();
  };

  return (
    <motion.div ref={scope} className={s.messageDrawer} initial={rootInitial} animate={rootAnimate}>
      <Group wrap="nowrap" justify="center">
        {beforeIcon && (
          <TypographyNew
            className={s.messageDrawerBeforeIcon}
            as="span"
            colorSchema="secondary-500"
          >
            {beforeIcon}
          </TypographyNew>
        )}
        <Stack
          className={s.messageDrawerContentWrapper}
          gap="xs-2"
          align="stretch"
          justify="center"
        >
          <TypographyNew as="span" variant="body-3" colorSchema="secondary-500">
            {label}
          </TypographyNew>
          <TypographyNew variant="body-3" lineClamp={1} as="div">
            {messageTextParser(message.message)}
          </TypographyNew>
        </Stack>
        <ButtonNew
          className={s.messageDrawerBtn}
          onClick={onHandleCancel}
          variant="ghost"
          size="icon"
        >
          <SvgIcon type="cross" />
        </ButtonNew>
      </Group>
    </motion.div>
  );
});

export const MessageDrawer = mergeComponentsWithName('MessageDrawer', _MessageDrawer);
