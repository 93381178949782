import { makeAutoObservable, onBecomeObserved, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import { BlockRepository, OnBoardRepository } from '@core/repositories';
import type { OnBoardBlockEntity, UpdateInterestsDto } from '@core/repositories';
import { getHttpError } from '@core/services/http';
import type { UserStore } from '@core/store/user';

type OnBoardingSteps = 'name' | 'interests';

export class OnBoardingStore {
  currentStep: OnBoardingSteps = 'name';

  isCompleted = false;

  isLoading = false;

  blocks: OnBoardBlockEntity[] = [];

  blockRepository = new BlockRepository();

  onBoardRepository = new OnBoardRepository();

  constructor(private userStore: UserStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    onBecomeObserved(this, 'blocks', this.getOnBoardBlocks);
  }

  getOnBoardBlocks = async () => {
    try {
      const data = await this.blockRepository.getOnBoardBlocks();

      runInAction(() => {
        this.blocks = data.block;
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    }
  };

  updateInterests = async (interests: UpdateInterestsDto) => {
    this.isLoading = true;
    try {
      const data = await this.onBoardRepository.updateInterests(interests);

      const checkedInterests = this.blocks.filter((item) => interests.onboardIds.includes(item.id));

      runInAction(() => {
        this.userStore.setOnboards(checkedInterests);

        if (!this.isCompleted) {
          this.completeOnBoarding();
        }
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  completeFirstStep = async (name: string) => {
    this.isLoading = true;
    try {
      const data = await this.userStore.patchMe({ name });
      this.setCurrentStep('interests');

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw err;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  completeOnBoarding = () => {
    this.isCompleted = true;
  };

  setIsCompleted = (value: boolean) => {
    this.isCompleted = value;
  };

  setCurrentStep = (value: OnBoardingSteps) => {
    this.currentStep = value;
  };

  isCheckedInterest = (interestId: number) => {
    return this.userStore.onboards.some((item) => item.id === interestId);
  };

  disposer = () => {
    this.currentStep = 'name';
    this.isCompleted = false;
    this.isLoading = false;
    this.blocks = [];
  };
}
