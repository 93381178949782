import { Children } from 'react';
import type { CSSProperties, HTMLAttributes, ReactNode } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import { useClassName } from '@shared/UI/_hooks';

import './styles.scss';

type DividerProps = {
  children?: ReactNode;

  orientation?: 'left' | 'center' | 'right';

  verticalSpace?: number | [number, number];
} & HTMLAttributes<HTMLDivElement>;

export const Divider = observer(function Divider(props: DividerProps) {
  const {
    children,
    style,
    orientation = 'center',
    className,
    role = 'separator',
    verticalSpace = 25,
    ...restProps
  } = props;

  const { rootClassName, appendClass } = useClassName('divider');

  const withChildren = Children.count(children) > 0;

  const rootClasses = cl(
    rootClassName,
    {
      [appendClass('-with-text')]: withChildren,
      [appendClass(`-with-text-${orientation}`)]: withChildren,
    },
    className,
  );

  const defaultStyles: CSSProperties = {
    margin: Array.isArray(verticalSpace)
      ? `${verticalSpace[0]}px 0 ${verticalSpace[1]}px 0`
      : `${verticalSpace}px 0`,
    ...style,
  };

  return (
    <div className={rootClasses} role={role} style={defaultStyles} {...restProps}>
      {withChildren && <span className={appendClass('-inner-text')}>{children}</span>}
    </div>
  );
});
