import type { ElementType, ReactNode } from 'react';

import cl from 'classnames';

import { observer } from '@shared/libs/mobx';

import type { StackProps, TypographyProps } from '@shared/UI';
import { Box, Group, Stack, Typography } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface ContainerWithTitleProps<Element extends ElementType = 'h2'> extends StackProps {
  label?: ReactNode;
  labelElement?: TypographyProps<Element>['as'];
  labelVariant?: TypographyProps['variant'];
  labelClassName?: string;
  contentClassName?: string;
  wrapperLabelClassName?: string;
  children: ReactNode;
  customLabel?: ReactNode | (() => ReactNode);
  labelIcon?: ReactNode;
}

const _ContainerWithTitle = observer(
  <Element extends ElementType>(props: ContainerWithTitleProps<Element>) => {
    const {
      label,
      children,
      gap = 'xxl',
      align = 'flex-start',
      labelElement = 'h2',
      labelVariant = 'title-bold-2',
      labelClassName,
      contentClassName,
      customLabel,
      labelIcon,
      wrapperLabelClassName,
      ...restProps
    } = props;

    return (
      <Stack align={align} gap={gap} className={s.containerWithTitle} {...restProps}>
        <Box w="100%" className={s.containerWithTitleBoxTitle}>
          {customLabel ? (
            typeof customLabel === 'function' ? (
              customLabel()
            ) : (
              customLabel
            )
          ) : (
            <Group justify="space-between" className={wrapperLabelClassName}>
              <Typography
                className={labelClassName}
                as={labelElement as ElementType}
                variant={labelVariant}
              >
                {label}
              </Typography>
              {labelIcon}
            </Group>
          )}
        </Box>
        <Box className={cl(s.containerWithTitleBoxContent, contentClassName)} w="100%">
          {children}
        </Box>
      </Stack>
    );
  },
);

export const ContainerWithTitle = mergeComponentsWithName(
  'ContainerWithTitle',
  _ContainerWithTitle,
);
