import { LoadingStore, MessageStore } from '../../common';
import type { UserStore } from '../../user';
import type { MessageCounterStore } from '../message-counter';
import type { CommunityChatsStore } from './chats.store';
import { MentionVariants } from '@constants';
import debounce from 'lodash.debounce';
import { runInAction } from 'mobx';

import { LoadMessageDirection, SocketEventsCollection } from '@core/constants';
import type {
  CommunityMentionEntity,
  CommunityMessageEntity,
  CommunityMessageReactionDto,
  CommunityMessageReactionEntity,
  ConnectToChatDto,
  ConnectToChatPaginationEntity,
  ConnectToChatResponse,
  ReceiveNewMessageResponse,
  ReceiveUnreadCommunication,
  RemoveMessageResponse,
  SearchUserCommunicationDto,
  SearchUserInCommunicationResponse,
  SendMessageDto,
  ViewAllMessagesResponse,
} from '@core/repositories';
import { appSocket } from '@core/services/socket';

import { makeAutoObservable } from '@shared/libs/mobx';

import type { ObjectValues } from '@shared/types';
import { mapToArray, withMediaUrl } from '@shared/utils';

type MessagesHashMap = Map<string, MessageStore>;

interface ChatBucket {
  pagination: ConnectToChatPaginationEntity;
  loader: LoadingStore;
  messages: MessagesHashMap;
}

export class CommunityMessagesStore {
  /**
   * Key: chatId
   * Value: ChatBucket
   */
  private _chatBuckets: Map<string, ChatBucket> = new Map();

  private needToViewMessages: Set<string> = new Set();

  private viewMessages = debounce(() => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.ViewMessage, {
      messageIds: Array.from(this.needToViewMessages, (id) => id),
    });

    this.needToViewMessages = new Set();
  }, 300);

  private _isPinnedMessagesVisible = false;

  mentions: CommunityMentionEntity[] = [];

  constructor(
    private userStore: UserStore,
    private chatsStore: CommunityChatsStore,
    private messageCounter: MessageCounterStore,
  ) {
    // this.initListeners();
    // this.initSubscribers();

    makeAutoObservable(this, {}, { autoBind: true });
  }

  //#region --- Getters

  get activeChatBucket() {
    if (!this.chatsStore.activeChat) return null;
    return this._chatBuckets.get(this.chatsStore.activeChat.id) ?? null;
  }

  get activeChatMessages() {
    if (!this.chatsStore.activeChat) return [];
    const collection = this._chatBuckets.get(this.chatsStore.activeChat.id);
    return collection ? Array.from(collection.messages, ([, msg]) => msg) : [];
  }

  get isPinnedMessagesVisible() {
    return this._isPinnedMessagesVisible;
  }

  get activeChatPagination() {
    return this.activeChatBucket ? this.activeChatBucket.pagination : null;
  }

  //#endregion --- Getters

  //#region --- Actions

  removeMessageEmitter = (messageId: string) => {
    const bucket = this.activeChatBucket;
    if (bucket) {
      const message = bucket.messages.get(messageId);
      if (message) {
        appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.RemoveMessage, {
          messageId,
          communicationItemId: message.chatId,
        });
        bucket.messages.delete(message.id);
      }
    }
  };

  pinMessageEmitter = (messageId: string) => {
    const collection = this.activeChatBucket;
    if (collection) {
      const message = collection.messages.get(messageId);

      if (message) {
        appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.TogglePinningMessage, {
          messageId,
          communicationItemId: message.chatId,
        });
        message.isPinned = !message.isPinned;

        if (this.isPinnedMessagesVisible) {
          collection.messages.delete(messageId);
        }
      }
    }
  };

  getPinnedMessagesEmitter = (communicationItemId: string) => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.GetPinnedMessages, {
      communicationItemId,
    });
  };

  toggleIsPinnedMessagesVisible = () => {
    return (this._isPinnedMessagesVisible = !this._isPinnedMessagesVisible);
  };

  awaitedLoadMessages = async (
    triggerMessage: MessageStore,
    direction: ObjectValues<typeof LoadMessageDirection>,
  ) => {
    if (!this.chatsStore.activeChat) return;

    const chatBucket = this.activeChatBucket;

    if (this.canLoadNewMessages(chatBucket)) return;

    try {
      const response = await appSocket.awaitedDispatch(
        SocketEventsCollection.CommunityEvents.Emitters.GetMessages,
        SocketEventsCollection.CommunityEvents.Subscribers.GetMessages,
        {
          direction,
          communicationItemId: triggerMessage.chatId,
          paginationMessageId: triggerMessage.id,
          limit: 20,
          skip: 0,
        },
      );

      const prevMessages = Array.from(chatBucket.messages, (collection) => collection[1]);

      if (response.pagination.direction === LoadMessageDirection.Up) {
        const reversedMessages = [...response.messages].reverse();
        const newMessages = this.createMessageStoresArray(reversedMessages);
        const messagesMap: MessagesHashMap = new Map();

        newMessages.forEach((msg) => messagesMap.set(msg.id, msg));
        prevMessages.forEach((msg) => messagesMap.set(msg.id, msg));

        runInAction(() => {
          chatBucket.messages = messagesMap;
          chatBucket.pagination.firstMessagePresent = response.pagination.firstMessagePresent;
        });

        return;
      }

      if (response.pagination.direction === LoadMessageDirection.Down) {
        const newMessages = this.createMessageStoresArray(response.messages);
        const messagesMap: MessagesHashMap = new Map();

        prevMessages.forEach((msg) => messagesMap.set(msg.id, msg));
        newMessages.forEach((msg) => messagesMap.set(msg.id, msg));

        runInAction(() => {
          chatBucket.messages = messagesMap;
          chatBucket.pagination.lastMessagePresent = response.pagination.lastMessagePresent;
        });
      }
    } catch (error) {}
  };

  sendMessageEmitter = (message: string, chatId: string, replyMessageId?: string) => {
    let dto: SendMessageDto = {
      message,
      communicationItemId: chatId,
    };
    if (replyMessageId) {
      dto['repliedOnMessageId'] = replyMessageId;
    }
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.SendMessage, dto);
  };

  editMessageEmitter = (details: {
    chatId: string;
    messageId: string;
    attachments: string[];
    messageText: string;
  }) => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.EditMessage, {
      messageId: details.messageId,
      message: details.messageText,
      communicationItemId: details.chatId,
      attachments: details.attachments,
    });
  };

  searchUserInChatEmitter = (details: SearchUserCommunicationDto) => {
    appSocket.dispatch(
      SocketEventsCollection.CommunityEvents.Emitters.SearchUserCommunication,
      details,
    );
  };

  readAllMessagesEmitter = (chatId: string) => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.ViewAllMessages, {
      communicationItemId: chatId,
    });
  };

  awaitedReadAllMessagesEmitter = async (chatId: string) => {
    const bucket = this.getChatBucketById(chatId);

    try {
      const { communicationItemId, messages } = await appSocket.awaitedDispatch(
        SocketEventsCollection.CommunityEvents.Emitters.ViewAllMessages,
        SocketEventsCollection.CommunityEvents.Subscribers.ViewAllMessages,
        {
          communicationItemId: chatId,
        },
      );

      const lastNewMessage = messages[messages.length - 1];
      const isExistOnBucket = bucket?.messages.has(lastNewMessage.id);

      runInAction(() => {
        this.messageCounter.getChat(communicationItemId)?.resetCount();

        if (!isExistOnBucket) {
          this.replaceMessagesByChatId(communicationItemId, messages);
        }
        if (bucket) {
          bucket.pagination.lastMessagePresent = true;
        }
      });
    } catch (error) {}
  };

  toggleEmojiEmitter = (details: CommunityMessageReactionDto) => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.ToggleEmoji, details);
  };

  private viewMessagesTrigger = (messageId: string, chatId: string) => {
    this.needToViewMessages.add(messageId);
    this.viewMessages();
    this.messageCounter.getChat(chatId)?.decreaseCount();
    this.chatsStore.readMessageInChat(chatId);
  };

  receiveViewerMessage = (callback?: (message: MessageStore) => void) => {
    const fn = (newMessage: CommunityMessageEntity) => {
      callback?.(this.createMessageStore(newMessage));
    };
    return appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.GetNewMessage,
      fn,
    );
  };

  receiveTemplateMessage = (callback?: (message: MessageStore) => void) => {
    return appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ReceiveTemplateMsg,
      (newMessage) => {
        callback?.(this.createMessageStore(newMessage));
      },
    );
  };

  receiveReadAllMessages = (callback?: (details: ViewAllMessagesResponse) => void) => {
    const fn = (details: ViewAllMessagesResponse) => {
      callback?.(details);
    };
    return appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ViewAllMessages,
      fn,
    );
  };

  //#endregion --- Actions

  //#region --- Helpers

  public getChatBucketById = (chatId: string) => {
    return this._chatBuckets.get(chatId);
  };

  public getMessagesByChatId = (chatId: string) => {
    const bucket = this.getChatBucketById(chatId);
    if (!bucket) return [];

    return mapToArray(bucket.messages);
  };

  public replaceMessagesByChatId = (chatId: string, messages: CommunityMessageEntity[]) => {
    const bucket = this.getChatBucketById(chatId);

    if (!bucket) return;

    const newMessages = this.createMessageStoresArray(messages);

    runInAction(() => {
      bucket.messages = new Map(newMessages.map((msg) => [msg.id, msg]));
    });
  };

  private canLoadNewMessages = (chatBucket: ChatBucket | null): chatBucket is null => {
    return (
      !chatBucket ||
      (chatBucket.pagination.lastMessagePresent && chatBucket.pagination.firstMessagePresent)
    );
  };

  private createMessageStoresArray = (messages: CommunityMessageEntity[]) => {
    return messages.map((message) => this.createMessageStore(message));
  };

  private createMessageStore = (message: CommunityMessageEntity) => {
    const { communicationItemId, user, ...restMessage } = message;
    return new MessageStore(
      {
        ...restMessage,
        ownerImage: withMediaUrl(user.image, true),
        chatId: communicationItemId,
        isMessageFromViewer: this.userStore.user.id === message.authorId,
        viewerIds: [],
      },
      this.viewMessagesTrigger,
      this.editMessageEmitter,
    );
  };

  //#endregion --- Helpers

  //#region --- Subscribers

  private connectToChatSubscriber = (details: ConnectToChatResponse) => {
    const { communicationItemId, pagination, messages } = details;

    const existChat = this._chatBuckets.get(communicationItemId);

    const _messages = this.createMessageStoresArray(messages).reduce(
      (acc: MessagesHashMap, msg) => {
        acc.set(msg.id, msg);
        return acc;
      },
      new Map(),
    );

    runInAction(() => {
      if (!existChat) {
        const chat: ChatBucket = {
          pagination,
          loader: new LoadingStore(),
          messages: _messages,
        };

        chat.loader.setLoaded(true);

        this._chatBuckets.set(communicationItemId, chat);
      } else {
        if (existChat.loader.isLoading) {
          existChat.loader.completeLoading();
        }

        existChat.pagination = pagination;
        existChat.messages = _messages;
      }

      this._isPinnedMessagesVisible = false;
    });
  };

  private getNewMessageSubscriber = (newMessage: ReceiveNewMessageResponse) => {
    const chatBucket = this.getChatBucketById(newMessage.communicationItemId);

    if (!chatBucket) return;

    const { communicationItemId, ...restNewMessage } = newMessage;

    const message = {
      ...restNewMessage,
      chatId: communicationItemId,
      viewerIds: [],
      isMessageFromViewer: this.userStore.user.id === newMessage.authorId,
      ownerImage: withMediaUrl(newMessage.user.image),
    };

    if (chatBucket.pagination.lastMessagePresent || chatBucket.messages.size === 0) {
      chatBucket.messages.set(
        newMessage.id,
        new MessageStore(message, this.viewMessagesTrigger, this.editMessageEmitter),
      );
      if (!message.isViewed) {
        this.messageCounter.getChat(communicationItemId)?.increaseCount();
      }
    }
  };

  private getUserMentionsSubscriber = (mentions: SearchUserInCommunicationResponse) => {
    runInAction(() => {
      this.mentions = this.userStore.permissions.communityMessageMention.create
        ? [
            { id: MentionVariants.Here, image: '', name: MentionVariants.Here },
            { id: MentionVariants.Everyone, image: '', name: MentionVariants.Everyone },
            ...mentions.users,
          ]
        : mentions.users;
    });
  };

  private getMessageReactionsSubscriber = (reactions: CommunityMessageReactionEntity) => {
    const collection = this.activeChatBucket;

    if (collection) {
      const message = collection.messages.get(reactions.messageId);

      if (message) {
        runInAction(() => {
          message.reactions.reactions = reactions.reactions;
        });
      }
    }
  };

  private getPinnedMessagesSubscriber = (pinnedMessages: CommunityMessageEntity[]) => {
    const messages = this.createMessageStoresArray(pinnedMessages);

    if (!messages[0]) return;

    const chat = this.getChatBucketById(messages[0].chatId);

    if (!chat) return;

    runInAction(() => {
      chat.messages = messages.reverse().reduce((acc: MessagesHashMap, msg) => {
        acc.set(msg.id, msg);
        return acc;
      }, new Map());
    });
  };

  private updateViewCountSubscriber = (details: string[]) => {
    runInAction(() => {
      this.activeChatBucket?.messages.forEach((message) => {
        if (details.includes(message.id)) {
          message.viewCount += 1;
        }
      });
    });
  };

  private editMessageSubscriber = (details: ReceiveNewMessageResponse) => {
    this.activeChatMessages.forEach((message) => {
      if (message.id === details.id) {
        message.message = details.message;
        message.isEdited = details.isEdited;
      }
    });
  };

  private receiveUnreadCommunication = (details: ReceiveUnreadCommunication) => {
    this.messageCounter.getChat(details.communicationItemId)?.increaseCount();
  };

  private removeMessageSubscriber = (details: RemoveMessageResponse) => {
    const collection = this.activeChatBucket;

    if (collection) {
      runInAction(() => {
        collection.messages.delete(details.messageId);
      });
    }
  };

  //#endregion --- Subscribers

  private connectToChatListener = (details: ConnectToChatDto) => {
    const existChat = this._chatBuckets.get(details.communicationItemId);

    if (!existChat) {
      this._chatBuckets.set(details.communicationItemId, {
        loader: new LoadingStore(true),
        pagination: {} as ChatBucket['pagination'],
        messages: new Map(),
      });
    } else {
      existChat.loader.startLoading();
    }
  };

  private initSubscribers = () => {
    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.GetUnread,
      this.receiveUnreadCommunication,
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ConnectCommunication,
      this.connectToChatSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.GetNewMessage,
      this.getNewMessageSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.SearchUserCommunication,
      this.getUserMentionsSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ToggleEmoji,
      this.getMessageReactionsSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.GetPinnedMessages,
      this.getPinnedMessagesSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ReceiveTemplateMsg,
      this.getNewMessageSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ViewMessage,
      (details) => {
        const messageIds = details.map((message) => message.messageId);
        this.updateViewCountSubscriber(messageIds);
      },
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.EditMessages,
      this.editMessageSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.RemoveMessage,
      this.removeMessageSubscriber,
    );
  };

  private initListeners = () => {
    appSocket.subscribeDispatch(
      SocketEventsCollection.CommunityEvents.Emitters.ConnectCommunication,
      this.connectToChatListener,
    );
  };

  initEvents = () => {
    this.initListeners();
    this.initSubscribers();
  };

  disposer = () => {
    this._chatBuckets = new Map();
  };
}
