import { useMemo } from 'react';
import type { MouseEventHandler } from 'react';

import { RouterLink } from '@components';
import { routerCollection } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import type { TFuncKey } from 'react-i18next';

import { For, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

type NavigationTabType = {
  label: TFuncKey;
  to: string;
  onClick?: MouseEventHandler<HTMLLIElement>;
};

export const NavigationTabs = observer(function NavigationTabs() {
  const { t } = useTranslation();

  const sendEvent = useAnalyticsContext();

  const tabs: NavigationTabType[] = useMemo(
    () => [
      {
        label: 'my-workspace.courses.tab',
        to: routerCollection.myWorkspace.courses,
      },
      {
        label: 'my-workspace.homework.tab',
        to: routerCollection.myWorkspace.homeWork,
        onClick: () => sendEvent({ event: AnalyticsEvents.MyHomeworkTap }),
      },
    ],
    [],
  );

  const renderNavigationTabs = (tab: NavigationTabType) => {
    return (
      <li className={s.navigationTabsItem} onClick={tab.onClick}>
        <RouterLink
          to={tab.to}
          className={s.navigationTabsItemLink}
          classNameActive={s.navigationTabsItemLinkActive}
        >
          <TypographyNew as="span" variant="body-2">
            {t(tab.label) as string}
          </TypographyNew>
        </RouterLink>
      </li>
    );
  };

  return (
    <div className={s.navigationTabs}>
      <ul className={s.navigationTabsList}>
        <For data={tabs} render={renderNavigationTabs} />
      </ul>
    </div>
  );
});
