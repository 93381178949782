import { forwardRef } from 'react';
import type { InputHTMLAttributes, ReactNode } from 'react';

import { RadioGroup } from './radio-group';
import { useRadioGroupContext } from './radio-group.context';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { Typography } from '@shared/UI';
import { useClassName } from '@shared/UI/_hooks';

import { useId } from '@shared/hooks';
import { componentName } from '@shared/utils';

import './styles.scss';

export interface RadioProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'aria-invalid' | 'size'> {
  hasError?: boolean;
  label?: ReactNode;
  size?: 'sm' | 'md' | 'xl';
  indicator?: ReactNode;
  fullWidthLabel?: boolean;
}

const _Radio = observer(
  forwardRef<HTMLInputElement, RadioProps>(function Radio(props, ref) {
    const {
      className,
      hasError,
      label,
      onChange,
      id,
      size = 'md',
      name,
      indicator,
      disabled = false,
      checked,
      fullWidthLabel,
      ...restProps
    } = props;

    const { rootClassName, appendClass } = useClassName('radio');

    const ctx = useRadioGroupContext();

    const uuid = useId(id);

    const contextProps = ctx
      ? {
          checked: ctx.value === restProps.value,
          name: name ?? ctx.name,
          onChange: ctx.onChange,
          disabled: ctx.disabled,
        }
      : {
          disabled,
          checked,
        };

    const rootClasses = cl(
      rootClassName,
      appendClass(`--${size}`),
      {
        [appendClass('--with-error')]: hasError,
        [appendClass('--disabled')]: contextProps.disabled,
      },
      className,
    );

    return (
      <div
        className={rootClasses}
        data-disabled={contextProps.disabled}
        data-checked={contextProps.checked}
      >
        <label htmlFor={uuid} className={appendClass('-label')}>
          <input
            id={uuid}
            ref={ref}
            className={appendClass('-field')}
            type="radio"
            aria-invalid={hasError}
            data-checked={contextProps.checked}
            {...restProps}
            {...contextProps}
          />
          <span className={appendClass('-circle')}>
            {indicator ?? <span className={appendClass('-circle-indicator')} />}
          </span>
          <Typography
            as="p"
            variant="body-medium-2"
            className={cl(appendClass('-text'), {
              [appendClass('-full-width-text')]: fullWidthLabel,
            })}
          >
            {label}
          </Typography>
        </label>
      </div>
    );
  }),
);

export const Radio = Object.assign(_Radio, {
  ...componentName('Radio'),
  Group: RadioGroup,
});
