import { useEffect, useState } from 'react';

export const usePaginateStreamsLiveChat = (
  messagesListRef: React.MutableRefObject<HTMLElement | null>,
) => {
  const [isNeedToPaginate, setIsNeedToPaginate] = useState(false);

  const intersectionObserverCallback: IntersectionObserverCallback = (entries, observer) => {
    if (entries[0].intersectionRatio <= 0) return;

    setIsNeedToPaginate(true);
    observer.unobserve(entries[0].target);
  };

  const mutationObserverCallback: MutationCallback = (mutations) => {
    const addedMutations = mutations.filter((mutation) => mutation.addedNodes.length);
    if (addedMutations.length >= 50) {
      const paginationNode = addedMutations[30]?.addedNodes[0] as HTMLElement;

      if (paginationNode) {
        setIsNeedToPaginate(false);

        const intersectionObserver = new IntersectionObserver(intersectionObserverCallback);
        intersectionObserver.observe(paginationNode);
      }
    }
  };

  useEffect(() => {
    if (messagesListRef.current) {
      const observer = new MutationObserver(mutationObserverCallback);

      observer.observe(messagesListRef.current, { childList: true, subtree: true });
    }
  }, []);

  return isNeedToPaginate;
};
