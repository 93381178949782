import { useMemo } from 'react';

import { ModalPlayer } from './modal-player';
import { modalStore } from '@components';
import { messageTextParser } from '@units';

import type { MessageStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import type { YouTubeVideoProps } from '@shared/UI';
import { Box, PreviewLink, YouTubeVideo } from '@shared/UI';

import { isYouTubeVideo, mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface MessageTextProps {
  message: MessageStore;
}

const YOUTUBE_OPTIONS: YouTubeVideoProps['opts'] = {
  width: '100%',
  height: '100%',
  playerVars: {
    controls: 1,
    rel: 0,
    autoplay: 0,
    disablekb: 0,
    modestbranding: 1,
    fs: 0,
  },
};

const _MessageText = observer((props: MessageTextProps) => {
  const { message } = props;

  const handleClickYouTubeContainer = () => {
    if (message.previewLink) {
      modalStore.activateModal({
        component: <ModalPlayer url={message.previewLink} />,
        id: 'youtube-vide',
      });
    }
  };

  const component = useMemo(() => {
    if (message.previewLink) {
      if (isYouTubeVideo(message.previewLink)) {
        return (
          <Box
            className={s.messageTextYoutubePlug}
            role="button"
            tabIndex={-1}
            onClick={handleClickYouTubeContainer}
          >
            <YouTubeVideo
              className={s.messageTextYoutubePlugPlayer}
              iframeClassName={s.messageTextYoutubePlugPlayerIframe}
              opts={YOUTUBE_OPTIONS}
              url={message.previewLink}
            />
          </Box>
        );
      }
      return (
        <PreviewLink
          className={s.messagePreviewLink}
          showGraphic
          url={message.previewLink}
          proxyUrl={import.meta.env.VITE_PROXY_URL}
        />
      );
    }

    return null;
  }, [message.previewLink]);

  return (
    <>
      {messageTextParser(message.message)}
      {component && <Box py={[8, 4]}>{component}</Box>}
    </>
  );
});

export const MessageText = mergeComponentsWithName('MessageText', _MessageText);
