import { makeAutoObservable } from 'mobx';

export class LoadingStore {
  _isLoading = false;

  _isLoaded = false;

  constructor(defaultLoading?: boolean) {
    makeAutoObservable(this, {}, { autoBind: true });

    if (typeof defaultLoading === 'boolean') {
      this.setLoading(defaultLoading);
    }
  }

  get isLoading() {
    return this._isLoading;
  }

  get isLoaded() {
    return this._isLoaded;
  }

  setLoading = (value: boolean) => {
    this._isLoading = value;
  };

  setLoaded = (value: boolean) => {
    this._isLoaded = value;
  };

  startLoading = () => {
    this.setLoading(true);
  };

  stopLoading = () => {
    this.setLoading(false);
  };

  completeLoading = () => {
    this.setLoading(false);
    this.setLoaded(true);
  };
}
