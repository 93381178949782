import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { GroupProps } from '@shared/UI';
import { Group, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface MessageDateProps extends Omit<GroupProps, 'children'> {
  date: string;
}

const _MessageDate = observer((props: MessageDateProps) => {
  const { date, className, ...restProps } = props;

  const rootClasses = cl(s.messageDate, className);

  return (
    <Group className={rootClasses} {...restProps}>
      <TypographyNew as="span" variant="body-3">
        {date}
      </TypographyNew>
    </Group>
  );
});

export const MessageDate = mergeComponentsWithName('MessageDate', _MessageDate);
