import { SvgIcon } from '../../../svg-icon';
import { useRichEditorContext } from '../../rich-editor.context';
import { EditorMenuItem } from '../editor-menu-item';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

const _BtnStrikeThrough = observer(() => {
  const { editor } = useRichEditorContext();

  const handleClickBtn = () => editor().chain().focus().toggleStrike().run();

  const icon = <SvgIcon type="editor-strike-trough" />;

  return (
    <EditorMenuItem icon={icon} onClick={handleClickBtn} isActive={editor().isActive('strike')} />
  );
});

export const BtnStrikeThrough = mergeComponentsWithName('BtnStrikeThrough', _BtnStrikeThrough);
