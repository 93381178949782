import { useContext } from 'react';

import { AnalyticsContext } from './AnalyticsContext';

export const useAnalyticsContext = () => {
  const ctx = useContext(AnalyticsContext);

  if (!ctx) {
    throw new Error('AnalyticsContext must be inside a Provider with a value');
  }

  return ctx;
};
