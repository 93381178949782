import type { ReactNode } from 'react';

import { randomId } from '@shared/utils';

export function mapSkeleton(count = 1, callback: (randomId: string, index: number) => ReactNode) {
  return Array(count)
    .fill(0)
    .map((_, index) => {
      const key = randomId();
      return callback(key, index);
    });
}
