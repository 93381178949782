import { NavItem } from '@components';
import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { Each, SvgIcon } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _NavList = observer(() => {
  const { appLayoutStore, messageCounter } = useRootStore();

  const items = [
    {
      label: 'dashboard.title',
      to: routerCollection.dashboard.root,
      icon: <SvgIcon type="dashboard" />,
      withLoading: true,
    },
    {
      label: 'community.title',
      to: routerCollection.community.root,
      icon: <SvgIcon type="community" />,
      withLoading: true,
      badgeCounter: messageCounter.communityGlobalUnreadCount,
    },
    {
      label: 'my-workspace.title',
      to: routerCollection.myWorkspace.root,
      icon: <SvgIcon type="workspace" />,
      withLoading: true,
    },
    {
      label: 'community.messages.title',
      to: routerCollection.privateChats.root,
      icon: <SvgIcon type="messages" />,
      withLoading: true,
      badgeCounter: messageCounter.privateChats.totalCount,
    },
    {
      label: 'calendar.title',
      to: routerCollection.events.root,
      icon: <SvgIcon type="events" />,
      withLoading: true,
    },
    {
      label: 'my-account.title',
      to: routerCollection.myAccount.root,
      icon: <SvgIcon type="account" />,
      withLoading: true,
    },
  ];

  return (
    <Each data={items}>
      {(item) => (
        <NavItem
          key={item.to as string}
          labelClassName={s.sidebarListItemLabel}
          classNameActive={s.sidebarListItemLinkActive}
          className={s.sidebarListItemLink}
          onClick={appLayoutStore.handleChangeOpen}
          {...item}
        />
      )}
    </Each>
  );
});

export const NavList = mergeComponentsWithName('NavList', _NavList);
