import type { ReactNode } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { observer } from '@shared/libs/mobx';

import type { GroupProps } from '@shared/UI';
import { Avatar, Box, Group, Stack, Typography } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const messageVideoTile = cva(s.messageVideoTile, {
  variants: {
    owner: {
      viewer: s.messageVideoTileViewer,
      participant: s.messageVideoTileParticipant,
      other: s.messageVideoTileOther,
    },
  },

  defaultVariants: {
    owner: 'viewer',
  },
});

export interface MessageVideoTileProps
  extends Omit<GroupProps, 'children'>,
    VariantProps<typeof messageVideoTile> {
  hideAvatar?: boolean;

  avatar?: string;

  ownerName?: string;

  date: string;

  isViewed: boolean;

  viewedCount: number;

  footerSlot?: ReactNode | (() => ReactNode);

  children?: ReactNode;
}

const _MessageVideoTile = observer((props: MessageVideoTileProps) => {
  const {
    avatar,
    hideAvatar = false,
    ownerName,
    date,
    owner,
    className,
    isViewed,
    viewedCount,
    footerSlot,
    children,
    ...restProps
  } = props;

  const rootClasses = messageVideoTile({ owner, className });

  return (
    <Group align="flex-start" wrap="nowrap" className={rootClasses} {...restProps}>
      {!hideAvatar && <Avatar src={avatar} size="sm-2" />}
      <Box className={s.messageVideoTileWrapper}>
        <Stack gap="none" justify="flex-start" align="center" w="100%">
          <Group justify={!ownerName ? 'flex-end' : 'space-between'} w="100%">
            {ownerName && (
              <Typography className={s.messageVideoTileOwnerName} as="span" variant="body-medium-2">
                {ownerName}
              </Typography>
            )}
          </Group>
          <Box className={s.messageVideoTileContentWrapper}>{children}</Box>
          <Box w="100%">{typeof footerSlot === 'function' ? footerSlot() : footerSlot}</Box>
        </Stack>
      </Box>
    </Group>
  );
});

export const MessageVideoTile = mergeComponentsWithName('MessageVideoTile', _MessageVideoTile);
