import { useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { MyHomeworkResponse } from '@core/repositories';

import UnverifiedEmptyImage from '@assets/images/empty-content/need-verification.png';
import ToBeSentEmptyImage from '@assets/images/empty-content/to-be-sent.png';
import VerifiedEmptyImage from '@assets/images/empty-content/verified.png';

import { TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

export type EmptyContentProps = {
  blockKey: keyof MyHomeworkResponse;
};

export const EmptyContent = observer(function EmptyContent(props: EmptyContentProps) {
  const { blockKey } = props;

  const { t } = useTranslation();

  const emptyImageObject = useMemo(() => {
    switch (blockKey) {
      case 'toBeSent':
        return {
          src: ToBeSentEmptyImage,
          alt: 'to be sent empty image',
        };
      case 'unverified':
        return {
          src: UnverifiedEmptyImage,
          alt: 'unverified empty image',
        };
      case 'verified':
        return {
          src: VerifiedEmptyImage,
          alt: 'verified empty image',
        };
      default:
        return {
          src: '',
          alt: '',
        };
    }
  }, [blockKey]);

  const emptyTitle = useMemo(() => {
    switch (blockKey) {
      case 'toBeSent':
        return t('my-workspace.homework.dont-have-to-be-sent.title');
      case 'unverified':
        return t('my-workspace.homework.dont-have-need-verification.title');
      case 'verified':
        return t('my-workspace.homework.dont-have-verified.title');
      default:
        return '';
    }
  }, [blockKey, t]);

  const emptyDescription = useMemo(() => {
    switch (blockKey) {
      case 'toBeSent':
        return t('my-workspace.homework.dont-have-to-be-sent.description');
      case 'unverified':
        return t('my-workspace.homework.dont-have-need-verification.description');
      case 'verified':
        return t('my-workspace.homework.dont-have-verified.description');
      default:
        return '';
    }
  }, [blockKey, t]);

  return (
    <div className={s.emptyContent}>
      <div className={s.imageContainer}>
        <img src={emptyImageObject.src} alt="Empty data image" />
      </div>
      <div className={s.textContainer}>
        <TypographyNew as="h4" variant="title-4" weight="bold">
          {emptyTitle}
        </TypographyNew>
        <TypographyNew as="p" variant="body-2" colorSchema="neutral-200">
          {emptyDescription}
        </TypographyNew>
      </div>
    </div>
  );
});
