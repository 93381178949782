import type { ReactNode } from 'react';

import type { ActiveModal } from './_types';
import { makeAutoObservable, runInAction } from 'mobx';

import { randomUuid } from '@shared/utils';

export class ModalStore {
  activeModals: ActiveModal[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get activeModalComponent() {
    if (this.activeModals.length === 0) return null;

    return this.activeModals.reduce((acc: null | ReactNode, modal) => {
      if (modal.isOpen) return modal.component;

      return acc;
    }, null);
  }

  activateModal = (modal: Omit<ActiveModal, 'isOpen' | 'id'> & { id?: string }) => {
    const id = modal.id ?? randomUuid();

    this.activeModals.forEach((m) => {
      m.isOpen = false;
    });

    const alreadyExist = this.activeModals.find((m) => m.id === id);

    if (alreadyExist && !alreadyExist.isOpen) {
      alreadyExist.isOpen = true;
    } else {
      this.activeModals.push({
        ...modal,
        id,
        isOpen: true,
      });
    }
  };

  deactivateModal = (id: string) => {
    setTimeout(() => {
      runInAction(() => {
        this.activeModals = this.activeModals.filter((modal) => modal.id !== id);
      });
    }, 100);
  };

  hideModal = (id: string) => {
    this.activeModals = this.activeModals.map((modal) =>
      modal.id === id
        ? {
            ...modal,
            isOpen: false,
          }
        : modal,
    );
  };

  closeAll = () => {
    this.activeModals = [];
  };

  disposer = () => {
    this.closeAll();
  };
}

export const modalStore = new ModalStore();
