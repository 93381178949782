import { observer } from '@shared/libs/mobx';

import { Button, Indicator, SvgIcon } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface FloatScrollToBottomProps {
  unreadCount?: number;
}

const _FloatScrollToBottom = observer((props: FloatScrollToBottomProps) => {
  const { unreadCount } = props;

  return (
    <Indicator count={unreadCount || 0} className={s.floatScrollToBottom}>
      <Button size="icon" variant="dark-gray" type="button">
        <SvgIcon type="arrow" />
      </Button>
    </Indicator>
  );
});

export const FloatScrollToBottom = mergeComponentsWithName(
  'FloatScrollToBottom',
  _FloatScrollToBottom,
);
