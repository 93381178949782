import type { BaseResponse } from '../_types';
import type { GetCommentsQueryDto } from './dto';
import type { GetCourseLessonCommentsResponse, PostCourseLessonCommentResponse } from './responses';

import { PATH_COLLECTION } from '@core/constants';
import { createHTTPService } from '@core/services/http';

export class CoursesRepository {
  private readonly http = createHTTPService(PATH_COLLECTION.COURSES.ROOT);

  getComments = async (courseId: number, lessonId: number, query?: GetCommentsQueryDto) => {
    const path = PATH_COLLECTION.COURSES.COMMENTS;

    try {
      const { data } = await this.http.get<GetCourseLessonCommentsResponse>(path, {
        param: { courseId, lessonId },
        query,
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  postComment = async (courseId: number, lessonId: number, comment: string) => {
    const path = PATH_COLLECTION.COURSES.COMMENTS;

    try {
      const { data } = await this.http.post<PostCourseLessonCommentResponse>(
        path,
        { comment },
        {
          param: { courseId, lessonId },
        },
      );

      return data;
    } catch (e) {
      throw e;
    }
  };

  markLessonVideoTime = async (courseId: number, lessonId: number, time: number) => {
    const path = PATH_COLLECTION.COURSES.MARK_LESSON_VIDEO_TIME;

    try {
      const { data } = await this.http.post<BaseResponse>(
        path,
        { time },
        {
          param: { courseId, lessonId },
        },
      );

      return data;
    } catch (e) {
      throw e;
    }
  };
}
