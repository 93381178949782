import { useCallback, useState } from 'react';

import { SvgIcon } from '../svg-icon';
import { DocumentThumbnail } from './document-thumbnail';
import type { DocumentProps as DocumentUIProps } from 'react-pdf';
import { Document as DocumentUI, Page, pdfjs } from 'react-pdf';
import type { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { useMediaQuery } from '@shared/hooks';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('document');

export interface DocumentProps extends DocumentUIProps {}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const _Document = observer((props: DocumentProps) => {
  const { onLoadSuccess, className, ...restProps } = props;

  const [pagesCount, setPagesCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const isNeededHeight = useMediaQuery('(max-height: 920px)');

  const handleLoadSuccess: OnDocumentLoadSuccess = useCallback(
    (details) => {
      setPagesCount(details.numPages);

      onLoadSuccess?.(details);
    },
    [onLoadSuccess],
  );

  const onNextPage = useCallback(() => {
    setCurrentPageNumber((prevState) => (pagesCount > prevState ? prevState + 1 : prevState));
  }, [pagesCount]);

  const onPrevPage = useCallback(() => {
    setCurrentPageNumber((prevState) => (prevState > 1 ? prevState - 1 : prevState));
  }, [pagesCount]);

  const rootClasses = cl(rootClass, className);

  return (
    <DocumentUI className={rootClasses} onLoadSuccess={handleLoadSuccess} {...restProps}>
      {currentPageNumber > 1 && (
        <button className={cl(appendClass('-btn'), appendClass('--prev'))} onClick={onPrevPage}>
          <span>
            <SvgIcon type="arrow" />
          </span>
        </button>
      )}

      <Page
        className={appendClass('-page')}
        pageNumber={currentPageNumber}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        scale={isNeededHeight ? 0.8 : 1}
      />

      {currentPageNumber < pagesCount && (
        <button className={cl(appendClass('-btn'), appendClass('--next'))} onClick={onNextPage}>
          <span>
            <SvgIcon type="arrow" />
          </span>
        </button>
      )}
    </DocumentUI>
  );
});

export const Document = mergeComponentsWithName('Document', _Document, {
  Thumbnail: DocumentThumbnail,
});
