import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

export const ReCaptcha = observer(function ReCaptcha() {
  const { authStore } = useRootStore();

  const handleCaptchaLoad = () => {
    window.grecaptcha.ready(() => authStore.setIsReCaptchaLoading(false));
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${
      import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY
    }`;

    script.addEventListener('load', handleCaptchaLoad);

    document.body.appendChild(script);
  }, []);

  return null;
});
