import { useEffect } from 'react';

import { TalkingArea, useAccessPermissions, useMessageParams } from '@units';

import { CommunityChatType } from '@core/constants';
import { useRootStore } from '@core/store';

import { useIsFirstRender } from '@shared/hooks';

export const useCommunityController = () => {
  const {
    communityChatsStore,
    communitiesStore,
    communityChannelsStore,
    communityMessagesStore,
    appSettingsStore,
  } = useRootStore();

  const { checkBusinessId } = useAccessPermissions();

  const { businessAccountId, chatId, channelId } = useMessageParams(TalkingArea.COMMUNITY);

  const isFirstRender = useIsFirstRender();

  const particularChatLoading = channelId
    ? communityChatsStore.getChatsLoadingByChannelId(channelId)
    : false;

  const particularChannelLoading = communityChannelsStore.channelsLoader.isLoading;

  const loadCommunity = async () => {
    try {
      await communitiesStore.awaitedConnectToCommunity(Number(businessAccountId));

      if (channelId) {
        await communityChannelsStore.awaitedConnectToChannel(channelId);

        if (chatId) {
          await communityChatsStore.awaitedConnectToChatEmitter({ communicationItemId: chatId });
        }
      }
    } catch (error) {}
  };

  const isForbiddenAccess =
    communityChatsStore.activeChat &&
    communityChatsStore.activeChat.type === CommunityChatType.Channel &&
    (!businessAccountId || !checkBusinessId(Number(businessAccountId)));

  const canShowFooter = Boolean(
    chatId &&
      !isForbiddenAccess &&
      !particularChatLoading &&
      !particularChannelLoading &&
      !isFirstRender &&
      !communityMessagesStore.isPinnedMessagesVisible &&
      appSettingsStore.isOnline,
  );

  useEffect(() => {
    if (businessAccountId) {
      loadCommunity();
    }
  }, [businessAccountId]);

  return {
    chatId,
    canShowFooter,
  } as const;
};
