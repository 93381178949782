import { ForgotPasswordLink, FormBox } from '../../_components';
import { Subtitle } from '../_components';
import { FormFields } from '@components';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import type { RegisterOptions, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { SubmitButton } from '@features/auth/_components/submit-button';
import { useVerifyOtp } from '@features/auth/_hooks';
import type { SignInByEmailSchema } from '@features/auth/_schemas';
import { signInByEmailSchema } from '@features/auth/_schemas';

import { Box } from '@shared/UI';

export const SignInFormByEmail = observer(function LoginForm() {
  const { t } = useTranslation();

  const { authStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const onVerifyOtp = useVerifyOtp();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    trigger,
    setValue,
  } = useForm<SignInByEmailSchema>({
    resolver: zodResolver(signInByEmailSchema),
    defaultValues: {
      ...authStore.defaultFormValues.signInByEmail,
      password: '',
      otp: '',
    },
  });

  const onSubmit: SubmitHandler<SignInByEmailSchema> = async (dto) => {
    const { otp, ...restDto } = dto;
    if (otp) {
      await onVerifyOtp({ email: restDto.email, otp });
      sendEvent({ event: AnalyticsEvents.LoginAccountTap });
    } else {
      await authStore.signInByEmail(restDto);
    }
  };

  const handleClickResend = async () => {
    try {
      const isValid = await trigger();
      if (isValid) {
        const { email, password } = getValues();
        await authStore.signInByEmail({ email, password });
        return;
      }
      return await Promise.reject();
    } catch (e) {
      return await Promise.reject();
    }
  };

  const { isOTPVisible } = authStore.defaultFormValues.signInByEmail;

  const handleChangeEmailOrPassword = () => {
    if (isOTPVisible) {
      authStore.setIsOTPVisible(false, 'signInByEmail');
      setValue('otp', '');
    }
  };

  const emailAndPasswordRules: RegisterOptions = {
    onChange: handleChangeEmailOrPassword,
  };

  return (
    <FormBox
      onSubmit={handleSubmit(onSubmit)}
      title={t('shared-content.sign-in')}
      subTitle={<Subtitle />}
    >
      <FormFields.Input
        name="email"
        label="inputs.email.label"
        placeholder="inputs.email.placeholder"
        error={errors.email}
        control={control}
        rules={emailAndPasswordRules}
      />
      <FormFields.Password
        name="password"
        label="inputs.password.label"
        placeholder="inputs.password.placeholder"
        error={errors.password}
        control={control}
        rules={emailAndPasswordRules}
      />

      {isOTPVisible && (
        <FormFields.OtpCode
          onClickResend={handleClickResend}
          name="otp"
          control={control}
          error={errors.otp}
        />
      )}

      <SubmitButton isOtpVisible={isOTPVisible} control={control} />

      <Box textAlign="center">
        <ForgotPasswordLink />
      </Box>
    </FormBox>
  );
});
