import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button, Stack, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

export const CommunityPreviewFooter = observer(function CommunityPreviewFooter() {
  const { t } = useTranslation();

  return (
    <Stack gap="xl" className={s.communityPreviewFooter}>
      <TypographyNew variant="title-2" weight="bold">
        {t('brands.have-questions')}
      </TypographyNew>
      <TypographyNew variant="title-2" weight="bold">
        {t('brands.contact-with-our-team')}
      </TypographyNew>
      <a href="mailto: support@comms.fun" target="_blank" rel="noopener noreferrer">
        <Button type="button" className={s.communityPreviewFooterButton}>
          {t('shared-content.contact-support')}
        </Button>
      </a>
    </Stack>
  );
});
