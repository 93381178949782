import i18n from 'i18next';
import { z } from 'zod';

export type CreateChatForm = {
  title: string;
  description: string;
  discordChannelId?: string;
  isReadOnly: boolean;
};

export const createChatSchema = z.object({
  title: z.string().min(1, {
    message: i18n.t('errors.required-field'),
  }),
  description: z.string().min(1, {
    message: i18n.t('errors.required-field'),
  }),
  discordChannelId: z.string().optional(),
  isReadOnly: z.boolean(),
});
