import { makeAutoObservable, runInAction, when } from 'mobx';

import type { ArgumentTypes } from '@shared/types';

type DispatchMap = Record<string, any>;

type DispatchKeys<T extends DispatchMap> = string & keyof T;

type SubscribeMap = Record<string, (...args: any) => any>;

type SubscribeKeys<T extends SubscribeMap> = string & keyof T;

const MAX_RETRY_COUNT = 3;

export class WebSocketService<Dispatch extends DispatchMap, Subscribe extends SubscribeMap> {
  isOpen = false;

  isClosed = false;

  isConnecting = false;

  private retryCount = 0;

  private dispatchListeners: Map<string, Set<(...args: any) => any>> = new Map();

  private subscribers: Map<string, Set<(...args: any) => any>> = new Map();

  private socket: WebSocket | null = null;

  private cachedDispatchers: (() => void)[] = [];

  private awaitedCache: (() => void)[] = [];

  constructor(private path: string | (() => string)) {
    makeAutoObservable<this, 'subscribers'>(
      this,
      {
        subscribers: false,
      },
      { autoBind: true },
    );

    when(
      () => this.isOpen,
      () => {
        if (this.cachedDispatchers.length) {
          this.cachedDispatchers.forEach((fn) => fn());
          this.cachedDispatchers = [];
        }
        if (this.awaitedCache.length) {
          this.awaitedCache.forEach((fn) => {
            fn();
          });
          this.awaitedCache = [];
        }
      },
    );
  }

  get subs() {
    return this.subscribers;
  }

  connect = () => {
    const path = typeof this.path === 'string' ? this.path : this.path();

    const socket = new WebSocket(path);

    socket.onopen = this.onOpen;
    socket.onerror = this.onError;
    socket.onclose = this.onClose;
    socket.onmessage = this.onMessage;

    this.socket = socket;

    this.isConnecting = true;
  };

  disconnect = () => {
    if (this.socket && !this.isClosed) {
      this.socket.close();
      this.subscribers = new Map();
    }
  };

  dispatch = <K extends DispatchKeys<Dispatch>>(action: K, details?: Dispatch[K]) => {
    if (!this.socket || !this.isOpen) {
      this.cachedDispatchers.push(() => {
        this.dispatch(action, details);
      });
      return;
    }
    this.socket.send(JSON.stringify({ action, ...details }));
    this.dispatchListeners.get(action)?.forEach((fn) => fn(details));
  };

  awaitedDispatch = <K extends DispatchKeys<Dispatch>, S extends SubscribeKeys<Subscribe>>(
    action: K,
    awaited?: S,
    details?: Dispatch[K],
  ): Promise<ArgumentTypes<Subscribe[S]>[0]> => {
    const signal = new AbortController();

    return new Promise((resolve, reject) => {
      try {
        this.socket?.send(JSON.stringify({ action, ...details }));
        this.dispatchListeners.get(action)?.forEach((fn) => fn(details));
        const listener = (event: MessageEvent) => {
          if (event && 'data' in event) {
            const dt = JSON.parse(event.data);
            if (dt && 'action' in dt && dt.action === awaited) {
              signal.abort();
              resolve(dt?.data);
            }
          }
        };
        this.socket?.addEventListener('message', listener, {
          signal: signal.signal,
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  subscribeDispatch = <K extends DispatchKeys<Dispatch>>(
    action: K,
    listener: (args: Dispatch[K]) => void,
  ) => {
    const event = this.dispatchListeners.get(action);

    if (event) {
      this.dispatchListeners.set(action, new Set([...event, listener]));
    } else {
      this.dispatchListeners.set(action, new Set([listener]));
    }
  };

  subscribe = <K extends SubscribeKeys<Subscribe>>(name: K, listener: Subscribe[K]) => {
    let event: Set<(...args: any) => any>;
    const ls = listener;

    const existEvent = this.subscribers.get(name);

    if (existEvent) {
      event = existEvent;
    } else {
      event = this.subscribers.set(name, new Set()).get(name)!;
    }

    const stack = event.add(ls);

    return () => {
      runInAction(() => {
        stack.delete(ls);
      });
    };
  };

  private onOpen = (event: Event) => {
    this.isConnecting = false;
    this.isOpen = true;
    this.isClosed = false;

    import.meta.env.MODE === 'development' && console.log('[onOpen] Event -->', event);
  };

  private onError = (event: Event) => {
    if (this.socket?.readyState === 3) {
      if (this.retryCount <= MAX_RETRY_COUNT) {
        this.retryCount += 1;
        setTimeout(this.connect, 1000);
      }
      return;
    }

    import.meta.env.MODE === 'development' && console.log('[onError] Event -->', event);
  };

  private onClose = () => {
    this.isClosed = true;
    this.isOpen = false;
    this.isConnecting = false;
  };

  private onMessage = (messageEvent: MessageEvent<string>) => {
    const { action, ...restData } = JSON.parse(messageEvent.data) as { action: string; data: any };

    import.meta.env.MODE === 'development' &&
      console.log(
        `[onMessage] %c ${action} `,
        'color:#c7efcf;font-weight:700;padding:2px;border:1px solid #769AD0;',
        restData.data,
      );

    this.subscribers.get(action)?.forEach((listener) => {
      listener(restData.data);
    });
  };
}

export const createWebSocket = <Dispatch extends DispatchMap, Subscribe extends SubscribeMap>(
  path: string | (() => string),
) => new WebSocketService<Dispatch, Subscribe>(path);
