import type { ReactElement, ReactNode } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import { type ImageProps } from '@shared/UI';

import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('avatar');

const avatar = cva(rootClass, {
  variants: {
    size: {
      xs: appendClass('--size-xs'),
      sm: appendClass('--size-sm'),
      'sm-2': appendClass('--size-sm-2'),
      md: appendClass('--size-md'),
      lg: appendClass('--size-lg'),
      xl: appendClass('--size-xl'),
      xxl: appendClass('--size-xxl'),
    },
    shape: {
      rounded: appendClass('--shape-rounded'),
      square: appendClass('--shape-square'),
    },
  },

  defaultVariants: {
    size: 'md',
    shape: 'rounded',
  },
});

export interface AvatarProps extends ElementProps<'span', 'children'>, VariantProps<typeof avatar> {
  icon?: ReactNode;
  hasBorder?: boolean;
  src?: string;
  imageProps?: Omit<ImageProps, 'src' | 'children' | 'alt'>;
  alt?: string;
  imagePlaceholder?: ReactElement;
  indicatorSlot?: ReactNode;
  lazyImage?: boolean;
}

const _Avatar = observer((props: AvatarProps) => {
  const {
    size,
    icon,
    src,
    className,
    shape,
    style,
    imageProps,
    hasBorder = false,
    alt,
    imagePlaceholder,
    indicatorSlot,
    ...restProps
  } = props;

  const { className: imageClassName } = imageProps ?? {};

  const rootClasses = avatar({ size, shape, className });

  const imageClasses = cl(appendClass('-image'), imageClassName);

  return (
    <div className="ui-avatar-container">
      <span className={rootClasses} data-border={hasBorder} {...restProps}>
        {icon && !src && <span className={appendClass('-icon')}>{icon}</span>}

        {src && <img src={src} alt={alt} className={imageClasses} />}
      </span>
      {indicatorSlot}
    </div>
  );
});

export const Avatar = mergeComponentsWithName('Avatar', _Avatar);
