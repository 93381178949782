import { Title } from '../_components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Form } from './form';

export const ChangePasswordForm = observer(function ChangePasswordForm() {
  const { t } = useTranslation();

  return (
    <div>
      <Title>{t('my-account.change-password.title')}</Title>
      <Form />
    </div>
  );
});
