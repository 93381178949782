import { ReactComponent as ArrowRounded } from '@assets/icons/arrow-rounded.svg';
import { ReactComponent as ArrowWithGradient } from '@assets/icons/arrow-with-gradient.svg';
import { ReactComponent as Arrow } from '@assets/icons/arrow.svg';
import { ReactComponent as AudioRecord } from '@assets/icons/audio-record.svg';
import { ReactComponent as BubbleChats } from '@assets/icons/bubble-chats.svg';
import { ReactComponent as Channel } from '@assets/icons/channel.svg';
import { ReactComponent as CheckmarkWithGradient } from '@assets/icons/checkmark-with-gradient.svg';
import { ReactComponent as Checkmark } from '@assets/icons/checkmark.svg';
import { ReactComponent as Copy } from '@assets/icons/copy.svg';
import { ReactComponent as Cross } from '@assets/icons/cross.svg';
import { ReactComponent as DoubleArrow } from '@assets/icons/double-arrow.svg';
import { ReactComponent as DoubleCheckmark } from '@assets/icons/double-checkmark.svg';
import { ReactComponent as DualRingSpinner } from '@assets/icons/dual-ring-spinner.svg';
import { ReactComponent as EditMessage } from '@assets/icons/edit-message.svg';
import { ReactComponent as EditorBold } from '@assets/icons/editor/bold.svg';
import { ReactComponent as EditorBulletList } from '@assets/icons/editor/bullet-list.svg';
import { ReactComponent as EditorItalic } from '@assets/icons/editor/italic.svg';
import { ReactComponent as EditorLink } from '@assets/icons/editor/link.svg';
import { ReactComponent as EditorNumberedList } from '@assets/icons/editor/numbered-list.svg';
import { ReactComponent as EditorStrikeThrough } from '@assets/icons/editor/strikethrough.svg';
import { ReactComponent as EditorUnderline } from '@assets/icons/editor/underline.svg';
import { ReactComponent as ElongatedArrow } from '@assets/icons/elongated-arrow.svg';
import { ReactComponent as Emoji } from '@assets/icons/emoji.svg';
import { ReactComponent as Eye } from '@assets/icons/eye.svg';
import { ReactComponent as FilePreviewIcon } from '@assets/icons/file-preview-icon.svg';
import { ReactComponent as File } from '@assets/icons/file.svg';
import { ReactComponent as EngFlag } from '@assets/icons/flags/en-flag.svg';
import { ReactComponent as UaFlag } from '@assets/icons/flags/ua-flag.svg';
import { ReactComponent as Globe } from '@assets/icons/globe.svg';
import { ReactComponent as GreenCheckmark } from '@assets/icons/green-checkmark.svg';
import { ReactComponent as Lock } from '@assets/icons/lock.svg';
import { ReactComponent as MessageReply } from '@assets/icons/message-reply.svg';
import { ReactComponent as PaperAirplane2 } from '@assets/icons/paper-airplane-2.svg';
import { ReactComponent as PaperAirplaine } from '@assets/icons/paper-airplane.svg';
import { ReactComponent as Papeclip } from '@assets/icons/paperclip.svg';
import { ReactComponent as PauseOutline } from '@assets/icons/pause-outline.svg';
import { ReactComponent as Pause } from '@assets/icons/pause.svg';
import { ReactComponent as Pen } from '@assets/icons/pen.svg';
import { ReactComponent as Persons } from '@assets/icons/persons.svg';
import { ReactComponent as PinFilledSm } from '@assets/icons/pin-filled-sm.svg';
import { ReactComponent as PinFilled } from '@assets/icons/pin-filled.svg';
import { ReactComponent as Pin } from '@assets/icons/pin.svg';
import { ReactComponent as PlayCircleGray } from '@assets/icons/play-circle-gray.svg';
import { ReactComponent as PlayCircle } from '@assets/icons/play-circle.svg';
import { ReactComponent as PlayOutline } from '@assets/icons/play-outline.svg';
import { ReactComponent as Play } from '@assets/icons/play.svg';
import { ReactComponent as Plus } from '@assets/icons/plus.svg';
import { ReactComponent as RedCross } from '@assets/icons/red-cross.svg';
import { ReactComponent as Reply } from '@assets/icons/reply.svg';
import { ReactComponent as RubbishBin } from '@assets/icons/rubbish-bin.svg';
import { ReactComponent as Share } from '@assets/icons/share.svg';
import { ReactComponent as Star } from '@assets/icons/star.svg';
import { ReactComponent as Timer } from '@assets/icons/timer.svg';
import { ReactComponent as VideoRecord } from '@assets/icons/video-record.svg';
import { ReactComponent as VolumeOff } from '@assets/icons/volume-off.svg';
import { ReactComponent as VolumeUp } from '@assets/icons/volume-up.svg';
import { ReactComponent as MasterCardGray } from '@assets/images/payments/mastercard-gray.svg';
import { ReactComponent as MasterCard } from '@assets/images/payments/mastercard.svg';
import { ReactComponent as StripePowerBy } from '@assets/images/payments/stripe.svg';
import { ReactComponent as VisaGray } from '@assets/images/payments/visa-gray.svg';
import { ReactComponent as Visa } from '@assets/images/payments/visa.svg';
import { ReactComponent as Apple } from '@assets/images/vector/apple.svg';
import { ReactComponent as ArrowRight } from '@assets/images/vector/arrow-right.svg';
import { ReactComponent as Check } from '@assets/images/vector/check.svg';
import { ReactComponent as Clock } from '@assets/images/vector/clock.svg';
import { ReactComponent as CommsIcon } from '@assets/images/vector/comms-icon.svg';
import { ReactComponent as CommunityBurger } from '@assets/images/vector/community/burger.svg';
import { ReactComponent as ChatOutlined } from '@assets/images/vector/community/chat-outlined.svg';
import { ReactComponent as Chat } from '@assets/images/vector/community/chat.svg';
import { ReactComponent as Info } from '@assets/images/vector/community/info.svg';
import { ReactComponent as MessageUnwatched } from '@assets/images/vector/community/message-status-unwatched.svg';
import { ReactComponent as MessageWatched } from '@assets/images/vector/community/message-status-watched.svg';
import { ReactComponent as SmilePlus } from '@assets/images/vector/community/smile-plus.svg';
import { ReactComponent as Smile } from '@assets/images/vector/community/smile.svg';
import { ReactComponent as Alarm } from '@assets/images/vector/courses/alarm.svg';
import { ReactComponent as LockClosedPink } from '@assets/images/vector/courses/lock-closed-pink.svg';
import { ReactComponent as LockClosed } from '@assets/images/vector/courses/lock-closed.svg';
import { ReactComponent as LockOpened } from '@assets/images/vector/courses/lock-opened.svg';
import { ReactComponent as StarOutlined } from '@assets/images/vector/courses/star-outlined.svg';
import { ReactComponent as Cross2 } from '@assets/images/vector/cross-2.svg';
import { ReactComponent as Doc } from '@assets/images/vector/doc.svg';
import { ReactComponent as Download } from '@assets/images/vector/download.svg';
import { ReactComponent as EmptyComments } from '@assets/images/vector/empty-comments.svg';
import { ReactComponent as EmptyCourses } from '@assets/images/vector/empty-courses.svg';
import { ReactComponent as EmptyMessages } from '@assets/images/vector/empty-messages.svg';
import { ReactComponent as EmptyNotifications } from '@assets/images/vector/empty-notifications.svg';
import { ReactComponent as EyeCrossedOut } from '@assets/images/vector/eye-crossed-out.svg';
import { ReactComponent as FingerPrint } from '@assets/images/vector/finger-print.svg';
import { ReactComponent as UaSquare } from '@assets/images/vector/flags/ua.svg';
import { ReactComponent as Google } from '@assets/images/vector/google.svg';
import { ReactComponent as Hamburger } from '@assets/images/vector/hamburger.svg';
import { ReactComponent as User } from '@assets/images/vector/header/user.svg';
import { ReactComponent as Incorrect } from '@assets/images/vector/homework/incorrect.svg';
import { ReactComponent as Selected } from '@assets/images/vector/homework/selected.svg';
import { ReactComponent as Unselected } from '@assets/images/vector/homework/unselected.svg';
import { ReactComponent as Logo } from '@assets/images/vector/logo/logo.svg';
import { ReactComponent as MarkAllAsRead } from '@assets/images/vector/mark-all-as-read.svg';
import { ReactComponent as BurgerMenu } from '@assets/images/vector/menu/burger.svg';
import { ReactComponent as Community } from '@assets/images/vector/menu/community.svg';
import { ReactComponent as Dashboard } from '@assets/images/vector/menu/dashboard.svg';
import { ReactComponent as Events } from '@assets/images/vector/menu/events.svg';
import { ReactComponent as Messages } from '@assets/images/vector/menu/messages.svg';
import { ReactComponent as MyAccount } from '@assets/images/vector/menu/my-account.svg';
import { ReactComponent as MyWorkspace } from '@assets/images/vector/menu/my-workspace.svg';
import { ReactComponent as Minus } from '@assets/images/vector/minus.svg';
import { ReactComponent as Moderate } from '@assets/images/vector/moderate.svg';
import { ReactComponent as Bell } from '@assets/images/vector/notifications/bell.svg';
import { ReactComponent as Pencil } from '@assets/images/vector/pencil.svg';
import { ReactComponent as Population } from '@assets/images/vector/population.svg';
import { ReactComponent as Rate } from '@assets/images/vector/rate.svg';
import { ReactComponent as ResendAgain } from '@assets/images/vector/resend-again.svg';
import { ReactComponent as Resend } from '@assets/images/vector/resend.svg';
import { ReactComponent as Search } from '@assets/images/vector/search.svg';
import { ReactComponent as Send } from '@assets/images/vector/send.svg';
import { ReactComponent as SettingsWhite } from '@assets/images/vector/settings-white.svg';
import { ReactComponent as Settings } from '@assets/images/vector/settings.svg';
import { ReactComponent as ShieldError } from '@assets/images/vector/shield-error.svg';
import { ReactComponent as ShieldSuccess } from '@assets/images/vector/shield-success.svg';
import { ReactComponent as Calendar } from '@assets/images/vector/subscription/calendar.svg';
import { ReactComponent as Warning } from '@assets/images/vector/subscription/warning.svg';
import { ReactComponent as ThreeDots } from '@assets/images/vector/three-dots.svg';
import { ReactComponent as Triangle } from '@assets/images/vector/triangle.svg';
import { ReactComponent as Union } from '@assets/images/vector/union.svg';
import { ReactComponent as Verified } from '@assets/images/vector/verified.svg';
import { ReactComponent as Video } from '@assets/images/vector/video.svg';

export const svgIconCollection = {
  // ------------- Flags
  'ua-flag': <UaFlag />,
  'en-flag': <EngFlag />,

  //languages
  'ua-square': <UaSquare />,

  // ------------- Fields
  'green-checkmark': <GreenCheckmark />,
  'red-cross': <RedCross />,
  checkmark: <Checkmark />,
  'checkmark-with-gradient': <CheckmarkWithGradient />,

  // ------------- Buttons
  'paper-airplane': <PaperAirplaine />,
  'paper-airplane-2': <PaperAirplane2 />,
  paperclip: <Papeclip />,
  'rubbish-bin': <RubbishBin />,
  cross: <Cross />,
  'video-record': <VideoRecord />,
  'audio-record': <AudioRecord />,
  emoji: <Emoji />,
  play: <Play />,
  pause: <Pause />,
  'play-outline': <PlayOutline />,
  'play-circle': <PlayCircle />,
  'play-circle-gray': <PlayCircleGray />,
  'pause-outline': <PauseOutline />,
  'volume-up': <VolumeUp />,
  'volume-off': <VolumeOff />,

  // ------------- commons
  file: <File />,
  persons: <Persons />,
  star: <Star />,
  share: <Share />,
  'double-arrow': <DoubleArrow />,
  'elongated-arrow': <ElongatedArrow />,
  'bubble-chats': <BubbleChats />,
  'double-checkmark': <DoubleCheckmark />,
  eye: <Eye />,
  reply: <Reply />,
  'edit-message': <EditMessage />,
  pin: <Pin />,
  'pin-filled': <PinFilled />,
  'pin-filled-sm': <PinFilledSm />,
  plus: <Plus />,
  lock: <Lock />,
  pen: <Pen />,
  copy: <Copy />,
  timer: <Timer />,

  // ----------- Rich Text Editor
  'editor-bold': <EditorBold />,
  'editor-italic': <EditorItalic />,
  'editor-underline': <EditorUnderline />,
  'editor-strike-trough': <EditorStrikeThrough />,
  'editor-bullet-list': <EditorBulletList />,
  'editor-numbered-list': <EditorNumberedList />,
  'editor-link': <EditorLink />,

  //menu
  account: <MyAccount />,
  community: <Community />,
  workspace: <MyWorkspace />,
  dashboard: <Dashboard />,
  events: <Events />,
  messages: <Messages />,

  //logo
  logo: <Logo />,

  'finger-print': <FingerPrint />,
  google: <Google />,
  apple: <Apple />,
  'eye-crossed-out': <EyeCrossedOut />,
  alarm: <Alarm />,
  'lock-opened': <LockOpened />,
  'lock-closed': <LockClosed />,
  'lock-closed-pink': <LockClosedPink />,
  arrow: <Arrow />,
  'arrow-rounded': <ArrowRounded />,
  'arrow-with-gradient': <ArrowWithGradient />,
  'arrow-right': <ArrowRight />,
  'star-outlined': <StarOutlined />,
  send: <Send />,
  'cross-2': <Cross2 />,
  'shield-success': <ShieldSuccess />,
  'shield-error': <ShieldError />,
  resend: <Resend />,
  'resend-again': <ResendAgain />,
  hamburger: <Hamburger />,
  bell: <Bell />,
  triangle: <Triangle />,
  check: <Check />,
  'empty-courses': <EmptyCourses />,
  'empty-notifications': <EmptyNotifications />,
  'empty-comments': <EmptyComments />,
  'empty-messages': <EmptyMessages />,
  'chat-outlined': <ChatOutlined />,
  info: <Info />,
  search: <Search />,
  moderate: <Moderate />,
  pencil: <Pencil />,
  settings: <Settings />,
  'settings-white': <SettingsWhite />,
  download: <Download />,
  minus: <Minus />,
  doc: <Doc />,
  video: <Video />,
  'three-dots': <ThreeDots />,
  clock: <Clock />,
  union: <Union />,
  verified: <Verified />,
  'mark-all-as-read': <MarkAllAsRead />,
  rate: <Rate />,
  population: <Population />,
  calendar: <Calendar />,
  warning: <Warning />,
  user: <User />,
  globe: <Globe />,

  chat: <Chat />,
  channel: <Channel />,
  smile: <Smile />,
  'smile-plus': <SmilePlus />,
  'message-status-unwatched': <MessageUnwatched />,
  'message-status-watched': <MessageWatched />,
  'burger-menu': <BurgerMenu />,
  selected: <Selected />,
  unselected: <Unselected />,
  incorrect: <Incorrect />,
  'community-burger': <CommunityBurger />,
  'comms-icon': <CommsIcon />,

  'mastercard-gray': <MasterCardGray />,
  mastercard: <MasterCard />,
  'visa-gray': <VisaGray />,
  visa: <Visa />,
  'stripe-power-by': <StripePowerBy />,

  'file-preview-icon': <FilePreviewIcon />,
  'message-reply': <MessageReply />,

  // ------------- spinners
  'dual-ring-spinner': <DualRingSpinner />,
} as const;
