import axios from 'axios';

import {
  rejectedInterceptor,
  requestMediaFulfilledInterceptor,
  responseFulfilledInterceptor,
} from './interceptors';

const baseURL = `${import.meta.env.VITE_MEDIA_SERVER_DEFAULT_API_URL_PATH}`;

const httpMediaClient = axios.create({
  baseURL,
  withCredentials: true,
});

httpMediaClient.interceptors.request.use(requestMediaFulfilledInterceptor, rejectedInterceptor);
httpMediaClient.interceptors.response.use(responseFulfilledInterceptor, rejectedInterceptor);

export { httpMediaClient };
