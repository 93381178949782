import { TypographyNew } from '../common';
import { Group } from '../group';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { cl } from '@shared/libs/classnames';

import s from './styles.module.scss';

type TabItem = {
  name: string;
  label: string;
};

interface TabsProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  tabs: TabItem[];
}

export const Tabs = observer(function Tabs(props: TabsProps) {
  const { activeTab, setActiveTab, tabs } = props;

  const { t } = useTranslation();

  return (
    <Group wrap="nowrap" w="100%" gap="none">
      {tabs.map((tab) => (
        <button
          key={tab.name}
          className={cl(s.tabsItem, { [s.tabsItemActive]: activeTab === tab.name })}
          onClick={() => setActiveTab(tab.name)}
        >
          <TypographyNew variant="body-3">{t(tab.label)}</TypographyNew>
        </button>
      ))}
    </Group>
  );
});
