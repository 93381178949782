import type { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

export type CheckLoadingWrapperProps = {
  children: ReactNode;
};
export const CheckLoadingWrapper = observer(function CheckLoadingWrapper(
  props: CheckLoadingWrapperProps,
) {
  const { children } = props;

  const { appLoaderStore } = useRootStore();

  if (appLoaderStore.isVisibleWelcome || appLoaderStore.isVisibleLoader) {
    return null;
  }

  return children;
});
