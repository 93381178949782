import { makeAutoObservable } from 'mobx';

export class AppSettingsStore {
  isOnline = true;

  private onlineCallbacks: Function[] = [];

  private offlineCallbacks: Function[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  private handleOnline = () => {
    this.isOnline = true;
    this.onlineCallbacks.forEach((callback) => {
      callback();
    });
  };

  private handleOffline = () => {
    this.isOnline = false;
    this.offlineCallbacks.forEach((callback) => {
      callback();
    });
  };

  listenOnline = (cb: Function) => {
    this.onlineCallbacks.push(cb);
  };

  listenOffline = (cb: Function) => {
    this.offlineCallbacks.push(cb);
  };

  unmountDisposer = () => {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);

    this.onlineCallbacks = [];
  };
}
