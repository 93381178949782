import { useEffect } from 'react';

import { MessageItem } from '../message-item';
import { observer } from 'mobx-react-lite';

import type { StreamChatTabs } from '@core/constants';
import { useRootStore } from '@core/store';

import type { ObjectValues } from '@shared/types';

interface OnApproveMessagesProps {
  tab: ObjectValues<typeof StreamChatTabs>;
}

export const OnApproveMessages = observer(function OnApproveMessages(
  props: OnApproveMessagesProps,
) {
  const { tab } = props;

  const { streamChatStore } = useRootStore();

  useEffect(() => {
    streamChatStore.getOnApproveMessages();
  }, []);

  return streamChatStore.onApproveMessages.map((message) => (
    <MessageItem
      key={message.id}
      message={message.message}
      messageId={message.id}
      authorId={message.authorId}
      authorName={message.authorName}
      tab={tab}
    />
  ));
});
