import { observer } from 'mobx-react-lite';

import { TypographyNew } from '@shared/UI';

import { randomUuid } from '@shared/utils';

import s from './styles.module.scss';

interface EmojiReactionItemProps {
  reaction: string;
  reactionCount: number;
  messageId: string;
  handleEmojiClick: (details: string, id: string) => void;
}

export const EmojiReactionItem = observer(function EmojiReactionItem(
  props: EmojiReactionItemProps,
) {
  const { reaction, reactionCount, messageId, handleEmojiClick } = props;

  return (
    <div
      key={randomUuid()}
      onClick={() => handleEmojiClick(reaction, messageId)}
      className={s.emojiReactionsItem}
    >
      <TypographyNew as="span" className={s.emojiReactionsItemText}>
        {reaction}
      </TypographyNew>
      {reactionCount > 0 && (
        <TypographyNew as="span" className={s.emojiReactionsItemCount}>
          {reactionCount > 99 ? '99+' : reactionCount}
        </TypographyNew>
      )}
    </div>
  );
});
