import type { CSSProperties, HTMLAttributes, ReactNode } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import { useClassName } from '@shared/UI/_hooks';

import './styles.scss';

export type ProgressBarVariants = 'gradient' | 'purple' | 'blue' | 'pink' | 'gray' | 'yellow';
export type ProgressBarBgVariants = 'gray' | 'black';

export type ProgressBarProps = {
  variant?: ProgressBarVariants;

  bgVariant?: ProgressBarBgVariants;

  isHiddenLabel?: boolean;

  customLabel?: ReactNode;

  progress: number;

  maxProgress?: number;

  size?: 'sm' | 'md' | 'lg';
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

export const ProgressBar = observer(function ProgressBar(props: ProgressBarProps) {
  const {
    progress,
    isHiddenLabel,
    className,
    size = 'md',
    variant = 'gradient',
    bgVariant = 'black',
    customLabel,
    ...restProps
  } = props;

  const { rootClassName, appendClass } = useClassName('progress-bar');

  const rootClasses = cl(
    rootClassName,
    appendClass(`--${variant}`),
    appendClass(`--bg-${bgVariant}`),
    appendClass(`--${size}`),
    className,
  );

  const fillerStyles: CSSProperties = {
    width: `100%`,
    maxWidth: `${progress > 100 ? 100 : progress}%`,
  };

  const label = customLabel ? customLabel : `${progress}%`;

  return (
    <div className={rootClasses} {...restProps}>
      <div className={appendClass('-filler-box')}>
        <div data-progress={progress} style={fillerStyles} className={appendClass('-filler')} />
      </div>
      {!isHiddenLabel && <span className={appendClass('-label')}>{label}</span>}
    </div>
  );
});
