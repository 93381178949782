import type { MouseEvent } from 'react';
import { useCallback } from 'react';

import { CreateChatModal } from '../modals';
import { modalStore } from '@components';
import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import {
  TalkingArea,
  useAccessPermissions,
  useCommunitySidebarSensors,
  useMessageParams,
} from '@units';
import { computed } from 'mobx';
import { useTranslation } from 'react-i18next';

import { type ChannelStore, useRootStore } from '@core/store';

import { ContextMenuNew, useContextMenu } from '@shared/UI';

import { extractNode } from '@shared/utils';

export interface UseCommunityChatListControllerProps {
  channel: ChannelStore;
}

export const useCommunityChatListController = (props: UseCommunityChatListControllerProps) => {
  const { channel } = props;

  const { t } = useTranslation();

  const { communityChatsStore, communityChannelsStore } = useRootStore();

  const dndSensors = useCommunitySidebarSensors();

  const { chatId } = useMessageParams(TalkingArea.COMMUNITY);

  const chats = computed(() => communityChatsStore.getChatsByChannelId(channel.id)).get();

  const contextMenu = useContextMenu();

  const params = useMessageParams(TalkingArea.COMMUNITY);

  const { checkPermissionWithBusinessId, permissions } = useAccessPermissions();

  const hasAccessToUpdate = params.businessAccountId
    ? checkPermissionWithBusinessId(
        permissions.communityCategory.update,
        parseInt(params.businessAccountId),
      )
    : false;

  const isLoaded = communityChannelsStore.channelBucketById(channel.id)?.loading.isLoaded ?? false;
  const isLoading =
    communityChannelsStore.channelBucketById(channel.id)?.loading.isLoading ?? false;

  const onDragEnd = useCallback((event: DragEndEvent) => {
    event.activatorEvent.stopPropagation();
    const { active, over } = event;

    if (!over?.id || active.id === over.id) return;

    const oldIndex = chats.findIndex((item) => item.id === active.id);
    const newIndex = chats.findIndex((item) => item.id === over.id);

    const updatedArray = arrayMove(chats, oldIndex, newIndex);

    communityChatsStore.updateChatPositionEmitter(updatedArray, channel.id);
  }, []);

  const onContextMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const node = extractNode(event, '[data-chat-id]');

    const { chatId } = node?.dataset || {};

    if (!chatId) return;

    const chat = communityChatsStore.getChatById(chatId);

    if (!chat) return;

    if (!hasAccessToUpdate) return;

    contextMenu.show({
      event,
      component: (
        <ContextMenuNew.Menu>
          <ContextMenuNew.Content>
            <ContextMenuNew.Item
              onClick={() => {
                modalStore.activateModal({
                  component: <CreateChatModal chat={chat} channel={channel} />,
                });
              }}
            >
              {t('modals.communication-item-settings.channel.title')}
            </ContextMenuNew.Item>
          </ContextMenuNew.Content>
        </ContextMenuNew.Menu>
      ),
    });
  };

  return { chats, chatId, dndSensors, isLoaded, isLoading, onDragEnd, onContextMenu } as const;
};
