import { CourseHomeworkStore } from './course-homework.store';
import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

import { BlockRepository } from '@core/repositories';
import type {
  DashboardBlockCourseContentEntity,
  MyCoursesQueryDto,
  MyHomeworkEntity,
  MyHomeworkTypes,
  MyHomeworksQueryDto,
} from '@core/repositories';
import type { IdParam } from '@core/repositories/_types';
import type { MyHomeworkResponse } from '@core/repositories/blocks/responses';
import { getHttpError } from '@core/services/http';
import { LoadingStore } from '@core/store/common';

export class MyWorkspaceStore {
  myCoursesBlocks: DashboardBlockCourseContentEntity[] | null = null;

  courseHomeworks: Record<keyof MyHomeworkResponse, CourseHomeworkStore[]> = {
    toBeSent: [],
    unverified: [],
    verified: [],
  };

  currentCourseGroupId: null | number = null;

  loader = new LoadingStore();

  courseHomeworksLoader = new LoadingStore();

  private repository = new BlockRepository();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: 'MyWorkspaceStore',
      properties: ['currentCourseGroupId'],
    });
  }

  getMyCourses = async (query?: MyCoursesQueryDto) => {
    if (!this.loader.isLoaded) {
      this.loader.startLoading();
    }

    try {
      const data = await this.repository.getMyCourses(query);

      runInAction(() => {
        this.myCoursesBlocks = data.block.courses;
        this.loader.setLoaded(true);

        if (!this.currentCourseGroupId) {
          this.currentCourseGroupId = data.block.courses?.[0].groupId ?? null;
        }
      });
    } catch (e) {
      const err = getHttpError(e);
      throw err;
    } finally {
      this.loader.stopLoading();
    }
  };

  getCourseHomeworks = async (query: MyHomeworksQueryDto, withCondition = true) => {
    if (query.groupId !== this.currentCourseGroupId && withCondition) {
      this.courseHomeworksLoader.startLoading();
    }

    try {
      const data = await this.repository.getMyHomeworksByGroupId(query);

      runInAction(() => {
        this.courseHomeworks = {
          toBeSent: this.createCourseHomeworks(data.toBeSent, query.groupId, 'toBeSent'),
          unverified: this.createCourseHomeworks(data.unverified, query.groupId, 'unverified'),
          verified: this.createCourseHomeworks(data.verified, query.groupId, 'verified'),
        };
        this.currentCourseGroupId = query.groupId;
      });
    } catch (e) {
      const err = getHttpError(e);
      throw err;
    } finally {
      this.courseHomeworksLoader.stopLoading();
      this.courseHomeworksLoader.setLoaded(true);
    }
  };

  completeHomework = async (homeworkId: number, blockKey: keyof MyHomeworkResponse) => {
    runInAction(() => {
      this.courseHomeworks[blockKey] = this.courseHomeworks[blockKey].filter((homework) => {
        return homework.homework.id !== homeworkId;
      });
    });

    if (this.currentCourseGroupId) {
      await this.getCourseHomeworks({ groupId: this.currentCourseGroupId }, false);
    }
  };

  disposer = () => {
    clearPersistedStore(this);
  };

  private createCourseHomeworks = (
    homeworks: MyHomeworkEntity[],
    groupId: IdParam,
    blockKey: MyHomeworkTypes,
  ) => {
    return homeworks.map((homework) => new CourseHomeworkStore(homework, groupId, blockKey));
  };
}
