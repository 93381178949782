export const defaultVideoJSOptions = {
  controls: true,
  responsive: true,
  fluid: true,
  preload: 'auto',
  html5: {
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    vhs: {
      overrideNative: true,
      withCredentials: true,
    },
  },
};

export const videoContentTypes = {
  mp4: 'video/mp4',
  hls: 'application/x-mpegURL',
};
