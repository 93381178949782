import { useEffect } from 'react';

import { AuthType } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { SignUpFormByEmail, SignUpFormByPhone } from '@features/auth';

export const Registration = observer(function Registration() {
  const { authStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  useEffect(() => {
    sendEvent({ event: AnalyticsEvents.RegistrationPageView });
  }, []);

  return authStore.preferredAuthType === AuthType.email ? (
    <SignUpFormByEmail />
  ) : (
    <SignUpFormByPhone />
  );
});
