import type { LocalStorageValues } from './_types';
import { StorageService } from './storage.service';

export class LocalStorageService extends StorageService<LocalStorageValues> {
  constructor() {
    super(localStorage);
  }
}

export const localStorageService = new LocalStorageService();
