import { lazy } from 'react';

import { ActiveChatContent } from './chat';
import { routerCollection } from '@constants';
import { Navigate, type RouteObject } from 'react-router-dom';

import { lazyDelay } from '@shared/utils';

const EntryPage = lazy(() => import('./EntryPage'));

const Chat = lazyDelay(() => import('./chat'));

export const privateChatsRouter: RouteObject = {
  path: routerCollection.privateChats.root,
  element: <EntryPage />,
  children: [
    {
      index: true,
      element: <Navigate to={routerCollection.privateChats.chats} />,
    },

    {
      path: routerCollection.privateChats.chats,
      element: <Chat />,

      children: [
        {
          path: routerCollection.privateChats.activeChat,
          element: <ActiveChatContent />,
        },
      ],
    },
  ],
};
