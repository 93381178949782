import type { TargetAndTransition } from 'framer-motion';

export const rootAnimate: TargetAndTransition = {
  maxHeight: 60,
  opacity: 1,
};

export const rootInitial = {
  opacity: 0,
  maxHeight: 0,
};
