import { RefObject } from 'react';

import { PrivateChatMessage } from '../private-chat-message';
import { VirtualItem } from '@tanstack/react-virtual';
import { IMessageListController } from '@units';

import { observer } from '@shared/libs/mobx';

import { Each } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

export interface EachMessageProps {
  items: VirtualItem[];
  scrollAreaRef: RefObject<HTMLElement>;
  messageListController: IMessageListController;
  setupItemNode: (node: HTMLElement | null) => void;
  isActiveSticky: (index: number) => boolean;
}

const _EachMessage = observer((props: EachMessageProps) => {
  const { scrollAreaRef, messageListController, setupItemNode, isActiveSticky, items } = props;

  return (
    <Each data={items}>
      {(item: VirtualItem) => {
        return (
          <PrivateChatMessage
            key={item.key}
            item={item}
            messageListController={messageListController}
            data-index={item.index}
            scrollAreaRef={scrollAreaRef}
            setupNode={setupItemNode}
            isActiveSticky={isActiveSticky}
          />
        );
      }}
    </Each>
  );
});

export const EachMessage = mergeComponentsWithName('EachMessage', _EachMessage);
