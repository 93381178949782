import { ReactNode, useEffect, useMemo } from 'react';

import { Content } from './content';
import { ContextMenuProvider, useContextMenuContext } from './context-menu.context';
import { ContextMenuController } from './context-menu.controller';
import { Item } from './item';
import { Menu } from './menu';
import { Separator } from './separator';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

export interface ContextMenuProviderProps {
  children: ReactNode;
}

const ShowComponent = observer(() => {
  const { controller } = useContextMenuContext();

  return <>{controller.component && controller.component}</>;
});

const _ContextMenu = observer((props: ContextMenuProviderProps) => {
  const { children } = props;

  const controller = useMemo(() => new ContextMenuController(), []);

  useEffect(() => {
    return () => {
      controller.dispose();
    };
  }, []);

  return (
    <ContextMenuProvider value={{ controller }}>
      {children}
      <ShowComponent />
    </ContextMenuProvider>
  );
});

export const ContextMenu = mergeComponentsWithName('ContextMenuProvider', _ContextMenu, {
  Menu,
  Content,
  Item,
  Separator,
});
