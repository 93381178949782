import type { ButtonHTMLAttributes, HTMLAttributeAnchorTarget, ReactNode } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import cl from 'classnames';

import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('btn');

const button = cva(rootClass, {
  variants: {
    variant: {
      ghost: appendClass('--variant-ghost'),
      'ghost-white': appendClass('--variant-ghost-white'),
      primary: appendClass('--variant-primary'),
      secondary: appendClass('--variant-secondary'),
      outline: appendClass('--variant-outline'),
      tinted: appendClass('--variant-tinted'),
      gray: appendClass('--variant-gray'),
      'dark-gray': appendClass('--variant-dark-gray'),
      'secondary-outline': appendClass('--variant-secondary-outline'),
      black: appendClass('--variant-black'),
      red: appendClass('--variant-red'),
      unset: null,
    },
    size: {
      xs: appendClass('--size-xs'),
      sm: appendClass('--size-sm'),
      md: appendClass('--size-md'),
      lg: appendClass('--size-lg'),
      icon: appendClass('--size-icon'),
      'icon-xs': appendClass('--size-icon-xs'),
    },
    rounded: {
      xs: appendClass('--rounded-xs'),
      sm: appendClass('--rounded-sm'),
      md: appendClass('--rounded-md'),
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
    rounded: 'md',
  },
});

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof button> {
  slotBefore?: ReactNode;
  slotAfter?: ReactNode;
  fullWidth?: boolean;
  linkHref?: string;
  linkTarget?: HTMLAttributeAnchorTarget;
}

const _Button = observer((props: ButtonProps) => {
  const {
    size,
    variant,
    rounded,
    className,
    slotBefore,
    slotAfter,
    fullWidth = false,
    children,
    type = 'button',
    linkHref,
    linkTarget = '_blank',
    ...restProps
  } = props;

  const rootClasses = button({
    size,
    variant,
    rounded,
    className: cl(
      {
        [appendClass('--fullWidth')]: fullWidth,
      },
      className,
    ),
  });

  return (
    <button className={rootClasses} type={type} {...restProps}>
      {linkHref && <a href={linkHref} className={appendClass('-anchor')} target={linkTarget} />}
      {slotBefore && <span className={appendClass('-slot')}>{slotBefore}</span>}
      {children}
      {slotAfter && <span className={appendClass('-slot')}>{slotBefore}</span>}
    </button>
  );
});

export const ButtonNew = mergeComponentsWithName('Button', _Button);
