export const blockMainType = {
  popular: 'popular',
  mainBanner: 'mainBanner',
  popularCommunity: 'popularCommunity',
  yourChoiceCommunity: 'yourChoiceCommunity',
  yourChoice: 'yourChoice',
  banner: 'banner',
  nearestEvent: 'nearestEvent',
  lastViewed: 'lastViewed',
  ticker: 'ticker',
} as const;
