import { useCallback, useLayoutEffect, useRef } from 'react';

import { modalStore } from '@components';
import { EMPTY_TAGS_PATTERN } from '@constants';
import type { EmojiDetails, UploadedFilesModalDetailsSubmit } from '@features-new';
import { UploadedFilesModal } from '@features-new';
import type { Editor } from '@tiptap/react';
import { useMediaRecorderContext, useMessageTransmitterContext } from '@units';
import { autorun } from 'mobx';
import { useForm } from 'react-hook-form';

import { useRootStore } from '@core/store';

import type { UploadedFile } from '@shared/UI';

import { useLatestCallback } from '@shared/hooks';
import { removeEmptyPTags, replaceMentionWithId } from '@shared/utils';

type MessageForm = {
  message: string;
};

interface UseFormControllerProps {
  chatId: string | null;
}

export const useFormController = (props: UseFormControllerProps) => {
  const { chatId } = props;

  const { communityMessagesStore, communityChatsStore } = useRootStore();

  const { messageTransmitter } = useMessageTransmitterContext();

  const editorRef = useRef<Editor>();

  const { videoRecordOptions, isRecording } = useMediaRecorderContext();

  const { control, handleSubmit, getValues, watch } = useForm<MessageForm>({
    defaultValues: {
      message: '',
    },
  });

  const messageText = watch('message');

  const onSubmit = handleSubmit(({ message }) => {
    if (message.replace(EMPTY_TAGS_PATTERN, '').length === 0) return;

    const parsedMessage = replaceMentionWithId(removeEmptyPTags(message));

    if (messageTransmitter.editableMessage) {
      messageTransmitter.editableMessage.editMessage(parsedMessage);
      messageTransmitter.clearEditableMessage();
    } else if (messageTransmitter.replyMessage) {
      if (chatId) {
        communityMessagesStore.sendMessageEmitter(
          parsedMessage,
          chatId,
          messageTransmitter.replyMessage.id,
        );
        messageTransmitter.clearReplyMessage();
      }
    } else {
      if (chatId) {
        communityMessagesStore.sendMessageEmitter(parsedMessage, chatId);
      }
    }

    editorRef?.current?.chain().setContent('', true).run();
  });

  const onSelectEmoji = useLatestCallback((emoji: EmojiDetails) => {
    editorRef?.current?.chain().focus().insertContent(emoji.native).run();
  });

  const onSubmitFileModal = useCallback(
    async ({ message, files }: UploadedFilesModalDetailsSubmit) => {
      const currentChat = communityChatsStore.activeChat;
      if (currentChat) {
        await currentChat
          .uploadChatAttachments({
            message,
            files: files.map((file) => file.originalFile),
          })
          .finally(() => {
            modalStore.closeAll();
          });
      }
    },
    [communityChatsStore.activeChat],
  );

  const onChangeUpload = (files: UploadedFile[]) => {
    const defaultValue = getValues('message');
    modalStore.activateModal({
      component: (
        <UploadedFilesModal
          onSubmit={onSubmitFileModal}
          isNeedToUnmount
          files={files}
          defaultValue={defaultValue}
        />
      ),
    });
  };

  const onSubmitByEnter = () => {
    onSubmit();
  };

  const canShowSendButton =
    messageText.length > 7 || messageTransmitter.replyMessage || messageTransmitter.editableMessage;

  useLayoutEffect(() => {
    const reaction = autorun(() => {
      if (messageTransmitter.editableMessage && !messageTransmitter.replyMessage) {
        editorRef?.current
          ?.chain()
          .setContent(messageTransmitter.editableMessage.message, true)
          .run();
      }

      if (!messageTransmitter.editableMessage) {
        editorRef?.current?.chain().setContent('', true).run();
      }
    });

    return () => {
      reaction();
    };
  }, []);

  return {
    messageTransmitter,
    control,
    editorRef,
    canShowSendButton,
    videoRecordOptions,
    isRecording,
    onSubmit,
    onSelectEmoji,
    onChangeUpload,
    onSubmitByEnter,
  };
};
