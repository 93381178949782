import { z } from 'zod';

const imageFileSchema = z.object({
  id: z.string(),
  name: z.string(),
  size: z.number(),
  type: z.string(),
  originalFile: z.instanceof(File),
  url: z.string(),
});

export const privateInformationSchema = z.object({
  name: z.string().nonempty({ message: 'errors.required-field' }),
  image: z.array(imageFileSchema),
});

export type PrivateInformationSchema = z.infer<typeof privateInformationSchema>;
