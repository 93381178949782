import { useEffect, useState } from 'react';

const browserPatterns: Record<string, RegExp> = {
  Firefox: /firefox/i,
  Chrome: /chrome/i,
  Safari: /safari/i,
  Edge: /edge/i,
  InternetExplorer: /msie|trident/i,
};

export const useBrowser = (): string => {
  const [browser, setBrowser] = useState<string>('');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    for (const [browserName, pattern] of Object.entries(browserPatterns)) {
      if (pattern.test(userAgent)) {
        setBrowser(browserName);
        return;
      }
    }
  }, []);

  return browser;
};
