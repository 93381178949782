import type { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import { SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

type ContentProps = {
  children: ReactNode;
};

export const Content = observer(function Content(props: ContentProps) {
  const { children } = props;

  return (
    <div className={s.content}>
      <div className={s.logoContainer}>
        <SvgIcon type="logo" />
      </div>
      <div className={s.contentContainer}>{children}</div>
    </div>
  );
});
