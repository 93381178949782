import { FormFields } from '@components';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import type { Control, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@shared/UI';

import s from './styles.module.scss';

type PrivacyPolicyProps = {
  control: Control<any>;
  isAgreeRulesError?: FieldError;
  emailSubscriptionError?: FieldError;
};

export const PrivacyPolicy = observer(function Terms(props: PrivacyPolicyProps) {
  const { control, isAgreeRulesError, emailSubscriptionError } = props;

  const { t } = useTranslation();

  return (
    <>
      <Box my={[24, 0]}>
        <FormFields.Checkbox name="isAgreeRules" control={control} error={isAgreeRulesError}>
          <span className={s.privacyPolicy}>
            <span className={s.baseText}>{t('registration.pp-tc')}</span>

            <a
              className={cl(s.link, s.linkPurple)}
              href="https://crew-c.com/public-offer-uk.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {t('shared-content.terms')}
            </a>

            <span className={s.baseText}>{t('shared-content.and')}</span>

            <a
              className={cl(s.link, s.linkBlue)}
              href="https://crew-c.com/privacy-policy-uk.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {t('shared-content.privacy-policy')}
            </a>
          </span>
        </FormFields.Checkbox>
      </Box>

      <Box my={[15, 0]}>
        <FormFields.Checkbox
          name="emailSubscription"
          control={control}
          error={emailSubscriptionError}
        >
          {t('registration.email-subscription')}
        </FormFields.Checkbox>
      </Box>
    </>
  );
});
