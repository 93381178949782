import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { toast } from 'react-toastify';

import type {
  CreatePaymentIntentDto,
  CreateSubscriptionDto,
  StripePaymentMethod,
} from '@core/repositories';
import { PaymentsRepository } from '@core/repositories';
import { getHttpError } from '@core/services/http';

export class PaymentsStore {
  paymentMethods: StripePaymentMethod[] = [];

  pk: string | null = null;

  private paymentsRepository = new PaymentsRepository();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'PaymentsStore',
      properties: ['paymentMethods', 'pk'],
    });
  }

  buyFreeCommunity = async (id: number) => {
    try {
      const data = await this.paymentsRepository.buyFreeCommunity(id);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  getPaymentConfig = async () => {
    try {
      const data = await this.paymentsRepository.getPaymentConfig();

      runInAction(() => {
        this.pk = data.publishableKey;
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  createPaymentIntent = async (body: CreatePaymentIntentDto) => {
    try {
      const data = await this.paymentsRepository.createPaymentIntent(body);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  createSubscription = async (body: CreateSubscriptionDto) => {
    try {
      const data = await this.paymentsRepository.createSubscription(body);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  createPaymentMethod = async (token: string) => {
    try {
      const data = await this.paymentsRepository.createPaymentMethod(token);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  getPaymentMethods = async () => {
    try {
      const data = await this.paymentsRepository.getPaymentMethods();

      runInAction(() => {
        this.paymentMethods = data.userHasPaymentMethod ? data.paymentMethods : [];
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  removePaymentMethod = async (id: string) => {
    try {
      const data = await this.paymentsRepository.removePaymentMethod(id);

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  unsubscribe = async (id: string) => {
    try {
      await this.paymentsRepository.unsubscribe(id);
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  enableCourseTrial = async (courseId: number, priceCourseId: number) => {
    try {
      await this.paymentsRepository.enableCourseTrial(courseId, priceCourseId);
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  disposer = () => {
    clearPersistedStore(this);
  };
}
