import type { ReactNode } from 'react';

import { ChatLayoutProvider, useChatLayout } from '../../libs';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { Box, Group, SvgIcon } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface ChatLayoutProps {
  sidebarSlot: ReactNode;
  chatSlot: ReactNode;
  headerSlot: ReactNode;
  footerSlot?: ReactNode;
}

const ChatLayoutComponent = observer((props: ChatLayoutProps) => {
  const { sidebarSlot, chatSlot, headerSlot, footerSlot = null } = props;

  const { isMobile, isOpenSidebar, onCloseSidebar, onOpenSidebar, chatContainer } = useChatLayout();

  const rootClasses = cl(s.chatLayout, {
    [s.chatLayoutMobile]: isMobile,
  });

  return (
    <Box className={rootClasses} data-sidebar-opened={isOpenSidebar}>
      <Box className={s.chatLayoutSidebar}>
        <Box className={s.chatLayoutSidebarContent}>{sidebarSlot}</Box>
        {isMobile && (
          <Box className={s.chatLayoutSidebarFooter}>
            <button onClick={onCloseSidebar} className={s.chatLayoutSidebarFooterBtn}>
              <SvgIcon type="elongated-arrow" />
            </button>
          </Box>
        )}
      </Box>
      <Box className={s.chatLayoutContent}>
        <Group wrap="nowrap" gap="xl" className={s.chatLayoutContentHeader}>
          {isMobile && (
            <button className={s.chatLayoutContentHeaderBtn} onClick={onOpenSidebar}>
              <SvgIcon type="arrow" />
            </button>
          )}

          <Box className={s.chatLayoutContentHeaderContent}>{headerSlot}</Box>
        </Group>
        <Box innerRef={chatContainer} id="chat-container" className={s.chatLayoutContentMessages}>
          {chatSlot}

          {footerSlot}
        </Box>
      </Box>
    </Box>
  );
});

const _ChatLayout = observer((props: ChatLayoutProps) => {
  return (
    <ChatLayoutProvider>
      <ChatLayoutComponent {...props} />
    </ChatLayoutProvider>
  );
});

export const ChatLayout = mergeComponentsWithName('ChatLayout', _ChatLayout);
