import { LanguageSwitcher } from '@components';
import { observer } from 'mobx-react-lite';

import s from './styles.module.scss';

export const Header = observer(function Header() {
  return (
    <div className={s.header}>
      <div className={s.container}>
        <LanguageSwitcher />
      </div>
    </div>
  );
});
