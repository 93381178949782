import type { FormEventHandler, ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import { SubmitButton } from './submit-button';

import s from './styles.module.scss';

export type FormBoxProps = {
  children: ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
  isDisabledButton?: boolean;
};

export const FormBox = observer(function FormBox(props: FormBoxProps) {
  const { children, isDisabledButton, onSubmit } = props;

  return (
    <div className={s.formWrapper}>
      <form className={s.form} onSubmit={onSubmit}>
        {children}
        <SubmitButton disabled={isDisabledButton} />
      </form>
    </div>
  );
});
