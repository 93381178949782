import { makeAutoObservable, runInAction } from 'mobx';

import type { CommunityChannelStatus } from '@core/constants';
import { SocketEventsCollection } from '@core/constants';
import type { CommunityCategoryEntity, CreateCommunityChatDto } from '@core/repositories';
import { appSocket } from '@core/services/socket';

import type { ObjectValues } from '@shared/types';

interface ICategoryStore extends CommunityCategoryEntity {}

export interface ICategoryStoreOptions extends ICategoryStore {}

export class ChannelStore implements ICategoryStore {
  id: string;

  imageUrl: string;

  isAvailableForUser: boolean;

  isPinned: boolean;

  isProOnly: boolean;

  status: ObjectValues<typeof CommunityChannelStatus>;

  title: string;

  communityId: number;

  availableChatsCount: number;

  constructor({
    id,
    status,
    isAvailableForUser,
    isProOnly,
    title,
    isPinned,
    imageUrl,
    communityId,
    activeCommunicationItemsCount,
  }: ICategoryStoreOptions) {
    this.id = id;
    this.status = status;
    this.isAvailableForUser = isAvailableForUser;
    this.isProOnly = isProOnly;
    this.title = title;
    this.isPinned = isPinned;
    this.imageUrl = imageUrl;
    this.communityId = communityId;
    this.availableChatsCount = activeCommunicationItemsCount ?? 0;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  //#region --- Emitters

  connectToChannelEmitter = () => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.ConnectCategory, {
      categoryId: this.id,
    });
  };

  createChatEmitter = (dto: Omit<CreateCommunityChatDto, 'categoryId'>) => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.CreateCommunication, {
      ...dto,
      categoryId: this.id,
    });
  };

  togglePinCategoryEmitter = () => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.TogglePinningCategory, {
      categoryId: this.id,
    });
    runInAction(() => {
      this.isPinned = !this.isPinned;
    });
  };

  //#endregion --- Emitters

  //#region --- Actions

  updateChannelInfo = (details: Partial<CommunityCategoryEntity>) => {
    runInAction(() => {
      this.title = details.title || this.title;
      this.isPinned = details.isPinned || this.isPinned;
      this.status = details.status || this.status;
      this.isProOnly = details.isProOnly || this.isProOnly;
      this.isAvailableForUser = details.isAvailableForUser || this.isAvailableForUser;
      this.imageUrl = details.imageUrl || this.imageUrl;
    });
  };

  //#endregion --- Actions

  //#region --- Helpers

  get isEmpty() {
    return this.availableChatsCount === 0;
  }

  //#endregion --- Helpers
}
