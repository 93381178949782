import { useEffect } from 'react';

import { StopWordsForm } from '../stop-words-form';
import type { BaseModalProps } from '@components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Modal, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

interface StopWordsModalProps extends BaseModalProps {}

export const StopWordsModal = observer(function StopWordsModal(props: StopWordsModalProps) {
  const { ...restProps } = props;

  const { t } = useTranslation();

  const { streamChatStore } = useRootStore();

  useEffect(() => {
    streamChatStore.getStopWords();
  }, []);

  return (
    <Modal size="sm" centered withHeader={false} {...restProps}>
      <div className={s.stopWordsModal}>
        <TypographyNew variant="title-3" weight="bold">
          {t('modals.configure-stop-words.title')}
        </TypographyNew>

        <StopWordsForm
          stopWords={streamChatStore.stopWords}
          configStopWords={streamChatStore.configStopWords}
        />
      </div>
    </Modal>
  );
});
