import { Logo } from '@components';
import { observer } from 'mobx-react-lite';

export const LeftSection = observer(function LeftSection() {
  return (
    <div>
      <Logo />
    </div>
  );
});
