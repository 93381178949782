import { type ReactNode, useCallback } from 'react';

import { AccordionProvider } from './Accordion.context';
import type { AccordionValue } from './Accordion.types';
import { AccordionControl } from './accordion-control';
import { AccordionItem } from './accordion-item';
import { AccordionPanel } from './accordion-panel';
import { observer } from 'mobx-react-lite';

import { useUncontrolled } from '@shared/hooks';
import type { ElementProps } from '@shared/types';
import { componentName, mergeComponents } from '@shared/utils';

export interface AccordionProps<Multiple extends boolean = false>
  extends ElementProps<'div', 'value' | 'onChange' | 'defaultValue' | 'children'> {
  value?: AccordionValue<Multiple>;

  multiple?: Multiple;

  defaultValue?: AccordionValue<Multiple>;

  onChange?: (value: AccordionValue<Multiple>) => void;

  chevron?: boolean;

  children: ReactNode;
}

const _Accordion = observer(<Multiple extends boolean = false>(props: AccordionProps<Multiple>) => {
  const { value, defaultValue, multiple, onChange, chevron, ...restProps } = props;

  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    finalValue: multiple ? ([] as any) : '',
    onChange,
  });

  const isItemActive = useCallback(
    (itemValue: string) => {
      return Array.isArray(_value) ? _value.includes(itemValue) : itemValue === _value;
    },
    [_value],
  );

  const onChangeActiveItem = useCallback(
    (itemValue: string) => {
      const newValue: AccordionValue<Multiple> = Array.isArray(_value)
        ? _value.includes(itemValue)
          ? _value.filter((selectedValue) => selectedValue !== itemValue)
          : [..._value, itemValue]
        : itemValue === _value
        ? null
        : (itemValue as any);

      handleChange(newValue);
    },
    [_value, handleChange],
  );

  const contextValue = {
    onChange: onChangeActiveItem,
    isItemActive,
    chevron,
  };

  return <AccordionProvider value={contextValue} {...restProps} />;
});

export const Accordion = mergeComponents(_Accordion, {
  Item: AccordionItem,
  Control: AccordionControl,
  Panel: AccordionPanel,
  ...componentName('Accordion'),
});
