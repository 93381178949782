import { RefObject } from 'react';

import { CommunityMessageItemNew } from '../community-message-item-new';
import { VirtualItem } from '@tanstack/react-virtual';
import { IMessageListController } from '@units';
import { observer } from 'mobx-react-lite';

import { Each } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

export interface EachCommunityMessageProps {
  items: VirtualItem[];
  messageListController: IMessageListController;
  scrollAreaRef: RefObject<HTMLElement>;
  setupRowNode: (node: HTMLElement | null) => void;
  isActiveSticky: (index: number) => boolean;
}

const _EachCommunityMessage = observer((props: EachCommunityMessageProps) => {
  const { items, messageListController, setupRowNode, isActiveSticky, scrollAreaRef } = props;

  return (
    <Each data={items}>
      {(virtualRow) => {
        return (
          <CommunityMessageItemNew
            row={virtualRow}
            isActiveSticky={isActiveSticky}
            key={virtualRow.key}
            data-index={virtualRow.index}
            communityMessagesListController={messageListController}
            setupRef={setupRowNode}
            scrollAreaRef={scrollAreaRef}
          />
        );
      }}
    </Each>
  );
});

export const EachCommunityMessage = mergeComponentsWithName(
  'EachCommunityMessage',
  _EachCommunityMessage,
);
