export const contentLengthToSize = (contentLength: string | number | null) => {
  const val = contentLength && contentLength.toString();

  let size = '';

  if (val) {
    const fileSizeInMB = parseInt(val) / (1024 * 1024);

    if (fileSizeInMB >= 1) {
      size = `${fileSizeInMB.toFixed(2)} MB`;
    } else {
      const fileSizeInKB = parseInt(val) / 1024;
      size = `${fileSizeInKB.toFixed(2)} KB`;
    }
  } else {
    size = '0 MB';
  }

  return size;
};
