import { z } from 'zod';

export const forgotPasswordFirstStepSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'errors.required-field' })
    .email({ message: 'errors.invalid-email' }),
});

export type ForgotPasswordFirstStepSchema = z.infer<typeof forgotPasswordFirstStepSchema>;
