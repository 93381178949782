import type { ChangeEvent } from 'react';
import { useMemo } from 'react';

import { FormBox } from '../../_components';
import type { ChangePasswordSchema } from '../../_schemas';
import { changePasswordSchema } from '../../_schemas';
import { FormFields } from '@components';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import type { RegisterOptions, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useRootStore } from '@core/store';

import { useToggle } from '@shared/hooks';
import { transformOtp } from '@shared/utils';

const defaultValues: ChangePasswordSchema = {
  oldPassword: '',
  newPassword: '',
  firstOtp: '',
};

export const Form = observer(function Form() {
  const { t } = useTranslation();

  const { userStore } = useRootStore();

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<ChangePasswordSchema>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues,
  });

  const [isOtpVisible, toggleIsOtpVisible] = useToggle();
  const [isOtpValid, toggleIsOtpValid] = useToggle();

  const handleFirstStep = async (dto: ChangePasswordSchema) => {
    try {
      delete dto.firstOtp;
      await userStore.firstStepChangePassword(dto);
      toggleIsOtpVisible(true);
    } catch (e) {}
  };

  const handleSecondStep = async (otp: string) => {
    try {
      await userStore.secondStepChangePassword({ firstOtp: transformOtp(otp) });
      toggleIsOtpVisible(false);
      toggleIsOtpValid(false);
      reset(defaultValues);
      toast.success(t('my-account.personal-information.successfully-changed'));
    } catch (e) {}
  };

  const onSubmit: SubmitHandler<ChangePasswordSchema> = async (dto) => {
    if (dto.firstOtp) {
      await handleSecondStep(dto.firstOtp);
      return;
    }
    await handleFirstStep(dto);
  };

  const handleChangeOtp = (e: ChangeEvent<HTMLInputElement>) => {
    const otp = e.target.value;

    if (otp.length === 9 && !isOtpValid) {
      toggleIsOtpValid(true);
    }

    if ((!otp || otp.length < 9) && isOtpValid) {
      toggleIsOtpValid(false);
    }
  };

  const handleChangeFields = () => {
    if (isOtpVisible) {
      toggleIsOtpVisible(false);
      setValue('firstOtp', defaultValues.firstOtp);
    }
  };

  const otpRules: RegisterOptions = useMemo(
    () => ({
      onChange: handleChangeOtp,
    }),
    [handleChangeOtp],
  );

  const passRules: RegisterOptions = useMemo(
    () => ({
      onChange: handleChangeFields,
    }),
    [handleChangeFields],
  );

  const isDisabledButton = !isDirty || userStore.loader.isLoading || (isOtpVisible && !isOtpValid);

  return (
    <FormBox onSubmit={handleSubmit(onSubmit)} isDisabledButton={isDisabledButton}>
      <FormFields.Password
        control={control}
        name="oldPassword"
        label="inputs.old-password.label"
        placeholder="inputs.old-password.placeholder"
        error={errors.oldPassword}
        rules={passRules}
      />
      <FormFields.Password
        control={control}
        name="newPassword"
        label="inputs.new-password.label"
        placeholder="inputs.new-password.placeholder"
        error={errors.newPassword}
        rules={passRules}
      />
      {isOtpVisible && (
        <FormFields.OtpCode
          control={control}
          name="firstOtp"
          error={errors.firstOtp}
          rules={otpRules}
          hiddeResend
        />
      )}
    </FormBox>
  );
});
