import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { For, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

export type RightSectionProps = {
  priceList: string[];
};

export const RightSection = observer(function RightSection(props: RightSectionProps) {
  const { priceList } = props;

  const { t } = useTranslation();

  const renderPlanOptions = (item: string, index: number) => {
    return (
      <li key={`${item}-${index}`} className={s.rightSectionListItem}>
        <TypographyNew as="span" variant="body-4">
          {item}
        </TypographyNew>
      </li>
    );
  };

  return (
    <div className={s.rightSection}>
      <TypographyNew as="span" variant="body-2" weight="extra-bold" className={s.rightSectionTitle}>
        {t('my-account.my-subscription.plan-includes')}
      </TypographyNew>
      <ul className={s.rightSectionList}>
        <For data={priceList} render={renderPlanOptions} />
      </ul>
    </div>
  );
});
