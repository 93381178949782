import { createWebSocket } from '@services/web-socket';

import type { SocketEventsCollection } from '@core/constants';
import type {
  CommunityDispatch,
  CommunitySubscribe,
  NotificationSubscribe,
  PrivateChatDispatch,
  PrivateChatSubscribe,
  StreamChatDispatch,
  StreamChatSubscribe,
} from '@core/repositories';

import { getCookie } from '@shared/utils';

const appSocketPath = () => {
  const access: string = getCookie('access') || import.meta.env.VITE_COOCKIE_DEV_TOKEN;

  const path = `${import.meta.env.VITE_SOCKET_URL}?access=${access}`;

  return path;
};

type AppSocketDispatch = CommunityDispatch & PrivateChatDispatch & StreamChatDispatch;

type AppSocketSubscribe = CommunitySubscribe &
  PrivateChatSubscribe & {
    [SocketEventsCollection.CommonEvents.Subscribers.Connected]: () => void;
  } & NotificationSubscribe &
  StreamChatSubscribe;

export const appSocket = createWebSocket<AppSocketDispatch, AppSocketSubscribe>(appSocketPath);
