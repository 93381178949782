import { observer } from 'mobx-react-lite';

import { cl } from '@shared/libs/classnames';

import { Stack, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

interface EmptyContentProps {
  title?: string;
  subtitle?: string;
  image?: string;
  className?: string;
}

export const EmptyContent = observer(function EmptyContent(props: EmptyContentProps) {
  const { title, subtitle, image, className } = props;

  return (
    <div className={cl(s.emptyContent, className)}>
      <Stack gap="sm">
        {image && <img src={image} className={s.emptyContentIcon} alt="empty" />}
        {title && (
          <TypographyNew variant="title-4" weight="bold">
            {title}
          </TypographyNew>
        )}
        {subtitle && (
          <TypographyNew textAlign="center" variant="body-2" colorSchema="neutral-200">
            {subtitle}
          </TypographyNew>
        )}
      </Stack>
    </div>
  );
});
