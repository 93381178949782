import type { KeyboardEvent, ReactNode, Ref } from 'react';
import { useRef } from 'react';

import type { TypographyNewProps } from '../../common';
import { TypographyNew } from '../../common';
import { EventKeys } from '@constants';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { useMergedRef } from '@shared/hooks';
import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('context-menu-item');

export interface MenuItemProps extends Omit<ElementProps<'li'>, 'children'> {
  label: string | (() => string);

  icon?: ReactNode;

  innerRef?: Ref<HTMLLIElement>;

  customContent?: () => ReactNode | ReactNode;

  typographyProps?: Partial<TypographyNewProps>;
}

const _MenuItem = observer((props: MenuItemProps) => {
  const { label, icon, innerRef, typographyProps, className, customContent, ...restProps } = props;

  const itemRef = useRef<HTMLLIElement>(null);

  const ref = useMergedRef(innerRef, itemRef);

  const rootClasses = cl(rootClass, className);

  const innerContent = (
    <>
      {icon && <span className={appendClass('-icon')}>{icon}</span>}
      <TypographyNew truncate as="span" variant="body-2" {...typographyProps}>
        {typeof label === 'function' ? label() : label}
      </TypographyNew>
    </>
  );

  const handleOnKeyUp = (event: KeyboardEvent<HTMLLIElement>) => {
    if (event.key === EventKeys.Enter) {
      itemRef?.current?.click();
    }
  };

  const content = customContent
    ? typeof customContent === 'function'
      ? customContent()
      : customContent
    : innerContent;

  return (
    <li
      className={rootClasses}
      onKeyUp={handleOnKeyUp}
      role="button"
      tabIndex={0}
      ref={ref}
      {...restProps}
    >
      {content}
    </li>
  );
});

export const MenuItem = mergeComponentsWithName('MenuItem', _MenuItem);
