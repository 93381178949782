import { useEffect, useLayoutEffect } from 'react';

import { toast } from 'react-toastify';

import { appSocket } from '@core/services/socket';
import { useRootStore } from '@core/store';

enum ToastKey {
  AppOffline = 'app_offline',
  AppOnline = 'app_online',
}

const useVisibilityChange = () => {
  const { authStore } = useRootStore();

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && appSocket.isClosed && authStore.isUserHasAccess) {
      window.location.reload();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

const useOnlineState = () => {
  const { appSettingsStore } = useRootStore();

  useEffect(() => {
    appSettingsStore.listenOnline(() => {
      window.location.reload();
      toast.dismiss(ToastKey.AppOffline);
      toast.success('Internet connection restored', {
        toastId: ToastKey.AppOnline,
        style: {
          width: 300,
        },
      });
    });
    appSettingsStore.listenOffline(() => {
      toast.dismiss(ToastKey.AppOnline);
      toast.error('Internet connection lost', {
        toastId: ToastKey.AppOffline,
        style: {
          width: 300,
        },
      });

      appSocket.disconnect();
    });

    return () => {
      appSettingsStore.unmountDisposer();
    };
  }, []);
};

const useSocketConnection = () => {
  const { authStore, initConnectedSocketEvents } = useRootStore();

  useEffect(() => {
    appSocket.subscribe('connected', () => {
      initConnectedSocketEvents();
    });
    if (authStore.isUserHasAccess) {
      appSocket.connect();
      return;
    }
    if (!authStore.isUserHasAccess) {
      appSocket.disconnect();
    }
  }, [authStore.isUserHasAccess]);

  useEffect(() => {
    return () => {
      appSocket.disconnect();
    };
  }, []);
};

export const useAppLifeCycle = () => {
  useVisibilityChange();
  useSocketConnection();
  useOnlineState();
};
