import type { CSSProperties } from 'react';

import { ScrollWrapper } from './scroll-wrapper';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type {
  LazyLoadImageProps,
  ScrollPosition as ScrollPositionProps,
} from 'react-lazy-load-image-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Logo from '@assets/icons/logo/logo-with-gradient.svg';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName, withMediaUrl } from '@shared/utils';

import './styles.scss';

import 'react-lazy-load-image-component/src/effects/opacity.css';

const { rootClass, appendClass } = classNames('image');

const image = cva(rootClass, {
  variants: {
    rounded: {
      none: '',
      sm: appendClass('--rounded-sm'),
      md: appendClass('--rounded-md'),
      xl: appendClass('--rounded-xl'),
    },
  },

  defaultVariants: {
    rounded: 'none',
  },
});

export type ScrollPosition = ScrollPositionProps;

export interface ImageProps extends Omit<LazyLoadImageProps, 'src'>, VariantProps<typeof image> {
  src: string;
  imageClassName?: string;
  isMediaUrl?: boolean;
  objectFit?: CSSProperties['objectFit'];
}

const _Image = observer((props: ImageProps) => {
  const {
    isMediaUrl = false,
    src,
    effect = 'opacity',
    className,
    visibleByDefault = true,
    placeholderSrc = Logo,
    imageClassName,
    style,
    wrapperClassName,
    objectFit = 'cover',
    rounded,
    ...restProps
  } = props;

  const _src = isMediaUrl ? withMediaUrl(src) : src;

  const rootClasses = image({ rounded, className });

  const imageClasses = cl(appendClass('-image'), imageClassName);

  const wrapperClasses = cl(appendClass('-wrapper'), wrapperClassName);

  const imageStyle = {
    '--ui-image-object-fit': objectFit,
    willChange: 'opacity',
    ...style,
  };

  return (
    <span className={rootClasses}>
      <LazyLoadImage
        src={_src}
        effect={effect}
        visibleByDefault={visibleByDefault}
        placeholderSrc={placeholderSrc}
        className={imageClasses}
        style={imageStyle}
        wrapperClassName={wrapperClasses}
        {...restProps}
      />
    </span>
  );
});

export const Image = mergeComponentsWithName('Image', _Image, {
  Wrapper: ScrollWrapper,
});
