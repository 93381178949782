import { Notifications } from './notifications';
import { ProfileDropdown } from './profile-dropdown';
import { SidebarTrigger } from './sidebar-trigger';
import { LanguageSwitcher } from '@components';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import s from './styles.module.scss';

export const RightSection = observer(function RightSection() {
  const { appLayoutStore } = useRootStore();

  return (
    <div className={s.rightSection}>
      <div className={s.rightSectionContainer}>
        <Notifications />
        {appLayoutStore.isMobileSidebar && <SidebarTrigger />}
        <LanguageSwitcher />
        <ProfileDropdown />
      </div>
    </div>
  );
});
