import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { MentionVariants } from '@constants';
import { useObserver } from 'mobx-react-lite';

import type { CommunityMentionEntity } from '@core/repositories';
import { useRootStore } from '@core/store';

import { Avatar, TypographyNew } from '@shared/UI';

import { withMediaUrl } from '@shared/utils';

import './styles.scss';

interface MentionListProps {
  query: string;
  command: ({ id, label }: { id: number | string; label: string }) => void;
}

export const MentionList = forwardRef((props: MentionListProps, ref) => {
  const { query, command } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const { communityMessagesStore, communityChatsStore } = useRootStore();

  const activeChat = useObserver(() => {
    return communityChatsStore.activeChat;
  });

  const mentions = useObserver(() => {
    return communityMessagesStore.mentions;
  });

  const searchUserInChatEmitter = useObserver(() => {
    return communityMessagesStore.searchUserInChatEmitter;
  });

  const selectItem = (index: number) => {
    const user = mentions?.[index];

    if (user) {
      command({ id: user.id, label: user.name });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + mentions?.length - 1) % mentions?.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % mentions?.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => {
    if (query.length > 1 && activeChat) {
      searchUserInChatEmitter({
        communicationItemId: activeChat.id,
        name: query,
        limit: 1000,
        page: 1,
      });
    }
  }, [query]);

  useEffect(() => setSelectedIndex(0), [mentions]);

  useImperativeHandle(ref, () => ({
    // @ts-ignore
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        event.stopPropagation();
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    query.length > 1 && (
      <div className="mention-list">
        {!!mentions?.length &&
          mentions?.map((mention: CommunityMentionEntity, index: number) => (
            <button
              className={`mention-list-item ${
                index === selectedIndex ? 'is-selected-mention' : ''
              }`}
              key={mention.id}
              onClick={() => selectItem(index)}
            >
              {mention.name === MentionVariants.Here ||
              mention.name === MentionVariants.Everyone ? null : (
                <Avatar size="sm" src={withMediaUrl(mention.image)} />
              )}
              <TypographyNew
                as="span"
                variant="body-3"
                colorSchema="neutral-200"
                className="mention-list-item-name"
              >
                {mention.name}
              </TypographyNew>
            </button>
          ))}
      </div>
    )
  );
});

MentionList.displayName = 'MentionList';
