import i18n from 'i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';

import type { UploadedFile } from '@shared/UI';

export type CreateChannelForm = {
  file: UploadedFile[];
  name: string;
};

const uploadedFileSchema = z.object({
  id: z.string(),
  name: z.string(),
  size: z.number(),
  type: z.string(),
  originalFile: z.instanceof(File),
  url: z.string(),
  progress: z.number().optional(),
});
export const createChannelSchema = z.object({
  file: z.array(uploadedFileSchema).refine((value) => {
    if (value.length === 0) {
      toast.error(i18n.t('errors.create-channel-image'));
      return false;
    }
    return true;
  }),
  name: z.string().min(1, {
    message: i18n.t('errors.min-field-length', { count: 1 }),
  }),
});

export const updateChannelSchema = z.object({
  file: z.array(uploadedFileSchema),
  name: z.string().min(1, {
    message: i18n.t('errors.min-field-length', { count: 1 }),
  }),
});
