import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import { useRootStore } from '@core/store';

import { ForgotPasswordSecondStepForm } from '@features/auth';

export const ForgotPasswordSecondStep = observer(function ForgotPasswordSecondStep() {
  const { authStore } = useRootStore();

  if (authStore.defaultFormValues.forgotPasswordFirstStep.email === '') {
    return <Navigate to={routerCollection.auth.login} replace />;
  }

  return <ForgotPasswordSecondStepForm />;
});
