export const renderCorrectUaNounInPlural = (
  amount: number,
  noun: string,
  secondOption?: boolean,
) => {
  if (amount % 100 >= 11 && amount % 100 <= 19) {
    return `${noun}ів`;
  } else {
    switch (amount % 10) {
      case 1:
        return noun;
      case 2:
      case 3:
      case 4:
        return secondOption ? `${noun}і` : `${noun}и`;
      default:
        return `${noun}ів`;
    }
  }
};
