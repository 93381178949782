import type { ReactNode } from 'react';

import type { FormErrorMessageProps, FormLabelProps } from '../_components';
import { FormErrorMessage, FormLabel } from '../_components';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import type { Control, FieldError, FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { InputProps as InputUIProps } from '@shared/UI';
import { Input as InputUI } from '@shared/UI';

import s from './styles.module.scss';

export type InputProps<TFormValues extends FieldValues> = {
  name: FieldPath<TFormValues>;
  label?: ReactNode;
  rules?: RegisterOptions;
  control?: Control<TFormValues>;
  error?: FieldError;
  withoutPadding?: boolean;

  labelElementProps?: Omit<FormLabelProps, 'content' | 'htmlFor'>;
  errorElementProps?: Omit<FormErrorMessageProps, 'message'>;
  hideTextError?: boolean;
} & InputUIProps;

export const Input = observer(function FormInput<TFormValues extends FieldValues>(
  props: InputProps<TFormValues>,
) {
  const {
    name,
    label,
    control,
    error,
    rules,
    labelElementProps,
    errorElementProps,
    placeholder,
    hidden,
    onChange,
    withoutPadding,
    hideTextError = false,
    ...restProps
  } = props;

  const { t } = useTranslation();

  const hasLabel = !!label;

  const placeholderWithTranslation = placeholder && t(placeholder as any);

  return (
    <div
      className={cl(s.formInput, withoutPadding ? s.formInputWithoutPadding : '')}
      hidden={hidden}
    >
      <div
        className={cl(s.textContainer, !hasLabel && !error ? s.textContainerWithoutPadding : '')}
      >
        {hasLabel && <FormLabel content={label} htmlFor={name} {...labelElementProps} />}
        {error && !hideTextError && (
          <FormErrorMessage message={error?.message} {...errorElementProps} />
        )}
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <InputUI
            id={name}
            hasError={!!error}
            placeholder={placeholderWithTranslation}
            autoComplete="off"
            hidden={hidden}
            {...field}
            {...restProps}
          />
        )}
      />
    </div>
  );
});
