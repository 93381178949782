import { Form } from './form';
import { useBrowser } from '@hooks';
import { MediaRecorderProvider } from '@units';
import { AnimatePresence } from 'framer-motion';

import { MessageViewType } from '@core/constants';
import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName, randomUuid } from '@shared/utils';

const createMediaFile = (blob: Blob, fileType: string) => {
  const name = `comms-${randomUuid()}`;
  const file = new File([blob], name, {
    type: fileType,
  });

  return file;
};

interface CommunityMessageFormProps {
  chatId: string | null;
  isVisibleForm?: boolean;
}

const _CommunityMessageForm = observer((props: CommunityMessageFormProps) => {
  const { chatId, isVisibleForm = false } = props;

  const browser = useBrowser();

  const { communityChatsStore } = useRootStore();

  const handleStopAudioRecording = (_blobUrl: string, blob: Blob) => {
    const mimeType = browser && browser === 'Safari' ? 'audio/mp4;codecs=mp4a' : 'audio/webm';

    const file = createMediaFile(blob, mimeType);
    const chat = communityChatsStore.activeChat;
    if (chat) {
      chat.uploadChatAttachments({
        message: '',
        type: MessageViewType.Audio,
        files: [file],
      });
    }
  };

  const handleStopVideoRecording = (_blobUrl: string, blob: Blob) => {
    const mimeType = browser && browser === 'Safari' ? 'video/mp4;codecs=avc1' : 'video/webm';

    const file = createMediaFile(blob, mimeType);
    const chat = communityChatsStore.activeChat;

    if (chat) {
      chat.uploadChatAttachments({
        message: '',
        type: MessageViewType.Video,
        files: [file],
      });
    }
  };

  return (
    <MediaRecorderProvider
      onStopAudio={handleStopAudioRecording}
      onStopVideo={handleStopVideoRecording}
    >
      <AnimatePresence>{isVisibleForm && <Form chatId={chatId} />}</AnimatePresence>
    </MediaRecorderProvider>
  );
});

export const CommunityMessageForm = mergeComponentsWithName(
  'CommunityMessageForm',
  _CommunityMessageForm,
);
