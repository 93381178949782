import { useEffect, useState } from 'react';

import { localStorageService } from '@services/storage-service';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import Countdown from 'react-countdown';
import type { CountdownRenderProps } from 'react-countdown/dist/Countdown';
import { useTranslation } from 'react-i18next';

import { Button, SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

export type ResendButtonProps = {
  onClickResend?: () => Promise<void>;
};

export const ResendButton = observer(function ResendButton(props: ResendButtonProps) {
  const { onClickResend } = props;

  const resendDate = localStorageService.getItem('resendCode');

  const [isDisabled, setIsDisabled] = useState(Boolean(resendDate));

  const { t } = useTranslation();

  const handleCompleteTimer = () => {
    localStorageService.removeItem('resendCode');

    setIsDisabled(false);
  };

  const handleClickResend = async () => {
    try {
      await onClickResend?.();

      const time = dayjs().add(3, 'minute').toDate();
      localStorageService.setItem('resendCode', time);
      setIsDisabled(true);
    } catch (e) {}
  };

  useEffect(() => {
    return handleCompleteTimer;
  }, []);

  const getButtonElement = (params?: CountdownRenderProps) => {
    const { formatted } = params || {};

    const timer = formatted && `${formatted.minutes}:${formatted.seconds}`;

    return (
      <Button
        variant="text"
        size="xs"
        prefixIcon={<SvgIcon type="resend" className={s.icon} />}
        className={s.resendButton}
        disabledAnimation
        disabled={isDisabled}
        onClick={handleClickResend}
      >
        {t('shared-content.resend-code')} {timer}
      </Button>
    );
  };

  if (isDisabled && resendDate) {
    return (
      <Countdown date={resendDate} renderer={getButtonElement} onComplete={handleCompleteTimer} />
    );
  }

  return <>{getButtonElement()}</>;
});
