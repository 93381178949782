import { FormBox } from '../../_components';
import { FormFields } from '@components';
import { observer } from 'mobx-react-lite';

import { useViewController } from './useViewController';

export const Form = observer(function Form() {
  const { onSubmit, errors, control, isDisabledButton, otpRules, isOtpVisible, emailAndPassRules } =
    useViewController();

  return (
    <FormBox onSubmit={onSubmit} isDisabledButton={isDisabledButton}>
      <FormFields.Input
        control={control}
        name="email"
        label="inputs.email.label"
        placeholder="inputs.email.placeholder"
        error={errors.email}
        rules={emailAndPassRules}
      />
      <FormFields.Password
        control={control}
        name="password"
        label="inputs.password.label"
        placeholder="inputs.password.placeholder"
        error={errors.password}
        rules={emailAndPassRules}
      />
      {isOtpVisible && (
        <FormFields.OtpCode
          control={control}
          name="firstOtp"
          error={errors.firstOtp}
          rules={otpRules}
          hiddeResend
        />
      )}
    </FormBox>
  );
});
