import { useCallback, useMemo, useState } from 'react';
import type { MutableRefObject } from 'react';

import { CourseRoadmapTile } from './course-roadmap-tile';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type {
  CourseGroupLessonBlockEntity,
  CourseGroupModuleBlockEntity,
} from '@core/repositories';

import { Box, Select, SvgIcon, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

interface CourseRoadmapProps {
  lessonsBlock: CourseGroupModuleBlockEntity[];
  isCourseWithModules: boolean;
  groupTitle: string;
  title: string;
  lessonsCount: number;
  duration: string;
  lessons: CourseGroupLessonBlockEntity[];
  setLessons: (lessons: CourseGroupLessonBlockEntity[]) => void;
  activeModuleId: number | null;
  roadmapWrapperRef: MutableRefObject<HTMLDivElement | null>;
}

export const CourseRoadmap = observer(function CourseRoadmap(props: CourseRoadmapProps) {
  const {
    lessonsBlock,
    isCourseWithModules,
    groupTitle,
    title,
    lessonsCount,
    duration,
    lessons,
    setLessons,
    activeModuleId,
    roadmapWrapperRef,
  } = props;

  const { t } = useTranslation();

  const [activeTimecodeId, setActiveTimecodeId] = useState(0);

  const options = useMemo(() => {
    return lessonsBlock?.map((item) => ({
      label: item.title,
      value: item.id,
    }));
  }, [lessonsBlock]);

  const onModuleChange = useCallback(
    (value: number) => {
      lessonsBlock?.map((item) => {
        if (item.id === value) {
          setLessons(item.lessons);
        }
      });
    },
    [lessonsBlock],
  );

  return (
    <div className={s.courseRoadmap}>
      {isCourseWithModules ? (
        <Box px={24} py={16} className={s.courseRoadmapModules} id="course-roadmap-header">
          <TypographyNew
            variant="title-3"
            weight="bold"
            colorSchema="neutral-100"
            className={s.courseRoadmapModulesTitle}
          >
            {t('shared-content.course-modules')}
          </TypographyNew>
          <Select
            defaultValue={activeModuleId}
            placeholder="Choose module"
            options={options}
            onChange={onModuleChange}
          />
        </Box>
      ) : (
        <Box px={24} py={16} className={s.courseRoadmapTile} id="course-roadmap-header">
          <TypographyNew variant="body-2" colorSchema="neutral-200">
            {groupTitle}
          </TypographyNew>
          <TypographyNew variant="title-2" weight="bold" className={s.courseRoadmapTileCourse}>
            {title}
          </TypographyNew>
          <div>
            <TypographyNew
              as="span"
              variant="body-3"
              colorSchema="neutral-200"
              className={s.courseRoadmapTileCount}
            >
              <SvgIcon type="play" /> {lessonsCount} {t('shared-content.lessons')}
            </TypographyNew>
            <TypographyNew as="span" variant="body-3" colorSchema="neutral-200">
              <SvgIcon type="alarm" /> {duration}
            </TypographyNew>
          </div>
        </Box>
      )}
      <div className={s.courseRoadmapLessons} ref={roadmapWrapperRef}>
        {lessons.map((lesson, index) => (
          <CourseRoadmapTile
            key={lesson.id}
            lesson={lesson}
            index={index}
            activeTimecodeId={activeTimecodeId}
            setActiveTimecodeId={setActiveTimecodeId}
          />
        ))}
      </div>
    </div>
  );
});
