import type { HTMLAttributes, Key, ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

export type RenderListItemContent<T> = (item: T, index: number) => ReactNode;
export type GetListKey<T> = (item: T) => Key;

export type ListProps<T extends Record<string, any>> = {
  data: T[];
  itemKey: GetListKey<T>;
  renderItem: RenderListItemContent<T>;
  itemClassName?: string;
} & Omit<HTMLAttributes<HTMLUListElement>, 'children'>;

export const List = observer(function List<T extends Record<string, any>>(props: ListProps<T>) {
  const { data, renderItem, itemKey, itemClassName, ...restProps } = props;

  const mapItems = data.map((item, index) => {
    return (
      <li key={itemKey(item)} className={itemClassName}>
        {renderItem(item, index)}
      </li>
    );
  });

  return <ul {...restProps}>{mapItems}</ul>;
});
