import type { ChangeEvent } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { observer } from '@shared/libs/mobx';

import { useUncontrolled } from '@shared/hooks';
import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('switch-btn');

const switchBtn = cva(rootClass, {
  variants: {
    size: {
      sm: appendClass('--size-sm'),
      md: appendClass('--size-md'),
      lg: appendClass('--size-lg'),
    },
    variant: {
      gradient: appendClass('--variant-gradient'),
      green: appendClass('--variant-green'),
      violet: appendClass('--variant-violet'),
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'green',
  },
});

export interface SwitchProps
  extends Omit<ElementProps<'input'>, 'type' | 'onChange' | 'size'>,
    VariantProps<typeof switchBtn> {
  onChange?: (checked: boolean) => void;
}

const _Switch = observer((props: SwitchProps) => {
  const { defaultChecked = false, checked, size, className, onChange, ...restProps } = props;

  const rootClasses = switchBtn({ size, className });

  const [_checked, _handleChange] = useUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    finalValue: false,
    onChange,
  });

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;

    _handleChange(checked);
  };

  return (
    <label className={rootClasses}>
      <input
        onChange={handleChangeCheckbox}
        type="checkbox"
        className={appendClass('-field')}
        checked={_checked}
        {...restProps}
      />
      <span className={appendClass('-slider')}></span>
    </label>
  );
});

export const SwitchBtn = mergeComponentsWithName('SwitchBtn', _Switch);
