import type { MouseEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { MediaContentTile } from '@units';
import { useInView } from 'framer-motion';

import { LoadingStore } from '@core/store';

import { makeAutoObservable, observer, runInAction } from '@shared/libs/mobx';

import { Group, Skeleton } from '@shared/UI';

import type { ObjectValues } from '@shared/types';
import type { FetchOutsideFileReturn } from '@shared/utils';
import {
  FileKind,
  fetchOutsideFile,
  imageWithLowSizeQuery,
  kindOfFile,
  mergeComponentsWithName,
  withMediaUrl,
} from '@shared/utils';

import s from './styles.module.scss';

export interface MediaItemProps {
  url: string;

  onClickFile?: (event: MouseEvent<HTMLLIElement>) => void;

  setLoadedFile?: (file: FetchOutsideFileReturn) => void;
}

class MediaItemStore {
  file: FetchOutsideFileReturn | null = null;

  fileKind: ObjectValues<typeof FileKind> | null = null;

  loader = new LoadingStore();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  initFile = async (fileUrl: string, cb?: (file: FetchOutsideFileReturn) => void) => {
    this.loader.startLoading();
    try {
      const file = await fetchOutsideFile(withMediaUrl(fileUrl));

      runInAction(() => {
        this.file = file;
        this.fileKind = kindOfFile(file.originUrl);
      });

      cb?.(file);
    } finally {
      this.loader.stopLoading();
      this.loader.setLoaded(true);
    }
  };
}

const _MediaItem = observer((props: MediaItemProps) => {
  const { url, onClickFile, setLoadedFile, ...restProps } = props;

  const itemRef = useRef<HTMLLIElement>(null);

  const isInView = useInView(itemRef, { once: true, margin: '200px' });

  const [mediaItemStore] = useState(() => new MediaItemStore());

  const Element = useMemo(() => {
    if (!mediaItemStore.file) return null;

    switch (mediaItemStore.fileKind) {
      case FileKind.DOCUMENT:
        return (
          <MediaContentTile
            document={mediaItemStore.file.content}
            label={mediaItemStore.file.name}
            mimeType={mediaItemStore.file.extension}
            size={mediaItemStore.file.readableSize}
          />
        );
      case FileKind.IMAGE:
        return (
          <MediaContentTile
            mediaUrl={imageWithLowSizeQuery(mediaItemStore.file.originUrl)}
            label={mediaItemStore.file.name}
            mimeType={mediaItemStore.file.extension}
            size={mediaItemStore.file.readableSize}
          />
        );
      default:
        return null;
    }
  }, [mediaItemStore.file]);

  useEffect(() => {
    if (!mediaItemStore.loader.isLoaded && isInView) {
      mediaItemStore.initFile(url, setLoadedFile);
    }
  }, [isInView]);

  return (
    <li className={s.mediaItem} role="button" ref={itemRef} onClick={onClickFile} {...restProps}>
      {mediaItemStore.loader.isLoading || !mediaItemStore.loader.isLoaded ? (
        <Group wrap="nowrap">
          <Skeleton borderRadius="50%" width={48} height={48} />
          <Skeleton height={24} width={320} />
        </Group>
      ) : (
        Element
      )}
    </li>
  );
});

export const MediaItem = mergeComponentsWithName('MediaItem', _MediaItem);
