import type { ReactNode } from 'react';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('rich-text-editor-menu');

export interface EditorMenuItemProps extends ElementProps<'button'> {
  icon: ReactNode;

  isActive?: boolean | (() => boolean);
}

const _EditorMenuItem = observer((props: EditorMenuItemProps) => {
  const { icon, isActive, className, ...restProps } = props;

  const rootClasses = cl(
    rootClass,
    {
      [appendClass('--is-active')]: typeof isActive === 'function' ? isActive() : isActive,
    },
    className,
  );

  return (
    <button type="button" className={rootClasses} {...restProps}>
      {icon}
    </button>
  );
});

export const EditorMenuItem = mergeComponentsWithName('EditorMenuItem', _EditorMenuItem);
