export const StreamChatMessageStatus = {
  Active: 'ACTIVE',
  Deleted: 'DELETED',
  OnApprove: 'ON_APPROVE',
} as const;

export const StreamChatUserStatus = {
  Muted: 'MUTED',
  Active: 'ACTIVE',
  Marked: 'MARKED',
};

export const StreamChatTabs = {
  Chat: 'chat',
  OnApprove: 'on-approve',
  Muted: 'muted',
};
