import { useMemo } from 'react';

import { messageTextParser } from '@units';
import { observer } from 'mobx-react-lite';

import { MessageViewType } from '@core/constants';

import { cl } from '@shared/libs/classnames';

import { Group, Image, Stack, SvgIcon, TypographyNew } from '@shared/UI';

import { withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

interface ReplyMessageInfoProps {
  authorName: string;
  message: string;
  type: string;
  attachments: string[];
  withLightBg?: boolean;
}

export const ReplyMessageInfo = observer(function ReplyMessageInfo(props: ReplyMessageInfoProps) {
  const { authorName, message, type, attachments = [], withLightBg } = props;

  const rootClasses = cl(s.replyMessage, { [s.replyMessageLightBg]: withLightBg });

  const renderAttachmentsBlock = useMemo(() => {
    if (!!attachments.length) {
      if (attachments[0].endsWith('.mp4') || attachments[0].endsWith('.pdf'))
        return <SvgIcon type="file-preview-icon" />;
    }

    return <Image src={withMediaUrl(attachments[0])} className={s.replyMessageAttachment} />;
  }, [attachments]);

  const renderMessageText = useMemo(() => {
    switch (type) {
      case MessageViewType.Video:
        return 'Video message';
      case MessageViewType.Audio:
        return 'Audio message';
      default:
        return messageTextParser(message);
    }
  }, [type, message]);

  return (
    <Group gap="sm" wrap="nowrap" w="100%" className={rootClasses}>
      {!!attachments.length && renderAttachmentsBlock}
      <Stack gap="xs-3" align="flex-start">
        <TypographyNew colorSchema="secondary-300" className={s.replyMessageAuthor}>
          {authorName}
        </TypographyNew>
        <TypographyNew lineClamp={4} as="div" className={s.replyMessageText}>
          {renderMessageText}
        </TypographyNew>
      </Stack>
    </Group>
  );
});
