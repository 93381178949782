import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Avatar, SvgIcon, TypographyNew } from '@shared/UI';

import { createDateWithMonthFormat } from '@shared/utils';

import s from './styles.module.scss';

export type LeftSectionProps = {
  title: string;
  endDate: string;
};

export const LeftSection = observer(function LeftSection(props: LeftSectionProps) {
  const { title, endDate } = props;

  const { t } = useTranslation();

  return (
    <div className={s.leftSection}>
      <div className={s.titleWrapper}>
        <Avatar size="sm" shape="square" icon={<SvgIcon type="comms-icon" />} />
        <TypographyNew as="span" variant="body-2" weight="extra-bold">
          {title}
        </TypographyNew>
      </div>

      <div className={s.infoWrapper}>
        <div className={s.infoItem}>
          <SvgIcon type="warning" />
          <div>
            <TypographyNew as="span" variant="body-4">
              {t('my-account.my-subscription.subscription-started')}
            </TypographyNew>
            <TypographyNew as="p" variant="body-2" weight="extra-bold">
              Active
            </TypographyNew>
          </div>
        </div>

        <div className={s.infoItem}>
          <SvgIcon type="calendar" />
          <div>
            <TypographyNew as="span" variant="body-4">
              {t('my-account.my-subscription.active-till')}
            </TypographyNew>
            <TypographyNew as="p" variant="body-2" weight="extra-bold">
              {createDateWithMonthFormat(endDate)}
            </TypographyNew>
          </div>
        </div>
      </div>
    </div>
  );
});
