import { FormBox } from '../../_components';
import type { PrivateInformationSchema } from '../../_schemas';
import { privateInformationSchema } from '../../_schemas';
import { ProfileAvatar } from './profile-avatar';
import { ProfileCredentials } from './profile-credentials';
import { FormFields } from '@components';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useRootStore } from '@core/store';

export const Form = observer(function Form() {
  const { t } = useTranslation();

  const { userStore } = useRootStore();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<PrivateInformationSchema>({
    resolver: zodResolver(privateInformationSchema),
    defaultValues: {
      name: userStore.user.name || '',
      image: [],
    },
  });

  const onSubmit: SubmitHandler<PrivateInformationSchema> = async (dto) => {
    if (dto.name && userStore.user.name !== dto.name) {
      try {
        await userStore.patchMe({ name: dto.name });
        toast.success(t('my-account.personal-information.successfully-changed'));
      } catch (e) {}
    }
  };

  const isDisabledButton = !isDirty || userStore.loader.isLoading;
  return (
    <FormBox onSubmit={handleSubmit(onSubmit)} isDisabledButton={isDisabledButton}>
      <ProfileAvatar />
      <ProfileCredentials />
      <FormFields.Input
        name="name"
        control={control}
        label={t('inputs.name.label')}
        placeholder={t('inputs.name.placeholder')}
        error={errors.name}
      />
    </FormBox>
  );
});
