import type { SessionStorageValues } from './_types';
import { StorageService } from './storage.service';

export class SessionStorageService extends StorageService<SessionStorageValues> {
  constructor() {
    super(sessionStorage);
  }
}

export const sessionStorageService = new SessionStorageService();
