import { PHONE_MASK } from '@constants';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import type { Control, FieldError, FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import { Input, SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

type FieldProps<TFormValues extends FieldValues> = {
  nameCode: FieldPath<TFormValues>;
  namePhone: FieldPath<TFormValues>;
  rulesCode?: RegisterOptions;
  rulesPhone?: RegisterOptions;
  error?: FieldError;
  control?: Control<TFormValues>;
};

export const Field = observer(function Field<TFormValues extends FieldValues>(
  props: FieldProps<TFormValues>,
) {
  const { control, nameCode, namePhone, error, rulesPhone, rulesCode } = props;

  const { t } = useTranslation();

  return (
    <div className={s.fieldContainer}>
      <Controller
        name={nameCode}
        control={control}
        rules={rulesCode}
        render={({ field }) => (
          <div className={s.fieldContainerSelectWrapper}>
            <PhoneInput
              {...field}
              excludeCountries={['ru']}
              searchPlaceholder={t('inputs.search-placeholder')}
              containerClass={s.fieldPhoneSelectContainer}
              inputClass={s.fieldPhoneSelectInput}
              dropdownClass={s.fieldPhoneSelectDropdown}
              onChange={(v) => field.onChange(`+${v}` as any)}
              value={field.value}
              enableSearch
              prefix="+"
            />
            <span className={s.fieldContainerSelectValue}>{field.value}</span>
            <span className={s.fieldContainerSelectArrow}>
              <SvgIcon type="arrow" />
            </span>
          </div>
        )}
      />

      <div className={s.fieldPhoneInput}>
        <Controller
          name={namePhone}
          control={control}
          rules={rulesPhone}
          render={({ field }) => (
            <Input
              id={namePhone}
              hasError={!!error}
              placeholder="12 345 67 89 000"
              mask={PHONE_MASK}
              autoComplete="off"
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
});
