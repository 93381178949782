export const createFormData = (
  data: Record<string, number | string | (string | File | number)[] | Record<string, any>>,
) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item, idx) =>
        formData.append(idx.toString(), item instanceof File ? item : item.toString()),
      );
    }
    if (value instanceof File) {
      formData.append(key, value);
      return;
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      formData.append(key, JSON.stringify(value));
      return;
    }
    formData.append(key, value.toString());
  });
  return formData;
};
