import type { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import { Content } from './Content';
import { Header } from './Header';

import s from './styles.module.scss';

type AuthLayoutProps = {
  children: ReactNode;
};

export const AuthLayout = observer(function AuthLayout(props: AuthLayoutProps) {
  const { children } = props;
  return (
    <div className={s.authLayout}>
      <Header />
      <Content>{children}</Content>
    </div>
  );
});
