import { Children, cloneElement } from 'react';
import type { ReactElement, ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import { TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

const elementStyles = {
  base: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    color: '#ffffff',
  },
};

const cardElementOptions = {
  style: elementStyles,
};

interface StripeFieldProps {
  label: string;
  children: ReactNode;
}

export const StripeField = observer(function StripeField(props: StripeFieldProps) {
  const { label, children } = props;

  const childrenArray = Children.toArray(children) as ReactElement[];

  return (
    <label className={s.stripeFieldLabel}>
      <TypographyNew variant="body-4" className={s.stripeFieldLabelText}>
        {label}
      </TypographyNew>
      <div className={s.stripeFieldItem}>
        {childrenArray.map((child) => {
          return cloneElement(child, { options: cardElementOptions });
        })}
      </div>
    </label>
  );
});
