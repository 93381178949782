import axios from 'axios';

import {
  rejectedInterceptor,
  requestFulfilledInterceptor,
  responseFulfilledInterceptor,
} from './interceptors';

const baseURL = `${import.meta.env.VITE_DEFAULT_API_URL_PATH}`;

const httpClient = axios.create({
  baseURL,
  withCredentials: true,
});

httpClient.interceptors.request.use(requestFulfilledInterceptor, rejectedInterceptor);

httpClient.interceptors.response.use(responseFulfilledInterceptor, rejectedInterceptor);

export { httpClient };
