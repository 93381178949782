import {
  rejectedInterceptor,
  requestFulfilledInterceptor,
  responseFulfilledInterceptor,
} from './interceptors';
import axios from 'axios';

const baseURL = `${import.meta.env.VITE_NOTIFICATION_API_URL}`;

const httpClientNotification = axios.create({
  baseURL,
  withCredentials: true,
});

httpClientNotification.interceptors.request.use(requestFulfilledInterceptor, rejectedInterceptor);

httpClientNotification.interceptors.response.use(responseFulfilledInterceptor, rejectedInterceptor);

export { httpClientNotification };
