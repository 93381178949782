import { useEffect, useMemo, useRef, useState } from 'react';

import { cl } from '@shared/libs/classnames';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@shared/libs/floating-ui';
import type { Placement } from '@shared/libs/floating-ui';
import { observer } from '@shared/libs/mobx';

import { SvgIcon, Typography } from '@shared/UI';
import { useClassName } from '@shared/UI/_hooks';

import { useToggle } from '@shared/hooks';

import { Menu } from './menu';
import type { MenuProps, SelectOption } from './menu';

import './styles.scss';

export type SelectProps<T extends SelectOption> = {
  options: T[];
  value?: T['value'];
  defaultOpened?: boolean;
  defaultValue?: T['value'] | null;
  disabled?: boolean;
  onChange?: (value: T['value'], option: T) => void;
  onSelect?: (value: T['value'], option: T) => void;
  placement?: Placement;
  menuProps?: Omit<
    MenuProps<T>,
    | 'selectedOption'
    | 'options'
    | 'getFloatingProps'
    | 'isOpen'
    | 'floatingStyles'
    | 'setFloating'
    | 'parentRef'
    | 'onClickOption'
  >;
  placeholder?: string;
  className?: string;
  isLoading?: boolean;
};
export const Select = observer(function Select<T extends SelectOption>(props: SelectProps<T>) {
  const {
    value,
    options = [],
    placeholder,
    defaultOpened = false,
    defaultValue,
    disabled = false,
    placement = 'bottom',
    menuProps,
    onChange,
    className,
    isLoading = false,
  } = props;

  const [selectedOption, setSelectedOption] = useState<T | undefined>();

  const selectOptions = useMemo(() => options, [options]);

  const [isOpen, toggleIsOpen] = useToggle(defaultOpened);

  const rootRef = useRef<HTMLDivElement | null>(null);

  const { refs, context, floatingStyles } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: toggleIsOpen,
    middleware: [offset(5), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: 'menu',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const { rootClassName, appendClass } = useClassName('select');

  const rootClasses = cl(
    rootClassName,
    {
      [appendClass('--opened')]: isOpen,
      [appendClass('--closed')]: !isOpen,
      [appendClass('--disabled')]: disabled,
    },
    className,
  );

  const originValue = useMemo(() => {
    if (value !== undefined) {
      return options.find((option) => option.value === value);
    }

    return selectedOption;
  }, [value, selectedOption, options]);

  const handleChangeValue = (option: T) => {
    if (option.value === selectedOption?.value) return;

    setSelectedOption(option);
    onChange?.(option.value, option);
    toggleIsOpen(false);
  };

  useEffect(() => {
    if (defaultValue && selectOptions.length) {
      setSelectedOption(() => {
        const foundValue = selectOptions.find((option) => option.value === defaultValue);

        return foundValue || undefined;
      });
    }
  }, [defaultValue, selectOptions]);

  return (
    <>
      <div
        ref={(node) => {
          refs.setReference(node);
          rootRef.current = node;
        }}
        className={rootClasses}
        {...getReferenceProps()}
      >
        <div className={appendClass('-wrapper')}>
          <input
            placeholder={placeholder}
            value={originValue?.value || ''}
            className={appendClass('-control')}
            type="search"
            hidden
            readOnly
          />
          {selectedOption && (
            <Typography as="p" ellipsis variant="body-medium-2" className={appendClass('-control')}>
              {selectedOption.label}
            </Typography>
          )}
          {!selectedOption && placeholder && (
            <Typography
              as="p"
              ellipsis
              variant="body-medium-2"
              className={appendClass('-placeholder')}
            >
              {placeholder}
            </Typography>
          )}
          <span className={appendClass('-trigger-arrow')}>
            <SvgIcon type="arrow" />
          </span>
        </div>
      </div>

      <Menu
        isLoading={isLoading}
        onClickOption={handleChangeValue}
        selectedOption={selectedOption}
        parentRef={refs.reference}
        isOpen={isOpen}
        setFloating={refs.setFloating}
        getFloatingProps={getFloatingProps}
        floatingStyles={floatingStyles}
        options={selectOptions}
        {...menuProps}
      />
    </>
  );
});
