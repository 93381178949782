import type { AxiosRequestConfig } from 'axios';

import { PATH_COLLECTION } from '@core/constants';
import type { CreateCommunityChannelDto, UploadChatAttachmentsDto } from '@core/repositories';
import { createHTTPMediaService } from '@core/services/http';

import { cleanObject, createFormData } from '@shared/utils';

export class CommunityRepository {
  private readonly httpMedia = createHTTPMediaService(PATH_COLLECTION.MEDIA.ROOT);

  uploadChatAttachments = (
    dto: UploadChatAttachmentsDto,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress'],
  ) => {
    const { files, chatId, message, type } = dto;

    let payload = {
      message,
      communicationItemId: chatId,
      type,
    };

    if (!payload.type) {
      delete payload.type;
    }

    const formData = createFormData({
      payload,
    });

    files.forEach((file, idx) => formData.append(idx.toString(), file));

    const path = PATH_COLLECTION.MEDIA.UPLOAD_CHANNEL_ATTACHMENTS;

    return this.httpMedia.post<null, FormData>(path, formData, {
      config: {
        onUploadProgress,
      },
    });
  };

  createChannel = (dto: CreateCommunityChannelDto) => {
    const { title, file } = dto;

    const path = PATH_COLLECTION.MEDIA.COMMUNITY_CHANNEL_MEDIA;

    const formData = createFormData({
      payload: {
        title,
        communityLang: 'en',
        type: 'PAID',
      },
    });

    formData.append('image', file);

    return this.httpMedia.post<null, FormData>(path, formData);
  };

  updateChannel = (channelId: string, dto: Partial<CreateCommunityChannelDto>) => {
    const { file, ...restCleanDto } = cleanObject(dto);

    const path = PATH_COLLECTION.MEDIA.COMMUNITY_CHANNEL_UPDATE;

    const formData = createFormData({
      payload: {
        ...restCleanDto,
        communityLang: 'en',
        type: 'FREE',
      },
    });

    if (file) {
      formData.append('image', file);
    }

    return this.httpMedia.patch<null, FormData>(path, formData, {
      param: {
        channelId,
      },
    });
  };
}

export const communityRepository = new CommunityRepository();
