import { AuthType } from '@constants';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import type { ButtonProps } from '@shared/UI';
import { Box, Button } from '@shared/UI';

import type { ObjectKeys } from '@shared/types';

import s from './styles.module.scss';

type SwitcherProps = {};

const buttons = [
  {
    type: AuthType.email,
    localizationKey: 'shared-content.sign-by-email',
  },
  {
    type: AuthType.phone,
    localizationKey: 'shared-content.sign-by-phone',
  },
];

export const Switcher = observer(function Switcher(props: SwitcherProps) {
  const {} = props;

  const { t } = useTranslation();

  const { authStore } = useRootStore();

  const handleClickButton = (type: ObjectKeys<typeof AuthType>) => {
    authStore.setPreferredAuthType(type);
  };

  const mapButtons = buttons.map((btn) => {
    const { localizationKey, type } = btn;

    const variant: ButtonProps['variant'] =
      type === authStore.preferredAuthType ? 'dark' : 'outline';

    return (
      <Button
        size="xs"
        key={type}
        variant={variant}
        className={s.button}
        onClick={() => handleClickButton(type)}
      >
        {t(localizationKey as any)}
      </Button>
    );
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={12}
      my={[15, 0]}
      className={s.switcher}
    >
      {mapButtons}
    </Box>
  );
});
