import type { MouseEvent, RefObject } from 'react';
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';

import { CommunityMessageContextMenu } from './community-message-context-menu';
import { ONLY_FIRST_LINK_PATTERN } from '@constants';
import type { IMessageListController } from '@units';
import {
  OBSERVE_ROOT_MARGIN,
  TalkingArea,
  extractMsgNodeFromParentEvent,
  useMessageListScroll,
  useMessageListVirtual,
  useMessageParams,
  useMessageTransmitterContext,
  useObserveLoadTriggers,
} from '@units';
import { makeAutoObservable, runInAction, when } from 'mobx';
import type Player from 'video.js/dist/types/player';
import WaveSurfer from 'wavesurfer.js';

import { LoadMessageDirection, MessageViewType } from '@core/constants';
import { LoadingStore, MessageStore, rootStore, useRootStore } from '@core/store';

import { useContextMenu } from '@shared/UI';

import { chatDateFormat } from '@shared/utils';

class CommunityMessagesController implements IMessageListController {
  public isInit = false;

  public isInitializing = false;

  public hadLoadedFirstNewMessages = false;

  public isUpProcessing = new LoadingStore();

  public isDownProcessing = new LoadingStore();

  public triggerUpMessage: MessageStore | null = null;

  private mediaElements: Map<string, WaveSurfer | Player> = new Map();

  private messagesStore = rootStore.communityMessagesStore;

  private chatsStore = rootStore.communityChatsStore;

  private counterStore = rootStore.messageCounter;

  constructor(private chatId?: string) {
    makeAutoObservable<this, 'mediaElements'>(
      this,
      {
        mediaElements: false,
      },
      { autoBind: true },
    );
  }

  public get chatUnreadCount() {
    if (!this.chatId) return 0;
    return this.counterStore.getChat(this.chatId)?.totalCount ?? 0;
  }

  public get rows() {
    if (!this.chatId) return [];
    const messages = this.messagesStore.getMessagesByChatId(this.chatId);
    return messages;
  }

  public get hasUnread() {
    if (!this.chatId) return false;
    const chatCounter = this.counterStore.getChat(this.chatId);

    if (!chatCounter) return false;

    return chatCounter.totalCount > 0;
  }

  public get rowsWithDate() {
    return this.rows.reduce((acc: Array<MessageStore | string>, row) => {
      const date = chatDateFormat(row.createDate);
      const alreadyExistDate = acc.includes(date);

      if (alreadyExistDate) {
        acc.push(row);
      } else {
        acc.push(date);
        acc.push(row);
      }

      return acc;
    }, []);
  }

  public get rowsWithDateCount() {
    return this.rowsWithDate.length;
  }

  public get rowsCount() {
    return this.rows.length;
  }

  public get isLoadingRows() {
    let activeMessagesLoading = false;

    if (this.chatId) {
      const chatBucket = this.messagesStore.getChatBucketById(this.chatId);

      activeMessagesLoading = chatBucket?.loader.isLoading ?? false;
    }

    return this.chatsStore.chatsLoader.isLoading || activeMessagesLoading;
  }

  public get isLoadedRows() {
    if (!this.chatId) return false;
    const chatBucket = this.messagesStore.getChatBucketById(this.chatId);
    return chatBucket?.loader.isLoaded ?? false;
  }

  public get hasMoreUpRows() {
    if (!this.chatId) return false;
    const pagination = this.messagesStore.getChatBucketById(this.chatId)?.pagination;
    return pagination ? !pagination.firstMessagePresent : false;
  }

  public get hasMoreDownRows() {
    if (!this.chatId) return false;
    const pagination = this.messagesStore.getChatBucketById(this.chatId)?.pagination;
    return pagination ? !pagination.lastMessagePresent : false;
  }

  public get firstRow() {
    return this.rows[0] ?? null;
  }

  public get lastRow() {
    return this.rows[this.rowsCount - 1] ?? null;
  }

  public get firstUnreadRowIdx() {
    return this.rows.findIndex((msg) => !msg.isViewed);
  }

  public get hasUnreadRows() {
    return this.rows.some((msg) => !msg.isViewed);
  }

  public processUpTrigger = async () => {
    if (this.isUpProcessing.isLoading || !this.firstRow) return;

    this.isUpProcessing.startLoading();

    runInAction(() => {
      this.triggerUpMessage = this.firstRow;
    });

    await this.messagesStore.awaitedLoadMessages(this.firstRow, LoadMessageDirection.Up);

    this.isUpProcessing.stopLoading();
  };

  public processDownTrigger = async () => {
    if (this.isDownProcessing.isLoading || !this.lastRow) return;

    this.isDownProcessing.startLoading();

    await this.messagesStore.awaitedLoadMessages(this.lastRow, LoadMessageDirection.Down);

    this.isDownProcessing.stopLoading();
  };

  public setMediaElement = (messageId: string, element: WaveSurfer | Player | null) => {
    if (!element) {
      this.mediaElements.delete(messageId);
      return;
    }
    this.mediaElements.set(messageId, element);
  };

  public onChangeActiveMedia = (messageId: string) => {
    this.mediaElements.forEach((media, key) => {
      if (key === messageId) {
        if (media instanceof WaveSurfer) {
          media.playPause();
        } else {
          if (media.paused()) {
            media.play();
          } else {
            media.pause();
          }
        }
        return;
      }

      media.pause();
    });
  };

  public loadFirstNewMessages = () => {
    runInAction(() => {
      this.hadLoadedFirstNewMessages = true;
    });
  };

  public estimateSize = (index: number) => {
    const row = this.rows[index];

    if (row.type === MessageViewType.Video) {
      return 282;
    }

    if (row.type === MessageViewType.Audio) {
      return 140;
    }

    const hasLink = Boolean(ONLY_FIRST_LINK_PATTERN.exec(row.message)?.[0]);

    if (hasLink && row?.replyMessageInfo?.messageId) {
      return 452;
    }

    if (row?.replyMessageInfo?.messageId) {
      return 146;
    }

    if (hasLink && row.type === MessageViewType.Text) {
      return 400;
    }

    if (row?.attachments.length && row.type === MessageViewType.Text) {
      return 152;
    }

    return 112;
  };

  public isMessage = (message: string | MessageStore): message is MessageStore =>
    message instanceof MessageStore;

  public getRowKeyByIndex = (index: number) => {
    const row = this.rowsWithDate[index];

    return this.isMessage(row) ? row.id : row;
  };

  public getRowIndexByKey = (key: string) => {
    return this.rows.findIndex((row) => row.id === key);
  };

  public getRowByIndex = (index: number) => {
    return this.rows[index];
  };

  public getRowByKey = (key: string) => {
    return this.rows.find((row) => row.id === key) ?? null;
  };

  startInit = () => {
    if (this.isInit || this.isInitializing) return;
    runInAction(() => {
      this.isInitializing = true;
    });
  };

  completeInit = () => {
    runInAction(() => {
      this.isInit = true;
      this.isInitializing = false;
    });
  };

  public disposer = () => {
    runInAction(() => {
      this.isInit = false;
      this.isUpProcessing = new LoadingStore();
      this.isDownProcessing = new LoadingStore();
      this.triggerUpMessage = null;
      this.mediaElements.clear();
    });
  };
}

const useMessageHandlers = (
  scrollArea: RefObject<HTMLElement>,
  control: CommunityMessagesController,
) => {
  const messageInstance = useRef<MessageStore | null>(null);

  const contextMenu = useContextMenu();
  const { messageTransmitter } = useMessageTransmitterContext();

  const onContextMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const messageNode = extractMsgNodeFromParentEvent(event);

    const message = messageNode?.messageId ? control.getRowByKey(messageNode.messageId) : null;

    if (!message) return;

    messageInstance.current = message;

    contextMenu.show({
      event,
      component: (
        <CommunityMessageContextMenu
          message={message}
          root={scrollArea.current}
          messageTransmitter={messageTransmitter}
        />
      ),
    });
  };

  return { onContextMenu };
};

export const useCommunityMessageListController = () => {
  const { chatId } = useMessageParams(TalkingArea.COMMUNITY);

  const { communityMessagesStore, appSettingsStore } = useRootStore();

  const control = useMemo(() => new CommunityMessagesController(chatId), []);

  const { scrollArea, virtualizer, isActiveSticky } = useMessageListVirtual(control);

  const items = virtualizer.getVirtualItems();

  const isFirstLoad = useRef(true);

  const {
    isRendered,
    isNearBottom,
    isToBottomScrolling,
    downRef,
    onScrollToBottom,
    setupRowNode,
    receiveBaseMessage,
    receiveVideoTemplateMessage,
    onScrollArea,
  } = useMessageListScroll(virtualizer, control, () => {
    if (chatId) communityMessagesStore.awaitedReadAllMessagesEmitter(chatId);
  });

  const { onContextMenu } = useMessageHandlers(scrollArea, control);

  const observeCallback = async (entries: IntersectionObserverEntry[]) => {
    if (control.isInitializing || !control.isInit || !isRendered) return;

    for (const entry of entries) {
      if (!entry.isIntersecting) continue;
      const totalSize = virtualizer.getTotalSize();
      if (entry.target === triggerUpRef.current) {
        await control.processUpTrigger();

        let prevOffset = 0;

        requestAnimationFrame(() => {
          prevOffset = Math.max(0, totalSize - (virtualizer.scrollElement?.offsetHeight ?? 0) + 20);
        });

        setTimeout(() => {
          requestAnimationFrame(() => {
            const currentListSize = virtualizer.getTotalSize();
            const scrollSize = currentListSize - prevOffset;
            scrollArea.current?.scrollTo({ top: scrollSize, behavior: 'auto' });
          });
        });
        setTimeout(() => {
          if (isFirstLoad.current) {
            isFirstLoad.current = false;
          }
        });
        break;
      }

      if (entry.target === triggerDownRef.current) {
        await control.processDownTrigger();
        break;
      }
    }
  };

  const { triggerDownRef, triggerUpRef } = useObserveLoadTriggers({
    canObserveUp: control.hasMoreUpRows && control.isInit,
    canObserveDown: control.hasMoreDownRows && control.isInit,
    observeCallback,
    observeOptions: {
      root: scrollArea.current,
      rootMargin: OBSERVE_ROOT_MARGIN,
    },
  });

  useLayoutEffect(() => {
    const listeners = [
      communityMessagesStore.receiveViewerMessage(receiveBaseMessage),
      communityMessagesStore.receiveTemplateMessage(receiveVideoTemplateMessage),
      communityMessagesStore.receiveReadAllMessages(() => onScrollToBottom(false)),
    ];
    return () => {
      control.disposer();
      listeners.forEach((disposer) => disposer());
    };
  }, []);

  useEffect(() => {
    const disposer = when(
      () =>
        !control.isLoadingRows &&
        !control.isInit &&
        control.rowsCount === 0 &&
        control.isLoadedRows,
      () => {
        control.completeInit();
      },
    );

    return () => {
      disposer();
    };
  }, []);

  return {
    control,
    items: items,
    // messages: communityMessagesStore.activeChatMessages,
    downRef,
    scrollArea,
    virtualizer,
    triggerDownRef,
    triggerUpRef,
    isToBottomScrolling,
    isNearBottom,
    onContextMenu,
    setupRowNode,
    onScrollArea,
    onScrollToBottom,
    isActiveSticky,
    isPinnedMessagesVisible: communityMessagesStore.isPinnedMessagesVisible,
    isOnline: appSettingsStore.isOnline,
  };
};
