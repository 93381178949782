import type { ChangeEvent } from 'react';

import { createOptionalContext } from '@shared/utils';

interface RadioGroupContext {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  disabled: boolean;
}

export const [RadioGroupProvider, useRadioGroupContext] =
  createOptionalContext<RadioGroupContext>();
