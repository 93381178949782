import type { ReactNode } from 'react';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

export interface WithAccessProps {
  access: boolean;

  children: ReactNode;
}

const _WithAccess = observer((props: WithAccessProps) => {
  const { access, children } = props;

  if (!access) return null;

  return <>{children}</>;
});

export const WithAccess = mergeComponentsWithName('WithAccess', _WithAccess);
