import { observer } from 'mobx-react-lite';

import { TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

export const EventCardLabel = observer(function EventCardLabel(props: { value: string }) {
  const { value } = props;

  return (
    <div className={s.eventCardLabel}>
      <TypographyNew variant="body-2" colorSchema="secondary-500">
        {value}
      </TypographyNew>
    </div>
  );
});
