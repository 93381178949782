import type { Key, ReactNode } from 'react';

import type { BaseModalProps } from '@components';

import { observer } from '@shared/libs/mobx';

import { Avatar, Button, Carousel, Group, Modal, Stack, SvgIcon, Typography } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface FullSizeMediaModalProps<T> extends Omit<BaseModalProps, 'children'> {
  slideContent: (item: T) => ReactNode;
  itemKey: (item: T) => Key;
  ownerName?: string;
  ownerImageUrl?: string;
  messageDate?: string;
  actionSlot?: ReactNode;
  data?: T[];
  initialSlide?: number;
}

const _FullSizeMediaModal = observer(<T,>(props: FullSizeMediaModalProps<T>) => {
  const {
    ownerImageUrl,
    ownerName,
    messageDate,
    actionSlot,
    data = [],
    initialSlide,
    itemKey,
    slideContent,
    ...restProps
  } = props;

  return (
    <Modal
      className={s.fullSizeMedia}
      size="fullSize"
      variant="non-styled"
      isNeedToUnmount
      withHeader={false}
      {...restProps}
    >
      <Stack align="flex-start" className={s.fullSizeMediaWrapper}>
        <Group className={s.fullSizeMediaHeader} justify="space-between">
          <Group wrap="nowrap">
            {ownerImageUrl && <Avatar size="lg" src={ownerImageUrl} />}
            <Stack gap="xs-3" align="flex-start">
              {ownerName && (
                <Typography as="span" variant="body-bold-2">
                  {ownerName}
                </Typography>
              )}
              {messageDate && (
                <Typography className={s.fullSizeMediaHeaderDate} as="span" variant="body-medium-3">
                  {messageDate}
                </Typography>
              )}
            </Stack>
          </Group>
          <Group>
            {actionSlot}
            <Button onClick={restProps.onClose} size="icon" disabledAnimation variant="text">
              <SvgIcon fontSize={34} type="cross" />
            </Button>
          </Group>
        </Group>
        <Stack className={s.fullSizeMediaBody}>
          <Carousel
            initialSlide={initialSlide}
            slidesPerView={1}
            loop
            navigation
            centeredSlides
            keyboard
          >
            {data.map((item) => (
              <Carousel.Slide key={itemKey(item)}>
                <div className={s.fullSizeMediaBodySlide}>{slideContent(item)}</div>
              </Carousel.Slide>
            ))}
          </Carousel>
        </Stack>
      </Stack>
    </Modal>
  );
});

export const FullSizeMediaModal = mergeComponentsWithName(
  'FullSizeMediaModal',
  _FullSizeMediaModal,
);
