import { mapSkeleton } from '@units';

import { observer } from '@shared/libs/mobx';

import { Skeleton } from '@shared/UI';

import { mergeComponentsWithName, rand } from '@shared/utils';

import s from './styles.module.scss';

export interface ChatMessagesLoaderProps {}

const _ChatMessagesLoader = observer((props: ChatMessagesLoaderProps) => {
  const {} = props;

  return (
    <div className={s.chatMessagesLoader}>
      {mapSkeleton(18, (_, index) => {
        const n = rand(1, 10);
        return (
          <div className={s.chatMessagesLoaderItem} data-odd={n % 2 === 0} key={index}>
            <Skeleton width={260} height={60} borderRadius={16} />
          </div>
        );
      })}
    </div>
  );
});

export const ChatMessagesLoader = mergeComponentsWithName(
  'ChatMessagesLoader',
  _ChatMessagesLoader,
);
