import { observer } from '@shared/libs/mobx';

import type { ElementProps } from '@shared/types';
import { mergeComponentsWithName } from '@shared/utils';

export interface ArrowIconProps extends ElementProps<'svg'> {}

const _ArrowIcon = observer((props: ArrowIconProps) => {
  const { width, height, style, ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      style={{ width, height, ...style }}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.27958 6.27748C9.65235 5.90751 10.2567 5.90751 10.6295 6.27748L15.7204 11.3301C16.0932 11.7001 16.0932 12.2999 15.7204 12.6699L10.6295 17.7225C10.2567 18.0925 9.65235 18.0925 9.27958 17.7225C8.90681 17.3526 8.90681 16.7527 9.27958 16.3827L13.6955 12L9.27958 7.61726C8.90681 7.24729 8.90681 6.64745 9.27958 6.27748Z"
        fill="currentColor"
      />
    </svg>
  );
});

export const ArrowIcon = mergeComponentsWithName('ArrowIcon', _ArrowIcon);
