import { routerCollection } from '@constants';
import { useAppLocation } from '@hooks';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet } from 'react-router-dom';

import { useRootStore } from '@core/store';

import { ReCaptcha } from '@features/auth';

import { mergeComponentsWithName } from '@shared/utils';

const _EntryPage = observer(() => {
  const { authStore } = useRootStore();

  const { state } = useAppLocation();

  if (authStore.isUserHasAccess) {
    const { from } = state || { from: { pathname: routerCollection.dashboard.root } };

    return <Navigate to={from} replace />;
  }

  return (
    <>
      <ReCaptcha />
      <Outlet />
    </>
  );
});

const EntryPage = mergeComponentsWithName('EntryPage', _EntryPage);

export default EntryPage;
