import { type Ref, useMemo } from 'react';

import { AccordionItemProvider } from '../AccordionItem.context';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { ElementProps } from '@shared/types';
import { classNames, componentName, mergeComponents } from '@shared/utils';

import './styles.scss';

const { rootClass } = classNames('accordion-item');

export interface AccordionItemProps extends ElementProps<'div'> {
  value: string;
  isLoading?: boolean;
  hideSeparator?: boolean;
  innerRef?: Ref<HTMLDivElement>;
}

const _AccordionItem = observer((props: AccordionItemProps) => {
  const {
    value,
    children,
    isLoading = false,
    className,
    innerRef,
    hideSeparator,
    ...restProps
  } = props;

  const rootClasses = cl(rootClass, className);

  const contextValue = useMemo(
    () => ({
      value,
      isLoading,
    }),
    [value, isLoading],
  );

  return (
    <AccordionItemProvider value={contextValue}>
      <div
        ref={innerRef}
        data-value={value}
        data-loading={isLoading}
        className={rootClasses}
        data-hide-separator={hideSeparator}
        {...restProps}
      >
        {children}
      </div>
    </AccordionItemProvider>
  );
});

export const AccordionItem = mergeComponents(_AccordionItem, {
  ...componentName('Accordion.Item'),
});
