import { useMemo, useState } from 'react';

import { MessageForm } from './message-form';
import { MessageList } from './message-list';
import { MutedAndMarkedUsers } from './muted-and-marked-users';
import { OnApproveMessages } from './on-approve-messages';
import { StopWordsModal } from './stop-words-modal';
import { modalStore } from '@components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { StreamChatTabs } from '@core/constants';
import { useRootStore } from '@core/store';

import { cl } from '@shared/libs/classnames';

import { Button, Group, SvgIcon, Tabs, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

const tabsList = [
  { name: StreamChatTabs.Chat, label: 'shared-content.chat' },
  { name: StreamChatTabs.OnApprove, label: 'shared-content.on-approve' },
  { name: StreamChatTabs.Muted, label: 'shared-content.muted' },
];

export const StreamChat = observer(function StreamChat() {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(StreamChatTabs.Chat);

  const { streamChatStore, streamsStore } = useRootStore();

  const tabsLayout = useMemo(() => {
    if (activeTab === StreamChatTabs.Chat) {
      return <MessageList tab={StreamChatTabs.Chat} />;
    }

    if (activeTab === StreamChatTabs.OnApprove) {
      return (
        <div className={cl(s.streamChatContainerList)}>
          <OnApproveMessages tab={StreamChatTabs.OnApprove} />
        </div>
      );
    }

    if (activeTab === StreamChatTabs.Muted) {
      return (
        <div className={cl(s.streamChatContainerList)}>
          <MutedAndMarkedUsers tab={StreamChatTabs.Muted} />
        </div>
      );
    }

    return null;
  }, [activeTab]);

  const openStopWordsModal = () => {
    modalStore.activateModal({ component: <StopWordsModal isNeedToUnmount /> });
  };

  return (
    <div className={s.streamChat}>
      <Group justify="space-between" w="100%">
        <TypographyNew variant="title-4" weight="bold">
          {t('streams.live-chat.title')}
        </TypographyNew>
        {streamsStore.stream?.userCanModerateChat && (
          <Button
            variant="outline"
            size="icon"
            disabledAnimation
            className={s.streamChatSettings}
            onClick={openStopWordsModal}
          >
            <SvgIcon type="settings" className={s.streamChatSettingsIcon} />
          </Button>
        )}
      </Group>

      {streamsStore.stream?.userCanModerateChat && (
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabsList} />
      )}

      <div className={s.streamChatContainer}>{tabsLayout}</div>

      {!streamChatStore.isUserMuted ? (
        <div>
          <MessageForm />
        </div>
      ) : (
        <div className={s.streamChatRestriction}>
          <TypographyNew variant="body-4" textAlign="center" colorSchema="neutral-2-600">
            {t('errors.dont-have-permissions-to-write')}
          </TypographyNew>
        </div>
      )}
    </div>
  );
});
