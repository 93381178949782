import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import { SocketEventsCollection } from '@core/constants';
import type {
  NewNotificationResponse,
  ReceiveUnreadNotificationResponse,
  UnreadNotificationEntity,
  UserCommunityUnreadStatus,
} from '@core/repositories';
import { notificationRepository } from '@core/repositories';
import { getHttpError } from '@core/services/http';
import { appSocket } from '@core/services/socket';

export class NotificationsStore {
  notifications: UnreadNotificationEntity[] = [];

  unreadStatuses: UserCommunityUnreadStatus | null = null;

  readAllNotifications = async () => {
    try {
      await notificationRepository.readAllNotifications();

      runInAction(() => {
        this.notifications = [];
      });
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  readNotificationById = async (notificationId: number) => {
    try {
      await notificationRepository.readNotificationById(notificationId);

      runInAction(() => {
        this.notifications = this.notifications.filter((item) => item.id !== notificationId);
      });
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    }
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    // this.initSubscribers();
  }

  private receiveUnreadNotificationSubscriber = (details: ReceiveUnreadNotificationResponse) => {
    runInAction(() => {
      this.notifications = details.userUnreadNotifications.notifications;
    });
  };

  private receiveUnreadStatusesSubscriber = (details: UserCommunityUnreadStatus) => {
    runInAction(() => {
      this.unreadStatuses = details;
    });
  };

  private receiveNewNotificationSubscriber = (details: NewNotificationResponse) => {
    runInAction(() => {
      this.notifications = [
        {
          body: details.notification.body,
          businessAccountId: null,
          createdDate: '',
          id: details.notification.id,
          imageUrl: details.notification.imageUrl,
          redirectInfo: details.notification.redirectInfo,
          title: details.notification.title,
        },
        ...this.notifications,
      ];
    });
  };

  private readNotifications = (details: string[]) => {
    runInAction(() => {
      this.notifications.forEach((item) => {
        const parsedRedirectInfo = JSON.parse(item.redirectInfo);

        if (details.includes(parsedRedirectInfo.messageId)) {
          this.readNotificationById(item.id);
        }
      });
    });
  };

  private initSubscribers = () => {
    appSocket.subscribe(
      SocketEventsCollection.NotificationEvents.Subscribers.ReceiveUnreadNotification,
      this.receiveUnreadNotificationSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.CommonEvents.Subscribers.ReceiveUnreadStatuses,
      this.receiveUnreadStatusesSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.NotificationEvents.Subscribers.NewNotification,
      this.receiveNewNotificationSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.ViewMessages,
      (details) => {
        return this.readNotifications(details.viewedMessageIds);
      },
    );

    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.ViewMessage,
      (details) => {
        const messageIds = details.map((item) => item.messageId);
        return this.readNotifications(messageIds);
      },
    );
  };

  initEvents = () => {
    this.initSubscribers();
  };

  disposer = () => {
    this.notifications = [];
  };
}
