import type { MouseEvent } from 'react';

import { CommunityChat } from './community-chat';
import type { UseCommunityChatListControllerProps } from './community-chat-list.controller';
import { useCommunityChatListController } from './community-chat-list.controller';
import { DndContext, MeasuringStrategy } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';

import type { ChatStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Box, Each, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

export interface CommunityChatListProps extends UseCommunityChatListControllerProps {
  isDraggable?: boolean;
  onItemContextMenu?: (chat: ChatStore, event: MouseEvent<HTMLLIElement>) => void;
}

const _CommunityChatList = observer((props: CommunityChatListProps) => {
  const { channel } = props;

  const { chats, dndSensors, isLoading, isLoaded, onDragEnd, onContextMenu } =
    useCommunityChatListController({
      channel,
    });

  if (chats.length === 0 && isLoaded && !isLoading) {
    return (
      <Box py={12}>
        <TypographyNew colorSchema="neutral-100" className={s.emptyCategory}>
          {channel.title} is empty
        </TypographyNew>
      </Box>
    );
  }

  return (
    <DndContext onDragEnd={onDragEnd} sensors={dndSensors} measuring={measuring}>
      <SortableContext items={chats} strategy={rectSortingStrategy}>
        <ul onContextMenu={onContextMenu}>
          <Each data={chats}>{(chat) => <CommunityChat chat={chat} key={chat.id} />}</Each>
        </ul>
      </SortableContext>
    </DndContext>
  );
});

export const CommunityChatList = mergeComponentsWithName('CommunityChatList', _CommunityChatList);
