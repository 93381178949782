import { SvgIcon } from '../../../svg-icon';
import { useRichEditorContext } from '../../rich-editor.context';
import { EditorMenuItem } from '../editor-menu-item';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

const _BtnUnderline = observer(() => {
  const { editor } = useRichEditorContext();

  const handleClickBtn = () => editor().chain().focus().toggleUnderline().run();

  const icon = <SvgIcon type="editor-underline" />;

  return (
    <EditorMenuItem
      icon={icon}
      onClick={handleClickBtn}
      isActive={editor().isActive('underline')}
    />
  );
});

export const BtnUnderline = mergeComponentsWithName('BtnUnderline', _BtnUnderline);
