import type { MouseEvent, RefObject } from 'react';
import { useCallback, useRef } from 'react';

import { MediaItem } from './media-item';
import { modalStore } from '@components';
import { FullSizeMediaModal, MessageImagePreview, messageTextParser } from '@units';

import type { MessageStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Box, Document, Each } from '@shared/UI';

import { useLatestCallback } from '@shared/hooks';
import type { FetchOutsideFileReturn } from '@shared/utils';
import { FileKind, hourAndMinuteDate, kindOfFile, mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface MessageMediaProps {
  message: MessageStore;
  parent: RefObject<HTMLElement>;
}

const _MessageMedia = observer((props: MessageMediaProps) => {
  const { message } = props;

  const listRef = useRef<HTMLUListElement>(null);

  const fileList = useRef<FetchOutsideFileReturn[]>([]);

  const setFileList = useLatestCallback((file: FetchOutsideFileReturn) => {
    fileList.current.push(file);
  });

  const slideKey = useLatestCallback((file: FetchOutsideFileReturn) => {
    return file.originUrl;
  });

  const slideContent = useLatestCallback((file: FetchOutsideFileReturn) => {
    const fileType = kindOfFile(file.originUrl);

    if (fileType === FileKind.IMAGE) {
      return <MessageImagePreview srcUrl={URL.createObjectURL(file.content)} />;
    }

    if (fileType === FileKind.DOCUMENT) {
      return <Document file={file.content} />;
    }

    return null;
  });

  const handleClickMedia = useCallback((event: MouseEvent<HTMLLIElement>) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));

    modalStore.activateModal({
      component: (
        <FullSizeMediaModal
          messageDate={hourAndMinuteDate(message.createDate)}
          ownerName={message.authorName}
          ownerImageUrl={message.ownerImage}
          data={fileList.current}
          itemKey={slideKey}
          slideContent={slideContent}
          initialSlide={index}
        />
      ),
    });
  }, []);

  return (
    <>
      {messageTextParser(message.message)}
      <Box w="100%" as="ul" innerRef={listRef} className={s.mediaList}>
        <Each data={message.attachments}>
          {(url, index) => (
            <MediaItem
              key={url}
              url={url}
              data-index={index}
              setLoadedFile={setFileList}
              onClickFile={handleClickMedia}
            />
          )}
        </Each>
      </Box>
    </>
  );
});

export const MessageMedia = mergeComponentsWithName('MessageMedia', _MessageMedia);
