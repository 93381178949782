import { useEffect } from 'react';

import { useCommunityController } from './community.controller';
import { ChatLayout, MessageTransmitterProvider } from '@units';
import { CommunityChatHeader, CommunityMessageForm, CommunitySidebarNew } from '@widgets';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { EmptyContent, Fallback } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _CommunityNew = observer(() => {
  const { t } = useTranslation();

  const { communityChannelsStore, communityChatsStore } = useRootStore();

  const { canShowFooter, chatId } = useCommunityController();

  useEffect(() => {
    return () => {
      communityChannelsStore.resetActiveChannel();
      communityChatsStore.resetActiveChat();
    };
  }, []);

  return (
    <MessageTransmitterProvider>
      <ChatLayout
        headerSlot={
          <Fallback when={!chatId} fallback={null}>
            <CommunityChatHeader />
          </Fallback>
        }
        sidebarSlot={<CommunitySidebarNew />}
        chatSlot={
          <Fallback
            when={!chatId}
            fallback={
              <EmptyContent
                title={t('community.channels.empty.title')}
                subtitle={t('community.channels.empty.description')}
                className={s.emptyContent}
              />
            }
          >
            <Outlet />
          </Fallback>
        }
        footerSlot={
          <CommunityMessageForm
            key={chatId}
            chatId={chatId ?? null}
            isVisibleForm={canShowFooter}
          />
        }
      />
    </MessageTransmitterProvider>
  );
});

const CommunityNew = mergeComponentsWithName('CommunityNew', _CommunityNew);

export default CommunityNew;
