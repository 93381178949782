import { useEffect, useMemo } from 'react';

import { modalStore } from '@components';
import { ChatLayout, MessageTransmitterProvider } from '@units';
import type { ChatFormProps } from '@widgets';
import { ChatForm, PrivateChatHeader, PrivateChatSidebar } from '@widgets';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import type { MessageViewType } from '@core/constants';
import { useRootStore } from '@core/store';

import { observer, when } from '@shared/libs/mobx';

import { EmptyContent, type UploadedFile } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _Chat = observer(() => {
  const { t } = useTranslation();

  const { privateChatsStore, privateMessagesStore, appSettingsStore } = useRootStore();

  const { chatId } = useParams<{ chatId: string }>();

  const handleUploadFiles = async (message: string, files: UploadedFile[]) => {
    const currentChat = privateChatsStore.currentChat;
    if (currentChat) {
      await currentChat
        .uploadChatAttachments({
          message,
          files: files.map((file) => file.originalFile),
        })
        .finally(() => {
          modalStore.closeAll();
        });
    }
  };

  const handleUploadMediaFile = (
    messageType: typeof MessageViewType.Audio | typeof MessageViewType.Video,
    file: File,
  ) => {
    const currentChat = privateChatsStore.currentChat;
    if (currentChat) {
      currentChat.uploadChatAttachments({
        message: '',
        type: messageType,
        files: [file],
      });
    }
  };

  const onHandleSubmitMessage = (message: string, replyMessageId?: string) => {
    privateMessagesStore.sendMessageEmitter(message, replyMessageId);
  };

  const editorExtensions = useMemo(
    (): ChatFormProps['editorExtensions'] => ({
      mention: false,
    }),
    [],
  );

  const sidebar = <PrivateChatSidebar />;
  const footer =
    privateChatsStore.currentChatId && appSettingsStore.isOnline ? (
      <ChatForm
        editorExtensions={editorExtensions}
        onSubmitMessage={onHandleSubmitMessage}
        onUploadFiles={handleUploadFiles}
        onUploadMediaFile={handleUploadMediaFile}
      />
    ) : null;

  useEffect(() => {
    const disposer = when(
      () => privateChatsStore.isConnectedToPrivateChats && Boolean(chatId),
      () => {
        privateChatsStore.joinToChat(chatId!);
      },
    );

    if (!privateChatsStore.isConnectingToPrivateChats) {
      privateChatsStore.awaitedConnectToPrivateChats();
    }

    return () => {
      disposer();
      privateChatsStore.disconnectFromPrivateChats();
    };
  }, []);

  return (
    <MessageTransmitterProvider>
      <ChatLayout
        sidebarSlot={sidebar}
        chatSlot={
          !privateChatsStore.currentChatId || !chatId ? (
            <EmptyContent
              title={t('community.messages.empty.title')}
              subtitle={t('community.messages.empty.description')}
              className={s.emptyContent}
            />
          ) : (
            <Outlet />
          )
        }
        headerSlot={privateChatsStore.currentChatId ? <PrivateChatHeader /> : null}
        footerSlot={footer}
      />
    </MessageTransmitterProvider>
  );
});

const Chat = mergeComponentsWithName('Chat', _Chat);

export default Chat;
