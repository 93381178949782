import { action, makeObservable, observable } from 'mobx';

// It is size when sidebar is mobile
const MEDIA_FOR_MOBILE = 860;

export class AppLayoutStore {
  isMobileSidebar = window.innerWidth < MEDIA_FOR_MOBILE;

  isSidebarOpen = false;

  windowSize = 0;

  isSharedPage = false;

  constructor() {
    makeObservable<AppLayoutStore, 'handleResize'>(this, {
      isMobileSidebar: observable,
      isSidebarOpen: observable,
      isSharedPage: observable,
      handleResize: action,
      handleChangeOpen: action,
    });
  }

  handleChangeOpen = () => {
    this.isSidebarOpen = !this.isSidebarOpen;
  };

  addSizeListener = () => {
    window.addEventListener('resize', this.handleResize);
  };

  removeSizeListener = () => {
    window.removeEventListener('resize', this.handleResize);
  };

  private handleResize = () => {
    this.windowSize = window.innerWidth;

    if (this.windowSize < MEDIA_FOR_MOBILE) {
      this.isMobileSidebar = true;
    } else {
      this.isMobileSidebar = false;
    }
  };
}
