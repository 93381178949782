import { useEffect, useRef } from 'react';

import { SvgIcon } from '../svg-icon';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface RatingProps {
  rating: number;
  className?: string;
}

const _Rating = observer((props: RatingProps) => {
  const { rating, className } = props;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const children = Array.from(ref.current.children);
      children.forEach((item, index) => {
        if (index + 1 <= rating) {
          if (item.firstChild instanceof SVGElement) {
            const svgFirstChild = item.firstChild;
            svgFirstChild.style.fill = '#b17ce1';
          }
        }
      });
    }
  }, [rating]);

  const rootClasses = cl(s.rating, className);

  return (
    <div ref={ref} className={rootClasses}>
      {Array.from({ length: 5 }, (_item, index) => index + 1).map((item: number) => (
        <SvgIcon type="star" key={item} className={s.ratingIcon} />
      ))}
    </div>
  );
});

export const Rating = mergeComponentsWithName('Rating', _Rating);
