import EntryPage from './EntryPage';
import { ForgotPasswordFirstStep, ForgotPasswordSecondStep } from './forgot-password';
import { Login } from './login';
import { Registration } from './registration';
import { routerCollection } from '@constants';
import { AuthLayout } from '@layouts';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

export const authRouter: RouteObject = {
  path: routerCollection.auth.root,
  element: (
    <AuthLayout>
      <EntryPage />
    </AuthLayout>
  ),
  children: [
    {
      index: true,
      element: <Navigate to={routerCollection.auth.login} />,
    },
    {
      path: routerCollection.auth.registration,
      element: <Registration />,
    },
    {
      path: routerCollection.auth.login,
      element: <Login />,
    },
    {
      path: routerCollection.auth.forgotPassword,
      element: <ForgotPasswordFirstStep />,
    },
    {
      path: routerCollection.auth.newPassword,
      element: <ForgotPasswordSecondStep />,
    },
  ],
};
