import type { MessageCounterStore } from './../message-counter';
import type { PrivateChatStoreOptions } from './private-chat.store';
import { PrivateChatStore } from './private-chat.store';
import { makeAutoObservable, runInAction } from 'mobx';

import { SocketEventsCollection } from '@core/constants';
import type {
  ConnectToPrivateChatsResponse,
  PrivateChatMessageEntity,
  PrivateChatParticipantEntity,
  ViewedPrivateMessageResponse,
} from '@core/repositories';
import { appSocket } from '@core/services/socket';
import { LoadingStore, MessageStore } from '@core/store/common';
import type { UserStore } from '@core/store/user';

type ChatsMap = Map<
  string,
  {
    chat: PrivateChatStore;
  }
>;

export class PrivateChatsStore {
  loader = new LoadingStore();

  isConnectedToPrivateChats = false;

  isConnectingToPrivateChats = false;

  private _chats: ChatsMap = new Map();

  private _currentChatId: string | null = null;

  constructor(private viewerStore: UserStore, private messageCounterStore: MessageCounterStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    // this.initSubscribers();

    // this.connectToPrivateChats();
  }

  get chats() {
    return Array.from(this._chats, ([, chat]) => chat.chat);
  }

  get currentChatId() {
    return this._currentChatId;
  }

  get currentChat() {
    return this.chats.find((chat) => chat.id === this._currentChatId) ?? null;
  }

  joinToChat = (chatId: string) => {
    if (this._currentChatId === chatId) return;
    this._currentChatId = null;
    this.loader.startLoading();
    appSocket.dispatch(SocketEventsCollection.PrivateMessageEvents.Emitters.JoinToChatById, {
      privateChatId: chatId,
    });
    this._currentChatId = chatId;
  };

  connectToPrivateChats = () => {
    this.loader.startLoading();
    this.isConnectingToPrivateChats = true;
    appSocket.dispatch(SocketEventsCollection.PrivateMessageEvents.Emitters.ConnectToPrivateChats);
  };

  awaitedConnectToPrivateChats = async () => {
    this.isConnectingToPrivateChats = true;
    this.loader.startLoading();
    await appSocket.awaitedDispatch(
      SocketEventsCollection.PrivateMessageEvents.Emitters.ConnectToPrivateChats,
      SocketEventsCollection.PrivateMessageEvents.Subscribers.ConnectToPrivateChats,
    );
    this.loader.stopLoading();
    this.setIsConnectedToPrivateChats(true);
    this.isConnectingToPrivateChats = false;
  };

  connectToPrivateChatsSubscriber = (details: ConnectToPrivateChatsResponse[]) => {
    const chats: ChatsMap = new Map();
    let viewer: PrivateChatParticipantEntity | undefined = undefined;

    for (const chat of details) {
      const participant = chat.participants.find((user) => user.id !== this.viewerStore.user.id);
      if (!viewer) {
        viewer = chat.participants.find((user) => user.id === this.viewerStore.user.id);
      }

      if (!viewer || !participant) continue;

      const _chat = this.newPrivateChat({
        ownerId: chat.chatInfo.ownerId,
        participantIds: chat.chatInfo.participantIds,
        unreadCount: chat.chatInfo.unreadCount,
        lastMessage: chat.lastMessage,
        id: chat.chatInfo.id,
        viewer,
        participant,
      });

      chats.set(chat.chatInfo.id, { chat: _chat });
    }

    runInAction(() => {
      this._chats = chats;
      this.loader.stopLoading();
      this.setIsConnectedToPrivateChats(true);
      this.isConnectingToPrivateChats = false;
    });
  };

  disconnectFromPrivateChats = () => {
    appSocket.dispatch(SocketEventsCollection.PrivateMessageEvents.Emitters.Disconnect);
    runInAction(() => {
      this._currentChatId = null;
      this.setIsConnectedToPrivateChats(false);
      this.loader.stopLoading();
    });
  };

  setIsConnectedToPrivateChats = (isConnected: boolean) => {
    this.isConnectedToPrivateChats = isConnected;
  };

  awaitedCreatePrivateChat = async (participantId: number) => {
    const details = await appSocket.awaitedDispatch(
      SocketEventsCollection.PrivateMessageEvents.Emitters.CreatePrivateChat,
      SocketEventsCollection.PrivateMessageEvents.Subscribers.CreatePrivateChat,
      { participantId },
    );

    let viewer: PrivateChatParticipantEntity | undefined = undefined;

    const participant = details.participants.find((user) => user.id !== this.viewerStore.user.id);

    if (!viewer) {
      viewer = details.participants.find((user) => user.id === this.viewerStore.user.id);
    }

    if (!viewer || !participant) return;

    const privateChat = this.newPrivateChat({
      ownerId: details.chatInfo.ownerId,
      participantIds: details.chatInfo.participantIds,
      unreadCount: details.chatInfo.unreadCount,
      lastMessage: details.lastMessage,
      id: details.chatInfo.id,
      viewer,
      participant,
    });

    runInAction(() => {
      this._chats.set(details.chatInfo.id, { chat: privateChat });
      this.joinToChat(details.chatInfo.id);
    });
  };

  getChatById = (chatId: string) => {
    return this._chats.get(chatId)?.chat ?? null;
  };

  private newPrivateChat = (options: PrivateChatStoreOptions) => {
    return new PrivateChatStore(options, this.messageCounterStore);
  };

  private receiveMessageSubscriber = (details: PrivateChatMessageEntity) => {
    const lastMessage = new MessageStore({
      ...details,
      chatId: details.privateChatId,
      isMessageFromViewer: this.viewerStore.user.id === details.authorId,
    });

    const chat = this.getChatById(details.privateChatId);

    if (!chat) return;

    runInAction(() => {
      chat.lastMessage = lastMessage;

      if (this.viewerStore.user.id !== details.authorId) {
        chat?.increaseUnreadCount();
      }
    });
  };

  private receiveNewPrivateChatSubscriber = (details: ConnectToPrivateChatsResponse) => {
    let viewer: PrivateChatParticipantEntity | undefined = undefined;

    const participant = details.participants.find((user) => user.id !== this.viewerStore.user.id);

    if (!viewer) {
      viewer = details.participants.find((user) => user.id === this.viewerStore.user.id);
    }

    if (!viewer || !participant) return;

    const privateChat = this.newPrivateChat({
      ownerId: details.chatInfo.ownerId,
      participantIds: details.chatInfo.participantIds,
      unreadCount: details.chatInfo.unreadCount,
      lastMessage: details.lastMessage,
      id: details.chatInfo.id,
      viewer,
      participant,
    });

    runInAction(() => {
      this._chats = new Map([[privateChat.id, { chat: privateChat }], ...this._chats]);
    });
  };

  private receiveViewedMessages = (details: ViewedPrivateMessageResponse) => {
    const bucket = this.getChatById(details.privateChatId);

    if (!bucket || details.viewedMessageIds.length === 0) return;

    const viewedMsgLng = details.viewedMessageIds.length;

    bucket.decreaseUnreadCount(viewedMsgLng);

    if (bucket.lastMessage && details.viewedMessageIds.includes(bucket.lastMessage.id)) {
      bucket.lastMessage.viewCount += 1;
    }
  };

  private initSubscribers = () => {
    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.ConnectToPrivateChats,
      this.connectToPrivateChatsSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.GetNewMessage,
      this.receiveMessageSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.CreatePrivateChat,
      this.receiveNewPrivateChatSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.PrivateMessageEvents.Subscribers.ViewMessages,
      this.receiveViewedMessages,
    );
  };

  initEvents = () => {
    this.initSubscribers();
    this.connectToPrivateChats();
  };

  disposer = () => {
    this._chats = new Map();
    this._currentChatId = null;
    this.loader = new LoadingStore();
    this.isConnectedToPrivateChats = false;
    this.isConnectingToPrivateChats = false;
  };
}
