import { useMemo } from 'react';
import type { ReactNode } from 'react';

import { observer } from '@shared/libs/mobx';

import { Box, For, Stack, Typography } from '@shared/UI';
import type { RenderForItem } from '@shared/UI';

import { mergeComponentsWithName, randomUuid } from '@shared/utils';

import s from './styles.module.scss';

export interface PriceCardProps {
  id: number;
  type: string;
  title: string;
  advantages: string[];
  price: number;
  isTrialEnabled: boolean;
  trialDayDuration: number;
  isFree?: boolean;
  isSubscription?: boolean;
  bottomSlot?: ReactNode;
  showNote?: boolean;
}

const _PriceCard = observer((props: PriceCardProps) => {
  const { title, advantages, bottomSlot, showNote = true } = props;

  const advantagesList = useMemo(
    () =>
      advantages.map((label) => {
        const key = randomUuid();

        return {
          label,
          key,
        };
      }),
    [advantages],
  );

  const renderAdvantages: RenderForItem<(typeof advantagesList)[0]> = (advantage) => {
    return (
      <li className={s.priceCardListItem} key={advantage.key}>
        <Typography as="span" variant="body-medium-4">
          {advantage.label}
        </Typography>
      </li>
    );
  };

  return (
    <Box intent="glass" className={s.priceCard}>
      <Stack gap="none" justify="space-between" className={s.priceCardContainer}>
        <Box w="100%">
          <Typography className={s.priceCardTitle} as="h4" variant="body-bold-1">
            Plan <span className={s.priceCardHighlighted}>{title}</span>
          </Typography>
          <Box as="ul" className={s.priceCardList}>
            <For data={advantagesList} render={renderAdvantages} />
          </Box>
        </Box>

        <Box>
          {bottomSlot && <Box className={s.priceCardBottomSlotContainer}>{bottomSlot}</Box>}
          {showNote && (
            <Box className={s.priceCardNoteContainer}>
              <Typography className={s.priceCardNote} as="span" variant="body-medium-4">
                *Taxes and overages may apply
              </Typography>
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
});

export const PriceCard = mergeComponentsWithName('PriceCard', _PriceCard);
