import { observer } from '@shared/libs/mobx';

import { Avatar, Box, Group, Stack, Typography } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface ReviewCardProps {
  avatarSrc?: string;

  nickname: string;

  date: string;

  description: string;
}

const _ReviewCard = observer((props: ReviewCardProps) => {
  const { avatarSrc, nickname, date, description } = props;

  return (
    <Box intent="glass" className={s.reviewCard}>
      <Stack className={s.reviewCardContainer} align="flex-start">
        <Group wrap="nowrap">
          <Avatar src={avatarSrc} alt="Reviewer avatar" />
          <Stack gap="xs">
            <Typography as="span" variant="body-bold-2">
              {nickname}
            </Typography>
            <Typography className={s.reviewCardDate} as="span" variant="body-medium-4">
              {date}
            </Typography>
          </Stack>
        </Group>
        <Typography
          className={s.reviewCardComment}
          as="p"
          variant="body-medium-3"
          lineClamp={5}
          title={description}
        >
          {description}
        </Typography>
      </Stack>
    </Box>
  );
});

export const ReviewCard = mergeComponentsWithName('ReviewCard', _ReviewCard);
