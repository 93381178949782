import type { CSSProperties, RefObject } from 'react';

import { usePrivateMessageController } from './private-message.controller';
import { CircleVideoMessage, CommunityMessageTile, MessageVariant } from '@features-new';
import type { VirtualItem } from '@tanstack/react-virtual';
import type { IMessageListController } from '@units';
import { EmojiReactionList, MessageDate, MessageTile, MessageTileFooterInfo } from '@units';

import { MessageViewType } from '@core/constants';

import { observer } from '@shared/libs/mobx';

import { Box, Group } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

export interface PrivateChatMessageProps {
  item: VirtualItem;
  scrollAreaRef: RefObject<HTMLElement>;
  messageListController: IMessageListController;
  isActiveSticky: (index: number) => boolean;
  setupNode: (node: HTMLElement | null) => void;
}

const _PrivateChatMessage = observer((props: PrivateChatMessageProps) => {
  const { item, scrollAreaRef, messageListController, setupNode, isActiveSticky, ...restProps } =
    props;

  const { message, messageParams, onSelectEmoji } = usePrivateMessageController({
    messageListController,
    row: item,
  });

  const messageVariant = () => {
    if (!messageListController.isMessage(message)) return null;

    if (message.isMessageWithFiles) {
      return <MessageVariant.TextWithMedia message={message} parent={scrollAreaRef} />;
    }

    if (message.isMessageWithAudio) {
      return (
        <MessageVariant.Audio
          setAudioRef={messageListController.setMediaElement}
          onClickAudio={messageListController.onChangeActiveMedia}
          message={message}
        />
      );
    }

    return <MessageVariant.Text message={message} />;
  };

  const messageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transform: `translateY(${item.start}px)`,
    willChange: 'transform',
  } satisfies CSSProperties;

  const dateStyle = {
    ...(isActiveSticky(item.index)
      ? { position: 'sticky', zIndex: 1 }
      : { position: 'absolute', transform: `translateY(${item.start}px)` }),
    width: '100%',
    top: 0,
    left: 0,
    willChange: 'transform',
    display: 'flex',
    justifyContent: 'center',
  } satisfies CSSProperties;

  if (!message) return null;

  return (
    <Box
      {...restProps}
      py={[0, 16]}
      style={messageListController.isMessage(message) ? messageStyle : dateStyle}
      as="li"
      innerRef={setupNode}
    >
      {messageListController.isMessage(message) ? (
        <CommunityMessageTile message={message} scrollAreaRef={scrollAreaRef}>
          {message?.type === MessageViewType.Video ? (
            <CircleVideoMessage
              message={message}
              onClickVideo={messageListController.onChangeActiveMedia}
              setVideoRef={messageListController.setMediaElement}
              handleEmojiClick={onSelectEmoji}
            />
          ) : (
            <MessageTile
              message={message}
              variant={messageParams.variant}
              owner={messageParams.owner}
              footerSlot={
                <Group gap="xs" align="flex-end" w="100%">
                  <EmojiReactionList
                    reactions={message.reactions.reactions}
                    messageId={message.id}
                    handleEmojiClick={onSelectEmoji}
                  />
                  <MessageTileFooterInfo message={message} />
                </Group>
              }
            >
              {messageVariant()}
            </MessageTile>
          )}
        </CommunityMessageTile>
      ) : (
        <MessageDate date={message} />
      )}
    </Box>
  );
});

export const PrivateChatMessage = mergeComponentsWithName(
  'PrivateChatMessage',
  _PrivateChatMessage,
);
