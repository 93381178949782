import { observer } from '@shared/libs/mobx';

import type { GroupProps } from '@shared/UI';
import { Avatar, CrawlLine, Group, Indicator, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface CommunityChannelItemProps extends Omit<GroupProps, 'children'> {
  imageSrc?: string;
  label: string;
  unreadCount?: number;
}

const _CommunityChannelItem = observer((props: CommunityChannelItemProps) => {
  const { imageSrc, label, unreadCount } = props;

  return (
    <Group wrap="nowrap" w="100%" overflow="hidden">
      <Avatar size="sm" src={imageSrc} />
      <CrawlLine trigger="hover">
        <TypographyNew
          as="span"
          variant="body-2"
          weight="bold"
          truncate
          className={s.communityChannelItemTitle}
        >
          {label}
        </TypographyNew>
      </CrawlLine>

      {Boolean(unreadCount) && (
        <Indicator
          count={unreadCount}
          size="md"
          maxCount={99}
          className={s.communityChannelItemIndicator}
        />
      )}
    </Group>
  );
});

export const CommunityChannelItem = mergeComponentsWithName(
  'CommunityChannelItem',
  _CommunityChannelItem,
);
