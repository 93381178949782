import type { CSSProperties, HTMLAttributes, ReactNode, Ref, RefObject } from 'react';
import { useCallback, useLayoutEffect, useRef } from 'react';

import type { MessageStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Box } from '@shared/UI';

import { useMergedRef } from '@shared/hooks';
import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface CommunityMessageTileProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  message: MessageStore;

  children: ReactNode;

  innerRef?: Ref<HTMLDivElement>;

  style?: CSSProperties;

  scrollAreaRef?: RefObject<HTMLElement>;
}

const _CommunityMessageTile = observer((props: CommunityMessageTileProps) => {
  const { message, children, innerRef, style, scrollAreaRef, ...restProps } = props;

  const messageRef = useRef<HTMLDivElement>(null);

  const ref = useMergedRef(messageRef, innerRef);

  const isObservable = useRef(false);

  const handleViewMessage = useCallback(() => {
    if (!message.isViewed) {
      message.viewMessage();
    }
  }, [message.isViewed, message.viewMessage]);

  const handleIntersecting = useCallback(
    ([entry]: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      if (entry.isIntersecting) {
        handleViewMessage();
        if (messageRef.current && observeOptions.needToUnobserve) {
          observer.unobserve(messageRef.current);
          isObservable.current = false;
        }
      }
    },
    [handleViewMessage],
  );

  const observeOptions = {
    needToObserve: !message.isViewed,
    needToUnobserve: isObservable.current && message.isViewed,
  };

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(handleIntersecting, {
      threshold: 0.05,
      root: scrollAreaRef?.current,
    });

    if (messageRef.current && observeOptions.needToObserve && scrollAreaRef?.current) {
      observer.observe(messageRef.current);
      isObservable.current = true;
    }

    return () => {
      if (messageRef.current) {
        observer.unobserve(messageRef.current);
        isObservable.current = false;
      }
    };
  }, [message.isViewed]);

  return (
    <div className={s.communityMessageTileWrapper} style={style} {...restProps}>
      <Box
        w="100%"
        innerRef={ref}
        data-message-id={message.id}
        data-unread={message.isViewed}
        data-viewer-message={message.isMessageFromViewer}
      >
        {children}
      </Box>
    </div>
  );
});

export const CommunityMessageTile = mergeComponentsWithName(
  'CommunityMessageTile',
  _CommunityMessageTile,
);
