import { useMemo } from 'react';

import { observer } from 'mobx-react-lite';

import { CommunityChatStatus, CommunityChatType } from '@core/constants';
import type { ChatStore } from '@core/store';
import { useRootStore } from '@core/store';

import type { GroupProps } from '@shared/UI';
import { CrawlLine, Group, Indicator, SvgIcon, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface CommunityChatItemProps extends Omit<GroupProps, 'children'> {
  chat: ChatStore;
}

const _CommunityChatItem = observer((props: CommunityChatItemProps) => {
  const { className, chat } = props;

  const chatIcon = useMemo(() => {
    if (chat.type === CommunityChatType.Channel) return 'channel';

    return 'bubble-chats';
  }, [chat]);

  const { messageCounter } = useRootStore();

  return (
    <Group className={className} wrap="nowrap" w="100%" overflow="hidden">
      <span className={s.communityChatItemChevron}>
        <SvgIcon type={chatIcon} />
      </span>
      <CrawlLine trigger="hover">
        <TypographyNew truncate as="span">
          {chat.title}
        </TypographyNew>
      </CrawlLine>
      {chat.status === CommunityChatStatus.Closed ? (
        <SvgIcon type="lock" fontSize={24} />
      ) : (
        <Indicator
          count={messageCounter.getChat(chat.id)?.totalCount ?? 0}
          maxCount={99}
          size="md"
          className={s.communityChatItemIndicator}
        />
      )}
    </Group>
  );
});

export const CommunityChatItem = mergeComponentsWithName('CommunityChatItem', _CommunityChatItem);
