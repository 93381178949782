import { MENTION_DATA_ID, MENTION_NAME, MENTION_TAG, MentionVariants } from '@constants';

export const replaceMentionWithId = (message: string) => {
  if (message.includes('data-id')) {
    return message.replace(MENTION_TAG, (el) => {
      const matches = el.match(MENTION_DATA_ID);
      const id = matches && matches[1] ? matches[1] : '';

      const nameMatches = el.match(MENTION_NAME);
      const name = nameMatches && nameMatches[1] ? nameMatches[1] : '';

      if (name === MentionVariants.Here || name === MentionVariants.Everyone) {
        return el.replace(MENTION_NAME, `<@${name}>`);
      }

      return el.replace(MENTION_NAME, `<@${id}>`);
    });
  }

  return message;
};
