import type { CSSProperties } from 'react';

import { observer } from 'mobx-react-lite';

import { Badge, Box, Group, Image, Stack, TypographyNew } from '@shared/UI';

import { asHtml } from '@shared/utils';

import s from './styles.module.scss';

interface CoursePreviewHeaderProps {
  generalImage: string;
  backgroundImage: string;
  title: string;
  description: string;
  lang: string;
  category: string;
}

export const CoursePreviewHeader = observer(function CoursePreviewHeader(
  props: CoursePreviewHeaderProps,
) {
  const { generalImage, backgroundImage, title, description, lang, category } = props;

  const styles: CSSProperties = { backgroundImage: `url(${backgroundImage})` };

  return (
    <Group
      align="flex-start"
      justify="flex-start"
      gap="xxl"
      className={s.coursePreviewHeader}
      style={styles}
      wrap="nowrap"
    >
      <Box className={s.coursePreviewHeaderImageBox}>
        <Image src={generalImage} rounded="md" />
      </Box>
      <Stack align="flex-start" gap="xl" className={s.coursePreviewHeaderDescriptionBox}>
        <div className={s.coursePreviewHeaderTagBox}>
          <Badge variant="purple" label={lang} />
          <Badge variant="blue" label={category} />
        </div>
        <TypographyNew as="h2" variant="title-1" weight="bold">
          {title}
        </TypographyNew>
        <TypographyNew variant="body-3">{asHtml(description)}</TypographyNew>
      </Stack>
    </Group>
  );
});
