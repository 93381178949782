import type { ReactNode } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '../box';
import type { NavigationArrowsProps } from './navigation-arrows';
import { NavigationArrows } from './navigation-arrows';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type SwiperClass from 'swiper';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import type { SwiperProps, SwiperRef } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SwiperModule } from 'swiper/types';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './styles.scss';

SwiperCore.use([Keyboard]);

const { rootClass, appendClass } = classNames('carousel');

const carousel = cva(rootClass, {
  variants: {
    navigationPosition: {
      none: '',
      'in-header': appendClass('--navigation-in-header'),
      'in-bottom': appendClass('--navigation-in-bottom'),
      horizontal: appendClass('--navigation-horizontal'),
    },
  },
  defaultVariants: {
    navigationPosition: 'none',
  },
});

export interface CarouselProps extends SwiperProps, VariantProps<typeof carousel> {
  headerSlot?: ReactNode;
  bottomSlot?: ReactNode;
  bottomSlotClassName?: string;
  headerSlotClassName?: string;
  withFade?: boolean;
  withOverflow?: boolean;
  arrowVariant?: NavigationArrowsProps['variant'];
  arrowClassName?: NavigationArrowsProps['className'];
}

const _Carousel = observer((props: CarouselProps) => {
  const {
    modules,
    slidesPerView = 'auto',
    centeredSlides = false,
    centeredSlidesBounds = false,
    spaceBetween = 14,
    className,
    children,
    headerSlot,
    bottomSlot,
    headerSlotClassName,
    bottomSlotClassName,
    grabCursor = true,
    withFade = false,
    withOverflow = false,
    navigationPosition,
    arrowVariant,
    arrowClassName,
    ...restProps
  } = props;

  const [showNavigation, setShowNavigation] = useState(false);

  const swiperRef = useRef<SwiperRef>(null);

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const _modules: SwiperModule[] =
    modules && modules.length ? modules : [Navigation, Keyboard, A11y];

  const rootClasses = carousel({
    navigationPosition,
    className: cl(
      {
        [appendClass('--with-fade')]: withFade && showNavigation,
        [appendClass('--with-overflow')]: withOverflow,
      },
      className,
    ),
  });

  const headerSlotClasses = cl(appendClass('-header-slot'), headerSlotClassName);
  const bottomSlotClasses = cl(appendClass('-bottom-slot'), bottomSlotClassName);

  const navigationArrowsClasses = cl(appendClass('-arrows'), arrowClassName);

  const navigationArrowsProps = {
    variant: arrowVariant,
    className: navigationArrowsClasses,
    swiper,
  };

  const Arrows = useMemo(() => {
    if (navigationPosition !== 'none' && showNavigation) {
      return <NavigationArrows {...navigationArrowsProps} />;
    }

    return null;
  }, [navigationArrowsProps, navigationPosition, showNavigation]);

  const handleHiddenNavigation = (allowSlideNext: boolean, allowSlidePrev: boolean) => {
    if (!allowSlideNext && !allowSlidePrev) {
      setShowNavigation(() => false);
      return;
    }
    if (allowSlideNext || allowSlidePrev) {
      setShowNavigation(() => true);
    }
  };

  const handleSwiperInit = (swiper: SwiperClass) => {
    handleHiddenNavigation(swiper.allowSlideNext, swiper.allowSlidePrev);
    setSwiper(swiper);
  };

  useEffect(() => {
    if (swiper) {
      swiper.on('resize', (_swiper) => {
        handleHiddenNavigation(_swiper.allowSlideNext, _swiper.allowSlidePrev);
      });
    }
  }, [swiper]);

  return (
    <div className={rootClasses}>
      {navigationPosition === 'horizontal' && Arrows}
      <Swiper
        className={appendClass('-component')}
        modules={_modules}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        grabCursor={grabCursor}
        centeredSlides={centeredSlides}
        ref={swiperRef}
        watchOverflow
        onSwiper={handleSwiperInit}
        centeredSlidesBounds={centeredSlidesBounds}
        {...restProps}
      >
        {(headerSlot || navigationPosition === 'in-header') && (
          <Box className={headerSlotClasses} slot="container-start">
            {navigationPosition === 'in-header' && Arrows}
            {headerSlot}
          </Box>
        )}

        <div className="some-block">{children}</div>
        {(bottomSlot || navigationPosition === 'in-bottom') && (
          <Box className={bottomSlotClasses} slot="container-end">
            {navigationPosition === 'in-bottom' && Arrows}
            {bottomSlot}
          </Box>
        )}
      </Swiper>
    </div>
  );
});

export const Carousel = mergeComponentsWithName('Carousel', _Carousel, {
  Slide: SwiperSlide,
});
