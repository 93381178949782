import { useCallback, useEffect, useMemo } from 'react';

import { IReactionDisposer, makeAutoObservable, runInAction, when } from 'mobx';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';

import { MessageStore } from '@core/store';

import { Nullable } from '@shared/types';
import { withMediaUrl } from '@shared/utils';

export class VideoMessageController {
  isPlaying = false;

  isReady = false;

  isLoading = false;

  isLoaded = false;

  isNeedToPlay = false;

  player: Nullable<Player> = null;

  videoElementRef: Nullable<HTMLElement> = null;

  private setPlayerDisposer: IReactionDisposer;

  constructor(
    private message: MessageStore,
    setPlayer?: (messageId: string, player: Player) => void,
  ) {
    makeAutoObservable(
      this,
      {
        videoElementRef: false,
      },
      { autoBind: true },
    );

    this.setPlayerDisposer = when(
      () => this.player !== null,
      () => {
        if (setPlayer) {
          setPlayer(message.id, this.player!);
        }
      },
    );
  }

  //#region --- Actions

  createPlayer = (mediaUrl?: string) => {
    if (this.isLoading) return;

    this.isLoading = true;

    const el = document.createElement('video-js');

    let src = '';

    if (mediaUrl) {
      src = mediaUrl;
    } else if (this.message.attachments?.[0]) {
      src = withMediaUrl(this.message.attachments[0]);
    }

    el.classList.add('vjs-big-play-centered');
    this.videoElementRef?.appendChild(el);
    window.VIDEOJS_NO_DYNAMIC_STYLE = true;

    const player = videojs(
      el,
      {
        autoPlay: true,
        controls: false,
        responsive: true,
        fluid: true,
        preload: 'metadata',
        playsinline: true,
        disablePictureInPicture: true,
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          vhs: {
            overrideNative: true,
            withCredentials: true,
            handlePartialData: true,
            liveRangeSafeTimeDelta: 1,
          },
        },
        sources: [
          {
            src,
            type: 'video/mp4',
          },
        ],
      },
      this.onReady,
    );

    runInAction(() => {
      this.player = player;
    });
  };

  setVideoElementRef = (el: HTMLElement | null) => {
    this.videoElementRef = el;
  };

  setIsNeedToPlay = (isNeedToPlay: boolean) => {
    this.isNeedToPlay = isNeedToPlay;
  };

  //#endregion --- Actions

  //#region --- Listeners

  private onReady = () => {
    this.isReady = true;

    if (this.player) {
      this.player.on('ended', this.onEnded);
      this.player.on('play', this.onPlay);
      this.player.on('pause', this.onPause);
      this.player.on('loadstart', this.onLoadStart);
      this.player.on('loadeddata', this.onLoadedData);
      this.player.on('canplay', this.onCanPlay);
    }
  };

  private onCanPlay = () => {
    this.isLoading = false;
    this.isLoaded = true;
    if (this.isNeedToPlay && this.player?.paused()) {
      this.player?.play();
      this.setIsNeedToPlay(false);
    }
  };

  private onPlay = () => {
    this.player?.on('timeupdate', this.onTimeUpdate);
    this.isPlaying = true;
  };

  private onPause = () => {
    this.player?.off('timeupdate', this.onTimeUpdate);
    this.isPlaying = false;
    this.setIsNeedToPlay(false);
  };

  private onEnded = () => {
    this.player?.currentTime(1);
    this.setIsNeedToPlay(false);
    if (!this.player?.paused() && this.isPlaying) {
      this.player?.paused();
    }
  };

  private onLoadStart = () => {
    this.isLoading = true;
  };

  private onLoadedData = () => {
    runInAction(() => {
      this.isLoaded = true;
    });
  };

  private onTimeUpdate = () => {};
  //#endregion --- Listeners

  disposer = () => {
    this.setPlayerDisposer();
    this?.player?.pause();
    this?.player?.currentTime(0);
    this.player = null;
  };
}

interface UseVideoMessageControllerProps {
  message: MessageStore;
  onClickVideo: (messageId: string) => void;
  setVideoRef: (messageId: string, video: Player | null) => void;
}

export const useVideoMessageController = (props: UseVideoMessageControllerProps) => {
  const { message, onClickVideo, setVideoRef } = props;

  const control = useMemo(() => new VideoMessageController(message, setVideoRef), [message]);

  const _onClickVideo = useCallback(() => {
    if (!control.player) {
      control.createPlayer(withMediaUrl(message.attachments?.[0]) || undefined);
    }
    onClickVideo(message.id);

    if (control.isReady) return;

    if (!control.isNeedToPlay) {
      control.setIsNeedToPlay(true);
      return;
    } else {
      control.setIsNeedToPlay(false);
    }
  }, [message]);

  useEffect(() => {
    return () => {
      control.disposer();
      setVideoRef(message.id, null);
    };
  }, []);

  return {
    control,
    onClickVideo: _onClickVideo,
  };
};
