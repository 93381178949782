import { DependencyList, useLayoutEffect, useRef } from 'react';

type UseObserveLoadTriggersProps = {
  canObserveDown: boolean;
  canObserveUp: boolean;
  effectDeps?: DependencyList;
  observeOptions?: IntersectionObserverInit;
  observeCallback: (entries: IntersectionObserverEntry[]) => void;
};

export const useObserveLoadTriggers = (props: UseObserveLoadTriggersProps) => {
  const { canObserveDown, canObserveUp, effectDeps = [], observeOptions, observeCallback } = props;

  const triggerUpRef = useRef<HTMLDivElement>(null);
  const triggerDownRef = useRef<HTMLDivElement>(null);

  const isUpObserved = useRef(false);
  const isDownObserved = useRef(false);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(observeCallback, observeOptions);

    if (triggerUpRef.current && canObserveUp && !isUpObserved.current) {
      observer.observe(triggerUpRef.current);
      isUpObserved.current = true;
    }

    if (triggerDownRef.current && canObserveDown && !isDownObserved.current) {
      observer.observe(triggerDownRef.current);
      isDownObserved.current = true;
    }

    // If canObserve has been changed, unobsevre is triggered
    if (triggerDownRef.current && !canObserveDown && isDownObserved.current) {
      observer.unobserve(triggerDownRef.current);
      isDownObserved.current = false;
    }

    if (triggerUpRef.current && !canObserveUp && isUpObserved.current) {
      observer.unobserve(triggerUpRef.current);
      isUpObserved.current = false;
    }

    return () => {
      observer.disconnect();
    };
  }, [...effectDeps, canObserveDown, canObserveUp]);

  return {
    triggerUpRef,
    triggerDownRef,
  };
};
