import type { CSSProperties } from 'react';

import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import type { BlockButton } from '@core/repositories';

import { Typography } from '@shared/UI';
import { ButtonNew } from '@shared/UI/button-new';

import { asHtml, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

export type BannerBlockProps = {
  button: BlockButton;

  backgroundImage: string;

  title: string;

  description: string;

  mainImage?: string;

  isMainBanner?: boolean;
};

export const BannerBlock = observer(function BannerBlock(props: BannerBlockProps) {
  const { description, button, backgroundImage, title, isMainBanner } = props;

  const sendEvent = useAnalyticsContext();

  const styles: CSSProperties = {
    backgroundImage: `url(${withMediaUrl(backgroundImage)})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div className={cl(s.bannerBlock, isMainBanner ? s.mainBannerBlock : '')} style={styles}>
      <div className={cl(s.bannerBlockContent, isMainBanner ? s.mainBannerBlockContent : '')}>
        <Typography
          variant={isMainBanner ? 'title-bold-1' : 'title-bold-2'}
          className={cl(s.bannerBlockTitleBox, isMainBanner ? s.mainBannerBlockTitleBox : '')}
        >
          {asHtml(title)}
        </Typography>
        <Typography
          variant={isMainBanner ? 'body-medium-1' : 'body-medium-3'}
          className={cl(
            s.bannerBlockDescriptionBox,
            isMainBanner ? s.mainBannerBlockDescriptionBox : '',
          )}
        >
          {asHtml(description)}
        </Typography>
        <div className={s.bannerBlockButtonBox}>
          <ButtonNew
            linkHref={button?.link ?? undefined}
            onClick={() =>
              sendEvent({ event: AnalyticsEvents.BannerBlockTap, payload: { banner_name: title } })
            }
          >
            {button.text}
          </ButtonNew>
        </div>
      </div>
    </div>
  );
});
