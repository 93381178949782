import { useMemo } from 'react';

import { RouterLink } from '@components';
import { NotificationRedirectType } from '@constants';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { Avatar, Group, Stack, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

interface NotificationItemProps {
  imageUrl: string;
  title: string;
  body: string;
  redirectInfo: string;
  joinToChat: (chatId: string) => void;
}

export const NotificationItem = observer(function NotificationItem(props: NotificationItemProps) {
  const { imageUrl, title, body, redirectInfo, joinToChat } = props;

  const { pathname } = useLocation();

  const parsedRedirectInfo = JSON.parse(redirectInfo);

  const generateRedirectLink = useMemo(() => {
    if (parsedRedirectInfo.type === NotificationRedirectType.CommunityMention) {
      return `/communities/${parsedRedirectInfo.communityId}/${parsedRedirectInfo.categoryId}/${parsedRedirectInfo.communicationItemId}`;
    }

    if (parsedRedirectInfo.type === NotificationRedirectType.LiveStream) {
      return `/my-workspace/streams/${parsedRedirectInfo.streamId}`;
    }

    if (parsedRedirectInfo.type === NotificationRedirectType.NewPrivateMessage) {
      return `/private-chats/chats/${parsedRedirectInfo.privateChatId}`;
    }

    if (parsedRedirectInfo.type === NotificationRedirectType.ExpiredSubscription) {
      return `/communities/overview/${parsedRedirectInfo.businessAccountId}`;
    }

    if (parsedRedirectInfo.type === NotificationRedirectType.RenewSubscription) {
      return `/communities/overview/${parsedRedirectInfo.businessAccountId}`;
    }

    return pathname;
  }, [parsedRedirectInfo.type]);

  const onClickHandler = () => {
    if (parsedRedirectInfo.type === NotificationRedirectType.NewPrivateMessage) {
      joinToChat(parsedRedirectInfo.privateChatId);
    }
  };

  return (
    <RouterLink
      to={generateRedirectLink}
      className={s.notificationItemLink}
      onClick={onClickHandler}
    >
      <Group justify="flex-start" wrap="nowrap" gap="xl" w="100%" className={s.notificationItem}>
        <Avatar src={imageUrl} />
        <Stack align="flex-start" gap="xs">
          <TypographyNew variant="body-3" weight="bold">
            {title}
          </TypographyNew>
          <TypographyNew variant="body-4">{body}</TypographyNew>
        </Stack>
      </Group>
    </RouterLink>
  );
});
