import { HTMLAttributes, KeyboardEvent, MouseEvent, ReactNode, useCallback, useRef } from 'react';

import { useContextMenuContext } from '../context-menu.context';
import { useMenuContext } from '../menu.context';
import cl from 'classnames';

import { observer } from '@shared/libs/mobx';

import { useLatestCallback } from '@shared/hooks';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

export interface ItemProps extends HTMLAttributes<HTMLLIElement> {
  icon?: ReactNode;

  color?: 'red' | 'green' | 'blue' | 'default';
}

const { rootClass, appendClass } = classNames('context-menu-item');

const _Item = observer((props: ItemProps) => {
  const { children, className, icon, color, onClick, onMouseUp, ...restProps } = props;

  const { setItemNode } = useMenuContext();

  const itemRef = useRef<HTMLLIElement | null>(null);

  const { controller: contextControler } = useContextMenuContext();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      onClick?.(event);
      contextControler.hide();
    },
    [onClick],
  );
  const hanldeMouseUp = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      onMouseUp?.(event);
      setTimeout(() => contextControler.hide());
    },
    [onMouseUp],
  );
  const handleOnKeyUp = useCallback((event: KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') {
      itemRef?.current?.click();
    }
  }, []);

  const initRefs = useLatestCallback((node: HTMLLIElement | null) => {
    setItemNode(node);
    itemRef.current = node;
  });

  return (
    <li
      className={cl(rootClass, className)}
      ref={initRefs}
      tabIndex={0}
      role="button"
      data-color={color}
      onClick={handleClick}
      onMouseUp={hanldeMouseUp}
      onKeyUp={handleOnKeyUp}
      {...restProps}
    >
      {icon && <div className={appendClass('-icon')}>{icon}</div>}
      {children}
    </li>
  );
});

export const Item = mergeComponentsWithName('Item', _Item);
