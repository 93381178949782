import { routerCollection } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { useAppNavigate } from '@hooks';
import { observer } from 'mobx-react-lite';
import AppleLogin from 'react-apple-login';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Button, SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

export const AppleButton = observer(function AppleButton() {
  const { t } = useTranslation();

  const { authStore, userStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const navigate = useAppNavigate();

  const handleSuccessLogin = async (code: string) => {
    try {
      await authStore.authViaApple({ code });
      await userStore.getMe();

      sendEvent({ event: AnalyticsEvents.LoginAccountTap });

      navigate(routerCollection.dashboard.root);
    } catch (e) {}
  };

  return (
    <AppleLogin
      scope="email"
      clientId={import.meta.env.VITE_APPLE_CLIENT_ID}
      redirectURI={import.meta.env.VITE_APP_DOMAIN}
      callback={(res) => {
        handleSuccessLogin(res.authorization.code);
      }}
      state="initial"
      usePopup
      render={({ onClick }) => {
        return (
          <Button
            className={s.appleButton}
            prefixIcon={<SvgIcon type="apple" />}
            onClick={onClick}
            size="sm"
          >
            {t('shared-content.continue-with-apple')}
          </Button>
        );
      }}
    />
  );
});
