import { CardSkeleton, ContainerWithTitle, mapSkeleton } from '@units';

import { observer } from '@shared/libs/mobx';

import { Box, Group, Skeleton } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

const _PageSkeleton = observer(() => {
  const skeletonCardMapper = mapSkeleton(2, (key) => {
    return <CardSkeleton key={key} />;
  });

  return (
    <>
      <Box>
        <ContainerWithTitle label={<Skeleton width={160} height={20} />}>
          <Group>{skeletonCardMapper}</Group>
        </ContainerWithTitle>
      </Box>
      <Group>
        <ContainerWithTitle label={<Skeleton width={160} height={20} />}>
          <Group>{skeletonCardMapper}</Group>
        </ContainerWithTitle>
      </Group>
    </>
  );
});

export const PageSkeleton = mergeComponentsWithName('PageSkeleton', _PageSkeleton);
