import { useLocation } from 'react-router-dom';

import { observer } from '@shared/libs/mobx';
import { useTranslation } from '@shared/libs/react-i18next';

import { Button, SvgIcon } from '@shared/UI';

import { useClipboard } from '@shared/hooks';
import { mergeComponentsWithName } from '@shared/utils';

const _CommunityShareAccessBtn = observer(() => {
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const clipboard = useClipboard({ timeout: 15 * 60 * 10000 });

  const label = clipboard.copied ? t('shared-content.link-copied') : t('shared-content.share-link');

  const icon = clipboard.copied ? <SvgIcon type="checkmark" /> : <SvgIcon type="share" />;

  const urlToCopy = import.meta.env.VITE_APP_DOMAIN + pathname;

  const handleClickBtn = () => {
    clipboard.copy(urlToCopy);
  };

  return (
    <Button
      disabled={clipboard.copied}
      onClick={handleClickBtn}
      variant="outline-neutral"
      prefixIcon={icon}
    >
      {label}
    </Button>
  );
});

export const CommunityShareAccessBtn = mergeComponentsWithName(
  'CommunityShareAccessBtn',
  _CommunityShareAccessBtn,
);
