import { useEffect } from 'react';

import { AuthType } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { SignInFormByEmail, SignInFormByPhone } from '@features/auth';

export const Login = observer(function Login() {
  const { authStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  useEffect(() => {
    sendEvent({ event: AnalyticsEvents.LoginPageView });
  }, []);

  return authStore.preferredAuthType === AuthType.email ? (
    <SignInFormByEmail />
  ) : (
    <SignInFormByPhone />
  );
});
