import { useMemo } from 'react';

import { YOUTUBE_LINK_ID } from '@constants';
import type { YouTubeProps } from 'react-youtube';
import YouTube from 'react-youtube';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

export interface YouTubeVideoProps extends Omit<YouTubeProps, 'videoId'> {
  url: string;
}

const _YouTubeVideo = observer((props: YouTubeVideoProps) => {
  const { url, ...restProps } = props;

  const videoId = useMemo(() => {
    const match = url.match(YOUTUBE_LINK_ID);

    return match && match[7].length == 11 ? match[7] : undefined;
  }, [url]);

  return <YouTube videoId={videoId} {...restProps} />;
});

export const YouTubeVideo = mergeComponentsWithName('YouTubeVideo', _YouTubeVideo);
