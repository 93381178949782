import { observer } from '@shared/libs/mobx';

import { Group, Skeleton, Stack } from '@shared/UI';

import { useMediaQuery } from '@shared/hooks';
import { mergeComponentsWithName } from '@shared/utils';

export interface PageSkeletonProps {}

const _PageSkeleton = observer((props: PageSkeletonProps) => {
  const {} = props;

  const isMatch = useMediaQuery('(max-width: 1024px)');

  return (
    <Stack gap="xxl" align="flex-start">
      {isMatch ? (
        <>
          <Skeleton height={300} borderRadius={14} />
          <Skeleton height={40} borderRadius={14} />
          <Skeleton height={100} borderRadius={14} />
          <Skeleton height={360} borderRadius={14} />
        </>
      ) : (
        <>
          <Group wrap="nowrap" w="100%">
            <Stack gap="xxl" align="flex-start">
              <Skeleton width={600} height={300} borderRadius={14} />
              <Skeleton width={600} height={40} borderRadius={14} />
              <Skeleton width={600} height={100} borderRadius={14} />
            </Stack>
            {!isMatch && <Skeleton height={472} borderRadius={14} />}
          </Group>

          <Skeleton width={600} height={360} borderRadius={14} />
        </>
      )}
    </Stack>
  );
});

export const PageSkeleton = mergeComponentsWithName('PageSkeleton', _PageSkeleton);
