import { routerCollection } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { useAppNavigate } from '@hooks';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useRootStore } from '@core/store';

import { Button, SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

const GoogleButtonComponent = observer(function GoogleButton() {
  const { t } = useTranslation();

  const { authStore, userStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const navigate = useAppNavigate();

  const handleSuccessLogin = async (googleAccessToken: string) => {
    try {
      await authStore.authViaGoogle({ googleAccessToken });
      await userStore.getMe();

      sendEvent({ event: AnalyticsEvents.LoginAccountTap });

      navigate(routerCollection.dashboard.root);
    } catch (e) {}
  };

  const login = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      handleSuccessLogin(access_token);
    },
    onError: (err) => {
      if (err.error_description) {
        toast.error(err.error_description);
      } else {
        toast.error(err.error);
      }
    },
  });

  const handleClickButton = () => {
    login();
  };

  return (
    <Button
      className={s.googleButton}
      prefixIcon={<SvgIcon type="google" />}
      onClick={handleClickButton}
      size="sm"
    >
      {t('shared-content.continue-with-google')}
    </Button>
  );
});

export const GoogleButton = observer(function GoogleButton() {
  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CONSOLE_KEY}>
      <GoogleButtonComponent />
    </GoogleOAuthProvider>
  );
});
