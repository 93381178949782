import { CSSProperties, ReactNode, Ref } from 'react';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

export interface ScrolledMessageListProps {
  children: ReactNode;
  innerRef?: Ref<HTMLDivElement>;
  itemStartPosition: number;
}

const _ScrolledMessageList = observer((props: ScrolledMessageListProps) => {
  const { children, innerRef, itemStartPosition } = props;

  const listStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transform: `translateY(${itemStartPosition}px)`,
    willChange: 'transform',
  };

  return (
    <div style={listStyle} ref={innerRef}>
      {children}
    </div>
  );
});

export const ScrolledMessageList = mergeComponentsWithName(
  'ScrolledMessageList',
  _ScrolledMessageList,
);
