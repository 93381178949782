import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { Indicator } from '@shared/UI';
import { ButtonNew, ButtonProps } from '@shared/UI/button-new';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface ScrollToBottomBtnProps extends ButtonProps {
  count?: number;
}

const _ScrollToBottomBtn = observer((props: ScrollToBottomBtnProps) => {
  const { count = 0, className, ...restProps } = props;

  return (
    <ButtonNew
      size="icon"
      variant="black"
      className={cl(s.scrollToBottomBtn, className)}
      {...restProps}
    >
      <Indicator count={count} className={s.scrollToBottomBtnIndicator} />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 5V19M12 19L19 12M12 19L5 12"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ButtonNew>
  );
});

export const ScrollToBottomBtn = mergeComponentsWithName('ScrollToBottomBtn', _ScrollToBottomBtn);
