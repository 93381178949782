import { observer } from '@shared/libs/mobx';
import { Controller } from '@shared/libs/react-hook-form';
import type {
  Control,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from '@shared/libs/react-hook-form';

import { Upload } from '@shared/UI';
import type { UploadProps } from '@shared/UI';

export type FileUploaderProps<TFormValues extends FieldValues> = {
  name: FieldPath<TFormValues>;
  rules?: RegisterOptions;
  control?: Control<TFormValues>;
} & UploadProps;

export const FileUploader = observer(function FileUploader<TFormValues extends FieldValues>(
  props: FileUploaderProps<TFormValues>,
) {
  const { name, rules, control, ...restProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Upload
          {...restProps}
          name={field.name}
          onBlur={field.onBlur}
          onChange={(files) => field.onChange(files as any)}
        />
      )}
    />
  );
});
