import type { CSSProperties, ChangeEvent, ReactNode } from 'react';

import { RadioGroupProvider } from '../radio-group.context';
import { observer } from 'mobx-react-lite';

import { useId, useUncontrolled } from '@shared/hooks';
import { componentName } from '@shared/utils';

export interface RadioGroupProps {
  value?: string;

  onChange?: (value: string) => void;

  defaultValue?: string;

  name?: string;

  children: ReactNode;

  direction?: 'vertical' | 'horizontal';

  disabled?: boolean;
}

const _RadioGroup = observer((props: RadioGroupProps) => {
  const {
    name,
    value,
    defaultValue,
    onChange,
    children,
    disabled = false,
    direction = 'vertical',
  } = props;

  const _name = useId(name);

  const [_value, setValue] = useUncontrolled({
    value,
    defaultValue,
    finalValue: '',
    onChange,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setValue(event.currentTarget.value);

  const containerStyles: CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: direction === 'vertical' ? 'column' : 'row',
    gap: 12,
  };

  return (
    <RadioGroupProvider
      value={{
        name: _name,
        onChange: handleChange,
        value: _value,
        disabled,
      }}
    >
      <div style={containerStyles}>{children}</div>
    </RadioGroupProvider>
  );
});

export const RadioGroup = Object.assign(_RadioGroup, {
  ...componentName('RadioGroup'),
});
