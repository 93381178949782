import { validationRules } from '@constants';
import { z } from 'zod';

export const changePasswordSchema = z.object({
  oldPassword: validationRules.password,
  newPassword: validationRules.password,
  firstOtp: validationRules.otp.optional(),
});

export type ChangePasswordSchema = z.infer<typeof changePasswordSchema>;
