import type { CSSProperties } from 'react';
import { useMemo } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { observer } from '@shared/libs/mobx';

import type { BoxProps } from '@shared/UI';
import { Badge, Box, Group, Image, ProgressBar, Stack, Typography } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const courseCard = cva(s.courseCard, {
  variants: {
    size: {
      primary: s.courseCardSizePrimary,
      fullWidth: s.courseCardSizeFullWidth,
    },
  },
  defaultVariants: {
    size: 'primary',
  },
});

export interface CourseCardProps extends Omit<BoxProps, 'title'>, VariantProps<typeof courseCard> {
  image: string;
  title: string;
  companyName?: string;
  date?: string | null;
  isLocked?: boolean;
  progress?: number;
  isPurchased?: boolean;
  groupId?: number;
}

const _CourseCard = observer((props: CourseCardProps) => {
  const {
    image,
    title,
    companyName,
    date,
    isLocked,
    className,
    size,
    style,
    progress,
    ...restProps
  } = props;

  const rootClasses = courseCard({ size, className });

  const rootStyle: CSSProperties = useMemo(
    () => ({
      ...style,
    }),
    [style],
  );

  return (
    <Box intent="glass" className={rootClasses} style={rootStyle} data-image={image} {...restProps}>
      <Image src={image} alt="course tile image" className={s.courseCardBg} />
      <div className={s.courseCardBackdrop} />
      <Stack align="flex-start" justify={companyName ? 'space-between' : 'flex-end'} h="100%">
        {companyName && (
          <Group w="100%">
            <Badge label={companyName} />
          </Group>
        )}
        <Stack align="flex-start" gap="sm">
          <Typography as="h4" variant="title-bold-3">
            {title}
          </Typography>
          {date && (
            <Typography className={s.courseCardDate} as="span" variant="body-medium-1">
              {date}
            </Typography>
          )}
        </Stack>
        {typeof progress === 'number' && progress !== 0 && (
          <ProgressBar
            className={s.courseCardProgress}
            size="sm"
            isHiddenLabel
            progress={progress}
            bgVariant="gray"
          />
        )}
      </Stack>
    </Box>
  );
});

export const CourseCard = mergeComponentsWithName('CourseCard', _CourseCard);
