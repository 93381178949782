import type { MouseEvent } from 'react';
import { useContext, useState } from 'react';

import { VideoTimecodes } from '@components';
import { AnalyticsEvents, PlayerContext, useAnalyticsContext } from '@contexts';
import cl from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { CourseGroupLessonBlockEntity } from '@core/repositories';
import type { VideoTimecodeEntity } from '@core/repositories/_entities';
import { useRootStore } from '@core/store';

import { ProgressBar, SvgIcon, TypographyNew } from '@shared/UI';

import { asHtml, createDateWithMonthFormat } from '@shared/utils';

import s from './styles.module.scss';

interface CourseRoadmapTileProps {
  lesson: CourseGroupLessonBlockEntity;
  index: number;
  activeTimecodeId: number;
  setActiveTimecodeId: (id: number) => void;
}

export const CourseRoadmapTile = observer(function CourseRoadmapTile(
  props: CourseRoadmapTileProps,
) {
  const { lesson, index, activeTimecodeId, setActiveTimecodeId } = props;
  const { title, duration, progress, videoTimecodes, startDate, isOpened: lessonIsOpened } = lesson;
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const playerContext = useContext(PlayerContext);

  const { courseViewStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const lessonStartDate = dayjs(startDate);
  const currentDate = dayjs();
  const isLessonDateNotStarted = currentDate.isBefore(lessonStartDate);

  const isWatchedLesson = videoTimecodes.every((item) => item.isWatched);

  const onLessonClickHandler = (id: number) => {
    if (!lessonIsOpened) return;

    if (id === lesson.id) {
      courseViewStore.setActiveLesson(lesson);

      // setActiveTimecodeId(lesson.videoTimecodes[0].id);

      if (courseViewStore.course?.courseWithModules && courseViewStore.activeModuleId) {
        sendEvent({
          event: AnalyticsEvents.CourseLessonTap,
          payload: {
            course_name: courseViewStore.course?.title,
            course_id: courseViewStore.course?.id,
            module_id: courseViewStore.activeModuleId,
            lesson_name: lesson.title,
            lesson_id: lesson.id,
          },
        });
      } else {
        sendEvent({
          event: AnalyticsEvents.CourseLessonTap,
          payload: {
            course_name: courseViewStore.course?.title,
            course_id: courseViewStore.course?.id,
            lesson_name: lesson.title,
            lesson_id: lesson.id,
          },
        });
      }
    }
  };

  const handleTimecodeClick = (timecode?: VideoTimecodeEntity) => {
    if (courseViewStore.activeLesson.id === lesson.id && timecode) {
      playerContext?.playerInstance?.currentTime(timecode.time);
      setActiveTimecodeId(timecode.id);
    }

    if (courseViewStore.activeLesson.id !== lesson.id) {
      courseViewStore.setActiveLesson(lesson);
    }
  };

  const showTimecodesClickHandler = (e: MouseEvent<HTMLElement>) => {
    if (!lessonIsOpened) return;

    if (isOpen) {
      e.stopPropagation();
    }

    setIsOpen((prevState) => !prevState);
    // setActiveTimecodeId(lesson.videoTimecodes[0].id);
  };

  const rootClasses = cl(s.roadmapTile, {
    [s.roadmapTileActive]: lesson.id === courseViewStore.activeLesson.id,
    [s.roadmapTileCompleted]: progress === 100,
    [s.roadmapTileDisabled]: !lessonIsOpened,
  });

  return (
    <div className={s.roadmapTileWrapper} id={`course-roadmap-lesson-${index}`}>
      <div className={rootClasses} onClick={() => onLessonClickHandler(lesson.id)}>
        {isLessonDateNotStarted && startDate && (
          <div className={s.roadmapTileStartDate}>
            <TypographyNew variant="body-3">{createDateWithMonthFormat(startDate)}</TypographyNew>
          </div>
        )}
        <TypographyNew variant="body-2" className={s.roadmapTileLessonCount}>
          {t('shared-content.lesson')} {index + 1}
        </TypographyNew>
        <TypographyNew variant="title-3" weight="bold" className={s.roadmapTileTitle}>
          {asHtml(title)}
        </TypographyNew>
        <TypographyNew variant="body-3" colorSchema="neutral-200" className={s.roadmapTileDuration}>
          <SvgIcon type="alarm" /> {duration}
        </TypographyNew>
        <div className={s.roadmapTileProgressBar}>
          <TypographyNew
            variant="body-2"
            colorSchema="neutral-400"
            className={s.roadmapTileProgress}
          >
            {t('shared-content.progress')}
          </TypographyNew>
          <ProgressBar progress={progress > 100 || isWatchedLesson ? 100 : progress} />
        </div>
        <div className={s.roadmapTileShowTimecodes}>
          <TypographyNew as="span" variant="body-3" onClick={showTimecodesClickHandler}>
            {isOpen ? t('shared-content.hide-timecode') : t('shared-content.view-timecode')}
          </TypographyNew>
          <SvgIcon
            type="arrow"
            className={cl(
              s.roadmapTileShowTimecodesArrow,
              isOpen ? s.roadmapTileShowTimecodesArrowOpened : '',
            )}
          />
        </div>
      </div>

      {isOpen && (
        <VideoTimecodes
          videoTimecodes={videoTimecodes}
          onTimecodeClick={handleTimecodeClick}
          activeTimecodeId={activeTimecodeId}
        />
      )}
    </div>
  );
});
