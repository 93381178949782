import { EntryPage } from './EntryPage';
import { routerCollection } from '@constants';
import type { RouteObject } from 'react-router-dom';

import { lazyDelay } from '@shared/utils';

const EventsMain = lazyDelay(() => import('./events-main'));

export const eventsRouter: RouteObject = {
  path: routerCollection.events.root,
  element: <EntryPage />,
  children: [
    {
      index: true,
      element: <EventsMain />,
    },
  ],
};
