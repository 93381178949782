import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Select } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _ViewerHomeworkSelection = observer(() => {
  const { i18n } = useTranslation();

  const { myWorkspaceStore } = useRootStore();

  const options = myWorkspaceStore.myCoursesBlocks?.map((block) => ({
    label: block.title,
    value: block.groupId,
    id: block.id,
  }));

  const handleChangeCourse = useCallback(
    (value: number) => {
      myWorkspaceStore.getCourseHomeworks({
        groupId: value,
        lang: i18n.language,
      });
    },
    [i18n.language],
  );

  useEffect(() => {
    if (myWorkspaceStore.currentCourseGroupId && !myWorkspaceStore.courseHomeworksLoader.isLoaded) {
      myWorkspaceStore.getCourseHomeworks({
        groupId: myWorkspaceStore.currentCourseGroupId,
        lang: i18n.language,
      });
    }
  }, [myWorkspaceStore.currentCourseGroupId, i18n.language]);

  return (
    <div className={s.viewerHomeworkSelectionContainer}>
      <Select
        placeholder={'Choose option'}
        options={options ? options : []}
        isLoading={myWorkspaceStore.loader.isLoading}
        onChange={handleChangeCourse}
        defaultValue={myWorkspaceStore.currentCourseGroupId}
      />
    </div>
  );
});

export const ViewerHomeworkSelection = mergeComponentsWithName(
  'ViewerHomeworkSelection',
  _ViewerHomeworkSelection,
);
