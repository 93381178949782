import type { MouseEvent } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';

import { defaultVideoJSOptions, videoContentTypes } from './player.constants';
import { PlayerContext } from '@contexts';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import videojs from 'video.js';
import type PlayerInstance from 'video.js/dist/types/player';
import 'videojs-contrib-quality-levels';

import 'video.js/dist/video-js.css';

import './theme.scss';

import './styles.scss';

interface PlayerProps {
  videoUrl: string;
  videoType: string;
}

export const Player = observer(function Player(props: PlayerProps) {
  const { videoUrl, videoType } = props;

  const [qualityLevels, setQualityLevels] = useState();
  const [isQualityLevelsVisible, setIsQualityLevelsVisible] = useState(false);
  const [activeQualityLevel, setActiveQualityLevel] = useState(100);

  const playerContext = useContext(PlayerContext);

  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<PlayerInstance | null>(null);

  useEffect(() => {
    if (playerContext?.playerInstance) {
      playerContext.playerInstance.src({ src: videoUrl, type: videoType });
    }
  }, [videoUrl]);

  const changeQualityLevel = (index: number) => {
    if (index === undefined) {
      setIsQualityLevelsVisible(false);

      return;
    }

    // @ts-ignore
    let _qualityLevels = playerRef.current?.qualityLevels();

    if (!_qualityLevels) return;

    let enableQualityLevel = (level: number) => {
      for (var i = 0; i < _qualityLevels.length; i++) {
        let qualityLevel = _qualityLevels[i];
        qualityLevel.enabled = i === level ? true : false;

        if (level === 100) {
          qualityLevel.enabled = false;
        }
      }

      _qualityLevels.selectedIndex_ = level;
      _qualityLevels.trigger({ type: 'change', selectedIndex: level });
    };

    enableQualityLevel(index);

    setActiveQualityLevel(index);
    setQualityLevels(_qualityLevels);
    setIsQualityLevelsVisible(false);
  };

  useEffect(() => {
    if (!playerRef.current) {
      if (!videoRef.current) return;

      playerRef.current = videojs(
        videoRef.current,
        {
          ...defaultVideoJSOptions,
          sources: [
            { src: videoUrl, type: videoContentTypes.hls },
            { src: videoUrl, type: videoContentTypes.mp4 },
          ],
        },
        () => {
          playerContext?.setPlayerInstance(playerRef.current);
        },
      );

      // @ts-ignore
      const levels = playerRef.current.qualityLevels();
      setQualityLevels(levels);
    }
  }, [videoRef]);

  useEffect(() => {
    // @ts-ignore
    const btn = playerRef.current.controlBar.addChild('button', {
      clickHandler: function () {
        setIsQualityLevelsVisible((prev) => !prev);
      },
    });
    btn.el_.id = 'quality-levels-control';
    btn.el_.style.display = 'none';

    if (videoType === videoContentTypes.hls) {
      btn.el_.style.display = 'block';
    }

    setActiveQualityLevel(100);

    return () => {
      // @ts-ignore
      playerRef.current.controlBar.removeChild(btn);
    };
  }, [videoType, videoUrl]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
        playerContext?.setPlayerInstance(null);
      }
    };
  }, [playerRef]);

  const handleContextMenuClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
  };

  return (
    <div onContextMenu={handleContextMenuClick} data-vjs-player>
      <video id="video-js-player" ref={videoRef} className="video-js vjs-theme-fantasy" />

      {isQualityLevelsVisible && (
        <div className="quality-levels-popup">
          {
            // @ts-ignore
            qualityLevels?.levels_
              .map((level: any, index: number) => (
                <button
                  key={index}
                  className={cl(
                    'quality-levels-popup__level',
                    activeQualityLevel === index ? 'quality-levels-popup__level-active' : '',
                  )}
                  onClick={() => changeQualityLevel(index)}
                >
                  {level.height}
                </button>
              ))
              .reverse()
          }

          <button
            className={cl(
              'quality-levels-popup__level',
              activeQualityLevel === 100 ? 'quality-levels-popup__level-active' : '',
            )}
            onClick={() => changeQualityLevel(100)}
          >
            auto
          </button>
        </div>
      )}
    </div>
  );
});
