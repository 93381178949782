import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Box, Group, Stack, SvgIcon, TypographyNew } from '@shared/UI';

import { renderCorrectUaNounInPlural, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

interface CourseHeaderProps {
  image: string;
  groupTitle: string;
  lessonsCount: number;
  duration: string;
  title: string;
}

export const CourseHeader = observer(function CourseHeader(props: CourseHeaderProps) {
  const { image, groupTitle, lessonsCount, duration, title } = props;

  const { t, i18n } = useTranslation();

  const lessonsLabel =
    i18n.language === 'ua'
      ? renderCorrectUaNounInPlural(lessonsCount, 'урок')
      : t('shared-content.lessons');

  return (
    <Box px={16} py={16} className={s.courseHeader}>
      <Group wrap="nowrap" align="center" w="100%" className={s.courseHeaderContent}>
        <img src={withMediaUrl(image)} alt="" className={s.courseHeaderImage} />
        <Stack align="flex-start">
          <Group className={s.courseHeaderTop}>
            <TypographyNew variant="body-3" colorSchema="neutral-200">
              {groupTitle}
            </TypographyNew>
            <TypographyNew variant="body-3" colorSchema="neutral-200">
              <SvgIcon type="play" /> {lessonsCount} {lessonsLabel}
            </TypographyNew>
            <TypographyNew variant="body-3" colorSchema="neutral-200">
              <SvgIcon type="alarm" /> {duration}
            </TypographyNew>
          </Group>
          <TypographyNew variant="title-2" weight="bold" colorSchema="neutral-100">
            {title}
          </TypographyNew>
        </Stack>
      </Group>
    </Box>
  );
});
