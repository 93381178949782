import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { t } from 'i18next';

const Layout = {
  STANDARD_DATE_FORMAT: 'DD.MM.YYYY',
  STANDARD_DATE_FORMAT_DAY_AND_MONTH: 'DD.MM',
  FULL_DATE: 'MMMM D, YYYY',
  TIME: 'HH:mm',
  DAY_AND_MONTH: 'DD MMMM',
};

export const createStandardDateFormat = (date: string) => {
  return dayjs(date).format(Layout.STANDARD_DATE_FORMAT);
};

export const createDateWithMonthFormat = (date: string) => {
  return dayjs(date).format(Layout.FULL_DATE);
};

export const hourAndMinuteDate = (date: string) => {
  return dayjs(date).format(Layout.TIME);
};

export const chatDateFormat = (date: string) => {
  const today = dayjs().format('YYYY-MM-DD');
  const dt = dayjs(date).format('YYYY-MM-DD');

  const diffDay = dayjs(today).diff(dt, 'day');
  const diffYear = dayjs(today).diff(dt, 'year');

  if (diffYear > 0) {
    return dayjs(date).format(Layout.STANDARD_DATE_FORMAT);
  }

  if (diffDay === 1) {
    return t('shared-content.yesterday');
  }

  if (diffDay > 1) {
    return dayjs(date).format(Layout.STANDARD_DATE_FORMAT_DAY_AND_MONTH);
  }

  return t('shared-content.today');
};

export const hourAndMinuteWithPreviousDate = (date: string) => {
  const today = dayjs().format('YYYY-MM-DD');
  const dt = dayjs(date).format('YYYY-MM-DD');

  const diffDay = dayjs(today).diff(dt, 'day');
  const diffYear = dayjs(today).diff(dt, 'year');

  if (diffYear > 0) {
    return dayjs(date).format(Layout.STANDARD_DATE_FORMAT);
  }

  if (diffDay === 1) {
    return t('shared-content.yesterday');
  }

  if (diffDay > 1) {
    return dayjs(date).format(Layout.STANDARD_DATE_FORMAT_DAY_AND_MONTH);
  }

  return dayjs(date).format(Layout.TIME);
};

export const dayAndMonth = (date: string) => {
  return dayjs(date).format(Layout.DAY_AND_MONTH);
};

export const getStartOfDay = () => {
  const nowLocal = dayjs();
  const startOfDayLocal = nowLocal.startOf('day');
  const startOfDayUtc = startOfDayLocal.utc();

  return startOfDayUtc.toISOString();
};

export const getEndOfYear = () => {
  const dayjsUTC = dayjs.extend(utc);
  const nowUTC = dayjsUTC.utc();

  return nowUTC.endOf('year').format();
};

export const getFormatedDate = (date: string, dateFormat: string) => {
  return dayjs(date).format(dateFormat);
};
