import type { HTMLAttributes } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import { useClassName } from '@shared/UI/_hooks';

import './styles.scss';

type SpinnerProps = HTMLAttributes<HTMLDivElement> & {
  isFullSize?: boolean;
  size?: 'sm' | 'md' | 'lg';
};

export const Spinner = observer(function Spinner(props: SpinnerProps) {
  const { className, size = 'md', isFullSize, ...restProps } = props;

  const { rootClassName, appendClass } = useClassName('spinner');

  const rootClasses = cl(
    rootClassName,
    appendClass(`--${size}`),
    className,
    isFullSize ? 'full-size-spinner' : '',
  );

  return (
    <div className={rootClasses} {...restProps}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
});
