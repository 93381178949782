import type { ReactNode } from 'react';

import type { CommunityPreviewAccountEntity } from '@core/repositories';

import { observer } from '@shared/libs/mobx';

import { Box, Group, Image, Stack, TypographyNew } from '@shared/UI';

import { asHtml, mergeComponentsWithName, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

export interface CommunityPreviewHeaderProps {
  actionSection?: ReactNode;

  previewAccount: CommunityPreviewAccountEntity;
}

const _CommunityPreviewHeader = observer((props: CommunityPreviewHeaderProps) => {
  const { previewAccount, actionSection } = props;

  return (
    <Group
      align="flex-start"
      justify="flex-start"
      gap="48"
      className={s.communityPreviewHeader}
      wrap="nowrap"
    >
      <Box className={s.communityPreviewHeaderImageBox}>
        <Image src={withMediaUrl(previewAccount.image)} rounded="md" />
      </Box>
      <Stack align="flex-start" gap="xl" className={s.communityPreviewHeaderDescriptionBox}>
        <TypographyNew as="h2" variant="title-1" weight="bold">
          {previewAccount.communityTitle}
        </TypographyNew>
        <TypographyNew>{asHtml(previewAccount.description)}</TypographyNew>

        <Group>{actionSection}</Group>
      </Stack>
    </Group>
  );
});

export const CommunityPreviewHeader = mergeComponentsWithName(
  'CommunityPreviewHeader',
  _CommunityPreviewHeader,
);
