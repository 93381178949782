import type { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';
import type { Control, FieldError, FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { CheckboxProps as CheckboxUIProps } from '@shared/UI';
import { Checkbox as CheckboxUI } from '@shared/UI';

export type CheckboxProps<TFormValues extends FieldValues> = {
  name: FieldPath<TFormValues>;
  children?: ReactNode;
  rules?: RegisterOptions;
  control?: Control<TFormValues>;
  error?: FieldError;
} & CheckboxUIProps;

export const Checkbox = observer(
  <TFormValues extends FieldValues>(props: CheckboxProps<TFormValues>) => {
    const { name, children, control, error, rules, placeholder, ...restProps } = props;

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <CheckboxUI
            hasError={!!error}
            onChange={field.onChange}
            checked={field.value}
            {...restProps}
          >
            {children}
          </CheckboxUI>
        )}
      />
    );
  },
);
