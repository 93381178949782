import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { observer } from '@shared/libs/mobx';

import type { SkeletonProps } from '@shared/UI';
import { Skeleton, Stack } from '@shared/UI';

import type { ElementProps } from '@shared/types';
import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const cardSkeleton = cva(s.cardSkeleton, {
  variants: {
    size: {
      200: s.cardSkeleton200,
      fullWidth: s.cardSkeletonFullWidth,
    },
  },

  defaultVariants: {
    size: 200,
  },
});

export type CardSkeletonProps = {
  hasTitle?: boolean;
} & VariantProps<typeof cardSkeleton> &
  ElementProps<'div'> &
  Pick<SkeletonProps, 'width' | 'height'>;

const _CardSkeleton = observer((props: CardSkeletonProps) => {
  const { size, className, hasTitle = true, height, width, ...restProps } = props;

  const rootClasses = cardSkeleton({ size, className });

  return (
    <Stack className={rootClasses} {...restProps}>
      <Skeleton className={s.cardSkeletonCardElement} height={height} width={width} />
      {hasTitle && <Skeleton className={s.cardSkeletonTitleElement} />}
    </Stack>
  );
});

export const CardSkeleton = mergeComponentsWithName('CardSkeleton', _CardSkeleton);
