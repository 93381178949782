import { useCallback, useRef } from 'react';

import type { IMessageListController } from '../interfaces';
import { ONLY_FIRST_LINK_PATTERN } from '@constants';
import type { Range, VirtualizerOptions } from '@tanstack/react-virtual';
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual';
import { computed } from 'mobx';

import { MessageViewType } from '@core/constants';

export const useMessageListVirtual = (
  control: IMessageListController,
  virtualOptions?: Pick<
    VirtualizerOptions<HTMLElement, Element>,
    'overscan' | 'getItemKey' | 'onChange' | 'rangeExtractor' | 'estimateSize'
  >,
) => {
  const scrollArea = useRef<HTMLElement>(null);

  const getScrollElement = useCallback(() => scrollArea.current, []);

  const estimateSize = useCallback((index: number) => {
    const row = control.rows[index];

    if (!row) return 40;

    if (typeof row === 'string') {
      return 40;
    }

    if (row.type === MessageViewType.Video) {
      return 282;
    }

    if (row.type === MessageViewType.Audio) {
      return 140;
    }

    const hasLink = Boolean(ONLY_FIRST_LINK_PATTERN.exec(row.message)?.[0]);

    if (hasLink && row?.replyMessageInfo?.messageId) {
      return 452;
    }

    if (row?.replyMessageInfo?.messageId) {
      return 146;
    }

    if (hasLink && row.type === MessageViewType.Text) {
      return 400;
    }

    if (row?.attachments.length && row.type === MessageViewType.Text) {
      return 152;
    }

    return 112;
  }, []);

  const activeStickyIndex = useRef(0);

  const stickyIndexes = computed(() => {
    const stack: Set<number> = new Set();

    for (let i = control.rowsWithDate.length - 1; i >= 0; i--) {
      if (typeof control.rowsWithDate[i] === 'string') stack.add(i);
    }

    return [...stack];
  });

  const isActiveSticky = useCallback((index: number) => activeStickyIndex.current === index, []);

  const virtualizer = useVirtualizer({
    count: control.rowsWithDateCount,
    getScrollElement,
    estimateSize,
    getItemKey: control.getRowKeyByIndex,
    rangeExtractor: useCallback(
      (range: Range) => {
        const newStickyIdx = stickyIndexes
          .get()
          .find((index) => Math.max(0, range.startIndex + 1) >= index);

        activeStickyIndex.current = newStickyIdx ?? 0;

        const next = new Set([activeStickyIndex.current, ...defaultRangeExtractor(range)]);

        return [...next].sort((a, b) => a - b);
      },
      [stickyIndexes],
    ),
    ...virtualOptions,
  });

  return {
    isActiveSticky,
    virtualizer,
    scrollArea,
  };
};
