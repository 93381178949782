import type { TargetAndTransition } from 'framer-motion';

export const containerAnimate: TargetAndTransition = {
  opacity: 1,
};

export const containerInitial = {
  opacity: 0,
};

export const containerExit: TargetAndTransition = {
  opacity: 0,
};
