import type { MouseEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { LoadingStore } from '@core/store';

import { makeAutoObservable, observer, runInAction } from '@shared/libs/mobx';

import { Box, Document } from '@shared/UI';

import type { ObjectValues } from '@shared/types';
import type { FetchOutsideFileReturn } from '@shared/utils';
import {
  FileKind,
  fetchOutsideFile,
  imageWithLowSizeQuery,
  kindOfFile,
  mergeComponentsWithName,
  withMediaUrl,
} from '@shared/utils';

import s from './styles.module.scss';

export interface AttachmentProps {
  url: string;

  onClickFile?: (event: MouseEvent<HTMLLIElement>) => void;

  setLoadedFile?: (file: FetchOutsideFileReturn) => void;

  withoutLabel?: boolean;
}

class MediaItemStore {
  file: FetchOutsideFileReturn | null = null;

  fileKind: ObjectValues<typeof FileKind> | null = null;

  loader = new LoadingStore();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  initFile = async (fileUrl: string, cb?: (file: FetchOutsideFileReturn) => void) => {
    this.loader.startLoading();
    try {
      const file = await fetchOutsideFile(withMediaUrl(fileUrl));

      runInAction(() => {
        this.file = file;
        this.fileKind = kindOfFile(file.originUrl);
      });

      cb?.(file);
    } finally {
      this.loader.stopLoading();
      this.loader.setLoaded(true);
    }
  };
}

const _Attachment = observer((props: AttachmentProps) => {
  const { url, onClickFile, setLoadedFile, ...restProps } = props;

  const itemRef = useRef<HTMLLIElement>(null);

  const [mediaItemStore] = useState(() => new MediaItemStore());

  const Element = useMemo(() => {
    if (!mediaItemStore.file) return null;

    switch (mediaItemStore.fileKind) {
      case FileKind.DOCUMENT:
        return (
          <Box className={s.attachmentTilePreview}>
            <Document.Thumbnail doc={mediaItemStore.file.content} />
          </Box>
        );
      case FileKind.IMAGE:
        return (
          <Box className={s.attachmentTilePreview}>
            <img
              width={48}
              height={48}
              src={imageWithLowSizeQuery(mediaItemStore.file.originUrl)}
              loading="lazy"
            />
          </Box>
        );
      default:
        return null;
    }
  }, [mediaItemStore.file]);

  useEffect(() => {
    if (!mediaItemStore.loader.isLoaded) {
      mediaItemStore.initFile(url, setLoadedFile);
    }
  }, []);

  return (
    <li className={s.attachment} role="button" ref={itemRef} onClick={onClickFile} {...restProps}>
      {Element}
    </li>
  );
});

export const Attachment = mergeComponentsWithName('Attachment', _Attachment);
