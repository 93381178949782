import { routerCollection } from '@constants';
import { useAppNavigate } from '@hooks';

import type { CommunityStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Avatar, Group, SvgIcon, TypographyNew } from '@shared/UI';

import { useLatestCallback } from '@shared/hooks';
import { mergeComponentsWithName, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

export interface CommunityItemProps {
  community: CommunityStore;
}

const _CommunityItem = observer((props: CommunityItemProps) => {
  const { community } = props;

  const navigate = useAppNavigate();

  const handleClickCommunity = useLatestCallback(() => {
    navigate(routerCollection.community.community, {
      params: {
        businessAccountId: community.id,
      },
    });
  });

  const itemChevron = community.isActive ? (
    <SvgIcon type="checkmark-with-gradient" fontSize={20} />
  ) : (
    <SvgIcon type="arrow" fontSize={20} className={s.communityItemCheckmarkIcon} />
  );

  const imageSrc = withMediaUrl(community.logo);

  return (
    <li
      className={s.communityItem}
      key={community.id}
      onClick={handleClickCommunity}
      role="button"
      tabIndex={0}
    >
      <Group w="100%" justify="space-between" align="center" wrap="nowrap">
        <Group wrap="nowrap" w="calc(100% - 48px)">
          <Avatar size="lg" src={imageSrc} />
          <TypographyNew variant="title-4" as="span" truncate>
            {community.communityTitle}
          </TypographyNew>
        </Group>
        {itemChevron}
      </Group>
    </li>
  );
});

export const CommunityItem = mergeComponentsWithName('CommunityItem', _CommunityItem);
