import { CardSkeleton, ReviewCard, mapSkeleton } from '@units';

import type { CommunityPreviewReviewEntity } from '@core/repositories';

import { observer } from '@shared/libs/mobx';

import { Carousel } from '@shared/UI';

import { createDateWithMonthFormat, mergeComponentsWithName, withMediaUrl } from '@shared/utils';

export interface ReviewsCarouselProps {
  reviewers: CommunityPreviewReviewEntity;

  isLoading?: boolean;
}

const _ReviewsCarousel = observer((props: ReviewsCarouselProps) => {
  const { reviewers, isLoading } = props;

  const reviewersMapper = reviewers.items.map((review) => {
    if (isLoading) {
      return mapSkeleton(3, (key) => {
        return (
          <Carousel.Slide key={key}>
            <CardSkeleton hasTitle={false} />
          </Carousel.Slide>
        );
      });
    }

    const date = createDateWithMonthFormat(review.createdAt);
    const avatarSrc = withMediaUrl(review.user.image);
    return (
      <Carousel.Slide key={review.id}>
        <ReviewCard
          nickname={review.user.name}
          description={review.comment}
          date={date}
          avatarSrc={avatarSrc}
        />
      </Carousel.Slide>
    );
  });

  return (
    <Carousel navigationPosition="in-header" arrowVariant="outlined">
      {reviewersMapper}
    </Carousel>
  );
});

export const ReviewsCarousel = mergeComponentsWithName('ReviewsCarousel', _ReviewsCarousel);
