import { modalStore } from '@components';
import type { ProductTypes } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { PayPlanModal, SuccessPaymentModal } from '@features-new';
import { CardSkeleton, PriceCard, mapSkeleton } from '@units';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import type { CommunityPreviewPrice } from '@core/repositories';
import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Button, Carousel, Stack } from '@shared/UI';

import type { ObjectValues } from '@shared/types';
import { mergeComponentsWithName } from '@shared/utils';

export interface PriceCarouselProps {
  priceList: CommunityPreviewPrice[];

  subscriptionTitle: string;

  productType: ObjectValues<typeof ProductTypes>;

  isLoading?: boolean;
}

const _PriceCarousel = observer((props: PriceCarouselProps) => {
  const { priceList, subscriptionTitle, productType, isLoading } = props;

  const { t } = useTranslation();

  const { paymentsStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const params = useParams();

  const getParamIDs = () => {
    let value: { courseId: number | null; brandId: number | null } = {
      courseId: null,
      brandId: null,
    };

    if (params?.courseId && 'courseId' in params) {
      value = {
        ...value,
        courseId: +params.courseId,
      };
    }

    if (params?.brandId && 'brandId' in params) {
      value = {
        ...value,
        brandId: +params.brandId,
      };
    }

    if (params?.id && 'id' in params) {
      value = {
        ...value,
        brandId: +params.id,
      };
    }

    return value;
  };

  const renderButtons = (price: CommunityPreviewPrice) => {
    const paramsIDs = getParamIDs();

    const activatePayModal = (isTrial: boolean = false) =>
      modalStore.activateModal({
        component: (
          <PayPlanModal
            price={price}
            subscriptionTitle={subscriptionTitle}
            isTrial={isTrial}
            productType={productType}
            isNeedToUnmount
            // @ts-ignore
            courseId={paramsIDs?.courseId}
          />
        ),
        id: 'pay-plan-modal',
      });

    const buyButtonHandler = () => {
      activatePayModal(false);

      if (paramsIDs?.courseId) {
        sendEvent({
          event: AnalyticsEvents.CoursePaymentTap,
          payload: { course_id: paramsIDs?.courseId, payment_amount: price.price },
        });
      }
    };

    if (price.isFree) {
      const handleClickFreeTariffButton = () => {
        paymentsStore
          .buyFreeCommunity(price.id)
          .then(() => {
            modalStore.activateModal({
              component: <SuccessPaymentModal price={price} isNeedToUnmount />,
              id: 'success-payment-modal',
            });
          })
          .catch((e) => {
            throw e;
          });
      };

      return (
        <Button variant="outline" onClick={handleClickFreeTariffButton}>
          Free
        </Button>
      );
    }

    if (price.price) {
      return (
        <Stack>
          {price.isTrialEnabled && (
            <Button variant="outline" onClick={() => activatePayModal(true)}>
              Try it free
            </Button>
          )}
          <Button onClick={buyButtonHandler}>
            {t('shared-content.buy-for')} {price.price}$
          </Button>
        </Stack>
      );
    }

    return null;
  };

  const slideMapper = priceList.map((price) => {
    if (isLoading) {
      return mapSkeleton(3, (key) => {
        return (
          <Carousel.Slide key={key}>
            <CardSkeleton hasTitle={false} />
          </Carousel.Slide>
        );
      });
    }

    const actionBtn = renderButtons(price);

    return (
      <Carousel.Slide key={price.id}>
        <PriceCard
          title={price.title}
          advantages={price.priceList}
          id={price.id}
          type={price.type}
          isFree={price.isFree}
          isSubscription={price.isSubscription}
          isTrialEnabled={price.isTrialEnabled}
          price={price.price}
          trialDayDuration={price.trialDayDuration}
          bottomSlot={actionBtn}
        />
      </Carousel.Slide>
    );
  });

  return <Carousel>{slideMapper}</Carousel>;
});

export const PriceCarousel = mergeComponentsWithName('PriceCarousel', _PriceCarousel);
