import { useEffect, useRef, useState } from 'react';

import { observer } from '@shared/libs/mobx';

import { Skeleton } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface VideoPreviewProps {
  stream: MediaStream | null;
}

const _VideoPreview = observer((props: VideoPreviewProps) => {
  const { stream } = props;

  const [hasSrc, setHasSrc] = useState(false);

  const videoElement = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoElement.current && stream) {
      setTimeout(() => {
        (videoElement.current as any).srcObject = stream;
        setHasSrc(true);
      });
    }
  }, [stream]);

  return (
    <div className={s.videoPreview}>
      <div className={s.videoPreviewContainer}>
        {!hasSrc && <Skeleton width="100%" height="100%" circle />}
        <video ref={videoElement} autoPlay={Boolean(stream)} />
      </div>
    </div>
  );
});

export const VideoPreview = mergeComponentsWithName('VideoPreview', _VideoPreview);
