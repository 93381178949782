import { useCallback } from 'react';

import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { For, Typography } from '@shared/UI';

import { randomUuid } from '@shared/utils';

import s from './styles.module.scss';

export const InterestsList = observer(function InterestsList() {
  const { userStore } = useRootStore();

  const renderInterests = useCallback(
    (item: any) => {
      const key = randomUuid();
      return (
        <li key={key} className={s.listItem}>
          <Typography as="span" variant="body-medium-3" ellipsis>
            {item.title}
          </Typography>
        </li>
      );
    },
    [userStore.onboards],
  );

  return (
    <ul className={s.list}>
      <For data={userStore.onboards} render={renderInterests} />
    </ul>
  );
});
