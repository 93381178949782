export function redableFileSize(value: string | number) {
  let size = '';

  if (value) {
    const fileSizeInMB = typeof value === 'string' ? parseInt(value) : value / (1024 * 1024);

    if (fileSizeInMB >= 1) {
      size = `${fileSizeInMB.toFixed(2)} MB`;
    } else {
      const fileSizeInKB = typeof value === 'string' ? parseInt(value) : value / 1024;
      size = `${fileSizeInKB.toFixed(2)} KB`;
    }
  } else {
    size = '0 MB';
  }

  return size;
}
