import { useState } from 'react';
import type { HTMLAttributes } from 'react';

import { SUPPORTED_LANGUAGES } from '@constants';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Button, SvgIcon } from '@shared/UI';

import { useOnClickOutside } from '@shared/hooks';

import s from './styles.module.scss';

export type LanguageSwitcherProps = {} & HTMLAttributes<HTMLDivElement>;

export const LanguageSwitcher = observer(function LanguageSwitcher(props: LanguageSwitcherProps) {
  const { ...restProps } = props;

  const { userStore, authStore } = useRootStore();

  const [isOpen, setIsOpen] = useState(false);

  const { i18n } = useTranslation();

  const wrapperRef = useOnClickOutside<HTMLDivElement>(() => setIsOpen(!isOpen));

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    if (authStore.isUserHasAccess) {
      userStore.patchMe({ preferableLang: lang });
    }
  };

  return (
    <div ref={wrapperRef} className={s.languageSwitcher} {...restProps}>
      {i18n.language === SUPPORTED_LANGUAGES.UA && (
        <Button size="icon" onClick={() => changeLanguageHandler(SUPPORTED_LANGUAGES.EN)}>
          <SvgIcon type="ua-flag" />
        </Button>
      )}
      {i18n.language === SUPPORTED_LANGUAGES.EN && (
        <Button size="icon" onClick={() => changeLanguageHandler(SUPPORTED_LANGUAGES.UA)}>
          <SvgIcon type="en-flag" />
        </Button>
      )}
    </div>
  );
});
