import { RouterLink } from '../../routes';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Badge, Button, Image, ProgressBar, TypographyNew } from '@shared/UI';
import type { ButtonProps } from '@shared/UI';

import { asHtml } from '@shared/utils';

import s from './styles.module.scss';

export type LastViewedCourseBlockProps = {
  image: string;

  courseName: string;

  groupId: number;

  id: number;

  progress: number;

  lang: string;

  categoryTitle: string;

  toCourseView: string;

  leftButtonTitle?: string;

  leftButtonProps?: Omit<ButtonProps, 'children'>;
};

export const LastViewedCourseBlock = observer(function LastViewedCourseBlock(
  props: LastViewedCourseBlockProps,
) {
  const {
    id,
    image,
    courseName,
    progress,
    lang,
    categoryTitle,
    leftButtonTitle,
    leftButtonProps,
    toCourseView,
  } = props;

  const { t } = useTranslation();

  const sendEvent = useAnalyticsContext();

  return (
    <div className={s.lastViewedCourseBlock}>
      <div className={s.lastViewedCourseBlockContainer}>
        <div className={s.lastViewedCourseBlockContent}>
          <div className={s.lastViewedCourseBlockImageBox}>
            <Image className={s.lastViewedCourseBlockImage} src={image} isMediaUrl />
          </div>
          <div className={s.lastViewedCourseBlockDescriptionContainer}>
            <div className={s.lastViewedCourseBlockTagBox}>
              <Badge variant="purple" label={lang} />
              <Badge variant="blue" label={categoryTitle} />
            </div>
            <div className={s.lastViewedCourseBlockInfoBox}>
              <TypographyNew as="h3" variant="title-2" weight="bold">
                {asHtml(courseName)}
              </TypographyNew>
            </div>
            <div className={s.lastViewedCourseBlockProgressBox}>
              <TypographyNew as="span" variant="body-4" colorSchema="neutral-400">
                {t('shared-content.progress')}
              </TypographyNew>
              <ProgressBar progress={progress} />
            </div>
            <div className={s.lastViewedCourseBlockButtonBox}>
              <RouterLink to={toCourseView} className={s.lastViewedCourseBlockButtonBoxLink}>
                <Button
                  size="sm"
                  onClick={() =>
                    sendEvent({
                      event: AnalyticsEvents.CourseLearningTap,
                      payload: { course_name: courseName, course_id: id },
                    })
                  }
                  {...leftButtonProps}
                >
                  {leftButtonTitle || t('shared-content.continue-studying')}
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
