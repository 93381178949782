import { validationRules } from '@constants';
import { z } from 'zod';

export const emailFormSchema = z.object({
  email: validationRules.email,
  password: validationRules.password,
  firstOtp: validationRules.otp.optional(),
});

export type EmailFormSchema = z.infer<typeof emailFormSchema>;
