import type { Ref } from 'react';
import { useMemo } from 'react';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { observer } from '@shared/libs/mobx';

import type { ButtonProps } from '@shared/UI';
import { Button, SvgIcon } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const recordButton = cva(s.recordButton, {
  variants: {
    variant: {
      video: s.recordButtonVideo,
      audio: s.recordButtonAudio,
    },
    size: {
      primary: s.recordButtonSizePrimary,
      secondary: s.recordButtonSizeSecondary,
    },
  },
  defaultVariants: {
    variant: 'video',
    size: 'primary',
  },
});

export interface RecordButtonProps
  extends Omit<ButtonProps, 'variant' | 'children' | 'size'>,
    VariantProps<typeof recordButton> {
  innerRef?: Ref<HTMLButtonElement>;
  isRecording: boolean;
}

const _RecordButton = observer((props: RecordButtonProps) => {
  const { variant, size, className, innerRef, isRecording, ...restProps } = props;

  const rootClasses = recordButton({ variant, size, className });

  const iconVariant = useMemo(() => {
    if (isRecording) {
      return 'paper-airplane-2';
    }
    if (variant === 'audio') {
      return 'audio-record';
    }

    return 'video-record';
  }, [variant, isRecording]);

  return (
    <Button ref={innerRef} size="icon" disabledAnimation className={rootClasses} {...restProps}>
      <SvgIcon type={iconVariant} />
    </Button>
  );
});

export const RecordButton = mergeComponentsWithName('RecordButton', _RecordButton);
