import { MessageAudio } from './message-audio';
import { MessageMedia } from './message-media';
import { MessageText } from './message-text';

export * from './message-media';

export const MessageVariant = {
  Text: MessageText,
  TextWithMedia: MessageMedia,
  Audio: MessageAudio,
} as const;
