export const FileKind = {
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document',
  UNKNOWN: 'unknown',
} as const;

const documentExtensions = ['md', 'pdf', 'doc', 'docx', 'odt', 'ppt', 'pptx', 'txt', 'xls', 'xlsx'];
const imageExtensions = ['jpg', 'png', 'jpeg', 'webp', 'gif'];
const videoExtensions = ['webm', 'mkv', 'wmv', 'mp4', 'm4v', 'mpg', 'mpeg', 'mov', 'avi'];

export function isImage(extension: string) {
  return imageExtensions.includes(extension);
}

export function isDocument(extension: string) {
  return documentExtensions.includes(extension);
}

export function isVideo(extension: string) {
  return videoExtensions.includes(extension);
}

export function kindOfFile(path: string) {
  const extension = path.substring(path.lastIndexOf('.') + 1);

  if (isImage(extension)) {
    return FileKind.IMAGE;
  }

  if (isVideo(extension)) {
    return FileKind.VIDEO;
  }
  if (isDocument(extension)) {
    return FileKind.DOCUMENT;
  }

  return FileKind.UNKNOWN;
}
