import { useHomeworkAccordion } from './_hooks';
import { CollapseContentTest } from './collapse-content-test';
import { CollapseContentWriting } from './collapse-content-writing';
import { EmptyContent } from './empty-content';
import { BaseAccordionLabel } from '@components';

import type { MyHomeworkTypes } from '@core/repositories';
import type { CourseHomeworkStore } from '@core/store';
import { HomeworkTestStore, HomeworkWritingStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Accordion, Badge, Box, For, Rating } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface ViewerHomeworkAccordionProps {
  homeworks: CourseHomeworkStore[];
  blockType: MyHomeworkTypes;
}

const _ViewerHomeworkAccordion = observer((props: ViewerHomeworkAccordionProps) => {
  const { homeworks, blockType } = props;

  const { badge, accordionLabel } = useHomeworkAccordion({ blockType });

  const accordionItemContent = (homework: CourseHomeworkStore) => {
    if (homework.homeworksTask instanceof HomeworkTestStore) {
      return <CollapseContentTest task={homework.homeworksTask} blockKey={blockType} />;
    }

    if (homework.homeworksTask instanceof HomeworkWritingStore) {
      return <CollapseContentWriting writing={homework.homeworksTask} blockKey={blockType} />;
    }

    return null;
  };

  const renderAccordionItems = (homework: CourseHomeworkStore) => {
    const labelProps = accordionLabel(homework.homework);

    const itemContent = accordionItemContent(homework);

    return (
      <Accordion.Item
        isLoading={homework.loader.isLoading}
        key={homework.homework.id}
        value={String(homework.homework.id)}
      >
        <Accordion.Control onOpen={homework.getHomeworkTaskById}>
          <BaseAccordionLabel {...labelProps} />
          {homework.blockKey === 'verified' && homework.homework.rating > 0 && (
            <Rating rating={homework.homework.rating} className={s.viewerHomeworkAccordionRating} />
          )}
        </Accordion.Control>
        <Accordion.Panel>{itemContent}</Accordion.Panel>
      </Accordion.Item>
    );
  };

  const isEmpty = homeworks.length === 0;

  return (
    <div className={s.viewerHomeworkAccordion} data-empty={isEmpty}>
      <Badge {...badge} />

      <Box intent="glass" className={s.viewerHomeworkAccordionContainer}>
        {!isEmpty ? (
          <Accordion id={blockType}>
            <For data={homeworks} render={renderAccordionItems} />
          </Accordion>
        ) : (
          <EmptyContent blockKey={blockType} />
        )}
      </Box>
    </div>
  );
});

export const ViewerHomeworkAccordion = mergeComponentsWithName(
  'ViewerHomeworkAccordion',
  _ViewerHomeworkAccordion,
);
