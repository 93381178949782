import type { CSSProperties } from 'react';

import { EachMessage } from './each-message';
import { usePrivateChatMessagesController } from './private-chat-messages.controller';
import { ChatMessagesLoader, ScrollToBottomBtn } from '@features-new';
import { ChatMessagesContainer, MessageListContainer } from '@units';
import { AnimatePresence, motion, useWillChange } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { observer } from '@shared/libs/mobx';

import { Box, EmptyContent, Spinner } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const SPINNER_STYLE = {
  alignSelf: 'center',
  padding: '24px 0',
  height: 20,
} satisfies CSSProperties;

const ANIMATE_DIV_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
} satisfies CSSProperties;

const INITIAL_ANIMATE_DIV = { opacity: 0 };
const ANIMATE_DIV = { opacity: 1, transition: { duration: 1 } };

const _PrivateChatMessageList = observer(() => {
  const { t } = useTranslation();

  const willChange = useWillChange();

  const {
    control,
    scrollArea,
    items,
    triggerDownRef,
    triggerUpRef,
    virtualizer,
    isNearBottom,
    isToBottomScrolling,
    downRef,
    onScrollToBottom,
    onContextMenu,
    setupRowNode,
    onScrollArea,
    isActiveSticky,
    isOnline,
  } = usePrivateChatMessagesController();

  if (control.rowsCount === 0 && control.isInit) {
    return (
      <EmptyContent
        title={t('community.messages.no-messages.title')}
        subtitle={t('community.messages.no-messages.description')}
        className={s.emptyContent}
      />
    );
  }

  if (!isOnline) {
    return (
      <EmptyContent title={t('shared-content.no-internet-connection')} className={s.emptyContent} />
    );
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        className={s.motionWrapper}
        style={{ ...ANIMATE_DIV_STYLE, willChange }}
        initial={INITIAL_ANIMATE_DIV}
        animate={ANIMATE_DIV}
      >
        {(!control.isInit ||
          control.isLoadingRows ||
          !control.isLoadedRows ||
          isToBottomScrolling) && <ChatMessagesLoader />}
        <ChatMessagesContainer
          innerRef={scrollArea}
          onContextMenu={onContextMenu}
          hideScrollbar={!control.isInit}
          onScroll={onScrollArea}
        >
          {control.isUpProcessing.isLoading && <Spinner style={SPINNER_STYLE} />}
          {control.isInit && <Box innerRef={triggerUpRef} h={20} />}
          <MessageListContainer totalSize={virtualizer.getTotalSize}>
            <EachMessage
              isActiveSticky={isActiveSticky}
              items={items}
              scrollAreaRef={scrollArea}
              messageListController={control}
              setupItemNode={setupRowNode}
            />
          </MessageListContainer>
          {control.isDownProcessing.isLoading && <Spinner style={SPINNER_STYLE} />}
          {control.isInit && <Box innerRef={triggerDownRef} h={15} />}
          <div ref={downRef} style={{ height: 1 }} />
        </ChatMessagesContainer>
        {control.isInit && !isNearBottom && (
          <ScrollToBottomBtn
            onClick={() => onScrollToBottom(control.hasUnread)}
            count={control.chatUnreadCount}
            className={s.scrollBtn}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
});

export const PrivateChatMessageList = mergeComponentsWithName(
  'PrivateChatMessageList',
  _PrivateChatMessageList,
);
