import { SubscriptionInfoModal, modalStore } from '@components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Avatar, SvgIcon, Typography } from '@shared/UI';

import { createStandardDateFormat, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

export type SubscriptionItemProps = {
  image: string;
  ownerName: string;
  title: string;
  endDate: string;
  priceList: string[];
  subscriptionId: string;
};

export const SubscriptionItem = observer(function SubscriptionItem(props: SubscriptionItemProps) {
  const { endDate, image, ownerName, title, priceList, subscriptionId } = props;

  const { t } = useTranslation();

  const handleClickSubscription = () => {
    modalStore.activateModal({
      component: (
        <SubscriptionInfoModal
          imageSrc={image}
          ownerName={ownerName}
          title={title}
          endDate={endDate}
          priceList={priceList}
          subscriptionId={subscriptionId}
          isNeedToUnmount
        />
      ),
      id: 'subscription-info-modal',
    });
  };

  return (
    <div className={s.subscriptionItem} onClick={handleClickSubscription}>
      <div className={s.subscriptionItemWrapper}>
        <div className={s.subscriptionItemLeft}>
          <div className={s.subscriptionItemImageBox}>
            <Avatar src={withMediaUrl(image)} size="lg" />
          </div>

          <div className={s.subscriptionItemInfoBox}>
            <Typography as="p" variant="body-medium-2">
              {ownerName}
            </Typography>
            <Typography as="p" variant="body-medium-4">
              {title}
            </Typography>
            <Typography as="p" variant="body-medium-4">
              {t('my-account.my-subscription.active-till')} {createStandardDateFormat(endDate)}
            </Typography>
          </div>
        </div>
        <div>
          <SvgIcon type="arrow-right" />
        </div>
      </div>
    </div>
  );
});
