import type { HTMLAttributes, ReactNode } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type FormErrorMessageProps = { message?: ReactNode } & Omit<
  HTMLAttributes<HTMLSpanElement>,
  'children'
>;

export const FormErrorMessage = observer(function FormErrorMessage(props: FormErrorMessageProps) {
  const { message, className, ...restProps } = props;

  const { t } = useTranslation();

  const messageWithTranslation = typeof message === 'string' ? t(message as any) : message;

  const rootClasses = cl('form-error-message', className);

  return (
    <span className={rootClasses} {...restProps}>
      {messageWithTranslation as any}
    </span>
  );
});
