import { RouterLink } from '@components';
import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';

import { SvgIcon } from '@shared/UI';

export type LogoProps = {
  variant?: 'link' | 'icon';
};

export const Logo = observer(function Logo(props: LogoProps) {
  const { variant = 'link' } = props;

  if (variant === 'icon') {
    return (
      <span>
        <SvgIcon type="logo" />
      </span>
    );
  }

  return (
    <RouterLink to={routerCollection.dashboard.root}>
      <SvgIcon type="logo" />
    </RouterLink>
  );
});
