import i18n from 'i18next';

import { PATH_COLLECTION } from '@core/constants';
import { createHTTPMediaService, createHTTPService } from '@core/services/http';

import type {
  FirstStepAddEmailDto,
  FirstStepAddPhoneDto,
  FirstStepChangePasswordDto,
  GetMeQueryDto,
  PatchMeDto,
  SecondStepAddEmailDto,
  SecondStepAddPhoneDto,
  SecondStepChangePasswordDto,
} from './dto';
import type {
  AddEmailResponse,
  AddPhoneResponse,
  ChangePasswordResponse,
  GetMeResponse,
  PatchMeResponse,
} from './responses';

export class UserRepository {
  private readonly http = createHTTPService(PATH_COLLECTION.USERS.ROOT);

  private readonly httpMedia = createHTTPMediaService(PATH_COLLECTION.MEDIA.ROOT);

  getMe = async (query?: GetMeQueryDto) => {
    const path = PATH_COLLECTION.USERS.ME;

    const { lang = i18n.language || 'ua', ...restQuery } = query || {};

    try {
      const { data } = await this.http.get<GetMeResponse>(path, { query: { lang, ...restQuery } });

      return data;
    } catch (e) {
      throw e;
    }
  };

  patchMe = async (dto: PatchMeDto) => {
    const path = PATH_COLLECTION.USERS.ME;

    try {
      const { data } = await this.http.patch<PatchMeResponse>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };

  uploadImage = async (file: FormData) => {
    const path = PATH_COLLECTION.MEDIA.USER_IMAGE;

    try {
      const { data } = await this.httpMedia.post(path, file);

      return data;
    } catch (e) {
      throw e;
    }
  };

  firstStepAddPhone = async (dto: FirstStepAddPhoneDto) => {
    const path = PATH_COLLECTION.USERS.ADD_PHONE.FIRST_STEP;

    try {
      const { data } = await this.http.patch<AddPhoneResponse>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };

  secondStepAddPhone = async (dto: SecondStepAddPhoneDto) => {
    const path = PATH_COLLECTION.USERS.ADD_PHONE.SECOND_STEP;

    try {
      const { data } = await this.http.patch<AddPhoneResponse>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };

  firstStepAddEmail = async (dto: FirstStepAddEmailDto) => {
    const path = PATH_COLLECTION.USERS.ADD_EMAIL.FIRST_STEP;

    try {
      const { data } = await this.http.patch<AddEmailResponse>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };

  secondStepAddEmail = async (dto: SecondStepAddEmailDto) => {
    const path = PATH_COLLECTION.USERS.ADD_EMAIL.SECOND_STEP;

    try {
      const { data } = await this.http.patch<AddEmailResponse>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };

  firstStepChangePassword = async (dto: FirstStepChangePasswordDto) => {
    const path = PATH_COLLECTION.USERS.CHANGE_PASSWORD.FIRST_STEP;
    try {
      const { data } = await this.http.patch<ChangePasswordResponse>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };

  secondStepChangePassword = async (dto: SecondStepChangePasswordDto) => {
    const path = PATH_COLLECTION.USERS.CHANGE_PASSWORD.SECOND_STEP;
    try {
      const { data } = await this.http.patch<ChangePasswordResponse>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };
}
