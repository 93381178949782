import { Form } from './form';
import { useBrowser } from '@hooks';
import { MediaRecorderProvider } from '@units';
import { AnimatePresence } from 'framer-motion';

import { MessageViewType } from '@core/constants';

import { observer } from '@shared/libs/mobx';

import type { RichTextEditorProps } from '@shared/UI';
import { type UploadedFile } from '@shared/UI';

import { useLatestCallback } from '@shared/hooks';
import type { ObjectValues } from '@shared/types';
import { mergeComponentsWithName, randomUuid } from '@shared/utils';

const createMediaFile = (blob: Blob, fileType: string) => {
  const name = `comms-${randomUuid()}`;
  const file = new File([blob], name, {
    type: fileType,
  });

  return file;
};

export type OnUploadMediaFile = (
  type: ObjectValues<Pick<typeof MessageViewType, 'Audio' | 'Video'>>,
  file: File,
) => void;

export interface ChatFormProps {
  onUploadFiles: (message: string, files: UploadedFile[]) => Promise<any>;
  onSubmitMessage: (message: string, replyMessageId?: string) => void;
  onUploadMediaFile: OnUploadMediaFile;
  editorExtensions?: RichTextEditorProps['extensionsOptions'];
}

const _ChatForm = observer((props: ChatFormProps) => {
  const { onUploadFiles, onSubmitMessage, onUploadMediaFile, editorExtensions } = props;

  const browser = useBrowser();

  const handleStopAudioRecording = useLatestCallback((_blobUrl: string, blob: Blob) => {
    const mimeType = browser && browser === 'Safari' ? 'audio/mp4;codecs=mp4a' : 'audio/webm';

    const file = createMediaFile(blob, mimeType);

    onUploadMediaFile(MessageViewType.Audio, file);
  });

  const handleStopVideoRecording = useLatestCallback((_blobUrl: string, blob: Blob) => {
    const mimeType = browser && browser === 'Safari' ? 'video/mp4;codecs=avc1' : 'video/webm';

    const file = createMediaFile(blob, mimeType);

    onUploadMediaFile(MessageViewType.Video, file);
  });

  return (
    <MediaRecorderProvider
      onStopAudio={handleStopAudioRecording}
      onStopVideo={handleStopVideoRecording}
    >
      <AnimatePresence>
        <Form
          onUploadFiles={onUploadFiles}
          onSubmitMessage={onSubmitMessage}
          editorExtensions={editorExtensions}
        />
      </AnimatePresence>
    </MediaRecorderProvider>
  );
});

export const ChatForm = mergeComponentsWithName('ChatForm', _ChatForm);
