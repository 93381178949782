import { z } from 'zod';

export const cancelSubscriptionReasonsSchema = z
  .object({
    selectedFields: z.array(z.boolean()).refine((value) => value.some((val) => val === true)),
    ownReason: z.string().optional(),
  })
  .superRefine(({ selectedFields, ownReason }, ctx) => {
    if (selectedFields[selectedFields.length - 1] === true && !ownReason) {
      ctx.addIssue({
        code: 'custom',
        path: ['ownReason'],
        message: 'Please, provide your own reason',
      });
    }
  });

export type CancelSubscriptionReasonsSchema = z.infer<typeof cancelSubscriptionReasonsSchema>;
