import { BannerBlock } from '@components';
import { observer } from 'mobx-react-lite';

import type { StreamBannerEntity } from '@core/repositories';

import { TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

interface StreamProps {
  youtubeLink: string;
  title: string;
  description: string;
  banner: StreamBannerEntity | null;
}

export const Stream = observer(function Stream(props: StreamProps) {
  const { youtubeLink, title, description, banner } = props;

  return (
    <div className={s.streamContent}>
      <iframe src={youtubeLink} className={s.streamVideo} />
      <TypographyNew variant="body-2" weight="bold" className={s.streamTitle}>
        {title}
      </TypographyNew>
      <TypographyNew variant="body-2" colorSchema="neutral-300" className={s.streamDescription}>
        {description}
      </TypographyNew>
      {banner && (
        <BannerBlock
          title={banner.title}
          backgroundImage={banner.backgroundUrl}
          button={banner.button}
          description={banner.description}
        />
      )}
    </div>
  );
});
