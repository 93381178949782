import { useRootStore } from '@core/store';

import { useLatestCallback } from '@shared/hooks';

export function useAccessPermissions() {
  const { userStore } = useRootStore();

  const checkBusinessId = useLatestCallback((businessId: number | null | undefined) => {
    if (!businessId || typeof userStore.businessId !== 'number') return false;

    return userStore.businessId === businessId;
  });

  const checkPermissionWithBusinessId = useLatestCallback(
    (permission: boolean, businessId: number | null | undefined) => {
      return permission && checkBusinessId(businessId);
    },
  );

  return { permissions: userStore.permissions, checkBusinessId, checkPermissionWithBusinessId };
}
