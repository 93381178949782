import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';

import type { MessageStore } from '@core/store';

import { createSafeContext } from '@shared/utils';

interface MessageTransmitterContext {
  messageTransmitter: MessageTransmitter;
}

class MessageTransmitter {
  private _replyMessage: MessageStore | null = null;

  private _editableMessage: MessageStore | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get replyMessage() {
    return this._replyMessage;
  }

  get editableMessage() {
    return this._editableMessage;
  }

  get hasReplyMessage() {
    return Boolean(this.replyMessage);
  }

  get hasEditableMessage() {
    return Boolean(this.editableMessage);
  }

  setEditableMessage = (message: MessageStore | null) => {
    runInAction(() => {
      this._editableMessage = message;
    });
  };

  clearEditableMessage = () => {
    this.setEditableMessage(null);
  };

  setReplyMessage = (message: MessageStore | null) => {
    runInAction(() => {
      this._replyMessage = message;
    });
  };

  clearReplyMessage = () => {
    this.setReplyMessage(null);
  };

  clearAll = () => {
    this.clearReplyMessage();
    this.clearEditableMessage();
  };
}

const [_MessageTransmitterProvider, useMessageTransmitterContext] =
  createSafeContext<MessageTransmitterContext>('MessageTransmitterContext');

const MessageTransmitterProvider = observer(({ children }: { children: ReactNode }) => {
  const messageTransmitter = useMemo(() => new MessageTransmitter(), []);

  return (
    <_MessageTransmitterProvider
      value={{
        messageTransmitter,
      }}
    >
      {children}
    </_MessageTransmitterProvider>
  );
});

export { MessageTransmitter, MessageTransmitterProvider, useMessageTransmitterContext };
