import { HTMLAttributes } from 'react';

import cl from 'classnames';

import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

export interface ContentProps extends HTMLAttributes<HTMLUListElement> {}

const { rootClass } = classNames('context-menu-content');

const _Content = observer((props: ContentProps) => {
  const { children, className, ...restProps } = props;

  return (
    <ul className={cl(rootClass, className)} {...restProps}>
      {children}
    </ul>
  );
});

export const Content = mergeComponentsWithName('Content', _Content);
