import { TargetAndTransition } from 'framer-motion';

export const animationInner = {
  animate: {
    opacity: 1,
  } satisfies TargetAndTransition,

  initial: {
    opacity: 0,
  },

  exit: {
    opacity: 0,
  } satisfies TargetAndTransition,
} as const;
