import { observer } from '@shared/libs/mobx';

import { Group, Indicator, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

export interface OnlineIndicatorProps {
  withLabel?: boolean;
  isOnline: boolean;
}

const _OnlineIndicator = observer((props: OnlineIndicatorProps) => {
  const { isOnline, withLabel = true } = props;

  return (
    <Group gap="xs" align="flex-start">
      {withLabel && (
        <TypographyNew colorSchema={isOnline ? 'secondary-200' : 'neutral-600'} variant="body-3">
          {isOnline ? 'Online' : 'Offline'}
        </TypographyNew>
      )}
      {isOnline && <Indicator variant="green" />}
    </Group>
  );
});

export const OnlineIndicator = mergeComponentsWithName('OnlineIndicator', _OnlineIndicator);
