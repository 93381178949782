import type { MouseEvent } from 'react';

// @ts-ignore
import { ReactTinyLink } from 'react-tiny-link';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { ObjectValues } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

export const ReactTinyLinkType = {
  TYPE_AMAZON: 'TYPE_AMAZON',
  TYPE_YOUTUBE: 'TYPE_YOUTUBE',
  TYPE_AUDIO: 'TYPE_AUDIO',
  TYPE_VIDEO: 'TYPE_VIDEO',
  TYPE_IMAGE: 'TYPE_IMAGE',
  TYPE_DEFAULT: 'TYPE_DEFAULT',
  TYPE_INSTAGRAM: 'TYPE_INSTAGRAM',
  TYPE_BOARDGAMEGEEK: 'TYPE_BOARDGAMEGEEK',
  TYPE_TWITTER: 'TYPE_TWITTER',
} as const;

export interface IReactTinyLinkData {
  description: string;
  image: string[];
  title: string;
  type: ObjectValues<typeof ReactTinyLinkType>;
  video: string[];
  url: string;
}

export type CardSizeType = 'small' | 'large';

export interface PreviewLinkProps {
  cardSize?: CardSizeType;
  maxLine?: number;
  minLine?: number;
  url: string;
  requestHeaders?: Headers | string[][] | Record<string, string>;
  header?: string;
  onError?: (error: Error) => void;
  onSuccess?: (response: IReactTinyLinkData) => void;
  onClick?: (e: MouseEvent<HTMLElement>, response?: IReactTinyLinkData) => void;
  description?: string;
  showGraphic?: boolean;
  autoPlay?: boolean;
  width?: string | number;
  noCache?: boolean;
  proxyUrl?: string;
  loadSecureUrl?: boolean;
  scraper?: (
    url: string,
    httpClient: any,
    defaultMedia: any,
  ) => Promise<{
    title: any;
    description: any;
    url: any;
    video: any[];
    image: any[];
    type: ObjectValues<typeof ReactTinyLinkType>;
  }>;
  defaultMedia?: string;
  className?: string;
}

const { rootClass } = classNames('preview-link');

const _PreviewLink = observer((props: PreviewLinkProps) => {
  const { maxLine = 2, className, ...restProps } = props;

  const rootClasses = cl(rootClass, className);

  return (
    <div className={rootClasses}>
      <ReactTinyLink maxLine={maxLine} {...restProps} />
    </div>
  );
});

export const PreviewLink = mergeComponentsWithName('PreviewLink', _PreviewLink);
