import type { FormEventHandler, ReactNode } from 'react';

import { Bottom } from './bottom';
import { Header } from './header';
import { Switcher } from './switcher';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { Box, Spinner } from '@shared/UI';

import s from './styles.module.scss';

type FormBoxProps = {
  children: ReactNode;

  title: ReactNode;
  subTitle?: ReactNode;

  hideGoogleButton?: boolean;
  hideSwitcher?: boolean;

  onSubmit?: FormEventHandler<HTMLFormElement>;
};

export const FormBox = observer(function FormBox(props: FormBoxProps) {
  const { children, title, subTitle, hideGoogleButton, hideSwitcher, onSubmit } = props;

  const { authStore } = useRootStore();

  const getContent = () => {
    if (authStore.isReCaptchaLoading) {
      return <Spinner />;
    }

    return (
      <div className={s.formBoxContainer}>
        <Header title={title} subTitle={subTitle} />
        {!hideSwitcher && <Switcher />}
        <Box my={[15, 0]}>
          <form onSubmit={onSubmit}>{children}</form>
        </Box>
        {!hideGoogleButton && <Bottom />}
      </div>
    );
  };

  return <div className={s.formBox}>{getContent()}</div>;
});
