import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type {
  ReactMediaRecorderHookProps,
  ReactMediaRecorderRenderProps,
} from 'react-media-recorder-2';
import { useReactMediaRecorder } from 'react-media-recorder-2';

import type { ObjectValues } from '@shared/types';
import { createSafeContext, mergeComponentsWithName } from '@shared/utils';

const padStart = (num: number) => {
  return num.toString().padStart(2, '0');
};

const formatMs = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  minutes = minutes % 60;
  seconds = seconds % 60;

  const ms = Math.floor((milliseconds % 1000) / 10);

  let str = `${padStart(minutes)}:${padStart(seconds)}.${padStart(ms)}`;

  if (hours > 0) {
    str = `${padStart(hours)}:${str}`;
  }

  return str;
};

class StopwatchStore {
  private intervalId: ReturnType<typeof setInterval> | undefined = undefined;

  private _isRunning = false;

  private _time = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isRunning() {
    return this._isRunning;
  }

  get time() {
    return formatMs(this._time);
  }

  start = () => {
    if (!this._isRunning) {
      runInAction(() => {
        this._isRunning = true;
      });
      this.interval();
    }
  };

  stop = () => {
    if (this._isRunning) {
      runInAction(() => {
        this._time = 0;
        this._isRunning = false;
        clearInterval(this.intervalId);
        this.intervalId = undefined;
      });
    }
  };

  private interval = () => {
    const startTime = Date.now();
    this.intervalId = setInterval(() => {
      runInAction(() => {
        this._time = Date.now() - startTime;
      });
    }, 10);
  };
}

export const RecordingType = {
  Audio: 'audio',
  Video: 'video',
  Idle: 'idle',
} as const;

interface MediaRecorderContext {
  recordingType: ObjectValues<typeof RecordingType>;
  startRecordingAudio: () => void;
  startRecordingVideo: () => void;
  isCanceled: () => boolean;
  cancelRecord: () => void;
  audioRecordOptions: ReactMediaRecorderRenderProps;
  videoRecordOptions: ReactMediaRecorderRenderProps;
  stopwatch: StopwatchStore;
  stopRecording: () => void;
  isRecording: boolean;
}

const [RecorderProvider, useMediaRecorderContext] =
  createSafeContext<MediaRecorderContext>('MediaRecorder');

interface MediaRecorderProps
  extends Partial<Omit<ReactMediaRecorderHookProps, 'audio' | 'video' | 'onStop'>> {
  children: ReactNode;
  onStopAudio?: (blobUrl: string, blob: Blob) => void;
  onStopVideo?: (blobUrl: string, blob: Blob) => void;
}

const _MediaRecorder = observer((props: MediaRecorderProps) => {
  const { children, onStopAudio, onStopVideo, ...restProps } = props;

  const videoRecordOptions = useReactMediaRecorder({
    ...restProps,
    video: true,
    onStop: handleStopVideoRecording,
  });

  const audioRecordOptions = useReactMediaRecorder({
    ...restProps,
    audio: true,
    video: false,
    onStop: (blobUrl, blob) => {
      handleStopAudioRecording(blobUrl, blob);
    },
  });

  const [recordingType, setRecordingType] = useState<ObjectValues<typeof RecordingType>>('idle');

  const isCanceled = useRef(false);

  const isStopped = useRef(false);

  const [stopwatch] = useState(() => new StopwatchStore());

  const startRecordingAudio = useCallback(() => {
    isStopped.current = false;
    isCanceled.current = false;
    setRecordingType(RecordingType.Audio);
    audioRecordOptions.startRecording();
  }, []);

  const startRecordingVideo = useCallback(() => {
    isStopped.current = false;
    isCanceled.current = false;
    setRecordingType(RecordingType.Video);
    videoRecordOptions.startRecording();
  }, []);

  const stopRecording = useCallback(() => {
    isStopped.current = true;
    if (recordingType === RecordingType.Video) {
      videoRecordOptions.stopRecording();
    }

    if (recordingType === RecordingType.Audio) {
      audioRecordOptions.stopRecording();
    }
  }, [videoRecordOptions, audioRecordOptions, recordingType]);

  const cancelRecord = useCallback(() => {
    isCanceled.current = true;
    if (recordingType === RecordingType.Audio) {
      audioRecordOptions.stopRecording();
      audioRecordOptions.clearBlobUrl();
    }
    if (recordingType === RecordingType.Video) {
      videoRecordOptions.stopRecording();
      videoRecordOptions.clearBlobUrl();
    }
  }, [recordingType]);

  function handleStopAudioRecording(blobUrl: string, blob: Blob) {
    if (!isCanceled.current) {
      onStopAudio?.(blobUrl, blob);
    }
    stopwatch.stop();
  }

  function handleStopVideoRecording(blobUrl: string, blob: Blob) {
    if (!isCanceled.current) {
      onStopVideo?.(blobUrl, blob);
    }
    stopwatch.stop();
  }

  useEffect(() => {
    if (
      recordingType === RecordingType.Video &&
      videoRecordOptions.status === 'recording' &&
      videoRecordOptions.previewStream
    ) {
      stopwatch.start();
    }
    if (
      recordingType === RecordingType.Audio &&
      audioRecordOptions.status === 'recording' &&
      audioRecordOptions.previewStream
    ) {
      stopwatch.start();
    }
    return () => {
      if (
        !isStopped.current &&
        (audioRecordOptions.status === 'recording' || videoRecordOptions.status === 'recording')
      ) {
        cancelRecord();
      }
    };
  }, [videoRecordOptions.status, recordingType, audioRecordOptions.status]);

  const value: MediaRecorderContext = {
    recordingType,
    startRecordingAudio,
    startRecordingVideo,
    isCanceled: () => isCanceled.current,
    cancelRecord,
    audioRecordOptions,
    videoRecordOptions,
    stopwatch,
    stopRecording,
    isRecording:
      audioRecordOptions.status === 'recording' || videoRecordOptions.status === 'recording',
  };

  return <RecorderProvider value={value}>{children}</RecorderProvider>;
});

const MediaRecorderProvider = mergeComponentsWithName('MediaRecorder', _MediaRecorder);

export { MediaRecorderProvider, useMediaRecorderContext };
