import { useCallback } from 'react';

import { UI_CLASS_PREFIX } from '../../_constants';

export const useClassName = (className: string, customPrefix?: string) => {
  const prefix = customPrefix ?? UI_CLASS_PREFIX;

  const rootClassName = `${prefix}-${className}`;

  const appendClass = useCallback(
    (childClass: string | string[]) => {
      if (Array.isArray(childClass)) {
        return childClass.map((child) => `${rootClassName}${child}`).join(' ');
      }

      return `${rootClassName}${childClass}`;
    },
    [rootClassName],
  );

  return { rootClassName, appendClass };
};
