import { useRef } from 'react';

import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import type { EmojiDetails } from '@features-new';
import { EmojiPicker } from '@features-new';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Input, SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

type CommentFormType = {
  comment: string;
};

export const CommentForm = observer(function CommentForm() {
  const { t } = useTranslation();

  const { courseViewStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const { control, handleSubmit, setValue, watch } = useForm<CommentFormType>({
    defaultValues: { comment: '' },
  });

  const comment = watch('comment', '');

  const onSubmit = ({ comment }: CommentFormType) => {
    const trimmedComment = comment.trim();

    if (trimmedComment && courseViewStore.course) {
      courseViewStore.postComment(
        courseViewStore.course.id,
        courseViewStore.activeLesson.id,
        trimmedComment,
      );

      sendEvent({
        event: AnalyticsEvents.CourseCommentTap,
        payload: {
          comment_text: trimmedComment,
          course_name: courseViewStore.course.title,
          course_id: courseViewStore.course.id,
          lesson_name: courseViewStore.activeLesson.title,
          lesson_id: courseViewStore.activeLesson.id,
        },
      });
    }

    setValue('comment', '');
  };

  const onSelectEmoji = (details: EmojiDetails) => {
    if (inputRef) {
      const cursorPosition = inputRef.current?.selectionStart || 0;
      const text =
        comment.slice(0, cursorPosition) + details.native + comment.slice(cursorPosition);
      setValue('comment', text);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.commentForm}>
      <div className={s.commentFormInputWrapper}>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <Input
              placeholder={t('shared-content.write-comment')}
              value={field.value}
              onChange={field.onChange}
              className={s.commentFormInput}
              ref={inputRef}
            />
          )}
        />
        <div className={s.commentFormEmojiPicker}>
          <EmojiPicker onSelect={onSelectEmoji} />
        </div>
      </div>
      <button type="submit" className={s.commentFormButton}>
        <SvgIcon type="send" />
      </button>
    </form>
  );
});
