import { OnlineIndicator } from '@units';

import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Avatar, Group, Stack, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName, withMediaUrl } from '@shared/utils';

export interface PrivateChatHeaderProps {}

const _PrivateChatHeader = observer((props: PrivateChatHeaderProps) => {
  const {} = props;

  const { privateMessagesStore } = useRootStore();

  const companion = privateMessagesStore.activeChatCompanion;

  if (!companion) {
    return null;
  }

  return (
    <Group wrap="nowrap" w="100%">
      {companion.image && <Avatar src={withMediaUrl(companion.image)} />}
      <Stack align="flex-start" gap="xs">
        <TypographyNew weight="bold">{companion.name}</TypographyNew>
        <OnlineIndicator isOnline={companion.isOnline ?? false} />
      </Stack>
    </Group>
  );
});

export const PrivateChatHeader = mergeComponentsWithName('PrivateChatHeader', _PrivateChatHeader);
