import {
  UseCommunityChatControllerProps,
  useCommunityChatController,
} from './community-chat.controller';
import { CommunityChatItem } from '@units';

import { observer } from '@shared/libs/mobx';

import { SortableDndBox } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface CommunityChatProps extends UseCommunityChatControllerProps {}

const _CommunityChat = observer((props: CommunityChatProps) => {
  const { chat } = props;

  const { isActive, isDisabled, isDraggable, onClickChat } = useCommunityChatController({ chat });

  return (
    <li
      data-chat-id={chat.id}
      data-chat-status={chat.status}
      data-disabled={isDisabled}
      data-active={isActive}
      className={s.channelItem}
      onClick={onClickChat}
      onTouchEnd={onClickChat}
    >
      <SortableDndBox
        dragId={chat.id}
        isDraggable={Boolean(isDraggable)}
        className={s.channelItemDraggableBox}
      >
        <CommunityChatItem chat={chat} />
      </SortableDndBox>
    </li>
  );
});

export const CommunityChat = mergeComponentsWithName('CommunityChat', _CommunityChat);
