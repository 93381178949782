import type { CreateChatForm } from './create-chat-modal.form';
import { createChatSchema } from './create-chat-modal.form';
import type { BaseModalProps } from '@components';
import { FormFields } from '@components';
import { zodResolver } from '@hookform/resolvers/zod';
import cl from 'classnames';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CommunityChatStatus, CommunityChatType } from '@core/constants';
import type { ChannelStore, ChatStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Box, Group, Modal, Stack, SwitchBtn, TypographyNew } from '@shared/UI';
import { ButtonNew } from '@shared/UI/button-new';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface CreateChatModalProps extends BaseModalProps {
  channel: ChannelStore;
  chat?: ChatStore;
}

const _CreateChatModal = observer((props: CreateChatModalProps) => {
  const { channel, onClose, chat, ...restProps } = props;

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateChatForm>({
    defaultValues: {
      title: chat?.title ?? '',
      description: chat?.description ?? '',
      discordChannelId: '',
      isReadOnly: chat ? chat.type === CommunityChatType.Channel : false,
    },
    resolver: zodResolver(createChatSchema),
  });

  const _handleSubmit: SubmitHandler<CreateChatForm> = (details) => {
    const { isReadOnly, description, discordChannelId, title } = details;
    const chatType = isReadOnly ? CommunityChatType.Channel : CommunityChatType.Chat;

    const dto = {
      description,
      title,
      discordChannelId,
      type: chatType,
      status: CommunityChatStatus.Active,
    };

    if (chat) {
      chat.updateChat(Object.assign(dto, { status: chat.status }));
    } else {
      channel.createChatEmitter(dto);
    }

    onClose?.();
  };

  const onHandleDelete = () => {
    chat?.updateChat({ status: CommunityChatStatus.Closed });

    onClose?.();
  };

  const onHandleRestore = () => {
    chat?.updateChat({ status: CommunityChatStatus.Active });

    onClose?.();
  };

  const title = (
    <TypographyNew as="h4" variant="body-1" weight="bold">
      {t('modals.create-communication-item.channel.title')}
    </TypographyNew>
  );

  return (
    <Modal onClose={onClose} centered title={title} className={s.createChatModal} {...restProps}>
      <Stack gap="xxl" align="stretch" className={s.createChatModalBody}>
        <TypographyNew variant="body-3" colorSchema="neutral-200">
          {t('modals.create-communication-item.channel.description')}
        </TypographyNew>
        <Box as="form" onSubmit={handleSubmit(_handleSubmit)}>
          <FormFields.Input
            control={control}
            name="title"
            label={t('inputs.channel-name.label')}
            placeholder={t('inputs.channel-name.placeholder')}
            error={errors.title}
          />
          <FormFields.Input
            control={control}
            name="description"
            label={t('inputs.channel-description.label')}
            placeholder={t('inputs.channel-description.placeholder')}
            error={errors.description}
          />
          <FormFields.Input
            control={control}
            name="discordChannelId"
            label={t('inputs.discord-channel-id.label')}
            placeholder={t('inputs.discord-channel-id.placeholder')}
            mask="999 999 999 999 999 9999"
          />

          <Stack align="stretch" gap="xxl">
            <Group wrap="nowrap">
              <TypographyNew>
                {t('modals.create-communication-item.channel.view-only.description')}
              </TypographyNew>
              <Controller
                render={({ field }) => (
                  <SwitchBtn checked={field.value} onChange={field.onChange} />
                )}
                control={control}
                name="isReadOnly"
              />
            </Group>

            <Group wrap="nowrap" justify="center" grow>
              <ButtonNew type="submit" fullWidth>
                {t('modals.create-communication-item.channel.submit-button')}
              </ButtonNew>

              {chat && (
                <ButtonNew
                  onClick={
                    chat.status === CommunityChatStatus.Active ? onHandleDelete : onHandleRestore
                  }
                  type="button"
                  fullWidth
                  variant="outline"
                  className={cl({
                    [s.createChatModalRemoveBtn]: chat?.status === CommunityChatStatus.Active,
                    [s.createChatModalRestoreBtn]: chat?.status === CommunityChatStatus.Closed,
                  })}
                >
                  {chat.status === CommunityChatStatus.Active
                    ? t('modals.communication-item-settings.channel.delete-button')
                    : t('modals.communication-item-settings.channel.restore-button')}
                </ButtonNew>
              )}
            </Group>
          </Stack>
        </Box>
      </Stack>
    </Modal>
  );
});

export const CreateChatModal = mergeComponentsWithName('CreateChatModal', _CreateChatModal);
