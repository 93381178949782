import { RouterLink } from '@components';
import { routerCollection } from '@constants';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const ForgotPasswordLink = observer(function ForgotPasswordLink() {
  const { t } = useTranslation();

  return (
    <RouterLink to={routerCollection.auth.forgotPassword}>
      {t('shared-content.forgot-password')}
    </RouterLink>
  );
});
