import { type CSSProperties, type ReactNode, useLayoutEffect } from 'react';

import cl from 'classnames';
import type { HTMLMotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';

import { Portal, SvgIcon } from '@shared/UI';
import {
  animateInner,
  animateOverlay,
  exitInner,
  exitOverlay,
  initialAnimationInner,
  initialAnimationOverlay,
} from '@shared/UI/modal/modal.config';

import { useId } from '@shared/hooks';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

type ModalSizes = string | number | 'sm' | 'md' | 'lg' | 'xl' | 'fullSize' | 'auto';

export interface ModalProps extends Omit<HTMLMotionProps<'div'>, 'title' | 'children'> {
  classNameWrapper?: string;
  title?: ReactNode;
  isOpen?: boolean;
  variant?: 'non-styled' | 'primary';
  withHeader?: boolean;
  centered?: boolean;
  size?: ModalSizes;
  onClose?: () => void;
  isNeedToUnmount?: boolean;
  children?: ReactNode;
  closeOnEscape?: boolean;
  innerProps?: Omit<HTMLMotionProps<'div'>, 'children'>;
}

const getModalSize = (size?: ModalSizes) => {
  if (!size) return '0 0 27.8rem';
  switch (size) {
    case 'sm':
      return '0 0 23.75rem';
    case 'md':
      return '0 0 27.5rem';
    case 'lg':
      return '0 0 40.75rem';
    case 'xl':
      return '0 0 48.75rem';
    case 'fullSize':
      return '0 0 100vw';
    case 'auto':
      return '0 0 auto';
    default:
      if (typeof size === 'number') {
        return `0 0 ${size}px`;
      }
      return `0 0 ${size}`;
  }
};

const { rootClass, appendClass } = classNames('modal');

const _Modal = observer((props: ModalProps) => {
  const {
    className,
    classNameWrapper,
    title,
    children,
    id,
    isOpen,
    withHeader = true,
    centered = false,
    variant = 'primary',
    size = 'md',
    innerProps,
    closeOnEscape = true,
    onClose,
    ...restProps
  } = props;

  const { className: classNameInner, ...restInnerProps } = innerProps || {};

  const wrapperId = useId(id);

  const rootClasses = cl(
    rootClass,
    appendClass(`--${variant}`),
    appendClass(`--${size}`),
    {
      [appendClass('--open')]: isOpen,
      [appendClass('--hidden')]: !isOpen,
      [appendClass('--centered')]: centered,
    },
    className,
  );
  const wrapperClasses = cl(appendClass('-wrapper'), classNameWrapper);

  const innerClasses = cl(appendClass('-inner'), classNameInner);

  const wrapperStyles: CSSProperties = {
    flex: getModalSize(size),
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && closeOnEscape) {
      onClose?.();
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal wrapperId={wrapperId}>
          <motion.div {...restProps} className={rootClasses}>
            <motion.div
              initial={initialAnimationOverlay}
              animate={animateOverlay}
              exit={exitOverlay}
              className={appendClass('-overlay')}
              onClick={onClose}
            />
            <motion.div
              initial={initialAnimationInner}
              animate={animateInner}
              exit={exitInner}
              className={innerClasses}
              {...restInnerProps}
            >
              <div
                className={wrapperClasses}
                role="dialog"
                style={wrapperStyles}
                aria-modal="true"
                tabIndex={-1}
              >
                {withHeader && (
                  <div className={appendClass('-header')}>
                    {title}
                    <button className={appendClass('-close-btn')} onClick={onClose}>
                      <SvgIcon type="cross-2" />
                    </button>
                  </div>
                )}
                <motion.div
                  initial={{
                    opacity: 0,
                    visibility: 'hidden',
                  }}
                  animate={{
                    opacity: 1,
                    visibility: 'visible',
                    transition: {
                      duration: 1,
                      ease: 'easeInOut',
                    },
                  }}
                  exit={{
                    opacity: 0,
                    visibility: 'hidden',
                  }}
                  className={appendClass('-body')}
                >
                  {children}
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  );
});
export const Modal = mergeComponentsWithName('Modal', _Modal);
