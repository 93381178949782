import type { MouseEvent } from 'react';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';

import { QuickEmoji } from '@features-new';
import type { IMessageListController } from '@units';
import {
  OBSERVE_ROOT_MARGIN,
  SCROLL_OPTIONS_START,
  TalkingArea,
  useMessageListScroll,
  useMessageListVirtual,
  useMessageParams,
  useMessageTransmitterContext,
  useObserveLoadTriggers,
} from '@units';
import { makeAutoObservable, runInAction, when } from 'mobx';
import { useTranslation } from 'react-i18next';
import type Player from 'video.js/dist/types/player';
import WaveSurfer from 'wavesurfer.js';

import { LoadMessageDirection } from '@core/constants';
import type { MessageStore } from '@core/store';
import { LoadingStore, rootStore, useRootStore } from '@core/store';

import { ContextMenuNew, Stack, SvgIcon, useContextMenu } from '@shared/UI';

import { chatDateFormat, extractNode } from '@shared/utils';

class PrivateChatMessagesController implements IMessageListController {
  public isInit = false;

  public isInitializing = false;

  public hadLoadedFirstNewMessages = false;

  public isUpProcessing = new LoadingStore();

  public isDownProcessing = new LoadingStore();

  public triggerUpMessage: MessageStore | null = null;

  private mediaElements: Map<string, WaveSurfer | Player> = new Map();

  private messagesStore = rootStore.privateMessagesStore;

  private chatsStore = rootStore.privateChatsStore;

  constructor(private chatId?: string) {
    makeAutoObservable<this, 'mediaElements'>(
      this,
      {
        mediaElements: false,
      },
      { autoBind: true },
    );
  }

  public get rows() {
    if (!this.chatId) return [];
    const messages = this.messagesStore.getMessagesByChatId(this.chatId);
    return messages;
  }

  public get rowsWithDate() {
    return this.rows.reduce((acc: Array<MessageStore | string>, row) => {
      const date = chatDateFormat(row.createDate);
      const alreadyExistDate = acc.includes(date);

      if (alreadyExistDate) {
        acc.push(row);
      } else {
        acc.push(date);
        acc.push(row);
      }

      return acc;
    }, []);
  }

  public get hasUnread() {
    return this.rows.some((msg) => !msg.isViewed);
  }

  public get rowsWithDateCount() {
    return this.rowsWithDate.length;
  }

  public get rowsCount() {
    return this.rows.length;
  }

  public get chatUnreadCount() {
    if (!this.chatId) return 0;
    const chat = this.chatsStore.getChatById(this.chatId);
    if (!chat) return 0;
    return chat.unreadCount;
  }

  public get isLoadingRows() {
    let activeMessagesLoading = false;

    if (this.chatId) {
      const chatBucket = this.messagesStore.getChatBucketByChatId(this.chatId);

      activeMessagesLoading = chatBucket?.loader.isLoading ?? false;
    }

    return this.chatsStore.loader.isLoading || activeMessagesLoading;
  }

  public isMessage = (message: string | MessageStore): message is MessageStore => {
    return typeof message !== 'string';
  };

  public get isLoadedRows() {
    if (!this.chatId) return false;
    const chatBucket = this.messagesStore.getChatBucketByChatId(this.chatId);
    return chatBucket?.loader.isLoaded ?? false;
  }

  public get hasMoreUpRows() {
    if (!this.chatId) return false;
    const pagination = this.messagesStore.getChatBucketByChatId(this.chatId)?.pagination;
    return pagination ? !pagination.firstMessagePresent : false;
  }

  public get hasMoreDownRows() {
    if (!this.chatId) return false;
    const pagination = this.messagesStore.getChatBucketByChatId(this.chatId)?.pagination;
    return pagination ? !pagination.lastMessagePresent : false;
  }

  public get firstRow() {
    return this.rows[0] ?? null;
  }

  public get lastRow() {
    return this.rows[this.rowsCount - 1] ?? null;
  }

  public get firstUnreadRowIdx() {
    return this.rows.findIndex((msg) => !msg.isViewed);
  }

  public get hasUnreadRows() {
    return this.rows.some((msg) => !msg.isViewed);
  }

  public processUpTrigger = async () => {
    if (this.isUpProcessing.isLoading || !this.firstRow) return;

    this.isUpProcessing.startLoading();

    runInAction(() => {
      this.triggerUpMessage = this.firstRow;
    });

    await this.messagesStore.awaitedLoadMessages(this.firstRow, LoadMessageDirection.Up);

    this.isUpProcessing.stopLoading();
  };

  public processDownTrigger = async () => {
    if (this.isDownProcessing.isLoading || !this.lastRow) return;

    this.isDownProcessing.startLoading();

    await this.messagesStore.awaitedLoadMessages(this.lastRow, LoadMessageDirection.Down);

    this.isDownProcessing.stopLoading();
  };

  public setMediaElement = (messageId: string, element: WaveSurfer | Player | null) => {
    if (!element) {
      this.mediaElements.delete(messageId);
      return;
    }
    this.mediaElements.set(messageId, element);
  };

  public onChangeActiveMedia = (messageId: string) => {
    this.mediaElements.forEach((media, key) => {
      if (key === messageId) {
        if (media instanceof WaveSurfer) {
          media.playPause();
        } else {
          if (media.paused()) {
            media.play();
          } else {
            media.pause();
          }
        }
        return;
      }

      media.pause();
    });
  };

  public loadFirstNewMessages = () => {
    runInAction(() => {
      this.hadLoadedFirstNewMessages = true;
    });
  };

  public getRowKeyByIndex = (index: number) => {
    const row = this.rowsWithDate[index];

    return this.isMessage(row) ? row.id : row;
  };

  public getRowIndexByKey = (key: string) => {
    return this.rows.findIndex((row) => row.id === key);
  };

  public getRowByIndex = (index: number) => {
    return this.rows[index];
  };

  public getRowByKey = (key: string) => {
    return this.rows.find((row) => row.id === key) ?? null;
  };

  startInit = () => {
    if (this.isInit || this.isInitializing) return;
    runInAction(() => {
      this.isInitializing = true;
    });
  };

  completeInit = () => {
    runInAction(() => {
      this.isInit = true;
      this.isInitializing = false;
    });
  };

  public disposer = () => {
    runInAction(() => {
      this.isInit = false;
      this.isUpProcessing = new LoadingStore();
      this.isDownProcessing = new LoadingStore();
      this.triggerUpMessage = null;
      this.mediaElements.clear();
    });
  };
}

const useMessageHandlers = (control: PrivateChatMessagesController) => {
  const { t } = useTranslation();

  const { privateMessagesStore } = useRootStore();

  const messageInstance = useRef<MessageStore | null>(null);

  const contextMenu = useContextMenu();
  const { messageTransmitter } = useMessageTransmitterContext();

  const onSelectEmoji = useCallback((emoji: string) => {
    if (!messageInstance.current) return;

    privateMessagesStore.updateEmojiEmitter({
      privateChatId: messageInstance.current.chatId,
      messageId: messageInstance.current.id,
      reaction: emoji,
    });
  }, []);

  const onSelectQuickEmoji = useCallback((emoji: string) => {
    onSelectEmoji(emoji);
    contextMenu.hide();
  }, []);

  const onClickReply = useCallback(() => {
    if (!messageInstance.current) return;
    messageTransmitter.setReplyMessage(messageInstance.current);
  }, []);

  const onEditMessage = useCallback(() => {
    if (!messageInstance.current) return;
    messageTransmitter.setEditableMessage(messageInstance.current);
  }, []);

  const onContextMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const node = extractNode(event, '[data-message-id]');
    const { messageId } = node?.dataset ?? {};

    if (!messageId) return;

    const message = messageId ? control.getRowByKey(messageId) : null;

    if (!message) return;

    messageInstance.current = message;

    contextMenu.show({
      event,
      component: (
        <ContextMenuNew.Menu>
          <Stack w="100%" align="baseline" gap="xs">
            <QuickEmoji onSelectEmoji={onSelectQuickEmoji} />
            <ContextMenuNew.Content>
              <ContextMenuNew.Item icon={<SvgIcon type="reply" />} onClick={onClickReply}>
                {t('shared-content.reply')}
              </ContextMenuNew.Item>

              {message.isMessageFromViewer && (
                <ContextMenuNew.Item icon={<SvgIcon type="edit-message" />} onClick={onEditMessage}>
                  {t('shared-content.edit-message')}
                </ContextMenuNew.Item>
              )}
            </ContextMenuNew.Content>
          </Stack>
        </ContextMenuNew.Menu>
      ),
      beforeClose: () => {
        messageInstance.current = null;
      },
    });
  };

  return {
    onContextMenu,
  };
};

export const usePrivateChatMessagesController = () => {
  const { chatId } = useMessageParams(TalkingArea.PRIVATE);

  const { privateMessagesStore, appSettingsStore } = useRootStore();

  const control = useMemo(() => new PrivateChatMessagesController(chatId), []);

  const { scrollArea, virtualizer, isActiveSticky } = useMessageListVirtual(control);

  const items = virtualizer.getVirtualItems();

  const {
    isNearBottom,
    isToBottomScrolling,
    downRef,
    setupRowNode,
    onScrollArea,
    onScrollToBottom,
    receiveBaseMessage,
    receiveVideoTemplateMessage,
  } = useMessageListScroll(virtualizer, control, () => {
    if (chatId) {
      privateMessagesStore.awaitedReadAllMessages(chatId);
    }
  });

  const { onContextMenu } = useMessageHandlers(control);

  const observeCallback = async (entries: IntersectionObserverEntry[]) => {
    if (control.isInitializing || !control.isInit) return;

    for (const entry of entries) {
      if (!entry.isIntersecting) continue;

      if (entry.target === triggerUpRef.current) {
        await control.processUpTrigger();
        const prevOffset = Math.max(
          0,
          virtualizer.getTotalSize() - (virtualizer.scrollElement?.offsetHeight ?? 0) - 20,
        );
        setTimeout(() => {
          const currentListSize = virtualizer.getTotalSize();
          const scrollSize = currentListSize - prevOffset;
          virtualizer.scrollToOffset(scrollSize, SCROLL_OPTIONS_START);
        });

        control.loadFirstNewMessages();
        break;
      }

      if (entry.target === triggerDownRef.current) {
        await control.processDownTrigger();
        control.loadFirstNewMessages();
        break;
      }
    }
  };

  const { triggerDownRef, triggerUpRef } = useObserveLoadTriggers({
    canObserveUp: control.hasMoreUpRows && control.isInit,
    canObserveDown: control.hasMoreDownRows && control.isInit,
    observeCallback,
    observeOptions: {
      root: scrollArea.current,
      rootMargin: OBSERVE_ROOT_MARGIN,
    },
  });

  useLayoutEffect(() => {
    const disposers = [
      privateMessagesStore.receiveViewerMessage(receiveBaseMessage),
      privateMessagesStore.receiveTemplateMessage(receiveVideoTemplateMessage),
    ];
    return () => {
      control.disposer();

      disposers.forEach((dis) => dis());
    };
  }, []);

  useEffect(() => {
    const disposer = when(
      () =>
        !control.isLoadingRows &&
        !control.isInit &&
        control.rowsCount === 0 &&
        control.isLoadedRows,
      () => {
        control.completeInit();
      },
    );

    return () => {
      disposer();
    };
  }, []);

  return {
    control,
    items,
    scrollArea,
    virtualizer,
    triggerDownRef,
    triggerUpRef,
    isNearBottom,
    isToBottomScrolling,
    downRef,
    onScrollToBottom,
    setupRowNode,
    onContextMenu,
    onScrollArea,
    isActiveSticky,
    isOnline: appSettingsStore.isOnline,
  };
};
