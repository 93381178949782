import { useCallback } from 'react';

import { ListItems } from './list-items';
import { routerCollection } from '@constants';
import { useAppNavigate } from '@hooks';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Avatar } from '@shared/UI';
import { ButtonNew } from '@shared/UI/button-new';

import s from './styles.module.scss';

export const ProfileContent = observer(function ProfileContent() {
  const { t } = useTranslation();
  const navigate = useAppNavigate();

  const { userStore, authStore, appLoaderStore, cleanStores } = useRootStore();

  const handleLogout = useCallback(async () => {
    appLoaderStore.startLoading();
    try {
      await authStore.logout();
      navigate(routerCollection.auth.root);
      cleanStores();
    } catch (e) {
    } finally {
      appLoaderStore.stopLoading();
    }
  }, []);

  return (
    <div className={s.profileContent}>
      <div className={s.profileContentInfoWrapper}>
        <Avatar size="lg" src={userStore.avatar} />
        <span className={s.profileContentUsername}>{userStore.user.name}</span>
      </div>

      <ListItems />

      <ButtonNew fullWidth variant="outline" onClick={handleLogout}>
        {t('shared-content.exit')}
      </ButtonNew>
    </div>
  );
});
