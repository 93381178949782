import { useCallback } from 'react';

import { routerCollection } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { useAppNavigate } from '@hooks';

import type { VerifyByEmailDto, VerifyByPhoneDto } from '@core/repositories';
import { useRootStore } from '@core/store';

export const useVerifyOtp = () => {
  const navigate = useAppNavigate();

  const { authStore, appLoaderStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  return useCallback(
    async (dto: VerifyByEmailDto | VerifyByPhoneDto) => {
      appLoaderStore.startLoading();
      try {
        const { user } = await authStore.verify(dto);

        if (user.name) {
          navigate(routerCollection.dashboard.root);
          sendEvent({ event: AnalyticsEvents.LoginSuccess });
        } else {
          navigate(routerCollection.onboarding.root);
          sendEvent({ event: AnalyticsEvents.RegistrationSuccess });
        }
      } catch (e) {
        return await Promise.reject(e);
      } finally {
        appLoaderStore.stopLoading();
      }
    },
    [authStore, navigate],
  );
};
