import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import s from './styles.module.scss';

export const ProfileCredentials = observer(function ProfileCredentials() {
  const { t } = useTranslation();

  const { userStore } = useRootStore();

  return (
    <div className={s.profileCredentials}>
      {userStore.user.email && (
        <div className={s.profileCredentialsInfoBox}>
          <span className={s.profileCredentialsLabel}> {t('shared-content.email')}</span>
          <span className={s.profileCredentialsValue}>{userStore.user.email}</span>
        </div>
      )}
      {userStore.user.phoneNumber && (
        <div className={s.profileCredentialsInfoBox}>
          <span className={s.profileCredentialsLabel}>{t('shared-content.phone')}</span>
          <span className={s.profileCredentialsValue}>{userStore.user.phoneNumber}</span>
        </div>
      )}
    </div>
  );
});
