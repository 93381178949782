import { observer } from 'mobx-react-lite';

import { SvgIcon } from '@shared/UI';
import { TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

export const EventCardTime = observer(function EventCardTime(props: { value: string }) {
  const { value } = props;

  return (
    <div className={s.eventCardTime}>
      <div className={s.eventCardTimeIcon}>
        <SvgIcon type="clock" />
      </div>
      <TypographyNew variant="body-1" weight="bold" colorSchema="neutral-100">
        {value}
      </TypographyNew>
    </div>
  );
});
