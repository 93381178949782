import { useState } from 'react';

import { Input } from '../input';
import type { InputProps } from '../input';
import { observer } from 'mobx-react-lite';
import type { FieldValues } from 'react-hook-form';

import { EyeSwitcher } from './eye-switcher';

export type PasswordProps<TFormValues extends FieldValues> = {} & Omit<
  InputProps<TFormValues>,
  'type'
>;

export const Password = observer(function Password<TFormValues extends FieldValues>(
  props: PasswordProps<TFormValues>,
) {
  const { ...restProps } = props;

  const [isHidden, setIsHidden] = useState(true);

  const handleClickSwitch = () => {
    setIsHidden((prevState) => !prevState);
  };

  const inputType = isHidden ? 'password' : 'text';

  return (
    <Input
      type={inputType}
      suffix={<EyeSwitcher isHidden={isHidden} onClick={handleClickSwitch} />}
      autoComplete="off"
      {...restProps}
    />
  );
});
