import { EntryPage } from './EntryPage';
import { routerCollection } from '@constants';
import type { RouteObject } from 'react-router-dom';

import { lazyDelay } from '@shared/utils';

const MyAccount = lazyDelay(() => import('./my-account'));

export const profileRouter: RouteObject = {
  path: routerCollection.myAccount.root,
  element: <EntryPage />,
  children: [
    {
      index: true,
      element: <MyAccount />,
    },
  ],
};
