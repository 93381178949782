import { useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { MessageStore } from '@core/store';

import { cl } from '@shared/libs/classnames';

import { Box, Group, SvgIcon, Typography } from '@shared/UI';

import { hourAndMinuteDate } from '@shared/utils';

import s from './styles.module.scss';

interface MessageTileFooterInfoProps {
  message: MessageStore;
  showViewedCount?: boolean;
  hasVideo?: boolean;
  className?: string;
}

export const MessageTileFooterInfo = observer(function MessageTileFooterInfo(
  props: MessageTileFooterInfoProps,
) {
  const { message, showViewedCount = false, hasVideo = true, className } = props;

  const { t } = useTranslation();

  const rootClasses = cl(s.messageTileFooterInfo, className);

  const readIcon = useMemo(() => {
    if (!hasVideo) {
      return 'timer';
    }

    if (message.viewCount && message.viewCount > 1) {
      return 'double-checkmark';
    }

    return 'checkmark';
  }, [hasVideo, message.viewCount]);

  return (
    <Group wrap="nowrap" gap="xs-3" justify="flex-end" className={rootClasses}>
      {message.isPinned && (
        <Box>
          <SvgIcon type="pin-filled-sm" className={s.messageTileFooterInfoPinIcon} />
        </Box>
      )}
      {typeof message.viewCount === 'number' && showViewedCount && message.viewCount > 1 && (
        <Group gap="xs-3" wrap="nowrap">
          <Typography as="span" variant="body-medium-4">
            {message.viewCount - 1}
          </Typography>
          <SvgIcon fontSize={16} type="eye" />
        </Group>
      )}
      {message.isEdited && (
        <Box>
          <Typography as="span" variant="body-medium-4" className={s.messageTileFooterInfoEdit}>
            {t('shared-content.edited')}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography as="span" variant="body-medium-4">
          {hourAndMinuteDate(message.createDate)}
        </Typography>
      </Box>
      {message.isMessageFromViewer && (
        <SvgIcon className={s.messageTileFooterInfoCheckmark} fontSize={20} type={readIcon} />
      )}
    </Group>
  );
});
