import type { ReactNode } from 'react';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { BoxProps } from '@shared/UI';
import { Box } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface ChatMessagesContainerProps extends Omit<BoxProps, 'as' | 'children'> {
  children: ReactNode;

  hideScrollbar?: boolean;
}

const _ChatMessagesContainer = observer((props: ChatMessagesContainerProps) => {
  const { children, className, hideScrollbar, ...restProps } = props;

  const rootClasses = cl(s.chatMessagesContainer, className);

  return (
    <Box className={rootClasses} data-hidden-scrollbar={hideScrollbar} {...restProps}>
      {children}
    </Box>
  );
});

export const ChatMessagesContainer = mergeComponentsWithName(
  'ChatMessagesContainer',
  _ChatMessagesContainer,
);
