import { MutableRefObject, ReactNode, useMemo } from 'react';

import { MenuProvider } from '../menu.context';
import { animationInner } from './menu.config';
import { useMenuController } from './menu.controller';
import { FloatingFocusManager, FloatingOverlay, FloatingPortal } from '@floating-ui/react';
import cl from 'classnames';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';

import { observer } from '@shared/libs/mobx';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

export interface MenuProps extends HTMLMotionProps<'div'> {
  id?: string;
  root?: HTMLElement | null | MutableRefObject<HTMLElement | null>;
  children: ReactNode;
}

export const { appendClass } = classNames('context-menu');

const _Menu = observer((props: MenuProps) => {
  const { id, root, children, className, ...restProps } = props;

  const { isOpen, isPositioned, context, refs, activeIndex, getFloatingProps, setItemNode } =
    useMenuController();

  const contextValue = useMemo(
    () => ({
      setItemNode,
      activeIndex,
    }),
    [setItemNode, activeIndex],
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <FloatingPortal id={id} root={root}>
          <FloatingOverlay lockScroll className={appendClass('-overlay')}>
            <FloatingFocusManager context={context} initialFocus={refs.floating}>
              <MenuProvider value={contextValue}>
                <motion.div
                  {...animationInner}
                  {...restProps}
                  {...getFloatingProps({
                    className: cl(appendClass('--inner'), className),
                  })}
                >
                  {isPositioned && <div className={appendClass('-root')}>{children}</div>}
                </motion.div>
              </MenuProvider>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </AnimatePresence>
  );
});

export const Menu = mergeComponentsWithName('Menu', _Menu);
