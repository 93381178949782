import { MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';

import { modalStore } from '@components';
import { useDroppable } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CreateChannelModal, CreateChatModal } from '@features-new';
import { useAppNavigate } from '@hooks';
import {
  TalkingArea,
  useAccessPermissions,
  useBusinessIdParam,
  useChatLayout,
  useMessageParams,
} from '@units';
import debounce from 'lodash.debounce';
import { makeAutoObservable, runInAction } from 'mobx';
import { stopPersisting } from 'mobx-persist-store';
import { useTranslation } from 'react-i18next';

import type { ChannelsStore } from '@core/store';
import { useRootStore } from '@core/store';

import { ContextMenuNew, useContextMenu } from '@shared/UI';

import { useLatestCallback } from '@shared/hooks';
import { extractNode } from '@shared/utils';

const DND_ID = 'community-sidebar-channels-dnd';

export class CommunitySidebarController {
  draggableChannelId: string | undefined = undefined;

  openedChannel: string | null = null;

  constructor(private channelsStore: ChannelsStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get activeChannel() {
    return this.channelsStore.activeChannel?.channel;
  }

  handleDragSort = (activeId: string, overId: string) => {
    const oldIndex = this.channelsStore.channelsList.findIndex((item) => item.id === activeId);
    const newIndex = this.channelsStore.channelsList.findIndex((item) => item.id === overId);

    const updatedArray = arrayMove(this.channelsStore.channelsList, oldIndex, newIndex);

    this.channelsStore.updateChannelsPositionEmitter(updatedArray);
  };

  handleDragStart = (activeId: string) => {
    this.setDraggableChannelId(activeId);
  };

  onChangeActiveChannel = (channelId: string | null) => {
    runInAction(() => {
      this.openedChannel = channelId ?? null;
    });
  };

  handleClickAddChannelBtn = () => {
    modalStore.activateModal({
      component: <CreateChannelModal />,
    });
  };

  receiveUnreadByChannelId = (channelId: string) => {
    return this.channelsStore.receiveUnreadByChannelId(channelId);
  };

  setDraggableChannelId = (channelId: string | undefined) => {
    this.draggableChannelId = channelId;
  };

  channelById = (channelId: string) => {
    return this.channelsStore.channelById(channelId);
  };

  get draggableChannel() {
    return this.channelsStore.channelsList.find((item) => item.id === this.draggableChannelId);
  }

  get canShowPinnedChannels() {
    return (
      this.channelsStore.channelsLoader.isLoaded && this.channelsStore.pinnedChannels.length > 0
    );
  }

  get canShowChannels() {
    return this.channelsStore.channelsLoader.isLoaded;
  }

  get isLoadingChannels() {
    return this.channelsStore.channelsLoader.isLoading;
  }

  get pinnedChannels() {
    return this.channelsStore.pinnedChannels;
  }

  get baseChannels() {
    return this.channelsStore.unpinnedChannels;
  }

  disposer = () => {
    stopPersisting(this);
  };
}

export const useCommunitySidebarController = () => {
  const { communityChannelsStore } = useRootStore();

  const { t } = useTranslation();

  const controller = useMemo(() => new CommunitySidebarController(communityChannelsStore), []);

  const { setNodeRef: setDroppableRef } = useDroppable({
    id: DND_ID,
  });

  const { chatId, channelId } = useMessageParams(TalkingArea.COMMUNITY);

  const navigate = useAppNavigate();

  const accordionItemsRefs = useRef<HTMLDivElement[]>([]);

  const isScrolledToActive = useRef(false);

  const businessAccountId = useBusinessIdParam();

  const { checkBusinessId, permissions, checkPermissionWithBusinessId } = useAccessPermissions();

  const { isMobile, isOpenSidebar, onOpenSidebar } = useChatLayout();

  const contextMenu = useContextMenu();

  const accessPermissions = useMemo(() => {
    return {
      canCreateChannel: checkPermissionWithBusinessId(
        permissions?.communityCommunicationItem?.create,
        businessAccountId,
      ),
      canUpdateChannel: checkPermissionWithBusinessId(
        permissions?.communityCategory?.update,
        businessAccountId,
      ),
      canUpdateChat: checkPermissionWithBusinessId(
        permissions.communityCommunicationItem.update,
        businessAccountId,
      ),
      needToBlockClosedChannel: !businessAccountId || !checkBusinessId(Number(businessAccountId)),
    };
  }, [permissions, businessAccountId]);

  const scrollToActive = useLatestCallback(
    debounce(() => {
      const activeChild = accordionItemsRefs.current.find((node) => {
        const isActive = node.getAttribute('data-active');
        return isActive === 'true';
      });

      if (activeChild && !isScrolledToActive.current) {
        requestAnimationFrame(() => {
          activeChild.scrollIntoView();
        });
        isScrolledToActive.current = true;
      }
    }),
  );

  const onChangeActiveChannel = useCallback(
    (id: string | null) => {
      if (id && id !== channelId) {
        communityChannelsStore.connectToChannelEmitter(id);
        navigate(id, { replace: true });
      }

      controller.onChangeActiveChannel(id === '' ? null : id);
    },
    [channelId],
  );

  const setAccordionItemRef = useLatestCallback((ref: HTMLDivElement) => {
    if (ref) {
      accordionItemsRefs.current.push(ref);
      scrollToActive();
    }
  });

  const onContextMenuChannels = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const node = extractNode(event, '[data-channel-id]');

    const { channelId } = node?.dataset || {};

    if (!channelId) return;

    const channel = controller.channelById(channelId);

    if (!channel) return;

    contextMenu.show({
      event,
      component: (
        <ContextMenuNew.Menu>
          <ContextMenuNew.Content>
            <ContextMenuNew.Item onClick={channel.togglePinCategoryEmitter}>
              {channel.isPinned ? t('shared-content.unpin') : t('shared-content.pin')}
            </ContextMenuNew.Item>
            {accessPermissions.canUpdateChannel && (
              <ContextMenuNew.Item
                onClick={() => {
                  modalStore.activateModal({
                    component: <CreateChannelModal channel={channel} />,
                  });
                }}
              >
                {t('shared-content.edit-channel')}
              </ContextMenuNew.Item>
            )}
            {accessPermissions.canUpdateChannel && (
              <ContextMenuNew.Item
                onClick={() => {
                  modalStore.activateModal({
                    component: <CreateChatModal channel={channel} />,
                  });
                }}
              >
                {t('modals.create-communication-item.channel.context-menu-trigger')}
              </ContextMenuNew.Item>
            )}
          </ContextMenuNew.Content>
        </ContextMenuNew.Menu>
      ),
    });
  };

  useEffect(() => {
    if ((!chatId || !channelId) && isMobile && !isOpenSidebar) {
      onOpenSidebar();
    }
  }, [isMobile]);

  useEffect(() => {
    if (channelId) {
      controller.onChangeActiveChannel(channelId);
    }

    return () => {
      controller.disposer();
    };
  }, []);

  return {
    controller,
    onChangeActiveChannel,
    setAccordionItemRef,
    setDroppableRef,
    onContextMenuChannels,
  };
};
