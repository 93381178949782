import type { ReactNode } from 'react';

import { createSafeContext } from '@shared/utils';

interface AccordionContext {
  onChange: (key: string) => void;
  isItemActive: (key: string) => boolean;
  chevron: ReactNode;
}

export const [AccordionProvider, useAccordionContext] =
  createSafeContext<AccordionContext>('Accordion');
