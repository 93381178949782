import type { CSSProperties } from 'react';

import { EditButton } from './edit-button';
import { InterestsList } from './interests-list';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import bgImage from '@assets/images/my-account/interests-decoration.png';

import { TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

export const Interests = observer(function Interests() {
  const { t } = useTranslation();

  const { userStore } = useRootStore();

  const interestsElement = userStore.hasInterests && <InterestsList />;

  const bgWrapperStyles: CSSProperties = {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'right bottom',
  };

  return (
    <div className={s.interests}>
      <TypographyNew as="h2" variant="title-2" weight="bold">
        {t('my-account.my-interests.title')}
      </TypographyNew>
      <div className={s.interestsBody}>
        <div className={s.interestsBodyWrapper}>
          <div className={s.bgWrapper} style={bgWrapperStyles}></div>

          {interestsElement}
          <EditButton />
        </div>
      </div>
    </div>
  );
});
