import type { HTMLAttributes } from 'react';

import cl from 'classnames';

import { motion } from '@shared/libs/framer-motion';
import { observer } from '@shared/libs/mobx';

import { Typography } from '@shared/UI';
import { useClassName } from '@shared/UI/_hooks';

import './index';

import './styles.scss';

export type SelectOption = {
  value: string | number;
  label: string;
  disabled?: boolean;
  group?: string;
  [key: string]: any;
};

export type OptionProps<T extends SelectOption> = {
  isSelected: boolean;
} & T &
  HTMLAttributes<HTMLDivElement>;

export const Option = observer(function Option<T extends SelectOption>(props: OptionProps<T>) {
  const { label, className, isSelected, ...restProps } = props;

  const { rootClassName, appendClass } = useClassName('select-option');

  const rootClasses = cl(
    rootClassName,
    {
      [appendClass('--selected')]: isSelected,
    },
    className,
  );

  return (
    <motion.li className={rootClasses} {...restProps}>
      <div className={appendClass('-wrapper')}>
        <Typography className={appendClass('-label')} as="span" variant="body-medium-3">
          {label}
        </Typography>
      </div>
    </motion.li>
  );
});
