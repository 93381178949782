import { z } from 'zod';

export const validationRules = {
  password: z.string().nonempty({ message: 'errors.required-field' }).min(8, 'errors.small-length'),
  otp: z.string(),
  email: z
    .string()
    .nonempty({ message: 'errors.required-field' })
    .email({ message: 'errors.invalid-email' }),
};
