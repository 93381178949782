import { useEffect, useRef, useState } from 'react';

import { PageSkeleton } from './page-skeleton';
import { AnalyticsEvents, PlayerContext, useAnalyticsContext } from '@contexts';
import { CourseHeader, CourseLessonComments, CourseLessonOverview } from '@units';
import { CoursePlayer, CourseRoadmap } from '@widgets';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type PlayerInstance from 'video.js/dist/types/player';

import type { CourseGroupModuleBlockEntity } from '@core/repositories';
import { useRootStore } from '@core/store';

import { Stack, Typography } from '@shared/UI';

import s from './styles.module.scss';

const Course = observer(function Course() {
  const { t } = useTranslation();

  const [playerInstance, setPlayerInstance] = useState<PlayerInstance | null>(null);
  const [showRoadmap, setShowRoadmap] = useState(false);

  const { id } = useParams();

  const { courseViewStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const roadmapWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleButtonClick = (index: number) => {
    const listItem = document.getElementById(`course-roadmap-lesson-${index}`);
    const courseRoadmapHeader = document.getElementById('course-roadmap-header');

    if (listItem && courseRoadmapHeader && roadmapWrapperRef.current) {
      roadmapWrapperRef.current.scrollTo({
        top: listItem.offsetTop - courseRoadmapHeader.offsetHeight - 40,
        behavior: 'smooth',
      });
    }
  };

  const getCourseView = async () => {
    if (id) {
      try {
        await courseViewStore.getCourse(+id);
      } catch (e) {}
    }
  };

  const getCourseLessonComments = async () => {
    if (courseViewStore.course?.id && courseViewStore.activeLesson.id) {
      try {
        await courseViewStore.getComments(
          courseViewStore.course?.id,
          courseViewStore.activeLesson.id,
        );
      } catch (e) {}
    }
  };

  useEffect(() => {
    getCourseView();

    return () => courseViewStore.disposer();
  }, [id]);

  useEffect(() => {
    getCourseLessonComments();

    return () => courseViewStore.disposer();
  }, [courseViewStore.activeLesson.id]);

  useEffect(() => {
    sendEvent({
      event: AnalyticsEvents.WorkspaceCourseView,
      payload: {
        course_name: courseViewStore.course?.title,
        course_id: courseViewStore.course?.id,
      },
    });
  }, []);

  if (courseViewStore.loader.isLoading) {
    return <PageSkeleton />;
  }

  if (courseViewStore.course) {
    return (
      <PlayerContext.Provider value={{ playerInstance, setPlayerInstance }}>
        <Stack align="flex-start" gap="xxl" className={s.course}>
          <Stack align="flex-start" gap="xxl" className={s.courseContent}>
            {courseViewStore.course.courseWithModules && (
              <CourseHeader
                image={courseViewStore.course.generalImage}
                groupTitle={courseViewStore.course.groupTitle}
                lessonsCount={courseViewStore.course.lessonsCount}
                duration={courseViewStore.course.duration}
                title={courseViewStore.course.title}
              />
            )}
            {id && <CoursePlayer courseId={courseViewStore.course.id} groupId={+id} />}
            <CourseLessonOverview
              title={courseViewStore.activeLesson?.title}
              description={courseViewStore.activeLesson?.description}
              handleButtonClick={handleButtonClick}
            />
            <CourseLessonComments />
          </Stack>

          <div
            className={cl(
              s.courseRoadmapContainer,
              showRoadmap ? s.courseRoadmapContainerVisible : '',
            )}
          >
            <CourseRoadmap
              lessonsBlock={courseViewStore.course.lessonsBlock as CourseGroupModuleBlockEntity[]}
              groupTitle={courseViewStore.course.groupTitle}
              title={courseViewStore.course.title}
              isCourseWithModules={courseViewStore.course.courseWithModules}
              lessonsCount={courseViewStore.course.lessonsCount}
              duration={courseViewStore.course.duration}
              lessons={courseViewStore.lessons}
              activeModuleId={courseViewStore.activeModuleId}
              setLessons={courseViewStore.setLessons}
              roadmapWrapperRef={roadmapWrapperRef}
            />
          </div>

          <button onClick={() => setShowRoadmap(!showRoadmap)} className={s.courseRoadmapBtn}>
            <Typography as="span" variant="body-medium-4">
              {showRoadmap
                ? t('my-workspace.course.roadmap.button-close')
                : t('my-workspace.course.roadmap.button-open')}
            </Typography>
          </button>
        </Stack>
      </PlayerContext.Provider>
    );
  }

  return null;
});

export default Course;
