import { routerCollection } from '@constants';
import { useAppLocation, useAppNavigate } from '@hooks';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Box, Button } from '@shared/UI';

import s from './styles.module.scss';

export const EditButton = observer(function EditButton() {
  const { t } = useTranslation();

  const navigate = useAppNavigate();

  const location = useAppLocation();

  const { userStore } = useRootStore();

  const handleClickEdit = () => {
    navigate(routerCollection.onboarding.interests, {
      withLoading: true,
      replace: true,
      search: {
        from: location.pathname,
      },
      state: {
        canEntryOnInterests: true,
      },
    });
  };

  return (
    <Box
      py={userStore.hasInterests ? [24, 0] : 0}
      className={s.btnWrapper}
      onClick={handleClickEdit}
    >
      <Button>{userStore.hasInterests ? t('shared-content.edit') : t('shared-content.add')}</Button>
    </Box>
  );
});
