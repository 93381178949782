import { ChannelsAccordion } from './channels-accordion';
import { ChannelsSkeleton } from './channels-skeleton';
import { useCommunitySidebarController } from './community-sidebar.controller';
import { CommunitySelector } from '@features-new';

import { observer } from '@shared/libs/mobx';

import { Box, Fallback } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

const _CommunitySidebarNew = observer(() => {
  const { controller, onContextMenuChannels } = useCommunitySidebarController();

  return (
    <Box className={s.communitySidebar}>
      <Box className={s.communitySidebarHeader}>
        <CommunitySelector />
      </Box>
      <Box className={s.communitySidebarContent} onContextMenu={onContextMenuChannels}>
        <Fallback when={controller.isLoadingChannels} fallback={<ChannelsSkeleton />}>
          <ChannelsAccordion control={controller} />
        </Fallback>
      </Box>
    </Box>
  );
});

export const CommunitySidebarNew = mergeComponentsWithName(
  'CommunitySidebarNew',
  _CommunitySidebarNew,
);
