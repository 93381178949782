type Events = {
  Emitters: Record<string, string>;
  Subscribers: Record<string, string>;
};

const CommonEvents = {
  Subscribers: {
    ReceiveUnreadStatuses: 'receive-unread-status-v2',
    GetError: 'error',
    Connected: 'connected',
  } as const,
  Emitters: {} as const,
} satisfies Events;

const NotificationEvents = {
  Subscribers: {
    ReceiveUnreadNotification: 'receive-unread-notifications',
    NewNotification: 'new-notification:notification',
  } as const,
  Emitters: {} as const,
} satisfies Events;

const CommunityEvents = {
  Subscribers: {
    Connect: 'connect',
    ConnectCategory: 'connect:category',
    ConnectCommunication: 'connect:communication',
    GetNewMessage: 'receive-message:communication',
    GetMessages: 'get-messages:communication',
    ToggleEmoji: 'receive-emoji:communication',
    GetUnread: 'unread:communication',
    ViewAllMessages: 'viewed-all-messages:communication',
    ViewMessage: 'update-viewed-count:communication',
    CreateCommunication: 'created-communication-item:communication',
    UpdateCommunication: 'updated-communication-item:communication',
    CreateChannel: 'created-category:communication',
    EditMessages: 'edited-message:communication',
    TogglePinningCategory: 'pin-toggle:category',
    TogglePinningMessage: 'pined-toggle-message:communication',
    GetPinnedMessages: 'get-pinned-message:communication',
    RemoveCategory: 'deleted:category',
    UpdateCategory: 'server:updated-category',
    SearchUserCommunication: 'search-user-in-communication-item:communication',
    GetMessage: 'receive-messages-by-message-id:communication',
    ReceiveTemplateMsg: 'receive-message-template:communication',
    RemoveMessage: 'deleted-message:communication',
  } as const,
  Emitters: {
    Connect: 'community:connect',
    ConnectCategory: 'community:connect:category',
    ConnectCommunication: 'community:connect:communication',
    SendMessage: 'community:send-message:communication',
    ToggleEmoji: 'community:emoji-toggle:communication',
    ViewAllMessages: 'community:view-all-messages:communication',
    EditMessage: 'community:edit-message:communication',
    GetMessages: 'community:get-messages:communication',
    ViewMessage: 'community:view-messages:communication',
    RemoveMessage: 'community:delete-message:communication',
    GetMessage: 'community:get-messages-by-message-id:communication',
    CreateCommunication: 'community:create-communication-item:communication',
    UpdateCommunication: 'community:update-communication-item:communication',

    TogglePinningMessage: 'community:pin-toggle-message:communication',
    TogglePinningCategory: 'community:pin-toggle:category',
    GetPinnedMessages: 'community:get-pinned-messages:communication',
    UpdateCategoryOrder: 'community:change-position-categories',
    UpdateCommunicationOrder: 'community:change-position-communication-items:communication',
    RemoveCategory: 'community:delete:category',
    UpdateCategory: 'community:update-category',
    SearchUserCommunication: 'community:search-user-in-communication-item:communication',
  } as const,
} satisfies Events;

const PrivateMessageEvents = {
  Emitters: {
    ConnectToPrivateChats: 'private-chat:connect',
    JoinToChatById: 'private-chat:connect:private-chat',
    CreatePrivateChat: 'private-chat:create-chat:private-chat',
    ToggleEmoji: 'private-chat:emoji-toggle:private-chats',
    SendMessage: 'private-chat:send-message:private-chat',
    GetMessages: 'private-chat:get-messages:private-chat',
    EditMessage: 'private-chat:edit-message:private-chat',
    ViewMessages: 'private-chat:view-messages:private-chat',
    ViewAllMessages: 'private-chat:view-all-messages:private-chat',
    Disconnect: 'private-chat:disconnect:private-chat',
  } as const,
  Subscribers: {
    ConnectToPrivateChats: 'receive-chats:private-chat',
    JoinToChatById: 'connect:private-chat',
    GetNewMessage: 'receive-message:private-chat',
    GetMessages: 'get-messages:private-chat',
    EditMessage: 'edited-message:private-chat',
    ToggleEmoji: 'receive-emoji:private-chat',
    OfflineUser: 'user-offline:private-chat',
    OnlineUser: 'user-online:private-chat',
    ViewMessages: 'update-viewed-count:private-chat',
    ViewAllMessages: 'viewed-all-messages:private-chat',
    CreatePrivateChat: 'created-chat:private-chat',
    ReceiveUnread: 'unread:private-chat',
    Disconnect: 'disconnected:private-chat',
    ReceiveTemplateMsg: 'receive-message-template:private-chat',
  } as const,
} satisfies Events;

const StreamChatEvents = {
  Emitters: {
    Connect: 'stream-chat:connect:stream-chat',
    Disconnect: 'stream-chat:disconnect:stream-chat',
    GetMessages: 'stream-chat:get-messages:stream-chat',
    SendMessage: 'stream-chat:send-message:stream-chat',
    DeleteMessage: 'stream-chat:delete-message:stream-chat',
    MuteUser: 'stream-chat:mute-user:stream-chat',
    MarkUser: 'stream-chat:mark-user:stream-chat',
    ResetUser: 'stream-chat:reset-user:stream-chat',
    ConfigStopWords: 'stream-chat:config-stop-words:stream-chat',
    GetStopWords: 'stream-chat:get-stop-words:stream-chat',
    GetMutedAndMarkedUsers: 'stream-chat:get-muted-and-marked-users:stream-chat',
    GetOnApproveMessages: 'stream-chat:get-on-approve-messages:stream-chat',
    ApproveMessage: 'stream-chat:approve-message:stream-chat',
  } as const,
  Subscribers: {
    Connected: 'connected:stream-chat',
    GetMessages: 'get-messages:stream-chat',
    ReceiveMessage: 'receive-message:stream-chat',
    DroppedUserStatus: 'dropped-user-status:stream-chat',
    GetStopWords: 'get-stop-words:stream-chat',
    GetMutedAndMarkedUsers: 'get-muted-and-marked-users:stream-chat',
    GetOnApproveMessages: 'get-on-approve-messages:stream-chat',
    ApprovedMessage: 'approved-message:stream-chat',
    UserMuted: 'user-muted:stream-chat',
    UserUnmuted: 'user-unmuted:stream-chat',
    DeletedMessage: 'deleted-message:stream-chat',
    ReceiveOnApproveMessage: 'receive-on-approve-message:stream-chat',
    UsersOnline: 'users-online:stream-chat',
    ClosedChat: 'closed-chat:stream-chat',
  } as const,
} satisfies Events;

export const SocketEventsCollection = {
  CommunityEvents,
  PrivateMessageEvents,
  CommonEvents,
  NotificationEvents,
  StreamChatEvents,
} as const;
