import { mapSkeleton } from '@units';

import { observer } from '@shared/libs/mobx';

import { Group, Skeleton, Stack } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface ChannelsSkeletonProps {}

const _ChannelsSkeleton = observer(() => {
  return (
    <Stack align="flex-start" gap="xl">
      {mapSkeleton(6, (key) => (
        <Group key={key} wrap="nowrap" w="100%" className={s.channelsAccordionSkeleton}>
          <Skeleton borderRadius="50%" width={32} height={32} />
          <Skeleton />
        </Group>
      ))}
    </Stack>
  );
});

export const ChannelsSkeleton = mergeComponentsWithName('ChannelsSkeleton', _ChannelsSkeleton);
