import { useMemo } from 'react';

import cl from 'classnames';

import { MessageViewType } from '@core/constants';

import { observer } from '@shared/libs/mobx';

import type { GroupProps } from '@shared/UI';
import { Avatar, Group, Indicator, Stack, SvgIcon, TypographyNew } from '@shared/UI';

import type { ObjectValues } from '@shared/types';
import { asHtml, hourAndMinuteWithPreviousDate, mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface PrivateMessageUserTileProps extends Omit<GroupProps, 'children' | 'role'> {
  avatar?: string;

  name: string;

  message?: string;

  date?: string;

  isViewed?: boolean;

  unreadCount: number;

  isActive?: boolean;

  isOnline?: boolean;

  messageType?: ObjectValues<typeof MessageViewType>;

  viewCount?: number;

  attachments?: string[];
}

const _PrivateMessageUserTile = observer((props: PrivateMessageUserTileProps) => {
  const {
    name,
    message,
    date,
    avatar,
    isActive = false,
    isViewed,
    unreadCount,
    className,
    isOnline,
    messageType,
    viewCount,
    attachments,
    ...restProps
  } = props;

  const _date = date ? hourAndMinuteWithPreviousDate(date) : '';

  const readIndicatorSection = useMemo(() => {
    if (unreadCount > 0) {
      return (
        <Indicator
          count={unreadCount}
          size="md"
          className={s.privateMessageUserTileCountIndicator}
        />
      );
    }

    if (viewCount) {
      return (
        <SvgIcon
          type={viewCount > 1 ? 'double-checkmark' : 'checkmark'}
          className={s.privateMessageUserTileCheckmarkRead}
        />
      );
    }

    return null;
  }, [unreadCount, viewCount]);

  const rootClasses = cl(s.privateMessageUserTile, className);

  const messageText = useMemo(() => {
    if (!messageType && !message) return null;

    if (messageType === MessageViewType.Video) {
      return 'Video message';
    }

    if (messageType === MessageViewType.Audio) {
      return 'Audio message';
    }

    if (Boolean(attachments?.length) && !message) {
      return 'File';
    }

    if (message) {
      return asHtml(message);
    }

    return null;
  }, [message, messageType, attachments?.length]);

  return (
    <Group
      wrap="nowrap"
      data-active={isActive}
      w="100%"
      role="button"
      className={rootClasses}
      {...restProps}
    >
      <Avatar
        src={avatar}
        indicatorSlot={isOnline && <div className={s.privateMessageUserTileIndicator} />}
      />
      <Group wrap="nowrap" w="100%">
        <Stack gap="xs-2" align="flex-start">
          <TypographyNew lineClamp={1} as="p" variant="body-2" weight="bold">
            {name}
          </TypographyNew>

          <TypographyNew lineClamp={1} as="p" variant="body-3" colorSchema="neutral-200">
            {messageText}
          </TypographyNew>
        </Stack>
        <Stack gap="xs-2" align="flex-end" w="auto">
          <TypographyNew as="span" variant="body-3" colorSchema="neutral-100">
            {_date}
          </TypographyNew>
          <span>{readIndicatorSection}</span>
        </Stack>
      </Group>
    </Group>
  );
});

export const PrivateMessageUserTile = mergeComponentsWithName(
  'PrivateMessageUserTile',
  _PrivateMessageUserTile,
);
