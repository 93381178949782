import { useAccordionContext } from '../Accordion.context';
import { useAccordionItemContext } from '../AccordionItem.context';
import { variants } from './config';
import { AnimatePresence, motion } from 'framer-motion';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { useClassName } from '@shared/UI/_hooks';

import type { ElementProps } from '@shared/types';
import { componentName, mergeComponents } from '@shared/utils';

import './styles.scss';

export interface AccordionPanelProps extends ElementProps<'div'> {}

const _AccordionPanel = observer((props: AccordionPanelProps) => {
  const { className, style, children, ...restProps } = props;

  const { isItemActive } = useAccordionContext();

  const { value } = useAccordionItemContext();
  const { rootClassName } = useClassName('accordion-panel');

  const rootClasses = cl(rootClassName, className);
  const isActive = isItemActive(value);

  return (
    <AnimatePresence initial={false}>
      {isActive && (
        <motion.div
          key="content"
          className={rootClasses}
          data-active={isActive}
          style={style}
          variants={variants}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          {...(restProps as any)}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export const AccordionPanel = mergeComponents(_AccordionPanel, {
  ...componentName('Accordion.Panel'),
});
