import * as z from 'zod';

export const signUpByEmailSchema = z
  .object({
    email: z
      .string({
        required_error: 'errors.required-field',
      })
      .min(1, { message: 'errors.required-field' })
      .email({ message: 'errors.invalid-email' }),
    password: z
      .string({ required_error: 'errors.required-field' })
      .min(1, { message: 'errors.required-field' })
      .refine((val) => val.length >= 8, { message: 'errors.small-length' }),
    confirmPassword: z.string().min(1, { message: 'errors.required-field' }),
    isAgreeRules: z.boolean().refine((val) => val, { message: '' }),
    emailSubscription: z.boolean().refine((val) => val, { message: '' }),
    otp: z.string().optional(),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: 'errors.dont-match-password',
    path: ['confirmPassword'],
  });

export type FormSignUpByEmailSchema = z.infer<typeof signUpByEmailSchema>;
