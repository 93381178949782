import { ReactNode } from 'react';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

export interface FallbackProps {
  fallback?: ReactNode | (() => ReactNode);
  children?: ReactNode;
  when: boolean;
}

const _Fallback = observer((props: FallbackProps) => {
  const { children, when, fallback } = props;

  if (when) {
    if (typeof fallback === 'function') {
      return <>{fallback()}</>;
    }
    return <>{fallback}</>;
  }

  return <>{children}</>;
});

export const Fallback = mergeComponentsWithName('Fallback', _Fallback);
