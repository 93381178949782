import { GridCourseBlock } from '@components';
import { routerCollection } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { ContainerWithTitle } from '@units';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import RobotImage from '@assets/images/empty-content/robot.png';

import { Each, EmptyContent, Skeleton, Stack } from '@shared/UI';

import s from './styles.module.scss';

export const MyCourses = observer(function MyCourses() {
  const { t } = useTranslation();

  const { myWorkspaceStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  if (!myWorkspaceStore.myCoursesBlocks) {
    return (
      <Stack align="flex-start">
        <Skeleton borderRadius={16} width={290} height={40} />
        <Skeleton borderRadius={16} width={290} height={280} />
      </Stack>
    );
  }

  if (!myWorkspaceStore.myCoursesBlocks?.length) {
    return (
      <EmptyContent
        title={t('my-workspace.courses.empty.title')}
        subtitle={t('my-workspace.courses.empty.description')}
        image={RobotImage}
        className={s.myCoursesWrapperEmpty}
      />
    );
  }

  return (
    <ContainerWithTitle label={t('my-workspace.courses.title')}>
      <div className={s.myCoursesWrapper}>
        <Each data={myWorkspaceStore.myCoursesBlocks}>
          {(course) => {
            const routePath = `${routerCollection.myWorkspace.courses}/${course.groupId}`;

            return (
              <div
                key={`${course.id}-${course.groupId}`}
                className={s.myCourseItem}
                onClick={() =>
                  sendEvent({
                    event: AnalyticsEvents.CourseLearningTap,
                    payload: { course_name: course.title, course_id: course.id },
                  })
                }
              >
                <GridCourseBlock
                  fullSize
                  progress={course.progress}
                  title={course.title}
                  startDate={course?.date?.start || new Date().toISOString()}
                  endDate={course?.date?.end || new Date().toISOString()}
                  accountTitle={course.businessAccountTitle}
                  image={course.generalImage}
                  groupId={course.groupId}
                  to={routePath}
                />
              </div>
            );
          }}
        </Each>
      </div>
    </ContainerWithTitle>
  );
});
