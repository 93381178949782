import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@core/store';

import { Button, TypographyNew } from '@shared/UI';

import { asHtml } from '@shared/utils';

import s from './styles.module.scss';

interface CourseLessonOverviewProps {
  title: string;
  description: string;
  handleButtonClick: (index: number) => void;
}

export const CourseLessonOverview = observer(function CourseLessonOverview(
  props: CourseLessonOverviewProps,
) {
  const { title = '', description = '', handleButtonClick } = props;

  const { t } = useTranslation();

  const { courseViewStore } = useRootStore();

  const prevClickHandler = () => {
    courseViewStore.setActiveLessonByIndex('prev', handleButtonClick);
  };

  const nextClickHandler = () => {
    courseViewStore.setActiveLessonByIndex('next', handleButtonClick);
  };

  return (
    <div className={s.root}>
      <div className={s.buttonsWrapper}>
        <Button disabled={courseViewStore.activeLesson.position === 1} onClick={prevClickHandler}>
          {t('my-workspace.course.roadmap.prev-lesson')}
        </Button>
        <Button
          disabled={courseViewStore.activeLesson.position === courseViewStore.lessons.length}
          onClick={nextClickHandler}
        >
          {t('my-workspace.course.roadmap.next-lesson')}
        </Button>
      </div>
      <TypographyNew variant="title-3" weight="bold">
        {asHtml(title)}
      </TypographyNew>
      {description && (
        <div className={s.descriptionWrapper}>
          <TypographyNew variant="body-2" colorSchema="neutral-200" className={s.description}>
            {asHtml(description)}
          </TypographyNew>
        </div>
      )}
    </div>
  );
});
