import { useCallback, useState } from 'react';

export const useToggle = (defaultValue?: boolean) => {
  const [isTrue, setIsTrue] = useState(() => {
    if (typeof defaultValue === 'boolean') {
      return defaultValue;
    }

    return false;
  });

  const toggle = useCallback((value?: boolean) => {
    setIsTrue((prevState) => {
      if (typeof value === 'boolean') {
        return value;
      }

      return !prevState;
    });
  }, []);

  return [isTrue, toggle] as const;
};
