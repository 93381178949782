import { DEFAULT_LANGUAGE } from '@constants';

import { PATH_COLLECTION } from '@core/constants';
import { createHTTPService } from '@core/services/http';

import type { SubscribeToEventResponse } from './responses';

export class CalendarRepository {
  private readonly http = createHTTPService(PATH_COLLECTION.CALENDAR.ROOT);

  subscribeToEvent = async (id: number) => {
    const path = PATH_COLLECTION.CALENDAR.SUBSCRIBE_TO_EVENT;

    const lang = DEFAULT_LANGUAGE;

    try {
      const { data } = await this.http.post<SubscribeToEventResponse>(
        path,
        {},
        { param: { id }, query: { lang } },
      );

      return data;
    } catch (e) {
      throw e;
    }
  };

  unsubscribeToEvent = async (id: number) => {
    const path = PATH_COLLECTION.CALENDAR.UNSUBSCRIBE_TO_EVENT;

    const lang = DEFAULT_LANGUAGE;

    try {
      const { data } = await this.http.post<SubscribeToEventResponse>(
        path,
        {},
        { param: { id }, query: { lang } },
      );

      return data;
    } catch (e) {
      throw e;
    }
  };
}
