import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useModalState } from '@hooks';
import { useTranslation } from 'react-i18next';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { Dropdown, SvgIcon } from '@shared/UI';

import { useMediaQuery } from '@shared/hooks';
import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export type EmojiDetails = {
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
};

export interface EmojiPickerProps {
  onSelect?: (details: EmojiDetails) => void;
}

const _EmojiPicker = observer((props: EmojiPickerProps) => {
  const { onSelect } = props;

  const { isOpen, onToggle } = useModalState();

  const { i18n } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 768px)');

  const locale = i18n.language === 'ua' ? 'uk' : 'en';

  const EmojiPicker = (
    <Picker
      data={data}
      navPosition="none"
      previewPosition="none"
      emojiSize={24}
      locale={locale}
      perLine={isMobile ? 6 : 8}
      onEmojiSelect={onSelect}
      theme="dark"
    />
  );
  const rootClasses = cl(s.emojiPickerBtn, {
    [s.emojiPickerBtnOpened]: isOpen,
  });

  return (
    <Dropdown
      contentClassName={s.emojiContent}
      isOpen={isOpen}
      onOpenChange={onToggle}
      content={EmojiPicker}
    >
      <button className={rootClasses} type="button">
        <SvgIcon type="emoji" />
      </button>
    </Dropdown>
  );
});

export const EmojiPicker = mergeComponentsWithName('EmojiPicker', _EmojiPicker);
