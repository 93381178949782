import type { BaseModalProps } from '../../_types';
import { LeftSection } from './left-section';
import type { LeftSectionProps } from './left-section';
import { RightSection } from './right-section';
import type { RightSectionProps } from './right-section';
import { Title } from './title';
import type { TitleProps } from './title';
import { CancelSubscriptionModal, modalStore } from '@components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@shared/UI';

import s from './styles.module.scss';

export type SubscriptionInfoModalProps = {
  subscriptionId: string;
} & BaseModalProps &
  TitleProps &
  Omit<LeftSectionProps, 'title'> &
  RightSectionProps;

export const SubscriptionInfoModal = observer(function SubscriptionInfoModal(
  props: SubscriptionInfoModalProps,
) {
  const { imageSrc, ownerName, title, endDate, priceList, subscriptionId, ...restProps } = props;

  const { t } = useTranslation();

  const handleCancelSubscriptionClick = () => {
    modalStore.activateModal({
      component: (
        <CancelSubscriptionModal
          endDate={endDate}
          subscriptionId={subscriptionId}
          isNeedToUnmount
        />
      ),
      id: 'cancel-subscription',
    });
  };

  return (
    <Modal
      centered
      size="lg"
      title={<Title imageSrc={imageSrc} ownerName={ownerName} title={title} />}
      id="subscription-info-modal"
      {...restProps}
    >
      <div className={s.subscriptionInfoModal}>
        <div className={s.subscriptionInfoModalContent}>
          <LeftSection title={title} endDate={endDate} />
          <RightSection priceList={priceList} />
        </div>
        <div className={s.subscriptionInfoModalButtons}>
          <a
            href="mailto: support@comms.fun"
            target="_blank"
            rel="noopener noreferrer"
            className={s.subscriptionInfoModalButtonsSupport}
          >
            <Button variant="dark-gray" size="sm">
              {t('shared-content.contact-support')}
            </Button>
          </a>
          <Button
            variant="dark-gray"
            size="sm"
            className={s.subscriptionInfoModalButtonsCancel}
            onClick={handleCancelSubscriptionClick}
          >
            {t('my-account.my-subscription.cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
});
