import { useMemo } from 'react';

import { useModalState } from '@hooks';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { StreamChatTabs, StreamChatUserStatus } from '@core/constants';
import { useRootStore } from '@core/store';

import { cl } from '@shared/libs/classnames';

import type { ContextMenuItem } from '@shared/UI';
import { Avatar, Button, ContextMenu, Group, SvgIcon, TypographyNew } from '@shared/UI';

import type { ObjectValues } from '@shared/types';

import s from './styles.module.scss';

interface MessageItemProps {
  authorId: number;
  authorName: string;
  tab: ObjectValues<typeof StreamChatTabs>;
  message?: string;
  messageId?: string;
  userStatus?: ObjectValues<typeof StreamChatUserStatus>;
}

export const MessageItem = observer(function MessageItem(props: MessageItemProps) {
  const { authorId, authorName, message, messageId, userStatus, tab } = props;

  const { t } = useTranslation();

  const { streamChatStore, streamsStore } = useRootStore();

  const { isOpen, onChange } = useModalState();

  const authorAvatarUrl = `${
    import.meta.env.VITE_MEDIA_SERVER_DEFAULT_API_URL_PATH
  }/attachment/lms/user/${authorId}/image`;

  const contectMenuItems: ContextMenuItem[] = useMemo(() => {
    if (tab === StreamChatTabs.Chat) {
      return [
        {
          key: 'mark',
          label: () => t('shared-content.mark-user'),
          onClick: () => streamChatStore.markUser(authorId),
        },
        {
          key: 'mute',
          label: () => t('shared-content.mute-user'),
          onClick: () => streamChatStore.muteUser(authorId),
        },
        {
          key: 'delete',
          label: () => t('shared-content.delete'),
          onClick: () => messageId && streamChatStore.deleteMessage(messageId),
        },
      ];
    }

    if (tab === StreamChatTabs.OnApprove) {
      return [
        {
          key: 'on-approve',
          label: () => t('shared-content.approve'),
          onClick: () => messageId && streamChatStore.approveMessage(messageId),
        },
      ];
    }

    if (tab === StreamChatTabs.Muted) {
      return [
        {
          key: 'reset',
          label: () => t('shared-content.reset'),
          onClick: () => streamChatStore.resetUser(authorId),
        },
      ];
    }

    return [];
  }, [tab]);

  const userAvatarClassname = cl({
    [s.userMarked]: userStatus === StreamChatUserStatus.Marked,
    [s.userMuted]: userStatus === StreamChatUserStatus.Muted,
  });

  return (
    <ContextMenu items={contectMenuItems} isOpen={isOpen} onChangeOpen={onChange}>
      <Group justify="space-between" align="center" w="100%">
        <Group>
          <Avatar src={authorAvatarUrl} className={userAvatarClassname} />
          <div>
            <TypographyNew variant="body-3" weight="bold">
              {authorName}
            </TypographyNew>
            {message && <TypographyNew variant="body-3">{message}</TypographyNew>}
          </div>
        </Group>
        {streamsStore.stream?.userCanModerateChat && (
          <Button variant="outline" size="icon" disabledAnimation onClick={() => onChange(true)}>
            <SvgIcon type="three-dots" />
          </Button>
        )}
      </Group>
    </ContextMenu>
  );
});
