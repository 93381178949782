import { makeAutoObservable, runInAction } from 'mobx';

import { SocketEventsCollection } from '@core/constants';
import type {
  ConnectToStreamChatResponse,
  GetStreamChatMessagesResponse,
  MarkedAndMutedUserEntity,
  StreamChatMessageEntity,
  StreamChatStopWordsEntity,
} from '@core/repositories';
import type { DeleteStreamChatMessageResponse } from '@core/repositories';
import { appSocket } from '@core/services/socket';
import { LoadingStore } from '@core/store/common';

export class StreamChatStore {
  private _messages: StreamChatMessageEntity[] = [];

  private _onApproveMessages: StreamChatMessageEntity[] = [];

  private _markedAndMutedUsers: MarkedAndMutedUserEntity[] = [];

  private _stopWords: string[] = [];

  private _currentChatId: string = '';

  private _isUserMuted: boolean = false;

  loader = new LoadingStore();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.initSubscribers();
  }

  get messages() {
    return this._messages;
  }

  get onApproveMessages() {
    return this._onApproveMessages;
  }

  get markedAndMutedUsers() {
    return this._markedAndMutedUsers;
  }

  get currentChatId() {
    return this._currentChatId;
  }

  get isUserMuted() {
    return this._isUserMuted;
  }

  get stopWords() {
    return this._stopWords;
  }

  connectToStreamChat = (streamId: string) => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.Connect, { streamId });
  };

  sendMessage = (message: string) => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.SendMessage, {
      message,
      streamChatId: this._currentChatId,
    });
  };

  getMessages = () => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.GetMessages, {
      limit: 50,
      skip: this._messages.length,
      streamChatId: this._currentChatId,
    });
  };

  deleteMessage = (messageId: string) => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.DeleteMessage, {
      messageId,
      streamChatId: this._currentChatId,
    });
  };

  markUser = (authorId: number) => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.MarkUser, {
      markedUserId: authorId,
      streamChatId: this._currentChatId,
    });
  };

  muteUser = (authorId: number) => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.MuteUser, {
      mutedUserId: authorId,
      streamChatId: this._currentChatId,
    });
  };

  getMarkedAndMutedUsers = () => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.GetMutedAndMarkedUsers, {
      streamChatId: this._currentChatId,
    });
  };

  resetUser = (authorId: number) => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.ResetUser, {
      resetUserId: authorId,
      streamChatId: this._currentChatId,
    });
  };

  getOnApproveMessages = () => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.GetOnApproveMessages, {
      streamChatId: this._currentChatId,
    });
  };

  approveMessage = (messageId: string) => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.ApproveMessage, {
      messageId,
      streamChatId: this._currentChatId,
    });
  };

  getStopWords = () => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.GetStopWords, {
      streamChatId: this._currentChatId,
    });
  };

  configStopWords = (stopWords: string[]) => {
    appSocket.dispatch(SocketEventsCollection.StreamChatEvents.Emitters.ConfigStopWords, {
      stopWords,
      streamChatId: this._currentChatId,
    });
  };

  private connectToStreamChatSubscriber = (details: ConnectToStreamChatResponse) => {
    runInAction(() => {
      this._messages = details.messages;
      this._currentChatId = details.streamChatId;
    });
  };

  private receiveMessageSubscriber = (details: StreamChatMessageEntity) => {
    runInAction(() => {
      this._messages = [details, ...this._messages] as StreamChatMessageEntity[];
    });
  };

  private getMessagesSubscriber = (details: GetStreamChatMessagesResponse) => {
    runInAction(() => {
      this._messages = [...this._messages, ...details.messages];
    });
  };

  private deleteMessageSubscriber = (details: DeleteStreamChatMessageResponse) => {
    runInAction(() => {
      this._messages = this._messages.filter((message) => message.id !== details.messageId);
    });
  };

  private muteUserSubscriber = () => {
    runInAction(() => {
      this._isUserMuted = true;
    });
  };

  private getMarkedAndMutedUsersSubscriber = (details: MarkedAndMutedUserEntity[]) => {
    runInAction(() => {
      this._markedAndMutedUsers = details;
    });
  };

  private getDroppedUserStatus = (details: MarkedAndMutedUserEntity) => {
    runInAction(() => {
      this._markedAndMutedUsers = this._markedAndMutedUsers.filter(
        (user) => user.authorId !== details.authorId,
      );
      this._isUserMuted = false;
    });
  };

  private getOnApproveMessagesSubscriber = (details: StreamChatMessageEntity[]) => {
    runInAction(() => {
      this._onApproveMessages = details;
    });
  };

  private approveMessageSubscriber = (details: StreamChatMessageEntity) => {
    runInAction(() => {
      this._onApproveMessages = this._onApproveMessages.filter(
        (message) => message.id !== details.id,
      );
    });
  };

  private getStopWordsSubscriber = (details: StreamChatStopWordsEntity[]) => {
    if (!details) {
      this.loader.startLoading();
    }

    try {
      runInAction(() => {
        this._stopWords = details[0].stopWords;
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.loader.startLoading();
    }
  };

  private initSubscribers = () => {
    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.Connected,
      this.connectToStreamChatSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.ReceiveMessage,
      this.receiveMessageSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.GetMessages,
      this.getMessagesSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.DeletedMessage,
      this.deleteMessageSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.UserMuted,
      this.muteUserSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.GetMutedAndMarkedUsers,
      this.getMarkedAndMutedUsersSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.DroppedUserStatus,
      this.getDroppedUserStatus,
    );

    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.GetOnApproveMessages,
      this.getOnApproveMessagesSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.ApprovedMessage,
      this.approveMessageSubscriber,
    );

    appSocket.subscribe(
      SocketEventsCollection.StreamChatEvents.Subscribers.GetStopWords,
      this.getStopWordsSubscriber,
    );
  };
}
