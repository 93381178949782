import { Outlet } from 'react-router-dom';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

export interface ChannelProps {}

const _Channel = observer((props: ChannelProps) => {
  const {} = props;

  return <Outlet />;
});

export const Channel = mergeComponentsWithName('Channel', _Channel);
