import type { HTMLAttributes, JSX, Key, MouseEvent, ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import { containerAnimate, containerExit, containerInitial } from './context-menu.animation';
import { setupMenuItemRef, useContextMenuFloating } from './libs';
import type { MenuItemProps } from './menu-item';
import { MenuItem } from './menu-item';
import { FloatingFocusManager, FloatingOverlay, FloatingPortal } from '@floating-ui/react';
import type { HTMLMotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { useLatestCallback, useUncontrolled } from '@shared/hooks';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('context-menu');

export type ContextMenuItem = MenuItemProps & {
  key: Key;

  closeByClick?: boolean;
};

export interface ContextMenuProps extends HTMLMotionProps<'div'> {
  children: JSX.Element;

  items?: ContextMenuItem[];

  isOpen?: boolean;

  onChangeOpen?: (value: boolean) => void;

  beforeSlot?: ReactNode;

  afterSlot?: ReactNode;
}

const _ContextMenu = observer((props: ContextMenuProps) => {
  const {
    children,
    isOpen,
    onChangeOpen,
    items = [],
    beforeSlot,
    afterSlot,
    className,
    ...restProps
  } = props;

  const _items = items.filter((item) => {
    return !('hidden' in item && item.hidden);
  });

  const [innerIsOpen, innerOnChangeIsOpen] = useUncontrolled({
    defaultValue: false,
    value: isOpen,
    onChange: onChangeOpen,
    finalValue: false,
  });

  const {
    getItemProps,
    getFloatingProps,
    floatingStyles,
    refs,
    listItemsRef,
    activeIndex,
    context,
  } = useContextMenuFloating({
    isOpen: innerIsOpen,
    items,
    onChangeOpen: innerOnChangeIsOpen,
  });

  const rootClasses = cl(rootClass, className);

  const canOpen = _items.length > 0 && innerIsOpen;

  const onHandleContextMenu = useLatestCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    innerOnChangeIsOpen(false);
  });

  return (
    <>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          const props = child.props as HTMLAttributes<HTMLElement>;
          return cloneElement(
            child,
            getItemProps({
              ...props,
              onContextMenu(e) {
                e.preventDefault();
                e.stopPropagation();
                innerOnChangeIsOpen(true);
                props?.onContextMenu?.(e);
                refs.setPositionReference({
                  getBoundingClientRect() {
                    return {
                      width: 0,
                      height: 0,
                      x: e.clientX,
                      y: e.clientY,
                      top: e.clientY,
                      right: e.clientX,
                      bottom: e.clientY,
                      left: e.clientX,
                    };
                  },
                });
              },
            }),
          );
        }

        return null;
      })}
      <AnimatePresence>
        {canOpen && (
          <FloatingPortal>
            <FloatingOverlay
              onContextMenu={onHandleContextMenu}
              lockScroll
              className={appendClass('-overlay')}
            >
              <FloatingFocusManager context={context} initialFocus={refs.floating}>
                <motion.div
                  animate={containerAnimate}
                  initial={containerInitial}
                  exit={containerExit}
                  {...restProps}
                  {...getFloatingProps({
                    ref: refs.setFloating,
                    className: rootClasses,
                    style: floatingStyles,
                  })}
                >
                  {beforeSlot}
                  <ul className={appendClass('-body')}>
                    {_items?.map((item, index) => {
                      const {
                        label,
                        key,
                        onClick,
                        onMouseUp,
                        innerRef,
                        closeByClick = true,
                        ...restItemProps
                      } = item;

                      return (
                        <MenuItem
                          key={key}
                          label={label}
                          innerRef={(node) => {
                            if (node) {
                              listItemsRef.current[index] = node;
                              setupMenuItemRef(node, innerRef);
                            }
                          }}
                          {...getItemProps({
                            tabIndex: activeIndex === index ? 0 : -1,
                            onClick: (event) => {
                              onClick?.(event as MouseEvent<HTMLLIElement>);
                              closeByClick && innerOnChangeIsOpen(false);
                            },
                            onMouseUp: (event) => {
                              onMouseUp?.(event as MouseEvent<HTMLLIElement>);
                              closeByClick && innerOnChangeIsOpen(false);
                            },
                          })}
                          {...restItemProps}
                        />
                      );
                    })}
                  </ul>
                  {afterSlot}
                </motion.div>
              </FloatingFocusManager>
            </FloatingOverlay>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  );
});

export const ContextMenu = mergeComponentsWithName('ContextMenu', _ContextMenu);
