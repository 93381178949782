import { makeAutoObservable, runInAction } from 'mobx';

import { SocketEventsCollection } from '@core/constants';
import type { UserCommunityUnreadInfo, UserCommunityUnreadStatus } from '@core/repositories';
import { appSocket } from '@core/services/socket';

interface IBaseUnread {
  totalCount: number;
}

interface ICommunitiesUnread extends IBaseUnread {
  communities: Record<string, Branch>;
  channels: Record<string, Branch>;
  chats: Record<string, Branch>;
}

export class Branch {
  parent: Branch | null = null;

  private _totalCount = 0;

  constructor(parent: Branch | null = null, totalCount = 0) {
    this.parent = parent;
    this._totalCount = totalCount;

    makeAutoObservable(
      this,
      {
        parent: false,
      },
      { autoBind: true },
    );
  }

  get totalCount() {
    return this._totalCount;
  }

  increaseCount = (count?: number) => {
    const _count = count ?? 1;

    this._totalCount += _count;

    if (this.parent) {
      this.parent.increaseCount(_count);
    }
  };

  decreaseCount = (count?: number) => {
    const _count = count ?? 1;
    this._totalCount = Math.max(0, this._totalCount - _count);
    if (this.parent) {
      this.parent.decreaseCount(count);
    }
  };

  updateCount = (count: number) => {
    this._totalCount = Math.max(0, count);
    if (this.parent) {
      this.parent.updateCount(count);
    }
  };

  resetCount = () => {
    if (this.parent) {
      this.parent.decreaseCount(this._totalCount);
    }
    this._totalCount = 0;
  };
}

export class MessageCounterStore {
  public privateChats = new Branch();

  public communities: ICommunitiesUnread = {
    totalCount: 0,
    communities: {},
    channels: {},
    chats: {},
  };

  constructor() {
    // this.initSubscribers();

    makeAutoObservable(this, {}, { autoBind: true });
  }

  public get communityGlobalUnreadCount() {
    return Object.values(this.communities.communities).reduce(
      (acc, curr) => acc + curr.totalCount,
      0,
    );
  }

  public getCommunity = (communityId: number): Branch | null => {
    return this.communities.communities[communityId] ?? null;
  };

  public getChannel = (channelId: string): Branch | null => {
    return this.communities.channels[channelId] ?? null;
  };

  public getChat = (chatId: string): Branch | null => {
    return this.communities.chats[chatId] ?? null;
  };

  private receiveUnreadStatuses = (details: UserCommunityUnreadStatus) => {
    runInAction(() => {
      this.privateChats = new Branch(null, details.privateChatUnreadCount.unreadMessagesCount);
      this.communities = {
        totalCount: this.calcTotalCommunityCount(details.userCommunityUnreadInfo),
        ...this.createBranches(details.userCommunityUnreadInfo),
      };
    });
  };

  private calcTotalCommunityCount = (details: UserCommunityUnreadInfo) => {
    let count = 0;

    for (const key in details) {
      if (details.hasOwnProperty(key)) {
        count += details[key].unreadCount;
      }
    }

    return count;
  };

  private createBranches = (userInfo: UserCommunityUnreadInfo) => {
    const branches: Omit<ICommunitiesUnread, 'totalCount'> = {
      channels: {},
      chats: {},
      communities: {},
    };

    for (const [communityId, channelsInfo] of Object.entries(userInfo)) {
      const communityBranch = new Branch(null, channelsInfo.unreadCount);
      branches.communities[communityId] = communityBranch;

      for (const channelInfo of channelsInfo.unreadInfo) {
        const {
          categoryId: channelCategoryId,
          unreadCount: channelUnreadCount,
          unreadInfo: channelUnreadInfo,
        } = channelInfo;
        const channelBranch = new Branch(communityBranch, channelUnreadCount);

        for (const chatInfo of channelUnreadInfo) {
          const { communicationItemId, unreadCount: chatUnreadCount } = chatInfo;
          const chatBranch = new Branch(channelBranch, chatUnreadCount);

          branches.chats[communicationItemId] = chatBranch;
        }

        branches.channels[channelCategoryId] = channelBranch;
      }
    }

    return branches;
  };

  private initSubscribers = () => {
    appSocket.subscribe(
      SocketEventsCollection.CommonEvents.Subscribers.ReceiveUnreadStatuses,
      this.receiveUnreadStatuses,
    );
  };

  initEvents = () => {
    this.initSubscribers();
  };

  disposer = () => {
    this.privateChats.resetCount();
    this.communities.totalCount = 0;
    this.communities.channels = {};
    this.communities.chats = {};
    this.communities.communities = {};
  };
}
