import type { InternalAxiosRequestConfig } from 'axios';

import { CONTENT_TYPE } from '@core/constants';

export const requestMediaFulfilledInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  const contentType = config.headers['Content-Type']
    ? config.headers['Content-Type']
    : CONTENT_TYPE.FORM_DATA;

  config.headers['Access-Control-Allow-Credentials'] = true;
  config.headers['Content-Type'] = contentType;
  config.headers['X-User-Timezone'] = new Date().getTimezoneOffset();

  return config;
};
