import App from './App';
import './i18n';
import { WelcomeScreen } from '@components';
import ReactDOM from 'react-dom/client';

import { RootStoreProvider, rootStore } from '@core/store';

import './styles/index.scss';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <RootStoreProvider value={rootStore}>
    <WelcomeScreen />
    <App />
  </RootStoreProvider>,
);
