import { useRef } from 'react';

import { useEventListener } from '../use-event-listener';

type Event = MouseEvent | TouchEvent;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  handler: (event: Event) => void,
) => {
  const ref = useRef<T>(null);

  const listener = (event: Event) => {
    const el = ref?.current;

    if (!el || el.contains((event?.target as Node) || null)) {
      return;
    }

    handler(event);
  };

  useEventListener('mousedown', listener);
  useEventListener('touchstart', listener);

  return ref;
};
