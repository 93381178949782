import { CommunitiesCollection } from './communities-collection';
import { dropdownAnimation } from './community-selector.config';
import { useModalState } from '@hooks';

import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Avatar, Dropdown, Group, SvgIcon, TypographyNew } from '@shared/UI';

import { mergeComponentsWithName, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

const _CommunitySelector = observer(() => {
  const { communitiesStore } = useRootStore();

  const { isOpen, onToggle } = useModalState();

  const isShowDropdown =
    communitiesStore.purchasedCommunities &&
    communitiesStore.purchasedCommunities.businessAccounts.length > 1;

  const communityLogo = communitiesStore?.activeCommunity?.logo
    ? withMediaUrl(communitiesStore?.activeCommunity?.logo, true)
    : '';

  return (
    <Dropdown
      className={s.communitySelector}
      isOpen={isOpen}
      onOpenChange={isShowDropdown ? () => onToggle() : () => {}}
      content={<CommunitiesCollection />}
      data-active={isOpen}
      animationVariants={dropdownAnimation}
      popupShift={{ padding: 0 }}
      placement="bottom-start"
    >
      <Group w="100%" justify="space-between" wrap="nowrap">
        <Group>
          <Avatar src={communityLogo} />
          <TypographyNew as="span" truncate weight="bold" variant="body-1">
            {communitiesStore?.activeCommunity?.communityTitle}
          </TypographyNew>
        </Group>
        {isShowDropdown && <SvgIcon type="double-arrow" className={s.communitySelectorIcon} />}
      </Group>
    </Dropdown>
  );
});

export const CommunitySelector = mergeComponentsWithName('CommunitySelector', _CommunitySelector);
