import type { ICategoryStoreOptions } from './channel.store';
import { ChannelStore } from './channel.store';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import { SocketEventsCollection } from '@core/constants';
import type {
  ConnectToCommunityResponse,
  CreateCommunityChannelDto,
  CreateCommunityChannelResponse,
  UpdateCommunityChannelDto,
  UpdateCommunityChannelResponse,
  UserCommunityUnreadStatus,
} from '@core/repositories';
import { communityRepository } from '@core/repositories';
import { getHttpError } from '@core/services/http';
import { appSocket } from '@core/services/socket';
import { LoadingStore } from '@core/store/common';

type ChannelBucket = {
  loading: LoadingStore;
  channel: ChannelStore;
};

type ChannelsMap = Map<string, ChannelBucket>;

export class ChannelsStore {
  channelsLoader = new LoadingStore();

  createChannelLoader = new LoadingStore();

  private _channelsMap: ChannelsMap = new Map();

  private _activeChannel: ChannelBucket | null = null;

  private _unreadHashMap = new Map<string, number>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    // this.initEvents();
  }

  //#region --- Getters
  get activeChannel() {
    return this._activeChannel;
  }

  get channelsList() {
    return this.makeChannelsList();
  }

  get pinnedChannels() {
    return this.channelsList.filter((channel) => channel.isPinned);
  }

  get unpinnedChannels() {
    return this.channelsList.filter((channel) => !channel.isPinned);
  }

  get calcAllUnreadCount() {
    let num = 0;

    this._unreadHashMap.forEach((n) => {
      num += n;
    });

    return num;
  }

  //#endregion --- Getters

  //#region --- Actions

  createChannel = async (dto: CreateCommunityChannelDto) => {
    this.createChannelLoader.startLoading();
    try {
      await communityRepository.createChannel(dto);
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
    } finally {
      this.createChannelLoader.stopLoading();
    }
  };

  updateChannelMedia = async (channelId: string, dto: Partial<CreateCommunityChannelDto>) => {
    this.createChannelLoader.startLoading();
    try {
      await communityRepository.updateChannel(channelId, dto);
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
    } finally {
      this.createChannelLoader.stopLoading();
    }
  };

  updateChannels = (channels: ChannelStore[]) => {
    const updatedChannelsMap: ChannelsMap = new Map();

    channels.forEach((channel) => {
      const createdBucket = this.createChannelBucket(channel);
      updatedChannelsMap.set(channel.id, createdBucket);
    });

    runInAction(() => {
      this._channelsMap = updatedChannelsMap;
    });
  };

  receiveUnreadByChannelId = (channelId: string) => {
    const num = this._unreadHashMap.get(channelId);

    return num ?? 0;
  };

  //#endregion --- Actions

  //#region --- Emitters

  connectToChannelEmitter = (channelId: string) => {
    if (this._activeChannel?.channel.id === channelId) return;
    const bucket = this.channelBucketById(channelId);
    this._activeChannel = bucket;
    bucket?.loading.startLoading();
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.ConnectCategory, {
      categoryId: channelId,
    });
  };

  awaitedConnectToChannel = async (channelId: string) => {
    if (this._activeChannel?.channel.id === channelId) return;
    this.channelsLoader.startLoading();
    this._activeChannel = this.channelBucketById(channelId);
    await appSocket.awaitedDispatch(
      SocketEventsCollection.CommunityEvents.Emitters.ConnectCategory,
      SocketEventsCollection.CommunityEvents.Subscribers.ConnectCategory,
      {
        categoryId: channelId,
      },
    );
    this.channelsLoader.completeLoading();
  };

  updateChannelEmitter = async (dto: UpdateCommunityChannelDto) => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.UpdateCategory, dto);
  };

  updateChannelsPositionEmitter = (updatedChannels: ChannelStore[]) => {
    this.updateChannels(updatedChannels);
    const dto = {
      categories: updatedChannels.map((channel, idx) => ({
        id: channel.id,
        position: idx,
      })),
    };
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.UpdateCategoryOrder, dto);
  };

  resetActiveChannel = () => {
    this._activeChannel = null;
  };

  //#endregion --- Emitters

  //#region --- Subscribers

  private createChannelSubscriber = (details: CreateCommunityChannelResponse) => {
    const channelBucket = this.createChannelBucket(details);

    runInAction(() => {
      this._channelsMap.set(details.id, channelBucket);
    });
  };

  private updateChannelSubscriber = (details: UpdateCommunityChannelResponse) => {
    const channel = this.channelById(details.id);

    if (channel) {
      channel.updateChannelInfo(details);
    }
  };

  private connectToCommunitySubscriber = (details: ConnectToCommunityResponse) => {
    const channelsMap: ChannelsMap = new Map();

    for (let i = 0; details.length > i; i++) {
      const channelBucket = this.createChannelBucket(details[i]);
      channelsMap.set(details[i].id, channelBucket);
    }

    runInAction(() => {
      this._channelsMap = channelsMap;
      this.channelsLoader.stopLoading();
      this.channelsLoader.setLoaded(true);
    });
  };

  private receiveUnreadStatusSubscriber = (details: UserCommunityUnreadStatus) => {
    runInAction(() => {
      Object.values(details.userCommunityUnreadInfo).forEach((item) => {
        item.unreadInfo.forEach((channel) => {
          this._unreadHashMap.set(channel.categoryId, channel.unreadCount);
        });
      });
    });
  };

  //#endregion --- Subscribers

  //#region --- Emit Listeners

  private connectToCommunityListener = () => {
    this.channelsLoader.startLoading();
  };

  //#endregion --- Emit Listeners

  //#region --- Helpers

  private createChannelBucket = (channel: ICategoryStoreOptions | ChannelStore) => {
    const bucket: ChannelBucket = {
      loading: new LoadingStore(),
      channel: channel instanceof ChannelStore ? channel : new ChannelStore(channel),
    };
    return bucket;
  };

  private makeChannelsList = () => {
    return Array.from(this._channelsMap, ([, channelBucket]) => channelBucket.channel);
  };

  channelById = (id: string) => {
    return this.channelBucketById(id)?.channel ?? null;
  };

  channelBucketById = (id: string) => {
    return this._channelsMap.get(id) ?? null;
  };

  //#endregion --- Helpers

  //#region --- Initialisers

  private initDispatchListeners = () => {
    appSocket.subscribeDispatch(
      SocketEventsCollection.CommunityEvents.Emitters.Connect,
      this.connectToCommunityListener,
    );
  };

  private initSubscribers = () => {
    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.Connect,
      this.connectToCommunitySubscriber,
    );
    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.CreateChannel,
      this.createChannelSubscriber,
    );
    appSocket.subscribe(
      SocketEventsCollection.CommunityEvents.Subscribers.UpdateCategory,
      this.updateChannelSubscriber,
    );
    appSocket.subscribe(
      SocketEventsCollection.CommonEvents.Subscribers.ReceiveUnreadStatuses,
      this.receiveUnreadStatusSubscriber,
    );
  };

  //#endregion --- Initialisers

  initEvents = () => {
    this.initDispatchListeners();
    this.initSubscribers();
  };

  disposer = () => {
    this.channelsLoader = new LoadingStore();
    this.createChannelLoader = new LoadingStore();
    this._channelsMap = new Map();
    this._activeChannel = null;
  };
}
