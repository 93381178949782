import type { ChangeEvent, ReactNode } from 'react';
import { Children, forwardRef, useCallback, useMemo, useRef } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { UploadError, UploadedFile } from '@shared/UI';
import { Typography } from '@shared/UI';
import { useClassName } from '@shared/UI/_hooks';

import { useId, useMergedRef } from '@shared/hooks';
import type { ElementProps } from '@shared/types';
import { getMbFromBytes } from '@shared/utils';
import { isAllowFileSize } from '@shared/utils/is-allow-file-size';

import './styles.scss';

export interface UploadProps extends ElementProps<'input', 'type' | 'onChange' | 'onError'> {
  label?: string;

  children?: ReactNode;

  maxFileSize?: number;

  maxFiles?: number;

  onChange?: (files: UploadedFile[], event: ChangeEvent<HTMLInputElement>) => void;

  onError?: (type: UploadError) => void;

  showErrorNotification?: boolean;
}

export const Upload = observer(
  forwardRef<HTMLInputElement, UploadProps>((props, ref) => {
    const {
      label = 'Upload',
      className,
      onChange,
      maxFileSize = 2,
      maxFiles,
      disabled,
      onError,
      children,
      id,
      multiple,
      showErrorNotification = false,
      ...restProps
    } = props;

    const { rootClassName, appendClass } = useClassName('upload');
    const inputRef = useRef<HTMLInputElement>(null);

    const { t } = useTranslation();

    const refs = useMergedRef(inputRef, ref);

    const _id = useId(id);

    const rootClasses = cl(
      rootClassName,
      {
        [appendClass('--unstyled')]: Children.count(children) > 0,
      },
      className,
    );

    const _maxFiles = useMemo(() => {
      if (typeof maxFiles === 'number') return maxFiles;
      if (multiple) return 10;
      return 1;
    }, [maxFiles, multiple]);

    const handleError = useCallback(
      (error: UploadError) => {
        if (showErrorNotification) {
          if (error.type === 'maxFileSize') {
            toast.error(t('errors.large-file-size', { size: error.allowedSize }));
          }
        }
        onError?.(error);
      },
      [onError, showErrorNotification],
    );

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (!inputRef || !inputRef.current) return;

        const files = Array.from(event.target.files || []);

        let errorType: UploadError | null = null;

        if (files.length > _maxFiles) {
          errorType = {
            type: 'maxFiles',
            size: files.length,
            allowed: _maxFiles,
          };
        }

        const uploadedFiles = files.reduce((acc: UploadedFile[], file) => {
          const uploadedFile: UploadedFile = {
            id: nanoid(),
            originalFile: file,
            name: file.name,
            size: file.size,
            type: file.type,
            url: URL.createObjectURL(file),
          };

          const canBeUploaded = isAllowFileSize(uploadedFile.size, maxFileSize);

          if (canBeUploaded) {
            acc.push(uploadedFile);
          } else {
            errorType = {
              type: 'maxFileSize',
              fileSize: `${getMbFromBytes(uploadedFile.size)} MB`,
              allowedSize: `${maxFileSize} mb`,
            };
          }
          return acc;
        }, []);

        if (errorType) {
          handleError(errorType);
        } else {
          onChange?.(uploadedFiles, event);
        }

        inputRef.current.value = '';
      },
      [_maxFiles, maxFileSize, onChange, handleError],
    );

    return (
      <label htmlFor={_id} className={rootClasses} data-disabled={disabled}>
        <input
          className={appendClass('-input')}
          id={_id}
          type="file"
          ref={refs}
          onChange={handleChange}
          disabled={disabled}
          multiple={multiple}
          {...restProps}
        />
        {children ? (
          children
        ) : (
          <Typography as="span" variant="body-medium-3">
            {label}
          </Typography>
        )}
      </label>
    );
  }),
);
