import { useCallback, useMemo, useState } from 'react';

import { ActionBtn } from './action-btn';
import { Checkboxes } from './checkboxes';
import { Description } from './description';
import { Radios } from './radios';
import { HomeworkTestType } from '@constants';
import { useTranslation } from 'react-i18next';

import type { MyHomeworkTypes } from '@core/repositories';
import type { HomeworkTestStore } from '@core/store';

import CalendarWithCheckmark from '@assets/img/calendar-with-checkmark.png';

import { AnimatePresence, motion } from '@shared/libs/framer-motion';
import { observer } from '@shared/libs/mobx';

import { Stack, Typography } from '@shared/UI';

import s from './styles.module.scss';

export type CollapseContentProps = {
  task: HomeworkTestStore;

  blockKey: MyHomeworkTypes;
};

export const CollapseContentTest = observer(function CollapseContent(props: CollapseContentProps) {
  const { task } = props;

  const { t } = useTranslation();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleSelectedIds = useCallback((value: string | string[]) => {
    if (Array.isArray(value)) {
      setSelectedIds(() => value);
    } else {
      setSelectedIds([value]);
    }
  }, []);

  const clearSelectedIds = useCallback(() => {
    setSelectedIds([]);
  }, []);

  const isNotEmptyIds = useCallback(() => {
    return selectedIds.length > 0;
  }, [selectedIds]);

  const canShowActions = useMemo(() => {
    return task.isCompleted || task.blockKey === 'verified' || task.blockKey === 'toBeSent';
  }, [task]);

  const testTypeContent = () => {
    if (task.isCompleted && task.blockKey === 'toBeSent') {
      return (
        <Stack gap="xs">
          <div>
            <img src={CalendarWithCheckmark} alt="complete test" />
          </div>

          <Typography as="p" align="center" variant="body-medium-2">
            {t('my-workspace.homework.successfully-passed')}
          </Typography>
          <Typography as="p" align="center" variant="body-medium-3">
            {t('my-workspace.homework.watch-results')}
          </Typography>
        </Stack>
      );
    }

    if (task.currentTest.testType === HomeworkTestType.radiobutton) {
      return (
        <Radios
          options={task.currentTest.testOptions}
          selectedIds={selectedIds}
          onChange={handleSelectedIds}
          canInteract={task.blockKey === 'toBeSent'}
        />
      );
    }

    if (task.currentTest.testType === HomeworkTestType.checkbox) {
      return (
        <Checkboxes
          options={task.currentTest.testOptions}
          selectedIds={selectedIds}
          onChange={handleSelectedIds}
          canInteract={task.blockKey === 'toBeSent'}
        />
      );
    }

    return null;
  };

  return (
    <div className={s.contentTest}>
      <Description task={task} />

      <div className={s.contentTestFieldsWrapper}>
        <AnimatePresence initial={false} mode="wait">
          <motion.div>{testTypeContent()}</motion.div>
        </AnimatePresence>
      </div>

      {canShowActions && (
        <div className={s.contentTestActionBtnWrapper}>
          <ActionBtn
            isNotEmptyIds={isNotEmptyIds}
            selectedIds={selectedIds}
            onClear={clearSelectedIds}
            task={task}
          />
        </div>
      )}
    </div>
  );
});
