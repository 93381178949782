import { useCallback, useRef, useState } from 'react';

import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { RecordingType, useMediaRecorderContext } from '@units';
import { LongPressCallback, useLongPress } from 'use-long-press';

import { useLatestCallback } from '@shared/hooks';
import { ObjectValues } from '@shared/types';

type LongPressContext = {
  targetType: ObjectValues<typeof RecordingType>;
};

export const useMediaRecordButtonsController = () => {
  const {
    recordingType,
    videoRecordOptions,
    audioRecordOptions,
    cancelRecord,
    startRecordingAudio,
    startRecordingVideo,
    stopRecording,
  } = useMediaRecorderContext();

  const videoContainerRef = useRef<HTMLElement>();

  const { refs, context, floatingStyles, strategy } = useFloating({
    open: audioRecordOptions.status === 'recording' || videoRecordOptions.status === 'recording',
    onOpenChange: useCallback((open: boolean) => {
      if (open) {
        stopRecording();
      }
    }, []),
  });

  const [currentBtn, setCurrentBtn] = useState<ObjectValues<typeof RecordingType>>(
    RecordingType.Video,
  );

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, {
      ancestorScroll: true,
      bubbles: false,
    }),
  ]);

  const onAudioRecord = useCallback(() => {
    if (audioRecordOptions.status === 'recording') {
      stopRecording();
    } else {
      startRecordingAudio();
    }
  }, [audioRecordOptions]);

  const onVideoRecord = useCallback(() => {
    if (videoRecordOptions.status === 'recording') {
      stopRecording();
    } else {
      startRecordingVideo();
    }
  }, [videoRecordOptions]);

  const onHandleRecord: LongPressCallback<Element, LongPressContext> = (_, meta) => {
    switch (meta.context?.targetType) {
      case RecordingType.Audio:
        onAudioRecord();
        break;
      case RecordingType.Video:
        onVideoRecord();
        break;
      default:
        break;
    }
  };

  const onSwitchRecordBtn = useCallback(() => {
    if (audioRecordOptions.status !== 'recording' && videoRecordOptions.status !== 'recording') {
      setCurrentBtn((prevState) =>
        prevState === RecordingType.Video ? RecordingType.Audio : RecordingType.Video,
      );
    }
  }, [audioRecordOptions, videoRecordOptions]);

  const onCancel = () => {
    if (videoRecordOptions.status === 'recording' || audioRecordOptions.status === 'recording') {
      stopRecording();
      return;
    }
    onSwitchRecordBtn();
  };

  const bindLongPress = useLongPress<Element, LongPressContext>(onHandleRecord, {
    onCancel,
    threshold: 400,
  });

  const setupVideoContainerRef = useLatestCallback((node: HTMLElement | null) => {
    if (!node) return;
    refs.setFloating(node);
    videoContainerRef.current = node;
  });

  return {
    refs,
    context,
    recordingType,
    videoRecordOptions,
    audioRecordOptions,
    currentBtn,
    videoContainerRef,
    floatingStyles,
    strategy,
    cancelRecord,
    setupVideoContainerRef,
    stopRecording,
    getReferenceProps,
    getFloatingProps,
    bindLongPress,
    onAudioRecord,
    onVideoRecord,
  };
};
