import type { CSSProperties, ReactNode } from 'react';

import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('badge');

const badge = cva(rootClass, {
  variants: {
    variant: {
      gradient: appendClass('--gradient'),
      black: appendClass('--black'),
      blackOpacity: appendClass('--black-opacity'),
      purple: appendClass('--purple'),
      blue: appendClass('--blue'),
      gray: appendClass('--gray'),
      custom: appendClass('--custom'),
    },
    size: {
      sm: appendClass('--sm'),
      md: appendClass('--md'),
    },
    weight: {
      bold: appendClass('--bold'),
      regular: appendClass('--regular'),
    },
  },

  defaultVariants: {
    variant: 'black',
    size: 'sm',
    weight: 'regular',
  },
});

export interface BadgeProps
  extends ElementProps<'div', 'text' | 'children'>,
    VariantProps<typeof badge> {
  label?: string | number;
  fullWidth?: boolean;
  backgroundColor?: CSSProperties['backgroundColor'];
  color?: CSSProperties['color'];
  leftSection?: ReactNode;
  rightSection?: ReactNode;
}

const _Badge = observer((props: BadgeProps) => {
  const {
    label,
    className,
    style,
    variant,
    size,
    weight,
    backgroundColor,
    color,
    leftSection,
    rightSection,
    fullWidth = false,
    ...restProps
  } = props;

  const _variant = backgroundColor ? null : variant;

  const rootClasses = badge({
    variant: _variant,
    size,
    weight,
    className: cl(
      {
        [appendClass('--fullWidth')]: fullWidth,
      },
      className,
    ),
  });

  const _style: CSSProperties = {
    backgroundColor,
    color,
    ...style,
  };

  return (
    <div className={rootClasses} style={_style} {...restProps}>
      {leftSection && (
        <span className={appendClass('-section')} data-position="left">
          {leftSection}
        </span>
      )}
      <span className={appendClass('-label')}>{label}</span>
      {rightSection && (
        <span className={appendClass('-section')} data-position="right">
          {rightSection}
        </span>
      )}
    </div>
  );
});

export const Badge = mergeComponentsWithName('Badge', _Badge);
