import type { RefObject } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import { SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

export type NavigationArrowsProps = {
  prevBtnRef?: RefObject<HTMLButtonElement>;
  nextBtnRef?: RefObject<HTMLButtonElement>;
};

export const NavigationArrows = observer(function NavigationArrows(props: NavigationArrowsProps) {
  const { prevBtnRef, nextBtnRef } = props;

  return (
    <div className={s.navigationArrows}>
      <div className={s.navigationArrowsBox}>
        <button ref={prevBtnRef} className={cl(s.navigationArrowsBtn, s.navigationArrowsBtnLeft)}>
          <SvgIcon type="arrow-right" />
        </button>
        <button ref={nextBtnRef} className={cl(s.navigationArrowsBtn, s.navigationArrowsBtnRight)}>
          <SvgIcon type="arrow-right" />
        </button>
      </div>
    </div>
  );
});
