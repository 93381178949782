import type { Editor } from '@tiptap/react';

import { createSafeContext } from '@shared/utils';

interface RichEditorContext {
  editor: () => Editor;
}

export const [RichEditorProvider, useRichEditorContext] =
  createSafeContext<RichEditorContext>('RichEditorProvider');
