import { useMemo } from 'react';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('circle-progress');

export interface CircleProgressProps extends ElementProps<'svg'> {
  progress?: number;

  circleRadius?: number;

  strokeWidth?: number;

  size?: number;
}

const _CircleProgress = observer((props: CircleProgressProps) => {
  const { progress = 0, size = 220, className, strokeWidth = 3, ...restProps } = props;

  const r = size / 2 - 10;
  const cxy = size / 2;
  const strokeDasharray = 3.14 * r * 2;

  const _progress = useMemo(() => {
    if (progress <= 0) return 0;
    if (progress > 100) return 100;

    const c = Math.PI * (r * 2);

    const pct = ((100 - progress) / 100) * c;

    return pct;
  }, [progress, r]);

  const rootClasses = cl(rootClass, className);

  return (
    <svg
      id="svg"
      width={size}
      height={size}
      viewBox={`-${size * 0.125} -${size * 0.125} ${size * 1.25} ${size * 1.25}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={rootClasses}
      {...restProps}
    >
      <circle
        r={r}
        cx={cxy}
        cy={cxy}
        fill="transparent"
        strokeDasharray={strokeDasharray}
        strokeDashoffset="0"
        strokeWidth={strokeWidth}
      ></circle>
      <circle
        className={appendClass('-bar')}
        r={r}
        cx={cxy}
        cy={cxy}
        fill="transparent"
        strokeDasharray={strokeDasharray}
        strokeDashoffset={_progress}
        strokeWidth={strokeWidth}
      ></circle>
    </svg>
  );
});

export const CircleProgress = mergeComponentsWithName('CircleProgress', _CircleProgress);
