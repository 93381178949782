import { observer } from 'mobx-react-lite';
import type { SkeletonProps as SkeletonUiProps } from 'react-loading-skeleton';
import { default as SkeletonUi } from 'react-loading-skeleton';

import { cl } from '@shared/libs/classnames';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

import 'react-loading-skeleton/dist/skeleton.css';

const { rootClass, appendClass } = classNames('skeleton');

export type SkeletonProps = {} & SkeletonUiProps;

const _Skeleton = observer((props: SkeletonProps) => {
  const { className, duration = 2, width, height, style, ...restProps } = props;

  const rootClasses = cl(rootClass, className);

  const containerClasses = cl(appendClass('-container'), {
    [cl(appendClass('-container--fullWidth'))]: !width || width === '100%',
  });

  const _style = {
    '--width-persentage': typeof width === 'string' && width.includes('%') ? width : undefined,
    ...style,
  };

  return (
    <SkeletonUi
      style={_style}
      width={width}
      height={height}
      containerClassName={containerClasses}
      className={rootClasses}
      baseColor="rgba(255, 255, 255, 0.01)"
      highlightColor="rgba(255, 255, 255, 0.05)"
      duration={duration}
      {...restProps}
    />
  );
});
export const Skeleton = mergeComponentsWithName('Skeleton', _Skeleton);
