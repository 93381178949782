const dashboard = {
  root: '/dashboard',
} as const;

const community = {
  root: '/communities',
  community: '/communities/:businessAccountId',
  communityChannel: '/communities/:businessAccountId/:channelId',
  communityChat: '/communities/:businessAccountId/:channelId/:chatId',
  communityPreview: '/communities/overview/:businessAccountId',
  categories: '/communities/:businessAccountId/categories',
  category: '/communities/:businessAccountId/categories/:categoryId',
  course: '/communities/course/:courseId',
} as const;

const privateChats = {
  root: '/private-chats',
  get chats() {
    return `${this.root}/chats`;
  },
  get activeChat() {
    return `${this.chats}/:chatId`;
  },
} as const;

const myWorkspace = {
  root: '/my-workspace',
  courses: '/my-workspace/courses',
  course: '/my-workspace/courses/:id',
  id: '/my-workspace/:id',
  homeWork: '/my-workspace/homework',
  streams: '/my-workspace/streams/:id',
} as const;

const events = {
  root: '/events',
  id: '/events/:id',
} as const;

const myAccount = {
  root: '/my-account',
} as const;

const auth = {
  root: '/auth',
  login: '/auth/sign-in',
  registration: '/auth/sign-up',
  forgotPassword: '/auth/forgot-password',
  newPassword: '/auth/new-password',
  termsOfService: '/auth/privacy-policy-of-service',
  privacyPolicy: '/auth/privacy-policy',
} as const;

const onboarding = {
  root: '/onboarding',
  interests: '/onboarding/interests',
} as const;

const paymentStatus = {
  root: '/payment-status',
} as const;

const notFound = {
  root: '/404',
} as const;

const sharedLinkPatterns = {
  communityPreview: /(\/communities\/overview\/\d+\&shared=true)/,
} as const;

export const routerCollection = {
  dashboard,
  community,
  myWorkspace,
  events,
  myAccount,
  auth,
  onboarding,
  paymentStatus,
  notFound,
  privateChats,
  sharedLinkPatterns,
} as const;
