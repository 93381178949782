import { useState } from 'react';

import { BaseAccordionLabel, VideoTimecodes } from '@components';
// import { routerCollection } from '@constants';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

// import { useNavigate } from 'react-router-dom';
import type {
  CoursePreviewLessonBlockEntity,
  CoursePreviewModuleBlockEntity,
} from '@core/repositories';

import { Accordion, Button, For, SvgIcon, TypographyNew } from '@shared/UI';

// import { useQuery } from '@shared/hooks';
import s from './styles.module.scss';

interface CoursePreviewAccordionProps {
  lessons?: CoursePreviewLessonBlockEntity[];
  modules?: CoursePreviewModuleBlockEntity[];
  groupId?: number;
  isPurchased?: boolean;
  scrollToPricesHandler?: () => void;
}

export const CoursePreviewAccordion = observer(function CoursePreviewAccordion(
  props: CoursePreviewAccordionProps,
) {
  const { lessons = [], modules = [] } = props;

  const { t } = useTranslation();

  const [showAllLessons, setShowAllLessons] = useState(false);

  // const navigate = useNavigate();

  // const { set } = useQuery();

  const adaptLessonsLength = (items: any) => {
    if (showAllLessons) return items;

    return items.slice(0, 5);
  };

  // const path = `${routerCollection.myWorkspace.courses}/${groupId}`;

  const renderLessonsContent = (lesson: CoursePreviewLessonBlockEntity) => {
    // const handleTimecodeClick = () => {
    //   if (isPurchased && lesson.id) {
    //     navigate(path);
    //     set({ lessonId: lesson.id.toString() });
    //   } else {
    //     scrollToPricesHandler?.();
    //   }
    // };

    return (
      <Accordion.Item
        key={`${lesson.title}-${lesson.position}`}
        value={`${lesson.title}-${lesson.position}`}
        className={s.coursePreviewAccordionItem}
      >
        <Accordion.Control className={s.coursePreviewAccordionControl}>
          <div>
            <BaseAccordionLabel
              title={`${t('shared-content.lesson')} ${lesson.position}`}
              subtitle={lesson.title}
            />
            <TypographyNew variant="body-3" colorSchema="neutral-200">
              <SvgIcon type="alarm" />
              <span className={s.coursePreviewAccordionControlDuration}>{lesson.duration}</span>
            </TypographyNew>
          </div>
        </Accordion.Control>
        <Accordion.Panel>
          <VideoTimecodes videoTimecodes={lesson.videoTimecodes} />
        </Accordion.Panel>
      </Accordion.Item>
    );
  };

  const renderModuleContent = (module: CoursePreviewModuleBlockEntity) => {
    // const handleTimecodeClick = (lessonId?: number) => {
    //   if (isPurchased) {
    //     navigate(path);
    //     set({ moduleId: module.id.toString(), lessonId: lessonId?.toString() });
    //   } else {
    //     scrollToPricesHandler?.();
    //   }
    // };

    return (
      <Accordion.Item
        key={module.id}
        value={`${module.id}-${module.title}`}
        className={s.coursePreviewAccordionItem}
      >
        <Accordion.Control className={s.coursePreviewAccordionControl}>
          <BaseAccordionLabel
            title={`${t('shared-content.module')} ${module.position}`}
            subtitle={module.title}
          />
        </Accordion.Control>
        <Accordion.Panel>
          <VideoTimecodes lessons={module.lessons} />
        </Accordion.Panel>
      </Accordion.Item>
    );
  };

  return (
    <div className={s.root}>
      <div className={s.coursePreviewAccordion}>
        {lessons.length ? (
          <Accordion id="course-landing-lessons">
            <For data={adaptLessonsLength(lessons)} render={renderLessonsContent} />
          </Accordion>
        ) : (
          <Accordion id="course-landing-modules">
            <For data={adaptLessonsLength(modules)} render={renderModuleContent} />
          </Accordion>
        )}
      </div>

      {(lessons.length > 5 || modules.length > 5) && (
        <>
          {!showAllLessons ? (
            <Button onClick={() => setShowAllLessons(true)} className={s.coursePreviewAccordionBtn}>
              {t('shared-content.view-all')}
            </Button>
          ) : (
            <Button
              onClick={() => setShowAllLessons(false)}
              className={cl(s.coursePreviewAccordionBtn, s.coursePreviewAccordionBtnHide)}
            >
              {t('shared-content.hide')}
            </Button>
          )}
        </>
      )}
    </div>
  );
});
