import type { BaseModalProps } from '@components';

import { observer } from '@shared/libs/mobx';

import type { YouTubeVideoProps } from '@shared/UI';
import { Modal, YouTubeVideo } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface ModalPlayerProps extends BaseModalProps {
  url: string;
}

const _ModalPlayer = observer((props: ModalPlayerProps) => {
  const { url, ...restProps } = props;

  const handleReady: YouTubeVideoProps['onReady'] = (event) => {
    event.target.playVideo();
  };

  const opts: YouTubeVideoProps['opts'] = {
    playerVars: {
      rel: 0,
      modestbranding: 1,
    },
  };

  return (
    <Modal
      centered
      withHeader={false}
      size="lg"
      className={s.modalPlayer}
      classNameWrapper={s.modalPlayerWrapper}
      isNeedToUnmount
      {...restProps}
    >
      <YouTubeVideo url={url} onReady={handleReady} className={s.modalPlayerContent} opts={opts} />
    </Modal>
  );
});

export const ModalPlayer = mergeComponentsWithName('ModalPlayer', _ModalPlayer);
