import type { ReactNode, RefObject } from 'react';
import { useEffect, useRef } from 'react';

import { useModalState } from '@hooks';

import { useMediaQuery } from '@shared/hooks';
import { createSafeContext } from '@shared/utils';

interface ChatLayoutContext {
  onToggleSidebar: () => void;

  onCloseSidebar: () => void;

  onOpenSidebar: () => void;

  isOpenSidebar: boolean;

  isMobile: boolean;

  chatContainer: RefObject<HTMLElement>;
}

interface ChatLayoutProviderProps {
  children: ReactNode;
}

const [Provider, useChatLayout] = createSafeContext<ChatLayoutContext>('ChatLayout');

const ChatLayoutProvider = (props: ChatLayoutProviderProps) => {
  const { children } = props;

  const isMobile = useMediaQuery('(max-width:1240px)');

  const chatContainer = useRef<HTMLElement>(null);

  const { isOpen, onToggle, onClose, onOpen } = useModalState();

  useEffect(() => {
    if (!isMobile && isOpen) {
      onClose();
    }
  }, [isMobile]);

  const value: ChatLayoutContext = {
    isMobile: Boolean(isMobile),
    isOpenSidebar: isOpen,
    onToggleSidebar: onToggle,
    onCloseSidebar: onClose,
    onOpenSidebar: onOpen,
    chatContainer,
  };

  return <Provider value={value}>{children}</Provider>;
};

export { ChatLayoutProvider, useChatLayout };
