import { observer } from 'mobx-react-lite';

import { Content } from './content';
import { Header } from './header';
import { Sidebar } from './sidebar';

import s from './styles.module.scss';

export const MainLayout = observer(function MainLayout() {
  return (
    <div className={s.mainLayout}>
      <div className={s.containerHeader}>
        <Header />
      </div>
      <div className={s.containerContent}>
        <Sidebar />
        <Content />
      </div>
    </div>
  );
});
