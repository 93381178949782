import type { HTMLAttributes, ReactElement, ReactNode } from 'react';
import { useId } from 'react';

import { useClassName } from '../_hooks';
import type { Placement, ShiftOptions } from '@floating-ui/react';
import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cl from 'classnames';
import type { AnimationProps, TargetAndTransition, Variant, Variants } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';

import './styles.scss';

export type DropdownAnimationVariants = {
  visible?: Variant;
  hidden?: Variant;
  exit?: TargetAndTransition;
};

export type DropdownProps = {
  isOpen: boolean;
  onOpenChange?: (value: boolean) => void;
  children: ReactElement;
  content?: ReactNode;
  contentClassName?: string;
  animation?: AnimationProps;
  animationVariants?: DropdownAnimationVariants;
  placement?: Placement;
  offsetValue?: number;
  popupShift?: ShiftOptions;
} & Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'content'>;

export const Dropdown = observer(function Dropdown(props: DropdownProps) {
  const {
    isOpen,
    onOpenChange,
    content,
    children,
    className,
    contentClassName,
    animation,
    animationVariants,
    placement = 'bottom',
    offsetValue = 10,
    popupShift,
    ...restProps
  } = props;

  const { rootClassName, appendClass } = useClassName('dropdown');

  const { refs, context, floatingStyles } = useFloating({
    placement,
    open: isOpen,
    onOpenChange,
    middleware: [offset(offsetValue), flip(), shift(popupShift || { padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const headingId = useId();

  const rootClasses = cl(
    rootClassName,
    {
      [appendClass('--open')]: isOpen,
      [appendClass('--hidden')]: !isOpen,
    },
    className,
  );

  const variants: Variants = {
    visible: {
      scale: 1,
      opacity: 1,
    },
    hidden: {
      scale: 0,
      opacity: 0,
    },
    ...animationVariants,
  };

  const exit = {
    scale: 0,
    opacity: 0,
    ...animationVariants?.exit,
  };

  const transition = { duration: 0.3 };

  const animate = isOpen ? 'visible' : ' hidden';

  const initial = 'hidden';

  return (
    <>
      <div className={rootClasses} {...restProps}>
        <div
          role="button"
          className={appendClass('-trigger')}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          {children}
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <FloatingPortal>
            <motion.div
              ref={refs.setFloating}
              className={appendClass('-content-wrapper')}
              aria-labelledby={headingId}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <motion.div
                initial={initial}
                animate={animate}
                variants={variants}
                transition={transition}
                exit={exit}
                className={cl(appendClass('-content'), contentClassName)}
                {...animation}
              >
                {content}
              </motion.div>
            </motion.div>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  );
});
