import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { AppleButton } from '@features/auth/_components/form-box/bottom/apple-button';
import { GoogleButton } from '@features/auth/_components/form-box/bottom/google-button';

import { Divider } from '@shared/UI';

export const Bottom = observer(function Bottom() {
  const { t } = useTranslation();

  return (
    <div>
      <Divider>{t('shared-content.or')}</Divider>
      <AppleButton />
      <GoogleButton />
    </div>
  );
});
