import type { NavigateFunction, NavigateOptions } from '../../_types';
import { useAppLocation } from '../useAppLocation';
import type { To } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useRootStore } from '@core/store';

import { replaceParamsInPath } from '@shared/utils';

export const useAppNavigate = () => {
  const { pathname } = useAppLocation();
  const navigate = useNavigate();
  const { appLoaderStore } = useRootStore();

  const onNavigate: NavigateFunction = (to: To | number, options?: NavigateOptions) => {
    let _to = to;

    if (options && 'params' in options && typeof _to === 'string') {
      _to = replaceParamsInPath(_to, options.params);
    }

    if (options && 'search' in options && typeof _to === 'string') {
      Object.entries(options.search || {}).forEach(([key, value], idx) => {
        const queryPrefix = idx === 0 ? '?' : '&';
        _to += `${queryPrefix}${key}=${value}`;
      });
    }

    if (options?.withLoading) {
      appLoaderStore.startLoading();
      setTimeout(appLoaderStore.stopLoading, 200);
    }

    if (typeof _to === 'number') {
      navigate(_to);
      return;
    }

    const from = options?.state?.from || pathname;

    const opts = {
      ...options,
      state: {
        ...options?.state,
        from,
      },
    };

    navigate(_to, opts);
  };

  return onNavigate;
};
