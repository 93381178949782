import { componentName } from '@shared/utils/component-name';

export const mergeComponents = <T extends Function, O extends Record<string, any>>(
  component: T,
  rest: O,
): T & O => {
  return Object.assign(component, rest);
};

export const mergeComponentsWithName = <
  N extends string,
  T extends Function,
  O extends Record<string, any>,
>(
  name: N,
  component: T,
  rest?: O,
): T & O => {
  const _rest = rest || ({} as O);

  return mergeComponents(component, {
    ...componentName<N>(name),
    ..._rest,
  });
};
