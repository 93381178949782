import type { CommunitySidebarController } from '../community-sidebar.controller';
import { ChannelItem } from './channel-item';
import { useChannelsAccordionController } from './channels-accordion.controller';
import { DndContext, DragOverlay, MeasuringStrategy, closestCenter } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { CommunityChannelItem, useCommunitySidebarSensors } from '@units';
import { useTranslation } from 'react-i18next';

import { CommunityChannelStatus } from '@core/constants';

import { observer } from '@shared/libs/mobx';

import { Accordion, Divider, Each, Fallback, Group, SvgIcon, TypographyNew } from '@shared/UI';
import { ButtonNew } from '@shared/UI/button-new';

import { mergeComponentsWithName, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

export interface ChannelsAccordionProps {
  control: CommunitySidebarController;
}

const _ChannelsAccordion = observer((props: ChannelsAccordionProps) => {
  const { control } = props;

  const { t } = useTranslation();

  const {
    currentChannelID,
    hasAccessToBlockedChannel,
    hasAccessToCreatingChannel,
    hasAccessToUpdate,
    onDragEnd,
    onDragStart,
    onChangeChannel,
  } = useChannelsAccordionController({ control });

  const dndSensors = useCommunitySidebarSensors();

  return (
    <Accordion value={currentChannelID} onChange={onChangeChannel}>
      <Fallback when={control.pinnedChannels.length === 0} fallback={null}>
        <TypographyNew
          className={s.channelsType}
          variant="body-3"
          as="h6"
          colorSchema="neutral-500"
        >
          {t('community.channels.pinned-channels-title')}
        </TypographyNew>
        <Each data={control.pinnedChannels}>
          {(channel) =>
            channel.isAvailableForUser ? <ChannelItem channel={channel} key={channel.id} /> : null
          }
        </Each>
        <Divider verticalSpace={[16, 24]} />
      </Fallback>
      <Group w="100%" justify="space-between">
        <TypographyNew
          className={s.channelsType}
          variant="body-3"
          as="h6"
          colorSchema="neutral-500"
        >
          {t('community.channels.channels-title')}
        </TypographyNew>

        {hasAccessToCreatingChannel && (
          <ButtonNew
            size="icon-xs"
            variant="ghost-white"
            onClick={control.handleClickAddChannelBtn}
          >
            <SvgIcon type="plus" fontSize={24} />
          </ButtonNew>
        )}
      </Group>
      <DndContext
        measuring={measuring}
        sensors={dndSensors}
        collisionDetection={closestCenter}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <SortableContext items={control.baseChannels} strategy={rectSortingStrategy}>
          <Each data={control.baseChannels}>
            {(channel) => {
              if (!channel.isAvailableForUser) return null;
              if (!Boolean(channel.availableChatsCount) && !hasAccessToUpdate) return null;
              if (!hasAccessToBlockedChannel && channel.status === CommunityChannelStatus.Closed)
                return null;
              return (
                <ChannelItem channel={channel} key={channel.id} isDraggable={hasAccessToUpdate} />
              );
            }}
          </Each>
        </SortableContext>
        <DragOverlay>
          {control.draggableChannel && (
            <CommunityChannelItem
              imageSrc={withMediaUrl(control.draggableChannel.imageUrl)}
              label={control.draggableChannel.title}
            />
          )}
        </DragOverlay>
      </DndContext>
    </Accordion>
  );
});

export const ChannelsAccordion = mergeComponentsWithName('ChannelsAccordion', _ChannelsAccordion);
