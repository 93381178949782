import type { BaseModalProps } from '../../_types';
import { modalStore } from '../../modal.store';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import successImg from '@assets/images/subscription/success.png';

import { Button, Image, Modal, Stack, TypographyNew } from '@shared/UI';

import { createDateWithMonthFormat } from '@shared/utils';

import s from './styles.module.scss';

interface CancelSubscriptionSuccessModalProps extends BaseModalProps {
  endDate: string;
}

export const CancelSubscriptionSuccessModal = observer(function CancelSubscriptionSuccess(
  props: CancelSubscriptionSuccessModalProps,
) {
  const { endDate, ...restProps } = props;

  const { t } = useTranslation();

  const handleCloseModalClick = () => {
    modalStore.closeAll();
  };

  return (
    <Modal
      centered
      size="lg"
      id="cancel-subscription-success"
      title={
        <TypographyNew variant="title-3" weight="bold">
          {t('my-account.my-subscription.cancel')}
        </TypographyNew>
      }
      {...restProps}
    >
      <Stack gap="xxl" className={s.cancelSubscriptionSuccessModal}>
        <Image src={successImg} width={160} height={160} />
        <TypographyNew variant="title-4" weight="bold">
          {t('my-account.my-subscription.subscription-cancelled-title')}
        </TypographyNew>
        <TypographyNew
          variant="body-3"
          colorSchema="neutral-200"
          className={s.cancelSubscriptionSuccessModalDescription}
        >
          {t('my-account.my-subscription.subscription-cancelled-description', {
            date: createDateWithMonthFormat(endDate),
          })}
        </TypographyNew>
        <Button
          size="sm"
          className={s.cancelSubscriptionSuccessModalButton}
          onClick={handleCloseModalClick}
        >
          {t('my-account.my-subscription.back-to-profile')}
        </Button>
      </Stack>
    </Modal>
  );
});
