import { type CSSProperties, Children, useMemo } from 'react';

import { TypographyNew } from '../common';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('indicator');

const indicator = cva(rootClass, {
  variants: {
    variant: {
      primary: appendClass('--variant-primary'),
      secondary: appendClass('--variant-secondary'),
      green: appendClass('--variant-green'),
    },
    size: {
      sm: appendClass('--size-sm'),
      md: appendClass('--size-md'),
    },
    placement: {
      top: appendClass('--placement-top'),
      'top-left': appendClass('--placement-top-left'),
      'top-right': appendClass('--placement-top-right'),
      bottom: appendClass('--placement-bottom'),
      'bottom-left': appendClass('--placement-bottom-left'),
      'bottom-right': appendClass('--placement-bottom-right'),
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'sm',
    placement: 'top-right',
  },
});

export interface IndicatorProps extends ElementProps<'div'>, VariantProps<typeof indicator> {
  color?: CSSProperties['color'];
  bgColor?: CSSProperties['background'];
  count?: number;
  maxCount?: number;
  isActive?: boolean;
}

const _Indicator = observer((props: IndicatorProps) => {
  const {
    color,
    children,
    style,
    bgColor,
    count,
    maxCount,
    className,
    size,
    variant,
    placement,
    isActive,
    ...restProps
  } = props;

  const childrenLength = Children.count(children);

  const rootClasses = indicator({
    size,
    variant,
    placement,
    className: cl({ [appendClass('--independet')]: childrenLength === 0 }, className),
  });

  const indicatorStyle: CSSProperties = useMemo(
    () => ({
      color,
      background: bgColor,
    }),
    [color, bgColor],
  );

  const _count = useMemo(() => {
    if (count && maxCount) {
      return count > maxCount ? `${maxCount}+` : count.toString();
    }
    if (count && !maxCount) {
      return count.toString();
    }

    return false;
  }, [count, maxCount]);

  const _isActive = useMemo(() => {
    if (typeof isActive === 'boolean') {
      return isActive;
    }
    if (typeof count === 'number' && count === 0) {
      return false;
    }

    return true;
  }, [count, isActive]);

  return (
    <div data-count={_count} data-active={_isActive} className={rootClasses} {...restProps}>
      <TypographyNew
        as="div"
        variant="body-4"
        weight="extra-bold"
        style={indicatorStyle}
        className={appendClass('-item')}
      >
        {_count}
      </TypographyNew>
      <div>{children}</div>
    </div>
  );
});

export const Indicator = mergeComponentsWithName('Indicator', _Indicator);
