import { lazy } from 'react';

import type { RouteObject } from 'react-router-dom';

const EntryPage = lazy(() => import('./EntryPage'));

export const uiTestingRouter: RouteObject = {
  path: 'ui-testing',
  element: <EntryPage />,
};
