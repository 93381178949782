import { PATH_PARAMS_PATTERN } from '@constants';

export function replaceParamsInPath(path: string, params?: Record<string, string | number>) {
  let _path = path;

  const match = PATH_PARAMS_PATTERN.exec(path);

  if (params && Object.keys(params).length > 0 && match) {
    _path = _path.replace(PATH_PARAMS_PATTERN, (_m, p) => String(params[p]));
  }

  return _path;
}
