import { z } from 'zod';

export const signUpByPhoneSchema = z.object({
  phone: z
    .string({
      required_error: 'errors.required-field',
    })
    .min(1, { message: 'errors.required-field' }),
  code: z.string(),
  isAgreeRules: z.boolean().refine((val) => val, { message: '' }),
  emailSubscription: z.boolean().refine((val) => val, { message: '' }),
  otp: z.string().optional(),
});

export type FormSignUpByPhoneSchema = z.infer<typeof signUpByPhoneSchema>;
