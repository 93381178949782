import { observer } from 'mobx-react-lite';

import { LeftSection } from './left-section';
import { RightSection } from './right-section';

import s from './styles.module.scss';

export const Header = observer(function Header() {
  return (
    <header className={s.header}>
      <div className={s.headerContainer}>
        <LeftSection />
        <RightSection />
      </div>
    </header>
  );
});
