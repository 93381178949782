import { Logo } from '@components';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import { Button, SvgIcon } from '@shared/UI';

import s from './styles.module.scss';

export const MobileSidebarLogo = observer(function MobileSidebarLogo() {
  const { appLayoutStore } = useRootStore();

  return (
    <div className={s.mobileSidebarLogo}>
      <Logo />

      <Button
        onClick={appLayoutStore.handleChangeOpen}
        size="icon"
        shape="square"
        variant="text"
        disabledAnimation
      >
        <SvgIcon type="cross-2" />
      </Button>
    </div>
  );
});
