import { observer } from 'mobx-react-lite';

import { TypographyNew } from '@shared/UI';

import { withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

interface EventCardCompanyProps {
  title: string;
  logo: string;
}

export const EventCardCompany = observer(function EventCardCompany(props: EventCardCompanyProps) {
  const { title, logo } = props;

  return (
    <div className={s.eventCardCompany}>
      <img className={s.eventCardCompanyImage} alt="company logo" src={withMediaUrl(logo)} />
      <TypographyNew variant="body-1" weight="bold" className={s.eventCardCompanyValue}>
        {title}
      </TypographyNew>
    </div>
  );
});
