import { useState } from 'react';

import {
  EventCardBookmark,
  EventCardCompany,
  EventCardLabel,
  EventCardRedirectionButton,
  EventCardTime,
} from './_components';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import type { EventsItemEntity } from '@core/repositories/_entities/events-item.entity';
import { useRootStore } from '@core/store';

import type { BoxProps } from '@shared/UI';
import { Box, TypographyNew } from '@shared/UI';

import { getFormatedDate, truncateText, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

interface EventCardProps extends BoxProps {
  event: EventsItemEntity;
  isDashboard?: boolean;
}

export const EventCard = observer(function EventCard(props: EventCardProps) {
  const { event, isDashboard = false } = props;

  const {
    access,
    buttonLink,
    buttonText,
    id,
    image,
    isSubscribed,
    isTimerEnabled,
    fullDescription,
    startDate,
    title,
    businessAccount,
    ...restProps
  } = event;

  const { eventsStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const monthName = getFormatedDate(startDate, 'MMM');
  const day = getFormatedDate(startDate, 'D');
  const time = getFormatedDate(startDate, 'HH:mm');

  const [expanded, setExpanded] = useState(isDashboard);
  const [description, setDescription] = useState(truncateText(fullDescription, 200));

  const handleCardClick = async () => {
    if (!expanded) {
      setExpanded(true);
      setDescription(fullDescription);
    } else {
      const truncatedDescription = truncateText(fullDescription, 200);
      setExpanded(false);
      setTimeout(() => setDescription(truncatedDescription), 300);
    }
  };

  const handleSubscriptionStatusChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (isSubscribed) {
      eventsStore.unSubscribeToEvent(id);
    } else {
      eventsStore.subscribeToEvent(id);

      sendEvent({
        event: AnalyticsEvents.EventFollowTap,
        payload: { event_id: id, event_name: title },
      });
    }
  };

  return (
    <Box
      onClick={() => !isDashboard && handleCardClick()}
      className={cl(
        s.eventCard,
        expanded && s.eventCardExpanded,
        isDashboard && s.dashboardEventCard,
      )}
      {...restProps}
    >
      <div className={s.eventCardSectionOne}>
        <div
          className={s.eventCardDecorationBg}
          style={{
            backgroundImage: `url(${withMediaUrl(image)})`,
          }}
        />
        <div className={s.eventCardDate}>
          <TypographyNew
            as="p"
            variant="title-1"
            weight="bold"
            colorSchema="neutral-100"
            className={s.eventCardDateDay}
          >
            {day}
          </TypographyNew>
          <TypographyNew as="p" variant="title-3" weight="bold" colorSchema="neutral-100">
            {monthName}
          </TypographyNew>
        </div>
      </div>
      <div className={s.eventCardSectionTwo}>
        <div className={s.eventCardSectionTwoTop}>
          <div className={s.eventCardItem}>
            <EventCardTime value={time} />
          </div>
          <div className={s.eventCardItem}>
            <EventCardLabel value={access} />
          </div>
          {!isDashboard && (
            <div className={s.eventCardItem}>
              <EventCardBookmark
                onChangeStatus={handleSubscriptionStatusChange}
                isSubscribed={isSubscribed}
              />
            </div>
          )}
          <div className={s.eventCardItem}>
            <EventCardCompany title={businessAccount?.title} logo={businessAccount?.logo} />
          </div>
        </div>
        <div className={s.eventCardSectionTwoBottom}>
          <TypographyNew
            as="div"
            variant="title-4"
            weight="extra-bold"
            className={s.eventCardTitle}
          >
            {title}
          </TypographyNew>
          <TypographyNew
            as="div"
            variant="body-3"
            colorSchema="neutral-200"
            className={cl(s.eventCardDescription, expanded && s.eventCardDescriptionExpanded)}
          >
            {description}
          </TypographyNew>
        </div>
        <div
          className={cl(
            s.eventCardSectionTwoHidden,
            expanded && s.eventCardSectionTwoHiddenExpanded,
          )}
        >
          <EventCardRedirectionButton
            startDate={startDate}
            buttonText={buttonText}
            buttonLink={buttonLink}
            isTimerEnabled={isTimerEnabled}
            id={id}
            title={title}
          />
        </div>
      </div>
    </Box>
  );
});
