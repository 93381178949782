import Course from './course';
import MyCourses from './my-courses';
import MyHomework from './my-homework';
import Streams from './streams';
import { routerCollection } from '@constants';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { lazyDelay } from '@shared/utils';

const EntryPage = lazyDelay(() => import('./EntryPage'));

export const myWorkspaceRouter: RouteObject = {
  path: routerCollection.myWorkspace.root,
  element: <EntryPage />,
  children: [
    {
      index: true,
      element: <Navigate to={routerCollection.myWorkspace.courses} />,
    },
    {
      path: routerCollection.myWorkspace.courses,
      element: <MyCourses />,
    },
    {
      path: routerCollection.myWorkspace.homeWork,
      element: <MyHomework />,
    },
    { path: routerCollection.myWorkspace.course, element: <Course /> },
    { path: routerCollection.myWorkspace.streams, element: <Streams /> },
  ],
};
