import { useMemo } from 'react';

import { useVideoMessageController } from './video-message.contoller';
import { EmojiReactionList, MessageTileFooterInfo, MessageVideoTile } from '@units';
import type Player from 'video.js/dist/types/player';

import type { MessageStore } from '@core/store';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { Box, Group, Spinner, SvgIcon } from '@shared/UI';

import { hourAndMinuteDate, mergeComponentsWithName, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

export interface CircleVideoMessageProps {
  message: MessageStore;
  onClickVideo: (messageId: string) => void;
  setVideoRef: (messageId: string, video: Player | null) => void;
  handleEmojiClick: (details: string, id: string) => void;
  showViewedCount?: boolean;
}

const _CircleVideoMessage = observer((props: CircleVideoMessageProps) => {
  const { message, setVideoRef, onClickVideo, handleEmojiClick, showViewedCount = false } = props;

  const { control, onClickVideo: _onClickVideo } = useVideoMessageController({
    message,
    onClickVideo,
    setVideoRef,
  });

  const rootClasses = cl(s.circleVideoMessage);

  const hasVideo = useMemo(() => typeof message.attachments[0] === 'string', [message.attachments]);

  const EmojiComponent = () => {
    return (
      <Group gap="xs" align="flex-end" w="100%">
        <EmojiReactionList
          reactions={message.reactions.reactions}
          messageId={message.id}
          handleEmojiClick={handleEmojiClick}
        />
        <MessageTileFooterInfo
          message={message}
          showViewedCount={showViewedCount}
          hasVideo={hasVideo}
        />
      </Group>
    );
  };

  const setupCanvas = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    const thumbnailPath = message.attachments[0]
      ? withMediaUrl(message.attachments[0] + '?videoThumbnail=1')
      : null;

    const img = new Image();

    if (thumbnailPath) {
      img.src = thumbnailPath;
      img.style.objectFit = 'cover';
    }

    img.onload = () => {
      requestAnimationFrame(() => {
        const scaleX = canvas.width / img.width;
        const scaleY = canvas.height / img.height;
        const scale = Math.max(scaleX, scaleY);

        const newWidth = img.width * scale;
        const newHeight = img.height * scale;

        const offsetX = (canvas.width - newWidth) / 2;
        const offsetY = (canvas.height - newHeight) / 2;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.save();
        ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);
        ctx.restore();

        ctx.fillStyle = `rgba(0, 0, 0, 0.5)`;
        ctx.fillRect(offsetX, offsetY, newWidth, newHeight);
      });
    };
  };

  return (
    <div className={rootClasses}>
      <MessageVideoTile
        footerSlot={EmojiComponent}
        avatar={message.ownerImage}
        isViewed={message.isViewed}
        viewedCount={message.viewCount}
        hideAvatar={message.isMessageFromViewer}
        date={hourAndMinuteDate(message.createDate)}
        owner={message.isMessageFromViewer ? 'viewer' : 'participant'}
        ownerName={message.isMessageFromViewer ? '' : message.authorName}
      >
        <>
          {control.isLoading && (
            <Box className={s.videoLoader}>
              <Spinner isFullSize />
            </Box>
          )}
          {hasVideo && (
            <Box as="span" className={s.circleVideoMessageControl} onClick={_onClickVideo}>
              <SvgIcon type={control.isPlaying ? 'pause-outline' : 'play-outline'} />
            </Box>
          )}
          {hasVideo ? (
            <Box
              role="button"
              tabIndex={-1}
              w="100%"
              h="100%"
              overflow="hidden"
              rounded="50%"
              onClick={_onClickVideo}
              onTouchEnd={_onClickVideo}
            >
              {!control.isPlaying && !control.player?.paused() && (
                <canvas ref={setupCanvas} width={230} height={230} />
              )}
              <div data-vjs-player className={s.circleVideoMessageElementContainer}>
                <div className={s.circleVideoMessageElement} ref={control.setVideoElementRef}></div>
              </div>
            </Box>
          ) : (
            <Box
              overflow="hidden"
              rounded="50%"
              w={230}
              h={230}
              className={s.circleVideoMessageTemplate}
            >
              <Spinner />
            </Box>
          )}
        </>
      </MessageVideoTile>
    </div>
  );
});

export const CircleVideoMessage = mergeComponentsWithName(
  'CircleVideoMessage',
  _CircleVideoMessage,
);
