import { useRef, useState } from 'react';

import { type EmojiDetails, EmojiPicker } from '../emoji-picker';
import type { BaseModalProps } from '@components';
import type { Editor } from '@tiptap/react';
import { EditorBox, MediaContentTile } from '@units';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import type { UploadedFile } from '@shared/UI';
import { Button, Group, Modal, RichTextEditor, Spinner, Stack, Typography } from '@shared/UI';

import { useId } from '@shared/hooks';
import { FileKind, kindOfFile, mergeComponentsWithName, redableFileSize } from '@shared/utils';

import s from './styles.module.scss';

type MessageForm = {
  message: string;
};

export type UploadedFilesModalDetailsSubmit = MessageForm & {
  files: UploadedFile[];
};

export interface UploadedFilesModalProps extends Omit<BaseModalProps, 'onSubmit'> {
  files: UploadedFile[];
  defaultValue: string;
  onSubmit?: (details: UploadedFilesModalDetailsSubmit) => Promise<any>;
}

const _UploadedFilesModal = observer((props: UploadedFilesModalProps) => {
  const { files, defaultValue, onSubmit: onSubmitProps, ...restProps } = props;

  const editorRef = useRef<Editor>();

  const { control, handleSubmit } = useForm<MessageForm>({
    defaultValues: {
      message: defaultValue,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const [_files] = useState<UploadedFile[]>(files);

  const handleSelectEmoji = (emoji: EmojiDetails) => {
    editorRef?.current?.chain().focus().insertContent(emoji.native).run();
  };

  const onSubmit: SubmitHandler<MessageForm> = async ({ message }) => {
    setIsLoading(true);
    try {
      await onSubmitProps?.({ message, files: _files });
    } finally {
      setIsLoading(false);
    }
  };

  const renderFiles = _files.map((file) => {
    const kind = kindOfFile(file.name);
    const size = redableFileSize(file.size);
    const extension = file.name.substring(file.name.lastIndexOf('.') + 1);

    switch (kind) {
      case FileKind.IMAGE:
        return (
          <MediaContentTile
            key={file.id}
            mediaUrl={file.url}
            label={file.name}
            mimeType={extension}
            size={size}
            progressLoad={file.progress}
          />
        );
      case FileKind.DOCUMENT:
        return (
          <MediaContentTile
            key={file.id}
            document={file.originalFile}
            label={file.name}
            mimeType={extension}
            size={size}
          />
        );
      default:
        return null;
    }
  });

  const title = (
    <Typography as="h4" variant="body-bold-2">
      Send {files.length} {files.length === 1 ? 'file' : 'files'}
    </Typography>
  );

  const formId = useId();

  const rootClasses = cl(s.uploadedFilesModal, {
    [s.uploadedFilesModalLoading]: isLoading,
  });

  return (
    <Modal
      title={title}
      centered
      size="lg"
      className={rootClasses}
      classNameWrapper={s.uploadedFilesModalWrapper}
      {...restProps}
    >
      {isLoading && <Spinner className={s.uploadedFilesModalSpinner} />}
      <Stack align="flex-start" className={s.uploadedFilesModalCotnent}>
        <Stack align="flex-start">{renderFiles}</Stack>

        <Group w="100%" wrap="nowrap">
          <EditorBox className={s.uploadedFilesModalFormContainer}>
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
              <Controller
                render={({ field }) => (
                  <RichTextEditor
                    editorRef={editorRef}
                    onChange={field.onChange}
                    content={field.value}
                    slotBefore={<EmojiPicker onSelect={handleSelectEmoji} />}
                  />
                )}
                name="message"
                control={control}
              />
            </form>
          </EditorBox>
          <Button form={formId} type="submit" className={s.uploadedFilesModalBtn}>
            Send
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
});

export const UploadedFilesModal = mergeComponentsWithName(
  'UploadedFilesModal',
  _UploadedFilesModal,
);
