import type { UIEventHandler } from 'react';
import { useEffect, useRef, useState, useTransition } from 'react';

import { SCROLL_OPTIONS_END, SCROLL_OPTIONS_START } from '../constants';
import type { IMessageListController } from '../interfaces';
import type { Virtualizer } from '@tanstack/react-virtual';
import { when } from 'mobx';

import { MessageViewType } from '@core/constants';
import type { MessageStore } from '@core/store';

enum ScrollType {
  ToUnread = 'to-unread',
  ToLast = 'to-last',
}

const MAX_BOTTOM_GAP = 200;

export const useMessageListScroll = (
  virtual: Virtualizer<HTMLElement, Element>,
  control: IMessageListController,
  readAllAction: () => Promise<any> | void,
) => {
  const { 0: isNearBottom, 1: setIsNearBottom } = useState(true);

  const renderTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { 0: isRendered, 1: setIsRendered } = useState(false);

  const { 1: startTransition } = useTransition();

  const { 0: isToBottomScrolling, 1: setIsToBottomScrolling } = useState(false);

  const downRef = useRef<HTMLDivElement>(null);

  const toBottomAsync = (timeout = 0) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        virtual.scrollToOffset(virtual.getTotalSize() + 40, SCROLL_OPTIONS_END);
        resolve(null);
      }, timeout);
    });
  };

  const onScrollToBottom = async (needToRead = true) => {
    if (control.hasUnread && needToRead) {
      setIsToBottomScrolling(true);
    }

    if (needToRead) {
      await readAllAction();
    }

    await toBottomAsync(300);
    await toBottomAsync(100);

    setIsToBottomScrolling(false);
  };

  const calcAmountFromBottom = () => {
    const totalSize = virtual.getTotalSize();
    const currentSize =
      virtual.scrollElement?.scrollTop! + virtual.scrollElement?.clientHeight! - 52;

    return Math.max(0, totalSize - currentSize);
  };

  const onScrollArea: UIEventHandler<HTMLElement> = () => {
    const amount = calcAmountFromBottom();

    if (amount > MAX_BOTTOM_GAP && isNearBottom) {
      setIsNearBottom(false);
    }

    if (amount < MAX_BOTTOM_GAP && !isNearBottom) {
      setIsNearBottom(true);
    }
  };

  const scrollToLastReceivedMessage = (message: MessageStore) => {
    if (virtual.isScrolling) return;

    const amount = calcAmountFromBottom();

    if ((amount <= MAX_BOTTOM_GAP && !message.isMessageFromViewer) || message.isMessageFromViewer) {
      let needToRead = false;

      if (message.isMessageFromViewer && control.hasUnread) {
        needToRead = true;
      }

      onScrollToBottom(needToRead);
    }
  };

  const receiveBaseMessage = (message: MessageStore) => {
    if (message.type === MessageViewType.Video) return;

    scrollToLastReceivedMessage(message);
  };

  const receiveVideoTemplateMessage = (message: MessageStore) => {
    if (message.type !== MessageViewType.Video) return;
    scrollToLastReceivedMessage(message);
  };

  const scrollAfterMounting = async () => {
    if (!control.isInitializing && !isRendered) {
      control.startInit();
    }

    const unreadIdx = control.firstUnreadRowIdx;
    const type = await new Promise<ScrollType>((resolve) => {
      const unreadIdx = control.firstUnreadRowIdx;
      if (unreadIdx !== -1) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            virtual.scrollToIndex(unreadIdx, SCROLL_OPTIONS_END);
          });
          return resolve(ScrollType.ToUnread);
        }, 200);
      } else {
        setTimeout(() => {
          requestAnimationFrame(() => {
            virtual.scrollToIndex(control.rowsCount - 1, SCROLL_OPTIONS_START);
            return resolve(ScrollType.ToLast);
          });
        });
      }
    });

    if (type === ScrollType.ToLast) {
      await new Promise((resolve) => {
        setTimeout(() => {
          requestAnimationFrame(() => {
            downRef.current?.scrollIntoView({ block: 'start' });
          });
          resolve(null);
        }, 400);
      });
    }

    control.completeInit();

    if (type === ScrollType.ToUnread) {
      await new Promise((resolve) => {
        setTimeout(() => {
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              virtual.scrollToIndex(unreadIdx, SCROLL_OPTIONS_END);
            });
          });
          resolve(null);
        }, 20);
      });
    }
  };

  const setupRowNode = (node: HTMLElement | null) => {
    if (!node) return;

    virtual.measureElement(node);

    if (!isRendered) {
      if (renderTimeout.current) {
        clearTimeout(renderTimeout.current);
        renderTimeout.current = null;
      }
      renderTimeout.current = setTimeout(() => {
        startTransition(() => {
          setIsRendered(true);
        });
      });
    }
  };

  useEffect(() => {
    when(
      () => isRendered,
      () => {
        scrollAfterMounting();
      },
    );
  }, [isRendered]);

  return {
    isRendered,
    isNearBottom,
    isToBottomScrolling,
    downRef,
    onScrollToBottom,
    onScrollArea,
    scrollToLastReceivedMessage,
    receiveBaseMessage,
    receiveVideoTemplateMessage,
    scrollAfterMounting,
    setupRowNode,
  };
};
