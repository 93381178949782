export const ProductTypes = {
  Course: 'course',
  Community: 'community',
};

export const PaymentVariants = {
  SavedCard: 'SAVED_CARD',
  NewCard: 'NEW_CARD',
};

export const PaymentResults = {
  Success: 'success',
  Error: 'error',
};
