import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import { MyCourses as MyCoursesFeature } from '@features/content';

const MyCourses = observer(function MyCourses() {
  return (
    <>
      <Outlet />
      <MyCoursesFeature />
    </>
  );
});

export default MyCourses;
