import { makeAutoObservable, runInAction } from 'mobx';

import { SocketEventsCollection } from '@core/constants';
import type { CommunityBlockItemEntity } from '@core/repositories';
import { appSocket } from '@core/services/socket';

interface ICommunityStoreOptions extends CommunityBlockItemEntity {}

export class CommunityStore {
  id: number;

  logo: string;

  communityId: number;

  communityTitle: string;

  categoryTitle: string;

  averageRating: number | null;

  activeCommunityUsers: number;

  lang: string;

  langFlagImage: string;

  isActive = false;

  constructor({
    communityId,
    communityTitle,
    categoryTitle,
    activeCommunityUsers,
    id,
    lang,
    langFlagImage,
    logo,
    averageRating,
  }: ICommunityStoreOptions) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.communityId = communityId;
    this.communityTitle = communityTitle;
    this.categoryTitle = categoryTitle;
    this.activeCommunityUsers = activeCommunityUsers;
    this.id = id;
    this.lang = lang;
    this.langFlagImage = langFlagImage;
    this.logo = logo;
    this.averageRating = averageRating;
  }

  changeIsActive = (value: boolean) => {
    if (this.isActive === value) return;

    runInAction(() => {
      this.isActive = value;
    });
  };

  connectToCommunity = () => {
    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.Connect, {
      communityId: this.id,
    });
  };
}
