export const convertSecondsToMinutesAndSecondsString = (durationInSeconds: number) => {
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(durationInSeconds % 60)
    .toString()
    .padStart(2, '0');

  if (typeof durationInSeconds === 'string') {
    return durationInSeconds;
  }

  return `${minutes}:${seconds}`;
};

export const formatTimeCodeIndexToStringWithZero = (index: number) => {
  if (index >= 10) {
    return index;
  }

  return index.toString().padStart(2, '0');
};
