import { useEffect } from 'react';

import { MobileSidebarLogo } from './mobile-sidebar-logo';
import { NavList } from './nav-list';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@core/store';

import s from './styles.module.scss';

export const Sidebar = observer(function Sidebar() {
  const { appLayoutStore } = useRootStore();

  useEffect(() => {
    appLayoutStore.addSizeListener();

    return () => {
      appLayoutStore.removeSizeListener();
    };
  }, []);

  const rootClasses = cl(s.sidebar, {
    [s.sidebarMobile]: appLayoutStore.isMobileSidebar,
    [s.sidebarMobileOpen]: appLayoutStore.isSidebarOpen,
  });

  return (
    <>
      <aside className={rootClasses}>
        {appLayoutStore.isMobileSidebar && <MobileSidebarLogo />}
        <nav className={s.navigation}>
          <NavList />
        </nav>
      </aside>
      {appLayoutStore.isMobileSidebar && (
        <div onClick={appLayoutStore.handleChangeOpen} className={s.sidebarMobileOverlay} />
      )}
    </>
  );
});
