import { useCallback, useState } from 'react';

import { CommunitySidebarController } from '../community-sidebar.controller';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { useAppNavigate } from '@hooks';
import { TalkingArea, useAccessPermissions, useMessageParams } from '@units';

import { useRootStore } from '@core/store';

import { useComponentWillMount } from '@shared/hooks';

interface UseChannelsAccordionControllerProps {
  control: CommunitySidebarController;
}

export const useChannelsAccordionController = (props: UseChannelsAccordionControllerProps) => {
  const { control } = props;

  const { communityChannelsStore } = useRootStore();

  const { 0: currentChannelID, 1: setCurrentChannelID } = useState<undefined | string>(undefined);

  const navigate = useAppNavigate();

  const params = useMessageParams(TalkingArea.COMMUNITY);

  const { checkBusinessId, checkPermissionWithBusinessId, permissions } = useAccessPermissions();

  const hasAccessToBlockedChannel = params.businessAccountId
    ? checkBusinessId(parseInt(params.businessAccountId))
    : false;

  const hasAccessToCreatingChannel = params.businessAccountId
    ? checkPermissionWithBusinessId(
        permissions.communityCategory.create,
        parseInt(params.businessAccountId),
      )
    : false;

  const hasAccessToUpdate = params.businessAccountId
    ? checkPermissionWithBusinessId(
        permissions.communityCategory.update,
        parseInt(params.businessAccountId),
      )
    : false;

  const onChangeChannel = (channelId: string | undefined) => {
    if (channelId && channelId !== currentChannelID) {
      navigate(channelId, { replace: true });

      communityChannelsStore.connectToChannelEmitter(channelId);
    }

    setCurrentChannelID(channelId);
  };

  const onDragStart = useCallback((event: DragStartEvent) => {
    control.handleDragStart(event.active.id as string);
  }, []);

  const onDragEnd = useCallback((event: DragEndEvent) => {
    event.activatorEvent.stopPropagation();
    const { active, over } = event;

    if (!over?.id || active.id === over.id) return;

    control.handleDragSort(active.id as string, over.id as string);
    control.setDraggableChannelId(undefined);
  }, []);

  useComponentWillMount(() => {
    if (params.channelId) {
      setCurrentChannelID(params.channelId);
    }
  });

  return {
    currentChannelID,
    hasAccessToBlockedChannel,
    hasAccessToCreatingChannel,
    hasAccessToUpdate,
    onChangeChannel,
    onDragStart,
    onDragEnd,
  };
};
