import { Children, cloneElement, useEffect, useRef } from 'react';
import type { ReactElement } from 'react';

import { observer } from 'mobx-react-lite';
import { A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SwiperRef } from 'swiper/react';
import type { SwiperProps } from 'swiper/react/swiper-react';
import type { NavigationOptions, SwiperModule } from 'swiper/types';

import { NavigationArrows } from './navigation-arrows';

import s from './styles.module.scss';

export type SliderProps = {} & SwiperProps;

export const Slider = observer(function Slider(props: SliderProps) {
  const {
    children,
    navigation,
    modules,
    slidesPerView = 'auto',
    spaceBetween = 14,
    ...restProps
  } = props;

  const swiperRef = useRef<SwiperRef>(null);
  const prevBtnRef = useRef<HTMLButtonElement>(null);
  const nextBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const swiper = swiperRef.current?.swiper;

    if (swiper && prevBtnRef.current && nextBtnRef.current) {
      if (typeof swiper.params.navigation === 'object') {
        swiper.params.navigation.prevEl = prevBtnRef?.current;
        swiper.params.navigation.nextEl = nextBtnRef?.current;
      }

      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, []);

  const modulesConfig: SwiperModule[] = modules || [Navigation, A11y];

  const getNavigationConfig = (): NavigationOptions | boolean => {
    if (navigation && typeof navigation === 'boolean') {
      return navigation;
    }
    return {
      prevEl: prevBtnRef.current,
      nextEl: nextBtnRef.current,
      ...navigation,
    };
  };

  const navigationConfig = getNavigationConfig();

  const mapChildren = Children.map(children, (child) => {
    return (
      <SwiperSlide className={s.sliderSlide}>
        {cloneElement(child as ReactElement, (child as ReactElement).props)}
      </SwiperSlide>
    );
  });

  return (
    <div className={s.slider}>
      <Swiper
        ref={swiperRef}
        slidesPerView={slidesPerView}
        navigation={navigationConfig}
        modules={modulesConfig}
        spaceBetween={spaceBetween}
        init
        {...restProps}
      >
        <NavigationArrows prevBtnRef={prevBtnRef} nextBtnRef={nextBtnRef} />
        {mapChildren}
      </Swiper>
    </div>
  );
});
