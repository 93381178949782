import type { MouseEvent, TouchEvent } from 'react';
import { useCallback } from 'react';

import { routerCollection } from '@constants';
import { useAppNavigate } from '@hooks';
import { TalkingArea, useAccessPermissions, useChatLayout, useMessageParams } from '@units';
import { computed } from 'mobx';

import { CommunityChatStatus } from '@core/constants';
import type { ChatStore } from '@core/store';
import { useRootStore } from '@core/store';

export interface UseCommunityChatControllerProps {
  chat: ChatStore;
}

export const useCommunityChatController = (props: UseCommunityChatControllerProps) => {
  const { chat } = props;

  const { communityChatsStore } = useRootStore();

  const { chatId, businessAccountId } = useMessageParams(TalkingArea.COMMUNITY);

  const { onCloseSidebar, isMobile, isOpenSidebar } = useChatLayout();

  const navigate = useAppNavigate();

  const { checkPermissionWithBusinessId, permissions } = useAccessPermissions();

  const isActive = computed(() => chatId === chat.id).get();
  const isDisabled = computed(() => chat.status === CommunityChatStatus.Closed).get();
  const isDraggable = computed(
    () =>
      businessAccountId &&
      checkPermissionWithBusinessId(
        permissions.communityCategory?.update,
        parseInt(businessAccountId),
      ),
  ).get();

  const onClickChat = useCallback(
    async (event: MouseEvent<HTMLLIElement> | TouchEvent<HTMLLIElement>) => {
      const { chatId } = event.currentTarget.dataset ?? {};

      if (!chatId) return;

      await communityChatsStore.awaitedConnectToChatEmitter({ communicationItemId: chatId });

      navigate(routerCollection.community.communityChat, {
        params: {
          channelId: chat.categoryId,
          chatId: chatId,
          businessAccountId: businessAccountId?.toString() ?? '',
        },
      });

      if (isMobile && isOpenSidebar) {
        onCloseSidebar();
      }
    },
    [],
  );

  return {
    isActive,
    isDisabled,
    isDraggable,
    onClickChat,
  };
};
