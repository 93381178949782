import type { LabelHTMLAttributes, ReactNode } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type FormLabelProps = { content?: ReactNode } & Omit<
  LabelHTMLAttributes<HTMLLabelElement>,
  'children' | 'content'
>;

export const FormLabel = observer(function FormLabel(props: FormLabelProps) {
  const { content, className, ...restProps } = props;

  const { t } = useTranslation();

  const contentTextTranslation = typeof content === 'string' ? t(content as any) : content;

  const rootClasses = cl('form-label', className);

  return (
    <label className={rootClasses} {...restProps}>
      {contentTextTranslation}
    </label>
  );
});
