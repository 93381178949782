import { useCallback } from 'react';

import { useAppNavigate } from '@hooks';
import { PrivateMessageUserTile, useChatLayout } from '@units';
import { useParams } from 'react-router-dom';

import { type PrivateChatStore, useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName, withMediaUrl } from '@shared/utils';

export interface PrivateMessageSidebarItemProps {
  chat: PrivateChatStore;
}

const _PrivateMessageSidebarItem = observer((props: PrivateMessageSidebarItemProps) => {
  const { chat } = props;

  const { privateChatsStore } = useRootStore();

  const { chatId } = useParams<{ chatId: string }>();

  const { isOpenSidebar, onCloseSidebar } = useChatLayout();

  const navigate = useAppNavigate();

  const onClickItem = useCallback(() => {
    if (chatId === chat.id) return;
    privateChatsStore.joinToChat(chat.id);
    navigate(chat.id);
    if (isOpenSidebar) onCloseSidebar();
  }, [chatId, isOpenSidebar]);

  return (
    <PrivateMessageUserTile
      isActive={chatId === chat.id}
      name={chat.participant.name}
      avatar={withMediaUrl(chat.participant.image, true)}
      isOnline={chat.participant.isOnline}
      message={chat.lastMessage ? chat.lastMessage.message : ''}
      date={chat.lastMessage ? chat.lastMessage.createDate : ''}
      isViewed={chat.lastMessage?.isViewed}
      unreadCount={chat.unreadCount}
      messageType={chat.lastMessage?.type}
      viewCount={chat.lastMessage?.viewCount}
      attachments={chat.lastMessage?.attachments}
      onClick={onClickItem}
    />
  );
});

export const PrivateMessageSidebarItem = mergeComponentsWithName(
  'PrivateMessageSidebarItem',
  _PrivateMessageSidebarItem,
);
