import cl from 'classnames';
import type { HTMLMotionProps } from 'framer-motion';
import { motion } from 'framer-motion';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export interface EditorBoxProps extends HTMLMotionProps<'div'> {}

const _EditorBox = observer((props: EditorBoxProps) => {
  const { className, ...restProps } = props;

  return <motion.div className={cl(s.editorBox, className)} {...restProps}></motion.div>;
});

export const EditorBox = mergeComponentsWithName('EditorBox', _EditorBox);
