import { modalStore } from '@components';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Textarea } from '@shared/UI';

import s from './styles.module.scss';

const adaptStopWordsForApi = (stopWords: string) => {
  return stopWords.split('\n').filter(Boolean);
};

const adaptStopWordsForForm = (stopWordsList: string[]) => {
  return stopWordsList.join('\n');
};

type StopWordsFormType = {
  stopWords: string;
};

interface StopWordsFormProps {
  stopWords: string[];
  configStopWords: (stopWords: string[]) => void;
}

export const StopWordsForm = observer(function StopWordsForm(props: StopWordsFormProps) {
  const { stopWords, configStopWords } = props;

  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<StopWordsFormType>({
    defaultValues: {
      stopWords: adaptStopWordsForForm(stopWords),
    },
  });

  const onSubmit = ({ stopWords }: StopWordsFormType) => {
    configStopWords(adaptStopWordsForApi(stopWords));
    modalStore.closeAll();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.stopWordsForm}>
      <Controller
        name="stopWords"
        control={control}
        render={({ field }) => (
          <Textarea
            label={t('inputs.stop-words.label')}
            placeholder={t('inputs.stop-words.placeholder')}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <Button disabled={!isDirty} type="submit" className={s.stopWordsFormButton}>
        {t('shared-content.save-changes')}
      </Button>
    </form>
  );
});
