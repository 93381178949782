import { useMemo } from 'react';

import { CommunityChatList } from '@features-new';
import { CommunityChannelItem } from '@units';
import { AnimatePresence, motion } from 'framer-motion';

import { CommunityChannelStatus } from '@core/constants';
import type { ChannelStore } from '@core/store';
import { useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import {
  Accordion,
  Box,
  Group,
  Skeleton,
  SortableDndBox,
  Stack,
  SvgIcon,
  TypographyNew,
} from '@shared/UI';

import { mergeComponentsWithName, withMediaUrl } from '@shared/utils';

import s from './styles.module.scss';

export interface ChannelItemProps {
  channel: ChannelStore;
  canDrag?: boolean;
  isDraggable?: boolean;
}

const _ChannelItem = observer((props: ChannelItemProps) => {
  const { channel, isDraggable = false } = props;

  const { communityChannelsStore, messageCounter } = useRootStore();

  const isLoading =
    communityChannelsStore.channelBucketById(channel.id)?.loading.isLoading ?? false;
  const isLoaded = communityChannelsStore.channelBucketById(channel.id)?.loading.isLoaded ?? false;

  const unreadCounter = messageCounter.getChannel(channel.id)?.totalCount ?? 0;

  const isDisabled = channel.status === CommunityChannelStatus.Closed;

  const loaders = useMemo(() => {
    return (
      <motion.div
        key={channel.id}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ padding: '0 20px' }}
      >
        <Box w="100%" py={10}>
          <Stack gap="sm" w="100%">
            {Array(channel.availableChatsCount === 0 ? 1 : channel.availableChatsCount)
              .fill(null)
              .map((_, index) => (
                <Group key={index} justify="center" wrap="nowrap" w="100%">
                  <Skeleton height={40} width={40} circle />
                  <Skeleton height={40} width="100%" />
                </Group>
              ))}
          </Stack>
        </Box>
      </motion.div>
    );
  }, [channel.availableChatsCount, channel.id]);

  return (
    <Accordion.Item
      hideSeparator
      value={channel.id}
      data-channel-id={channel.id}
      data-channel-status={channel.status}
      className={s.channelItem}
    >
      <Accordion.Control
        align="center"
        className={s.channelItemControl}
        disabled={isDisabled}
        chevronIcon={<SvgIcon type={isDisabled ? 'lock' : 'arrow-rounded'} />}
      >
        <SortableDndBox dragId={channel.id} isDraggable={isDraggable}>
          <CommunityChannelItem
            label={channel.title}
            imageSrc={withMediaUrl(channel.imageUrl, true)}
            unreadCount={unreadCounter}
          />
        </SortableDndBox>
      </Accordion.Control>
      <Accordion.Panel>
        {channel.availableChatsCount === 0 ? (
          <Box py={12}>
            <TypographyNew colorSchema="neutral-100" className={s.emptyCategory}>
              {channel.title} is empty
            </TypographyNew>
          </Box>
        ) : null}
        {channel.availableChatsCount > 0 && (
          <AnimatePresence mode="wait">
            {isLoading && !isLoaded ? (
              loaders
            ) : (
              <motion.div
                key={'list'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'spring' }}
              >
                <CommunityChatList isDraggable={isDraggable} channel={channel} />
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
});

export const ChannelItem = mergeComponentsWithName('ChannelItem', _ChannelItem);
