import { Children, useMemo } from 'react';
import type { ReactNode } from 'react';

import { observer } from '@shared/libs/mobx';

export type RenderForItem<T, O extends Record<string, any> = {}> = (
  item: T,
  index: number,
  outsideProps?: O,
) => ReactNode;

export type ForProps<T, O extends Record<string, any> = {}> = {
  data: T[];

  outsideProps?: O;

  render: RenderForItem<T, O>;

  itemsLimit?: number;
};

export const For = observer(function For<T, O extends Record<string, any> = {}>(
  props: ForProps<T, O>,
) {
  const { data, render, itemsLimit, outsideProps } = props;

  const list = useMemo(() => {
    if (itemsLimit && Array.isArray(data) && data.length > 0) {
      return data.slice(0, itemsLimit);
    } else {
      return data;
    }
  }, [itemsLimit, data]);

  return Children.toArray(list.map((item, index) => render(item, index, outsideProps)));
});
