import type { InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import type { Props as ReactInputMaksProps } from 'react-input-mask';
import ReactInputMask from 'react-input-mask';

import { useClassName } from '@shared/UI/_hooks';

import './styles.scss';

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> & {
  hasError?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
} & Partial<Omit<ReactInputMaksProps, 'prefix'>>;

export const Input = observer(
  forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
    const { className, hasError, type = 'text', prefix, suffix, mask, ...restProps } = props;

    const { rootClassName, appendClass } = useClassName('field-input');

    const rootClasses = cl(
      rootClassName,
      {
        [appendClass('--with-error')]: hasError,
      },
      className,
    );

    const wrapperClasses = cl(appendClass('-wrapper'), {
      [appendClass('--with-prefix')]: Boolean(prefix),
      [appendClass('--with-suffix')]: Boolean(suffix),
    });

    const getField = () => {
      const baseProps = {
        className: rootClasses,
        type,
        'aria-invalid': hasError,
      };

      if (mask) {
        return (
          <ReactInputMask inputRef={ref} mask={mask} maskChar="" {...baseProps} {...restProps} />
        );
      }

      return <input ref={ref} {...baseProps} {...restProps} />;
    };

    return (
      <span className={wrapperClasses}>
        {prefix && <span className={appendClass('-prefix')}>{prefix}</span>}
        {getField()}
        {suffix && <span className={appendClass('-suffix')}>{suffix}</span>}
      </span>
    );
  }),
);
