import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { toast } from 'react-toastify';

import type { StreamBannerEntity, StreamEntity } from '@core/repositories';
import { BlockRepository } from '@core/repositories';
import { getHttpError } from '@core/services/http';
import { LoadingStore } from '@core/store/common';

export class StreamsStore {
  stream: StreamEntity | null = null;

  banner: StreamBannerEntity | null = null;

  loader = new LoadingStore();

  private blocksRepository = new BlockRepository();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'StreamsStore',
      properties: ['stream'],
    });
  }

  getStream = async (streamId: number) => {
    if (!this.stream) {
      this.loader.startLoading();
    }

    try {
      const data = await this.blocksRepository.getStreamById(streamId);

      runInAction(() => {
        this.stream = data.block.stream;
        this.banner = data.block.banner;
      });

      return data;
    } catch (e) {
      const err = getHttpError(e);
      toast.error(err.message);
      throw e;
    } finally {
      this.loader.stopLoading();
    }
  };

  disposer = () => {
    clearPersistedStore(this);
  };
}
