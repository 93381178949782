import type { InputHTMLAttributes, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

import { cl } from '@shared/libs/classnames';
import { observer } from '@shared/libs/mobx';

import { SvgIcon, Typography } from '@shared/UI';
import { useClassName } from '@shared/UI/_hooks';

import { useId } from '@shared/hooks';
import type { ElementSizes } from '@shared/types';
import { componentName } from '@shared/utils';

import { CheckboxGroup } from './checkbox-group';
import { useCheckboxContext } from './checkbox-group.context';
import './styles.scss';

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'value'> {
  hasError?: boolean;
  children?: ReactNode;
  indicator?: ReactNode;
  size?: Extract<ElementSizes, 'sm' | 'md' | 'xl'>;
  value?: string;
  inputRef?: Ref<HTMLInputElement>;
}

const _Checkbox = observer(
  forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    const {
      className,
      children,
      hasError,
      id,
      size = 'md',
      checked,
      indicator,
      disabled,
      inputRef,
      ...restProps
    } = props;

    const { rootClassName, appendClass } = useClassName('checkbox');

    const _id = useId(id);

    const ctx = useCheckboxContext();

    const contextProps = ctx
      ? {
          checked: ctx.isChecked(restProps.value as string),
          onChange: ctx.onChange,
          disabled: ctx.disabled,
        }
      : {
          disabled,
          checked,
        };

    const rootClasses = cl(
      rootClassName,
      appendClass(`--${size}`),
      {
        [appendClass('--with-error')]: hasError,
        [appendClass('--disabled')]: contextProps.disabled,
      },
      className,
    );

    return (
      <label
        className={rootClasses}
        htmlFor={_id}
        data-checked={contextProps.checked}
        data-disabled={contextProps.disabled}
      >
        <input
          ref={ref}
          id={_id}
          type="checkbox"
          className={appendClass('-field')}
          aria-disabled={contextProps.disabled}
          {...contextProps}
          {...restProps}
        />
        <span className={appendClass('-square')}>
          {indicator ?? <SvgIcon type="checkmark" className={appendClass('-indicator')}></SvgIcon>}
        </span>
        {typeof children === 'string' ? (
          <Typography
            userSelect="none"
            as="p"
            variant="body-medium-2"
            className={appendClass('-label')}
          >
            {children}
          </Typography>
        ) : (
          children
        )}
      </label>
    );
  }),
);
export const Checkbox = Object.assign(_Checkbox, {
  ...componentName('Checkbox'),
  Group: CheckboxGroup,
});
