import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { HomeworkTestStore } from '@core/store';

import { ProgressBar, TypographyNew } from '@shared/UI';

import { asHtml, calcProgressToGoal } from '@shared/utils';

import s from './styles.module.scss';

export interface DescriptionProps {
  task: HomeworkTestStore;
}

export const Description = observer(function Description(props: DescriptionProps) {
  const { task } = props;

  const { t } = useTranslation();

  const canShowTitle = (task.isCompleted && task.blockKey === 'toBeSent') || true;
  const canShowProgress = !task.isCompleted;

  return (
    <div className={s.description}>
      {canShowProgress && (
        <div className={s.descriptionProgressWrapper}>
          <TypographyNew as="span" variant="body-3" colorSchema="neutral-400">
            {t('shared-content.progress')}
          </TypographyNew>
          <ProgressBar
            className={s.descriptionProgressLine}
            progress={calcProgressToGoal(task.completedTests, task.total)}
            variant={task.isCompleted ? 'gradient' : 'yellow'}
            customLabel={`${task.completedTests}/${task.total}`}
          />
        </div>
      )}

      <div className={s.descriptionTitleWrapper}>
        {canShowTitle && (
          <TypographyNew className={s.descriptionTitle} variant="body-2" weight="bold">
            {asHtml(task.currentTest.title)}
          </TypographyNew>
        )}
      </div>
    </div>
  );
});
