import { z } from 'zod';

export const forgotPasswordSecondStepSchema = z.object({
  email: z
    .string({ required_error: 'errors.required-field' })
    .email({ message: 'errors.invalid-email' }),
  newPassword: z
    .string({ required_error: 'errors.required-field' })
    .refine((val) => val.length !== 0, { message: 'errors.required-field' })
    .refine((val) => val.length >= 8, { message: 'errors.small-length' }),
  confirmPassword: z.string().min(1, { message: 'errors.required-field' }),
  firstOtp: z.string().min(1, { message: 'errors.required-field' }),
});

export type ForgotPasswordSecondStepSchema = z.infer<typeof forgotPasswordSecondStepSchema>;
