import { Outlet } from 'react-router-dom';

import { observer } from '@shared/libs/mobx';

import { Box } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

export interface ContentProps {}

const _Content = observer((props: ContentProps) => {
  const {} = props;

  return (
    <Box className="layout-main-content">
      <Outlet />
    </Box>
  );
});

export const Content = mergeComponentsWithName('Content', _Content);
