import { useEffect } from 'react';

import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { ViewerHomeworkAccordion, ViewerHomeworkSelection } from '@features-new';

import type { MyHomeworkTypes } from '@core/repositories';
import { type CourseHomeworkStore, useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Box, For } from '@shared/UI';

import { randomId } from '@shared/utils';

import s from './styles.module.scss';

const MyHomework = observer(function MyHomework() {
  const { myWorkspaceStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  useEffect(() => {
    sendEvent({ event: AnalyticsEvents.WorkspaceHomeworkView });
  }, []);

  const renderHomeworks = ([blockType, value]: [string, CourseHomeworkStore[]]) => {
    const key = randomId();
    return (
      <ViewerHomeworkAccordion
        key={key}
        homeworks={value}
        blockType={blockType as MyHomeworkTypes}
      />
    );
  };

  return (
    <Box>
      <ViewerHomeworkSelection />

      <Box className={s.myHomeworkAccordionsContainer}>
        <For data={Object.entries(myWorkspaceStore.courseHomeworks)} render={renderHomeworks} />
      </Box>
    </Box>
  );
});

export default MyHomework;
