import type { BaseModalProps } from '@components';
import { modalStore } from '@components';
import { routerCollection } from '@constants';
import Lottie from 'lottie-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { CommunityPreviewPrice } from '@core/repositories';

import paymentSuccess from '@assets/lottie-animations/payment_success.json';

import { Button, Group, Modal, Stack, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

interface SuccessPaymentModalProps extends BaseModalProps {
  price: CommunityPreviewPrice;
}

export const SuccessPaymentModal = observer(function FreePlanModal(
  props: SuccessPaymentModalProps,
) {
  const { price, ...restProps } = props;

  const { t } = useTranslation();

  const closeModalHandler = () => {
    modalStore.deactivateModal('success-payment-modal');
  };

  const navigateToCommunityHandler = () => {
    window.location.replace(`${routerCollection.community.root}`);
    modalStore.closeAll();
  };

  return (
    <Modal centered size="lg" id="success-payment-modal" withHeader={false} {...restProps}>
      <Stack gap="56" className={s.successPaymentModal}>
        <Stack gap="xl">
          <div className={s.successPaymentModalAnimation}>
            <Lottie animationData={paymentSuccess} loop={false} />
          </div>
          <TypographyNew
            variant="title-2"
            textAlign="center"
            className={s.successPaymentModalTitle}
          >
            {t('modals.success-payment-modal.header.title')}
          </TypographyNew>
        </Stack>
        <Group wrap="nowrap" w="70%">
          <Button variant="outline" onClick={closeModalHandler}>
            {t('modals.success-payment-modal.actions.back')}
          </Button>
          <Button onClick={navigateToCommunityHandler}>
            {t('modals.success-payment-modal.actions.community')}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
});
