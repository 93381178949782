import { lazy } from 'react';

import { Communities } from './communities';
import { Chat as CommunityChat } from './community';
import { routerCollection } from '@constants';
import { Outlet, type RouteObject } from 'react-router-dom';

import { lazyDelay } from '@shared/utils';

const EntryPage = lazyDelay(() => import('./EntryPage'));

const CommunityPreview = lazyDelay(() => import('./community-preview'));

const Community = lazy(() => import('./community'));

const CourseLanding = lazy(() => import('./course-preview'));

export const communityRouter: RouteObject = {
  path: routerCollection.community.root,
  element: <EntryPage />,
  children: [
    {
      index: true,
      element: <Communities />,
    },
    {
      path: routerCollection.community.communityPreview,
      element: <CommunityPreview />,
    },
    {
      path: routerCollection.community.community,
      element: <Community />,
      children: [
        {
          path: routerCollection.community.communityChannel,
          element: <Outlet />,
          children: [
            {
              path: routerCollection.community.communityChat,
              element: <CommunityChat />,
            },
          ],
        },
      ],
    },
    {
      path: routerCollection.community.course,
      element: <CourseLanding />,
    },
  ],
};
