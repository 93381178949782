import { LoadingStore } from '../../common';
import { CommunityStore } from './community.store';
import { makeAutoObservable, runInAction } from 'mobx';

import { SocketEventsCollection } from '@core/constants';
import type {
  CommunityBlockEntity,
  CommunityPreviewBlockEntity,
  GetCommunitiesQueryDto,
  GetCommunityPreviewQueryDto,
  UserCommunityUnreadStatus,
} from '@core/repositories';
import { blockRepository } from '@core/repositories';
import { getHttpError } from '@core/services/http';
import { appSocket } from '@core/services/socket';

type PurchasedCommunity = Omit<CommunityBlockEntity, 'businessAccounts'> & {
  businessAccounts: CommunityStore[];
};

type CommunitiesBlocks = {
  purchased: PurchasedCommunity | null;
  other: CommunityBlockEntity[];
  favorite: CommunityBlockEntity[];
};

export class CommunitiesStore {
  communitiesBlocks: CommunitiesBlocks = {
    purchased: null,
    other: [],
    favorite: [],
  };

  communityPreviewBlocks: CommunityPreviewBlockEntity | null = null;

  communityBlocksLoader = new LoadingStore();

  communityPreviewLoader = new LoadingStore();

  private _unreadHashMap = new Map<number, number>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    // this.initSubscribers();
  }

  get calcAllUnreadCount() {
    let num = 0;

    this._unreadHashMap.forEach((n) => {
      num += n;
    });

    return num;
  }

  get otherCommunities() {
    return this.communitiesBlocks.other;
  }

  get purchasedCommunities() {
    return this.communitiesBlocks.purchased;
  }

  get favoriteCommunities() {
    return this.communitiesBlocks.favorite;
  }

  get activeCommunity() {
    return (
      this.purchasedCommunities?.businessAccounts.find((community) => community.isActive) ?? null
    );
  }

  receiveUnreadByCommunityId = (communityId: number) => {
    const num = this._unreadHashMap.get(communityId);

    return num ?? 0;
  };

  getCommunitiesBlocks = async (query?: GetCommunitiesQueryDto) => {
    try {
      const { purchased, favoriteCommunicationItems, categories } =
        await blockRepository.getCommunities(query);

      const _purchased: PurchasedCommunity = {
        ...purchased,
        businessAccounts: purchased.businessAccounts.map(
          (community) => new CommunityStore(community),
        ),
      };
      runInAction(() => {
        this.communitiesBlocks = {
          ...this.communitiesBlocks,
          favorite: favoriteCommunicationItems,
          other: categories,
          purchased: _purchased,
        };
      });
    } catch (e) {
    } finally {
      this.communityBlocksLoader.stopLoading();
      this.communityBlocksLoader.setLoaded(true);
    }
  };

  getCommunityPreview = async (businessId: number, query?: GetCommunityPreviewQueryDto) => {
    if (
      !this.communityPreviewBlocks ||
      this.communityPreviewBlocks.businessAccount.id !== businessId
    ) {
      this.communityPreviewLoader.startLoading();
    }
    try {
      const data = await blockRepository.getCommunityPreview(businessId, query);

      runInAction(() => {
        this.communityPreviewBlocks = data;
      });
    } catch (e) {
      const err = getHttpError(e);
      return await Promise.reject(err.message);
    } finally {
      this.communityPreviewLoader.stopLoading();
    }
  };

  connectToCommunity = async (businessId: number) => {
    if (!this.communityBlocksLoader.isLoaded) {
      await this.getCommunitiesBlocks();
    }

    this.purchasedCommunities?.businessAccounts?.forEach((community) => {
      community.id === businessId
        ? community.changeIsActive(true)
        : community.changeIsActive(false);
    });

    appSocket.dispatch(SocketEventsCollection.CommunityEvents.Emitters.Connect, {
      communityId: businessId,
    });
  };

  awaitedConnectToCommunity = async (businessId: number) => {
    if (!this.communityBlocksLoader.isLoaded) {
      await this.getCommunitiesBlocks();
    }
    this.purchasedCommunities?.businessAccounts?.forEach((community) => {
      community.id === businessId
        ? community.changeIsActive(true)
        : community.changeIsActive(false);
    });

    await appSocket.awaitedDispatch(
      SocketEventsCollection.CommunityEvents.Emitters.Connect,
      SocketEventsCollection.CommunityEvents.Subscribers.Connect,
      {
        communityId: businessId,
      },
    );
  };

  private receiveUnreadStatusSubscriber = (details: UserCommunityUnreadStatus) => {
    runInAction(() => {
      Object.entries(details.userCommunityUnreadInfo).forEach((item) => {
        this._unreadHashMap.set(Number(item[0]), item[1].unreadCount);
      });
    });
  };

  private initSubscribers = () => {
    appSocket.subscribe(
      SocketEventsCollection.CommonEvents.Subscribers.ReceiveUnreadStatuses,
      this.receiveUnreadStatusSubscriber,
    );
  };

  initEvents = () => {
    this.initSubscribers();
  };

  disposer = () => {
    this.communitiesBlocks = {
      purchased: null,
      other: [],
      favorite: [],
    };
    this.communityPreviewBlocks = null;
    this.communityBlocksLoader = new LoadingStore();
    this.communityPreviewLoader = new LoadingStore();
  };
}
