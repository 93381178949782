import { NotificationItem } from '../notification-item';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { UnreadNotificationEntity } from '@core/repositories';

import notificationsImage from '@assets/images/empty-content/notifications.png';

import { Box, Button, Image, Stack, TypographyNew } from '@shared/UI';

import s from './styles.module.scss';

interface NotificationContentProps {
  joinToChat: (chatId: string) => void;
  readAllNotifications: () => Promise<void>;
  notifications: UnreadNotificationEntity[];
}

export const NotificationContent = observer(function NotificationContent(
  props: NotificationContentProps,
) {
  const { joinToChat, readAllNotifications, notifications } = props;

  const { t } = useTranslation();

  return (
    <div className={s.notificationsContent}>
      <div className={s.notificationsContentContainer}>
        <div className={s.notificationsContentHeader}>
          <TypographyNew variant="title-4" weight="bold">
            {t('dashboard.notifications.title')}
          </TypographyNew>
          <Button
            size="xs"
            variant="text"
            className={s.notificationsContentHeaderButton}
            onClick={readAllNotifications}
            disabledAnimation
            disabled={!notifications.length}
          >
            {t('shared-content.read-all')}
          </Button>
        </div>

        {!!notifications.length ? (
          <Stack className={s.notificationsContentBody}>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                imageUrl={notification.imageUrl}
                title={notification.title}
                body={notification.body}
                redirectInfo={notification.redirectInfo}
                joinToChat={joinToChat}
              />
            ))}
          </Stack>
        ) : (
          <Stack align="center" justify="center" className={s.notificationsContentBody}>
            <Image src={notificationsImage} className={s.notificationsContentBodyImage} />
            <Box>
              <TypographyNew
                variant="title-4"
                weight="bold"
                textAlign="center"
                className={s.notificationsContentBodyEmptyTitle}
              >
                {t('dashboard.notifications.empty.title')}
              </TypographyNew>
              <TypographyNew variant="body-2" colorSchema="neutral-200" textAlign="center">
                {t('dashboard.notifications.empty.description')}
              </TypographyNew>
            </Box>
          </Stack>
        )}
      </div>
    </div>
  );
});
