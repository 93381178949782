import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import { ArrowIcon } from '../arrow-icon';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type SwiperClass from 'swiper';

import { observer } from '@shared/libs/mobx';

import type { ElementProps } from '@shared/types';
import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass, appendClass } = classNames('carousel-navigation');

const navigationArrows = cva(rootClass, {
  variants: {
    variant: {
      filled: appendClass('--filled'),
      outlined: appendClass('--outlined'),
    },
  },

  defaultVariants: {
    variant: 'filled',
  },
});

const ArrowDirection = {
  next: 'next',
  prev: 'prev',
} as const;

export interface NavigationArrowsProps
  extends VariantProps<typeof navigationArrows>,
    ElementProps<'div'> {
  swiper: SwiperClass | null;
}

const _NavigationArrows = observer((props: NavigationArrowsProps) => {
  const { variant, className, swiper, ...restProps } = props;

  const [arrowsConfig, setArrowsConfig] = useState({
    isBeginning: false,
    isEnd: false,
  });

  const handleClickArrow = (event: MouseEvent) => {
    const direction = event.currentTarget.getAttribute('data-direction');
    if (direction === ArrowDirection.next) {
      swiper?.slideNext();
    }
    if (direction === ArrowDirection.prev) {
      swiper?.slidePrev();
    }
  };

  const rootClasses = navigationArrows({ variant, className });

  const btnClasses = appendClass('-btn');

  useEffect(() => {
    swiper?.on('update', (_swiper) => {
      const isBeginning = _swiper.isBeginning || _swiper.slides.length === 1;
      const isEnd = _swiper.isEnd;

      setArrowsConfig({
        isBeginning,
        isEnd,
      });
    });
    swiper?.on('toEdge', (_swiper) => {
      setArrowsConfig({
        isBeginning: _swiper.isBeginning,
        isEnd: _swiper.isEnd,
      });
    });
    swiper?.on('fromEdge', (_swiper) => {
      setArrowsConfig({
        isBeginning: _swiper.isBeginning,
        isEnd: _swiper.isEnd,
      });
    });
  }, [swiper]);

  return (
    <div className={rootClasses} {...restProps}>
      <button
        className={btnClasses}
        onClick={handleClickArrow}
        data-direction={ArrowDirection.prev}
        disabled={arrowsConfig.isBeginning}
      >
        <ArrowIcon />
      </button>
      <button
        className={btnClasses}
        onClick={handleClickArrow}
        data-direction={ArrowDirection.next}
        disabled={arrowsConfig.isEnd}
      >
        <ArrowIcon />
      </button>
    </div>
  );
});

export const NavigationArrows = mergeComponentsWithName('NavigationArrows', _NavigationArrows);
