import { BtnBold } from './btn-bold';
import { BtnBulletList } from './btn-bullet-list';
import { BtnItalic } from './btn-italic';
import { BtnLink } from './btn-link';
import { BtnOrderedList } from './btn-ordered-list';
import { BtnStrikeThrough } from './btn-strike-through';
import { BtnUnderline } from './btn-underline';
import type { Editor } from '@tiptap/react';
import { BubbleMenu } from '@tiptap/react';

import { observer } from '@shared/libs/mobx';

import { Group } from '@shared/UI';

import { classNames, mergeComponentsWithName } from '@shared/utils';

import './styles.scss';

const { rootClass } = classNames('bubble-menu');

export interface BubbleEditorMenuProps {
  editor: () => Editor;
}

const _BubbleEditorMenu = observer((props: BubbleEditorMenuProps) => {
  const { editor } = props;

  return (
    <BubbleMenu
      tippyOptions={{
        zIndex: 1060,
      }}
      className={rootClass}
      editor={editor?.() || undefined}
    >
      <Group>
        <BtnBold />
        <BtnItalic />
        <BtnUnderline />
        <BtnStrikeThrough />
        <BtnBulletList />
        <BtnOrderedList />
        <BtnLink />
      </Group>
    </BubbleMenu>
  );
});

export const BubbleEditorMenu = mergeComponentsWithName('BubbleEditorMenu', _BubbleEditorMenu);
