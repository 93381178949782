import { PrivateChatMessageList } from '@widgets';
import { useParams } from 'react-router-dom';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

const _ActiveChatContent = observer(() => {
  const { chatId } = useParams<{ chatId: string }>();

  return <PrivateChatMessageList key={chatId} />;
});

export const ActiveChatContent = mergeComponentsWithName('ActiveChatContent', _ActiveChatContent);
