import { useEffect, useState } from 'react';

import { REDIRECTION_BUTTON_STATUSES } from './event-card-redirection-button.constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button } from '@shared/UI';

import s from './styles.module.scss';

interface EventCardRedirectionButtonProps {
  startDate: string;
  buttonText: string;
  buttonLink: string;
  isTimerEnabled: boolean;
  id: number;
  title: string;
}

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

let TIMER_ID: any = null;

export const EventCardRedirectionButton = observer(function EventCardRedirectionButton(
  props: EventCardRedirectionButtonProps,
) {
  const { startDate, buttonLink, buttonText, isTimerEnabled, id, title } = props;

  const sendEvent = useAnalyticsContext();

  const [timeLeft, setTimeLeft] = useState(0);
  const [showCountdown, setShowCountdown] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { t } = useTranslation();

  const getButtonStatus = () => {
    const start = dayjs(startDate);
    const secondsLeft = start.diff(dayjs(), 'second');
    const isEventAlreadyStarted = secondsLeft <= 0;
    const isLessThen10Hours = secondsLeft < 10 * 60 * 60;

    if (isTimerEnabled && !isEventAlreadyStarted && !isLessThen10Hours) {
      return REDIRECTION_BUTTON_STATUSES.DISABLE;
    }
    if (isTimerEnabled && !isEventAlreadyStarted) return REDIRECTION_BUTTON_STATUSES.TIMER;
    if (isTimerEnabled && isEventAlreadyStarted) return REDIRECTION_BUTTON_STATUSES.ENABLE;
    if (!isTimerEnabled && !isEventAlreadyStarted) return REDIRECTION_BUTTON_STATUSES.DISABLE;
    if (!isTimerEnabled && isEventAlreadyStarted) return REDIRECTION_BUTTON_STATUSES.ENABLE;

    return REDIRECTION_BUTTON_STATUSES.ENABLE;
  };

  useEffect(() => {
    const start = dayjs(startDate);
    const secondsLeft = start.diff(dayjs(), 'second');
    const buttonStatus = getButtonStatus();

    switch (buttonStatus) {
      case REDIRECTION_BUTTON_STATUSES.TIMER:
        setShowCountdown(true);
        setIsDisabled(true);
        setTimeLeft(secondsLeft);
        break;
      case REDIRECTION_BUTTON_STATUSES.ENABLE:
        setShowCountdown(false);
        setIsDisabled(false);
        break;
      case REDIRECTION_BUTTON_STATUSES.DISABLE:
        setShowCountdown(false);
        setIsDisabled(true);
        break;
      default:
        setShowCountdown(false);
        setIsDisabled(false);
        break;
    }

    if (buttonStatus === REDIRECTION_BUTTON_STATUSES.TIMER) {
      TIMER_ID = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 0) {
            clearInterval(TIMER_ID);
            setShowCountdown(false);
            setIsDisabled(false);
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(TIMER_ID);
      setShowCountdown(false);
    };
  }, [startDate, isTimerEnabled]);

  const formatTime = (timeInSeconds: number) => {
    if (timeInSeconds < 0) {
      return '00:00:00';
    }

    const durationObject = dayjs.duration(timeInSeconds, 'seconds');
    return durationObject.format('HH:mm:ss');
  };

  return (
    <a
      href={buttonLink}
      target="_blank"
      rel="noopener noreferrer"
      className={s.eventCardRedirectionLink}
    >
      <Button
        type="button"
        disabled={isDisabled}
        className={s.eventCardRedirectionButton}
        onClick={() =>
          sendEvent({
            event: AnalyticsEvents.EventAttendTap,
            payload: { event_id: id, event_name: title },
          })
        }
      >
        {showCountdown ? `${t('calendar.redirection-button')} ${formatTime(timeLeft)}` : buttonText}
      </Button>
    </a>
  );
});
