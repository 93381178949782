import type { HomeworkTestOptionEntity } from '@core/repositories';

import { observer } from '@shared/libs/mobx';

import { Radio } from '@shared/UI';

import { HomeworkRadio } from './homework-radio';

export interface RadiosProps {
  options: HomeworkTestOptionEntity[];

  selectedIds: string[];

  onChange: (value: string | string[]) => void;

  canInteract: boolean;
}

export const Radios = observer(function Radios(props: RadiosProps) {
  const { options, selectedIds, canInteract, onChange } = props;

  return (
    <Radio.Group disabled={!canInteract} onChange={onChange} value={selectedIds?.[0]}>
      {options.map((option) => {
        const isChecked = selectedIds.includes(String(option.id));
        return <HomeworkRadio key={option.id} option={option} isChecked={isChecked} />;
      })}
    </Radio.Group>
  );
});
