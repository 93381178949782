import { type ReactNode } from 'react';

import type { RouterLinkProps } from '../router-link';
import { RouterLink } from '../router-link';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Group, Indicator } from '@shared/UI';

import { mergeComponentsWithName } from '@shared/utils';

import s from './styles.module.scss';

export type NavItemProps = {
  icon?: ReactNode;
  label: ReactNode;
  labelClassName?: string;
  badgeCounter?: number;
} & Omit<RouterLinkProps, 'children'>;

const _NavItem = observer((props: NavItemProps) => {
  const { className, labelClassName, icon, label, badgeCounter, ...restProps } = props;

  const { t } = useTranslation();

  const rootClasses = cl(s.navItem, className);

  return (
    <RouterLink className={rootClasses} {...restProps}>
      <Group>
        {icon && <span>{icon}</span>}
        <span className={labelClassName}>
          {typeof label === 'string' ? t(label as any) : label}
        </span>
      </Group>
      {Boolean(badgeCounter) && (
        <Indicator count={badgeCounter} size="md" maxCount={99} className={s.navItemIndicator} />
      )}
    </RouterLink>
  );
});

export const NavItem = mergeComponentsWithName('NavItem', _NavItem);
