import { HomeworkType } from '@constants';
import { AnalyticsEvents, useAnalyticsContext } from '@contexts';
import { useTranslation } from 'react-i18next';

import { type HomeworkTestStore, type HomeworkWritingStore, useRootStore } from '@core/store';

import { observer } from '@shared/libs/mobx';

import { Button, Group } from '@shared/UI';

export interface ActionBtnProps {
  task: HomeworkTestStore | HomeworkWritingStore;

  selectedIds: string[];

  isNotEmptyIds: () => boolean;

  onClear: () => void;
}

export const ActionBtn = observer(function ActionBtn(props: ActionBtnProps) {
  const { task, selectedIds, isNotEmptyIds, onClear } = props;

  const { t } = useTranslation();

  const { myWorkspaceStore } = useRootStore();
  const sendEvent = useAnalyticsContext();

  const handleClick = (_task: HomeworkTestStore) => {
    const ids = selectedIds.map((id) => Number(id));
    _task.completeTask(ids);
    if (_task.isLastTest) {
      setTimeout(() => {
        myWorkspaceStore.completeHomework(_task.homeworkId, _task.blockKey);
      }, 5000);

      sendEvent({
        event: AnalyticsEvents.TestFinishTap,
        payload: { test_mark: _task.correctAnswers },
      });
    }

    onClear();
  };

  if (task.blockKey === 'toBeSent') {
    if (task.type === HomeworkType.test) {
      let label: string = '';

      if (task.hasMoreTask) {
        label = t('shared-content.next-question');
      }
      if (task.isLastTest) {
        label = t('shared-content.send-results');
      }

      if (task.isCompleted && task.blockKey === 'toBeSent') {
        return null;
      }

      return (
        <Button
          disabled={!isNotEmptyIds()}
          onClick={() => handleClick(task)}
          disabledAnimation
          size="sm"
        >
          {label}
        </Button>
      );
    }
  }

  if (task.blockKey === 'verified') {
    if (task.type === HomeworkType.test) {
      return (
        <Group wrap="nowrap" gap="md" justify="center">
          {task.currentTestIndex !== 0 && (
            <Button
              onClick={() => task.prevTest()}
              disabledAnimation
              size="sm"
              variant="outline-neutral"
            >
              {t('shared-content.back')}
            </Button>
          )}
          {task.currentTestIndex + 1 !== task.total && (
            <Button
              onClick={() => task.nextTest()}
              disabledAnimation
              size="sm"
              variant="outline-neutral"
            >
              {t('shared-content.next')}
            </Button>
          )}
        </Group>
      );
    }
  }

  if (task.blockKey === 'unverified') {
    return null;
  }

  return null;
});
