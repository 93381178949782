import { SvgIcon } from '../../../svg-icon';
import { useRichEditorContext } from '../../rich-editor.context';
import { EditorMenuItem } from '../editor-menu-item';

import { observer } from '@shared/libs/mobx';

import { mergeComponentsWithName } from '@shared/utils';

const _BtnBulletList = observer(() => {
  const { editor } = useRichEditorContext();
  const handleClickBtn = () => editor().chain().focus().toggleBulletList().run();

  const icon = <SvgIcon type="editor-bullet-list" />;
  return (
    <EditorMenuItem
      icon={icon}
      onClick={handleClickBtn}
      isActive={editor().isActive('bulletList')}
    />
  );
});

export const BtnBulletList = mergeComponentsWithName('BtnBulletList', _BtnBulletList);
