import { useId as useReactId, useState } from 'react';

import { useIsomorphicEffect } from '@shared/hooks';
import { randomId } from '@shared/utils';

export function useId(staticId?: string) {
  const reactId = useReactId();

  const [uuid, setUuid] = useState(reactId);

  useIsomorphicEffect(() => {
    setUuid(randomId());
  }, []);

  if (typeof staticId === 'string') {
    return staticId;
  }

  if (typeof window === 'undefined') {
    return reactId;
  }

  return uuid;
}
