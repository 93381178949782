import i18n from '../../../i18n';
import type {
  AuthViaAppleDto,
  AuthViaGoogleDto,
  ForgotPasswordFirstStepDto,
  ForgotPasswordSecondStepDto,
  SignInByEmailDto,
  SignUpByEmailDto,
  SignUpByPhoneDto,
  VerifyByEmailDto,
  VerifyByPhoneDto,
} from './dto';
import type {
  AuthByEmailOrPhoneResponse,
  ForgotPasswordResponse,
  VerifyByEmailOrPhoneResponse,
} from './responses';
import { toast } from 'react-toastify';

import { PATH_COLLECTION } from '@core/constants';
import { createHTTPService } from '@core/services/http';

import { transformOtp } from '@shared/utils';

export class AuthRepository {
  private readonly http = createHTTPService(PATH_COLLECTION.AUTH.ROOT);

  authViaGoogle = async (dto: AuthViaGoogleDto) => {
    const path = PATH_COLLECTION.AUTH.SIGN_IN_WITH_GOOGLE;

    try {
      const { data } = await this.http.post<AuthViaGoogleDto>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };

  authViaApple = async (dto: AuthViaAppleDto) => {
    const path = PATH_COLLECTION.AUTH.SIGN_IN_WITH_APPLE;

    try {
      const { data } = await this.http.post<AuthViaAppleDto>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };

  signUp = async (dto: SignUpByPhoneDto | SignUpByEmailDto) => {
    const path = PATH_COLLECTION.AUTH.SIGN_UP;

    try {
      const captcha = await this.getCaptcha();

      const { data } = await this.http.post<AuthByEmailOrPhoneResponse>(path, { ...dto, captcha });

      return data;
    } catch (e) {
      throw e;
    }
  };

  signIn = async (dto: SignUpByPhoneDto | SignInByEmailDto) => {
    const path = PATH_COLLECTION.AUTH.SIGN_IN;

    const captcha = await this.getCaptcha();

    try {
      const { data } = await this.http.post<AuthByEmailOrPhoneResponse>(path, { ...dto, captcha });

      return data;
    } catch (e) {
      throw e;
    }
  };

  verify = async (dto: VerifyByEmailDto | VerifyByPhoneDto) => {
    const path = PATH_COLLECTION.AUTH.VERIFY;

    try {
      const { data } = await this.http.post<{
        user: VerifyByEmailOrPhoneResponse;
      }>(path, {
        ...dto,
        otp: transformOtp(dto.otp),
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  forgotPasswordFirstStep = async (dto: ForgotPasswordFirstStepDto) => {
    const path = PATH_COLLECTION.AUTH.FORGOT_PASSWORD_FIRST_STEP;

    try {
      const { data } = await this.http.patch<ForgotPasswordResponse>(path, dto);

      return data;
    } catch (e) {
      throw e;
    }
  };

  forgotPasswordSecondStep = async (dto: ForgotPasswordSecondStepDto) => {
    const path = PATH_COLLECTION.AUTH.FORGOT_PASSWORD_SECOND_STEP;

    try {
      const { data } = await this.http.patch<ForgotPasswordResponse>(path, {
        ...dto,
        firstOtp: transformOtp(dto.firstOtp),
      });

      toast.success(i18n.t('new-password.successfully-changed'));

      return data;
    } catch (e) {
      throw e;
    }
  };

  logout = async () => {
    const path = PATH_COLLECTION.AUTH.LOGOUT;

    try {
      await this.http.post(path);
    } catch (e) {
      throw e;
    }
  };

  private getCaptcha = async () => {
    return window.grecaptcha.execute(import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY, { action: '' });
  };
}
